import * as React from 'react';
import { PageFinanceActionsCtx, PageFinanceActionsRecord, usePageFinanceActionsRxjs } from './PageFinanceActionsStore';
import SpectreCardHeader from '../../../components/spectre/SpectreCardHeader';
import SpectreButton from '../../../components/spectre/SpectreButton';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { cs, formatCents, formatDate } from '../../../utils';
import GenericPagination from '../../../components/GenericPagination';
import ModalFilter, { ModalFilterValues } from './ModalFilter';
import * as _ from 'underscore';
import { Link } from '../../../components/Link';
import FormGroup from '../../../components/FormGroup';
import SpectreCalendar from '../../../components/spectre/SpectreCalendar';

export default function PageFinanceActions() {
  const { store, context } = usePageFinanceActionsRxjs();

  if (!store || !context) return null;

  return (
    <PageFinanceActionsCtx.Provider value={{ store, context }}>
      <div className="container">
        <Table />
        <Modals />
      </div>
    </PageFinanceActionsCtx.Provider>
  );
}

function Table() {
  const { store, context } = React.useContext(PageFinanceActionsCtx);

  return (
    <div className="card">
      <div className={cs('card-loading', !context.loaded && 'active')} />

      <SpectreCardHeader title="Statement-regels" subtitle="Zie hier, alle statement-regels." />
      <div className="card-body">
        <Search />
      </div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>OrderNr</th>
              <th>DNr</th>
              <th>Moment</th>
              <th>Type</th>
              <th>Prijs Exc.</th>
              <th>
                <abbr title="Commissie">Comm.</abbr> Exc.
              </th>
              <th>ArtikelNr</th>
              <th>Artikel</th>
            </tr>
          </thead>
          <tbody>
            {context.records.map(record => (
              <TableRow key={record.opla_id} record={record} />
            ))}
          </tbody>

          <GenericPagination pagination={context.pagination} setPage={store.setPage} span={7} />
        </table>
      </div>
    </div>
  );
}

function TableRow({ record }: { record: PageFinanceActionsRecord }) {
  const { store } = React.useContext(PageFinanceActionsCtx);

  return (
    <tr key={record.opla_id}>
      <td className="has-table-shrink-nowrap">#{record.opla_id}</td>
      <td className="has-table-shrink-nowrap">
        <Link pathName="/order/purchase/one" pathSearch={{ id: record.op_id.toString() }}>
          #{record.op_cart_id}
        </Link>
      </td>
      <td className="has-table-shrink-nowrap">#{record.opla_tenant_id}</td>
      <td className="has-table-shrink-nowrap">{formatDate(record.opla_created_at, 'L LT')}</td>
      <td className="has-table-shrink-nowrap">{ComponentOplaKind(record.opla_kind)}</td>
      <td className="has-table-shrink-nowrap text-right">{formatCents(record.opla_exclusive)}</td>
      <td className="has-table-shrink-nowrap text-right">{formatCents(-1 * record.opla_commission_exclusive)}</td>
      <td className="has-table-shrink-nowrap">{record.sa_number_beeyond}</td>
      <td>{record.sa_name}</td>
    </tr>
  );
}

function ComponentOplaKind(value: string) {
  if (value === 'sale') {
    return <span>Kassaverkoop</span>;
  }
  if (value === 'delivered') {
    return <span>Levering</span>;
  }
  if (value === 'cancellation') {
    return <span>Retour</span>;
  }
  if (value === 'compensation') {
    return <span>Klantcompensatie</span>;
  }
  return <span className="text-danger">{value}</span>;
}

function Modals() {
  const { store, context } = React.useContext(PageFinanceActionsCtx);
  const mctx = context.modal;

  if (!mctx) return null;

  function filterApplications(values?: ModalFilterValues) {
    if (_.isEmpty(values)) {
      store.closeModal();
    } else {
      store.closeModal();
      store.search(values);
    }
  }

  return (
    <React.Fragment>
      {mctx.which === 'filter' && <ModalFilter {...mctx.data} closeModal={filterApplications} />}
    </React.Fragment>
  );
}

function Search() {
  const { store, context } = React.useContext(PageFinanceActionsCtx);
  const initial = {
    day_start: context.query.day_start,
    day_end: context.query.day_end,
  };
  const query_is_empty = store.queryIsEmpty(false);

  function openFilterApplications() {
    store.openModal({ which: 'filter', data: { initial: context.query } });
  }

  return (
    <Formik
      initialValues={initial}
      enableReinitialize
      onSubmit={async (values, formikActions) => {
        await store.search(values);
        formikActions.setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string(),
        online_status: Yup.string(),
        status: Yup.string(),
      })}
      render={fp => (
        <Form style={{ padding: '0 1rem' }}>
          <div className={cs('form-group')}>
            <div className="columns">
              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="button"
                  className="btn btn-error-outline btn-action s-circle tooltip tooltip-right"
                  data-tooltip="Leeg alle zoekvelden."
                  disabled={!context.loaded}
                  onClick={async () => {
                    await store.resetQuery();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="column col-4 col-xs-12  pr-2">
                <FormGroup name="day_start" title="Vanaf datum" errors={fp.errors}>
                  <SpectreCalendar name="day_start" fp={fp} />
                </FormGroup>
              </div>
              <div className="column col-4 col-xs-12  pr-2">
                <FormGroup name="day_end" title="Tot datum" errors={fp.errors}>
                  <SpectreCalendar name="day_end" fp={fp} />
                </FormGroup>
              </div>

              <div className="column" />

              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <SpectreButton
                  icon="fa-filter"
                  classNames={!query_is_empty && ['btn-primary btn-action s-circle mx-1']}
                  onClick={openFilterApplications}
                  disabled={!context.loaded}
                  tooltip={{ title: 'Filter de statement-regels', position: 'left' }}
                />
                <button
                  type="submit"
                  className="btn btn-primary-outline btn-action s-circle"
                  disabled={fp.isSubmitting || !context.loaded}
                >
                  <i className={cs('fal fa-fw', !context.loaded ? 'fa-spin fa-spinner-third' : 'fa-search')} />
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
}
