import PageOrderGenericMany from './generic/PageOrderGenericMany';
import PageOrderGenericOne from './generic/PageOrderGenericOne';
import PageOrderTenantMany from './tenant/PageOrderTenantMany';
import PageOrderTenantOne from './tenant/PageOrderTenantOne';
import PageOrderSponsorMany from './sponsor/PageOrderSponsorMany';
import PageOrderSponsorLines from './sponsor/PageOrderSponsorLines';

const OrderStructure = [
  {
    pathname: '/order/generic/many',
    title: 'Overzicht',
    icon: 'fa-desktop',
    component: PageOrderGenericMany,

    is_global: true,
    rights: ['shop.orders'],
  },
  {
    pathname: '/order/generic/one',
    title: 'Order',
    icon: 'fa-file-invoice',
    component: PageOrderGenericOne,
    parent: () => ({ pathName: '/order/generic/many' }),

    is_global: true,
    rights: ['shop.orders'],
  },

  {
    pathname: '/order/tenant/many',
    title: 'Overzicht',
    icon: 'fa-desktop',
    component: PageOrderTenantMany,

    rights: ['shop.orders'],
  },
  {
    pathname: '/order/tenant/one',
    title: 'Order',
    icon: 'fa-file-invoice',
    component: PageOrderTenantOne,
    parent: () => ({ pathName: '/order/tenant/many' }),

    rights: ['shop.orders'],
  },

  {
    pathname: '/order/sponsor/many',
    title: 'Overzicht',
    icon: 'fa-list',
    component: PageOrderSponsorMany,

    rights: ['order.sponsor'],
  },
  {
    pathname: '/order/sponsor/articles',
    title: 'Artikelen',
    icon: 'fa-shopping-cart',
    component: PageOrderSponsorLines,
    parent: () => ({ pathName: '/order/sponsor/many' }),

    rights: ['order.sponsor'],
  },
];

export default OrderStructure;
