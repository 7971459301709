import PageShopDashboard from './PageShopDashboard';
import PageShopArticleMany from './article/many/PageShopArticleMany';
import PageShopArticleStickerTemplateMany from './article/sticker/PageShopArticleStickerTemplateMany';
import PageShopArticleStickerTemplateEdit from './article/sticker/PageShopArtickeStickerTemplateEdit';
import PageShopArticleStickerTemplatePublish from './article/sticker/PageShopArtickeStickerTemplatePublish';

const ShopStructure = [
  {
    pathname: '/shop/dashboard',
    title: 'Dashboard',
    icon: 'fa-desktop',
    component: PageShopDashboard,

    rights: ['xxx'],
  },
  {
    pathname: '/shop/article/many',
    title: 'Artikelen',
    icon: 'fa-desktop',
    component: PageShopArticleMany,

    rights: [],
  },
  {
    pathname: '/shop/sticker/template/many',
    title: 'Stickers',
    icon: 'fa-sticky-note',
    component: PageShopArticleStickerTemplateMany,

    rights: [],
  },
  {
    pathname: '/shop/sticker/template/edit',
    title: 'Template',
    icon: 'fa-sticky-note',
    component: PageShopArticleStickerTemplateEdit,
    parent: () => ({ pathName: '/shop/sticker/template/many' }),

    rights: [],
  },
  {
    pathname: '/shop/sticker/template/publish',
    title: 'Printen',
    icon: 'fa-print',
    component: PageShopArticleStickerTemplatePublish,
    parent: search => ({ pathName: '/shop/sticker/template/edit', pathSearch: { template_id: search.template_id } }),

    rights: [],
  },
];

export default ShopStructure;
