import * as React from 'react';
import { cs } from '../../utils';
import { BaseStore, useRxjsStore } from '../../effects';
import { Form, Formik } from 'formik';
import * as _ from 'underscore';
import SpectreAutocomplete from '../../components/spectre/SpectreAutocomplete';
import FormGroup from '../../components/FormGroup';
import SpectreCalendar from '../../components/spectre/SpectreCalendar';

// region Types

export type PageFinanceDashboardBasicProps = {
  store: PageFinanceDashboardStore;
  context: PageFinanceDashboardContext;
};

export type PageFinanceDashboardQuery = {};

export type PageFinanceDashboardContext = {
  loaded: boolean;

  query: PageFinanceDashboardQuery;
};

// endregion

export const PageFinanceDashboardCtx = React.createContext<PageFinanceDashboardBasicProps>(null);

export default function PageFinanceDashboard() {
  const { store, context } = usePageFinanceDashboardRxjs();

  if (!store || !context) return null;

  return (
    <PageFinanceDashboardCtx.Provider value={{ store, context }}>
      <div className="container">
        <div className="card">
          <div className={cs('card-loading', !context.loaded && 'active')} />

          <div className="card-header">
            <div className="card-title h5">Order overzicht</div>
          </div>
          <div className="card-body">
            <Search />
          </div>
        </div>
      </div>
    </PageFinanceDashboardCtx.Provider>
  );
}

// region Components

function Search() {
  const { context, store } = React.useContext(PageFinanceDashboardCtx);

  const initial = {};
  const query_is_empty = store.queryIsEmpty(['search']);

  return (
    <Formik
      initialValues={initial}
      enableReinitialize={true}
      onSubmit={async (values, fa) => {
        await store.search(values);
        fa.setSubmitting(false);
      }}
      render={fp => (
        <Form style={{ padding: '0 1rem' }}>
          <div className={cs('form-group')}>
            <div className="columns">
              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="button"
                  className="btn btn-error-outline btn-action s-circle tooltip tooltip-right"
                  data-tooltip="Leeg alle zoekvelden."
                  disabled={!context.loaded}
                  onClick={async () => {
                    await store.resetQuery();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="column col-3 col-xs-12  pr-2">
                <label className="form-label">Verstiging(en)</label>
                <SpectreAutocomplete
                  name="building_set"
                  url="/core/plan/building/autocomplete"
                  fp={fp}
                  multiple
                  allowEmptySearch
                />
              </div>

              <div className="column col-3 col-xs-12  pr-2">
                <label className="form-label">Deelnemer</label>
                <SpectreAutocomplete
                  name="tenant_set"
                  url="/core/contact/tenant/autocomplete"
                  fp={fp}
                  multiple
                  render={record => {
                    return (
                      <div>
                        ({record.value}) <strong>{record.title}</strong>
                      </div>
                    );
                  }}
                />
              </div>

              <div className="column col-2 col-xs-12  pr-2">
                <FormGroup name="start" title="Startdatum" errors={fp.errors}>
                  <SpectreCalendar name="start" fp={fp} />
                </FormGroup>
              </div>
              <div className="column col-2 col-xs-12  pr-2">
                <FormGroup name="end" title="Einddatum" errors={fp.errors}>
                  <SpectreCalendar name="end" fp={fp} />
                </FormGroup>
              </div>

              <div className="column" />

              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="submit"
                  className="btn btn-primary-outline btn-action s-circle"
                  disabled={fp.isSubmitting || !context.loaded}
                >
                  <i className={cs('fal fa-fw', !context.loaded ? 'fa-spin fa-spinner-third' : 'fa-search')} />
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
}

// endregion

// region Store

class PageFinanceDashboardStore extends BaseStore<PageFinanceDashboardContext> {
  setup() {
    return {
      loaded: false,
      query: PageFinanceDashboardStore.emptyQuery(),
    };
  }

  load() {
    this.next(draft => {
      draft.loaded = true;
    });
  }

  async search(values: Partial<PageFinanceDashboardQuery>) {
    this.next(draft => {
      _.keys(draft.query).forEach(key => {
        const value = values[key];
        if (value !== undefined) {
          draft.query[key] = value;
        }
      });
    });

    await this.load();
  }

  async resetQuery() {
    this.next(draft => {
      draft.query = PageFinanceDashboardStore.emptyQuery();
    });

    await this.load();
  }

  queryIsEmpty(extra_ignore?: string[]) {
    const ignore = _.union(['page', 'per_page'], extra_ignore);
    const empty = PageFinanceDashboardStore.emptyQuery();

    return _.pairs(this.current().query)
      .filter(([k, v]) => !_.contains(ignore, k))
      .every(([k, v]) => _.isEqual(v, empty[k]));
  }

  static emptyQuery(): PageFinanceDashboardQuery {
    return {};
  }
}

export function usePageFinanceDashboardRxjs(): PageFinanceDashboardBasicProps {
  const { store, context } = useRxjsStore<PageFinanceDashboardContext, PageFinanceDashboardStore>(
    PageFinanceDashboardStore,
    null,
    () => {
      store.load();
    },
  );

  return { store, context };
}

// endregion
