import * as React from 'react';
import { loadManyRecordsOr } from '../../../utils';
import produce from 'immer';
import { http } from '../../../http';
import * as _ from 'underscore';

type Props = {
  job?: ModelVacancyJob;
  applicant: ModelVacancyApplicant;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalJobApplicantLinkTo({ job, applicant, closeModal }: Props) {
  const [jobs, setJobs] = React.useState<ModelVacancyJob[]>([]);
  const [locations, setLocations] = React.useState<ModelVacancyLocation[]>([]);
  const [departments, setDepartments] = React.useState<ModelVacancyDepartment[]>([]);
  const [loaded, setLoaded] = React.useState(false);
  const [checked_set, setCheckedSet] = React.useState<number[]>([]);

  React.useEffect(() => {
    (async () => {
      setJobs((await loadManyRecordsOr('/core/vacancy/job/many')).filter(x => !job || x.id !== job.id));
      setLocations(await loadManyRecordsOr('/core/vacancy/location/many'));
      setDepartments(await loadManyRecordsOr('/core/vacancy/department/many'));
      setLoaded(true);
    })();
  }, []);

  async function onSubmit() {
    await http().post('/core/vacancy/applicant/link_to', {
      applicant: applicant.id,
      jobs: checked_set,
    });

    closeModal({ reload: true });
  }

  function onChange(checked: boolean, job_id: number) {
    return () => {
      setCheckedSet(
        produce(checked_set, draft => {
          if (checked) {
            draft.splice(draft.findIndex(x => x === job_id), 1);
          } else {
            draft.push(job_id);
          }
        }),
      );
    };
  }

  return (
    <div className="modal modal-sm active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">
            Sollicitatie van <strong>{applicant.name}</strong> linken
          </div>
        </div>

        <div className="modal-body">
          <p>
            Je kunt, door een of meerdere vacatures hieronder aan te vinken, het profiel koppelen. De applicatie maakt
            geen kopië, maar koppelt de sollicitant aan de andere vacatures. Je zal de sollicitant terugvinden onder de
            wachtlijst status.
          </p>

          {loaded &&
            jobs.map(job => {
              const checked = _.contains(checked_set, job.id);
              const location = job.location_id && locations.find(x => x.id === job.location_id);
              const department = job.department_id && departments.find(x => x.id === job.department_id);

              return (
                <div key={job.id}>
                  <label className="form-checkbox">
                    <input type="checkbox" checked={checked} onChange={onChange(checked, job.id)} />
                    <i className="form-icon" />

                    <div>
                      <div>
                        <strong>{job.title}</strong>
                      </div>
                      {(department || location) && (
                        <div>
                          <small>
                            {department && department.name}
                            {department && location && ' in '}
                            {location && location.name}
                          </small>
                        </div>
                      )}
                    </div>
                  </label>
                </div>
              );
            })}
        </div>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                <i className="fal fa-times" /> Annuleren
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-primary" onClick={onSubmit}>
                <i className="fal fa-save" /> Opslaan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
