import * as React from 'react';

type Props = {
  isLarge: boolean;
};

export default function ComponentColgroup({isLarge}: Props) {
  return (
    <colgroup>
      <col width={38} />
      {!isLarge && <col width={64} />}
      {!isLarge && <col width={40} />}
      <col />
      {!isLarge && <col width={100} />}
      <col width={isLarge ? 70 : 112} />
    </colgroup>
  );
}
