import ModalOrderDownloadPdf from '../models/ModalOrderDownloadPdf';
import ModalOrderPrintTicket from '../models/ModalOrderPrintTicket';
import ModalOrderProcessed from '../models/ModalOrderProcessed';
import ModalOrderReady from '../models/ModalOrderReady';
import ModalOrderDelivery from '../models/ModalOrderDelivery';
import ModalOrderDelivered from '../models/ModalOrderDelivered';
import ModalOrderFinalPayment from '../models/ModalOrderFinalPayment';
import ModalOrderMailPdf from '../models/ModalOrderMailPdf';
import ModalOrderMailRequestPayment from '../models/ModalOrderMailRequestPayment';
import ModalOrderCustomerUpdate from '../models/ModalOrderCustomerUpdate';
import { http } from '../../../http';
import ModalOrderAddressUpdate from '../models/ModalOrderAddressUpdate';
import * as React from 'react';
import { Context, Store } from '../generic/PageOrderGenericOne';
import ModalOrderPaymentByBank from '../models/ModalOrderPaymentByBank';
import ModalOrderAnnul from '../models/ModalOrderAnnul';
import ModalOrderAdd from '../models/ModalOrderAdd';
import ModalOrderUpdate from '../models/ModalOrderUpdate';
import ModalOrderCancel from '../models/ModalOrderCancel';
import ModalPaymentSale from '../models/ModalPaymentSale';
import ModalPaymentRefund from '../models/ModalPaymentRefund';
import ModalOrderCompensation from '../models/ModalOrderCompensation';
import ModalOrderOpen from '../models/ModalOrderOpen';
import ModalPaymentDelete from '../models/ModalPaymentDelete';

export default function ComponentOrderPurchaseModals({ context, store }: { context: Context; store: Store }) {
  function closeAndReload(success?: boolean) {
    store.closeModal();
    if (success) {
      store.load();
    }
  }

  return (
    <>
      {context.modal && context.modal.which === 'download-pdf' && (
        <ModalOrderDownloadPdf purchase={context.purchase} close={closeAndReload} />
      )}
      {context.modal && context.modal.which === 'print-ticket' && (
        <ModalOrderPrintTicket cart_id={context.modal.data.cart_id} close={closeAndReload} />
      )}

      {context.modal && context.modal.which === 'add' && (
        <ModalOrderAdd context={context} store={store} close={closeAndReload} />
      )}
      {context.modal && context.modal.which === 'update' && (
        <ModalOrderUpdate
          cart_line_id={context.modal.data.cart_line_id}
          context={context}
          store={store}
          close={closeAndReload}
        />
      )}
      {context.modal && context.modal.which === 'compensation' && (
        <ModalOrderCompensation
          cart_line_id={context.modal.data.cart_line_id}
          context={context}
          store={store}
          close={closeAndReload}
        />
      )}

      {context.modal && context.modal.which === 'annul' && (
        <ModalOrderAnnul purchases_per_tenant={context.modal.data.purchases_per_tenant} close={closeAndReload} />
      )}
      {context.modal && context.modal.which === 'cancel' && (
        <ModalOrderCancel purchases_per_tenant={context.modal.data.purchases_per_tenant} close={closeAndReload} />
      )}
      {context.modal && context.modal.which === 'open' && (
        <ModalOrderOpen purchases_per_tenant={context.modal.data.purchases_per_tenant} close={closeAndReload} />
      )}
      {context.modal && context.modal.which === 'processed' && (
        <ModalOrderProcessed purchases_per_tenant={context.modal.data.purchases_per_tenant} close={closeAndReload} />
      )}
      {context.modal && context.modal.which === 'ready' && (
        <ModalOrderReady purchases_per_tenant={context.modal.data.purchases_per_tenant} close={closeAndReload} />
      )}
      {context.modal && context.modal.which === 'delivery' && (
        <ModalOrderDelivery purchases_per_tenant={context.modal.data.purchases_per_tenant} close={closeAndReload} />
      )}
      {context.modal && context.modal.which === 'delivered' && (
        <ModalOrderDelivered purchases_per_tenant={context.modal.data.purchases_per_tenant} close={closeAndReload} />
      )}

      {context.modal && context.modal.which === 'payment-sale' && (
        <ModalPaymentSale purchase={context.modal.data.purchase} close={closeAndReload} />
      )}
      {context.modal && context.modal.which === 'payment-refund' && (
        <ModalPaymentRefund purchase={context.modal.data.purchase} close={closeAndReload} />
      )}
      {context.modal && context.modal.which === 'payment-delete' && (
        <ModalPaymentDelete payment={context.modal.data.payment} close={closeAndReload} />
      )}

      {context.modal && context.modal.which === 'final-payment' && (
        <ModalOrderFinalPayment cart_id={context.purchase.cart_id} close={closeAndReload} />
      )}
      {context.modal && context.modal.which === 'payment-by-bank' && (
        <ModalOrderPaymentByBank context={context} close={closeAndReload} />
      )}

      {context.modal && context.modal.which === 'mail-pdf' && (
        <ModalOrderMailPdf purchase={context.purchase} close={closeAndReload} />
      )}
      {context.modal && context.modal.which === 'mail-request-payment' && (
        <ModalOrderMailRequestPayment purchase={context.purchase} close={closeAndReload} />
      )}

      {context.modal && context.modal.which === 'customer-update' && (
        <ModalOrderCustomerUpdate
          customer={context.purchase.customer}
          close={closeAndReload}
          update={async values => {
            try {
              await http().post('/core/order/purchase/customer_update', {
                purchase: context.purchase.id,
                ...values,
              });
              store.closeModal();
              return true;
            } catch (e) {
              console.error(e);
              return false;
            }
          }}
        />
      )}

      {context.modal && context.modal.which === 'address-delivery-update' && (
        <ModalOrderAddressUpdate
          customer={context.purchase.customer}
          address={context.purchase.address_delivery}
          close={closeAndReload}
          update={async values => {
            try {
              await http().post('/core/order/purchase/address_delivery_update', {
                purchase: context.purchase.id,
                ...values,
              });
              store.closeModal();
              return true;
            } catch (e) {
              console.error(e);
              return false;
            }
          }}
        />
      )}

      {context.modal && context.modal.which === 'address-invoice-update' && (
        <ModalOrderAddressUpdate
          customer={context.purchase.customer}
          address={context.purchase.address_invoice}
          close={closeAndReload}
          update={async values => {
            try {
              await http().post('/core/order/purchase/address_invoice_update', {
                purchase: context.purchase.id,
                ...values,
              });
              store.closeModal();
              return true;
            } catch (e) {
              console.error(e);
              return false;
            }
          }}
        />
      )}
    </>
  );
}
