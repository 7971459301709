import * as React from 'react';
import { http } from '../../../http';
import { MessageService } from '../../../services/MessageService';
import { cs, formatDate, formatMomentAgo, parseMoment } from '../../../utils';
import * as moment from 'moment';

type Props = {
  purchase: ModelOrderPurchase;
  close: (success?: boolean) => void;
};

export default function ModalOrderMailPdf(props: Props) {
  const [loading, setLoading] = React.useState(false);
  const [mails, setMails] = React.useState<ModelGenericMailSent[]>([]);

  React.useEffect(() => {
    (async () => {
      const res = await http().post('/core/order/purchase/mails_sent', {
        purchase: props.purchase.id,
        templates: ['shop_cart_pdf.html'],
      });
      setMails(res.data.records);
    })();
  }, []);

  async function submit() {
    setLoading(true);

    try {
      await http().post('/core/order/purchase/mail_pdf', { purchase: props.purchase.id });

      MessageService.setMessage('De mail zal binnen vijf minuten worden verstuurd naar de klant!', 'success');

      props.close(true);
    } catch (exc) {
      console.error(exc);
      setLoading(false);
    }
  }

  return (
    <div className="modal active" id="modal-id">
      <div className="modal-overlay" />

      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Verstuur order bevestiging</div>
        </div>

        <div className="modal-body">
          <p>Verstuur (opnieuw) de order PDF naar het e-mailadres van de klant.</p>
        </div>

        <table className="table  table-hover">
          <thead>
            <tr>
              <th>Onderwerp</th>
              <th>Moment</th>
              <th>Naar</th>
              <th>Van</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {mails.map(mail => {
              const days_since = moment()
                .startOf('day')
                .diff(parseMoment(mail.created_at).startOf('day'), 'days');
              const has_warning = days_since <= 7;

              return (
                <tr className={cs(has_warning && 'bg-warning')} key={mail.id}>
                  <td>{mail.subject}</td>
                  <td className="has-table-shrink-nowrap">
                    {formatDate(mail.created_at, 'L HH:mm')}
                    <br />
                    {formatMomentAgo(mail.created_at)}
                  </td>
                  <td className="has-table-shrink-nowrap">
                    <a href={mail.to_email}>{mail.to_name}</a>
                  </td>
                  <td className="has-table-shrink-nowrap">
                    <a href={mail.from_email}>{mail.from_name}</a>
                  </td>
                  <td className="has-table-shrink-nowrap">
                    <a href={`/generic/mail/one?id=${mail.id}`} target="_blank">
                      <i className="fal fa-search" />
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-dark-outline" onClick={() => props.close()} disabled={loading}>
                <i className="fal fa-times" /> Annuleren
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-primary" onClick={submit} disabled={loading}>
                <i className="fal fa-envelope" /> Versturen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
