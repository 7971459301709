import * as React from 'react';
import Render from './Render';

export default function PageDocMvIntroduction() {
  return (
    <div>
      <Render
        markdown={markdown}
        prepend={
          <div style={{ marginBottom: '2em' }}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/-MIteyC8v3I"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        }
      />
    </div>
  );
}

const markdown = `

# Visie & Missie

## De wereld volgens Loods 5

Een huis is meer dan vier muren en een dak. Iemands huis en interieur zijn een afspiegeling van de _lifestyle_
van die persoon. Het laat originaliteit en authenticiteit zien. Je leert iemand beter kennen wanneer je een
kijkje hebt mogen nemen bij hem of haar in huis. Een huis moet een plek zijn waarin je je prettig en veilig
voelt. Oftewel thuis. Jouw thuis. Zelf samengesteld. Volgens jouw keuze. Bij Loods 5 geloven we hier al jaren in. 

Samen met onze klanten, deelnemers en medewerkers creëren we plekken om te ontspannen, te werken, lief te hebben,
te leven en samen te komen. Bij Loods 5 vind je talloze verschillende woonstijlen. Stijlen voor elke smaak en elk
budget. In de winkels en online kun je met hetzelfde gemak terecht voor een breed aanbod bijzondere, maar ook
gangbare producten. Met een overdaad aan **inspiratie((. Hierdoor zijn we altijd _on point_ en _on trend_. Door middel
van een prettige, ongedwongen en unieke winkelervaring begint bij ons het gevoel van thuis al voordat je een
product in huis hebt. 

Loods 5 is niet bang om te experimenteren en door ons **ondernemende** karakter weten we wat onze deelnemers en
klanten willen. De mensen die bij ons werken barsten van de goede ideeën & innovatieve plannen en krijgen de
ruimte om deze te realiseren. We geloven in een **persoonlijke** aanpak en vinden het belangrijk dat onze mensen
een prettige omgeving hebben om in te werken. Een omgeving waarin iedereen met respect en plezier met elkaar
omgaat. Men zichzelf kan zijn en die keuze juist omarmd wordt. We vinden het van belang dat deze waarden ook
overkomen op onze klanten en deelnemers. Transparantie, waardering en het vieren van successen zijn hierbij
onmisbaar. 

Loods 5 is **eigenzinnig** in alles wat we doen. Hier zijn we trots op en willen dit nog jaren voortzetten. De
balans tussen het assortiment, medewerkers en de uitstraling van Loods 5 is essentieel om onze bijzondere
winkelervaring te kunnen blijven bieden. We beseffen ons dat we hierbij **bewustere** keuzes moeten maken.
Keuzes die niet overhaast worden gemaakt, maar getoetst aan de kernwaarden van Loods 5, onze deelnemers en klanten.

Zodat iedereen - door te shoppen bij Loods 5 - zijn of haar eigen originele woonstijl kan laten zien.

\`#orignalshopping\` \`#omarmoriginaliteit\` \`#loods5inhuis\`

## Loods 5 Kernwaarden

_We hebben er voor gekozen om onze kernwaarden te verdelen in bestaande waarden (DNA) en waarden die **ambitie**
uitdragen. De bestaande waarden gaan al jaren mee en zijn de basis voor wat Loods 5 is. De ambitie-waarden
zijn waarden waar we aan werken. Deze kunnen in de toekomst onderdeel worden van het vaste DNA, maar ook
uitgewisseld voor nieuwe waarden._

### DNA

**Eigenzinnig** > Loods 5 is origineel, gedreven en creatief. We volgen niet de massa, maken onze eigen keuzes
en doen dingen op onze manier. 

**Inspirerend** > Inspireren is een van onze sterkste eigenschappen. We zijn divers in ons assortiment, medewerkers
en de manier waarop we communiceren.

**Ondernemend** > Loods 5 is proactief, zelfstandig en verantwoordelijkheid. We zeggen wat we doen en doen wat we
zeggen. We worden enthousiast van nieuwe dingen.

### AMBITIES

**Persoonlijk** > Loods 5 gelooft in een persoonlijke aanpak. We hebben aandacht en respect voor onszelf, elkaar,
onze deelnemers, klanten en producten. Loods 5 vindt het belangrijk dat iedereen zich welkom, veilig, gehoord
en gewaardeerd voelt. Ongeacht culturele achtergrond, geslacht, leeftijd, godsdienst of seksuele voorkeur. We
zijn transparant, eerlijk en er is ruimte voor ideeën, groei en (persoonlijke) ontwikkeling. Wij maken plezier
in ons werk met collega’s, deelnemers en klanten. Wij staan voor vriendelijkheid en voelen ons thuis in een
familiaire omgeving. We geven om elkaar en het succes van het bedrijf en vieren dit.

**Bewust** > Loods 5 streeft naar een bewuste aanpak. Niet meer zomaar doen, maar met aandacht, een kritisch oog
en de juiste kennis. Dit vragen we van de mensen die bij ons werken en verwachten we van de partners waar mee
we samenwerken. We weten waar we mee bezig zijn en volgen de juiste processen. Elk onderdeel van de organisatie
moet een positief effect hebben op onze medewerkers, deelnemers en klanten. Duurzaamheid, service en kwaliteit
worden hierdoor getoetst en beter op de kaart gezet. 


`;
