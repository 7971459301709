import * as React from 'react';
import PaneIdle from './PaneIdle';
import PaneCart from './PaneCart';
import { Container, GlobalStyles } from './Components';
import { BaseStore, useRxjsStore } from '../../effects';
import { http } from '../../http';
import Modals from './Modals';
import { parseMessage, SocketProvider, useSocket } from '../../lib/WebSocket';
import * as _ from 'underscore';
import PaneThanks from './PaneThanks';

export const PageDisplayCtx = React.createContext<PageDisplayBasicProps>(null);

export default function PageDisplay() {
  const { store, context } = usePageDisplayRxjs();

  return (
    <PageDisplayCtx.Provider value={{ store, context }}>
      <SocketProvider url={GLOBAL_WSS_URL}>
        <Page />
      </SocketProvider>
    </PageDisplayCtx.Provider>
  );
}

type Pointer = {
  cart_id: number;
  iteration: number;
};

type PageDisplayContext = {
  pane: 'idle' | 'cart' | 'payment' | 'thanks';
  cart_id: number;

  loading: boolean;
  iteration: number;

  cart: ModelShopCart;
  customer: ModelShopCustomer;
  lines: ModelShopCartLine[];
  giftcards_payment: ModelShopCartGiftcardPayment[];
  giftcards_purchase: ModelShopCartGiftcardPurchase[];

  modal?: PageDisplayModalContext;
};

function Page() {
  const ggd = window.GLOBAL_GENERIC_DATA;
  const group_id = ggd && ggd.device && ggd.device.group && ggd.device.group.id;

  const { context, store } = React.useContext(PageDisplayCtx);
  const { socket, readyState } = useSocket((e: MessageEvent) => {
    const { action, payload } = parseMessage(e);

    if (action === 'display.update') {
      display_update(payload);
    }
  });

  async function display_update(payload) {
    const { pane, cart_id } = payload;

    if (pane === 'idle') {
      await store.load(null);
    } else {
      await store.setPane(pane);
      await store.load(cart_id);
    }
  }

  // Tell the server we have connected.
  React.useEffect(() => {
    if (readyState === WebSocket.OPEN && group_id) {
      socket.send(
        JSON.stringify({
          action: 'connect',
          payload: {
            group_id: group_id,
            mode: 'display',
          },
        }),
      );
    }
  }, [readyState, group_id]);

  React.useEffect(() => {
    if (context && context.pane === 'cart') {
      let breakout = 3600;

      const timeout = setInterval(() => {
        breakout -= 1;

        if (breakout <= 0) {
          store.load(null);
        }
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [context && context.pane === 'cart', context && context.iteration]);

  if (!store || !context) return null;

  return (
    <Container>
      <GlobalStyles />

      <PaneIdle loaded={context.cart_id !== null} />

      {context.cart && _.contains(['cart', 'payment'], context.pane) && <PaneCart />}
      {context.cart && context.pane == 'thanks' && <PaneThanks />}

      <Modals />
    </Container>
  );
}

// region Store

function emptyContext(): PageDisplayContext {
  return {
    pane: 'idle',

    cart_id: null,

    loading: false,
    iteration: 0,

    cart: null,
    customer: null,
    lines: [],
    giftcards_payment: [],
    giftcards_purchase: [],

    modal: null,
  };
}

class PageDisplayStore extends BaseStore<PageDisplayContext> {
  setup(): PageDisplayContext {
    return emptyContext();
  }

  setPane(value: 'idle' | 'cart' | 'payment' | 'thanks') {
    this.next(draft => {
      draft.pane = value;
    });
  }

  async load(cart_id: number | null | undefined) {
    const ggd = window.GLOBAL_GENERIC_DATA;

    if (cart_id === null) {
      this.next(draft => {
        return emptyContext();
      });

      return;
    }

    if (cart_id === undefined) {
      cart_id = this.current().cart_id;
    }

    this.next(draft => {
      draft.loading = true;
      draft.cart_id = cart_id;
    });

    const res = await http().post('/sale/cart/one', {
      cart: cart_id,
      computer: ggd.device.me.id,
      group: ggd.device.group.id,
    });

    this.next(draft => {
      draft.loading = false;

      if (draft.pane === 'idle') {
        draft.pane = 'cart';
      }

      draft.iteration += 1;

      draft.cart = res.data.cart;
      draft.customer = res.data.customer;
      draft.lines = res.data.lines;
      draft.giftcards_payment = res.data.giftcards_payment;
      draft.giftcards_purchase = res.data.giftcards_purchase;
    });
  }

  // region Modal

  openModal(ctx: PageDisplayModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }

  closeModal() {
    this.next(draft => {
      draft.modal = null;
    });
    this.load(undefined);
  }

  // endregion
}

export type PageDisplayBasicProps = {
  store: PageDisplayStore;
  context: PageDisplayContext;
};

export type PageDisplayModalContext =
  | {
      which: 'email';
      data?: null;
    }
  | {
      which: 'newsletter';
      data?: null;
    };

export function usePageDisplayRxjs(): PageDisplayBasicProps {
  const { store, context } = useRxjsStore<PageDisplayContext, PageDisplayStore>(PageDisplayStore);

  return { store, context };
}

// endregion
