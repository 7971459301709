import Service from './Service';
import { http, httpLive } from '../http';
import { detect } from 'detect-browser';
import { LocationService } from './LocationService';

export type GenericDataState =
  | {
      loaded: false;
      data: null;
    }
  | {
      loaded: true;
      data: GLOBAL_GENERIC_DATA;
    };

function initialState(): GenericDataState {
  return {
    loaded: false,
    data: null,
  };
}

async function load(): Promise<GenericDataState> {
  let res;

  try {
    res = await http().post('/core/generic/cache');
  } catch (exc) {
    console.error('Loading /core/generic/cache', exc);
    res = null;
  }

  const host = res && res.data.host;
  const rights = res && res.data.rights;
  const workflows = res && res.data.workflows;
  const tenants = res && res.data.tenants;
  const buildings = res && res.data.buildings;
  const countries = res && res.data.countries;

  try {
    res = await httpLive().post('/devices/group', null, {
      timeout: 1000,
    });
  } catch (exc) {
    console.error('Loading /devices/group', exc);
    res = null;
  }

  const device = res && res.data;

  const browser = detect();

  const data: GLOBAL_GENERIC_DATA = (window.GLOBAL_GENERIC_DATA = {
    browser,
    host,
    rights,
    workflows,
    tenants,
    buildings,
    countries,
    device,
  });

  if (GLOBAL_ENV !== 'dev') {
    if (data.device && data.device.me && data.device.group.display_id == data.device.me.id) {
      if (!LocationService.current().pathname.startsWith('/display')) {
        LocationService.navigate('/display');
      }
    }
  }

  return {
    loaded: true,
    data,
  };
}

export const GenericDataService = (() => {
  const { current, next, useEffect } = Service<GenericDataState>('GenericDataService', initialState);

  return {
    current,
    next,
    useEffect,

    load: async () => {
      next(await load());
    },
  };
})();
