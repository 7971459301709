import * as React from 'react';

export default function ComponentOrderPurchaseLineState({ state }: { state: string }) {
  if (state === 'open')
    return (
      <span className="text-primary">
        <i className="fal fa-fw  fa-star" /> Nieuw
      </span>
    );
  if (state === 'processed')
    return (
      <span className="text-dark">
        <i className="fal fa-fw fa-user-hard-hat" /> Verwerkt
      </span>
    );
  if (state === 'ready')
    return (
      <span className="text-dark">
        <i className="fal fa-fw fa-archive" /> Gereed
      </span>
    );
  if (state === 'delivery')
    return (
      <span className="text-dark">
        <i className="fal fa-fw fa-shipping-fast" /> Onderweg
      </span>
    );
  if (state === 'delivered')
    return (
      <span className="text-success">
        <i className="fal fa-fw  fa-check" /> Afgerond
      </span>
    );
  if (state === 'cancelled')
    return (
      <span className="text-error">
        <i className="fal fa-fw  fa-sync-alt" /> Geretourneerd
      </span>
    );
  if (state === 'annulled')
    return (
      <span className="text-error">
        <i className="fal fa-fw  fa-ban" /> Geannuleerd
      </span>
    );

  return null;
}
