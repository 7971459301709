import { useMemo } from 'react';
import * as React from 'react';
import { calculateCashSaleLineInclusive, getCashSaleLineSchema } from '../../cash/sale/CashSaleLineSchema';
import { Context, Store } from '../generic/PageOrderGenericOne';
import { cs, formatCents, formatDate, formatMomentAgo, getTouchedErrors, parseCents } from '../../../utils';
import { http } from '../../../http';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormRadio from '../../../components/FormRadio';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import { displayError } from '../../../components/FormGroup';
import moment = require('moment');

type Props = {
  cart_line_id: number;
  context: Context;
  store: Store;
  close: (success: boolean) => void;
};

export default function ModalOrderUpdate({ cart_line_id, context, store, close }: Props) {
  const [state, setState] = React.useState<{ line: ModelShopCartLine; article: ModelShopArticle }>({
    line: null,
    article: null,
  });

  React.useEffect(() => {
    (async () => {
      const res_line = await http().post('/core/shop/cart/line', { id: cart_line_id });
      const line = res_line.data.line;

      const res_article = await http().post('/core/shop/article/one', { id: line.article_id });
      const article = res_article.data;

      setState({ line, article });
    })();
  }, []);

  const { line, article } = state;

  const schema = useMemo(
    () =>
      getCashSaleLineSchema(article?.inclusive || 0, [
        'amount',
        'deposit_percentage',
        'delivery_min_at',
        'delivery_max_at',
      ]),
    [article?.inclusive],
  );

  if (!line || !article) {
    return null;
  }

  const transfer_options = article.type.transfer_options;
  const transfer_options_translations = {
    carry: 'Direct meenemen',
    delivery: 'Thuisbezorgen',
    'direct-pickup': 'Direct afhalen',
    pickup: 'Afhalen in winkel',
  };

  return (
    <div className="modal active" id="ModalOrderUpdate">
      <div className="modal-overlay" />

      <div className="modal-container">
        <Formik
          initialValues={{
            transfer: line.transfer,
            configuration: line.configuration,
            manual_discount_value: formatCents(line.manual_discount_value, true),
            manual_discount_type: line.manual_discount_type,
            manual_discount_description: line.manual_discount_description,
            manual_increase_value: formatCents(line.manual_increase_price, true),
            manual_increase_type: line.manual_increase_type,
            delivery_min_at: formatDate(line.delivery_min_at || moment().add(2, 'weeks')),
            delivery_max_at: formatDate(line.delivery_max_at || moment().add(3, 'weeks')),
          }}
          validationSchema={schema}
          onSubmit={async (values, actions) => {
            try {
              const res = await http().post('/core/order/purchase/update_line', {
                purchase: context.purchase.id,
                line: cart_line_id,

                transfer: values.transfer,
                configuration: values.configuration,
                manual_discount_type: values.manual_discount_type,
                manual_discount_value: parseCents(values.manual_discount_value),
                manual_discount_description: values.manual_discount_description,
                manual_increase_type: values.manual_increase_type,
                manual_increase_value: parseCents(values.manual_increase_value),

                delivery_min_at: formatDate(values.delivery_min_at, 'YYYY-MM-DD'),
                delivery_max_at: formatDate(values.delivery_max_at, 'YYYY-MM-DD'),
              });

              close(true);
            } catch (exc) {
              console.error(exc);
              actions.setSubmitting(false);
            }
          }}
          render={fp => {
            const errors = getTouchedErrors(fp.touched, fp.errors);

            const calculation = calculateCashSaleLineInclusive(
              article.inclusive,
              fp.values.manual_increase_type,
              fp.values.manual_increase_value,
              fp.values.manual_discount_type,
              fp.values.manual_discount_value,
            );
            const has_manual_discount = parseCents(fp.values.manual_discount_value) !== 0;

            return (
              <Form>
                <div className="modal-header">
                  <div className="modal-title h5">Artikel {article.name} aanpassen</div>
                </div>

                <div className="modal-body">
                  <div className="form-horizontal p-0">
                    {transfer_options.length > 1 && (
                      <div className={cs('form-group', errors.transfer && 'has-error')}>
                        <div className="column col-3 col-sm-12">
                          <label className="form-label">Levering</label>
                        </div>
                        <div className="column col-9 col-sm-12">
                          <FormRadio
                            name="transfer"
                            items={transfer_options
                              .filter(v => ['delivery', 'direct-pickup', 'pickup'].indexOf(v) !== -1)
                              .map(v => ({ value: v, title: transfer_options_translations[v] }))}
                          />
                        </div>
                      </div>
                    )}

                    <div className={cs('form-group', errors.configuration && 'has-error')}>
                      <div className="column col-3 col-sm-12">
                        <label className="form-label">Configuratie</label>
                      </div>
                      <div className="column col-9 col-sm-12">
                        <FormInput component="textarea" name="configuration" />
                      </div>
                    </div>
                    <div className={cs('form-group', errors.manual_increase_value && 'has-error')}>
                      <div className="column col-3 col-sm-12">
                        <label className="form-label">Opslag</label>
                      </div>
                      <div className="column col-3 col-sm-12">
                        <FormSelect name="manual_increase_type">
                          <option value="percentage">Percentage</option>
                          <option value="fixed">Vast bedrag</option>
                        </FormSelect>
                      </div>
                      <div className="column col-3 col-sm-12  text-right">
                        <FormInput name="manual_increase_value" classNames={['text-right']} />
                        {errors.manual_increase_value && (
                          <div className="form-input-hint">{displayError(errors.manual_increase_value)}</div>
                        )}
                      </div>
                      <div className="column col-3  ">
                        <div className="form-input static  text-right">
                          {formatCents(calculation.increase_inclusive)}
                        </div>
                      </div>
                    </div>
                    <div className={cs('form-group', errors.manual_discount_value && 'has-error')}>
                      <div className="column col-3 col-sm-12">
                        <label className="form-label">Korting</label>
                      </div>
                      <div className="column col-3 col-sm-12">
                        <FormSelect name="manual_discount_type">
                          <option value="percentage">Percentage</option>
                          <option value="fixed">Vast bedrag</option>
                        </FormSelect>
                      </div>
                      <div className="column col-3 col-sm-12  text-right">
                        <FormInput name="manual_discount_value" classNames={['text-right']} />
                        {errors.manual_discount_value && (
                          <div className="form-input-hint">{displayError(errors.manual_discount_value)}</div>
                        )}
                      </div>
                      <div className="column col-3  ">
                        <div className="form-input static  text-right">
                          {formatCents(calculation.discount_inclusive)}
                        </div>
                      </div>
                    </div>
                    {has_manual_discount && (
                      <div className={cs('form-group', errors.manual_discount_description && 'has-error')}>
                        <div className="column col-3 col-sm-12">
                          <label className="form-label">Reden van korting</label>
                        </div>
                        <div className="column col-9 col-sm-12">
                          <FormInput component="textarea" name="manual_discount_description" />
                          {errors.manual_discount_description && (
                            <div className="form-input-hint">{displayError(errors.manual_discount_description)}</div>
                          )}
                        </div>
                      </div>
                    )}

                    {',delivery,pickup,'.indexOf(fp.values.transfer) !== -1 && (
                      <div
                        className={cs('form-group', (errors.delivery_min_at || errors.delivery_max_at) && 'has-error')}
                      >
                        <div className="column col-3 col-sm-12">
                          <label className="form-label">Levertijd</label>
                        </div>
                        <div className="column col-3 col-sm-12">
                          <FormInput name="delivery_min_at" classNames={['text-right']} />
                          {errors.delivery_min_at && (
                            <div className="form-input-hint">{displayError(errors.delivery_min_at)}</div>
                          )}
                          <div className="form-input-hint">
                            vroegst na: {formatMomentAgo(fp.values.delivery_min_at)}
                          </div>
                        </div>
                        <div className="column col-3 col-sm-12  text-right">
                          <FormInput name="delivery_max_at" classNames={['text-right']} />
                          {errors.delivery_max_at && (
                            <div className="form-input-hint">{displayError(errors.delivery_max_at)}</div>
                          )}
                          <div className="form-input-hint">
                            uiterlijk op: {formatMomentAgo(fp.values.delivery_max_at)}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="modal-body">
                  Het nieuwe totaalbedrag van deze orderregel is nu{' '}
                  <strong>{formatCents(calculation.discount_inclusive * line.amount)}</strong>.
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button
                        type="button"
                        className="btn btn-dark-outline"
                        onClick={() => close(false)}
                        disabled={fp.isSubmitting}
                      >
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={fp.isSubmitting || Object.keys(fp.errors).length > 0}
                      >
                        <i className="fal fa-paper-plane" /> Aanpassen
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </div>
    </div>
  );
}
