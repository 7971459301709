import * as React from 'react';
import { cs, formatDate, isWithinMz, loadManyRecordsPaginated } from '../../../../utils';
import SpectreCardHeader from '../../../../components/spectre/SpectreCardHeader';
import SpectreButton from '../../../../components/spectre/SpectreButton';
import * as _ from 'underscore';
import { Form, Formik } from 'formik';
import FormInput from '../../../../components/FormInput';

import { BaseStore, useRxjsStore } from '../../../../effects';

import { GenericDataService } from '../../../../services/GenericDataService';
import ModalTemplateCreate from './ModalTemplateCreate';
import { LocationService } from '../../../../services/LocationService';

// region Types

export type PageShopArticleStickerTemplateManyQuery = {
  page: number;
  per_page: number;

  search: string;
};

export type PageShopArticleStickerTemplateManyContext = {
  loaded: boolean;

  query: PageShopArticleStickerTemplateManyQuery;

  records: ModelShopStickerTemplate[];
  pagination?: Pagination;
  load_iteration: number;

  modal?: PageShopArticleStickerTemplateManyModalContext;
};

export type PageShopArticleStickerTemplateManyModalContext =
  | {
      which: 'create';
    }
  | {
      which: 'print';
      data: { template_id: number };
    };

export type PageShopArticleStickerTemplateManyModalFilterValues = {
  search: string;
};

// endregion

export const PageShopArticleStickerTemplateManyCtx = React.createContext<PageShopArticleStickerTemplateManyBasicProps>(
  null,
);

export default function PageShopArticleStickerTemplateMany() {
  const { store, context } = usePageShopArticleStickerTemplateManyRxjs();

  if (!store || !context) return null;

  return (
    <PageShopArticleStickerTemplateManyCtx.Provider value={{ store, context }}>
      <div className="container">
        <div className="card">
          <div className={cs('card-loading', !context.loaded && 'active')} />

          <SpectreCardHeader
            title="Sticker-templates"
            subtitle="Overzicht van alle beschikbare sticker-templates."
            buttons={[
              <SpectreButton
                icon="fa-plus"
                onClick={() => store.openModal({ which: 'create' })}
                tooltip={{ title: 'Maak een nieuwe sticker-template.', position: 'left' }}
              />,
            ]}
          />
          <div className="card-body">
            <Search />
          </div>
          <div className="card-body">
            {context.records.length !== 0 && (
              <div className="card-body">
                <Table />
              </div>
            )}
            {context.records.length === 0 && (
              <div className="card-body">
                <div className="empty">
                  <div className="empty-icon">
                    <i className="fal fa-shopping-cart  fa-3x" />
                  </div>
                  <p className="empty-title h5">Geen records</p>
                  <p className="empty-subtitle">
                    Er zijn geen records gevonden, leeg de zoek-parameters, of voeg eerst iets toe...
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <Modals />
      </div>
    </PageShopArticleStickerTemplateManyCtx.Provider>
  );
}

// region Components

function Modals() {
  const { context, store } = React.useContext(PageShopArticleStickerTemplateManyCtx);
  const mctx = context.modal;

  if (!mctx) return null;

  return (
    <React.Fragment>
      {mctx.which === 'create' && <ModalTemplateCreate onClose={() => store.closeModal()} />}
    </React.Fragment>
  );
}

function Search() {
  const { context, store } = React.useContext(PageShopArticleStickerTemplateManyCtx);

  const initial = {
    search: context.query.search,
  };
  const query_is_empty = store.queryIsEmpty(['search']);

  return (
    <Formik
      initialValues={initial}
      enableReinitialize={true}
      onSubmit={async (values, fa) => {
        await store.search(values);
        fa.setSubmitting(false);
      }}
      render={fp => (
        <Form style={{ padding: '0 1rem' }}>
          <div className={cs('form-group')}>
            <div className="columns">
              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="button"
                  className="btn btn-error-outline btn-action s-circle tooltip tooltip-right"
                  data-tooltip="Leeg alle zoekvelden."
                  disabled={!context.loaded}
                  onClick={async () => {
                    await store.resetQuery();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="column col-4 col-xs-12  pr-2">
                <label className="form-label">Zoeken</label>
                <FormInput name="search" />
              </div>

              <div className="column" />

              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="submit"
                  className="btn btn-primary-outline btn-action s-circle"
                  disabled={!context.loaded}
                >
                  <i className={cs('fal fa-fw', !context.loaded ? 'fa-spin fa-spinner-third' : 'fa-search')} />
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
}

function Table() {
  const generic = GenericDataService.useEffect('PageShipArticleStickerTemplateMany');
  const { context, store } = React.useContext(PageShopArticleStickerTemplateManyCtx);

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th className="has-table-shrink-nowrap">Nummer</th>
          <th className="has-table-shrink-nowrap">Aangemaakt</th>
          <th>Naam</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {context.records.map(template => {
          function onEdit() {
            LocationService.navigate('/shop/sticker/template/edit', { template_id: template.id.toString() });
          }
          function onPrint() {
            LocationService.navigate('/shop/sticker/template/publish', { template_id: template.id.toString() });
          }

          return (
            <tr key={template.id} onDoubleClick={onEdit}>
              <td className="has-table-shrink-nowrap">#{template.id}</td>
              <td className="has-table-shrink-nowrap">{formatDate(template.created_at, 'L LT')}</td>
              <td>{template.name}</td>

              <td className="has-table-shrink-nowrap">
                {isWithinMz() && (
                  <SpectreButton
                    classNames={['btn-default-outline btn-action s-circle mx-1']}
                    icon="fa-print"
                    onClick={onPrint}
                    tooltip={{
                      title: 'Afdrukken.',
                      position: 'left',
                    }}
                  />
                )}
                <SpectreButton
                  icon="fa-list-ul"
                  onClick={onEdit}
                  tooltip={{
                    title: 'Beheer de artikelen.',
                    position: 'left',
                  }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

// endregion
// region Store

function emptyQuery() {
  return {
    page: 0,
    per_page: 50,
    search: '',
  };
}

class PageShopArticleStickerTemplateManyStore extends BaseStore<PageShopArticleStickerTemplateManyContext> {
  setup(): PageShopArticleStickerTemplateManyContext {
    return {
      loaded: false,
      query: emptyQuery(),
      records: [],
      pagination: null,
      load_iteration: 0,
      modal: null,
    };
  }

  async load(partial: boolean = true) {
    this.next(draft => {
      draft.loaded = false;
    });

    const result = await loadManyRecordsPaginated('/core/shop/sticker/template/many', {
      ...this.current().query,
    });

    this.next(draft => {
      draft.loaded = true;

      draft.pagination = result.pagination;
      draft.records = result.records;
      draft.load_iteration += 1;
    });
  }

  async search(values: Partial<PageShopArticleStickerTemplateManyModalFilterValues>) {
    this.next(draft => {
      _.keys(draft.query).forEach(key => {
        const value = values[key];
        if (value !== undefined) {
          draft.query[key] = value;
        }
      });
    });

    await this.load();
  }

  async resetQuery() {
    this.next(draft => {
      draft.query = emptyQuery();
    });

    await this.load();
  }

  queryIsEmpty(extra_ignore?: string[]) {
    const ignore = _.union(['page', 'per_page'], extra_ignore);
    const empty = emptyQuery();

    return _.pairs(this.current().query)
      .filter(([k, v]) => !_.contains(ignore, k))
      .every(([k, v]) => _.isEqual(v, empty[k]));
  }

  // region Utils

  setPage = async (page: number) => {
    this.next(draft => {
      draft.query.page = page;
    });
    await this.load();
  };

  openModal(ctx: PageShopArticleStickerTemplateManyModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }

  closeModal = ({ reload }: { reload: boolean } = { reload: false }) => {
    this.next(draft => {
      draft.modal = null;
    });

    if (reload) {
      setTimeout(() => {
        this.load();
      }, 1);
    }
  };

  // endregion
}

export type PageShopArticleStickerTemplateManyBasicProps = {
  store: PageShopArticleStickerTemplateManyStore;
  context: PageShopArticleStickerTemplateManyContext;
};

export function usePageShopArticleStickerTemplateManyRxjs(): PageShopArticleStickerTemplateManyBasicProps {
  const { store, context } = useRxjsStore<
    PageShopArticleStickerTemplateManyContext,
    PageShopArticleStickerTemplateManyStore
  >(PageShopArticleStickerTemplateManyStore, null, () => {
    store.load(false);
  });

  return { store, context };
}

// endregion
