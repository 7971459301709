import * as React from 'react';
import { cs, formatCents, formatDate, formatLineState, isWithinMz } from '../../../utils';
import { BaseStore, useRxjsStore } from '../../../effects';
import { http } from '../../../http';
import { AxiosError } from 'axios';
import { GenericDataService } from '../../../services/GenericDataService';
import { Link } from '../../../components/Link';
import ModalOrderPrintTicket from '../models/ModalOrderPrintTicket';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormInput from '../../../components/FormInput';
import GenericPagination from '../../../components/GenericPagination';
import SpectreAutocomplete from '../../../components/spectre/SpectreAutocomplete';

type Context = {
  loading: boolean;

  query: {
    page: number;
    per_page: number;
    state: string;
  } & SearchContext;

  pagination?: Pagination;
  records: Record[];
  counts: {
    many: number;
    open: number;
    processed: number;
    ready: number;
    delivery: number;
    delivered: number;
    retour: number;
    cancelled: number;
  };

  modal: ModalContext;
};

type SearchContext = {
  order_id: string;
  tenant_set: string[];
  building_set: string[];
  customer_name: string;
};

type ModalContext = {
  which: 'print-ticket';
  data: {
    cart_id: number;
  };
};

type Record = {
  cart_id: number;
  building_id: number;
  purchase_id: number;
  purchase_created_at: string;
  customer?: ModelShopCustomer;
  address_delivery?: ModelShopCustomerAddress;
  address_invoice?: ModelShopCustomerAddress;
  id: number;
  tenant_id: number;
  state: string;
  inclusive: number;
  amount_open: number;
  amount_processed: number;
  amount_delivered: number;
  amount_cancelled: number;
  fully_paid: boolean;
};

class Store extends BaseStore<Context> {
  setup(): Context {
    return {
      loading: false,

      query: {
        page: 0,
        per_page: 12,
        state: 'open',

        order_id: null,
        tenant_set: [],
        building_set: [],
        customer_name: null,
      },

      pagination: null,
      records: [],
      counts: null,

      modal: null,
    };
  }

  async setState(state: string) {
    this.next(draft => {
      draft.query.page = 0;
      draft.query.state = state;
    });

    await this.load();
  }

  setPage = async (page: number) => {
    this.next(draft => {
      draft.query.page = page;
    });

    await this.load();
  };

  async load() {
    this.next(draft => {
      draft.loading = true;
    });

    try {
      const data = {
        ...this.current().query,
      };

      let res;

      res = await http().post('/core/order/purchase/tenant/many', data);

      const pagination = res.data.pagination;
      const records = res.data.records;

      this.next(draft => {
        draft.loading = false;
        draft.pagination = pagination;
        draft.records = records;
      });

      res = await http().post('/core/order/purchase/tenant/counts', data);

      const counts = res.data;

      this.next(draft => {
        draft.counts = counts;
      });
    } catch (exc) {
      const err: AxiosError = exc;

      console.error(err);

      this.next(draft => {
        draft.loading = false;
        draft.records = [];
        draft.pagination = null;
        draft.counts = null;
      });
    }
  }

  async search(values: SearchContext) {
    this.next(draft => {
      draft.query.order_id = values.order_id || null;
      draft.query.tenant_set = values.tenant_set;
      draft.query.building_set = values.building_set;
      draft.query.customer_name = values.customer_name || null;
    });

    await this.load();
  }

  openModal(ctx: ModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }
  closeModal() {
    this.next(draft => {
      draft.modal = null;
    });
  }
}

export default function PageOrderTenantMany() {
  const ggd = window.GLOBAL_GENERIC_DATA;
  const generic = GenericDataService.useEffect('PageOrderTenantMany');
  const { context, store } = useRxjsStore<Context, Store>(Store, null, () => {
    store.load();
  });

  if (!context) return null;

  const getTenantById = (id: number) => generic.data.tenants[id];
  const getBuildingById = (id: number) => generic.data.buildings[id];
  const states = ['many', 'open', 'processed', 'ready', 'delivery', 'delivered', 'retour', 'cancelled'];

  const initialValues: SearchContext = {
    order_id: '',
    tenant_set: [],
    building_set: [],
    customer_name: '',
  };

  return (
    <div className="container">
      <div className="card">
        <div className={cs('card-loading', context.loading && 'active')} />

        <div className="card-header">
          <div className="card-title h5">Order overzicht</div>

          <div>
            Onder "Nieuwe orders" vind je alle openstaande orders terug, dit zijn de orders die geleverd moeten worden.
            Je kan binnen een order aangeven of de "In behandeling" artikelen zijn. Hiermee geef je aan dat je de nieuwe
            order (welke "Nieuw" was) hebt gezien en hebt opgepakt. Dit kan met name handig zijn voor je eigen
            administratie. Zet een order op "Gereed" wanneer het artikel klaar is om geleverd te gaan worden, maar er
            nog een deel-betaling moet plaatsvinden. Is de order al helemaal betaald, dan kan je direct overgaan op
            leveren, geef aan "Onderweg" wanneer deze is meegegeven aan de transporteur, geef aan "Geleverd" wanneer het
            bij de klant is afgeleverd. Wanneer het artikel is afgeleverd verschijnt deze op de omzet.
          </div>
        </div>
        <div className="card-body">
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, fa) => {
              await store.search(values);
              fa.setSubmitting(false);
            }}
            validationSchema={Yup.object().shape({
              order_id: Yup.number(),
            })}
            render={fp => (
              <Form style={{ padding: '0 1rem' }}>
                <div className={cs('form-group')}>
                  <div className="columns">
                    <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                      <button
                        type="button"
                        className="btn btn-error-outline btn-action s-circle tooltip tooltip-right"
                        data-tooltip="Leeg alle zoekvelden."
                        disabled={context.loading}
                        onClick={async () => {
                          fp.setValues(initialValues);
                          await store.search(initialValues);
                        }}
                      >
                        <i className="fal fa-times" />
                      </button>
                    </div>

                    <div className="column col-2 col-xs-12  pr-2">
                      <label className="form-label">Ordernummer</label>
                      <FormInput name="order_id" />
                    </div>

                    <div className="column col-2 col-xs-12  pr-2">
                      <label className="form-label">Verstiging(en)</label>
                      <SpectreAutocomplete
                        name="building_set"
                        url="/core/plan/building/autocomplete"
                        fp={fp}
                        multiple
                        allowEmptySearch
                      />
                    </div>

                    <div className="column col-3 col-xs-12  pr-2">
                      <label className="form-label">Deelnemer</label>
                      <SpectreAutocomplete
                        name="tenant_set"
                        url="/core/contact/tenant/autocomplete"
                        fp={fp}
                        multiple
                        render={record => {
                          return (
                            <div>
                              ({record.value}) <strong>{record.title}</strong>
                            </div>
                          );
                        }}
                      />
                    </div>
                    <div className="column col-2 col-xs-12  pr-2">
                      <label className="form-label">Klantnaam</label>
                      <FormInput name="customer_name" />
                    </div>

                    <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                      <button
                        type="submit"
                        className="btn btn-primary-outline btn-action s-circle"
                        disabled={context.loading}
                      >
                        <i className={cs('fal fa-fw', context.loading ? 'fa-spin fa-spinner-third' : 'fa-search')} />
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          />
        </div>
        <div className="card-body">
          <ul className="tab tab-block">
            {context.counts &&
              states.map(state => (
                <li key={state} className={cs('tab-item', context.query.state === state && 'active')}>
                  <a
                    href="javascript:"
                    onClick={() => store.setState(state)}
                    className={cs(context.counts[state] > 0 && 'badge')}
                    data-badge={context.counts[state]}
                  >
                    {formatLineState(state, true)}
                  </a>
                </li>
              ))}
          </ul>
        </div>
        <div className="card-body">
          {context.records.length > 0 && (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="has-table-shrink-nowrap text-center">
                    <i className="fal fa-fw fa-hashtag" />
                  </th>
                  <th className="has-table-shrink-nowrap">Vestiging</th>
                  <th className="has-table-shrink-nowrap">Datum</th>
                  <th className="has-table-shrink-nowrap">Status</th>
                  <th>Klant</th>
                  <th>Stad</th>
                  <th className="has-table-shrink-nowrap">Deelnemer</th>
                  <th className="has-table-shrink-nowrap text-right">Bedrag</th>
                  <th className="has-table-shrink-nowrap text-center">
                    <i className="fal fa-fw fa-star" />
                  </th>
                  <th className="has-table-shrink-nowrap text-center">
                    <i className="fal fa-fw fa-user-hard-hat" />
                  </th>
                  <th className="has-table-shrink-nowrap text-center">
                    <i className="fal fa-fw fa-check" />
                  </th>
                  <th className="has-table-shrink-nowrap text-center">
                    <i className="fal fa-fw fa-ban" />
                  </th>
                  <th colSpan={2} />
                </tr>
              </thead>
              <tbody>
                {context.records.map(record => {
                  const tenant = getTenantById(record.tenant_id);
                  const fully_paid = record.fully_paid;

                  return (
                    <tr key={record.id}>
                      <td className="has-table-shrink-nowrap text-right">#{record.cart_id}</td>
                      <td className="has-table-shrink-nowrap">{getBuildingById(record.building_id).name}</td>
                      <td className="has-table-shrink-nowrap">{formatDate(record.purchase_created_at, 'L LT')}</td>
                      <td className="has-table-shrink-nowrap">
                        {record.state === 'open' && (
                          <span className="text-primary">
                            <i className="fal fa-fw  fa-star" /> nieuw
                          </span>
                        )}
                        {record.state === 'processed' && <span>verwerkt</span>}
                        {record.state === 'delivered' && (
                          <span>
                            <i className="fal fa-fw  fa-check" /> afgerond
                          </span>
                        )}
                        {record.state === 'cancelled' && (
                          <span>
                            <i className="fal fa-fw  fa-sync-alt" /> geretourneerd
                          </span>
                        )}
                      </td>
                      <td>
                        {record.customer ? (
                          <span>{record.customer.last}</span>
                        ) : (
                          <em className="text-gray">Onbekend</em>
                        )}
                      </td>
                      <td>
                        {record.address_delivery ? (
                          <span>{record.address_delivery.city}</span>
                        ) : (
                          <em className="text-gray">Onbekend</em>
                        )}
                      </td>
                      <td className="has-table-shrink-nowrap">
                        <span className="tooltip tooltip-bottom" data-tooltip={tenant.name}>
                          {tenant.id}
                        </span>
                      </td>
                      <td className="has-table-shrink-nowrap text-right">
                        {formatCents(record.inclusive)}{' '}
                        {fully_paid ? (
                          <span className="text-success tooltip tooltip-left" data-tooltip="Volledig betaald.">
                            <i className="fal fa-fw fa-money-bill-wave" />
                          </span>
                        ) : (
                          <span className="text-error tooltip tooltip-left" data-tooltip="Deels betaald.">
                            <i className="fal fa-fw fa-money-check" />
                          </span>
                        )}
                      </td>
                      <td className="text-center">{record.amount_open}</td>
                      <td className="text-center">{record.amount_processed}</td>
                      <td className="text-center">{record.amount_delivered}</td>
                      <td className="text-center">{record.amount_cancelled}</td>
                      <td className="has-table-shrink-nowrap">
                        {isWithinMz() && !!ggd.device?.ticket_printer && (
                          <button
                            type="button"
                            className="btn btn-dark-outline  btn-action s-circle"
                            onClick={() =>
                              store.openModal({ which: 'print-ticket', data: { cart_id: record.cart_id } })
                            }
                          >
                            <i className="fal fa-fw  fa-ticket" />
                          </button>
                        )}
                        <Link
                          pathName="/order/tenant/one"
                          pathSearch={{ id: record.id.toString() }}
                          className="btn btn-primary-outline  btn-action s-circle ml-1"
                        >
                          <i className="fal fa-fw  fa-search" />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>

              <GenericPagination pagination={context.pagination} setPage={store.setPage} span={13} />
            </table>
          )}

          {context.records.length === 0 && (
            <div className="card-body">
              <div className="empty">
                <div className="empty-icon">
                  <i className="fal fa-shopping-cart  fa-3x" />
                </div>
                <p className="empty-title h5">Geen orders</p>
                <p className="empty-subtitle">Er zijn geen orders gevonden die voldoen aan dit filter.</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {context.modal && context.modal.which == 'print-ticket' && (
        <ModalOrderPrintTicket cart_id={context.modal.data.cart_id} close={() => store.closeModal()} />
      )}
    </div>
  );
}
