import PageMarketingDashboard from './PageMarketingDashboard';

const MarketingStructure = [
  {
    pathname: '/marketing/dashboard',
    title: 'Dashboard',
    icon: 'fa-desktop',
    component: PageMarketingDashboard,

    rights: ['xxx'],
  },
];

export default MarketingStructure;
