import * as React from 'react';
import { cs, getGlobalDevice, isWithinMz } from '../../../utils';
import { PageDefinition } from './PageState';
import { ComponentTileCustomer } from './ComponentTileCustomer';
import { ComponentTileAddressDelivery } from './ComponentTileAddressDelivery';
import { ComponentTileAddressInvoice } from './ComponentTileAddressInvoice';
import { ComponentTileTenant } from './ComponentTileTenant';
import { ComponentTilePerson } from './ComponentTilePerson';
import { ComponentTileEmpty } from './ComponentTileEmpty';

type Props = {
  page: PageDefinition;
};

export default function ComponentQuickAdd({ page }: Props) {
  const { context } = page;
  const department = isWithinMz() && getGlobalDevice().department;
  const has_buzzer = (department && department.has_buzzer) || 0;
  const number_buzzer = context.cart && context.cart.number_buzzer;

  const categories = context.touch_categories;
  const [activeCategory, setActiveCategory] = React.useState(categories[0].id);
  const category = categories.find(v => v.id === activeCategory);

  const has_touch = !!context.touch;
  const is_large = has_touch && context.touch.is_large;

  const hasCart = context.cart && context.cart.id;

  return (
    <div className="card">
      <ul className="tab tab-block">
        {categories.map(category => (
          <li className={cs('tab-item', category.id === activeCategory && 'active')} key={category.id}>
            <a href="javascript:" onClick={() => setActiveCategory(category.id)}>
              <span>{category.name}</span>
            </a>
          </li>
        ))}
      </ul>
      <div className="card-body" style={{ overflow: 'auto' }}>
        <div className="columns col-gapless">
          {Array.from(Array(is_large ? 30 : 18)).map((i, ix) => {
            const article = category.articles.find(v => v.position === ix + 1);

            return (
              <div
                className={cs('column p-1')}
                key={ix}
                style={{ width: is_large ? '20%' : '33.333%', flex: 'none', minHeight: is_large ? 86 : 70 }}
              >
                <div className="card card-body p-1" style={{ height: '100%' }}>
                  <span
                    style={{
                      position: 'absolute',
                      right: 6,
                      display: 'block',
                      top: 6,
                      fontSize: 8,
                      color: '#939393',
                    }}
                  >
                    {ix + 1}
                  </span>
                  {article ? (
                    <a
                      href="javascript:"
                      data-article_id={article.id}
                      data-number_beeyond={article.number_beeyond}
                      className="p-1"
                      style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        wordWrap: 'break-word',
                        fontSize: '0.95rem',
                      }}
                      onClick={() => page.store.addOrUpdate(article.id)}
                    >
                      <div style={{ width: '100%' }}>{article.name}</div>
                    </a>
                  ) : (
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        wordWrap: 'break-word',
                      }}
                      className="p-1"
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="card-footer">
        <div className="columns">
          {!!has_buzzer && (
            <div className="column col-auto">
              <button
                type="button"
                className="btn btn-primary-outline"
                onClick={() => page.store.toggleModalBuzzer(true)}
              >
                Buzzer {number_buzzer > 0 && <span>({number_buzzer})</span>}
              </button>
            </div>
          )}

          {hasCart && (
            <div className="column">
              {!context.customer && !context.person && !context.tenant && <ComponentTileEmpty page={page} />}
              {context.customer && (
                <div className={cs(is_large && 'columns')}>
                  <div className={cs(is_large && 'column')}>
                    <ComponentTileCustomer page={page} />
                  </div>
                  <div className={cs(is_large && 'column')}>
                    <ComponentTileAddressDelivery
                      page={page}
                      select={() => page.store.toggleModalAddressSelect('delivery')}
                      update={() => page.store.toggleModalAddressUpdate('delivery')}
                    />
                  </div>
                  <div className={cs(is_large && 'column')}>
                    <ComponentTileAddressInvoice
                      page={page}
                      select={() => page.store.toggleModalAddressSelect('invoice')}
                      update={() => page.store.toggleModalAddressUpdate('invoice')}
                    />
                  </div>
                </div>
              )}
              {context.person && <ComponentTilePerson page={page} />}
              {context.tenant && <ComponentTileTenant page={page} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
