import PageFinanceDashboard from './PageFinanceDashboard';
import PageFinanceSettlementMany from './settlement/PageFinanceSettlementMany';
import PageFinanceActions from './actions/PageFinanceActions';

const FinanceStructure = [
  {
    pathname: '/finance/dashboard',
    title: 'Dashboard',
    icon: 'fa-desktop',
    component: PageFinanceDashboard,

    rights: ['finance.statistics'],
  },
  {
    pathname: '/finance/settlement/many',
    title: 'Weekafrekeningen',
    icon: 'fa-desktop',
    component: PageFinanceSettlementMany,

    rights: ['finance.statistics'],
  },
  {
    pathname: '/finance/actions',
    title: 'Statement-regels',
    icon: 'fa-desktop',
    component: PageFinanceActions,

    rights: ['finance.statistics'],
  },
];

export default FinanceStructure;
