import * as React from 'react';
import { BaseStore, useRxjsStore } from '../../../effects';
import { http } from '../../../http';
import { AxiosError } from 'axios';
import { GenericDataService } from '../../../services/GenericDataService';
import Loading from '../../../components/Loading';
import { ComponentOrderPurchaseHeader } from '../components/ComponentOrderPurchaseHeader';
import ComponentOrderPurchaseCustomer from '../components/ComponentOrderPurchaseCustomer';
import ComponentOrderPurchaseModals from '../components/ComponentOrderPurchaseModals';
import ComponentOrderPurchaseLines from '../components/ComponentOrderPurchaseLines';
import ComponentOrderPurchaseActions from '../components/ComponentOrderPurchaseActions';

export type Context = {
  loaded: boolean;

  purchase_id: number;
  purchase_tenant_id: number;

  purchase: ModelOrderPurchase;
  purchase_tenant: ModelOrderPurchaseTenant;
  purchases_per_tenant: ModelOrderPurchaseTenant[];

  payments: ModelFinancePayment[];
  creditations: ModelFinanceCreditation[];
  actions: ModelOrderPurchaseLineAction[];

  modal: ModalContext;
};

export type ModalContext =
  | {
      which: 'download-pdf';
      data: {
        cart_id: number;
      };
    }
  | {
      which: 'print-ticket';
      data: {
        cart_id: number;
      };
    }
  | {
      which: 'add';
    }
  | {
      which: 'update';
      data: {
        cart_line_id: number;
      };
    }
  | {
      which: 'compensation';
      data: {
        cart_line_id: number;
      };
    }
  | {
      which: 'annul';
      data: {
        purchases_per_tenant: ModelOrderPurchaseTenant[];
      };
    }
  | {
      which: 'cancel';
      data: {
        purchases_per_tenant: ModelOrderPurchaseTenant[];
      };
    }
  | {
      which: 'open';
      data: {
        purchases_per_tenant: ModelOrderPurchaseTenant[];
      };
    }
  | {
      which: 'processed';
      data: {
        purchases_per_tenant: ModelOrderPurchaseTenant[];
      };
    }
  | {
      which: 'ready';
      data: {
        purchases_per_tenant: ModelOrderPurchaseTenant[];
      };
    }
  | {
      which: 'delivery';
      data: {
        purchases_per_tenant: ModelOrderPurchaseTenant[];
      };
    }
  | {
      which: 'delivered';
      data: {
        purchases_per_tenant: ModelOrderPurchaseTenant[];
      };
    }
  | {
      which: 'final-payment';
    }
  | {
      which: 'payment-by-bank';
    }
  | {
      which: 'mail-pdf';
    }
  | {
      which: 'mail-request-payment';
    }
  | {
      which: 'customer-update';
    }
  | {
      which: 'address-delivery-update';
    }
  | {
      which: 'address-invoice-update';
    }
  | {
      which: 'payment-sale';
      data: {
        purchase: ModelOrderPurchase;
      };
    }
  | {
      which: 'payment-refund';
      data: {
        purchase: ModelOrderPurchase;
      };
    }
  | {
      which: 'payment-delete';
      data: {
        payment: ModelFinancePayment;
      };
    };

export class Store extends BaseStore<Context> {
  setup(): Context {
    return {
      loaded: false,
      purchase_id: null,
      purchase_tenant_id: null,

      purchase: null,
      purchase_tenant: null,
      purchases_per_tenant: [],

      payments: [],
      creditations: [],
      actions: [],

      modal: null,
    };
  }

  async load() {
    this.next(draft => {
      draft.loaded = false;
    });

    try {
      const data = {};

      const res = await http().post('/core/order/purchase/one', {
        purchase: this.current().purchase_id,
      });

      this.next(draft => {
        draft.loaded = true;
        draft.purchase = res.data.purchase;
        draft.purchases_per_tenant = res.data.purchases_per_tenant;
        draft.payments = res.data.payments;
        draft.creditations = res.data.creditations;
        draft.actions = res.data.actions;
      });
    } catch (exc) {
      const err: AxiosError = exc;

      this.next(draft => {
        draft.loaded = true;
        draft.purchase = null;
        draft.purchases_per_tenant = [];
      });
    }
  }

  openModal(ctx: ModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }
  closeModal() {
    this.next(draft => {
      draft.modal = null;
    });

    this.load();
  }
}

export default function PageOrderGenericOne(props: { id: number }) {
  const generic = GenericDataService.useEffect('PageOrderGenericOne');
  const { context, store } = useRxjsStore<Context, Store>(
    Store,
    (): any => {
      return { purchase_id: props.id };
    },
    () => {
      store.load();
    },
  );

  if (!context) return null;

  if (!context.loaded) {
    return <div className="container">{!context.loaded && <Loading />}</div>;
  }

  const fully_paid = context.purchase.paid >= context.purchase.current_inclusive;

  return (
    <div className="container">
      {context.purchase && (
        <>
          <ComponentOrderPurchaseHeader purchase={context.purchase} fully_paid={fully_paid} store={store} />
          <ComponentOrderPurchaseCustomer context={context} store={store} />
        </>
      )}

      {context.purchases_per_tenant.length > 0 && <ComponentOrderPurchaseLines context={context} store={store} />}
      {context.actions.length > 0 && <ComponentOrderPurchaseActions context={context} store={store} />}

      <ComponentOrderPurchaseModals context={context} store={store} />
    </div>
  );
}
