import * as React from 'react';
import { formatCentsShort, loadOneRecord } from '../../../utils';
import ModalLoading from '../../../components/ModalLoading';
import { nl2br } from '../../../quick';

type Props = {
  sponsor_id: number;
  closeModal: () => void;
};

export default function ModalSponsorView({ sponsor_id, closeModal }: Props) {
  const [loaded, setLoaded] = React.useState(false);
  const [sponsor, setSponsor] = React.useState<ModelOrderSponsor>(null);

  React.useEffect(() => {
    (async () => {
      setSponsor(await loadOneRecord('/core/order/sponsor/one', { sponsor: sponsor_id }));
      setLoaded(true);
    })();
  }, [sponsor_id]);

  if (!loaded) return <ModalLoading title="Even laden, bijna klaar!" />;

  return (
    <div className="modal modal-sm active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">
            Sponsor <strong>{sponsor.subject}</strong>
          </div>
        </div>

        <table className="table">
          <tbody>
            <tr>
              <th className="has-table-shrink-nowrap">Onderwerp</th>
              <td>{sponsor.subject}</td>
            </tr>
            <tr>
              <th className="has-table-shrink-nowrap">Beschrijving</th>
              <td>{nl2br(sponsor.description)}</td>
            </tr>
            <tr>
              <th className="has-table-shrink-nowrap">Artikelen</th>
              <td className="text-right">{sponsor.amount}</td>
            </tr>
            <tr>
              <th className="has-table-shrink-nowrap">Totaal</th>
              <td className="text-right">{formatCentsShort(sponsor.inclusive)}</td>
            </tr>
          </tbody>
        </table>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                <i className="fal fa-times" /> Sluiten
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
