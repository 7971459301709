import * as React from 'react';
import * as ReactMarkdown from 'react-markdown';

function slug(value: string): string {
  return value.toLowerCase().replace(/[^a-z0-9-]+/gi, '-');
}

function toToc(value: string) {
  const re = /(#{1,}) ([a-z0-9 _-]+)$/gim;
  const result = [];

  let match;

  do {
    match = re.exec(value);
    if (match) {
      const [_, times, heading] = match;

      result.push({
        times: times.length,
        link: slug(heading),
        heading,
      });
    }
  } while (match);

  return result;
}

export default function Render({ markdown, prepend }: { markdown: string; prepend?: React.ReactNode }) {
  const me = React.useRef(null);
  const [image, setImage] = React.useState(null);
  const [top, setTop] = React.useState(0);
  const toc = React.useMemo(() => toToc(markdown), [markdown]);

  function markdownClick(evt) {
    const target: HTMLImageElement = evt.target;
    if (target.nodeName === 'IMG') {
      setImage(target.src);
    }
  }

  React.useEffect(() => {
    const router = document.getElementsByClassName('AppRouter')[0];

    function handleScroll() {
      setTop(router.scrollTop);
    }

    router.addEventListener('scroll', handleScroll);

    return () => {
      router.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="container">
      <div className="columns">
        <div className="column col-3">
          <div className="menu" style={{ marginTop: top }}>
            {toc.map(item => {
              return (
                <div
                  key={item.link}
                  className={`menu-item menu-toc-${item.times}`}
                  style={{ paddingLeft: 12 * item.times - 6 }}
                >
                  <a href={`${window.location.href.split('#')[0]}#${item.link}`}>{item.heading}</a>
                </div>
              );
            })}
          </div>
        </div>
        <div className="column col-9">
          <div className="card">
            <div className="card-body">
              {prepend}

              <div className="markdown" onClick={markdownClick} ref={me}>
                <SubRender markdown={markdown} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {image && (
        <div className="modal modal-lg active" onClick={() => setImage(null)}>
          <div className="modal-overlay" />
          <div className="modal-container" style={{ maxWidth: '90%' }}>
            <div className="modal-body">
              <img src={image} alt="Afbeelding." style={{ maxWidth: '100%' }} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function SubRender({ markdown }) {
  const item = React.useMemo(() => {
    return (
      <ReactMarkdown
        source={markdown}
        renderers={{
          heading: item => {
            const link = slug(item.children[0].props.value);
            if (item.level === 1) {
              return <h1 id={link}>{item.children}</h1>;
            } else if (item.level === 2) {
              return <h2 id={link}>{item.children}</h2>;
            } else {
              return <h3 id={link}>{item.children}</h3>;
            }
          },
          image: item => {
            if (item.src.endsWith('.mp4')) {
              return <video className="video-responsive" src={item.src} controls />;
            } else {
              return <img src={item.src} alt={item.alt} />;
            }
          },
        }}
      />
    );
  }, [markdown]);

  return item;
}
