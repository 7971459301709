import * as React from 'react';
import { Form, Formik } from 'formik';
import { GenericDataService } from '../../../services/GenericDataService';
import { cs, hasRights } from '../../../utils';
import FormInput from '../../../components/FormInput';
import { Link } from '../../../components/Link';
import MenuDropdown from '../../../components/MenuDropdown';
import GenericPagination from '../../../components/GenericPagination';
import ModalContactPersonPasswordSet from '../modals/ModalContactPersonPasswordSet';
import ModalTakeover from './ModalTakeover';
import { PageContactPersonManyCtx, usePageContactPersonManyRxjs } from './PageContactPersonManyStore';
import SpectreCardHeader from '../../../components/spectre/SpectreCardHeader';
import SpectreButton from '../../../components/spectre/SpectreButton';
import ModalFilter, { ModalFilterValues } from './ModalFilter';
import * as _ from 'underscore';
import ModalContactPersonCreate from '../modals/ModalContactPersonCreate';
import ModalContactPersonEdit from '../modals/ModalContactPersonEdit';
import ModalContactPersonView from '../modals/ModalContactPersonView';
import ModalContactPersonPasswordReset from '../modals/ModalContactPersonPasswordReset';
import ModalContactPersonArchive from '../modals/ModalContactPersonArchive';
import ModalContactPersonActivate from '../modals/ModalContactPersonActivate';
import FormGroup from '../../../components/FormGroup';
import SpectreAutocomplete from '../../../components/spectre/SpectreAutocomplete';
import ModalSignoutEverywhere from './ModalSignoutEverywhere';
import AtomTableNoRecords from '../../../components/AtomTableNoRecords';
import { useDebounce } from '../../../effects';

export default function PageContactPersonMany() {
  const { context, store } = usePageContactPersonManyRxjs();

  if (!store || !context) return null;

  function openPersonCreate() {
    store.openModal({ which: 'person-create', data: {} });
  }

  return (
    <PageContactPersonManyCtx.Provider value={{ store, context }}>
      <div className="container">
        <div className="card">
          <div className={cs('card-loading', !context.loaded && 'active')} />

          <SpectreCardHeader
            title="Personen"
            buttons={[
              <SpectreButton
                icon="fa-plus"
                onClick={openPersonCreate}
                tooltip={{ title: 'Nieuw persoon toevoegen', position: 'left' }}
              />,
            ]}
          />
          <div className="card-body">
            <Search />
          </div>
          <div className="card-body">
            <Table />
          </div>
        </div>
        <Modals />
      </div>
    </PageContactPersonManyCtx.Provider>
  );
}

function Table() {
  const generic = GenericDataService.useEffect('PageContactPersonMany');
  const { context, store } = React.useContext(PageContactPersonManyCtx);

  const getWorkflowById = (id: number) => generic.data.workflows[id];
  const getBuildingById = (id: number) => generic.data.buildings[id];
  const getTenantById = (id: number) => generic.data.tenants[id];

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th colSpan={2} className="has-table-shrink-nowrap text-center">
            <i className="fal fa-fw fa-hashtag" />
          </th>
          <th>Name</th>
          <th>Rechten</th>
          <th className="has-table-shrink-nowrap tooltip tooltip-left" data-tooltip="Overkoepelend alle deelnemers.">
            <i className="fal fa-crown" />
          </th>
          <th>Deelnemer(s)</th>
          <th>Vestiging(en)</th>
          <th className="has-table-shrink-nowrap tooltip tooltip-left" data-tooltip="E-mailadres">
            <i className="fal fa-envelope" />
          </th>
          <th className="has-table-shrink-nowrap tooltip tooltip-left" data-tooltip="Telefoonnummer">
            <i className="fal fa-phone" />
          </th>
          <th className="has-table-shrink-nowrap tooltip tooltip-left" data-tooltip="Mobielnummer">
            <i className="fal fa-mobile" />
          </th>
          <th />
        </tr>
      </thead>

      {context.records.length > 0 && (
        <>
          <GenericPagination pagination={context.pagination} setPage={store.setPage} span={11} />
          <tbody>
            {context.records.map(record => {
              const is_valid = _.contains(record.rights, 'core.authenticate') && !record.is_archived;

              function openPersonEdit() {
                store.openModal({ which: 'person-edit', data: { person: record } });
              }
              function openPersonView() {
                store.openModal({ which: 'person-view', data: { person: record } });
              }
              function openPersonArchive() {
                store.openModal({ which: 'person-archive', data: { person: record } });
              }
              function openPersonActivate() {
                store.openModal({ which: 'person-activate', data: { person: record } });
              }
              function openPersonPasswordReset() {
                store.openModal({ which: 'person-password-reset', data: { person: record } });
              }
              function openPersonPasswordSet() {
                store.openModal({ which: 'person-password-set', data: { person: record } });
              }
              function openSignoutEverywhere() {
                store.openModal({ which: 'signout-everywhere', data: { person: record } });
              }
              function openTakeover() {
                store.openModal({ which: 'takeover', data: { person: record } });
              }

              return (
                <tr
                  key={record.id}
                  onDoubleClick={evt => {
                    evt.preventDefault();
                    openPersonView();
                  }}
                >
                  <td className="has-table-shrink-nowrap text-right">#{record.id}</td>
                  <td className="has-table-shrink-nowrap text-right">
                    {is_valid && (
                      <i
                        className="fal fa-check text-success tooltip tooltip-right"
                        data-tooltip="Deze persoon mag inloggen."
                      />
                    )}
                    {!is_valid && (
                      <i
                        className="fal fa-times text-danger tooltip tooltip-right"
                        data-tooltip="Deze persoon mag niet inloggen."
                      />
                    )}
                  </td>
                  <td>{record.name}</td>

                  <td className="has-table-shrink-nowrap">
                    {record.workflows
                      .map(x => getWorkflowById(x))
                      .filter(x => x)
                      .map((workflow, ix, { length }) => (
                        <span key={workflow.id}>
                          {workflow.name}
                          {length - 1 !== ix && ', '}
                        </span>
                      ))}
                  </td>
                  <QuickTdIcon value={record.is_global && 'Overkoepelend'} icon="fa-crown" />
                  <td className="has-table-shrink">
                    {record.tenant_set
                      .map(x => getTenantById(x))
                      .filter(x => x)
                      .slice(0, 10)
                      .map((tenant, ix, arr) =>
                        hasRights(['contact.tenant']) ? (
                          <Link
                            key={ix}
                            className="tooltip tooltip-bottom"
                            data-tooltip={tenant.name}
                            pathName="/contact/tenant/one"
                            pathSearch={{ id: tenant.id.toString() }}
                          >
                            {tenant.id}
                            {arr.length - 1 !== ix && ', '}
                          </Link>
                        ) : (
                          <span className="tooltip tooltip-bottom" data-tooltip={tenant.name}>
                            {tenant.id}
                            {arr.length - 1 !== ix && ', '}
                          </span>
                        ),
                      )}
                    {record.tenant_set.length > 10 && '...'}
                  </td>
                  <td className="has-table-shrink">
                    {record.building_set
                      .map(x => getBuildingById(x))
                      .filter(x => x)
                      .map((building, ix, arr) => (
                        <span key={building.id}>
                          {building.name}
                          {ix !== arr.length - 1 && ', '}
                        </span>
                      ))}
                  </td>

                  <QuickTdIcon mode="mailto" value={record.email} icon="fa-envelope" />
                  <QuickTdIcon mode="tel" value={record.phone} icon="fa-phone" />
                  <QuickTdIcon mode="tel" value={record.mobile} icon="fa-mobile" />

                  <td className="has-table-shrink-nowrap">
                    {hasRights(['contact.person.manage', 'contact.person.rights'], true) && (
                      <MenuDropdown
                        button={<i className="fal fa-ellipsis-h" />}
                        classNames={['mr-1']}
                        btnClassNames={['btn btn-dark-outline btn-action s-circle']}
                        items={[
                          hasRights(['contact.person.manage']) && (
                            <a href="javascript:" onClick={openPersonEdit}>
                              <i className="fal fa-fw fa-pencil" /> aanpassen
                            </a>
                          ),
                          hasRights(['contact.person.manage']) && (
                            <a href="javascript:" onClick={openPersonPasswordReset}>
                              <i className="fal fa-fw fa-key" /> wachtwoord herstel
                            </a>
                          ),
                          hasRights(['contact.person.manage']) && (
                            <a href="javascript:" onClick={openPersonPasswordSet}>
                              <i className="fal fa-fw fa-key" /> wachtwoord instellen
                            </a>
                          ),
                          hasRights(['contact.person.manage']) && is_valid && (
                            <a href="javascript:" onClick={openPersonArchive}>
                              <i className="fal fa-fw fa-stop-circle" /> archiveren
                            </a>
                          ),
                          hasRights(['contact.person.manage']) && !is_valid && (
                            <a href="javascript:" onClick={openPersonActivate}>
                              <i className="fal fa-fw fa-play-circle" /> activeren
                            </a>
                          ),
                          hasRights(['contact.person.rights']) && true,
                          hasRights(['contact.person.rights']) && (
                            <a href="javascript:" onClick={openSignoutEverywhere}>
                              <i className="fal fa-fw fa-sign-out" /> overal afmelden
                            </a>
                          ),
                          hasRights(['contact.person.rights']) && (
                            <a href="javascript:" onClick={openTakeover}>
                              <i className="fal fa-fw fa-user-secret" /> overnemen
                            </a>
                          ),
                        ]}
                      />
                    )}
                    <SpectreButton
                      icon="fa-search"
                      onClick={openPersonView}
                      tooltip={{
                        title: 'Bekijk de persoonsinformatie.',
                        position: 'left',
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
          <GenericPagination pagination={context.pagination} setPage={store.setPage} span={11} />
        </>
      )}

      {context.records.length === 0 && (
        <tbody>
          <tr>
            <td colSpan={11}>
              <AtomTableNoRecords />
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
}

type FormikOnChangeEffectProps = {
  onChange?: (values: any, previous: any) => void;
  onChangeDebounced?: (values: any) => void;
  formik: any;
};

function FormikOnChangeEffect(props: FormikOnChangeEffectProps) {
  const { onChange, onChangeDebounced, formik } = props;
  const { values } = formik;
  const [state, setState] = React.useState('null');
  const [first, setFirst] = React.useState(true);
  const values_json = JSON.stringify(values);

  const debounced_state = useDebounce(state, 500);

  React.useEffect(() => {
    if (state !== values_json) {
      if (!first) {
        setState(values_json);
        if (onChange) onChange(values, JSON.parse(state));
      } else {
        setFirst(false);
      }
    }
  }, [values_json]);

  React.useEffect(() => {
    if (!first) {
      if (onChangeDebounced) onChangeDebounced(JSON.parse(debounced_state));
    }
  }, [debounced_state]);

  return null;
}

function Search() {
  const { context, store } = React.useContext(PageContactPersonManyCtx);
  const initial = {
    name: context.query.name,
    email: context.query.email,
    tenants: context.query.tenants,
  };
  const query_is_empty = store.queryIsEmpty(['name', 'email', 'tenants']);

  function openFilter() {
    store.openModal({ which: 'filter', data: { initial: context.query } });
  }

  return (
    <Formik
      initialValues={initial}
      enableReinitialize={true}
      onSubmit={async (values, fa) => {
        await store.search(values);
        fa.setSubmitting(false);
      }}
      render={fp => (
        <Form style={{ padding: '0 1rem' }}>
          <FormikOnChangeEffect
            onChangeDebounced={async values => {
              fp.setSubmitting(true);
              await store.search(values);
              fp.setSubmitting(false);
            }}
            formik={fp}
          />
          <div className={cs('form-group')}>
            <div className="columns">
              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="button"
                  className="btn btn-error-outline btn-action s-circle tooltip tooltip-right"
                  data-tooltip="Leeg alle zoekvelden."
                  disabled={!context.loaded}
                  onClick={async () => {
                    await store.resetQuery();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="column col-2 col-xs-12  pr-2">
                <label className="form-label">Naam</label>
                <FormInput name="name" />
              </div>
              <div className="column col-2 col-xs-12  pr-2">
                <label className="form-label">E-mailadres</label>
                <FormInput name="email" />
              </div>
              <div className="column col-4 col-xs-12  pr-2">
                <FormGroup title="Deelnemer(s)" name="tenants">
                  <SpectreAutocomplete
                    name="tenants"
                    url="/core/contact/tenant/autocomplete"
                    fp={fp}
                    multiple
                    render={record => {
                      return (
                        <div>
                          ({record.value}) <strong>{record.title}</strong>
                        </div>
                      );
                    }}
                  />
                </FormGroup>
              </div>

              <div className="column" />

              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <SpectreButton
                  icon="fa-filter"
                  classNames={!query_is_empty && ['btn-primary btn-action s-circle mx-1']}
                  onClick={openFilter}
                  disabled={!context.loaded}
                  tooltip={{ title: 'Filter de personen', position: 'left' }}
                />
                <button
                  type="submit"
                  className="btn btn-primary-outline btn-action s-circle"
                  disabled={fp.isSubmitting || !context.loaded}
                >
                  <i className={cs('fal fa-fw', !context.loaded ? 'fa-spin fa-spinner-third' : 'fa-search')} />
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
}

function QuickTdIcon({ mode, value, icon }: { mode?: string; value: string; icon: string }) {
  return value ? (
    <td className="has-table-shrink-nowrap  tooltip tooltip-left" data-tooltip={value}>
      {mode ? (
        <a href={`${mode}:${value}`}>
          <i className={cs('fal', icon)} />
        </a>
      ) : (
        <i className={cs('fal', icon)} />
      )}
    </td>
  ) : (
    <td className="has-table-shrink-nowrap">&nbsp;</td>
  );
}

function Modals() {
  const { context, store } = React.useContext(PageContactPersonManyCtx);
  const mctx = context.modal;

  if (!mctx) return null;

  function closeModalFilter(values?: ModalFilterValues) {
    if (_.isEmpty(values)) {
      store.closeModal();
    } else {
      store.closeModal();
      store.search(values);
    }
  }

  return (
    <React.Fragment>
      {mctx.which === 'person-create' && <ModalContactPersonCreate {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'person-edit' && <ModalContactPersonEdit {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'person-view' && <ModalContactPersonView {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'person-archive' && <ModalContactPersonArchive {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'person-activate' && <ModalContactPersonActivate {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'person-password-reset' && (
        <ModalContactPersonPasswordReset {...mctx.data} closeModal={store.closeModal} />
      )}
      {mctx.which === 'person-password-set' && (
        <ModalContactPersonPasswordSet {...mctx.data} closeModal={store.closeModal} />
      )}
      {mctx.which === 'signout-everywhere' && <ModalSignoutEverywhere {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'takeover' && <ModalTakeover {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'filter' && <ModalFilter {...mctx.data} closeModal={closeModalFilter} />}
    </React.Fragment>
  );
}
