import * as React from 'react';
import { cs, formatCents, formatDate, formatLineState, isWithinMz } from '../../../utils';
import { BaseStore, useRxjsStore } from '../../../effects';
import { http } from '../../../http';
import { AxiosError } from 'axios';
import { GenericDataService } from '../../../services/GenericDataService';
import { Link } from '../../../components/Link';
import ComponentOrderPurchaseLineState from '../../../components/ComponentOrderPurchaseLineState';
import ModalOrderPrintTicket from '../models/ModalOrderPrintTicket';
import { Form, Formik } from 'formik';
import FormInput from '../../../components/FormInput';
import * as Yup from 'yup';
import GenericPagination from '../../../components/GenericPagination';
import SpectreAutocomplete from '../../../components/spectre/SpectreAutocomplete';
import Query from '../../../lib/Query';
import AtomTableNoRecords from '../../../components/AtomTableNoRecords';
import _ = require('underscore');

function console_error(...args) {
  console.error(...args);
  return false;
}

type Context = {
  loading: boolean;

  query: ContextQuery;
  query_is_empty: boolean;

  pagination?: Pagination;
  records: ModelOrderPurchase[];
  counts: {
    many: number;
    open: number;
    processed: number;
    ready: number;
    delivery: number;
    delivered: number;
    retour: number;
    cancelled: number;
  };

  modal: ModalContext;
};

type ContextQuery = {
  page: number;
  per_page: number;
  state: string;
} & SearchContext;

type SearchContext = {
  order_id: string;
  tenant_set: string[];
  building_set: string[];
  payment_foreign_number: string;
  customer_name: string;
};

type ModalContext = {
  which: 'print-ticket';
  data: {
    cart_id: number;
  };
};

function emptyQuery(): ContextQuery {
  return {
    page: 0,
    per_page: 12,
    state: 'many',
    order_id: '',
    tenant_set: [],
    building_set: [],
    payment_foreign_number: '',
    customer_name: '',
  };
}

function isQueryEmpty(q: ContextQuery) {
  return _.isEqual(_.omit(q, ['state']), _.omit(emptyQuery(), ['state']));
}

const query = new Query<ContextQuery>('PageOrderGenericMany');

class Store extends BaseStore<Context> {
  setup(): Context {
    const q = query.load() || emptyQuery();

    return {
      loading: false,

      query: q,
      query_is_empty: isQueryEmpty(q),

      pagination: null,
      records: [],
      counts: null,

      modal: null,
    };
  }

  async setState(state: string) {
    this.next(draft => {
      draft.query.state = state;
      draft.query_is_empty = isQueryEmpty(draft.query);

      query.save(draft.query);
    });

    await this.load();
  }

  setPage = async (page: number) => {
    this.next(draft => {
      draft.query.page = page;
      draft.query_is_empty = isQueryEmpty(draft.query);

      query.save(draft.query);
    });

    await this.load();
  };

  async load() {
    this.next(draft => {
      draft.loading = true;
    });

    try {
      const q = this.current().query;
      const data = {
        page: q.page,
        per_page: q.per_page,
        state: q.state,
        order_id: q.order_id || null,
        tenant_set: q.tenant_set,
        building_set: q.building_set,
        payment_foreign_number: q.payment_foreign_number || null,
        customer_name: q.customer_name || null,
      };

      const res_many = await http().post('/core/order/purchase/many', data);
      const pagination = res_many.data.pagination;
      const records = res_many.data.records;

      this.next(draft => {
        draft.loading = false;
        draft.pagination = pagination;
        draft.records = records;
      });

      const res_counts = await http().post('/core/order/purchase/counts', data);
      const counts = res_counts.data;

      this.next(draft => {
        draft.counts = counts;
      });
    } catch (exc) {
      const err: AxiosError = exc;

      this.next(draft => {
        draft.loading = false;
        draft.records = [];
        draft.pagination = null;
        draft.counts = null;
      });
    }

    return this;
  }

  async search(values: SearchContext) {
    this.next(draft => {
      draft.query.page = 0;
      draft.query.order_id = values.order_id;
      draft.query.tenant_set = values.tenant_set;
      draft.query.building_set = values.building_set;
      draft.query.payment_foreign_number = values.payment_foreign_number;
      draft.query.customer_name = values.customer_name;
      draft.query_is_empty = isQueryEmpty(draft.query);

      query.save(draft.query);
    });

    return await this.load();
  }

  async resetQuery() {
    return await this.search(emptyQuery());
  }

  openModal(ctx: ModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }
  closeModal() {
    this.next(draft => {
      draft.modal = null;
    });
  }
}

export default function PageOrderGenericMany() {
  const ggd = window.GLOBAL_GENERIC_DATA;
  const generic = GenericDataService.useEffect('PageOrderGenericMany');
  const { context, store } = useRxjsStore<Context, Store>(Store, null, () => {
    store.load();
  });

  if (!context) return null;

  const getTenantById = (id: number) => generic.data.tenants[id];
  const getBuildingById = (id: number) => generic.data.buildings[id];
  const states = ['many', 'open', 'processed', 'ready', 'delivery', 'delivered'];

  return (
    <div className="container">
      <div className="card">
        <div className={cs('card-loading', context.loading && 'active')} />

        <div className="card-header">
          <div className="card-title h5">Order overzicht</div>
        </div>
        <div className="card-body">
          <SearchForm store={store} context={context} />
        </div>
        <div className="card-body">
          <ul className="tab tab-block">
            {context.counts &&
              states.map(state => (
                <li key={state} className={cs('tab-item', context.query.state === state && 'active')}>
                  <a
                    href="javascript:"
                    onClick={() => store.setState(state)}
                    className={cs(context.counts[state] > 0 && 'badge')}
                    data-badge={context.counts[state]}
                  >
                    {formatLineState(state, true)}
                  </a>
                </li>
              ))}
          </ul>
        </div>
        <div className="card-body">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="has-table-shrink-nowrap text-center">
                  <i className="fal fa-fw fa-hashtag" />
                </th>
                <th className="has-table-shrink-nowrap">Vestiging</th>
                <th className="has-table-shrink-nowrap">Datum</th>
                <th className="has-table-shrink-nowrap">Status</th>
                <th>Klant</th>
                <th>Stad</th>
                <th className="has-table-shrink-nowrap">Deelnemers</th>
                <th className="has-table-shrink-nowrap text-right">Bedrag</th>
                <th className="has-table-shrink-nowrap text-center">
                  <i className="fal fa-fw fa-star" />
                </th>
                <th className="has-table-shrink-nowrap text-center">
                  <i className="fal fa-fw fa-user-hard-hat" />
                </th>
                <th className="has-table-shrink-nowrap text-center">
                  <i className="fal fa-fw fa-check" />
                </th>
                <th className="has-table-shrink-nowrap text-center">
                  <i className="fal fa-fw fa-ban" />
                </th>
                <th colSpan={2} />
              </tr>
            </thead>

            {context.records.length === 0 && (
              <tbody>
                <tr>
                  <td colSpan={13}>
                    <AtomTableNoRecords />
                  </td>
                </tr>
              </tbody>
            )}
            {context.records.length > 0 && (
              <>
                <GenericPagination pagination={context.pagination} setPage={store.setPage} span={13} />
                <tbody>
                  {context.records.map(record => {
                    const fully_paid = record.paid >= record.current_inclusive;

                    return (
                      <tr key={record.id}>
                        <td className="has-table-shrink-nowrap text-right">#{record.cart_id}</td>
                        <td className="has-table-shrink-nowrap">
                          {record.building_id
                            ? getBuildingById(record.building_id).name
                            : console_error('Missing building_id', record.id, record.building_id) || 'X'}
                        </td>
                        <td className="has-table-shrink-nowrap">{formatDate(record.created_at, 'L LT')}</td>
                        <td className="has-table-shrink-nowrap">
                          <ComponentOrderPurchaseLineState state={record.state} />
                        </td>
                        <td>
                          {record.customer ? (
                            <span>{record.customer.last}</span>
                          ) : (
                            <em className="text-gray">Onbekend</em>
                          )}
                        </td>
                        <td>
                          {record.address_delivery ? (
                            <span>{record.address_delivery.city}</span>
                          ) : (
                            <em className="text-gray">Onbekend</em>
                          )}
                        </td>
                        <td className="has-table-shrink-nowrap">
                          {record.tenant_id_set.length > 3 ? (
                            <span className="tooltip tooltip-bottom" data-tooltip={record.tenant_id_set.join(', ')}>
                              {record.tenant_id_set.length} deelnemers
                            </span>
                          ) : (
                            record.tenant_id_set.map((tenant_id, rx) => {
                              const tenant = getTenantById(tenant_id);

                              return (
                                <span key={tenant_id} className="tooltip tooltip-bottom" data-tooltip={tenant.name}>
                                  {rx !== 0 && ', '}
                                  {tenant.id}
                                </span>
                              );
                            })
                          )}
                        </td>
                        <td className="has-table-shrink-nowrap text-right">
                          {formatCents(record.inclusive)}{' '}
                          {fully_paid ? (
                            <span className="text-success tooltip tooltip-left" data-tooltip="Volledig betaald.">
                              <i className="fal fa-fw fa-money-bill-wave" />
                            </span>
                          ) : (
                            <span
                              className="text-error tooltip tooltip-bottom"
                              data-tooltip={`€ ${formatCents(record.paid)} van de € ${formatCents(
                                record.inclusive,
                              )} is betaald.`}
                            >
                              <i className="fal fa-fw fa-money-check" />
                            </span>
                          )}
                        </td>
                        <td className="text-center">{record.amount_open}</td>
                        <td className="text-center">{record.amount_processed}</td>
                        <td className="text-center">{record.amount_delivered}</td>
                        <td className="text-center">{record.amount_cancelled}</td>
                        <td className="has-table-shrink-nowrap">
                          {isWithinMz() && !!ggd.device?.ticket_printer && (
                            <button
                              type="button"
                              className="btn btn-dark-outline  btn-action s-circle"
                              onClick={() =>
                                store.openModal({ which: 'print-ticket', data: { cart_id: record.cart_id } })
                              }
                            >
                              <i className="fal fa-fw  fa-ticket" />
                            </button>
                          )}
                          <Link
                            pathName="/order/generic/one"
                            pathSearch={{ id: record.id.toString() }}
                            className="btn btn-primary-outline  btn-action s-circle ml-1"
                          >
                            <i className="fal fa-fw  fa-search" />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <GenericPagination pagination={context.pagination} setPage={store.setPage} span={13} />
              </>
            )}
          </table>
        </div>
      </div>

      {context.modal && context.modal.which == 'print-ticket' && (
        <ModalOrderPrintTicket cart_id={context.modal.data.cart_id} close={() => store.closeModal()} />
      )}
    </div>
  );
}

function SearchForm({ store, context }) {
  const initialValues: SearchContext = context.query;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, fa) => {
        await store.search(values);
        fa.setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        order_id: Yup.number(),
        payment_foreign_number: Yup.string().max(6, 'stringMaxLength'),
      })}
      render={fp => (
        <Form style={{ padding: '0 1rem' }}>
          <div className={cs('form-group')}>
            <div className="columns">
              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="button"
                  className={cs(
                    'btn btn-action s-circle tooltip tooltip-right',
                    context.query_is_empty ? 'btn-error-outline' : 'btn-error',
                  )}
                  data-tooltip="Leeg alle zoekvelden."
                  disabled={context.loading}
                  onClick={async () => {
                    fp.setValues((await store.resetQuery()).current().query);
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="column col-2 col-xs-12  pr-2">
                <label className="form-label">Ordernummer</label>
                <FormInput name="order_id" autoFocus />
              </div>

              <div className="column col-2 col-xs-12  pr-2">
                <label className="form-label">Verstiging(en)</label>
                <SpectreAutocomplete
                  name="building_set"
                  url="/core/plan/building/autocomplete"
                  fp={fp}
                  multiple
                  allowEmptySearch
                />
              </div>

              <div className="column col-3 col-xs-12  pr-2">
                <label className="form-label">Deelnemer</label>
                <SpectreAutocomplete
                  name="tenant_set"
                  url="/core/contact/tenant/autocomplete"
                  fp={fp}
                  multiple
                  render={record => {
                    return (
                      <div>
                        ({record.value}) <strong>{record.title}</strong>
                      </div>
                    );
                  }}
                />
              </div>
              <div className="column col-1 col-xs-12  pr-2">
                <label className="form-label">Auth.code</label>
                <FormInput name="payment_foreign_number" />
              </div>
              <div className="column col-2 col-xs-12  pr-2">
                <label className="form-label">Klantnaam</label>
                <FormInput name="customer_name" />
              </div>

              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="submit"
                  className="btn btn-primary-outline btn-action s-circle"
                  disabled={fp.isSubmitting || context.loading}
                >
                  <i className={cs('fal fa-fw', context.loading ? 'fa-spin fa-spinner-third' : 'fa-search')} />
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
}
