import * as React from 'react';
import AppHeader from './AppHeader';
import AppNavigation from './AppNavigation';
import AppRouter from './AppRouter';
import AppToastbar from './AppToastbar';
import { cs } from '../utils';
import { Helmet } from 'react-helmet';
import { ProfileService } from '../services/ProfileService';

export default function App() {
  const profile = ProfileService.useEffect('App');
  const isDevEnv = false && GLOBAL_ENV === 'dev';
  const title = isDevEnv ? '[DEV] Loods 5 - Apps' : 'Loods 5 - Apps';

  console.log(
    '============== Boot App ==============',
    profile.loading,
    profile.present,
    profile.person && profile.person.name,
    (window as any).ENV,
  );

  return (
    <div className={cs('App', profile.present || 'inactive', isDevEnv && 'dev')}>
      <Helmet
        title={title}
        meta={[
          { name: 'msapplication-TileColor', content: '#000000' },
          { name: 'theme-color', content: '#ffffff' },
        ]}
        link={[
          { rel: 'apple-touch-icon', sizes: '180x180', href: require('./../assets/favicon/apple-touch-icon.png') },
          { rel: 'icon', type: 'image/png', sizes: '32x32', href: require('./../assets/favicon/favicon-32x32.png') },
          { rel: 'icon', type: 'image/png', sizes: '16x16', href: require('./../assets/favicon/favicon-16x16.png') },
          { rel: 'manifest', href: require('./../assets/favicon/site.webmanifest') },
          { rel: 'mask-icon', href: require('./../assets/favicon/safari-pinned-tab.svg'), color: '#000000' },
        ]}
      />

      {isDevEnv && <div className="AppRibbon">Development</div>}

      {profile.loading ? (
        <div
          style={{
            position: 'fixed',
            display: 'flex',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: 'rgba(255, 255, 255, 1)',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={require('./../assets/logo.svg')} alt="Loods 5 - Apps" style={{ maxWidth: 160 }} />
        </div>
      ) : (
        <>
          {profile.present && <AppNavigation />}

          <div className="AppContainer">
            {profile.present && <AppHeader />}
            <AppRouter />
            <AppToastbar />
          </div>
        </>
      )}
    </div>
  );
}
