import * as React from 'react';
import { http } from '../../../http';
import { Form, Formik, FormikActions } from 'formik';
import { AxiosError } from 'axios';

type Props = {
  person: ModelContactPerson;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalContactPersonPasswordReset({ person, closeModal }: Props) {
  async function onSubmit(values: any, formikActions: FormikActions<any>) {
    try {
      await http().post('/core/contact/person/password_reset_start', {
        person: person.id,
      });

      closeModal({ reload: true });
    } catch (exc) {
      const err: AxiosError = exc;
      console.error(exc);

      if (err.response.data && err.response.data.errors) {
        formikActions.setErrors(err.response.data.errors);
      }

      formikActions.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{}}
      onSubmit={onSubmit}
      render={fp => {
        return (
          <Form>
            <div className="modal modal-sm active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">
                    Wachtwoord herstel uitvoeren voor <strong>{person.first}</strong>
                  </div>
                </div>

                <div className="modal-body">
                  Je gaat nu een wachtwoord-herstel mail sturen naar {person.first} op het e-mailadres{' '}
                  <strong>{person.email}</strong>, deze mail bevat een knop waarmee {person.first} direct het wachtwoord
                  van ShopManager POS kan instellen.
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={fp.isSubmitting || Object.keys(fp.errors).length > 0}
                      >
                        <i className="fal fa-envelope" /> Mail versturen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
