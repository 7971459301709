import * as React from 'react';
import { BaseStore, useRxjsStore } from '../../../effects';
import { http, httpLive } from '../../../http';
import Ticket from '../../../lib/Ticket';
import { buildCartTicket, formatCents } from '../../../utils';

type Context = {
  loaded: boolean;
  step: number;
  cart_id: number;

  device: ModalBundleDevice;

  cart: ModelShopCart;
  lines: ModelShopCartLine[];

  payment: ModelFinancePayment;
  ping: boolean;
};

type Props = {
  cart_id: number;
  close: (success?: boolean) => void;
};

class Store extends BaseStore<Context> {
  setup(): Context {
    return {
      loaded: false,
      step: 0,
      cart_id: null,

      device: null,

      cart: null,
      lines: [],

      payment: null,
      ping: false,
    };
  }

  async load() {
    this.next(draft => {
      draft.loaded = false;
    });

    try {
      let res;

      res = await httpLive().post('/devices/group');

      this.next(draft => {
        draft.device = res.data;
      });

      await this.update();
    } catch (e) {
      this.next(draft => {
        draft.loaded = true;
      });
    }
  }

  async update() {
    const res = await http().post('/sale/cart/one', {
      cart: this.current().cart_id,
    });

    const cart = res.data.cart;
    const lines = res.data.lines;

    this.next(draft => {
      draft.loaded = true;

      draft.cart = cart;
      draft.lines = lines;
    });
  }

  async startProcess() {
    let res;
    const current = this.current();

    if (current.payment && current.payment.is_cancelled) {
      await http().post('/sale/payment/reset', {
        id: current.payment.id,
      });
    } else {
      res = await http().post('/sale/cart/final_payment', {
        cart: this.current().cart.id,
        group: this.current().device.group.id,
        terminal: this.current().device.terminal.id,
      });

      this.next(draft => {
        draft.payment = res.data.payment;
      });
    }

    try {
      const res = await httpLive().post('/terminals/process-payment', {
        payment_id: this.current().payment.id,
      });
    } catch (exc) {
      console.error(exc);
    }

    this.next(draft => {
      draft.step = 1;
      draft.ping = true;
    });
  }

  async pingPayment() {
    if (!this.current().ping) return false;

    const res = await http().post('/sale/payment/one', {
      id: this.current().payment.id,
    });

    this.next(draft => {
      draft.payment = res.data.payment;
    });

    if (res.data.payment.is_authorized) {
      await this.update();

      this.next(draft => {
        draft.ping = false;
        draft.step = 2;
      });

      // await this.printTicket();

      this.next(draft => {
        draft.step = 3;
      });
    } else if (res.data.payment.is_cancelled) {
      this.next(draft => {
        draft.ping = false;
        draft.step = 0;
      });
    }
  }

  async terminalsCancelProcess() {
    const res = await httpLive().post('/terminals/cancel-process', {
      terminal_id: this.current().payment.terminal_id,
    });
  }

  async printTicket() {
    this.next(draft => {});

    const current = this.current();
    const ticket_printer_id = current.device.ticket_printer.id;
    const ticket = await buildCartTicket(
      current.cart,
      current.lines,
      [],
      [],
      current.payment && current.payment.foreign_client_ticket,
    );

    await this.ticketsPrint(ticket_printer_id, ticket);

    this.next(draft => {});
  }

  async ticketsPrint(ticket_printer_id, ticket: Ticket) {
    const res = await httpLive().post('/tickets/print', {
      ticket_printer_id,
      ticket: await ticket.generate(),
    });
  }
}

export default function ModalOrderFinalPayment(props: Props) {
  const { context, store } = useRxjsStore<Context, Store>(
    Store,
    (): any => {
      return {
        cart_id: props.cart_id,
      };
    },
    () => {
      store.load();
    },
  );

  React.useEffect(() => {
    if (context && context.ping) {
      const interval = setInterval(() => {
        store.pingPayment();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [context && context.ping]);

  if (!context) return null;

  return (
    <div className="modal active" id="modal-id">
      <div className="modal-overlay" />

      {context.loaded && (
        <div className="modal-container">
          <div className="modal-header">
            <div className="modal-title h5">Eindbetaling #{context.cart.id}</div>
          </div>

          <div className="modal-body">
            <p>
              Het bedrag van {formatCents(context.cart.paid)} is reeds betaald. Het totale bedrag van de order is{' '}
              {formatCents(context.cart.inclusive)},<br />
              hier moet dus nog {formatCents(context.cart.inclusive - context.cart.paid)} van betaald worden.
            </p>
            <p>Klik op starten om de deelbetaling uit te voeren.</p>
          </div>

          <div className="modal-footer">
            {context.step === 0 && (
              <button className="btn btn-link  float-left" onClick={() => props.close(false)}>
                <i className="fal fa-times" /> Annuleren
              </button>
            )}

            {context.step === 1 && (
              <button className="btn btn-error  float-right" onClick={() => store.terminalsCancelProcess()}>
                <i className="fal fa-times" /> Stoppen
              </button>
            )}
            {context.loaded && context.step === 0 && (
              <button className="btn btn-primary  float-right" onClick={() => store.startProcess()}>
                <i className="fal fa-paper-plane" /> Starten
              </button>
            )}
            {context.step === 3 && (
              <button className="btn btn-success  float-right" onClick={() => props.close(true)}>
                <i className="fal fa-check" /> Sluiten
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
