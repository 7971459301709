import * as React from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikActions } from 'formik';
import { http } from '../../../http';
import FormInput from '../../../components/FormInput';
import FormGroup from '../../../components/FormGroup';
import { AxiosError } from 'axios';
import FormOptionSwitch from '../../../components/FormOptionSwitch';
import SpectreContentResource from '../../../components/spectre/SpectreContentResource';
import SpectreAutocomplete from '../../../components/spectre/SpectreAutocomplete';

type Values = {
  first: string;
  infix: string;
  last: string;
  birth: string;
  email: string;
  mobile: string;
  phone: string;
  address_country: string;
  address_zipcode: string;
  address_housenumber: string;
  address_extension: string;
  address_street: string;
  address_city: string;
  avatar_resource: string;
  resume_resource: string;
  motivation_resource: string;
  source: string;
  hours_available: string;
  months_cancellation_period: string;
  is_available_weekends: string;
  has_motivation: string;
  do_sent_applicant_apply_mail: string;
  motivation: string;
};

const schema = Yup.object().shape({
  first: Yup.string()
    .min(1, 'stringMinLength')
    .required('isRequired'),
  infix: Yup.string(),
  last: Yup.string()
    .min(1, 'stringMinLength')
    .required('isRequired'),

  birth: Yup.string().matches(/^\d{1,2}-\d{1,2}-\d{4}$/, 'dateIsRequired'),

  email: Yup.string().email('shouldBeEmail'),
  mobile: Yup.string(),
  phone: Yup.string(),

  // address_country: Yup.number(),
  address_zipcode: Yup.string().test('length', 'stringLength', function(value) {
    if (value === undefined || value === '') {
      return true;
    }

    if (this.parent.country === '1') {
      return value.length >= 6 && value.length <= 7;
    } else {
      return value.length >= 4 && value.length <= 10;
    }
  }),
  // address_housenumber: Yup.string().min(1, 'stringMinLength'),
  // address_extension: Yup.string(),
  // address_street: Yup.string(),
  // address_city: Yup.string(),

  avatar_resource: Yup.number(),
  resume_resource: Yup.number(),
  motivation_resource: Yup.number(),
  source: Yup.number(),

  hours_available: Yup.number()
    .typeError('invalidNumberWhole')
    .integer('invalidNumberWhole')
    .min(0),

  months_cancellation_period: Yup.number()
    .typeError('invalidNumberWhole')
    .integer('invalidNumberWhole')
    .min(0),
  // is_available_weekends: Yup.string(),
  motivation: Yup.string(),
});

type Props = {
  job: ModelVacancyJob;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalJobApplicantCreate({ job, closeModal }: Props) {
  const initialValues: Values = {
    first: '',
    infix: '',
    last: '',

    birth: '',

    email: '',
    mobile: '',
    phone: '',

    address_country: '1',
    address_zipcode: '',
    address_housenumber: '',
    address_extension: '',
    address_street: '',
    address_city: '',

    avatar_resource: '',
    resume_resource: '',
    motivation_resource: '',
    source: '',

    hours_available: '',
    months_cancellation_period: '',
    is_available_weekends: '',
    has_motivation: '',
    do_sent_applicant_apply_mail: '1',
    motivation: '',
  };

  async function onSubmit(values: Values, formikActions: FormikActions<Values>) {
    try {
      await http().post('/core/vacancy/applicant/create', {
        job: job.id,

        first: values.first,
        infix: values.infix,
        last: values.last,
        birth: values.birth || null,
        email: values.email,
        mobile: values.mobile,
        phone: values.phone,
        address_country: values.address_country,
        address_zipcode: values.address_zipcode,
        address_housenumber: values.address_housenumber,
        address_extension: values.address_extension,
        address_street: values.address_street,
        address_city: values.address_city,
        avatar_resource: values.avatar_resource || null,
        resume_resource: values.resume_resource || null,
        motivation_resource: values.motivation_resource || null,
        source: values.source || null,
        hours_available: values.hours_available || 0,
        months_cancellation_period: values.months_cancellation_period || 0,
        is_available_weekends: values.is_available_weekends,
        do_sent_applicant_apply_mail: values.do_sent_applicant_apply_mail,
        motivation: values.motivation,
      });

      closeModal({ reload: true });
    } catch (exc) {
      const err: AxiosError = exc;
      console.error(exc);

      if (err.response.data && err.response.data.errors) {
        formikActions.setErrors(err.response.data.errors);
      }

      formikActions.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      render={fp => {
        return (
          <Form>
            <div className="modal modal-lg active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">
                    Sollicitant <strong>{fp.values.first}</strong> toevoegen aan {job.title}
                  </div>
                </div>

                <div className="modal-body">
                  <div className="columns">
                    <div className="column col-4 col-xs-12">
                      <FormGroup title="Voornaam" name="first" errors={fp.errors}>
                        <FormInput name="first" loading={fp.isSubmitting} />
                      </FormGroup>
                    </div>
                    <div className="column col-auto col-xs-12">
                      <FormGroup title="Tussenvoegsel" name="infix" errors={fp.errors}>
                        <FormInput name="infix" loading={fp.isSubmitting} />
                      </FormGroup>
                    </div>
                    <div className="column col-xs-12">
                      <FormGroup title="Achternaam" name="last" errors={fp.errors}>
                        <FormInput name="last" loading={fp.isSubmitting} />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column col-4 col-xs-12">
                      <FormGroup title="E-mailadres" name="email" errors={fp.errors}>
                        <FormInput name="email" loading={fp.isSubmitting} />
                      </FormGroup>
                    </div>
                    <div className="column col-4 col-xs-12">
                      <FormGroup title="Mobielnummer" name="mobile" errors={fp.errors}>
                        <FormInput name="mobile" loading={fp.isSubmitting} />
                      </FormGroup>
                    </div>
                    <div className="column col-4 col-xs-12">
                      <FormGroup title="Telefoonnummer" name="phone" errors={fp.errors}>
                        <FormInput name="phone" loading={fp.isSubmitting} />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column col-4 col-xs-12">
                      <FormGroup name="hours_available" title="Beschikbaarheid (uren per week)" errors={fp.errors}>
                        <FormInput name="hours_available" />
                      </FormGroup>
                    </div>
                    <div className="column col-4 col-xs-12">
                      <FormGroup name="months_cancellation_period" title="Opzegtermijn (in maanden)" errors={fp.errors}>
                        <FormInput name="months_cancellation_period" />
                      </FormGroup>
                    </div>
                  </div>
                  <FormGroup name="is_available_weekends" title="Weekend beschikbaarheid" errors={fp.errors}>
                    <FormOptionSwitch
                      name="is_available_weekends"
                      title_on="Is beschikbaar in het weekend."
                      title_off="Is niet beschikbaar in het weekend."
                    />
                  </FormGroup>

                  <FormGroup name="has_motivation" title="Motivatie beschikbaar" errors={fp.errors}>
                    <FormOptionSwitch
                      name="has_motivation"
                      title_on="Motivatie kan hieronder opgegeven worden."
                      title_off="Er is geen motivatie geschreven."
                    />
                  </FormGroup>
                  {fp.values.has_motivation && (
                    <FormGroup name="motivation" title="Motivatie" errors={fp.errors}>
                      <FormInput component="textarea" name="motivation" rows={8} />
                    </FormGroup>
                  )}

                  <FormGroup name="source" title="Bron van de sollicitant">
                    <SpectreAutocomplete
                      name="source"
                      fp={fp}
                      url="/core/vacancy/source/autocomplete"
                      allowEmptySearch
                    />
                  </FormGroup>

                  <div className="columns mb-2">
                    <div className="column col-xs-12">
                      <FormGroup name="avatar_resource" title="Profiel-foto upload">
                        <SpectreContentResource name="avatar_resource" fp={fp} folder="vacancy-applicant" />
                      </FormGroup>
                    </div>
                    <div className="column col-xs-12">
                      <FormGroup name="resume_resource" title="C.V. document upload">
                        <SpectreContentResource name="resume_resource" fp={fp} folder="vacancy-applicant" />
                      </FormGroup>
                    </div>
                    <div className="column col-xs-12">
                      <FormGroup name="motivation_resource" title="Motivatie-document upload">
                        <SpectreContentResource name="motivation_resource" fp={fp} folder="vacancy-applicant" />
                      </FormGroup>
                    </div>
                  </div>

                  {job.mail_applicant_apply_id && (
                    <FormGroup
                      name="do_sent_applicant_apply_mail"
                      title="Automatische mail bij aanmelding"
                      errors={fp.errors}
                    >
                      <FormOptionSwitch
                        name="do_sent_applicant_apply_mail"
                        title_on="Verstuur aanmeldings e-mail."
                        title_off="Geen e-mail versturen."
                      />
                    </FormGroup>
                  )}

                  {!job.mail_applicant_apply_id && (
                    <FormGroup
                      name="do_sent_applicant_apply_mail"
                      title="Automatische mail bij aanmelding"
                      errors={fp.errors}
                    >
                      <p className="ml-2 text-warning">
                        Er is geen automatisch mail bij aanmelding ingestelt bij deze vacacture.
                      </p>
                    </FormGroup>
                  )}
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={fp.isSubmitting || Object.keys(fp.errors).length > 0}
                      >
                        <i className="fal fa-plus" /> Toevoegen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
