import PageConnectDashboard from './PageConnectDashboard';

const ConnectStructure = [
  {
    pathname: '/connect/dashboard',
    title: 'Dashboard',
    icon: 'fa-desktop',
    component: PageConnectDashboard,

    rights: ['xxx'],
  },
];

export default ConnectStructure;
