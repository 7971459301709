import * as React from 'react';
import { cs } from '../../utils';
import { LocationService, urlFor } from '../../services/LocationService';
import * as _ from 'underscore';

type Props = {
  icon: string;
  link?: [string, SearchParams?, string?];
  onClick?: (event: React.MouseEvent<any>) => void;
  classNames?: string[];
  tooltip?: string | { title: string; position: string };
  disabled?: boolean;
  blank?: boolean;
};

export default function SpectreButton({ icon, link, onClick, classNames, tooltip, disabled, blank }: Props) {
  const tp = _.isEmpty(tooltip) ? null : _.isString(tooltip) ? { title: tooltip, position: 'top' } : tooltip;

  const className = cs(
    'btn',
    tp && 'tooltip',
    tp && `tooltip-${tp.position}`,
    ...(classNames || ['btn-primary-outline btn-action s-circle mx-1']),
    disabled && 'disabled',
  );

  const [path, search, hash] = link || ['', null, null];
  const href = link ? urlFor(path, search, hash) : 'javascript:';

  function onClickGeneric(event: React.MouseEvent<any>) {
    if (blank) return;
    if (link) event.preventDefault();
    if (onClick) onClick(event);
    if (link) LocationService.navigate(path, search, hash);
  }

  return (
    <a
      href={disabled ? null : href}
      className={className}
      data-tooltip={tp && tp.title}
      onClick={onClickGeneric}
      target={blank ? '_blank' : '_self'}
    >
      <i className={cs('fal', icon)} />
    </a>
  );
}
