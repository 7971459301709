import * as React from 'react';
import { Context, Store } from '../generic/PageOrderGenericOne';
import { GenericDataService } from '../../../services/GenericDataService';
import { cs, formatCents, formatDate, formatMomentAgo, hasRights } from '../../../utils';
import ComponentFinancePaymentKind from '../../../components/ComponentFinancePaymentKind';
import * as _ from 'underscore';

type Props = {
  context: Context;
  store: Store;
};

export default function ComponentOrderPurchaseCustomer({ context, store }: Props) {
  const generic = GenericDataService.useEffect('PageOrderGenericOne');
  const { purchase, payments, creditations } = context;
  const { customer, address_delivery, address_invoice } = purchase;

  const getBuildingById = (id: number) => generic.data.buildings[id];
  const getCountryById = (id: number) => generic.data.countries[id];

  return (
    <div className="columns my-2">
      <div className="column col-4">
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <div className="flex-row flex-space-between">
                <h4>Order</h4>

                {hasRights(['cash.sale']) && (
                  <div>
                    <button
                      className="btn btn-action btn-primary-outline s-circle"
                      onClick={() => store.openModal({ which: 'customer-update' })}
                    >
                      <i className="fal fa-fw fa-pencil" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card-body" style={{ overflow: 'auto' }}>
            <table className="table no-border-bottom">
              <tbody>
                <tr>
                  <th className="has-table-shrink">Locatie</th>
                  <td>{getBuildingById(purchase.cart.building_id).name}</td>
                </tr>
                <tr>
                  <th className="has-table-shrink">OrderNr</th>
                  <td>{purchase.cart_id}</td>
                </tr>
                <tr>
                  <th className="has-table-shrink">Aangemaakt</th>
                  <td>
                    {formatDate(purchase.created_at, 'LL LT')}
                    <br />
                    {formatMomentAgo(purchase.created_at)} geleden
                  </td>
                </tr>

                {purchase.is_customer_hidden && customer && (
                  <>
                    <tr>
                      <th className="has-table-shrink">Klant</th>
                      <td>Via Customer-Display ingevoerd</td>
                    </tr>
                  </>
                )}

                {!purchase.is_customer_hidden && customer && (
                  <>
                    {!!customer.name && (
                      <tr>
                        <th className="has-table-shrink">Klantnaam</th>
                        <td>{customer.name}</td>
                      </tr>
                    )}
                    {!!customer.email && (
                      <tr>
                        <th className="has-table-shrink">E-mailadres</th>
                        <td>
                          <a href={`mailto:${customer.email}`}>{customer.email}</a>
                        </td>
                      </tr>
                    )}
                    {!!customer.phone && (
                      <tr>
                        <th className="has-table-shrink">Telefoonnummer</th>
                        <td>{customer.phone}</td>
                      </tr>
                    )}
                    {!!customer.mobile && (
                      <tr>
                        <th className="has-table-shrink">Mobielnummer</th>
                        <td>{customer.mobile}</td>
                      </tr>
                    )}
                    {!!customer.company_name && (
                      <tr>
                        <th className="has-table-shrink">Bedrijf</th>
                        <td>{customer.company_name}</td>
                      </tr>
                    )}
                    {!!customer.company_vat && (
                      <tr>
                        <th className="has-table-shrink">BTW nr</th>
                        <td>{customer.company_vat}</td>
                      </tr>
                    )}
                  </>
                )}
                {!purchase.is_customer_hidden && !customer && (
                  <tr>
                    <td colSpan={2}>
                      <em>Geen klantgegevens opgegeven</em>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="column col-4">
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <div className="flex-row flex-space-between">
                <h4>Leveradres</h4>

                {hasRights(['cash.sale']) && (
                  <div>
                    <button
                      className="btn btn-action btn-primary-outline s-circle"
                      onClick={() => store.openModal({ which: 'address-delivery-update' })}
                    >
                      <i className="fal fa-fw fa-pencil" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {address_delivery && (
            <div className="card-body">
              {address_delivery.street} {address_delivery.housenumber}{' '}
              {address_delivery.extension && <span>&ndash; {address_delivery.extension}</span>}
              <br />
              {address_delivery.zipcode}, {address_delivery.city}
              <br />
              {getCountryById(address_delivery.country_id).name}
              {address_delivery.has_company && (
                <div className="mt-1">
                  {address_delivery.company_name}
                  <br />
                  {address_delivery.company_vat && <span>BTW: {address_delivery.company_vat}</span>}
                </div>
              )}
            </div>
          )}

          {!address_delivery && (
            <div className="card-body">
              <em>Geen leveringsadres opgegeven</em>
            </div>
          )}
        </div>
        <div className="card mt-2">
          <div className="card-header">
            <div className="card-title">
              <div className="flex-row flex-space-between">
                <h4>Factuuradres</h4>

                {hasRights(['cash.sale']) && (
                  <div>
                    <button
                      className="btn btn-action btn-primary-outline s-circle"
                      onClick={() => store.openModal({ which: 'address-invoice-update' })}
                    >
                      <i className="fal fa-fw fa-pencil" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {address_invoice && (
            <div className="card-body">
              {address_invoice.street} {address_invoice.housenumber}{' '}
              {address_invoice.extension && <span>&ndash; {address_invoice.extension}</span>}
              <br />
              {address_invoice.zipcode}, {address_invoice.city}
              <br />
              {getCountryById(address_invoice.country_id).name}
              {address_invoice.has_company && (
                <div className="mt-1">
                  {address_invoice.company_name}
                  <br />
                  {address_invoice.company_vat && <span>BTW: {address_invoice.company_vat}</span>}
                </div>
              )}
            </div>
          )}

          {!address_invoice && (
            <div className="card-body">
              <em>Geen facturatieadres opgegeven</em>
            </div>
          )}
        </div>
      </div>

      {hasRights(['cash.sale']) && (
        <div className="column">
          <Ledger
            purchase={purchase}
            payments={payments}
            creditations={creditations}
            openModal={(payment: ModelFinancePayment) =>
              store.openModal({ which: 'payment-delete', data: { payment } })
            }
          />
        </div>
      )}
    </div>
  );
}

type LedgerProps = {
  purchase: ModelOrderPurchase;
  payments: ModelFinancePayment[];
  creditations: ModelFinanceCreditation[];
  openModal: (payment: ModelFinancePayment) => void;
};

function Ledger({ purchase, payments, creditations, openModal }: LedgerProps) {
  const purchase_has_cancellations = purchase.amount_cancelled > 0;
  const payment_is_equal = purchase.current_inclusive == purchase.paid;

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <div className="flex-row flex-space-between">
              <h4>Betalingsoverzicht</h4>
            </div>
          </div>
        </div>

        <table className="table">
          <tbody>
            {purchase_has_cancellations && (
              <tr>
                <th>Origineel</th>
                <td className="text-right" colSpan={2}>
                  {formatCents(purchase.inclusive)}
                </td>
              </tr>
            )}
            <tr>
              <th>Totaalbedrag</th>
              {purchase_has_cancellations && (
                <td className="text-right">{formatCents(purchase.current_inclusive - purchase.inclusive)}</td>
              )}
              <td className="text-right" colSpan={purchase_has_cancellations ? 1 : 2}>
                {formatCents(purchase.current_inclusive)}
              </td>
            </tr>
            <tr>
              <th>Betaald door klant</th>
              <td className="text-right" colSpan={2}>
                {formatCents(purchase.paid)}
              </td>
            </tr>
            {purchase.current_inclusive > purchase.paid && (
              <tr className="bg-warning">
                <th>Restbedrag</th>
                <td className="text-right" colSpan={2}>
                  {formatCents(purchase.current_inclusive - purchase.paid)}
                </td>
              </tr>
            )}
            {purchase.current_inclusive < purchase.paid && (
              <tr className="bg-error">
                <th>Creditatiebedrag</th>
                <td className="text-right" colSpan={2}>
                  {formatCents(purchase.paid - purchase.current_inclusive)}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {payments.length > 0 && (
        <div className="card mt-2">
          <div className="card-header">
            <div className="card-title">
              <div className="flex-row flex-space-between">
                <h4>Betalingen</h4>
              </div>
            </div>
          </div>

          <table className="table">
            <tbody>
              {payments.map(payment => (
                <tr
                  key={payment.id}
                  className={cs(_.contains(['by-bank', 'internal-transfer'], payment.kind) && 'text-error')}
                >
                  <th>
                    <ComponentFinancePaymentKind kind={payment.kind} />
                  </th>
                  <td>{payment.authorized_by && payment.authorized_by.name}</td>
                  <td className="text-right has-table-shrink-nowrap">{formatDate(payment.authorized_at)}</td>
                  <td className="text-right has-table-shrink-nowrap">{formatCents(payment.original_amount)}</td>
                  {hasRights(['cash.payment-delete']) && (
                    <td className="has-table-shrink-nowrap">
                      {payment.kind == 'by-bank' && (
                        <button type="button" className="btn btn-error ml-1" onClick={() => openModal(payment)}>
                          <i className="fal fa-trash" />
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
              <tr className="bg-gray">
                <th colSpan={3}>Totaal</th>
                <td className="text-right has-table-shrink-nowrap">
                  {formatCents(payments.reduce((r, x) => r + x.original_amount, 0))}
                </td>
                {hasRights(['cash.payment-delete']) && <td />}
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {creditations.length > 0 && (
        <div className="card mt-2">
          <div className="card-header">
            <div className="card-title">
              <div className="flex-row flex-space-between">
                <h4>Creditaties</h4>
              </div>
            </div>
          </div>

          <table className="table">
            <tbody>
              {creditations.map(creditation => (
                <tr
                  key={creditation.id}
                  className={cs(_.contains(['by-bank', 'internal-transfer'], creditation.kind) && 'text-error')}
                >
                  <th>
                    <ComponentFinancePaymentKind kind={creditation.kind} />
                  </th>
                  <td className="text-right has-table-shrink-nowrap">{formatDate(creditation.authorized_at)}</td>
                  <td className="text-right has-table-shrink-nowrap">{formatCents(creditation.original_amount)}</td>
                </tr>
              ))}
              <tr className="bg-gray">
                <th colSpan={2}>Totaal</th>
                <td className="text-right has-table-shrink-nowrap">
                  {formatCents(creditations.reduce((r, x) => r + x.original_amount, 0))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
