import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { cs } from '../../../utils';
import { displayError } from '../../../components/FormGroup';
import FormInput from '../../../components/FormInput';
import { http } from '../../../http';

type Props = {
  close: () => void;
  select: (customer: ModelContactPersonSimple) => void;
};

type Values = {
  search: string;
};

type State = {
  loading: boolean;
  people: ModelContactPersonSimple[];
};

export default function ModalPersonSearch(props: Props) {
  const error = null;

  const [state, setState] = React.useState<State>({
    loading: false,
    people: [],
  });

  async function onSubmit(values: Values, fp: FormikProps<Values>) {
    const search = values.search;

    setState({
      loading: true,
      people: [],
    });

    try {
      const res = await http().post('/sale/cart/person_search', values);

      if (/^\d+$/.exec(search) && res.data.records.length === 1) {
        props.select(res.data.records[0]);
      } else {
        setState({ loading: false, people: res.data.records });
      }
    } catch (e) {
      console.log(e);
      setState({ loading: false, people: [] });
    }
  }

  return (
    <div className="modal active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Medewerker opzoeken</div>
        </div>

        <div className="modal-body">
          <Formik
            initialValues={{
              search: '',
            }}
            onSubmit={onSubmit}
            render={fp => (
              <Form style={{ padding: '0 1rem' }}>
                <div className={cs('form-group', error && 'has-error')}>
                  <div className="columns">
                    <div className="column  pr-2">
                      <label className="form-label">Zoeken</label>
                      <FormInput name="search" />
                      {error && <div className="form-input-hint">{displayError(error)}</div>}
                    </div>
                    <div className="column col-auto" style={{ alignSelf: 'center' }}>
                      <button
                        type="submit"
                        className="btn btn-primary-outline btn-action s-circle"
                        disabled={state.loading}
                      >
                        <i className="fal fa-fw fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          />

          {state.loading && <p>We zijn aan het laden!</p>}
          {!state.loading && state.people.length > 0 && (
            <div className="pt-2">
              <table className="table  table-hover">
                <tbody>
                  {state.people.map(person => (
                    <tr key={person.id} onClick={() => props.select(person)}>
                      <td className="has-table-shrink-nowrap">#{person.id}</td>
                      <td>{person.name}</td>
                      <td>{person.email}</td>
                      <td className="has-table-shrink-nowrap">
                        <i className="fal fa-fw fa-hand-point-up" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className="modal-footer">
          <button className="btn btn-link  float-left" onClick={props.close}>
            <i className="fal fa-times" /> Annuleren
          </button>
        </div>
      </div>
    </div>
  );
}
