import * as React from 'react';
import { ContentBlock, DraftHandleValue, Editor, EditorState, RichUtils } from 'draft-js';
import { cs } from '../utils';

type FormEditorProps = {
  name: string;
  onChange: (field: string, value: EditorState) => void;
  editorState: EditorState;
};

export default function FormEditor(props: FormEditorProps) {
  const me = React.useRef(null);
  const ref = React.useRef(null);

  const [fixed, setFixed] = React.useState(0);

  function handleResize() {
    const rect = me.current.getBoundingClientRect();

    if (rect.top < 0 && rect.bottom > 133) {
      setFixed(Math.abs(rect.top));
    } else {
      setFixed(0);
    }
  }

  React.useEffect(() => {
    const handler = setInterval(handleResize, 150);

    return () => {
      clearTimeout(handler);
    };
  }, [me]);

  // region Functions

  function focus() {
    if (ref.current) {
      (ref.current as any).focus();
    }
  }

  function handleKeyCommand(command: string): DraftHandleValue {
    const state = RichUtils.handleKeyCommand(props.editorState, command);
    if (state) {
      onChange(state);
      return 'handled';
    }

    return 'not-handled';
  }

  function onChange(editorState: EditorState) {
    props.onChange(props.name, editorState);
  }

  function onTab(event: React.KeyboardEvent<{}>) {
    onChange(RichUtils.onTab(event, props.editorState, 4));
  }

  function toggleInlineStyle(inlineStyle: string) {
    onChange(RichUtils.toggleInlineStyle(props.editorState, inlineStyle));
  }

  function toggleBlockType(blockType: string) {
    onChange(RichUtils.toggleBlockType(props.editorState, blockType));
  }

  function getBlockStyle(block: ContentBlock) {
    switch (block.getType()) {
      case 'blockquote':
        return 'RichEditor-blockquote';
      default:
        return '';
    }
  }

  // endregion

  return (
    <div
      className="RichEditor-root"
      ref={me}
      style={
        fixed
          ? {
              position: 'relative',
              paddingTop: 33,
            }
          : { position: 'relative' }
      }
    >
      <div
        className="RichEditor-controls-container"
        style={
          fixed
            ? {
                position: 'absolute',
                top: fixed + 50,
                width: '100%',
                zIndex: 4,
                backgroundColor: '#fff',
              }
            : {
                backgroundColor: '#fff',
              }
        }
      >
        <StyleControls
          editorState={props.editorState}
          onBlockToggle={toggleBlockType}
          onInlineToggle={toggleInlineStyle}
        />
      </div>
      <div className="RichEditor-editor" onClick={focus}>
        <Editor
          blockStyleFn={getBlockStyle}
          editorState={props.editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={onChange}
          onTab={onTab}
          ref={ref}
          spellCheck={true}
        />
      </div>
    </div>
  );
}

interface StyleButtonProps {
  active: boolean;
  tooltip: string;
  icon: string;
  style: string;
  onToggle: (style: string) => void;
}

function StyleButton(props: StyleButtonProps) {
  return (
    <div
      className={cs('btn btn-primary-outline tooltip tooltip-bottom mr-1', props.active && 'active')}
      onMouseDown={e => ~e.preventDefault() && props.onToggle(props.style)}
      data-tooltip={props.tooltip}
    >
      <i className={cs('fal fa-fw', props.icon)} />
    </div>
  );
}

const BLOCK_TYPES = [
  { tooltip: 'Kop 1', icon: 'fa-h1', style: 'header-one' },
  { tooltip: 'Kop 2', icon: 'fa-h2', style: 'header-two' },
  { tooltip: 'Kop 3', icon: 'fa-h3', style: 'header-three' },
  { tooltip: 'Quote', icon: 'fa-quote-right', style: 'blockquote' },
  { tooltip: 'Opsommingslijst', icon: 'fa-list-ul', style: 'unordered-list-item' },
  { tooltip: 'Genummerde lijst', icon: 'fa-list-ol', style: 'ordered-list-item' },
];

const INLINE_STYLES = [
  { tooltip: 'Vet', icon: 'fa-bold', style: 'BOLD' },
  { tooltip: 'Cursief', icon: 'fa-italic', style: 'ITALIC' },
  { tooltip: 'Onderstrepen', icon: 'fa-underline', style: 'UNDERLINE' },
];

interface StyleControlsProps {
  editorState: EditorState;
  onBlockToggle: (style: string) => void;
  onInlineToggle: (style: string) => void;
}

function StyleControls(props: StyleControlsProps) {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const currentStyle = props.editorState.getCurrentInlineStyle();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.style}
          active={type.style === blockType}
          tooltip={type.tooltip}
          icon={type.icon}
          onToggle={props.onBlockToggle}
          style={type.style}
        />
      ))}

      <div className="mx-2" style={{ display: 'inline-block' }}>
        &nbsp;
      </div>

      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.style}
          active={currentStyle.has(type.style)}
          tooltip={type.tooltip}
          icon={type.icon}
          onToggle={props.onInlineToggle}
          style={type.style}
        />
      ))}
    </div>
  );
}
