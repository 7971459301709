import { formatCents, formatEan13Number, formatGiftcardIdentifier } from '../../utils';
import * as React from 'react';
import {
  BottomData,
  Button,
  ButtonGroup,
  Col,
  Data,
  GiftCardAmount,
  ItemData,
  Label,
  List,
  ListItem,
  ListSubItem,
  RoundedNumber,
  SubLabel,
  Text,
  theme,
  TopData,
  Value,
} from './Components';
import { PageDisplayCtx } from './PageDisplay';

export default function PaneCart() {
  const { context, store } = React.useContext(PageDisplayCtx);

  const total_discount = context.lines.reduce((value, line) => {
    return value + line.discount_price + line.manual_discount_price;
  }, 0);

  let inclusive_subtracting_giftcards = context.cart.deposit;

  return (
    <React.Fragment>
      <Col left>
        {!context.customer && <ContentGoingPaperless />}
        {context.customer && <ContentSwapAndRetour />}
      </Col>
      <Col right>
        <TopData>
          <List>
            {[...context.lines].reverse().map(line => {
              return (
                <ListItem key={line.id}>
                  <ItemData amount>{line.amount}</ItemData>
                  <ItemData>
                    <Data>
                      <span>{line.article.name}</span>
                    </Data>
                    <Data>{formatEan13Number(line.article.number_beeyond)}</Data>
                  </ItemData>
                  <ItemData price>
                    <Data>{formatCents(line.calculated_inclusive * line.amount)}</Data>
                    {(line.discount_price > 0 || line.manual_discount_value > 0) && (
                      <Data>{formatCents(line.original_inclusive * line.amount)}</Data>
                    )}
                  </ItemData>
                </ListItem>
              );
            })}
          </List>
        </TopData>
        <BottomData>
          <List totals>
            <ListItem discounts>
              <ListSubItem>
                <Label>Subtotaal</Label>
                <Value>{formatCents(context.lines.reduce((p, l) => p + l.original_inclusive * l.amount, 0))}</Value>
              </ListSubItem>
              {total_discount > 0 && (
                <ListSubItem>
                  <Label>Korting</Label>
                  <Value>- {formatCents(total_discount)}</Value>
                </ListSubItem>
              )}

              {context.giftcards_payment.map(gp => {
                let using = 0;

                if (gp.balance_before > inclusive_subtracting_giftcards) {
                  using = inclusive_subtracting_giftcards;
                } else {
                  using = gp.balance_before;
                }

                inclusive_subtracting_giftcards -= using;

                return (
                  <ListSubItem>
                    <Label>
                      Cadeaukaart {formatGiftcardIdentifier(gp.giftcard.identifier, true)}
                      <GiftCardAmount>{formatCents(gp.balance_before)}</GiftCardAmount>
                    </Label>
                    <Value>- {formatCents(using)}</Value>
                  </ListSubItem>
                );
              })}
            </ListItem>
            <ListItem>
              <Label total>
                Te betalen
                {context.cart.vat_21 > 0 && <SubLabel>incl. {formatCents(context.cart.vat_21)} btw 21%</SubLabel>}
                {context.cart.vat_9 > 0 && <SubLabel>incl. {formatCents(context.cart.vat_9)} btw 9%</SubLabel>}
                {context.cart.vat_6 > 0 && <SubLabel>incl. {formatCents(context.cart.vat_6)} btw 6%</SubLabel>}
              </Label>
              <Value total>{formatCents(inclusive_subtracting_giftcards)}</Value>
            </ListItem>

            {context.pane === 'payment' && (
              <ListItem payment>
                <div style={{ textAlign: 'center' }}>
                  <Text primary>Je kunt nu betalen</Text>
                  {context.customer && context.customer.email ? (
                    <Text>
                      Je bon gaat direct naar <strong>{context.customer.email}</strong>
                    </Text>
                  ) : (
                    <Text>
                      Je bon ontvangt <strong>geen bon via e-mail!</strong>
                    </Text>
                  )}
                </div>
              </ListItem>
            )}
          </List>

          {context.pane === 'cart' && !context.customer && (
            <ButtonGroup>
              <Button primary type="button" onClick={() => store.openModal({ which: 'email' })}>
                Bon?&nbsp; Vul je e-mailadres in!
              </Button>
            </ButtonGroup>
          )}

          {context.pane === 'cart' && context.customer && (
            <ListItem payment>
              <div style={{ textAlign: 'center' }}>
                <Text primary>
                  Je bon gaat direct naar
                  <br /> <strong>{context.customer.email}</strong>
                </Text>
              </div>
            </ListItem>
          )}
        </BottomData>
      </Col>
    </React.Fragment>
  );
}

function ContentGoingPaperless() {
  return (
    <div
      style={{
        backgroundColor: theme.primary,
        color: 'white',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        fontSize: 65,
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 100,
        padding: '0.25em 1em',
        lineHeight: 1.2,
      }}
    >
      Loods 5<br />
      gaat paperless!
      <br />
      <br />
      Vul je
      <br />
      e-mailadres in en
      <br />
      ontvang de bon
      <br />
      in je mailbox.
      <br />
      <div
        style={{
          marginTop: 155,
          position: 'relative',
          width: '100%',
        }}
      >
        <div
          style={{
            position: 'relative',
            marginTop: 14,
            background: 'white',
            height: 3,
          }}
        />
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: -8,
            content: '',
            display: 'inline-block',
            width: 20,
            height: 20,
            borderRight: '3px solid white',
            borderTop: '3px solid white',
            transform: 'rotate(45deg)',
          }}
        />
      </div>
    </div>
  );
}

function ContentSwapAndRetour() {
  const data = window.GLOBAL_GENERIC_DATA;

  return (
    <div
      style={{
        backgroundColor: theme.primary,
        position: 'relative',
        color: 'white',
        width: '100%',
        height: '100%',
        textAlign: 'center',
        fontSize: 65,
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 100,
        // padding: '0.25em 1em',
        lineHeight: 1.2,
      }}
    >
      <div style={{ position: 'absolute', transform: 'translate(0, -50%)', top: '50%', padding: '0.25em 1em' }}>
        {data.device.me.building_id === 5 ? (
          <>
            Bij de Garage <br /> aangekochte artikelen <br /> kunnen niet <br />
            meer geruild of <br />
            geretourneerd worden
          </>
        ) : (
          <>
            Ruilen & retouren
            <br />
            kan binnen 14
            <br />
            dagen in één van
            <br />
            onze winkels.
          </>
        )}
      </div>
    </div>
  );
}
