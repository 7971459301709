import { formatCentsShort, formatMomentAgo, formatPercentage } from '../../../utils';
import * as React from 'react';

type Props = {
  line: ModelShopCartLine;
  imageSize?: number;
  onDuplicateLine?: (line: ModelShopCartLine) => any;
  onUpdateLineAmountClick?: (line_id: number, amount: number) => any;
  onOpenUpdateArticle?: (line: ModelShopCartLine) => any;
};

export default function ComponentTrLine({
  line,
  imageSize,
  onDuplicateLine,
  onUpdateLineAmountClick,
  onOpenUpdateArticle,
}: Props) {
  return (
    <tr>
      {onUpdateLineAmountClick && (
        <td className="text-center">
          <a
            href="javascript:"
            className="btn btn-error-outline btn-action s-circle"
            onClick={onUpdateLineAmountClick(line.id, -1)}
          >
            {line.amount > 1 ? <i className="fal fa-minus" /> : <i className="fal fa-times" />}
          </a>
        </td>
      )}
      <td className="px-0 text-center">
        {line.article.images.length > 0 ? (
          <img
            className="img-fit-contain s-rounded"
            src={line.article.images[0].href}
            style={{
              width: imageSize || 64,
              height: imageSize || 64,
              display: 'inline-block',
              border: '1px solid rgba(0,0,0,.1)',
            }}
            alt={line.article.name}
          />
        ) : (
          <img src="https://via.placeholder.com/150" alt="150x150" style={{ width: imageSize || 64 }} />
        )}
      </td>
      <td className="px-0  text-center" style={{ fontSize: '1.4em' }}>
        {line.amount}x
      </td>
      <td>
        <strong>{line.article.name}</strong>
        <div>
          {line.configuration && (
            <div>
              <small className="text-overflow" style={{ display: 'inline-block', width: '95%' }}>
                {line.configuration}
              </small>
            </div>
          )}

          <div>
            <span className="label">
              <span className="mr-1">
                <strong>{line.amount}</strong>
                <i className="fal fa-times ml-1" />
              </span>
              (
              <span className="tooltip" data-tooltip="Origineel bedrag.">
                {formatCentsShort(line.original_inclusive)}
              </span>
              {line.manual_increase_price > 0 && (
                <span className="ml-1 text-warning  tooltip" data-tooltip="Prijsverhoging.">
                  <i className="fal fa-plus" /> {formatCentsShort(line.manual_increase_price)}
                </span>
              )}
              {line.discount_price > 0 && (
                <span className="ml-1 text-success  tooltip" data-tooltip="Berekende korting.">
                  <i className="fal fa-minus" /> {formatCentsShort(line.discount_price)}
                </span>
              )}
              {line.manual_discount_price > 0 && (
                <span className="ml-1 text-success  tooltip" data-tooltip="Gegeven korting.">
                  <i className="fal fa-minus" /> {formatCentsShort(line.manual_discount_price)}
                </span>
              )}
              )
            </span>
          </div>

          {',delivery,pickup,'.indexOf(line.transfer) !== -1 && (
            <div>
              <i className="fal fa-truck-container" /> levering tussen: {formatMomentAgo(line.delivery_min_at)} en{' '}
              {formatMomentAgo(line.delivery_max_at)}
            </div>
          )}

          <div>
            {line.transfer !== 'carry' && (
              <span className="label label-primary mr-2">
                {line.transfer === 'delivery' && 'Thuisbezorgen'}
                {line.transfer === 'pickup' && 'Ophalen'}
                {line.transfer === 'direct-pickup' && 'Direct ophalen'}
              </span>
            )}
            {line.deposit_percentage !== 10000 && (
              <span className="label label-success mr-2">aanbetaling {formatPercentage(line.deposit_percentage)}</span>
            )}
          </div>
        </div>
      </td>
      <td className="text-right">{formatCentsShort(line.inclusive)}</td>
      {onDuplicateLine && onOpenUpdateArticle && onUpdateLineAmountClick && (
        <td className="text-right  has-table-shrink-nowrap">
          <a
            href="javascript:"
            className="btn btn-action s-circle btn-primary-outline  mr-1"
            onClick={onDuplicateLine(line)}
          >
            <i className="fal fa-clone" />
          </a>
          <a
            href="javascript:"
            className="btn btn-action s-circle btn-primary-outline  mr-1"
            onClick={onOpenUpdateArticle(line)}
          >
            <i className="fal fa-pencil" />
          </a>
          <a
            href="javascript:"
            className="btn btn-success-outline btn-action s-circle"
            style={{ marginLeft: 12 }}
            onClick={onUpdateLineAmountClick(line.id, 1)}
          >
            <i className="fal fa-plus" />
          </a>
        </td>
      )}
    </tr>
  );
}
