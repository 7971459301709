import * as React from 'react';
import Render from './Render';

export default function PageDocOrderTenantMany() {
  return <Render markdown={markdown} />;
}

const markdown = `

# Inleiding

Welkom bij de handleiding van Loods 5 ShopManager Orders. We zoomen hier in
op de werkzaamheden die jij als deelnemer kunt uitvoeren. Wanneer je bent ingelogd
ga je links in het menu naar *Deelnemers Orders*, waarna je klikt op *Overzicht*. 

![Stap](${GLOBAL_APIS_URL}static/doc/order-tenant-many/Schermafbeelding%202020-09-03%20om%2021.46.44.png)

## Orders overzicht

Eenmaal het overzicht geopent, focust de applicatie zich direct op alle *Nieuwe
orders* die in het systeem staan. Hier vind je alle nieuwe orders die zijn verkocht bij Loods 5.

Je kunt in dit overzicht zien waar de order is verkocht, wie de klant is, wat het totaalbedrag is, en
wat de onderliggende status van de artikelen zijn. In het gegeven voorbeeld zijn er twee orders gemaakt.
Order #443512 heeft een groen icoontje achter het bedrag (volledig betaald), terwijl order #443509 een
rood icoon heeft (deels betaald).

We zullen zo starten met de order met het groene icoon.

![Stap](${GLOBAL_APIS_URL}static/doc/order-tenant-many/Schermafbeelding%202020-09-03%20om%2021.46.52.png)

## Order geopend

We hebben nu Order #443512 geopend, je kunt hier alle informatie over de order vinden
die nodig is om de order te kunnen verwerken. 

![Stap](${GLOBAL_APIS_URL}static/doc/order-tenant-many/Schermafbeelding%202020-09-03%20om%2021.47.12.png)

## Verwerken

Zodra je met de artikelen van de order aan de slag gaat, klik je op *Verwerken*. Dit zorgt er
voor dat de status van de artikelen wijzigt. Je krijgt een popup te zien waar je kunt aangeven
welke artikelen in de order je graag wilt verwerken.

De applicatie selecteert automatisch alle artikelen die nog de status *Nieuw* hebben.

![Stap](${GLOBAL_APIS_URL}static/doc/order-tenant-many/Schermafbeelding%202020-09-03%20om%2021.47.23.png)

## Verwerkt

Wanneer je de popup hebt verstuurd staat het artikel in de order op verwerkt. Zo kan iedereen
die met de order werkt zien welke artikelen al door jou zijn opgepakt om te verwerken. Dit
is een belangrijke stap omdat het winkelpersoon de artikelen die op *Nieuw* staan nog kunnen muteren.
Door de artikelen te verwerken geef je aan dat jij de order hebt verwerkt, en dat wijzigen van
de order alleen nog maar in overleg met jou uitgevoerd kan worden.

![Stap](${GLOBAL_APIS_URL}static/doc/order-tenant-many/Schermafbeelding%202020-09-03%20om%2021.47.30.png)

## In behandeling overzicht

Terug in het *Order overzicht* is de order nu verhuist naar het tabje *In behandeling*. Door
alle nieuwe orders goed bij te houden heb je dus altijd een goed overzicht van orders die
nieuw zijn binnengekomen, en orders die je zelf al verwerkt hebt. Ook het winkelpersoneel
kan deze informatie inzien, en zo de klant hiervan op de hoogte brengen, wanneer deze hier
naar vraag.

![Stap](${GLOBAL_APIS_URL}static/doc/order-tenant-many/Schermafbeelding%202020-09-03%20om%2021.47.39.png)

## Gereed

Klik je binnen de order op *Gereed*, dan markeer je de artikelen klaar voor transport. Deze stap
kun je gebruiken voor je eigen administratie, maar ook wanneer de artikelen zijn klaargezet in het
magazijn van Loods 5, klaar voor transport of om op te halen door de klant.

![Stap](${GLOBAL_APIS_URL}static/doc/order-tenant-many/Schermafbeelding%202020-09-03%20om%2021.47.47.png)

## Gereed overzicht 

Terug in het *Order overzicht* is de order nu verhuist naar het tabje *Gereed*. 

De magazijnmedewerkers van Loods 5 houden deze tab goed in de gaten, komen hier nieuwe orders
en artikelen binnen, dan zullen zij hier actie gaan ondernemen.

![Stap](${GLOBAL_APIS_URL}static/doc/order-tenant-many/Schermafbeelding%202020-09-03%20om%2021.48.00.png)

## Onderweg

Wil je de klant goed op de hoogte houden, dan kun je deze status gebruiken. De magazijnmedewerkers
van Loods 5 gebruiken deze status om aan te geven dat de order is meegenomen uit het magazijn
om deze te leveren aan de klant.

![Stap](${GLOBAL_APIS_URL}static/doc/order-tenant-many/Schermafbeelding%202020-09-03%20om%2021.48.13.png)

## Geleverd

is de levering bij de klant geweest dan kun je de artikelen op *Geleverd* zetten. Wanneer je dit
doet komt het artikel automatisch, direct, op het weekstatement terecht.

Verstuur je de artikelen via het magazijn? Dan zetten de magazijnmedewerkers deze artikelen op geleverd.

![Stap](${GLOBAL_APIS_URL}static/doc/order-tenant-many/Schermafbeelding%202020-09-03%20om%2021.48.19.png)

Na het leveren kun je onder in de order zien welke statementregels hier uit voort zijn gekomen.

![Stap](${GLOBAL_APIS_URL}static/doc/order-tenant-many/Schermafbeelding%202020-09-03%20om%2021.48.23.png)

## Geleverd overzicht

Het geleverd overzicht bevat *alle* orders die zijn geleverd. Onder geleverde orders beschouwen we
alle orders waarvan de klant het artikel in handen heeft. Wanneer er een direct kassaverkoop is
zien we dit dus in feite als een *directe levering*.

# Deels betaalde orders

Wanneer de klant een aankopen doet met een aanbetaling, dan is er een speciaal proces actief
in de applicatie.

## Orders overzicht

Je kunt deels betaalde orders herkennen aan het rode icoon naast het bedrag. Deze geeft visueel aan
dat het bedrag nog niet helemaal is voldaan.

![Stap](${GLOBAL_APIS_URL}static/doc/order-tenant-many/Schermafbeelding%202020-09-03%20om%2021.48.47.png)

## Order bekijken

Open je deze order, dan kun je zien dat je de artikelen niet kunt markeren als *Onderweg* of
als *Geleverd*. De klant heeft immers nog niet het hele bedrag voldaan.

![Stap](${GLOBAL_APIS_URL}static/doc/order-tenant-many/Schermafbeelding%202020-09-03%20om%2021.48.53.png)

## Verzoek restbetaling

Wanneer je de artikelen gereed hebt, ze zijn dus leverbaar aan de klant, dan kunnen we een
verzoek tot restbetaling versturen. Je kunt deze functionaliteit zelf gebruiken, of in
overleg met de winkelmedewerkers van Loods 5, aan hun over laten.

![Stap](${GLOBAL_APIS_URL}static/doc/order-tenant-many/Schermafbeelding%202020-09-03%20om%2021.48.56.png)

## Verstuur verzoek restbetaling

Het dialoog laat zien of er een mail is verstuurd naar de klant. Na het klikken op versturen kan
het even duren voordat het bericht verschijnt, dat ligt aan de drukte op de server. We markeren
berichten die onlangs verstuurd zijn met oranje, zo zie je direct of je de het verzoek wel zou
moeten versturen. Wanneer het bericht oranje is, wil je liever de klant nog even een paar dagen
geven!

![Stap](${GLOBAL_APIS_URL}static/doc/order-tenant-many/Schermafbeelding%202020-09-03%20om%2021.49.27.png)

`;
