import * as React from 'react';
import { PageDefinition } from './PageState';
import { isWithinMz } from '../../../utils';

export default function ComponentButtons(props: { page: PageDefinition }) {
  const { context, store } = props.page;

  function onAbandonClick() {
    store.toggleAbandon(true);
  }
  function onParkClick() {
    store.togglePark(true);
  }

  const carryOnly = context.lines.every(line => line.transfer === 'carry');
  const hasCustomer = !!context.customer;
  const hasAddressDelivery = !!context.address_delivery;

  const submitDisabled = !context.cart || (!carryOnly && (!hasCustomer || !hasAddressDelivery));

  return (
    <div className="card-footer">
      <div className="flex-row flex-space-between">
        <div>
          <button
            type="button"
            className="btn mr-2  btn-error-outline"
            onClick={onAbandonClick}
            disabled={!context.cart}
          >
            <i className="fal fa-fw fa-times" />
            {!(context.touch && context.touch.is_large) && 'Annuleren'}
          </button>
          <button type="button" className="btn mr-2" onClick={onParkClick} disabled={!context.cart}>
            <i className="fal fa-fw fa-parking-circle" />
            {!(context.touch && context.touch.is_large) && 'Parkeren'}
          </button>

          {context.lines.length > 0 && !carryOnly && (
            <button
              type="button"
              className="btn btn-primary-outline mr-2"
              onClick={() => props.page.store.modalQuotationOpen()}
            >
              <i className="fal fa-file-pdf" /> Offerte downloaden
            </button>
          )}
        </div>
        <div>
          {isWithinMz() ? (
            <button
              type="button"
              className="btn btn-primary btn-lg"
              onClick={() => props.page.store.modalPaymentOpen()}
              disabled={submitDisabled}
            >
              <i className="fal fa-caret-right" /> Betalen
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary btn-lg"
              onClick={() => props.page.store.openFinish()}
              disabled={submitDisabled}
            >
              <i className="fal fa-caret-right" /> Bevestigen &amp; versturen
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
