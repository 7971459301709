import * as React from 'react';

const SocketContext = React.createContext<{
  socket: WebSocket;
  readyState: number;
}>(null);

export class SocketProvider extends React.Component<{ url: string }, { retry: number; readyState: number }> {
  socket: WebSocket;
  retry: number;

  constructor(props) {
    super(props);

    this.state = {
      retry: 5,
      readyState: -1,
    };

    this.connect();
  }

  connect() {
    this.socket = new WebSocket(this.props.url);
    this.socket.onmessage = e => console.log('onmessage', e);
    this.socket.onerror = e => console.log('onerror', e);
    this.socket.onopen = this.onOpen;
    this.socket.onclose = this.onClose;
  }

  onOpen = () => {
    this.setState({
      retry: 5,
      readyState: this.socket.readyState,
    });
  };

  onClose = () => {
    this.setState(
      {
        retry: this.state.retry - 1,
        readyState: this.socket.readyState,
      },
      () => {
        if (this.state.retry >= 0) {
          console.log('onClose', this.state.retry, 'connect!');

          setTimeout(() => {
            this.connect();
          }, 50);
        }
      },
    );
  };

  render() {
    return (
      <SocketContext.Provider
        value={{
          socket: this.socket,
          readyState: this.socket.readyState,
        }}
      >
        {this.props.children}
      </SocketContext.Provider>
    );
  }
}

export function useSocket(callback) {
  const { socket, readyState } = React.useContext(SocketContext);
  // const ref_callback = React.useRef(callback);

  React.useEffect(() => {
    socket.onmessage = function() {
      if (callback) {
        callback.apply(this, arguments);
      }
    };

    return () => {
      socket.onmessage = null;
    };
  }, [callback]);

  return { socket, readyState };
}

export type SocketMessage = {
  action: string;
  payload: any;
}

export function parseMessage(e: MessageEvent): SocketMessage {
  try {
    return JSON.parse(e.data);
  } catch (exc) {
    console.error('Could not parse MessageEvent', exc, e);
    return { action: 'load.error', payload: { error: exc } };
  }
}
