import { PageDefinition } from './PageState';
import * as React from 'react';
import MenuDropdown from '../../../components/MenuDropdown';

export function ComponentTilePerson({ page }: { page: PageDefinition }) {
  const person = page.context.person;

  const items = [];

  for (const discount of page.context.available_discounts) {
    items.push(
      <a href="javascript:" onClick={() => page.store.attachDiscount(discount.id)}>
        <i className="fal fa-tag  mr-1" /> {discount.name}
      </a>,
    );
    discount.name;
  }

  items.push(
    <a href="javascript:" onClick={() => page.store.anonymize()}>
      <i className="fal fa-trash-alt  mr-1" /> ontkoppelen
    </a>,
  );

  return (
    <div className="tile mb-2  tile-centered">
      <div className="tile-content">
        <div className="tile-title">{person.name}</div>
        <small className="tile-subtitle text-gray">
          {person.email && (
            <div>
              <i className="fal fa-envelope" /> {person.email}
            </div>
          )}
        </small>
      </div>
      <div className="tile-action">
        <MenuDropdown button={<i className="fal fa-pencil" />} classNames={['menu-dropdown-top']} items={items} />
      </div>
    </div>
  );
}
