import * as React from 'react';
import Loading from '../../../components/Loading';
import usePageStore from './PageState';
import ModalUpdateArticle from './ModalUpdateArticle';
import ComponentQuickAdd from './ComponentQuickAdd';
import ModalAbandon from './ModalAbandon';
import ModalPark from './ModalPark';
import ModalGiftcardPurchase from './ModalGiftcardPurchase';
import ModalCustomerSearch from './ModalCustomerSearch';
import ModalCustomerUpdate from './ModalCustomerUpdate';
import ModalAddressSelect from './ModalAddessSelect';
import ModalAddressUpdate from './ModalAddressUpdate';
import ModalPayment from './ModalPayment';
import ComponentSearch from './ComponentSearch';
import ComponentHeader from './ComponentHeader';
import ComponentArticles from './ComponentArticles';
import ComponentFooter from './ComponentFooter';
import ComponentButtons from './ComponentButtons';
import { cs } from '../../../utils';
import ModalTenantSearch from './ModalTenantSearch';
import ModalPersonSearch from './ModalPersonSearch';
import ModalBuzzer from './ModalBuzzer';
import ModalQuotation from './ModalQuotation';
import ModalFinish from './ModalFinish';
import { parseMessage, SocketProvider, useSocket } from '../../../lib/WebSocket';
import ModalWrongCode from './ModalWrongCode';

type PageCashSaleProps = {
  id?: string;
};

export default function PageCashSale(props: PageCashSaleProps) {
  return (
    <SocketProvider url={GLOBAL_WSS_URL}>
      <Page {...props} />
    </SocketProvider>
  );
}

function Page(props: PageCashSaleProps) {
  const ggd = window.GLOBAL_GENERIC_DATA;
  const group_id = ggd.device && ggd.device.group && ggd.device.group.id;

  const refScrollableDiv = React.useRef<HTMLDivElement>(null);
  const page = usePageStore();
  const { context, store } = page;
  const { socket, readyState } = useSocket(function(e) {
    const { action, payload } = parseMessage(e);

    if (action === 'sale.update') {
      store.update(undefined, true);
    }
  });

  // Tell the server we have connected.
  React.useEffect(() => {
    if (readyState === WebSocket.OPEN && group_id) {
      socket.send(
        JSON.stringify({
          action: 'connect',
          payload: {
            group_id: group_id,
            mode: 'sale',
          },
        }),
      );
    }
  }, [readyState, group_id]);

  React.useEffect(() => {
    if (store) {
      (async () => {
        if (!context.loaded) {
          await store.load();
        }

        const cart_id = parseInt(props.id, 10);
        if (!isNaN(cart_id) && (!context.cart || context.cart.id !== cart_id)) {
          await store.update(cart_id);
        }
      })();
    }
  }, [store, props.id]);

  React.useEffect(() => {
    if (refScrollableDiv.current) {
      // When the `refScrollableDiv` first regsiters, we pass
      // the `scrollHeight` and `offsetHeight` back to the
      // controlling component.
      const { scrollHeight, offsetHeight } = refScrollableDiv.current;

      store.updateDimensions(scrollHeight, offsetHeight);
    }
  }, [refScrollableDiv, context && context.cart]);

  if (!context) {
    return null;
  }

  if (!context.loaded) {
    return <Loading />;
  }

  async function selectCustomer(customer: ModelShopCustomer) {
    await store.setCustomer(customer);

    store.toggleModalCustomerSearch(false);
  }

  async function updateCustomer(values) {
    await store.updateCustomer(values);

    store.toggleModalCustomerUpdate(false);
  }

  async function selectAddress(address: ModelShopCustomerAddress) {
    if (context.modalAddressType == 'delivery') {
      await store.setDeliveryAddress(address);
    } else if (context.modalAddressType == 'invoice') {
      await store.setInvoiceAddress(address);
    }

    store.toggleModalAddressSelect(null, false);
  }

  async function updateAddress(values) {
    if (context.modalAddressType == 'delivery') {
      await store.updateDeliveryAddress(values);
    } else if (context.modalAddressType == 'invoice') {
      await store.updateInvoiceAddress(values);
    }

    store.toggleModalAddressUpdate(null, false);
  }

  function onWheel(event: React.WheelEvent) {
    store.scroll(event.deltaY);
  }

  const hasTouch = !!context.touch;
  const isLarge = hasTouch && context.touch.is_large;

  const allAmounts = context.lines.length + context.giftcards_purchase.length + context.giftcards_payment.length;

  return (
    <div className="container Fullscreen">
      <div className="columns">
        <div className="column">
          <div className="card">
            <div className="card-header">
              <div className="card-title h5">Kassa scherm</div>
            </div>

            <div className="card-footer">
              <ComponentSearch page={page} />
            </div>
            {allAmounts > 0 && (
              <>
                <div className="card-footer py-0">
                  <ComponentHeader page={page} />
                </div>
                <div
                  className="card-body card-body-fixed  py-0"
                  style={{ overflow: 'hidden' }}
                  onWheel={onWheel}
                  ref={refScrollableDiv}
                >
                  <ComponentArticles page={page} />
                </div>
                <div className="card-footer py-0">
                  <ComponentFooter page={page} />
                </div>
              </>
            )}
            {allAmounts === 0 && (
              <div className="card-body">
                <div className="empty">
                  <div className="empty-icon">
                    <i className="fal fa-shopping-cart  fa-3x" />
                  </div>
                  <p className="empty-title h5">Geen producten</p>
                  <p className="empty-subtitle">Er zijn nog geen artikelen toegevoegd aan de winkelwagen</p>
                </div>
              </div>
            )}

            {allAmounts > 0 && <ComponentButtons page={page} />}
          </div>
        </div>
        <div className={cs('column', isLarge ? 'col-8' : 'col-4')}>
          <ComponentQuickAdd page={page} />
        </div>
      </div>

      {context.modalWrongCodeOpen && <ModalWrongCode page={page} />}

      {context.modalGiftcardPurchaseOpen && <ModalGiftcardPurchase page={page} />}
      {context.modalUpdateLineOpen && <ModalUpdateArticle page={page} />}
      {context.modalAbandonOpen && <ModalAbandon page={page} />}
      {context.modalParkOpen && <ModalPark page={page} />}

      {context.modalCustomerSearchOpen && (
        <ModalCustomerSearch close={() => store.toggleModalCustomerSearch()} select={selectCustomer} />
      )}

      {context.modalPersonSearchOpen && (
        <ModalPersonSearch
          close={() => store.toggleModalPersonSearch()}
          select={person => page.store.setPerson(person)}
        />
      )}
      {context.modalTenantSearchOpen && (
        <ModalTenantSearch
          close={() => store.toggleModalTenantSearch()}
          select={tenant => page.store.setTenant(tenant)}
        />
      )}

      {context.modalCustomerUpdateOpen && (
        <ModalCustomerUpdate
          customer={context.customer}
          isTouchIsLarge={!!context.touch && context.touch.is_large}
          close={() => store.toggleModalCustomerUpdate()}
          update={updateCustomer}
        />
      )}
      {context.modalAddressSelectOpen && (
        <ModalAddressSelect
          customer={context.customer}
          address={context.modalAddressType === 'delivery' ? context.address_delivery : context.address_invoice}
          close={() => store.toggleModalAddressSelect(null, false)}
          closeAndCreate={() => {
            store.toggleModalAddressSelect(null, false);
            store.toggleModalAddressUpdate(context.modalAddressType, true);
          }}
          select={selectAddress}
        />
      )}
      {context.modalAddressUpdateOpen && (
        <ModalAddressUpdate
          customer={context.customer}
          address={context.modalAddressType === 'delivery' ? context.address_delivery : context.address_invoice}
          close={() => store.toggleModalAddressUpdate(null, false)}
          update={updateAddress}
        />
      )}
      {context.modalBuzzerOpen && <ModalBuzzer page={page} />}
      {context.modalPaymentOpen && <ModalPayment page={page} close={s => store.modalPaymentClose(s)} />}
      {context.modalFinishOpen && <ModalFinish page={page} />}

      {context.modalQuotationOpen && <ModalQuotation page={page} />}
    </div>
  );
}
