import * as React from 'react';
import { GenericDataService } from '../../../services/GenericDataService';
import {
  compactOrderPurchaseLines,
  cs,
  formatCents,
  formatCentsShort,
  formatDate,
  formatEan13Number,
  formatMomentAgo, hasRights,
} from '../../../utils';
import ComponentOrderPurchaseLineState from '../../../components/ComponentOrderPurchaseLineState';
import ComponentOrderPurchaseLineTransfer from '../../../components/ComponentOrderPurchaseLineTransfer';
import { Context, Store } from '../generic/PageOrderGenericOne';
import * as _ from 'underscore';

type Props = { context: Context; store: Store };

export default function ComponentOrderPurchaseLines(props: Props) {
  const { context, store } = props;
  const purchase = context.purchase;
  const fully_paid = context.purchase.paid >= context.purchase.current_inclusive;
  const generic = GenericDataService.useEffect('PageOrderGenericOne');

  return (
    <div className="card  mt-2">
      <div className="card-header">
        <div className="flex-row">
          <div className="flex-grow">
            <div className="card-title h5">Orderregels #{purchase.cart_id}</div>
          </div>
          <div className="flex-shrink">
            <Buttons
              purchase={purchase}
              purchases_per_tenant={context.purchases_per_tenant}
              store={store}
              fully_paid={fully_paid}
            />
          </div>
        </div>
      </div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Nummer</th>
              <th>Artikel</th>
              <th>Vroegst</th>
              <th>Uiterlijk</th>
              <th>Levering</th>
              <th className="text-right">Berekening</th>
              <th className="text-right">Verkocht</th>
              <th className="text-right">Aantal</th>
              <th className="text-right">Totaalbedrag</th>
              <th className="has-table-shrink-nowrap">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {context.purchases_per_tenant.map(opt => (
              <React.Fragment key={opt.id}>
                {compactOrderPurchaseLines(opt.lines).map((line, lx) => (
                  <React.Fragment key={lx}>
                    <tr
                      className={cs(
                        (!!line.configuration || !!line.annul_reason) && 'no-border-bottom',
                        _.contains(['annulled', 'cancelled'], line.state) && 'bg-dark',
                      )}
                      data-line_id={line.id.toString()}
                    >
                      <td className="has-table-shrink-nowrap">
                        <ComponentOrderPurchaseLineState state={line.state} />
                      </td>
                      <td className="has-table-shrink-nowrap">{formatEan13Number(line.article.number_beeyond)}</td>
                      <td>{line.article.name}</td>
                      {_.contains(['delivered', 'cancelled', 'annulled'], line.state) && (
                        <td colSpan={2}>{formatDate(line.updated_at)}</td>
                      )}
                      {!_.contains(['delivered', 'cancelled', 'annulled'], line.state) && (
                        <React.Fragment>
                          <td className="has-table-shrink-nowrap">
                            {formatMomentAgo(line.delivery_min_at, undefined, 'weeks')}
                          </td>
                          <td className="has-table-shrink-nowrap">
                            {formatMomentAgo(line.delivery_max_at, undefined, 'weeks')}
                          </td>
                        </React.Fragment>
                      )}
                      <td className="has-table-shrink-nowrap">
                        <ComponentOrderPurchaseLineTransfer transfer={line.transfer_bought} />
                      </td>
                      <td className="has-table-shrink-nowrap  text-right">
                        <span className="label">
                          (
                          <span className="tooltip" data-tooltip="Origineel bedrag.">
                            {formatCentsShort(line.original_inclusive)}
                          </span>
                          {line.discount_inclusive > 0 && (
                            <span className="ml-1 text-error  tooltip" data-tooltip="Berekende korting.">
                              <i className="fal fa-minus" /> {formatCentsShort(line.discount_inclusive)}
                            </span>
                          )}
                          )
                        </span>
                      </td>
                      <td className="has-table-shrink-nowrap  text-right">{formatCents(line.inclusive)}</td>
                      <td className="has-table-shrink-nowrap  text-right">{line.amount}</td>
                      <td className="has-table-shrink-nowrap  text-right">
                        {line.total_compensation !== 0 && (
                          <React.Fragment>
                            <span
                              style={{ textDecoration: 'line-through' }}
                              className="tooltip tooltip-left"
                              data-tooltip={`${formatCents(line.purchase_inclusive * line.amount)} - ${formatCents(
                                line.total_compensation,
                              )}`}
                            >
                              {formatCents(line.purchase_inclusive * line.amount)}
                            </span>{' '}
                          </React.Fragment>
                        )}
                        {formatCents(line.total_inclusive)}
                      </td>

                      <td
                        rowSpan={(!!line.annul_reason ? 1 : 0) + (!!line.configuration ? 1 : 0) + 1}
                        className="has-table-shrink-nowrap yes-border-bottom"
                      >
                        {line.state === 'open' && (
                          <button
                            type="button"
                            className="btn btn-action btn-dark-outline s-circle tooltip tooltip-left"
                            data-tooltip="Artikel aanpassen."
                            onClick={() =>
                              store.openModal({ which: 'update', data: { cart_line_id: line.cart_line_id } })
                            }
                          >
                            <i className="fal fa-fw fa-pencil" />
                          </button>
                        )}
                        {line.state === 'delivered' && (
                          <button
                            type="button"
                            className="btn btn-action btn-error-outline s-circle tooltip tooltip-left"
                            data-tooltip="Klantcompensatie uitvoeren op deze regel."
                            onClick={() =>
                              store.openModal({ which: 'compensation', data: { cart_line_id: line.cart_line_id } })
                            }
                          >
                            <i className="fal fa-fw fa-chart-line-down" />
                          </button>
                        )}
                      </td>
                    </tr>
                    {!!line.discount_name && (
                      <tr
                        className={cs(
                          _.contains(['annulled', 'cancelled'], line.state) && 'bg-dark',
                          (!!line.annul_reason || !!line.manual_discount_description || !!line.configuration) &&
                            'no-border-bottom',
                        )}
                      >
                        <td colSpan={2} className="text-right">
                          <strong>Korting</strong>
                        </td>
                        <td>
                          #{line.discount_id} &ndash; {line.discount_name}
                        </td>
                        <td colSpan={8} />
                      </tr>
                    )}
                    {!!line.manual_discount_description && (
                      <tr
                        className={cs(
                          _.contains(['annulled', 'cancelled'], line.state) && 'bg-dark',
                          (!!line.annul_reason || !!line.configuration) && 'no-border-bottom',
                        )}
                      >
                        <td colSpan={2} className="text-right">
                          <strong>Handmatige korting</strong>
                        </td>
                        <td>{line.manual_discount_description}</td>
                        <td colSpan={8} />
                      </tr>
                    )}
                    {!!line.configuration && (
                      <tr
                        className={cs(
                          _.contains(['annulled', 'cancelled'], line.state) && 'bg-dark',
                          !!line.annul_reason && 'no-border-bottom',
                        )}
                      >
                        <td colSpan={2} className="text-right">
                          <strong>Configuratie</strong>
                        </td>
                        <td>{line.configuration}</td>
                        <td colSpan={8} />
                      </tr>
                    )}
                    {!!line.annul_reason && (
                      <tr className="bg-dark">
                        <td colSpan={2} className="text-right">
                          <strong>Reden van annulering</strong>
                        </td>
                        <td>{line.annul_reason}</td>
                        <td colSpan={8} />
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

type ButtonsProps = {
  purchase: ModelOrderPurchase;
  purchases_per_tenant: ModelOrderPurchaseTenant[];
  store: Store;
  fully_paid: boolean;
};

function Buttons({ purchase, purchases_per_tenant, store, fully_paid }: ButtonsProps) {
  const has_open = purchases_per_tenant.some(ppt => ppt.amount_open > 0);
  const has_ready = purchases_per_tenant.some(ppt => ppt.amount_ready > 0);
  const has_processed = purchases_per_tenant.some(ppt => ppt.amount_processed > 0);
  const has_delivery = purchases_per_tenant.some(ppt => ppt.amount_delivery > 0);
  const has_delivered = purchases_per_tenant.some(ppt => ppt.amount_delivered > 0);

  const has_active = has_open || has_ready || has_processed || has_delivery;

  return (
    <div className="flex-row flex-space-between">
      <div style={{ marginRight: '2em' }}>
        {has_open && (
          <button
            type="button"
            className="btn btn-error-outline ml-1"
            onClick={() => store.openModal({ which: 'annul', data: { purchases_per_tenant } })}
          >
            Annuleren
          </button>
        )}
        {hasRights(['cash.refund']) && has_delivered && (
          <button
            type="button"
            className="btn btn-warning-outline ml-1"
            onClick={() => store.openModal({ which: 'cancel', data: { purchases_per_tenant } })}
          >
            Retour in winkel
          </button>
        )}

        <button
          type="button"
          className="btn btn-success-outline ml-1"
          onClick={() => store.openModal({ which: 'add' })}
        >
          Toevoegen
        </button>
      </div>
      <div>
        {(has_ready || has_processed) && (
          <button
            type="button"
            className="btn btn-warning-outline ml-1"
            onClick={() => store.openModal({ which: 'open', data: { purchases_per_tenant } })}
          >
            Terug naar open
          </button>
        )}
        {has_active && (
          <button
            type="button"
            className="btn btn-primary-outline ml-1"
            onClick={() => store.openModal({ which: 'processed', data: { purchases_per_tenant } })}
          >
            Verwerken
          </button>
        )}
        {has_active && (
          <button
            type="button"
            className="btn btn-primary-outline ml-1"
            onClick={() => store.openModal({ which: 'ready', data: { purchases_per_tenant } })}
          >
            Gereed
          </button>
        )}
        {has_active && (
          <button
            type="button"
            className="btn btn-primary-outline ml-1"
            onClick={() => store.openModal({ which: 'delivery', data: { purchases_per_tenant } })}
            disabled={!fully_paid}
          >
            Onderweg
          </button>
        )}
        {has_active && (
          <button
            type="button"
            className="btn btn-primary-outline ml-1"
            onClick={() => store.openModal({ which: 'delivered', data: { purchases_per_tenant } })}
            disabled={!fully_paid}
          >
            Geleverd
          </button>
        )}
      </div>
    </div>
  );
}
