import PageDisplay from './PageDisplay';

const DisplayStructure = [
  {
    pathname: '/display',
    title: 'Aanmelden',
    icon: 'fa-tv',
    component: PageDisplay,
  },
];

export default DisplayStructure;
