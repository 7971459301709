import PageDmzLogin from './PageDmzLogin';
import PageDmzPasswordRecovery from './PageDmzPasswordRecovery';
import PageDmzLogout from './PageDmzLogout';
import PageDmzTakeback from './PageDmzTakeback';
import PageDmzVersions from './PageDmzVersions';
import PageDmzExternalView from './PageDmzExternalView';
import PageDmzPasswordReset from './PageDmzPasswordReset';
import PageDmzLogoutEverywhere from './PageDmzLogoutEverywhere';

const DmzStructure = [
  {
    pathname: '/dmz/login',
    title: 'Aanmelden',
    icon: 'fa-sign-in',
    component: PageDmzLogin,
  },
  {
    pathname: '/dmz/password-recovery',
    title: 'Wachtwoord herstellen',
    icon: 'fa-sign-in',
    component: PageDmzPasswordRecovery,
  },
  {
    pathname: '/dmz/password-reset',
    title: 'Wachtwoord instellen',
    icon: 'fa-key',
    component: PageDmzPasswordReset,
  },
  {
    pathname: '/dmz/logout-everywhere',
    title: 'Overal afmelden',
    icon: 'fa-sign-out',
    component: PageDmzLogoutEverywhere,
  },
  {
    pathname: '/dmz/logout',
    title: 'Afmelden',
    icon: 'fa-sign-out',
    component: PageDmzLogout,
  },
  {
    pathname: '/dmz/takeback',
    title: 'Heraanmelden',
    icon: 'fa-sign-in',
    special: profile => !!profile.takeback,
    component: PageDmzTakeback,
  },
  {
    pathname: '/dmz/versions',
    title: 'Versies',
    icon: 'fa-code-branch',
    component: PageDmzVersions,
  },
  {
    pathname: '/dmz/external',
    title: 'Klantendisplay',
    icon: 'fa-code-branch',
    component: PageDmzExternalView,
  },
];

export default DmzStructure;
