import * as React from 'react';

type Props = {
  title: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  buttons?: React.ReactNode[];
};

export default function SpectreCardHeader({ title, subtitle, buttons }: Props) {
  return (
    <div className="card-header">
      <div className="flex-row">
        <div className="flex-grow">
          <div className="card-title h5">{title}</div>
          {subtitle && <div className="card-subtitle text-gray">{subtitle}</div>}
        </div>
        {buttons && (
          <div className="flex-shrink">
            {buttons.map((x, ix) => (
              <React.Fragment key={ix}>{x}</React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
