import * as React from 'react';
import { PageVacancyManageCtx } from './PageVacancyManageStore';
import * as Yup from 'yup';
import { Form, Formik, FormikActions } from 'formik';
import { http } from '../../../http';
import FormInput from '../../../components/FormInput';
import FormGroup from '../../../components/FormGroup';
import { AxiosError } from 'axios';
import FormRadio from '../../../components/FormRadio';
import FormCheckbox from '../../../components/FormCheckbox';

type Props = {
  tag: ModelVacancyTag;
};

type Values = {
  name: string;
  color: string;
  status_set: string[];
};

const schema = Yup.object().shape({
  name: Yup.string().min(3, 'stringMinLength'),
});

export default function ModalTagEdit({ tag }: Props) {
  const { store, context } = React.useContext(PageVacancyManageCtx);

  const initialValues = {
    name: tag.name,
    color: tag.color,
    status_set: tag.status_set,
  };

  console.log(initialValues);

  async function onSubmit(values: Values, formikActions: FormikActions<Values>) {
    try {
      await http().post('/core/vacancy/tag/edit', {
        tag: tag.id,
        name: values.name,
        color: values.color,
        status_set: values.status_set,
      });

      store.closeModal({ reload: true });
    } catch (exc) {
      const err: AxiosError = exc;
      console.error(exc);

      if (err.response.data && err.response.data.errors) {
        formikActions.setErrors(err.response.data.errors);
      }

      formikActions.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      render={fp => {
        return (
          <Form>
            <div className="modal modal-sm active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">
                    Tag <strong>{tag.name}</strong> aanpassen
                  </div>
                </div>

                <div className="modal-body">
                  <FormGroup title="Naam van de tag" name="name" errors={fp.errors}>
                    <FormInput name="name" loading={fp.isSubmitting} />
                  </FormGroup>
                  <FormGroup title="Sollicitatie-status" name="statuses">
                    <FormCheckbox
                      name="status_set"
                      items={[
                        { title: 'Wachtlijst', value: 'pending' },
                        { title: 'Interview (rondes)', value: 'interview' },
                        { title: 'Onderhandeling', value: 'negotiations' },
                        { title: 'Aangenomen', value: 'employed' },
                        { title: 'Afgewezen', value: 'rejected' },
                      ]}
                    />
                  </FormGroup>
                  <FormGroup title="Kleur" name="color" errors={fp.errors}>
                    <FormRadio
                      name="color"
                      items={[
                        { title: <span style={{ color: '#f44336' }}>Rood</span>, value: 'f44336' },
                        { title: <span style={{ color: '#9C27B0' }}>Paars</span>, value: '9C27B0' },
                        { title: <span style={{ color: '#3F51B5' }}>Indigo</span>, value: '3F51B5' },
                        { title: <span style={{ color: '#03A9F4' }}>Blauw</span>, value: '03A9F4' },
                        { title: <span style={{ color: '#00796B' }}>Teal</span>, value: '00796B' },
                        { title: <span style={{ color: '#4CAF50' }}>Groen</span>, value: '4CAF50' },
                        { title: <span style={{ color: '#8BC34A' }}>Lichtgroen</span>, value: '8BC34A' },
                        { title: <span style={{ color: '#FFEB3B' }}>Geel</span>, value: 'FFEB3B' },
                        { title: <span style={{ color: '#FF9800' }}>Oranje</span>, value: 'FF9800' },
                        { title: <span style={{ color: '#795548' }}>Bruin</span>, value: '795548' },
                      ]}
                    />
                  </FormGroup>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => store.closeModal()}
                        disabled={fp.isSubmitting}
                      >
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary" disabled={fp.isSubmitting}>
                        <i className="fal fa-pencil" /> Aanpassen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
