import * as Yup from 'yup';
import { parseCents } from '../../../utils';
import _ = require('underscore');

export function getCashSaleLineSchema(original_inclusive: number, discard: string[]) {
  const shape = {
    amount: Yup.number()
      .required('isRequired')
      .min(1)
      .max(100),
    configuration: Yup.string().max(2500, 'stringMaxLength'),
    deposit_percentage: Yup.number().integer('isWhole'),

    manual_increase_value: Yup.string()
      .matches(/^-?[,.0-9]+([,.][0-9]{2})?$/, 'invalidNumber')
      .test('testInvalidIncrease', 'invalidNumber', function(value) {
        const parsed = parseCents(value) / 100;

        if (this.parent.manual_increase_type === 'percentage') {
          return parsed >= 0 && parsed <= 100;
        } else {
          return true;
        }
      })
      .test('testCalculationg', 'invalidCalculation', function(value) {
        const parsed = parseCents(value) / 100;
        const calculated = calculateCashSaleLineInclusive(
          original_inclusive,
          this.parent.manual_increase_type,
          this.parent.manual_increase_value,
          this.parent.manual_discount_type,
          this.parent.manual_discount_value,
        );

        return parsed != 0 ? calculated.increase_inclusive >= 0 && calculated.final_inclusive >= 0 : true;
      }),
    manual_discount_value: Yup.string()
      .matches(/^-?[,.0-9]+([,.][0-9]{2})?$/, 'invalidNumber')
      .test('testInvalidDiscount', 'invalidNumber', function(value) {
        const parsed = parseCents(value) / 100;

        if (this.parent.manual_discount_type === 'percentage') {
          return parsed >= 0 && parsed <= 100;
        } else {
          return parsed >= 0;
        }
      })
      .test('testCalculationg', 'invalidCalculation', function(value) {
        const parsed = parseCents(value) / 100;
        const calculated = calculateCashSaleLineInclusive(
          original_inclusive,
          this.parent.manual_increase_type,
          this.parent.manual_increase_value,
          this.parent.manual_discount_type,
          this.parent.manual_discount_value,
        );

        return parsed != 0 ? calculated.discount_inclusive >= 0 && calculated.final_inclusive >= 0 : true;
      }),

    manual_discount_description: Yup.string()
      .max(2500, 'stringMaxLength')
      .test('isRequired', 'isRequired', function(value) {
        return !(parseCents(this.parent.manual_discount_value) !== 0 && !value);
      })
      .test('stringMinLength', 'stringMinLength', function(value) {
        return !(
          parseCents(this.parent.manual_discount_value) !== 0 &&
          (!value || (value as string).trim().length < 5)
        );
      }),

    delivery_min_at: Yup.string()
      .matches(/^\d{1,2}-\d{1,2}-\d{4}$/, 'dateIsRequired')
      .required('isRequired'),
    delivery_max_at: Yup.string()
      .matches(/^\d{1,2}-\d{1,2}-\d{4}$/, 'dateIsRequired')
      .required('isRequired'),
  };

  return Yup.object().shape(
    _.pick(
      shape,
      [
        'amount',
        'configuration',
        'deposit_percentage',
        'manual_increase_value',
        'manual_discount_value',
        'manual_discount_description',
        'delivery_min_at',
        'delivery_max_at',
      ].filter(x => !discard.includes(x)) as any,
    ),
  );
}

export function calculateCashSaleLineInclusive(
  original_inclusive: number,
  manual_increase_type: string,
  manual_increase_value: string,
  manual_discount_type: string,
  manual_discount_value: string,
) {
  let increase_inclusive;
  let discount_inclusive;

  if (manual_increase_type === 'percentage') {
    increase_inclusive =
      original_inclusive + Math.round((original_inclusive / 100) * (parseCents(manual_increase_value) / 100));
  } else {
    increase_inclusive = original_inclusive + parseCents(manual_increase_value);
  }

  if (manual_discount_type === 'percentage') {
    const parsed_manual_discount_value = parseCents(manual_discount_value) / 100;
    const manual_discount_calculation = (increase_inclusive / 100) * parsed_manual_discount_value;
    discount_inclusive = increase_inclusive - Math.round(manual_discount_calculation);
  } else {
    discount_inclusive = increase_inclusive - parseCents(manual_discount_value);
  }

  const final_inclusive = discount_inclusive;

  return {
    original_inclusive,
    increase_inclusive,
    discount_inclusive,
    final_inclusive,
  };
}
