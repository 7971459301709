import * as React from 'react';
import { PageVacancyJobApplicationsCtx, usePageVacancyJobApplicationsRxjs } from './PageVacancyJobApplicationsStore';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import produce from 'immer';
import { cs, formatDate, formatMomentAgo, hasRights, parseMoment } from '../../../utils';
import ModalJobEdit from '../modals/ModalJobEdit';
import ModalJobOnlineStatus from '../modals/ModalJobOnlineStatus';
import ModalJobActivate from '../modals/ModalJobActivate';
import ModalJobArchive from '../modals/ModalJobArchive';
import SpectreButton from '../../../components/spectre/SpectreButton';
import * as _ from 'underscore';
import ModalJobApplicantCreate from '../modals/ModalJobApplicantCreate';
import ModalJobApplicantEdit from '../modals/ModalJobApplicantEdit';
import MenuDropdown from '../../../components/MenuDropdown';
import ModalJobApplicationArchive from '../modals/ModalJobApplicationArchive';
import ModalJobApplicationTag from '../modals/ModalJobApplicationTag';
import ModalJobApplicantLinkTo from '../modals/ModalJobApplicantLinkTo';
import ModalJobApplicationView from '../modals/ModalJobApplicationView';
import ModalApplicationMail from '../modals/ModalApplicationMail';
import ModalJobApplicationDelete from '../modals/ModalJobApplicationDelete';
import moment = require('moment');

type Card = {
  id: number;
  column: string;
  index: number;

  application: ModelVacancyApplication;
};

type DragItem = {
  type: string;
  index: number;
  id: number;
  column: string;
};

export default function PageVacancyJobApplications({ job_id }: { job_id: string }) {
  const { store, context } = usePageVacancyJobApplicationsRxjs(job_id);
  const [cards, setCards] = React.useState<Card[]>([]);

  const load_iteration = context && context.load_iteration;
  const job_loaded = !!(context && context.job && context.job.id);
  const job_rounds = (context && context.job && context.job.rounds) || 0;

  const columns_start = ['pending'];
  const columns_interview = Array.from({ length: job_rounds }, (v, k) => `round-${k}`);
  const columns_end = ['negotiations', 'employed', 'rejected'];

  const columns = [...columns_start, ...columns_interview, ...columns_end];

  React.useEffect(() => {
    if (!job_loaded) return;

    const draft: Card[] = context.applications.map(application => {
      let column = application.status;
      if (column === 'interview') {
        column = `round-${application.round}`;
      }

      return {
        id: application.id,
        index: application.position,
        column,

        application,
      };
    });

    setCards(draft);
  }, [load_iteration, job_loaded]);

  React.useEffect(() => {
    if (store) {
      const interval = setInterval(() => {
        store.ping();
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [!!store]);

  if (!store || !context || !context.job) return null;

  const job = context.job;

  const application_status_translations = {
    pending: 'Wachtlijst',
    interview: 'Interview',
    negotiations: 'Onderhandeling',
    employed: 'Aangenomen',
    rejected: 'Afgewezen',
  };

  Array.from({ length: job_rounds }, (v, k) => `round-${k}`).forEach(
    (v, k) => (application_status_translations[v] = `Ronde ${k + 1}`),
  );

  function moveApplication(toColumn: string, hoverId: number | null, dragId: number): [number, string] {
    const next = produce(cards, draft => {
      const dragIndex = draft.findIndex(x => x.id === dragId);

      if (hoverId === null) {
        draft[dragIndex].index = -1;
      } else {
        const hoverIndex = draft.findIndex(x => x.id === hoverId);
        const newHoverIndex = draft[dragIndex].index;

        draft[dragIndex].index = draft[hoverIndex].index;
        draft[hoverIndex].index = newHoverIndex;
      }

      draft[dragIndex].column = toColumn;

      columns.forEach(column => {
        let index = 0;

        draft
          .filter(x => x.column === column)
          .sort(compare)
          .map(a => {
            a.index = index;
            index += 1;
            return a;
          });
      });
    });
    setCards(next);

    const application = next.find(x => x.id === dragId);
    return [application.index, application.column];
  }

  async function synchronizeApplications() {
    store.bulkUpdateStatus(
      cards.map(application => {
        let status = application.column;
        let round = 0;

        if (status.startsWith('round-')) {
          round = parseInt(status.split('-').pop());
          status = 'interview';
        }

        return {
          application: application.id,
          status: status,
          round: round,
          position: application.index,
        };
      }),
    );
  }

  async function applicationCloseTag(application_id: number, tag_id: number) {
    store.applicationCloseTag(application_id, tag_id);
  }

  function compare(a: Card, b: Card) {
    return a.index - b.index;
  }

  function openJobArchive() {
    store.openModal({ which: 'job-archive', data: { job } });
  }
  function openJobActivate() {
    store.openModal({ which: 'job-activate', data: { job } });
  }
  function openJobOnlineStatus() {
    store.openModal({ which: 'job-online-status', data: { job } });
  }
  function openJobEdit() {
    store.openModal({ which: 'job-edit', data: { job } });
  }
  function openApplicantCreate() {
    store.openModal({ which: 'applicant-create', data: { job } });
  }
  function openApplicantAction(mode: string, application_id: number) {
    const application = context.applications.find(x => x.id === application_id);
    const applicant = application.applicant;

    if (mode === 'edit') {
      store.openModal({ which: 'applicant-edit', data: { applicant } });
    } else if (mode === 'view') {
      store.openModal({ which: 'application-view', data: { application } });
    } else if (mode === 'link-to') {
      store.openModal({ which: 'applicant-link-to', data: { applicant, job } });
    } else if (mode === 'tag') {
      store.openModal({ which: 'application-tag', data: { application } });
    } else if (mode === 'mail') {
      store.openModal({ which: 'application-mail', data: { application } });
    } else if (mode === 'archive') {
      store.openModal({ which: 'application-archive', data: { application } });
    } else if (mode === 'delete') {
      store.openModal({ which: 'application-delete', data: { application } });
    }
  }

  return (
    <PageVacancyJobApplicationsCtx.Provider value={{ store, context }}>
      <div className="container" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div className="columns" style={{ flexShrink: 1, flexBasis: 'auto' }}>
          <div className="column col-12  mb-2">
            <div className="card">
              <div className="card-body">
                <div className="flex-row">
                  <div className="flex-grow">
                    <h5 className="card-title">{job.title}</h5>
                    <div>
                      {job.online_status === 'offline' && <span>Deze vacature is offline.</span>}
                      {job.online_status === 'online' && <span>Deze vacature staat live.</span>}
                    </div>
                  </div>
                  <div className="ml-2">
                    {context.job_updated_at && (
                      <SpectreButton
                        icon="fa-sync"
                        classNames={['btn-primary btn-action s-circle mx-1']}
                        onClick={() => store.load()}
                        tooltip={{
                          title: `Aangepast op ${formatDate(context.job_updated_at, 'L LT')}.`,
                          position: 'left',
                        }}
                      />
                    )}
                    {_.contains(['pending', 'active'], job.status) && (
                      <SpectreButton
                        icon="fa-user-plus"
                        onClick={openApplicantCreate}
                        tooltip={{ title: 'Sollicitant toevoegen.', position: 'left' }}
                      />
                    )}
                  </div>
                  <div className="ml-2">
                    {_.contains(['pending', 'active'], job.status) && (
                      <SpectreButton
                        icon="fa-trash"
                        onClick={openJobArchive}
                        tooltip={{ title: 'Vacature archiveren.', position: 'left' }}
                      />
                    )}
                    {job.status === 'pending' && (
                      <SpectreButton
                        icon="fa-play"
                        onClick={openJobActivate}
                        tooltip={{ title: 'Vacature activeren.', position: 'left' }}
                      />
                    )}
                    {job.status === 'active' && (
                      <SpectreButton
                        icon={job.online_status === 'online' ? 'fa-align-slash' : 'fa-plug'}
                        onClick={openJobOnlineStatus}
                        tooltip={{
                          title: job.online_status === 'online' ? 'Offline halen.' : 'Online zetten.',
                          position: 'left',
                        }}
                      />
                    )}
                    {_.contains(['pending', 'active'], job.status) && (
                      <SpectreButton
                        icon="fa-pencil"
                        onClick={openJobEdit}
                        tooltip={{ title: 'Vacature aanpassen.', position: 'left' }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="columns" style={{ flex: 1, flexGrow: 1, flexWrap: 'nowrap' }}>
          {columns.map(column => {
            return (
              <ApplicationsColumn
                key={column}
                name={column}
                title={application_status_translations[column]}
                cards={cards.filter(x => x.column === column).sort(compare)}
                open={openApplicantAction}
                synchronize={synchronizeApplications}
                move={moveApplication}
                closeTag={applicationCloseTag}
              />
            );
          })}
        </div>
      </div>

      <Modals />
    </PageVacancyJobApplicationsCtx.Provider>
  );
}

function Modals() {
  const { store, context } = React.useContext(PageVacancyJobApplicationsCtx);
  const mctx = context.modal;

  if (!mctx) return null;

  return (
    <React.Fragment>
      {mctx.which === 'job-edit' && <ModalJobEdit {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'job-online-status' && <ModalJobOnlineStatus {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'job-activate' && <ModalJobActivate {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'job-archive' && <ModalJobArchive {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'applicant-create' && <ModalJobApplicantCreate {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'applicant-edit' && <ModalJobApplicantEdit {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'application-view' && <ModalJobApplicationView {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'applicant-link-to' && <ModalJobApplicantLinkTo {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'application-tag' && <ModalJobApplicationTag {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'application-mail' && <ModalApplicationMail {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'application-archive' && (
        <ModalJobApplicationArchive {...mctx.data} closeModal={store.closeModal} />
      )}
      {mctx.which === 'application-delete' && (
        <ModalJobApplicationDelete {...mctx.data} closeModal={store.closeModal} />
      )}
    </React.Fragment>
  );
}

function ApplicationsColumn({
  name,
  title,
  cards,
  open,
  move,
  synchronize,
  closeTag,
}: {
  name: string;
  title: string;
  cards: Card[];
  open: (mode: string, application_id: number) => void;
  move: (toColumn: string, hoverId: number | null, dragId: number) => [number, string];
  synchronize: () => void;
  closeTag: (application_id, tag_id: number) => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'card',
    drop(item: DragItem, monitor: DropTargetMonitor) {
      if (monitor.isOver()) {
        synchronize();
      }
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) return;

      if (item.column === name) {
        return;
      }

      const [up_index, up_column] = move(name, null, item.id);

      item.index = up_index;
      item.column = up_column;
    },
  });

  drop(ref);

  return (
    <div className="column" style={{ minWidth: 100, maxWidth: 240 }}>
      <div className="card" style={{ height: '100%' }}>
        <div className="card-header">
          <h5 className="card-title">{title}</h5>
        </div>
        <div className="card-body  card-body-fixed" ref={ref}>
          {cards.map((card, index) => {
            return (
              <ApplicationCard
                key={card.id}
                card={card}
                open={open}
                move={move}
                synchronize={synchronize}
                closeTag={closeTag}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function ApplicationCard({
  card,
  open,
  move,
  synchronize,
  closeTag,
}: {
  card: Card;
  open: (mode: string, application_id: number) => void;
  move: (toColumn: string, hoverId: number | null, dragId: number) => [number, string];
  synchronize: () => void;
  closeTag: (application_id, tag_id: number) => void;
}) {
  const ref = React.useRef<HTMLDivElement>(null);
  const application = card.application;
  const applicant = application.applicant;
  const may_write = hasRights(['vacancy.write']);

  const [, drop] = useDrop({
    accept: 'card',
    drop(item: DragItem, monitor: DropTargetMonitor) {
      if (monitor.isOver()) {
        synchronize();
      }
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) return;

      if (item.column === card.column && item.index === card.index) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (item.column === card.column) {
        if (item.index < card.index && hoverClientY < hoverMiddleY) {
          return;
        }
        if (item.index > card.index && hoverClientY > hoverMiddleY) {
          return;
        }
      }

      const [up_index, up_column] = move(card.column, card.id, item.id);

      item.index = up_index;
      item.column = up_column;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'card',
      id: card.id,
      column: card.column,
      index: card.index,
    },
    collect: monitor => ({
      isDragging: monitor.getItem() && monitor.getItem().id === card.id,
    }),
  });

  drag(drop(ref));

  const age = moment().diff(parseMoment(application.created_at), 'weeks');
  const classNames = ['card', 'card-sm', 'my-1'];
  if (isDragging) classNames.push('bg-primary');
  if (_.contains(['pending', 'interview', 'negotiations'], application.status)) {
    if (age >= 3) {
      classNames.push('bg-error');
    } else if (age > 1) {
      classNames.push('bg-warning');
    }
  }

  return (
    <div className={cs(...classNames)} ref={ref} onDoubleClick={() => open('view', application.id)}>
      <div className="card-body">
        <div className="flex-row">
          <div className="flex-grow">
            {applicant && <strong>{applicant.name}</strong>}
            {!applicant && <em>Anoniem #{application.id}</em>}
          </div>
          {may_write && (
            <div>
              <MenuDropdown
                hover
                button={<i className="fal fa-ellipsis-h" />}
                classNames={['menu-dropdown-sm']}
                btnClassNames={['btn btn-link btn-sm btn-action s-circle']}
                items={[
                  <a href="javascript:" onClick={() => open('edit', application.id)}>
                    aanpassen
                  </a>,
                  <a href="javascript:" onClick={() => open('tag', application.id)}>
                    tags
                  </a>,
                  <a href="javascript:" onClick={() => open('link-to', application.id)}>
                    linken
                  </a>,
                  <a href="javascript:" onClick={() => open('view', application.id)}>
                    bekijken
                  </a>,
                  <a href="javascript:" onClick={() => open('mail', application.id)}>
                    mail versturen
                  </a>,
                  card.column ? true : null,
                  card.column !== 'rejected' && (
                    <a href="javascript:" onClick={() => open('archive', application.id)}>
                      afwijzen
                    </a>
                  ),
                  card.column === 'rejected' && (
                    <a href="javascript:" onClick={() => open('delete', application.id)}>
                      verwijderen
                    </a>
                  ),
                ]}
              />
            </div>
          )}
        </div>
        <div className="flex-row">
          <div className="flex-grow">{formatMomentAgo(application.created_at, null, 'weeks')}</div>
          {may_write && (
            <div>
              <SpectreButton
                icon="fa-envelope"
                onClick={() => {
                  open('mail', application.id);
                }}
                classNames={['btn-primary-outline btn-action btn-sm s-circle']}
              />
            </div>
          )}
        </div>
        <div>
          {application.tags
            .filter(vat => vat.end === null)
            .map(vat => {
              const tag = vat.tag;

              return (
                <span className="chip chip-sm" style={{ maxWidth: '100%' }} key={vat.id}>
                  <div className="flex-row" style={{ width: '100%' }}>
                    <div className="flex-shrink">
                      <figure className="avatar avatar-xs" style={{ backgroundColor: `#${tag.color}` }} />
                    </div>
                    <div
                      className="flex-grow"
                      style={{
                        flex: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        minWidth: 0,
                      }}
                    >
                      {tag.name}
                    </div>
                    {may_write && (
                      <div className="flex-shrink">
                        <a
                          href="javascript:"
                          className="btn btn-clear"
                          aria-label="Close"
                          role="button"
                          onClick={() => closeTag(application.id, tag.id)}
                        />
                      </div>
                    )}
                  </div>
                </span>
              );
            })}
        </div>
      </div>
    </div>
  );
}
