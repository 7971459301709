import * as React from 'react';
import { BehaviorSubject } from 'rxjs';
import produce from 'immer';

export function useTimeoutEffect(callback: () => void, duration: number | undefined | null) {
  return React.useEffect(() => {
    if (duration) {
      const timeout = setTimeout(() => {
        callback();
      }, duration);

      return () => clearTimeout(timeout);
    }
  }, [duration]);
}

export function useDebounce(value: any, delay: number) {
  const [debounced, setDebounced] = React.useState(value);

  React.useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebounced(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    Array.isArray(value) ? value : [value],
  );

  return debounced;
}

export abstract class BaseStore<T> {
  context: BehaviorSubject<T>;

  constructor(setup?: () => Partial<T>) {
    this.context = new BehaviorSubject({ ...this.setup(), ...(setup && setup()) });
  }

  next(callback: (draft: T) => void) {
    this.context.next(produce(this.context.getValue(), callback));
  }
  current(): T {
    return this.context.getValue();
  }

  abstract setup(): T;

  teardown(): void {}
}

type useRxjsResult<C, S> = { context: C; store: S };

export function useRxjsStore<C, S>(
  klass: any,
  setup?: () => Partial<C>,
  load?: (store: S) => void,
): useRxjsResult<C, S> {
  const [state, setState] = React.useState(null);

  React.useEffect(() => {
    const store = new klass(setup);

    const subscription = store.context.subscribe(context => setState({ context, store }));
    return () => {
      store.teardown();
      subscription.unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    if (state && load) {
      load(state.store);
    }
  }, [!!state]);

  if (!state) {
    // This happes the first time, as the useEffect has not yet ran.
    return { context: null, store: null };
  }

  return state;
}

function getOnlineStatus(): boolean | null {
  return typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine : null;
}

export function useOnlineStatus() {
  const [isOnline, setIsOnline] = React.useState(getOnlineStatus());

  const goOnline = () => setIsOnline(true);
  const goOffline = () => setIsOnline(false);

  React.useEffect(() => {
    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  return isOnline;
}
