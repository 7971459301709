import PageContentDashboard from './PageContentDashboard';

const ContentStructure = [
  {
    pathname: '/content/dashboard',
    title: 'Dashboard',
    icon: 'fa-desktop',
    component: PageContentDashboard,

    rights: ['xxx'],
  },
];

export default ContentStructure;
