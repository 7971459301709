import * as React from 'react';
import { http } from '../../../http';
import { Form, Formik, FormikActions } from 'formik';
import { AxiosError } from 'axios';
import FormGroup from '../../../components/FormGroup';
import FormOptionSwitch from '../../../components/FormOptionSwitch';
import FormCheckbox from '../../../components/FormCheckbox';
import { loadManyRecordsOr } from '../../../utils';
import ModalLoading from '../../../components/ModalLoading';

type Props = {
  person: ModelContactPerson;
  closeModal: ({ reload: boolean }?) => void;
};

type Values = {
  workflows: string[];
  password_reset: boolean;
};

export default function ModalContactPersonActivate({ person, closeModal }: Props) {
  const [loaded, setLoaded] = React.useState(false);
  const [workflows, setWorkflows] = React.useState<ModelGenericWorkflow[]>([]);

  React.useEffect(() => {
    (async () => {
      setWorkflows(await loadManyRecordsOr('/core/generic/workflow/many'));
      setLoaded(true);
    })();
  }, []);

  async function onSubmit({ workflows, password_reset }, formikActions: FormikActions<Values>) {
    try {
      await http().post('/core/contact/person/activate', {
        person: person.id,
        workflows,
        password_reset,
      });

      closeModal({ reload: true });
    } catch (exc) {
      const err: AxiosError = exc;
      console.error(exc);

      if (err.response.data && err.response.data.errors) {
        formikActions.setErrors(err.response.data.errors);
      }

      formikActions.setSubmitting(false);
    }
  }

  if (!loaded) return <ModalLoading title={`Profiel van ${person.first} activeren`} />;

  return (
    <Formik
      initialValues={{ password_reset: true, workflows: [] }}
      onSubmit={onSubmit}
      render={fp => {
        return (
          <Form>
            <div className="modal modal-sm active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">
                    Profiel van <strong>{person.first}</strong> activeren
                  </div>
                </div>

                <div className="modal-body">
                  <p>
                    Je gaat het profiel van {person.first} activeren. Na deze actie kan {person.first} weer normaal
                    inloggen, het oude wachtwoord werkt echter <strong>niet</strong> meer. Je kunt direct een wachtwoord
                    herstel mail versturen, of je kunt {person.first} zelf het wachtwoord laten herstellen.
                  </p>

                  <FormGroup title="Workflow(s)" name="workflows">
                    <FormCheckbox
                      name="workflows"
                      items={workflows.map(workflow => ({
                        value: workflow.id.toString(),
                        title: workflow.name,
                      }))}
                    />
                  </FormGroup>

                  <FormGroup name="password_reset" title="Wachtwoord instellen">
                    <FormOptionSwitch
                      name="password_reset"
                      title_on="E-mail om wachtwoord in te stellen versturen."
                      title_off="Geen e-mail versturen."
                    />
                  </FormGroup>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-success"
                        disabled={fp.isSubmitting || Object.keys(fp.errors).length > 0}
                      >
                        <i className="fal fa-play-circle" /> Activeren
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
