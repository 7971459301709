import * as React from 'react';
import { http } from '../../http';
import { AxiosError } from 'axios';
import MenuDropdown from '../../components/MenuDropdown';
import ModelValidateTerminal from './modal/ModalValidateTerminal';
import ModalCreateTicketPrinter from './modal/ModalCreateTicketPrinter';
import ModalDeleteTicketPrinter from './modal/ModalDeleteTicketPrinter';
import ModalTestTicketPrinter from './modal/ModalTestTicketPrinter';

type State = {
  loading: boolean;

  terminals: ModelDeviceTerminal[];
  stickerPrinters: ModelDeviceStickerPrinter[];
  ticketPrinters: ModelDeviceTicketPrinter[];

  modalValidateTerminalOpen: boolean;
  modalValidateTerminalId: number;
  modalCreateTicketPrinterOpen: boolean;
  modalTestTicketPrinterOpen: boolean;
  modalTestTicketPrinterId: number;
  modalDeleteTicketPrinterOpen: boolean;
  modalDeleteTicketPrinterId: number;
};

function emptyState(loading: boolean): State {
  return {
    loading,

    terminals: [],
    stickerPrinters: [],
    ticketPrinters: [],

    modalValidateTerminalOpen: false,
    modalValidateTerminalId: null,
    modalCreateTicketPrinterOpen: false,
    modalTestTicketPrinterOpen: false,
    modalTestTicketPrinterId: null,
    modalDeleteTicketPrinterOpen: false,
    modalDeleteTicketPrinterId: null,
  };
}

enum Action {
  Loading,
  Loaded,
  OpenModalValidateTerminal,
  CloseModalValidateTerminal,
  OpenModalCreateTicketPrinter,
  CloseModalCreateTicketPrinter,
  OpenModalTestTicketPrinter,
  CloseModalTestTicketPrinter,
  OpenModalDeleteTicketPrinter,
  CloseModalDeleteTicketPrinter,
  FailIntoLimbo,
}

type Payload =
  | {
      action: Action.Loading;
    }
  | {
      action: Action.Loaded;
      terminals: ModelDeviceTerminal[];
      stickerPrinters: ModelDeviceStickerPrinter[];
      ticketPrinters: ModelDeviceTicketPrinter[];
    }
  | {
      action: Action.OpenModalValidateTerminal;
      terminalId: number;
    }
  | {
      action: Action.CloseModalValidateTerminal;
    }
  | {
      action: Action.OpenModalCreateTicketPrinter;
    }
  | {
      action: Action.CloseModalCreateTicketPrinter;
    }
  | {
      action: Action.OpenModalTestTicketPrinter;
      printerId: number;
    }
  | {
      action: Action.CloseModalTestTicketPrinter;
    }
  | {
      action: Action.OpenModalDeleteTicketPrinter;
      printerId: number;
    }
  | {
      action: Action.CloseModalDeleteTicketPrinter;
    }
  | {
      action: Action.FailIntoLimbo;
    };

function reducer(state: State, payload: Payload): State {
  if (payload.action === Action.Loading) {
    return emptyState(true);
  }

  if (payload.action === Action.Loaded) {
    return {
      ...state,

      loading: false,
      terminals: payload.terminals,
      stickerPrinters: payload.stickerPrinters,
      ticketPrinters: payload.ticketPrinters,
    };
  }

  if (payload.action === Action.OpenModalValidateTerminal) {
    return {
      ...state,

      modalValidateTerminalOpen: true,
      modalValidateTerminalId: payload.terminalId,
    };
  }

  if (payload.action === Action.CloseModalValidateTerminal) {
    return {
      ...state,

      modalValidateTerminalOpen: false,
      modalValidateTerminalId: null,
    };
  }

  if (payload.action === Action.OpenModalCreateTicketPrinter) {
    return {
      ...state,

      modalCreateTicketPrinterOpen: true,
    };
  }

  if (payload.action === Action.CloseModalCreateTicketPrinter) {
    return {
      ...state,

      modalCreateTicketPrinterOpen: false,
    };
  }

  if (payload.action === Action.OpenModalTestTicketPrinter) {
    return {
      ...state,

      modalTestTicketPrinterOpen: true,
      modalTestTicketPrinterId: payload.printerId,
    };
  }

  if (payload.action === Action.CloseModalTestTicketPrinter) {
    return {
      ...state,

      modalTestTicketPrinterOpen: false,
    };
  }

  if (payload.action === Action.OpenModalDeleteTicketPrinter) {
    return {
      ...state,

      modalDeleteTicketPrinterOpen: true,
      modalDeleteTicketPrinterId: payload.printerId,
    };
  }

  if (payload.action === Action.CloseModalDeleteTicketPrinter) {
    return {
      ...state,

      modalDeleteTicketPrinterOpen: false,
    };
  }

  if (payload.action === Action.FailIntoLimbo) {
    return emptyState(false);
  }
}

export default function PageDeviceDashboard() {
  const [state, dispatch] = React.useReducer<React.Reducer<State, Payload>>(reducer, emptyState(true));

  React.useEffect(() => {
    loadRecords();
  }, []);

  async function loadRecords() {
    dispatch({ action: Action.Loading });

    try {
      const [terminalsRes, stickerPrintersRes, ticketPrintersRes] = await Promise.all([
        http().post('/core/device/terminal/many'),
        http().post('/core/device/sticker_printer/many'),
        http().post('/core/device/ticket_printer/many'),
      ]);

      dispatch({
        action: Action.Loaded,
        terminals: terminalsRes.data.records,
        stickerPrinters: stickerPrintersRes.data.records,
        ticketPrinters: ticketPrintersRes.data.records,
      });
    } catch (exc) {
      const err: AxiosError = exc;

      dispatch({
        action: Action.FailIntoLimbo,
      });
    }
  }

  function onOpenModalValidateTerminalClick(terminalId: number) {
    return (event: React.MouseEvent<any>) => {
      dispatch({ action: Action.OpenModalValidateTerminal, terminalId });
    };
  }
  function onCloseModalValidateTerminal(success: boolean) {
    if (success) {
      loadRecords();
    } else {
      dispatch({ action: Action.CloseModalValidateTerminal });
    }
  }

  function onOpenModalCreateTicketPrinterClick() {
    dispatch({ action: Action.OpenModalCreateTicketPrinter });
  }
  function onCloseModalCreateTicketPrinter(success: boolean) {
    if (success) {
      loadRecords();
    } else {
      dispatch({ action: Action.CloseModalCreateTicketPrinter });
    }
  }

  function onOpenModalTestTicketPrinterClick(printerId: number) {
    return (event: React.MouseEvent<any>) => {
      dispatch({ action: Action.OpenModalTestTicketPrinter, printerId });
    };
  }
  function onCloseModalTestTicketPrinter(success: boolean) {
    if (success) {
      loadRecords();
    } else {
      dispatch({ action: Action.CloseModalTestTicketPrinter });
    }
  }

  function onOpenModalDeleteTicketPrinterClick(printerId: number) {
    return (event: React.MouseEvent<any>) => {
      dispatch({ action: Action.OpenModalDeleteTicketPrinter, printerId });
    };
  }
  function onCloseModalDeleteTicketPrinter(success: boolean) {
    if (success) {
      loadRecords();
    } else {
      dispatch({ action: Action.CloseModalDeleteTicketPrinter });
    }
  }

  return (
    <div className="container">
      <div className="card mb-2">
        <div className="card-header">
          <div className="card-title h5">PIN-terminals</div>
        </div>
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th>Nummer</th>
                <th>Referentienummer</th>
                <th>Locatie</th>
                <th
                  className="text-center  tooltip tooltip-bottom"
                  data-tooltip="Is deze PIN-terminal online (via PaymentServer)?"
                >
                  <i className="fal fa-plug" />
                </th>
                <th
                  className="text-center  tooltip tooltip-bottom"
                  data-tooltip="Is deze PIN-terminal gevalideerd door Loods 5?"
                >
                  <i className="fal fa-euro-sign" />
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {state.terminals.map(terminal => (
                <tr key={terminal.id}>
                  <td>{terminal.identifier}</td>
                  <td>{terminal.bank_identifier}</td>
                  <td>
                    {terminal.groups.map(group => (
                      <span>
                        <code>{group.name}</code> {group.label}
                      </span>
                    ))}
                  </td>
                  <td className="has-table-shrink-nowrap">
                    {terminal.is_online ? (
                      <span>
                        <i className="fal fa-plug  text-success" /> verbonden
                      </span>
                    ) : (
                      <span>
                        <i className="fal fa-wifi-slash  text-error" /> niet gekoppeld
                      </span>
                    )}
                  </td>
                  <td className="has-table-shrink-nowrap">
                    {terminal.is_validated ? (
                      <span>
                        <i className="fal fa-euro-sign  text-success" /> gevalideerd
                      </span>
                    ) : (
                      <span>
                        <i className="fal fa-ban  text-error" /> niet gevalideerd
                      </span>
                    )}
                  </td>
                  <td className="has-table-shrink-nowrap">
                    <MenuDropdown
                      button={<i className="fal fa-ellipsis-h" />}
                      items={[
                        <a href="javascript:" onClick={onOpenModalValidateTerminalClick(terminal.id)}>
                          valideren
                        </a>,
                        <a href="javascript:">deactiveren</a>,
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="card-body">
          Wanneer je een nieuwe PIN-terminal aan het netwerk hangt, komt deze in contact met de PaymentServer. Deze zal
          de nieuwe PIN-terminal automatisch hier registreren. De PIN-terminal is dan echter nog niet geschikt om mee te
          betalen, je zal hem eerst moeten valideren. Dit kan door op <strong>valideren</strong> te klikken onder de{' '}
          <span className="btn btn-link">
            <i className="fal fa-ellipsis-h" />
          </span>{' '}
          knop.
        </div>
      </div>
      <div className="card mb-2">
        <div className="card-header">
          <div className="flex-grow">
            <div className="card-title h5">Ticket printers</div>
          </div>
          <div className="flex-shrink">
            <a
              href="javascript:"
              className="btn btn-link btn-action s-circle"
              onClick={onOpenModalCreateTicketPrinterClick}
            >
              <i className="fal fa-fw fa-plus" />
            </a>
          </div>
        </div>
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th>IP Adres</th>
                <th
                  className="text-center  tooltip tooltip-bottom"
                  data-tooltip="Is deze PIN-terminal online (via PaymentServer)?"
                >
                  <i className="fal fa-plug" />
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {state.ticketPrinters.map(printer => (
                <tr key={printer.id}>
                  <td>{printer.ip_address}</td>
                  <td className="has-table-shrink-nowrap">
                    <span>
                      <i className="fal fa-plug  text-success" /> verbonden
                    </span>
                  </td>
                  <td className="has-table-shrink-nowrap">
                    <MenuDropdown
                      button={<i className="fal fa-ellipsis-h" />}
                      items={[
                        <a href="javascript:" onClick={onOpenModalTestTicketPrinterClick(printer.id)}>
                          test afdrukken
                        </a>,
                        <a href="javascript:" onClick={onOpenModalDeleteTicketPrinterClick(printer.id)}>
                          verwijderen
                        </a>,
                      ]}
                    />
                  </td>
                </tr>
              ))}
              {state.ticketPrinters.length === 0 && (
                <tr>
                  <td colSpan={3} className="text-center">
                    <em>Er zijn ticket printers gekoppeld.</em>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="card-body">
          Ticket Printers melden zichzelf niet aan, wanneer je een nieuwe ticket-printer aan het netwerk koppelt, print
          deze een bon uit. Op deze bon staat het IP-adres van de printer. Voeg deze vervolgens zelf toe aan deze lijst.
        </div>
      </div>

      {state.modalValidateTerminalOpen && (
        <ModelValidateTerminal onClose={onCloseModalValidateTerminal} terminalId={state.modalValidateTerminalId} />
      )}

      {state.modalCreateTicketPrinterOpen && <ModalCreateTicketPrinter onClose={onCloseModalCreateTicketPrinter} />}
      {state.modalTestTicketPrinterOpen && (
        <ModalTestTicketPrinter onClose={onCloseModalTestTicketPrinter} printerId={state.modalTestTicketPrinterId} />
      )}
      {state.modalDeleteTicketPrinterOpen && (
        <ModalDeleteTicketPrinter
          onClose={onCloseModalDeleteTicketPrinter}
          printerId={state.modalDeleteTicketPrinterId}
        />
      )}
    </div>
  );
}
