import { ReactNode } from 'react';
import Service from './Service';

export interface MessageState {
  active: boolean;

  content: ReactNode | string;
  color: string;
  close?: number;
}

function initialState(): MessageState {
  return {
    active: false,

    content: '',
    color: 'primary',
  };
}

export const MessageService = (() => {
  const { current, next, useEffect } = Service<MessageState>('MessageService', initialState);

  return {
    current,
    next,
    useEffect,

    setMessage: (content: ReactNode | string, color: string = 'primary', close?: number) => {
      next({ content, color, close, active: true });
    },
    clear: () => {
      next(initialState());
    },
  };
})();

/**
 * How to use this:
 *

 function SomeComponent () {
    const state = MessageService.useEffect();

    useTimeoutEffect(dismiss, MessageService.clear);

    if (state.active) {
      const { color, content } = state;

      return (
        <div className="Toastbar">
          <SpectreToast color={color} dismiss={MessageService.clear}>
            {content}
          </SpectreToast>
        </div>
      );
    }

    return null;
  }

 */
