import * as React from 'react';
import { PageDefinition } from './PageState';
import { http } from '../../../http';

type Props = {
  page: PageDefinition;
};

export default function ModalQuotation(props: Props) {
  const [loading, setLoading] = React.useState(false);
  const [link, setLink] = React.useState(null);

  async function submit() {
    setLoading(true);

    try {
      const res = await http().post('/core/shop/cart/download_quotation_pdf', { cart: props.page.context.cart.id });

      setLink(res.data.link);
    } catch (exc) {
      console.error(exc);
    }

    setLoading(false);
  }

  function onCloseClick() {
    props.page.store.modalQuotationClose();
  }

  return (
    <div className="modal active">
      <div className="modal-overlay" />
      <div className="modal-container" style={{ maxHeight: '90vh' }}>
        <div className="modal-header">
          <div className="modal-title h5">Offerte downloaden</div>
        </div>

        <div className="modal-body">
          {!link && <p>Klik op genereren om de PDF te genereren en een link te verkrijgen.</p>}
          {link && <p>De PDF van de factuur staat klaar, je kunt nu op downloaden klikken.</p>}
        </div>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link  float-left" onClick={onCloseClick} disabled={loading}>
                <i className="fal fa-times" /> Sluiten
              </button>
            </div>
            <div>
              {!link && (
                <button type="button" className="btn btn-primary-outline" onClick={submit} disabled={loading}>
                  <i className="fal fa-cog" /> Genereren
                </button>
              )}

              {link && (
                <a href={link} className="btn btn-primary" target={'_blank'}>
                  <i className="fal fa-download" /> Downloaden
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
