import { BaseStore, useRxjsStore } from '../../../effects';
import * as React from 'react';
import { loadManyRecordsOr, loadManyRecordsPaginated } from '../../../utils';
import { http } from '../../../http';
import { ModalFilterValues } from './ModalFilter';
import * as _ from 'underscore';
import Query from '../../../lib/Query';

export type PageVacancyApplicationManyContext = {
  loaded: boolean;
  jobs: ModelVacancyJob[];
  locations: ModelVacancyLocation[];
  departments: ModelVacancyDepartment[];
  tags: ModelVacancyTag[];

  query: ContextQuery;
  query_is_empty: boolean;

  pagination?: Pagination;
  applications: ModelVacancyApplication[];

  modal?: PageVacancyApplicationManyModalContext;

  load_iteration: number;
};

type ContextQuery = {
  page: number;
  per_page: number;
} & ModalFilterValues;

const emptyQuery = (): ContextQuery => ({
  page: 0,
  per_page: 50,

  name: '',
  status: '',
  anonymized: '-1',
  lead_time: [],
  jobs: [],
  locations: [],
  departments: [],
  tags: [],

  sorting: 'lead_time-asc',
});

function isQueryEmpty(query: ContextQuery) {
  return _.isEqual(query, emptyQuery());
}

const query = new Query<ContextQuery>('PageVacancyApplicationMany');

export class PageVacancyApplicationManyStore extends BaseStore<PageVacancyApplicationManyContext> {
  setup(): PageVacancyApplicationManyContext {
    const q = query.load() || emptyQuery();

    return {
      loaded: false,
      jobs: [],
      locations: [],
      departments: [],
      tags: [],

      query: q,
      query_is_empty: isQueryEmpty(q),

      pagination: null,
      applications: [],

      modal: null,

      load_iteration: 0,
    };
  }

  async load(partial: boolean = true) {
    this.next(draft => {
      draft.loaded = false;
    });

    if (partial === false) {
      const jobs = await loadManyRecordsOr('/core/vacancy/job/many');
      const locations = await loadManyRecordsOr('/core/vacancy/location/many');
      const departments = await loadManyRecordsOr('/core/vacancy/department/many');
      const tags = await loadManyRecordsOr('/core/vacancy/tag/many');

      this.next(draft => {
        draft.jobs = jobs;
        draft.locations = locations;
        draft.departments = departments;
        draft.tags = tags;
      });
    }

    const applications = await loadManyRecordsPaginated('/core/vacancy/application/many', {
      ...this.current().query,
    });

    this.next(draft => {
      draft.loaded = true;

      draft.pagination = applications.pagination;
      draft.applications = applications.records;
      draft.load_iteration += 1;
    });
  }

  async search(values: Partial<ModalFilterValues>) {
    this.next(draft => {
      _.keys(draft.query).forEach(key => {
        const value = values[key];
        if (value !== undefined) {
          draft.query[key] = value;
        }
      });

      draft.query.page = 0;
      draft.query_is_empty = isQueryEmpty(draft.query);

      query.save(draft.query);
    });

    await this.load();
  }

  async resetQuery() {
    this.next(draft => {
      draft.query = emptyQuery();
      draft.query_is_empty = isQueryEmpty(draft.query);

      query.save(draft.query);
    });

    await this.load();
  }

  queryInModalIsEmpty(include_name?: boolean) {
    const ignore = ['page', 'per_page'];
    if (include_name !== false) ignore.push('name');

    const empty = emptyQuery();

    return _.pairs(this.current().query)
      .filter(([k, v]) => !_.contains(ignore, k))
      .every(([k, v]) => _.isEqual(v, empty[k]));
  }

  async applicationCloseTag(application_id, tag_id: number) {
    this.next(draft => {
      draft.loaded = false;
    });

    await http().post('/core/vacancy/application/tag_close', {
      application: application_id,
      tag: tag_id,
    });

    await this.load();
  }

  // region Utils

  setPage = async (page: number) => {
    this.next(draft => {
      draft.query.page = page;
      draft.query_is_empty = isQueryEmpty(draft.query);

      query.save(draft.query);
    });
    await this.load();
  };

  jobById(job_id: number) {
    return this.current().jobs.find(x => x.id === job_id);
  }
  departmentById(department_id: number) {
    return this.current().departments.find(x => x.id === department_id);
  }
  locationById(location_id: number) {
    return this.current().locations.find(x => x.id === location_id);
  }

  openModal(ctx: PageVacancyApplicationManyModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }

  closeModal = ({ reload }: { reload: boolean } = { reload: false }) => {
    this.next(draft => {
      draft.modal = null;
    });

    if (reload) {
      setTimeout(() => {
        this.load();
      }, 1);
    }
  };

  // endregion
}

// region Modal Context

export type PageVacancyApplicationManyModalContext =
  | {
      which: 'filter-applications';
      data: { initial: ModalFilterValues };
    }
  | {
      which: 'applicant-create';
      data: {};
    }
  | {
      which: 'applicant-edit';
      data: { applicant: ModelVacancyApplicant };
    }
  | {
      which: 'application-view';
      data: { application: ModelVacancyApplication };
    }
  | {
      which: 'applicant-link-to';
      data: { applicant: ModelVacancyApplicant };
    }
  | {
      which: 'application-tag';
      data: { application: ModelVacancyApplication };
    }
  | {
      which: 'application-archive';
      data: { application: ModelVacancyApplication };
    }
  | {
      which: 'application-delete';
      data: { application: ModelVacancyApplication };
    };

// endregion
// region Utilities

export type PageVacancyApplicationManyBasicProps = {
  store: PageVacancyApplicationManyStore;
  context: PageVacancyApplicationManyContext;
};

export function usePageVacancyApplicationManyRxjs(): PageVacancyApplicationManyBasicProps {
  const { store, context } = useRxjsStore<PageVacancyApplicationManyContext, PageVacancyApplicationManyStore>(
    PageVacancyApplicationManyStore,
    null,
    () => {
      store.load(false);
    },
  );

  return { store, context };
}

export const PageVacancyApplicationManyCtx = React.createContext<PageVacancyApplicationManyBasicProps>(null);

// endregion
