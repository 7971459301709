import * as React from 'react';
import { PageVacancyManageCtx } from './PageVacancyManageStore';
import * as Yup from 'yup';
import { Form, Formik, FormikActions } from 'formik';
import { http } from '../../../http';
import FormInput from '../../../components/FormInput';
import FormGroup from '../../../components/FormGroup';
import { AxiosError } from 'axios';
import { draftToValue, sanitizeFormValues, valueToDraft } from '../../../utils';
import FormEditor from '../../../components/FormEditor';
import FormCheckbox from '../../../components/FormCheckbox';

type Props = {
  mail: ModelVacancyMail;
};

type Values = {
  title: string;
  subject: string;
  statuses: string[];
};

const schema = Yup.object().shape({
  title: Yup.string().min(3, 'stringMinLength'),
  subject: Yup.string().min(3, 'stringMinLength'),
});

export default function ModalMailEdit({ mail }: Props) {
  const { store, context } = React.useContext(PageVacancyManageCtx);

  const initialValues = sanitizeFormValues({
    title: mail.title,
    subject: mail.subject,
    statuses: mail.statuses,
    description_state: valueToDraft(mail.description_json, mail.description_html),
  });

  async function onSubmit(values: Values, formikActions: FormikActions<Values>) {
    const [description_json, description_html] = draftToValue((values as any).description_state);

    try {
      await http().post('/core/vacancy/mail/edit', {
        mail: mail.id,

        title: values.title,
        subject: values.subject,
        statuses: values.statuses,

        description_json: JSON.stringify(description_json),
        description_html: description_html,
      });

      store.closeModal({ reload: true });
    } catch (exc) {
      const err: AxiosError = exc;
      console.error(exc);

      if (err.response.data && err.response.data.errors) {
        formikActions.setErrors(err.response.data.errors);
      }

      formikActions.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      render={fp => {
        return (
          <Form>
            <div className="modal modal-md active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">
                    Mail <strong>{mail.title}</strong> aanpassen
                  </div>
                </div>

                <div className="modal-body">
                  <FormGroup title="Interne naam van de mail" name="title" errors={fp.errors}>
                    <FormInput name="title" loading={fp.isSubmitting} />
                  </FormGroup>

                  <FormGroup title="Geschikt voor sollicitatie-status" name="statuses">
                    <FormCheckbox
                      name="statuses"
                      items={[
                        { title: 'Wachtlijst', value: 'pending' },
                        { title: 'Interview (rondes)', value: 'interview' },
                        { title: 'Onderhandeling', value: 'negotiations' },
                        { title: 'Aangenomen', value: 'employed' },
                        { title: 'Afgewezen', value: 'rejected' },
                      ]}
                    />
                  </FormGroup>

                  <FormGroup title="Onderwerp van de mail" name="subject" errors={fp.errors}>
                    <FormInput name="subject" loading={fp.isSubmitting} />
                  </FormGroup>

                  <FormGroup name="description_state" title="Content van de mail" errors={fp.errors}>
                    <FormEditor
                      name="description_state"
                      onChange={(n, s) => fp.setFieldValue(n, s)}
                      editorState={(fp.values as any).description_state}
                    />
                  </FormGroup>
                </div>
                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => store.closeModal()}
                        disabled={fp.isSubmitting}
                      >
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary" disabled={fp.isSubmitting}>
                        <i className="fal fa-pencil" /> Aanpassen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
