import * as React from 'react';
import Render from './Render';

export default function PageDocCoreOrderSponsor() {
  return <Render markdown={markdown} />;
}

const markdown = `

# Inleiding

Welkom bij de handleiding van Loods 5 - Sponsoring. Je vindt het beheer
van de sponsoring links in het menu onder het kopje Orders, en
vervolgens Sponsoring.

De sponsoring is een versimpelde versie van het normale kassa en order
beheer in de ShopManager. Een aantal van de functionaliteiten komen overeen,
en een aantal functionaliteiten zijn speciaal voor deze module ontwikkeld.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/83db1dfe-4b45-41a4-987e-bdb4aa0957a0.png)

# Sponsoring toevoegen

Links bovenin vind je de knop om een nieuwe sponsoring toe te voegen.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/7c1593f6-775d-4725-8e27-5ccff2deee8f.png)

Er opent een popup waar de basisgegevens van de sponsoring ingevoerd
kunnen worden.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/5e713b57-b2ed-4f57-8447-5a30b215ddf4.png)

Wanneer je op **Toevoegen** hebt geklikt opent het artikelen scherm.
Je kunt dus direct starten met het toevoegen van artikelen aan
de sponsoring.

![Stap 3](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/0e6dfecb-ee19-4324-9be0-2a3513668197.png)

## Artikelen toevoegen

Er zijn twee manieren om artikelen toe te voegen aan de sponsoring. De
eerste manier is het scannen van een barcode. De barcode scanner voert
dan automatisch het nummer in wat er op de barcode staat.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/6d1ba867-809b-4d8c-899c-0439ced4ba1f.png)

Het artikel wordt dan direct toegevoegd aan de lijst, je hoort een 
bevestigingsgeluid dat het toevoegen is gelukt.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/ec33be9a-eb4a-4bb6-b0e8-d9dd2b9100b9.png)

Soms lukt het niet met het scannen van de barcode, dan kun je altijd
handmatig zoeken door alle artikelen. Je krijgt dan een lijst waar je
het juiste artikel kunt selecteren.

![Stap 3](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/7919c977-e64b-446a-85a7-0a290ec08f80.png)

## Regel aanpassen

Het is mogelijk om een beschrijving te plaatsen bij een regel.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/e752e4ee-eb08-4193-b278-acbbdda58f04.png)

Er opent een popup waar de gewenste informatie ingevoerd kan worden

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/e376bdc4-cb61-45f4-b5dc-e0a5b4af0805.png)

De knop is donker gekleurd wanneer er informatie is ingevoerd bij een regel.

![Stap 3](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/1838fa3d-f80b-428f-bae3-5bc42dfdd0e2.png)

## Aantallen aanpassen

Rechts in de regel kun je klikken op de plus knop om zo het aantal te vermeerderen.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/9ec79471-fe61-45f1-a8a1-3e8e0ad3f516.png)

Links kun je na het vermeerderen weer verminderen. Deze knop is beschikbaar wanneer
er meer dan 1 artikel aanwezig is.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/167fa6c2-d814-435a-a067-0b68ef13a324.png)

Is er nog maar 1 artikel aanwezig, dan zal de meest linker knop het artikel verwijderen
uit de lijst.

![Stap 3](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/82c767f6-c13f-4734-8333-c3f84e130f3d.png)

## Opslaan

Klink onderin de pagina op opslaan om de huidige configuratie op te slaan en
alle informatie door te laten rekenen.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/e05e7429-faa3-4777-ba33-7bfa41a1d7a9.png)

Je keert automatisch terug naar het overzicht. Alle informatie is nu opgeslagen.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/9363a65e-8440-4264-85a6-e5403a31b142.png)

Je kunt altijd weer verder met het aanpassen van de artikelen, door in het overzicht
te dubbel-klikken op de sponsoring-regel, of rechts op de knop *Beheer de artikelen* te klikken.

![Stap 3](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/953ee353-c827-481c-b79e-eccab62cf6d9.png)

# Contextuele popups

Er zijn een aantal contextuele popups en handelingen die je kunt uitvoeren
vanuit het overzicht.

## Aanpassen

Vanuit het context menu kun je kiezen voor de optie _Aanpassen_.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/4171ab82-0be3-46f3-97fd-db8df25e427e.png)

Er opent een popup waar je de basisgegevens van de sponsoring kunt aanpassen.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/a021921a-03ea-49d5-a8a1-b780e089b53c.png)

## Bekijken beknopt

Vanuit het context menu kun je kiezen voor de optie _Bekijken_.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/623ad58d-a758-4a8f-b26f-afc7824e766b.png)

Er opent een popup waar alle gegevens beknopt worden weergegeven.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/be769053-bfc6-4af4-9291-c4d71e76734e.png)

## Excel downloaden

Vanuit het context menu kun je kiezen voor de optie _downloaden_.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/10a87034-f24c-4e2e-9603-9340f7bcc753.png)

Er wordt automatisch een Excel bestand gedownload.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/10a87034-f24c-4e2e-9603-9340f7bcc753-excel-artikelen.png)

De Excel heeft een tweede tabblad waar de samenvatting staat weergegeven.

![Stap 3](${GLOBAL_APIS_URL}static/doc/core-order-sponsor/10a87034-f24c-4e2e-9603-9340f7bcc753-excel-samenvatting.png)

`;
