import * as React from 'react';
import FormCheckbox from '../../../components/FormCheckbox';
import { UpdateAddressValues } from './PageState';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { cs, getCountriesSorted, getTouchedErrors } from '../../../utils';
import FormSelect from '../../../components/FormSelect';
import { displayError } from '../../../components/FormGroup';
import FormInput from '../../../components/FormInput';
import { http } from '../../../http';
import produce from 'immer';
import FormRadio from '../../../components/FormRadio';
import { nl2br } from '../../../quick';

type Props = {
  customer: ModelShopCustomer;
  address: ModelShopCustomerAddress;

  close: () => void;
  update: (values: UpdateAddressValues) => void;
};

const schema = Yup.object().shape({
  country: Yup.number().required('isRequired'),

  name: Yup.string()
    .max(250, 'stringMaxLength')
    .required('isRequired'),

  zipcode: Yup.string()
    .test('length', 'stringLength', function(value) {
      if (!value) return false;

      if (this.parent.country === '1') {
        return value.length >= 6 && value.length <= 7;
      } else {
        return value.length >= 4 && value.length <= 10;
      }
    })
    .required('isRequired'),

  housenumber: Yup.string()
    .min(1, 'stringMinLength')
    .required('isRequired'),

  extension: Yup.string(),

  street: Yup.string().required('isRequired'),
  city: Yup.string().required('isRequired'),

  has_company: Yup.string(),
  company_name: Yup.string(),
  company_vat: Yup.string(),
});

type ViesValidation = {
  valid: number;
  name: string;
  address: string;
};

type FormValues = UpdateAddressValues & {
  manual_vies_validated?: string;
};

export default function ModalAddressUpdate(props: Props) {
  function onSubmit(values: FormValues, fp: FormikProps<FormValues>) {
    props.update({ ...values, is_vies_validated: viesValidation?.valid === 1 || values.manual_vies_validated === '1' });
  }

  const values: FormValues = props.address
    ? {
        country: props.address.country_id.toString(),
        name: props.address.name,
        zipcode: props.address.zipcode,
        housenumber: props.address.housenumber,
        extension: props.address.extension,
        street: props.address.street,
        city: props.address.city,
        has_company: props.address.has_company ? '1' : '0',
        company_name: props.address.company_name,
        company_vat: props.address.company_vat,
        manual_vies_validated: props.address.company_validated ? '1' : '0',
      }
    : {
        country: props.customer ? props.customer.country_id.toString() : '1',
        name: props.customer ? props.customer.name : '',
        zipcode: '',
        housenumber: '',
        extension: '',
        street: '',
        city: '',
        has_company: '0',
        company_name: '',
        company_vat: '',
        manual_vies_validated: '0',
      };

  const [searching, setSearching] = React.useState(false);
  const [viesValidation, setValidated] = React.useState<ViesValidation | null>(null);

  return (
    <div className="modal active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Adresgegevens opvoeren</div>
          <div className="h6 text-gray" />
        </div>

        <Formik
          initialValues={values}
          onSubmit={onSubmit}
          validationSchema={schema}
          render={fp => {
            const errors = getTouchedErrors(fp.touched, fp.errors);
            const { country, name, zipcode, housenumber, extension, street, city, company_name, company_vat } = errors;

            async function search() {
              setSearching(true);

              const { country, zipcode, housenumber, extension } = fp.values;

              try {
                const res = await http().post('/sale/cart/address_search', {
                  country,
                  zipcode,
                  housenumber,
                  extension,
                });

                const data = res.data as AddressSearchResult;

                fp.setValues(
                  produce(fp.values, draft => {
                    draft.street = data.street;
                    draft.city = data.city;
                  }),
                );

                setSearching(false);
              } catch (e) {
                setSearching(false);
              }
            }

            async function validateVat() {
              setSearching(true);

              const { country, company_vat } = fp.values;

              try {
                const data = { country, company_vat };
                const res = await http().post('/core/contact/person/validate_company_vat', data);

                setValidated(res.data);
                setSearching(false);
              } catch (e) {
                setSearching(false);
              }
            }

            return (
              <Form>
                <div className="modal-body">
                  <div className={cs('form-group', country && 'has-error')}>
                    <label className="form-label">Land</label>
                    <FormSelect name="country">
                      {getCountriesSorted().map(country => (
                        <option value={country.id}>{country.name}</option>
                      ))}
                    </FormSelect>
                    {country && <div className="form-input-hint">{displayError(country)}</div>}
                  </div>

                  <div className={cs('form-group', name && 'has-error')}>
                    <label className="form-label">T.a.v.</label>
                    <FormInput name="name" autoFocus />
                    {name && <div className="form-input-hint">{displayError(name)}</div>}
                  </div>

                  <div className={cs('columns form-group', (zipcode || housenumber || extension) && 'has-error')}>
                    <div className="column col-3 col-sm-12">
                      <label className="form-label">Postcode</label>
                      <FormInput name="zipcode" />
                      {zipcode && <div className="form-input-hint">{displayError(zipcode)}</div>}
                    </div>
                    <div className="column col-3 col-sm-12">
                      <label className="form-label is-optional">Huisnummer</label>
                      <FormInput name="housenumber" />
                      {housenumber && <div className="form-input-hint">{displayError(housenumber)}</div>}
                    </div>
                    <div className="column">
                      <label className="form-label">Toevoeging</label>
                      <FormInput name="extension" />
                      {extension && <div className="form-input-hint">{displayError(extension)}</div>}
                    </div>
                    {fp.values.country === '1' && (
                      <div className="column col-auto" style={{ alignSelf: 'center' }}>
                        <button type="button" className="btn btn-primary-outline btn-action s-circle" onClick={search}>
                          <i className={cs('fal fa-fw', searching ? 'fa-spin fa-spinner-third' : 'fa-search')} />
                        </button>
                      </div>
                    )}
                  </div>

                  <div className={cs('columns form-group', (street || city) && 'has-error')}>
                    <div className="column">
                      <label className="form-label">Straat</label>
                      <FormInput name="street" />
                      {street && <div className="form-input-hint">{displayError(street)}</div>}
                    </div>
                    <div className="column">
                      <label className="form-label is-optional">Stad</label>
                      <FormInput name="city" />
                      {city && <div className="form-input-hint">{displayError(city)}</div>}
                    </div>
                  </div>

                  <div className={cs('form-group')}>
                    <label className="form-label is-optional">Bedrijfsadres?</label>
                    <FormRadio
                      name="has_company"
                      items={[
                        { title: 'Ja, er is hier een bedrijf gevestigd.', value: '1' },
                        { title: 'Nee, geen bedrijf.', value: '0' },
                      ]}
                    />
                  </div>

                  {fp.values.has_company === '1' && (
                    <React.Fragment>
                      <div className={cs('columns form-group')}>
                        <div className="column">
                          <label className="form-label">Bedrijfsnaam</label>
                          <FormInput name="company_name" />
                          {company_name && <div className="form-input-hint">{displayError(company_name)}</div>}
                        </div>
                        <div className="column">
                          <label className="form-label is-optional">BTW Nummer</label>
                          <FormInput name="company_vat" />
                          {company_vat && <div className="form-input-hint">{displayError(company_vat)}</div>}
                        </div>
                      </div>

                      {fp.values.country !== '1' && (
                        <div className="columns form-group">
                          {viesValidation && (
                            <React.Fragment>
                              {viesValidation.valid === 1 && (
                                <div className="column" style={{ alignSelf: 'center' }}>
                                  <div className="mx-2 my-2  toast toast-success">
                                    Validatie bij Europa VIES is gelukt, controleer de overeenkomst:
                                    <div className="mx-2 my-2">
                                      <strong>{viesValidation.name}</strong>
                                      <br />
                                      {nl2br(viesValidation.address)}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {viesValidation.valid === 0 && (
                                <div className="column" style={{ alignSelf: 'center' }}>
                                  <div className="mx-2 my-2  toast toast-error">
                                    Europa VIES geeft aan dat dit BTW nummer niet bestaat, controleer de invoer.
                                  </div>
                                </div>
                              )}
                              {viesValidation.valid === -1 && (
                                <div className="column" style={{ alignSelf: 'center' }}>
                                  <div className="mx-2 my-2  toast toast-error">
                                    Europa VIES gaf een foutmelding, we kunnen momenteel niet valideren.
                                  </div>
                                </div>
                              )}
                            </React.Fragment>
                          )}

                          {!viesValidation &&
                            (props.address && props.address.company_validated ? (
                              <div className="column" style={{ alignSelf: 'center' }}>
                                <div className="mx-2 my-2  toast toast-primary">
                                  Eerder succesvol gevalideerd bij Europa VIES.
                                </div>
                              </div>
                            ) : (
                              <div className="column" style={{ alignSelf: 'center' }}>
                                <div className="mx-2 my-2  toast toast-primary">
                                  Er heeft nog geen validatie plaatsgevonden bij Europa VIES.
                                </div>
                              </div>
                            ))}
                          {(!viesValidation || viesValidation.valid !== 1) && (
                            <div className="column col-auto" style={{ alignSelf: 'center' }}>
                              <button
                                type="button"
                                className="btn btn-primary-outline btn-action s-circle"
                                onClick={validateVat}
                              >
                                <i className={cs('fal fa-fw', searching ? 'fa-spin fa-spinner-third' : 'fa-check')} />
                              </button>
                            </div>
                          )}

                          {viesValidation && viesValidation.valid !== 1 && (
                            <div className="column col-auto" style={{ alignSelf: 'center' }}>
                              <FormRadio
                                name="manual_vies_validated"
                                items={[
                                  {
                                    title:
                                      'Handmatig valideren, we hebben handmatig gecontroleerd en deze gegevens zijn juist.',
                                    value: '1',
                                  },
                                  { title: 'Afkeuren, de gegevens zijn onjuist.', value: '0' },
                                ]}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  )}

                  <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button
                      type="button"
                      className="btn btn-link"
                      style={{ alignSelf: 'flex-start' }}
                      onClick={props.close}
                      disabled={fp.isSubmitting}
                    >
                      <i className={cs('fal fa-fw fa-times')} /> Annuleren
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary-outline"
                      disabled={fp.isSubmitting}
                      style={{ alignSelf: 'flex-end' }}
                    >
                      <i className={cs('fal fa-fw', fp.isSubmitting ? 'fa-spin fa-spinner' : 'fa-save')} /> Opslaan
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </div>
    </div>
  );
}

type AddressSearchResult = {
  zipcode: string;
  housenumber: string;
  extension: string;

  street: string;
  city: string;
  municipality: string;
  province: string;
  country: any;

  latitude?: string;
  longitude?: string;
};
