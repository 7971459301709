import * as React from 'react';
import { useTimeoutEffect } from '../effects';
import { MessageService } from '../services/MessageService';
import { Toast } from './Toast';

export default function AppToastbar() {
  const state = MessageService.useEffect('AppToastbar');

  useTimeoutEffect(MessageService.clear, state && state.active && (state.close || 5000));

  if (state.active) {
    const { color, content } = state;

    return (
      <div className="AppToastbar">
        <Toast color={color} dismiss={MessageService.clear}>
          {content}
        </Toast>
      </div>
    );
  }

  return null;
}
