import { BaseStore, useRxjsStore } from '../../../effects';
import * as React from 'react';
import { loadManyRecordsPaginated } from '../../../utils';
import * as _ from 'underscore';
import { ModalFilterValues } from './ModalFilter';
import Query from '../../../lib/Query';

export type PageContactPersonManyContext = {
  loaded: boolean;
  jobs: ModelVacancyJob[];
  locations: ModelVacancyLocation[];
  departments: ModelVacancyDepartment[];
  tags: ModelVacancyTag[];

  query: PageContactPersonManyContextQuery;

  pagination?: Pagination;
  records: ModelContactPerson[];

  modal?: PageContactPersonManyModalContext;

  load_iteration: number;
};

type PageContactPersonManyContextQuery = {
  page: number;
  per_page: number;
} & ModalFilterValues;

const emptyQuery = (): PageContactPersonManyContextQuery => ({
  page: 0,
  per_page: 50,

  name: '',
  email: '',

  workflows: [],
  buildings: [],
  tenants: [],

  is_archived: '0',
});

const query = new Query<PageContactPersonManyContextQuery>('PageContactPersonMany');

export class PageContactPersonManyStore extends BaseStore<PageContactPersonManyContext> {
  setup(): PageContactPersonManyContext {
    return {
      loaded: false,
      jobs: [],
      locations: [],
      departments: [],
      tags: [],

      query: query.load() || emptyQuery(),

      pagination: null,
      records: [],

      modal: null,

      load_iteration: 0,
    };
  }

  async load() {
    this.next(draft => {
      draft.loaded = false;
    });

    const result = await loadManyRecordsPaginated('/core/contact/person/many', {
      ...this.current().query,
    });

    this.next(draft => {
      draft.loaded = true;

      draft.pagination = result.pagination;
      draft.records = result.records;
      draft.load_iteration += 1;
    });
  }

  async search(values: Partial<ModalFilterValues>) {
    this.next(draft => {
      _.keys(draft.query).forEach(key => {
        const value = values[key];
        if (value !== undefined) {
          draft.query[key] = value;
        }
      });

      draft.query.page = 0;

      query.save(draft.query);
    });

    await this.load();
  }

  async resetQuery() {
    this.next(draft => {
      draft.query = emptyQuery();
    });

    query.save(null);

    await this.load();
  }

  queryIsEmpty(extra_ignore?: string[]) {
    const ignore = _.union(['page', 'per_page'], extra_ignore);
    const empty = emptyQuery();

    return _.pairs(this.current().query)
      .filter(([k, v]) => !_.contains(ignore, k))
      .every(([k, v]) => _.isEqual(v, empty[k]));
  }

  // region Utils

  setPage = async (page: number) => {
    this.next(draft => {
      draft.query.page = page;
      query.save(draft.query);
    });

    await this.load();
  };

  openModal(ctx: PageContactPersonManyModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }

  closeModal = ({ reload }: { reload: boolean } = { reload: false }) => {
    this.next(draft => {
      draft.modal = null;
    });

    if (reload) {
      setTimeout(() => {
        this.load();
      }, 1);
    }
  };

  // endregion
}

// region Modal Context

export type PageContactPersonManyModalContext =
  | {
      which: 'takeover';
      data: { person: ModelContactPerson };
    }
  | {
      which: 'signout-everywhere';
      data: { person: ModelContactPerson };
    }
  | {
      which: 'filter';
      data: { initial: ModalFilterValues };
    }
  | {
      which: 'person-create';
      data: {};
    }
  | {
      which: 'person-edit';
      data: { person: ModelContactPerson };
    }
  | {
      which: 'person-view';
      data: { person: ModelContactPerson };
    }
  | {
      which: 'person-archive';
      data: { person: ModelContactPerson };
    }
  | {
      which: 'person-activate';
      data: { person: ModelContactPerson };
    }
  | {
      which: 'person-password-reset';
      data: { person: ModelContactPerson };
    }
  | {
      which: 'person-password-set';
      data: { person: ModelContactPerson };
    };

// endregion
// region Utilities

export type PageContactPersonManyBasicProps = {
  store: PageContactPersonManyStore;
  context: PageContactPersonManyContext;
};

export function usePageContactPersonManyRxjs(): PageContactPersonManyBasicProps {
  const { store, context } = useRxjsStore<PageContactPersonManyContext, PageContactPersonManyStore>(
    PageContactPersonManyStore,
    null,
    () => {
      store.load();
    },
  );

  return { store, context };
}

export const PageContactPersonManyCtx = React.createContext<PageContactPersonManyBasicProps>(null);

// endregion
