import { BaseStore, useRxjsStore } from '../../../effects';
import * as React from 'react';
import { loadManyRecordsOr } from '../../../utils';
import { http } from '../../../http';

export type PageVacancyJobApplicationsContextSearch = {};

export type PageVacancyJobApplicationsContext = {
  job_id: number;
  job_updated_at?: string;

  loaded: boolean;
  locations: ModelVacancyLocation[];
  departments: ModelVacancyDepartment[];

  query: PageVacancyJobApplicationsContextSearch;

  pagination?: Pagination;
  job: ModelVacancyJob;
  applications: ModelVacancyApplication[];

  modal?: PageVacancyJobApplicationsModalContext;

  load_iteration: number;
};

export class PageVacancyJobApplicationsStore extends BaseStore<PageVacancyJobApplicationsContext> {
  setup(): PageVacancyJobApplicationsContext {
    return {
      job_id: null,
      loaded: false,

      query: {},

      pagination: null,
      job: null,
      applications: [],

      locations: [],
      departments: [],

      modal: null,

      load_iteration: 0,
    };
  }

  async load() {
    this.next(draft => {
      draft.loaded = false;
      draft.job_updated_at = undefined;
    });

    const locations = await loadManyRecordsOr('/core/vacancy/location/many');
    const departments = await loadManyRecordsOr('/core/vacancy/department/many');

    const job_id = this.current().job_id;

    const job = (await http().post('/core/vacancy/job/one', { job: job_id })).data;
    const applications = await loadManyRecordsOr('/core/vacancy/job/applications', {
      job: job_id,
      ...this.current().query,
    });

    this.next(draft => {
      draft.loaded = true;
      draft.locations = locations;
      draft.departments = departments;

      draft.job = job;
      draft.applications = applications;
      draft.load_iteration += 1;
    });
  }

  async search(values: PageVacancyJobApplicationsContextSearch) {
    this.next(draft => {
      // draft.query.department = values.department || null;
    });

    await this.load();
  }

  async bulkUpdateStatus(applications) {
    this.next(draft => {
      draft.loaded = false;
    });

    const res = await http().post('/core/vacancy/application/bulk_update_status', {
      job: this.current().job.id,
      applications,
    });

    await this.load();
  }

  async applicationCloseTag(application_id, tag_id: number) {
    this.next(draft => {
      draft.loaded = false;
    });

    await http().post('/core/vacancy/application/tag_close', {
      application: application_id,
      tag: tag_id,
    });

    await this.load();
  }

  async ping() {
    const job = this.current().job;
    const res = await http().post('/core/vacancy/job/ping', {
      job: job.id,
      updated_at: job.updated_at,
    });

    if (res.data !== null) {
      this.next(draft => {
        draft.job_updated_at = res.data;
      });
    }
  }

  // region Utils

  departmentById(department_id: number) {
    return this.current().departments.find(x => x.id === department_id);
  }
  locationById(location_id: number) {
    return this.current().locations.find(x => x.id === location_id);
  }

  openModal(ctx: PageVacancyJobApplicationsModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }

  closeModal = ({ reload }: { reload: boolean } = { reload: false }) => {
    this.next(draft => {
      draft.modal = null;
    });

    if (reload) {
      setTimeout(() => {
        this.load();
      }, 1);
    }
  };

  // endregion
}

// region Modal Context

export type PageVacancyJobApplicationsModalContext =
  | {
      which: 'job-edit';
      data: { job: ModelVacancyJob };
    }
  | {
      which: 'job-online-status';
      data: { job: ModelVacancyJob };
    }
  | {
      which: 'job-activate';
      data: { job: ModelVacancyJob };
    }
  | {
      which: 'job-archive';
      data: { job: ModelVacancyJob };
    }
  | {
      which: 'applicant-create';
      data: { job: ModelVacancyJob };
    }
  | {
      which: 'applicant-edit';
      data: { applicant: ModelVacancyApplicant };
    }
  | {
      which: 'application-view';
      data: { application: ModelVacancyApplication };
    }
  | {
      which: 'applicant-link-to';
      data: { applicant: ModelVacancyApplicant; job: ModelVacancyJob };
    }
  | {
      which: 'application-tag';
      data: { application: ModelVacancyApplication };
    }
  | {
      which: 'application-mail';
      data: { application: ModelVacancyApplication };
    }
  | {
      which: 'application-archive';
      data: { application: ModelVacancyApplication };
    }
  | {
      which: 'application-delete';
      data: { application: ModelVacancyApplication };
    };

// endregion
// region Utilities

export type PageVacancyJobApplicationsBasicProps = {
  store: PageVacancyJobApplicationsStore;
  context: PageVacancyJobApplicationsContext;
};

export function usePageVacancyJobApplicationsRxjs(job_id: string): PageVacancyJobApplicationsBasicProps {
  const { store, context } = useRxjsStore<PageVacancyJobApplicationsContext, PageVacancyJobApplicationsStore>(
    PageVacancyJobApplicationsStore,
    () => {
      return { job_id: parseInt(job_id) } as any;
    },
    () => {
      store.load();
    },
  );

  return { store, context };
}

export const PageVacancyJobApplicationsCtx = React.createContext<PageVacancyJobApplicationsBasicProps>(null);

// endregion
