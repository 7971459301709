import * as React from 'react';
import { BaseStore, useRxjsStore } from '../../../effects';
import { formatLineState, getTouchedErrors } from '../../../utils';
import { Form, Formik } from 'formik';
import FormSelect from '../../../components/FormSelect';
import { http } from '../../../http';
import { formToDataObject, prepareShrunkenLines } from '../OrderUtils';

type Context = {
  loaded: boolean;

  purchases_per_tenant: ModelOrderPurchaseTenant[];
  shrunken_lines: ModelOrderPurchaseLineShrunken[];
};

type Props = {
  purchases_per_tenant: ModelOrderPurchaseTenant[];
  close: (success?: boolean) => void;
};

class Store extends BaseStore<Context> {
  setup(): Context {
    return {
      loaded: true,
      purchases_per_tenant: [],
      shrunken_lines: [],
    };
  }

  async submit(values: { [name: string]: string }) {
    const { purchases_per_tenant, shrunken_lines } = this.current();
    const data = formToDataObject(values, purchases_per_tenant, shrunken_lines);

    try {
      await http().post('/core/order/purchase/tenant/open', data);

      return true;
    } catch (exc) {
      console.error(exc);

      return false;
    }
  }
}

export default function ModalOrderOpen(props: Props) {
  const { context, store } = useRxjsStore<Context, Store>(Store, (): any => {
    return {
      purchases_per_tenant: props.purchases_per_tenant,
      shrunken_lines: prepareShrunkenLines(props.purchases_per_tenant, [
        'open',
        'delivery',
        'delivered',
        'cancelled',
        'annulled',
      ]),
    };
  });

  if (!context) return null;

  const initialValues = context.shrunken_lines.reduce((r, v, k) => {
    r[`line_set-${k}`] = '0';
    return r;
  }, {});

  return (
    <div className="modal active" id="modal-id">
      <div className="modal-overlay" />

      {context.loaded && (
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, formikActions) => {
            if (await store.submit(values)) {
              props.close(true);
            } else {
              formikActions.setSubmitting(false);
            }
          }}
          render={fp => {
            const errors = getTouchedErrors(fp.touched, fp.errors);

            return (
              <Form className="modal-container" style={{ maxHeight: '90vh' }}>
                <div className="modal-header">
                  <div className="modal-title h5">Terug naar open</div>
                </div>
                <div className="modal-body">
                  <p>
                    Plaats een of meerderen artikelen terug naar open. <strong>Let op:</strong> zorg er voor dat de
                    handelingen die je gaat uitvoeren goed met de deelnemer gecommuniceerd zijn!
                  </p>
                  <table className="table">
                    <thead>
                      <tr>
                        <th colSpan={2}>Aantal</th>
                        <th>Artikel</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {context.shrunken_lines.map((line, lx) => (
                        <tr key={line.id}>
                          <td style={{ width: 80 }}>
                            <FormSelect name={`line_set-${lx}`}>
                              {Array.from({ length: line.amount + 1 }, (v, k) => k).map(v => (
                                <option key={v} value={v}>
                                  {v}
                                </option>
                              ))}
                            </FormSelect>
                          </td>
                          <td className="has-table-shrink-nowrap">van {line.amount}</td>
                          <td>{line.article.name}</td>
                          <td>{formatLineState(line.state)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button type="button" className="btn btn-dark-outline" onClick={() => props.close()}>
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button type={'submit'} className="btn btn-primary" disabled={fp.isSubmitting}>
                        <i className="fal fa-paper-plane" /> Verwerken
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      )}
    </div>
  );
}
