import * as React from 'react';
import { PageDefinition } from './PageState';

type Props = {
  page: PageDefinition;
};

export default function ModalAbandon(props: Props) {
  const [loading, setLoading] = React.useState(false);

  function onAbandonClick() {
    setLoading(true);
    props.page.store.abandon(true);
  }

  return (
    <div className="modal active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Verkoop annuleren</div>
        </div>

        {loading && (
          <div className="modal-body">
            <div className="text-center">
              <p>
                <i className="fal fa-spin fa-spinner-third" /> Even laden hoor!
              </p>
            </div>
          </div>
        )}

        {!loading && (
          <div className="modal-body">
            <p>
              Weet je zeker dat je de verkoop wilt annuleren? Wanneer je dit doet kan je de order niet meer ophalen, we
              archiveren deze voor statistische doeleinden.
            </p>
          </div>
        )}

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-link  float-left"
            onClick={() => props.page.store.toggleAbandon(false)}
          >
            <i className="fal fa-times" /> Sluiten
          </button>
          <button type="button" className="btn btn-error-outline float-right" onClick={onAbandonClick}>
            <i className="fal fa-times" /> Annuleren
          </button>
        </div>
      </div>
    </div>
  );
}
