import * as React from 'react';
import { Context, Store } from '../generic/PageOrderGenericOne';
import { cs, formatCents, getTouchedErrors, parseCents } from '../../../utils';
import { http } from '../../../http';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormInput from '../../../components/FormInput';
import FormSelect from '../../../components/FormSelect';
import { displayError } from '../../../components/FormGroup';

type Props = {
  cart_line_id: number;
  context: Context;
  store: Store;
  close: (success: boolean) => void;
};

export default function ModalOrderCompensation({ cart_line_id, context, store, close }: Props) {
  const [state, setState] = React.useState<{ line: ModelShopCartLine; article: ModelShopArticle }>({
    line: null,
    article: null,
  });

  React.useEffect(() => {
    (async () => {
      const res_line = await http().post('/core/shop/cart/line', { id: cart_line_id });
      const line = res_line.data.line;

      const res_article = await http().post('/core/shop/article/one', { id: line.article_id });
      const article = res_article.data;

      setState({ line, article });
    })();
  }, []);

  const { line, article } = state;

  if (!line || !article) {
    return null;
  }

  const schema = Yup.object().shape({
    compensation_value: Yup.string()
      .matches(/^-?[,.0-9]+([,.][0-9]{2})?$/, 'invalidNumber')
      .test('neverNegative', 'De waarde zal hiermee negatief worden, dit is niet toegestaan.', function(value) {
        let compensation_price = 0;

        if (this.parent.compensation_type === 'percentage') {
          compensation_price = Math.round((line.inclusive / 100) * (parseCents(value) / 100));
        } else {
          compensation_price = parseCents(value);
        }

        return compensation_price < line.inclusive;
      }),
    compensation_description: Yup.string()
      .max(2500, 'stringMaxLength')
      .test('isRequired', 'isRequired', function(value) {
        return !(parseCents(this.parent.compensation_value) !== 0 && (!value || (value as string).trim().length < 5));
      }),
  });

  return (
    <div className="modal active" id="modal-id">
      <div className="modal-overlay" />

      <div className="modal-container">
        <Formik
          initialValues={{
            amount: line.amount,

            compensation_value: '0',
            compensation_type: 'percentage',
            compensation_description: '',
          }}
          validationSchema={schema}
          onSubmit={async (values, actions) => {
            try {
              const res = await http().post('/core/order/purchase/lines/compensation', {
                purchase: context.purchase.id,
                line: cart_line_id,

                amount: values.amount,

                compensation_type: values.compensation_type,
                compensation_value: parseCents(values.compensation_value),
                compensation_description: values.compensation_description,
              });

              close(true);
            } catch (exc) {
              console.error(exc);
              actions.setSubmitting(false);
            }
          }}
          render={fp => {
            const errors = getTouchedErrors(fp.touched, fp.errors);

            let compensation_price;

            if (fp.values.compensation_type === 'percentage') {
              compensation_price = Math.round(
                (article.inclusive / 100) * (parseCents(fp.values.compensation_value) / 100),
              );
            } else {
              compensation_price = parseCents(fp.values.compensation_value);
            }

            const total_inclusive = line.inclusive;
            const total_compensation = compensation_price * fp.values.amount;

            return (
              <Form>
                <div className="modal-header">
                  <div className="modal-title h5">Klantcompensatie voor artikel {article.name}</div>
                </div>

                <div className="modal-body">
                  <div className="form-horizontal p-0">
                    <div className={cs('form-group', errors.amount && 'has-error')}>
                      <div className="column col-3 col-sm-12">
                        <label className="form-label">Aantal artikelen</label>
                      </div>
                      <div className="column col-3 col-sm-12">
                        <FormSelect name="amount">
                          {Array.from({ length: line.amount + 1 }, (v, k) => k).map(v => (
                            <option key={v} value={v}>
                              {v}
                            </option>
                          ))}
                        </FormSelect>
                      </div>
                    </div>
                    <div className={cs('form-group', errors.compensation_value && 'has-error')}>
                      <div className="column col-3 col-sm-12">
                        <label className="form-label">Compensatie</label>
                      </div>
                      <div className="column col-3 col-sm-12">
                        <FormSelect name="compensation_type">
                          <option value="percentage">Percentage</option>
                          <option value="fixed">Vast bedrag</option>
                        </FormSelect>
                      </div>
                      <div className="column col-2 col-sm-12  text-right">
                        <FormInput name="compensation_value" classNames={['text-right']} />
                        {errors.compensation_value && (
                          <div className="form-input-hint">{displayError(errors.compensation_value)}</div>
                        )}
                      </div>
                      <div className="column col-2  ">
                        <div className="form-input static  text-right">{formatCents(compensation_price)}</div>
                      </div>
                      <div className="column col-2  ">
                        <div className="form-input static  text-right">{formatCents(total_compensation)}</div>
                      </div>
                    </div>
                    <div className="columns mb-2">
                      <div className="column">&nbsp;</div>
                      <div className="column col-4">
                        Van <strong>{formatCents(total_inclusive)}</strong> naar{' '}
                        <strong>{formatCents(total_inclusive - total_compensation)}</strong>.
                      </div>
                    </div>

                    <div className={cs('form-group', errors.compensation_description && 'has-error')}>
                      <div className="column col-3 col-sm-12">
                        <label className="form-label">Reden van compensatie</label>
                      </div>
                      <div className="column col-9 col-sm-12">
                        <FormInput component="textarea" name="compensation_description" />
                        {errors.compensation_description && (
                          <div className="form-input-hint">{displayError(errors.compensation_description)}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button
                        type="button"
                        className="btn btn-dark-outline"
                        onClick={() => close(false)}
                        disabled={fp.isSubmitting}
                      >
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary" disabled={fp.isSubmitting}>
                        <i className="fal fa-paper-plane" /> Aanpassen
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </div>
    </div>
  );
}
