import * as React from 'react';
import { formatDate } from '../../utils';

export default function PageDmzVersions() {
  return (
    <div className="container">
      <div className="card">
        <div className="card-header">
          <div className="card-title h5">Versies van Loods 5 Apps</div>
        </div>
        <div className="card-body">
          <div className="timeline">
            <div className="timeline-item">
              <div className="timeline-left">
                <div className="timeline-icon icon-lg">
                  <i className="fal fa-star" />
                </div>
              </div>
              <div className="timeline-content">
                <h5>Versie 1.0.16 - {formatDate('2019-03-19 07:16')}</h5>

                <div className="tile tile-centered">
                  <div className="tile-content">
                    <div className="tile-title">
                      <strong>Retourneren</strong>
                    </div>

                    <div className="columns">
                      <div className="column col-6">
                        <p>
                          Er kan nu geretourneerd worden met de kassa-software! Selecteer simpelweg de artikelen die
                          retour moeten en start de retour-betaling.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tile tile-centered">
                  <div className="tile-content">
                    <div className="tile-title">
                      <strong>Cadeaukaarten</strong>
                    </div>

                    <div className="columns">
                      <div className="column col-6">
                        <p>
                          We hebben nu de juiste gegevens om betalingen te kunnen verrichten met cadeaukaarten! Voeg de
                          cadeaukaart van de klant toe aan de kassa-bon en de kassa verrekent zelf het bedrag bij
                          afrekenen.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tile tile-centered">
                  <div className="tile-content">
                    <div className="tile-title">
                      <strong>Order overzicht</strong>
                    </div>

                    <div className="columns">
                      <div className="column col-6">
                        <p>
                          Het eerste deel van het order overzicht is er! Er komt nog veel meer aan, maar nu kan je
                          direct inzien welke artikelen er op de bon stonden, je kan direct opnieuw een bon afdrukken,
                          of, je kan vanuit het order overzicht een retour starten.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="timeline-item">
              <div className="timeline-left">
                <div className="timeline-icon icon-lg">
                  <i className="fal fa-star" />
                </div>
              </div>
              <div className="timeline-content">
                <h5>Versie 1.0.15 - {formatDate('2019-03-09 14:29')}</h5>

                <div className="tile tile-centered">
                  <div className="tile-content">
                    <div className="tile-title">
                      <strong>Versie notificatie</strong>
                    </div>

                    <div className="columns">
                      <div className="column col-6">
                        <p>
                          Er zal voortaan altijd het versienummer in de hoofdbalk staan. Het versienummer zal donker
                          kleuren (zoals in het voorbeeld), wanneer er een nieuwe versie beschikbaar is.
                          <br />
                          <strong>Let op</strong>: we verwachten wanneer je met de applicatie werkt, je de update draait
                          wanneer jij daar tijd voor hebt, maar wel zo spoedig mogelijk. Een dag met een oude versie
                          draaien is niet aan te raden. Het updaten van de applicatie duurt enkele seconden, dus het kan
                          tussen transacties door!
                        </p>
                      </div>
                      <div className="column col-6">
                        <img
                          className="img-responsive s-rounded"
                          src={require('./../../assets/images/versions/1.0.15-version-in-header.png')}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tile tile-centered">
                  <div className="tile-content">
                    <div className="tile-title">
                      <strong>Verbeteringen horeca</strong>
                    </div>

                    <div className="columns">
                      <div className="column col-6">
                        <p>
                          Alle kortingen werken weer naar behoren. De panelen zijn uitgebreid met twee missende
                          artikelen.
                        </p>
                        <p>
                          Wanneer je de klant <em>ontkoppeld</em> verwijderen we alle gekoppelde kortingen, voor wanneer
                          deze perongeluk verkeerd zijn gekoppeld.
                        </p>
                        <p>
                          Zoeken naar artikelen was niet breed genoeg om alle informatie te tonen, deze is voor horeca
                          breder gemaakt.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
