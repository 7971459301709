import * as React from 'react';
import { cs } from '../../utils';
import { Field, FormikProps } from 'formik';
import moment = require('moment');

type Props = {
  name: string;
  fp: FormikProps<any>;
};

export default function SpectreCalendar(props: Props) {
  const me = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);

  const value = props.fp.values[props.name];

  return (
    <div ref={me}>
      <div className={cs('has-icon-right')}>
        <Field
          component="input"
          name={props.name}
          className={cs('form-input')}
          placeholder="dd-mm-jjjj"
          autoComplete="off"
        />
        <a href="javascript:" className="form-icon" onClick={() => !open && setOpen(true)}>
          <i className="fal fa-calendar" />
        </a>
      </div>
      {open && (
        <Calendar
          value={value}
          close={() => setOpen(false)}
          pick={value => {
            props.fp.setFieldValue(props.name, value);
            setOpen(false);
          }}
        />
      )}{' '}
    </div>
  );
}

function Calendar({ value, pick, close }) {
  let current = moment(value, 'D-M-YYYY');
  let today = moment().startOf('day');

  const [firstDay, setFirstDay] = React.useState((current.isValid() ? current : today).clone().date(1));

  const weekStart = firstDay.clone().day(1);
  const lastDay = firstDay
    .clone()
    .add(1, 'months')
    .subtract(1, 'days');
  const weekEnd = lastDay.clone().day(7);
  const days = weekEnd.diff(weekStart, 'day') + 1;
  const daysArray = Array.from({ length: days }, (k, v) => v);

  function prevMonth() {
    setFirstDay(firstDay.clone().subtract(1, 'months'));
  }
  function nextMonth() {
    setFirstDay(firstDay.clone().add(1, 'months'));
  }

  return (
    <div
      style={{
        position: 'fixed',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,

        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      onClick={e => e.currentTarget === e.target && close()}
    >
      <div className="calendar" style={{ width: 280, marginTop: 100 }}>
        <div className="calendar-nav navbar">
          <button type="button" className="btn btn-action btn-link btn-lg" onClick={prevMonth}>
            <i className="fal fa-angle-left" />
          </button>
          <div className="navbar-primary  text-center">{firstDay.format('MMMM YYYY')}</div>
          <button type="button" className="btn btn-action btn-link btn-lg" onClick={nextMonth}>
            <i className="fal fa-angle-right" />
          </button>
        </div>

        <div className="calendar-container">
          <div className="calendar-header">
            <div className="calendar-date">Maa</div>
            <div className="calendar-date">Din</div>
            <div className="calendar-date">Woe</div>
            <div className="calendar-date">Doe</div>
            <div className="calendar-date">Vri</div>
            <div className="calendar-date">Zat</div>
            <div className="calendar-date">Zon</div>
          </div>

          <div className="calendar-body">
            {daysArray.map(daysSinceStart => {
              const day = weekStart.clone().add(daysSinceStart, 'days');
              const isPrevMonth = day.get('month') < firstDay.get('month');
              const isNextMonth = day.get('month') > firstDay.get('month');
              const isToday = day.isSame(today);
              const isCurrent = current.isValid() && day.isSame(current);

              function onClick() {
                pick(day.format('L'));
              }

              return (
                <div
                  className={cs('calendar-date', isPrevMonth && 'prev-month', isNextMonth && 'next-month')}
                  key={day.format('L')}
                >
                  <button
                    type="button"
                    className={cs('date-item', isToday && 'date-today', isCurrent && 'active')}
                    onClick={onClick}
                  >
                    {day.get('date')}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="calendar-body flex-row flex-space-between">
          <div className="px-2">
            {!today.isSame(firstDay, 'month') && (
              <small>
                <a href="javascript:" onClick={() => setFirstDay(today.clone().date(1))}>
                  ga naar vandaag
                </a>
              </small>
            )}
          </div>
          <div className="px-2">
            <small>
              <a href="javascript:" onClick={() => close()}>
                sluiten
              </a>
            </small>
          </div>
        </div>
      </div>
    </div>
  );
}
