import * as React from 'react';
import { PageVacancyManageCtx } from './PageVacancyManageStore';
import * as Yup from 'yup';
import { Form, Formik, FormikActions } from 'formik';
import { http } from '../../../http';
import FormInput from '../../../components/FormInput';
import FormGroup from '../../../components/FormGroup';
import { AxiosError } from 'axios';
import SpectreAutocomplete from '../../../components/spectre/SpectreAutocomplete';
import SpectreContentResource from '../../../components/spectre/SpectreContentResource';

type Props = {
  leader: ModelVacancyLeader;
};

type Values = {
  name: string;
  location: string;
  department: string;
  resource: string;
};

const schema = Yup.object().shape({
  name: Yup.string().min(3, 'stringMinLength'),
});

export default function ModalLeaderEdit({ leader }: Props) {
  const { store, context } = React.useContext(PageVacancyManageCtx);

  const initialValues = {
    name: leader.name,
    location: (leader.location_id || '').toString(),
    department: (leader.department_id || '').toString(),
    resource: (leader.resource_id || '').toString(),
  };

  async function onSubmit(values: Values, formikActions: FormikActions<Values>) {
    try {
      await http().post('/core/vacancy/leader/edit', {
        leader: leader.id,
        name: values.name,
        location: values.location || null,
        department: values.department || null,
        resource: values.resource,
      });

      store.closeModal({ reload: true });
    } catch (exc) {
      const err: AxiosError = exc;
      console.error(exc);

      if (err.response && err.response.data && err.response.data.errors) {
        formikActions.setErrors(err.response.data.errors);
      }

      formikActions.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      render={fp => {
        return (
          <Form>
            <div className="modal modal-sm active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">
                    Leader <strong>{leader.name}</strong> aanpassen
                  </div>
                </div>

                <div className="modal-body">
                  <FormGroup title="Naam van de afdeling" name="name" errors={fp.errors}>
                    <FormInput name="name" loading={fp.isSubmitting} />
                  </FormGroup>

                  <FormGroup title="Locatie" name="location" errors={fp.errors}>
                    <SpectreAutocomplete
                      name="location"
                      fp={fp}
                      url="/core/vacancy/location/autocomplete"
                      allowEmptySearch
                    />

                    {!fp.values.location && (
                      <div className="form-input-hint">
                        De leader is actief <em>voor alle locaties.</em>
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup title="Afdeling" name="department" errors={fp.errors}>
                    <SpectreAutocomplete
                      name="department"
                      fp={fp}
                      url="/core/vacancy/department/autocomplete"
                      allowEmptySearch
                    />
                    {!fp.values.department && (
                      <div className="form-input-hint">
                        De leader is actief <em>voor alle afdelingen.</em>
                      </div>
                    )}
                  </FormGroup>

                  <FormGroup name="resource" title="Beeld">
                    <SpectreContentResource name="resource" fp={fp} folder="vacancy-leader" />
                  </FormGroup>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => store.closeModal()}
                        disabled={fp.isSubmitting}
                      >
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary" disabled={fp.isSubmitting}>
                        <i className="fal fa-pencil" /> Aanpassen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
