import { PageDefinition } from './PageState';
import * as React from 'react';
import MenuDropdown from '../../../components/MenuDropdown';

export function ComponentTileAddressDelivery(props: { page: PageDefinition; select: () => void; update: () => void }) {
  const page = props.page;

  const address = page.context.address_delivery;

  return (
    <div className="tile mb-2  tile-centered">
      {address && (
        <div className="tile-content">
          <div className="tile-title">t.a.v. {address.name}</div>
          <small className="tile-subtitle text-gray">
            {address.street} {address.housenumber} {address.extension}
            <br />
            {address.zipcode} {address.city}
            {address.has_company && (
              <div className="mt-1">
                {address.company_name}
                <br />
                {address.company_vat && (
                  <span>
                    BTW: {address.company_vat} {address.company_validated && <span>(gevalideerd)</span>}
                  </span>
                )}
              </div>
            )}
          </small>
        </div>
      )}
      {!address && (
        <div className="tile-content">
          <div className="tile-title">
            <em>Geen leveringsadres ingesteld</em>
          </div>
        </div>
      )}
      <div className="tile-action">
        <MenuDropdown
          button={<i className="fal fa-pencil" />}
          classNames={['menu-dropdown-top']}
          items={[
            <a href="javascript:" onClick={props.select}>
              <i className="fal fa-address-book  mr-1" /> adresboek
            </a>,
            <a href="javascript:" onClick={props.update}>
              <i className="fal fa-pencil  mr-1" /> aanpassen
            </a>,
          ]}
        />
      </div>
    </div>
  );
}
