import * as React from 'react';
import { Context } from '../generic/PageOrderGenericOne';
import * as Yup from 'yup';
import { cs, formatCents, formatDate, parseCentedValue, parseCents, parseMoment } from '../../../utils';
import { Form, Formik, FormikActions } from 'formik';
import * as moment from 'moment';
import { displayError } from '../../../components/FormGroup';
import FormInput from '../../../components/FormInput';
import { http } from '../../../http';

const schema = Yup.object().shape({
  authorized_at: Yup.string()
    .matches(/^\d{1,2}-\d{1,2}-\d{4}$/, 'dateIsRequired')
    .required('isRequired'),
  account: Yup.string()
    .min(3, 'stringMinLength')
    .max(250, 'stringMaxLength')
    .required('isRequired'),
  amount: Yup.string()
    .matches(/^[0-9]+([,.][0-9]{2})?$/, 'invalidNumber')
    .required('isRequired'),
});

type Values = {
  authorized_at: string;
  account: string;
  amount: string;
};

export default function ModalOrderPaymentByBank({ context, close }: { context: Context; close: any }) {
  async function onSubmit(values: Values, fa: FormikActions<Values>) {
      try {
        await http().post('/core/order/purchase/payment_by_bank', {
          purchase: context.purchase.id,

          authorized_at: formatDate(values.authorized_at, 'YYYY-MM-DD'),
          account: values.account,
          amount: parseCents(values.amount),
        });

        close();
      } catch (e) {
        console.error(e);
        fa.setSubmitting(false);
      }
  }

  const values = {
    authorized_at: moment().format('DD-MM-YYYY'),
    account: '',
    amount: ((context.purchase.inclusive - context.purchase.paid) / 100).toFixed(2)
  };

  return (
    <div className="modal active" id="modal-id">
      <div className="modal-overlay" />

      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Registreer bank-betaling</div>
        </div>

        <Formik
          initialValues={values}
          onSubmit={onSubmit}
          validationSchema={schema}
          render={fp => {
            const errors = fp.errors;
            const { authorized_at, account, amount } = errors;

            return (
              <Form>
                <div className="modal-body">
                  <div className={cs('form-group', authorized_at && 'has-error')}>
                    <label className="form-label">Betalingsmoment (op rekening ontvangen)</label>
                    <FormInput name="authorized_at" />
                    {authorized_at && <div className="form-input-hint">{displayError(authorized_at)}</div>}
                  </div>

                  <div className={cs('form-group', account && 'has-error')}>
                    <label className="form-label">Tegenrekening (rekening van de klant)</label>
                    <FormInput name="account" />
                    {account && <div className="form-input-hint">{displayError(account)}</div>}
                  </div>

                  <div className={cs('form-group', amount && 'has-error')}>
                    <label className="form-label">Betaalde bedrag</label>
                    <FormInput name="amount" />
                    {amount && <div className="form-input-hint">{displayError(amount)}</div>}
                  </div>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button
                        type="button"
                        className="btn btn-dark-outline"
                        onClick={() => close()}
                        disabled={fp.isSubmitting}
                      >
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary" disabled={fp.isSubmitting}>
                        <i className="fal fa-envelope" /> Versturen
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        />
      </div>
    </div>
  );
}
