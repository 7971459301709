import * as React from 'react';
import { PageVacancyManageCtx } from './PageVacancyManageStore';
import { http } from '../../../http';

type Props = {
  source: ModelVacancySource;
};

export default function ModalSourceArchive({ source }: Props) {
  const { store, context } = React.useContext(PageVacancyManageCtx);
  const [loading, setLoading] = React.useState<boolean>(false);

  async function onSubmit() {
    setLoading(true);

    try {
      await http().post('/core/vacancy/source/archive', {
        source: source.id,
      });

      store.closeModal({ reload: true });
    } catch (exc) {
      console.error(exc);
      setLoading(false);
    }
  }

  return (
    <div className="modal modal-sm active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">
            Bron <strong>{source.name}</strong> archiveren
          </div>
        </div>

        <div className="modal-body">
          <p>
            Weet je zeker dat je de bron <strong>{source.name}</strong> wilt archiveren? Het archiveren zorgt er alleen
            voor dat deze bron niet meer geselecteerd kan worden door de sollicitanten. De statistieken van de bron
            blijven wel bewaard.
          </p>
        </div>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link" onClick={() => store.closeModal()} disabled={loading}>
                <i className="fal fa-times" /> Annuleren
              </button>
            </div>
            <div>
              <button type="submit" className="btn btn-error" onClick={() => onSubmit()} disabled={loading}>
                <i className="fal fa-trash" /> Archiveren
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
