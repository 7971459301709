import * as React from 'react';

export default function ComponentOrderPurchaseLineTransfer({ transfer }: { transfer: string }) {
  if (transfer === 'carry')
    return (
      <span>
        <i className="fal fa-fw  fa-cash-register" /> Kassaverkoop
      </span>
    );
  if (transfer === 'delivery')
    return (
      <span className="text-primary">
        <i className="fal fa-fw fa-shipping-fast" /> Thuisbezorgen
      </span>
    );
  if (transfer === 'pickup')
    return (
      <span className="text-warning">
        <i className="fal fa-fw fa-car-side" /> Ophalen
      </span>
    );
  if (transfer === 'direct-pickup')
    return (
      <span className="text-error">
        <i className="fal fa-fw fa-dolly-flatbed" /> Direct ophalen
      </span>
    );

  return <span>transfer</span>;
}
