import * as React from 'react';
import { LocationService } from '../services/LocationService';
import { cs, formatSeconds, isWithinMz } from '../utils';
import Structure from '../lib/Structure';
import { Link } from './Link';
import { GenericDataService } from '../services/GenericDataService';
import { ProfileService } from '../services/ProfileService';

function checkIfFullscreen() {
  return !!(document as any).fullscreenElement;
}

export default function AppHeader() {
  const profile = ProfileService.useEffect('AppHeader');
  const location = LocationService.useEffect('AppHeader');
  const menuItem = Structure.structureByPathname[location.pathname];
  const parents = Structure.getParents(location, menuItem);

  const [isFullscreen, setFullscreen] = React.useState(checkIfFullscreen());

  const max_elapsed = 30 * 60;
  const idle_elapsed = 25 * 60;
  const [moment, setMoment] = React.useState(Date.now());
  const [elapsed, setElapsed] = React.useState(0);
  const [idle, setIdle] = React.useState(false);

  const check = React.useCallback(() => {
    const elapsed = Math.floor((Date.now() - moment) / 1000);

    if (elapsed >= max_elapsed) {
      ProfileService.empty();
    } else {
      if (elapsed >= idle_elapsed && !idle) {
        setIdle(true);
      }
      setElapsed(elapsed);
    }
  }, [moment, idle, max_elapsed, idle_elapsed]);

  React.useEffect(() => {
    const interval = setInterval(check, 1000);
    return () => clearInterval(interval);
  }, [moment, idle]);

  React.useEffect(() => {
    function onReset() {
      setMoment(Date.now());
      setIdle(false);
    }
    document.addEventListener('keypress', onReset);
    document.addEventListener('click', onReset);

    return () => {
      document.removeEventListener('keypress', onReset);
      document.removeEventListener('click', onReset);
    };
  }, []);

  async function toggleFullScreen() {
    if (!isFullscreen) {
      await document.documentElement.requestFullscreen();
    } else {
      await document.exitFullscreen();
    }

    setFullscreen(checkIfFullscreen());
  }

  function reload() {
    window.location.reload();
  }

  return (
    <>
      <div className="AppHeader">
        <div className="AppHeader-left">
          {parents.map(([url, item]) => (
            <Link
              className="AppHeaderItem"
              pathName={url.pathName}
              pathSearch={url.pathSearch}
              pathHash={url.pathHash}
              key={url.pathName}
            >
              {item.icon && (
                <div className="AppHeaderItem-icon">
                  <i className={cs('fal', item.icon)} />
                </div>
              )}
              <div className="AppHeaderItem-title">{item.title}</div>
            </Link>
          ))}
          {menuItem && (
            <div className="AppHeaderItem  active">
              {menuItem.icon && (
                <div className="AppHeaderItem-icon">
                  <i className={cs('fal', menuItem.icon)} />
                </div>
              )}
              <div className="AppHeaderItem-title">{menuItem.title}</div>
            </div>
          )}
        </div>

        <div className="AppHeader-right">
          <Device />

          {profile && profile.person && (
            <div className="AppHeaderItem">
              <div className="AppHeaderItem-icon">
                <i className="fal fa-fw  fa-user-alt" />
              </div>
              <div className={cs('AppHeaderItem-title', idle && 'text-error')}>
                {profile.person.name} ({formatSeconds(max_elapsed - elapsed)})
              </div>
            </div>
          )}

          <Version version={location.version} newVersion={location.newVersion} reload={reload} />

          <a className="AppHeaderItem" href="javascript:" onClick={reload}>
            <div className="AppHeaderItem-icon  only  tooltip tooltip-left" data-tooltip="Herlaad de applicatie!">
              <i className="fal fa-fw fa-sync" />
            </div>
          </a>
        </div>
      </div>

      {idle && (
        <div className="modal active">
          <div className="modal-overlay" />
          <div className="modal-container">
            <div className="modal-header">
              <div className="modal-title h5  text-error">
                Inactief - we gaan je {elapsed > 60 ? 'binnenkort' : 'spoedig'} uitloggen!
              </div>
            </div>

            <div className="modal-body">
              <div>
                {window.GLOBAL_IS_TOUCH_DEVICE && (
                  <p>
                    We detecteren dat je inactief bent, druk met je vinger ergens op het scherm, bijvoorbeeld op de
                    onderstaande "Ik ben er nog!" knop.
                  </p>
                )}
                {!window.GLOBAL_IS_TOUCH_DEVICE && (
                  <p>
                    We detecteren dat je inactief bent, klik met de muis op het scherm, of druk op een toets op het
                    toetsenboard, je kunt ook op de onderstaande knop klikken!
                  </p>
                )}
                <p>
                  <strong>Let op:</strong> we loggen je over <code>{formatSeconds(max_elapsed - elapsed)} minuten</code>{' '}
                  automatisch uit, niet opgeslagen werk zal verloren gaan.
                </p>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-link btn-lg  float-right  text-primary">
                <i className="fal fa-hand-pointer" /> Ik ben er nog!
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function Device() {
  const ggd = window.GLOBAL_GENERIC_DATA;

  const browser_name = ggd && ggd.browser && ggd.browser.name;
  const browser_icon =
    {
      edge: 'fab fa-edge',
      chrome: 'fab fa-chrome',
      firefox: 'fab fa-firefox',
      safari: 'fab fa-safari',
    }[browser_name] || 'fal fa-browser';

  const building = ggd && ggd.device && ggd.device.group && ggd.buildings[ggd.device.group.building_id];

  if (isWithinMz())
    return (
      <div className="AppHeaderItem  bg-dark">
        <div className="AppHeaderItem-title">
          {ggd.device.group.label}
          {ggd.browser && (
            <span
              className="tooltip  tooltip-left  mx-2"
              data-tooltip={`Browser: ${ggd.browser.name}, versie: ${ggd.browser.version}`}
            >
              <i className={cs('fa-fw', browser_icon)} />
            </span>
          )}
          {building && (
            <span className="tooltip  tooltip-left  mx-2" data-tooltip={`Vestiging: ${building.name}`}>
              <i className="fal fa-fw  fa-building" />
            </span>
          )}
          {ggd.device.computer && (
            <span
              className="tooltip  tooltip-left  mx-2"
              data-tooltip={`Computernaam: ${ggd.device.computer.name}, ip-adres: ${ggd.device.computer.ip_address}`}
            >
              <i className="fal fa-fw  fa-desktop" />
            </span>
          )}
          {ggd.device.terminal && (
            <span
              className="tooltip  tooltip-left  mr-2"
              data-tooltip={`PIN-terminal: ${ggd.device.terminal.identifier}`}
            >
              <i className="fal fa-fw  fa-money-check-alt" />
            </span>
          )}
          {ggd.device.paper_printer && (
            <span
              className="tooltip  tooltip-left  mr-2"
              data-tooltip={`Paper-printer: ${ggd.device.paper_printer.ip_address}`}
            >
              <i className="fal fa-fw  fa-print" />
            </span>
          )}
          {ggd.device.ticket_printer && (
            <span
              className="tooltip  tooltip-left  mr-2"
              data-tooltip={`Ticket-printer: ${ggd.device.ticket_printer.ip_address} - ${
                ggd.device.group.manual_ticket_print ? 'manueel' : 'automatisch'
              }`}
            >
              <i className="fal fa-fw  fa-ticket" />
            </span>
          )}
          {ggd.device.kitchen_printer && (
            <span
              className="tooltip  tooltip-left  mr-2"
              data-tooltip={`Keuken-printer: ${ggd.device.kitchen_printer.ip_address}`}
            >
              <i className="fal fa-fw  fa-drumstick-bite" />
            </span>
          )}
        </div>
      </div>
    );

  if (ggd && !isWithinMz())
    return (
      <div className="AppHeaderItem  bg-gray">
        <div className="AppHeaderItem-title">
          {ggd.host}
          {ggd.browser && (
            <span
              className="tooltip  tooltip-left  mx-2"
              data-tooltip={`Browser: ${ggd.browser.name}, versie: ${ggd.browser.version}`}
            >
              <i className={cs('fa-fw', browser_icon)} />
            </span>
          )}
        </div>
      </div>
    );

  return null;
}

function Version({ version, newVersion, reload }: { version?: string; newVersion?: string; reload: () => void }) {
  const version_is_global = version === GLOBAL_VERSION;

  return (
    <React.Fragment>
      {version === newVersion && (
        <div className="AppHeaderItem tooltip tooltip-bottom" data-tooltip="Je hebt de laatste versie!">
          <div className="AppHeaderItem-icon">
            <i className="fal fa-fw  fa-code-branch" />
          </div>
          <div className="AppHeaderItem-title">
            versie {version}
            {!version_is_global && ' ###'} [{GLOBAL_ENV}]
          </div>
        </div>
      )}
      {version !== newVersion && (
        <a
          className="AppHeaderItem tooltip tooltip-bottom  bg-primary text-light"
          data-tooltip="Er is een nieuwe versie, klik hier om te updaten!"
          href="javascript:"
          onClick={reload}
        >
          <div className="AppHeaderItem-icon">
            <i className="fal fa-fw  fa-star" />
          </div>
          <div className="AppHeaderItem-title">
            <strong>
              nieuwe versie {newVersion} [{GLOBAL_ENV}]
            </strong>
          </div>
        </a>
      )}
    </React.Fragment>
  );
}
