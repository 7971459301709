import * as React from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { LocationService } from '../../../../services/LocationService';
import { getTouchedErrors } from '../../../../utils';
import FormGroup from '../../../../components/FormGroup';
import FormInput from '../../../../components/FormInput';
import { handleHttpError, handleSimpleFormSubmit, http, SubmitResult } from '../../../../http';

type Props = {
  onClose: (success?: boolean) => void;
};

type FormValues = {
  name: string;
};

const schema = Yup.object().shape({
  name: Yup.string()
    .min(6, 'stringMinLength')
    .required('isRequired'),
});

function FormRender(props: Props & { fp: FormikProps<FormValues> }) {
  const errors = getTouchedErrors(props.fp.touched, props.fp.errors);

  return (
    <Form className="modal-container">
      <div className="modal-header">
        <div className="modal-title h5">Aanmaken Sticker Template</div>
      </div>
      <div className="modal-body">
        <FormGroup title="Naam van de template" name="name" errors={errors}>
          <FormInput name="name" loading={props.fp.isSubmitting} />
        </FormGroup>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-link  float-left" onClick={() => props.onClose()}>
          <i className="fal fa-times" /> Annuleren
        </button>

        <button type="submit" className="btn btn-primary  float-right" disabled={props.fp.isSubmitting}>
          <i className="fal fa-paper-plane" /> Versturen
        </button>
      </div>
    </Form>
  );
}

export default function ModalTemplateCreate(props: Props) {
  async function sendData(values: FormValues): Promise<SubmitResult> {
    try {
      const result = await http().post('/core/shop/sticker/template/create', values);

      LocationService.navigate('/shop/sticker/template/edit', { template_id: result.data.id });
    } catch (exc) {
      return handleHttpError(exc, 'Het verwerken van de data ging niet goed.');
    }
  }

  return (
    <div className="modal active">
      <div className="modal-overlay" />

      <Formik
        initialValues={{ name: '' }}
        validationSchema={schema}
        onSubmit={handleSimpleFormSubmit<FormValues>(sendData)}
        render={fp => <FormRender {...props} fp={fp} />}
      />
    </div>
  );
}
