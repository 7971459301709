import * as React from 'react';
import SpectreCardHeader from '../../../components/spectre/SpectreCardHeader';
import SpectreButton from '../../../components/spectre/SpectreButton';
import {
  PageVacancyJobManyContextSearchValues,
  PageVacancyJobManyCtx,
  usePageVacancyJobManyRxjs,
} from './PageVacancyJobManyStore';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { cs, formatDate, hasRights } from '../../../utils';
import FormSelect from '../../../components/FormSelect';
import FormInput from '../../../components/FormInput';
import ModalJobCreate from '../modals/ModalJobCreate';
import ModalJobEdit from '../modals/ModalJobEdit';
import GenericPagination from '../../../components/GenericPagination';
import ModalJobOnlineStatus from '../modals/ModalJobOnlineStatus';
import ModalJobArchive from '../modals/ModalJobArchive';
import ModalJobDearchive from '../modals/ModalJobDearchive';
import * as _ from 'underscore';
import ModalJobActivate from '../modals/ModalJobActivate';
import AtomTableNoRecords from '../../../components/AtomTableNoRecords';

export default function PageVacancyJobMany() {
  const { store, context } = usePageVacancyJobManyRxjs();

  if (!store || !context) return null;

  return (
    <PageVacancyJobManyCtx.Provider value={{ store, context }}>
      <div className="container">
        <Table />
        <Modals />
      </div>
    </PageVacancyJobManyCtx.Provider>
  );
}

function Table() {
  const { store, context } = React.useContext(PageVacancyJobManyCtx);

  function openJobCreate() {
    store.openModal({ which: 'job-create', data: {} });
  }

  return (
    <div className="card">
      <SpectreCardHeader
        title="Vacatures"
        subtitle="Beheer hier de vacatures."
        buttons={[
          hasRights(['vacancy.write']) && (
            <SpectreButton
              icon="fa-plus"
              onClick={openJobCreate}
              tooltip={{ title: 'Nieuwe vacature toevoegen', position: 'left' }}
            />
          ),
        ]}
      />
      <div className="card-body">
        <Search />
      </div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th>Titel</th>
              <th className="text-center">Uren</th>
              <th className="text-center">Start / Einde</th>
              <th>Online</th>
              <th>Status</th>
              <th className="tooltip tooltip-left" data-tooltip="Sollicitaties.">
                <i className="fal fa-users" />
              </th>
              <th className="tooltip tooltip-left" data-tooltip="Aangenomen.">
                <i className="fal fa-user-check" />
              </th>
              <th>&nbsp;</th>
            </tr>
          </thead>

          {context.jobs.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={9}>
                  <AtomTableNoRecords />
                </td>{' '}
              </tr>
            </tbody>
          )}

          {context.jobs.length > 0 && (
            <>
              <GenericPagination pagination={context.pagination} setPage={store.setPage} span={9} />
              <tbody>
                {!context.loaded && (
                  <tr>
                    <td colSpan={7} className="text-center">
                      <em>Even laden hoor, ogenblikje!</em>
                    </td>
                  </tr>
                )}

                {context.jobs.map(job => {
                  function openJobOnlineStatus() {
                    store.openModal({ which: 'job-online-status', data: { job } });
                  }
                  function openJobActivate() {
                    store.openModal({ which: 'job-activate', data: { job } });
                  }
                  function openJobArchive() {
                    store.openModal({ which: 'job-archive', data: { job } });
                  }
                  function openJobDearchive() {
                    store.openModal({ which: 'job-dearchive', data: { job } });
                  }
                  function openJobEdit() {
                    store.openModal({ which: 'job-edit', data: { job } });
                  }

                  const department = store.departmentById(job.department_id);
                  const location = store.locationById(job.location_id);

                  return (
                    <tr key={job.id}>
                      <td className="text-right has-table-shrink-nowrap">#{job.id}</td>
                      <td>
                        <strong>{job.title}</strong>
                        <br />
                        {department ? department.name : <em>onbekend</em>} in{' '}
                        {location ? location.name : <em>onbekend</em>}
                      </td>
                      <td className="has-table-shrink-nowrap  text-center">
                        {job.hours_min || '*'} / {job.hours_max || '*'}
                      </td>
                      <td className="has-table-shrink-nowrap  text-center">
                        {formatDate(job.start, 'L', 'onbekend')} - {formatDate(job.end, 'L', 'onbekend')}
                      </td>
                      <td className="has-table-shrink-nowrap">
                        <JobOnlineStatus job={job} />
                      </td>
                      <td className="has-table-shrink-nowrap">
                        <JobStatus job={job} />
                      </td>
                      <td
                        className="has-table-shrink-nowrap text-center tooltip tooltip-left"
                        data-tooltip="Sollicitaties."
                      >
                        {job.amount_applications_active}
                      </td>
                      <td
                        className="has-table-shrink-nowrap text-center tooltip tooltip-left"
                        data-tooltip="Aangenomen."
                      >
                        {job.amount_applications_employed}
                      </td>
                      <td className="has-table-shrink-nowrap">
                        {hasRights(['vacancy.write']) && (
                          <React.Fragment>
                            {_.contains(['pending', 'active'], job.status) && (
                              <SpectreButton
                                icon="fa-trash"
                                onClick={openJobArchive}
                                tooltip={{ title: 'Vacature archiveren.', position: 'left' }}
                              />
                            )}
                            {job.status === 'pending' && (
                              <SpectreButton
                                icon="fa-play"
                                onClick={openJobActivate}
                                tooltip={{ title: 'Vacature activeren.', position: 'left' }}
                              />
                            )}
                            {job.status === 'active' && (
                              <SpectreButton
                                icon={job.online_status === 'online' ? 'fa-align-slash' : 'fa-plug'}
                                onClick={openJobOnlineStatus}
                                tooltip={{
                                  title: job.online_status === 'online' ? 'Offline halen.' : 'Online zetten.',
                                  position: 'left',
                                }}
                              />
                            )}
                            {_.contains(['abandoned', 'fulfilled'], job.status) && (
                              <SpectreButton
                                icon="fa-sync"
                                onClick={openJobDearchive}
                                tooltip={{ title: 'Vacature heropenen.', position: 'left' }}
                              />
                            )}
                            {_.contains(['pending', 'active'], job.status) && (
                              <SpectreButton
                                icon="fa-pencil"
                                onClick={openJobEdit}
                                tooltip={{ title: 'Vacature aanpassen.', position: 'left' }}
                              />
                            )}
                          </React.Fragment>
                        )}

                        <SpectreButton
                          icon="fa-users"
                          link={['/vacancy/job/applications', { job_id: job.id.toString() }]}
                          tooltip={{ title: 'Sollicitaties bekijken.', position: 'left' }}
                        />
                      </td>
                    </tr>
                  );
                })}
                {context.loaded && context.jobs.length === 0 && (
                  <tr>
                    <td colSpan={6}>
                      <div className="empty">
                        <div className="empty-icon">
                          <i className="fal fa-empty-set" />
                        </div>
                        <p className="empty-title h5">Niets gevonden</p>
                        <p className="empty-subtitle">Er zijn geen vacatures gevonden met dit filter.</p>
                        <div className="empty-action">
                          <button className="btn btn-primary" onClick={openJobCreate}>
                            Vacature toevoegen
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              <GenericPagination pagination={context.pagination} setPage={store.setPage} span={9} />
            </>
          )}
        </table>
      </div>
    </div>
  );
}

function JobOnlineStatus({ job }: { job: ModelVacancyJob }) {
  switch (job.online_status) {
    case 'online':
      return (
        <span className="text-success">
          <i className="fal fa-fw fa-plug" /> Online
        </span>
      );
    case 'offline':
      return (
        <span className="text-error">
          <i className="fal fa-fw fa-align-slash" /> Offline
        </span>
      );
    default:
      return <span className="text-error">{job.online_status}</span>;
  }
}

function JobStatus({ job }: { job: ModelVacancyJob }) {
  switch (job.status) {
    case 'pending':
      return (
        <span className="text-primary">
          <i className="fal fa-fw fa-pencil" /> Concept
        </span>
      );
    case 'active':
      return (
        <span className="text-primary">
          <i className="fal fa-fw fa-play" /> Actief
        </span>
      );
    case 'fulfilled':
      return (
        <span className="text-success">
          <i className="fal fa-fw fa-check" /> Vervuld
        </span>
      );
    case 'canceled':
      return (
        <span className="text-error">
          <i className="fal fa-fw fa-ban" /> Geannuleerd
        </span>
      );
    case 'abandoned':
      return (
        <span className="text-error">
          <i className="fal fa-fw fa-ban" /> Verlaten
        </span>
      );
    default:
      return <span className="text-error">{job.status}</span>;
  }
}

function Modals() {
  const { store, context } = React.useContext(PageVacancyJobManyCtx);
  const mctx = context.modal;

  if (!mctx) return null;

  return (
    <React.Fragment>
      {mctx.which === 'job-create' && <ModalJobCreate {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'job-edit' && <ModalJobEdit {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'job-online-status' && <ModalJobOnlineStatus {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'job-activate' && <ModalJobActivate {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'job-archive' && <ModalJobArchive {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'job-dearchive' && <ModalJobDearchive {...mctx.data} closeModal={store.closeModal} />}
    </React.Fragment>
  );
}

function Search() {
  const { store, context } = React.useContext(PageVacancyJobManyCtx);
  const initialValues: PageVacancyJobManyContextSearchValues = _.omit(context.query, ['page', 'per_page']);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, formikActions) => {
        await store.search(values);
        formikActions.setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string(),
        online_status: Yup.string(),
        status: Yup.string(),
      })}
      render={fp => (
        <Form style={{ padding: '0 1rem' }}>
          <div className={cs('form-group')}>
            <div className="columns">
              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="button"
                  className={cs(
                    'btn btn-action s-circle tooltip tooltip-right',
                    context.query_is_empty ? 'btn-error-outline ' : 'btn-error',
                  )}
                  data-tooltip="Leeg alle zoekvelden."
                  disabled={!context.loaded}
                  onClick={async () => {
                    fp.setValues((await store.resetQuery()).current().query);
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="column col-4 col-xs-12  pr-2">
                <label className="form-label">Zoeken op titel</label>
                <FormInput name="title" />
              </div>
              <div className="column">
                <div className="columns">
                  <div className="column  col-xs-12  pr-2">
                    <label className="form-label">Online Status</label>
                    <FormSelect name="online_status">
                      <option value="">- niet filteren -</option>
                      <option value="online">Alleen online</option>
                      <option value="offline">Alleen offline</option>
                    </FormSelect>
                  </div>
                  <div className="column  col-xs-12  pr-2">
                    <label className="form-label">Status</label>
                    <FormSelect name="status">
                      <option value="">- niet filteren -</option>
                      <option value="pending">Concept</option>
                      <option value="active">Actief</option>
                      <option value="fulfilled">Vervuld</option>
                      <option value="abandoned">Verlaten</option>
                    </FormSelect>
                  </div>
                  <div className="column  col-xs-12  pr-2">
                    <label className="form-label">Locatie</label>
                    <FormSelect name="location">
                      <option value="">- niet filteren -</option>
                      {context.locations.map(location => (
                        <option key={location.id} value={location.id.toString()}>
                          {location.name}
                        </option>
                      ))}
                    </FormSelect>
                  </div>
                  <div className="column  col-xs-12  pr-2">
                    <label className="form-label">Afdeling</label>
                    <FormSelect name="department">
                      <option value="">- niet filteren -</option>
                      {context.departments.map(department => (
                        <option key={department.id} value={department.id.toString()}>
                          {department.name}
                        </option>
                      ))}
                    </FormSelect>
                  </div>
                </div>
              </div>

              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="submit"
                  className="btn btn-primary-outline btn-action s-circle"
                  disabled={!context.loaded}
                >
                  <i className={cs('fal fa-fw', !context.loaded ? 'fa-spin fa-spinner-third' : 'fa-search')} />
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
}
