import { BaseStore, useRxjsStore } from '../../../effects';
import * as React from 'react';
import { loadManyRecordsOr } from '../../../utils';

export type PageVacancyManageContext = {
  loaded: boolean;
  locations: ModelVacancyLocation[];
  departments: ModelVacancyDepartment[];
  leaders: ModelVacancyLeader[];
  sources: ModelVacancySource[];
  tags: ModelVacancyTag[];
  mails: ModelVacancyMail[];

  modal?: PageVacancyManageModalContext;
};

export class PageVacancyManageStore extends BaseStore<PageVacancyManageContext> {
  setup(): PageVacancyManageContext {
    return {
      loaded: false,
      locations: [],
      departments: [],
      leaders: [],
      sources: [],
      tags: [],
      mails: [],

      modal: null,
    };
  }

  async load() {
    this.next(draft => {
      draft.loaded = false;
    });

    const locations = await loadManyRecordsOr('/core/vacancy/location/many');
    const departments = await loadManyRecordsOr('/core/vacancy/department/many');
    const leaders = await loadManyRecordsOr('/core/vacancy/leader/many');
    const sources = await loadManyRecordsOr('/core/vacancy/source/many');
    const tags = await loadManyRecordsOr('/core/vacancy/tag/many');
    const mails = await loadManyRecordsOr('/core/vacancy/mail/many');

    this.next(draft => {
      draft.loaded = true;
      draft.locations = locations;
      draft.departments = departments;
      draft.leaders = leaders;
      draft.sources = sources;
      draft.tags = tags;
      draft.mails = mails;
    });
  }

  openModal(ctx: PageVacancyManageModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }

  closeModal({ reload }: { reload: boolean } = { reload: false }) {
    this.next(draft => {
      draft.modal = null;
    });

    if (reload) {
      setTimeout(() => {
        this.load();
      }, 1);
    }
  }
}

// region Modal Context

export type PageVacancyManageModalContext =
  | {
      which: 'location-create';
      data: {};
    }
  | {
      which: 'location-edit';
      data: { location: ModelVacancyLocation };
    }
  | {
      which: 'location-archive';
      data: { location: ModelVacancyLocation };
    }
  | {
      which: 'department-create';
      data: {};
    }
  | {
      which: 'department-edit';
      data: { department: ModelVacancyDepartment };
    }
  | {
      which: 'department-archive';
      data: { department: ModelVacancyDepartment };
    }
  | {
      which: 'mail-create';
      data: {};
    }
  | {
      which: 'mail-edit';
      data: { mail: ModelVacancyMail };
    }
  | {
      which: 'mail-archive';
      data: { mail: ModelVacancyMail };
    }
  | {
      which: 'source-create';
      data: {};
    }
  | {
      which: 'source-edit';
      data: { source: ModelVacancySource };
    }
  | {
      which: 'source-archive';
      data: { source: ModelVacancySource };
    }
  | {
      which: 'tag-create';
      data: {};
    }
  | {
      which: 'tag-edit';
      data: { tag: ModelVacancyTag };
    }
  | {
      which: 'tag-archive';
      data: { tag: ModelVacancyTag };
    }
  | {
      which: 'leader-create';
      data: {};
    }
  | {
      which: 'leader-edit';
      data: { leader: ModelVacancyLeader };
    }
  | {
      which: 'leader-archive';
      data: { leader: ModelVacancyLeader };
    };

// endregion
// region Utilities

export type PageVacancyManageBasicProps = {
  store: PageVacancyManageStore;
  context: PageVacancyManageContext;
};

export function usePageVacancyManageRxjs(): PageVacancyManageBasicProps {
  const { store, context } = useRxjsStore<PageVacancyManageContext, PageVacancyManageStore>(
    PageVacancyManageStore,
    null,
    () => {
      store.load();
    },
  );

  return { store, context };
}

export const PageVacancyManageCtx = React.createContext<PageVacancyManageBasicProps>(null);

// endregion
