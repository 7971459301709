import { PageDefinition } from './PageState';
import * as React from 'react';
import MenuDropdown from '../../../components/MenuDropdown';

export function ComponentTileCustomer({ page }: { page: PageDefinition }) {
  return (
    <div className="tile mb-2  tile-centered">
      <div className="tile-content">
        <div className="tile-title">{page.context.customer.name}</div>
        <small className="tile-subtitle text-gray">
          {page.context.customer.email && (
            <div>
              <i className="fal fa-envelope" /> {page.context.customer.email}
            </div>
          )}
          {page.context.customer.phone && (
            <div>
              <i className="fal fa-phone" /> {page.context.customer.phone}
            </div>
          )}
          {page.context.customer.mobile && (
            <div>
              <i className="fal fa-mobile" /> {page.context.customer.mobile}
            </div>
          )}
        </small>
      </div>
      <div className="tile-action">
        <MenuDropdown
          button={<i className="fal fa-pencil" />}
          classNames={['menu-dropdown-top']}
          items={[
            <a href="javascript:" onClick={() => page.store.toggleModalCustomerSearch()}>
              <i className="fal fa-search  mr-1" /> opzoeken
            </a>,
            <a href="javascript:" onClick={() => page.store.toggleModalCustomerUpdate()}>
              <i className="fal fa-pencil  mr-1" /> aanpassen
            </a>,
            <a href="javascript:" onClick={() => page.store.anonymize()}>
              <i className="fal fa-trash-alt  mr-1" /> ontkoppelen
            </a>,
          ]}
        />
      </div>
    </div>
  );
}
