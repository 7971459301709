import * as React from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import FormGroup from '../../components/FormGroup';
import FormInput from '../../components/FormInput';
import Structure from '../../lib/Structure';
import { Form, Formik } from 'formik';
import { GenericDataService } from '../../services/GenericDataService';
import { getTouchedErrors } from '../../utils';
import { handleHttpError, handleSimpleFormSubmit, http, SubmitResult } from '../../http';
import { LocationService } from '../../services/LocationService';
import { ProfileService } from '../../services/ProfileService';
import PageDmzLoginModalDroplet from './modal/PageDmzLoginModalDroplet';
import { Link } from '../../components/Link';

interface FormValues {
  email: string;
  password: string;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .min(4, 'stringMinLength')
    .required('isRequired'),
  password: Yup.string()
    .min(6, 'stringMinLength')
    .required('isRequired'),
});

export default function PageDmzLogin() {
  const profile = ProfileService.useEffect('PageDmzLogin');
  const location = LocationService.useEffect('PageDmzLogin');

  const [state, setState] = React.useState({
    modalDropletOpen: false,
  });

  if (profile.present && location.pathname === '/dmz/login') {
    // Only redirect when we are on this exact page!
    const pathname = Structure.getMenuForProfile(profile).reduce((result, menuItem) => {
      return result === '/'
        ? menuItem.sections.reduce((result, sectionItem) => {
            return sectionItem.items[0];
          }, '/')
        : result;
    }, '/');

    LocationService.navigate(pathname);
  }

  async function sendData(values: FormValues): Promise<SubmitResult> {
    const { email, password } = values;

    try {
      delete axios.defaults.headers['x-session'];

      const result = await http().post(
        '/dmz/security/authentication',
        new URLSearchParams({
          email,
          password,
        }),
      );

      axios.defaults.headers['x-session'] = result.data.session.token;

      await GenericDataService.load();

      ProfileService.setProfile({
        person: result.data.person,
        session: result.data.session,
      });

      return {
        valid: true,
        message: 'Welkom in de ShopManager POS.',
      };
    } catch (exc) {
      return handleHttpError(exc, 'Het aanmelden ging niet goed.');
    }
  }

  function onDropletLoginClick() {
    setState({ modalDropletOpen: true });
  }

  function onCloseModalDroplet() {
    setState({ modalDropletOpen: false });
  }

  return (
    <div className="container">
      <div className="columns">
        <div className="column col-sm-10 col-md-8 col-5 col-mx-auto">
          <h1 className="text-center text-primary">Loods 5 - Apps</h1>

          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={schema}
            onSubmit={handleSimpleFormSubmit<FormValues>(sendData)}
            render={fp => {
              const errors = getTouchedErrors(fp.touched, fp.errors);

              return (
                <Form noValidate>
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title  h5">Aanmelden</div>
                      <div className="card-subtitle text-gray">Voordat je verder gaat, even vertellen wie je bent!</div>
                    </div>
                    <div className="card-body">
                      <FormGroup title="E-mailadres" name="email" errors={errors}>
                        <FormInput name="email" type="email" loading={fp.isSubmitting} autoFocus />
                      </FormGroup>
                      <FormGroup title="Wachtwoord" name="password" errors={errors}>
                        <FormInput name="password" type="password" loading={fp.isSubmitting} />
                      </FormGroup>
                    </div>
                    <div className="card-footer">
                      <div className="flex-row flex-space-between">
                        <div>
                          <button type="submit" className="btn btn-primary mr-2" disabled={fp.isSubmitting}>
                            <i className="fal fa-send" />
                            <i className="fal fa-paper-plane" /> Versturen
                          </button>
                          <Link
                            pathName="/dmz/password-recovery"
                            className="btn btn-primary-outline"
                            disabled={fp.isSubmitting}
                          >
                            <i className="fal fa-send" />
                            <i className="fal fa-question-circle" /> Wachtwoord vergeten?
                          </Link>
                        </div>
                      </div>
                      <div className="flex-row flex-space-between" style={{ paddingTop: '1rem', color: '#a3a3a3' }}>
                        <div />
                        <div>
                          Server {location.newVersion} | App: {GLOBAL_VERSION}
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          />
        </div>
      </div>

      {state.modalDropletOpen && <PageDmzLoginModalDroplet onClose={onCloseModalDroplet} />}
    </div>
  );
}

PageDmzLogin.getAppRouterClassname = () => {
  return 'PageDmzLogin';
};
