import * as React from 'react';
import { loadManyRecordsOr } from '../../../utils';
import produce from 'immer';
import { http } from '../../../http';
import * as _ from 'underscore';

type Props = {
  application: ModelVacancyApplication;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalJobApplicationTag({ application, closeModal }: Props) {
  const [tags, setTags] = React.useState<ModelVacancyTag[]>([]);
  const [loaded, setLoaded] = React.useState(false);
  const [checked_set, setCheckedSet] = React.useState(
    application.tags.filter(vat => vat.end === null).map(vat => vat.tag_id),
  );

  React.useEffect(() => {
    (async () => {
      setTags(await loadManyRecordsOr('/core/vacancy/tag/many', { status: application.status }));
      setLoaded(true);
    })();
  }, []);

  async function onSubmit() {
    await http().post('/core/vacancy/application/tag', {
      application: application.id,
      tags: checked_set,
    });

    closeModal({ reload: true });
  }

  function onChange(checked: boolean, tag_id: number) {
    return () => {
      setCheckedSet(
        produce(checked_set, draft => {
          if (checked) {
            draft.splice(draft.findIndex(x => x === tag_id), 1);
          } else {
            draft.push(tag_id);
          }
        }),
      );
    };
  }

  return (
    <div className="modal modal-sm active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">
            Tag toevoegen bij <strong>{application.applicant.name}</strong>
          </div>
        </div>

        <div className="modal-body">
          {application.tags
            .filter(vat => vat.end === null)
            .filter(vat => !_.contains(vat.tag.status_set, application.status))
            .map(vat => {
              const tag = vat.tag;
              const checked = _.contains(checked_set, tag.id);

              return (
                <div key={tag.id}>
                  <label className="form-checkbox">
                    <input type="checkbox" checked={checked} onChange={onChange(checked, tag.id)} />
                    <i className="form-icon" />
                    <span className="chip">
                      <figure className="avatar avatar-xs" style={{ backgroundColor: `#${tag.color}` }} /> {tag.name}
                    </span>
                  </label>
                </div>
              );
            })}

          {loaded &&
            tags.map(tag => {
              const checked = _.contains(checked_set, tag.id);

              return (
                <div key={tag.id}>
                  <label className="form-checkbox">
                    <input type="checkbox" checked={checked} onChange={onChange(checked, tag.id)} />
                    <i className="form-icon" />
                    <span className="chip">
                      <figure className="avatar avatar-xs" style={{ backgroundColor: `#${tag.color}` }} /> {tag.name}
                    </span>
                  </label>
                </div>
              );
            })}
        </div>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                <i className="fal fa-times" /> Annuleren
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-primary" onClick={onSubmit}>
                <i className="fal fa-save" /> Opslaan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
