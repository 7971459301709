import * as React from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikActions } from 'formik';
import FormOptionSwitch from "../../../components/FormOptionSwitch";
import { http } from '../../../http';
import FormInput from '../../../components/FormInput';
import FormGroup from '../../../components/FormGroup';
import { AxiosError } from 'axios';
import SpectreAutocomplete from '../../../components/spectre/SpectreAutocomplete';
import SpectreCalendar from '../../../components/spectre/SpectreCalendar';
import FormEditor from '../../../components/FormEditor';
import { draftToValue, sanitizeFormValues, valueToDraft } from '../../../utils';
import moment = require('moment');

type Values = {
  title: string;
  location: string;
  department: string;
  contact: string;
  start: string;
  end: string;
  mail_applicant_apply: string;
  mail_pending: string;
  mail_interview: string;
  mail_negotiations: string;
  mail_employed: string;
  mail_rejected: string;
  education_level: string;
  hours_min: string;
  hours_max: string;
  is_motivation_optional: boolean;
  // description_state: EditorState;
};

const schema = Yup.object().shape({
  title: Yup.string().min(3, 'stringMinLength'),
  start: Yup.string().matches(/^\d{1,2}-\d{1,2}-\d{4}$/, 'dateIsRequired'),
  end: Yup.string().matches(/^\d{1,2}-\d{1,2}-\d{4}$/, 'dateIsRequired'),
  hours_min: Yup.number()
    .typeError('invalidNumberWhole')
    .integer('invalidNumberWhole')
    .min(0),
  hours_max: Yup.number()
    .typeError('invalidNumberWhole')
    .integer('invalidNumberWhole')
    .min(0),
});

type Props = {
  job: ModelVacancyJob;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalJobEdit({ job, closeModal }: Props) {
  const initialValues = sanitizeFormValues({
    title: job.title,
    location: (job.location_id || '').toString(),
    department: (job.department_id || '').toString(),
    contact: (job.contact_id || '').toString(),
    start: job.start ? moment(job.start).format('L') : '',
    end: job.end ? moment(job.end).format('L') : '',
    mail_applicant_apply: (job.mail_applicant_apply_id || '').toString(),
    mail_pending: (job.mail_pending_id || '').toString(),
    mail_interview: (job.mail_interview_id || '').toString(),
    mail_negotiations: (job.mail_negotiations_id || '').toString(),
    mail_employed: (job.mail_employed_id || '').toString(),
    mail_rejected: (job.mail_rejected_id || '').toString(),
    education_level: job.education_level,
    hours_min: job.hours_min,
    hours_max: job.hours_max,

    is_motivation_optional: job.is_motivation_optional,

    description_state: valueToDraft(job.description_json, job.description_html),
  });

  async function onSubmit(values: Values, formikActions: FormikActions<Values>) {
    const [description_json, description_html] = draftToValue((values as any).description_state);

    try {
      await http().post('/core/vacancy/job/edit', {
        job: job.id,

        title: values.title,
        location: values.location || null,
        department: values.department || null,
        contact: values.contact || null,
        start: values.start,
        end: values.end,

        mail_applicant_apply: values.mail_applicant_apply || null,
        mail_pending: values.mail_pending || null,
        mail_interview: values.mail_interview || null,
        mail_negotiations: values.mail_negotiations || null,
        mail_employed: values.mail_employed || null,
        mail_rejected: values.mail_rejected || null,

        education_level: values.education_level,
        hours_min: values.hours_min || null,
        hours_max: values.hours_max || null,

        description_json: JSON.stringify(description_json),
        description_html: description_html,

        is_motivation_optional: values.is_motivation_optional,
      });

      closeModal({ reload: true });
    } catch (exc) {
      const err: AxiosError = exc;
      console.error(exc);

      if (err.response.data && err.response.data.errors) {
        formikActions.setErrors(err.response.data.errors);
      }

      formikActions.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      render={fp => {
        return (
          <Form>
            <div className="modal active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">
                    Vacature <strong>{fp.values.title}</strong> aanpassen
                  </div>
                </div>

                <div className="modal-body">
                  <FormGroup title="Titel" name="title" errors={fp.errors}>
                    <FormInput name="title" loading={fp.isSubmitting} classNames={['input-lg']} />
                  </FormGroup>

                  <div className="columns">
                    <div className="column col-xs-12">
                      <FormGroup title="Locatie" name="location" errors={fp.errors}>
                        <SpectreAutocomplete
                          name="location"
                          fp={fp}
                          url="/core/vacancy/location/autocomplete"
                          allowEmptySearch
                        />
                      </FormGroup>
                    </div>
                    <div className="column col-xs-12">
                      <FormGroup title="Afdeling" name="department" errors={fp.errors}>
                        <SpectreAutocomplete
                          name="department"
                          fp={fp}
                          url="/core/vacancy/department/autocomplete"
                          allowEmptySearch
                        />
                      </FormGroup>
                    </div>
                    <div className="column col-xs-12">
                      <FormGroup title="Contactpersoon" name="contact" errors={fp.errors}>
                        <SpectreAutocomplete
                          name="contact"
                          fp={fp}
                          url="/core/contact/person/autocomplete"
                          query={{ is_global: 2, tenant_set: [141, 293, 341] }}
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <div className="columns">
                    <div className="column col-4">
                      <FormGroup name="start" title="Startdatum" errors={fp.errors}>
                        <SpectreCalendar name="start" fp={fp} />
                      </FormGroup>
                    </div>
                    <div className="column col-4">
                      <FormGroup name="end" title="Einddatum" errors={fp.errors}>
                        <SpectreCalendar name="end" fp={fp} />
                      </FormGroup>
                    </div>
                  </div>

                  <FormGroup
                    name="is_motivation_optional"
                    title="Is de motivatiebrief optioneel?"
                  >
                    <FormOptionSwitch
                      name="is_motivation_optional"
                      title_on="De motivatiebrief zal niet worden getoond op de website."
                      title_off="De motivatiebrief is verplicht en wordt getoond."
                    />
                  </FormGroup>

                  <FormGroup
                    name="mail_applicant_apply"
                    title="Automatisch mail aanmelding nieuwe sollicitant"
                    errors={fp.errors}
                  >
                    <SpectreAutocomplete
                      name="mail_applicant_apply"
                      fp={fp}
                      allowEmptySearch
                      url="/core/vacancy/mail/autocomplete"
                    />
                  </FormGroup>

                  <div className="columns">
                    <div className="column col-4">
                      <FormGroup name="mail_pending" title="Mail voor: Wachtlijst" errors={fp.errors}>
                        <SpectreAutocomplete
                          name="mail_pending"
                          fp={fp}
                          allowEmptySearch
                          url="/core/vacancy/mail/autocomplete"
                          query={{ statuses: ['pending'] }}
                        />
                      </FormGroup>
                    </div>
                    <div className="column col-4">
                      <FormGroup name="mail_interview" title="Mail voor: Interview (rondes)" errors={fp.errors}>
                        <SpectreAutocomplete
                          name="mail_interview"
                          fp={fp}
                          allowEmptySearch
                          url="/core/vacancy/mail/autocomplete"
                          query={{ statuses: ['interview'] }}
                        />
                      </FormGroup>
                    </div>
                    <div className="column col-4">
                      <FormGroup name="mail_negotiations" title="Mail voor: Onderhandeling" errors={fp.errors}>
                        <SpectreAutocomplete
                          name="mail_negotiations"
                          fp={fp}
                          allowEmptySearch
                          url="/core/vacancy/mail/autocomplete"
                          query={{ statuses: ['negotiations'] }}
                        />
                      </FormGroup>
                    </div>
                    <div className="column col-4">
                      <FormGroup name="mail_employed" title="Mail voor: Aangenomen" errors={fp.errors}>
                        <SpectreAutocomplete
                          name="mail_employed"
                          fp={fp}
                          allowEmptySearch
                          url="/core/vacancy/mail/autocomplete"
                          query={{ statuses: ['employed'] }}
                        />
                      </FormGroup>
                    </div>
                    <div className="column col-4">
                      <FormGroup name="mail_rejected" title="Mail voor: Afgewezen" errors={fp.errors}>
                        <SpectreAutocomplete
                          name="mail_rejected"
                          fp={fp}
                          allowEmptySearch
                          url="/core/vacancy/mail/autocomplete"
                          query={{ statuses: ['rejected'] }}
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <FormGroup name="description_state" title="Omschrijving" errors={fp.errors}>
                    <FormEditor
                      name="description_state"
                      onChange={(n, s) => fp.setFieldValue(n, s)}
                      editorState={(fp.values as any).description_state}
                    />
                  </FormGroup>

                  <FormGroup name="education_level" title="Opleidingsniveau" errors={fp.errors}>
                    <FormInput name="education_level" />
                  </FormGroup>

                  <div className="columns">
                    <div className="column col-4">
                      <FormGroup name="hours_min" title="Min. uren" errors={fp.errors}>
                        <FormInput name="hours_min" />
                      </FormGroup>
                    </div>
                    <div className="column col-4">
                      <FormGroup name="hours_max" title="Max. uren" errors={fp.errors}>
                        <FormInput name="hours_max" />
                      </FormGroup>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary" disabled={fp.isSubmitting}>
                        <i className="fal fa-pencil" /> Aanpassen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
