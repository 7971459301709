import * as React from 'react';
import { cs, formatCents, formatDate, loadManyRecordsPaginated } from '../../../../utils';
import SpectreCardHeader from '../../../../components/spectre/SpectreCardHeader';
import SpectreButton from '../../../../components/spectre/SpectreButton';
import * as _ from 'underscore';
import ModalFilter from './ModalFilter';
import { Form, Formik } from 'formik';
import FormInput from '../../../../components/FormInput';

import { BaseStore, useRxjsStore } from '../../../../effects';

import { GenericDataService } from '../../../../services/GenericDataService';

// region Types

export type PageShopArticleManyQuery = {
  page: number;
  per_page: number;

  search: string;
};

export type PageShopArticleManyContext = {
  loaded: boolean;

  query: PageShopArticleManyQuery;

  records: ModelShopArticle[];
  pagination?: Pagination;
  load_iteration: number;

  modal?: PageShopArticleManyModalContext;
};

export type PageShopArticleManyModalContext = {
  which: 'filter';
  data: { initial: PageShopArticleManyModalFilterValues };
};

export type PageShopArticleManyModalFilterValues = {
  search: string;
};

// endregion

export const PageShopArticleManyCtx = React.createContext<PageShopArticleManyBasicProps>(null);

export default function PageShopArticleMany() {
  const { store, context } = usePageShopArticleManyRxjs();

  if (!store || !context) return null;

  return (
    <PageShopArticleManyCtx.Provider value={{ store, context }}>
      <div className="container">
        <div className="card">
          <div className={cs('card-loading', !context.loaded && 'active')} />

          <SpectreCardHeader
            title="Artikelen"
            subtitle="Overzicht van alle artikelen."
            buttons={[
              <SpectreButton
                icon="fa-plus"
                disabled={true}
                tooltip={{ title: 'Alle artikelen komen uit Beeyond voor dit moment.', position: 'left' }}
              />,
            ]}
          />
          <div className="card-body">
            <Search />
          </div>
          <div className="card-body">
            <Table />
          </div>
        </div>

        <Modals />
      </div>
    </PageShopArticleManyCtx.Provider>
  );
}

// region Components

function Modals() {
  const { context, store } = React.useContext(PageShopArticleManyCtx);
  const mctx = context.modal;

  if (!mctx) return null;

  function closeModalFilter(values?: PageShopArticleManyModalFilterValues) {
    if (_.isEmpty(values)) {
      store.closeModal();
    } else {
      store.closeModal();
      store.search(values);
    }
  }

  return (
    <React.Fragment>
      {mctx.which === 'filter' && <ModalFilter {...mctx.data} closeModal={closeModalFilter} />}
    </React.Fragment>
  );
}

function Search() {
  const { context, store } = React.useContext(PageShopArticleManyCtx);

  const initial = {
    search: context.query.search,
  };
  const query_is_empty = store.queryIsEmpty(['search']);

  function openFilter() {
    store.openModal({ which: 'filter', data: { initial: context.query } });
  }

  return (
    <Formik
      initialValues={initial}
      enableReinitialize={true}
      onSubmit={async (values, fa) => {
        await store.search(values);
        fa.setSubmitting(false);
      }}
      render={fp => (
        <Form style={{ padding: '0 1rem' }}>
          <div className={cs('form-group')}>
            <div className="columns">
              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="button"
                  className="btn btn-error-outline btn-action s-circle tooltip tooltip-right"
                  data-tooltip="Leeg alle zoekvelden."
                  disabled={!context.loaded}
                  onClick={async () => {
                    await store.resetQuery();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="column col-4 col-xs-12  pr-2">
                <label className="form-label">Zoeken op ArtikelNaam</label>
                <FormInput name="search" />
              </div>

              <div className="column" />

              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <SpectreButton
                  icon="fa-filter"
                  classNames={!query_is_empty && ['btn-primary btn-action s-circle mx-1']}
                  onClick={openFilter}
                  disabled={!context.loaded}
                  tooltip={{ title: 'Filter de artikelen', position: 'left' }}
                />
                <button
                  type="submit"
                  className="btn btn-primary-outline btn-action s-circle"
                  disabled={!context.loaded}
                >
                  <i className={cs('fal fa-fw', !context.loaded ? 'fa-spin fa-spinner-third' : 'fa-search')} />
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
}

function Table() {
  const generic = GenericDataService.useEffect('PageContactPersonMany');
  const { context, store } = React.useContext(PageShopArticleManyCtx);

  const getWorkflowById = (id: number) => generic.data.workflows[id];
  const getBuildingById = (id: number) => generic.data.buildings[id];
  const getTenantById = (id: number) => generic.data.tenants[id];

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th className="has-table-shrink-nowrap">BeeyondNummer</th>
          <th className="has-table-shrink-nowrap">Aangemaakt</th>
          <th>ArtikelNaam</th>
          <th className="has-table-shrink-nowrap">Deelnemer</th>
          <th className="has-table-shrink-nowrap  text-right">Prijs</th>
          <th className="has-table-shrink-nowrap  tooltip tooltip-left" data-tooltip="Actief in winkel.">
            <i className="fal fa-store-alt" />
          </th>
          {/*<th className="has-table-shrink-nowrap tooltip tooltip-left" data-tooltip="Actief online.">*/}
          {/*  <i className="fal fa-crown" />*/}
          {/*</th>*/}
        </tr>
      </thead>
      <tbody>
        {context.records.map(record => {
          return (
            <tr key={record.id}>
              <td className="has-table-shrink-nowrap">{record.number_beeyond}</td>
              <td className="has-table-shrink-nowrap">{formatDate(record.created_at, 'L LT')}</td>
              <td>{record.name}</td>
              <td className="has-table-shrink-nowrap">
                {record.tenant.id} &ndash; {record.tenant.name}
              </td>
              <td className="has-table-shrink-nowrap  text-right">{formatCents(record.inclusive)}</td>
              <td className="has-table-shrink-nowrap">{record.is_active_store ? 'Ja' : 'Nee'}</td>
              {/*<td>{record.is_active_online ? 'Ja' : 'Nee'}</td>*/}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

// endregion
// region Store

function emptyQuery() {
  return {
    page: 0,
    per_page: 50,
    search: '',
  };
}

class PageShopArticleManyStore extends BaseStore<PageShopArticleManyContext> {
  setup(): PageShopArticleManyContext {
    return {
      loaded: false,
      query: emptyQuery(),
      records: [],
      pagination: null,
      load_iteration: 0,
      modal: null,
    };
  }

  async load(partial: boolean = true) {
    this.next(draft => {
      draft.loaded = false;
    });

    const result = await loadManyRecordsPaginated('/core/shop/article/many', {
      ...this.current().query,
    });

    this.next(draft => {
      draft.loaded = true;

      draft.pagination = result.pagination;
      draft.records = result.records;
      draft.load_iteration += 1;
    });
  }

  async search(values: Partial<PageShopArticleManyModalFilterValues>) {
    this.next(draft => {
      _.keys(draft.query).forEach(key => {
        const value = values[key];
        if (value !== undefined) {
          draft.query[key] = value;
        }
      });
    });

    await this.load();
  }

  async resetQuery() {
    this.next(draft => {
      draft.query = emptyQuery();
    });

    await this.load();
  }

  queryIsEmpty(extra_ignore?: string[]) {
    const ignore = _.union(['page', 'per_page'], extra_ignore);
    const empty = emptyQuery();

    return _.pairs(this.current().query)
      .filter(([k, v]) => !_.contains(ignore, k))
      .every(([k, v]) => _.isEqual(v, empty[k]));
  }

  // region Utils

  setPage = async (page: number) => {
    this.next(draft => {
      draft.query.page = page;
    });
    await this.load();
  };

  openModal(ctx: PageShopArticleManyModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }

  closeModal = ({ reload }: { reload: boolean } = { reload: false }) => {
    this.next(draft => {
      draft.modal = null;
    });

    if (reload) {
      setTimeout(() => {
        this.load();
      }, 1);
    }
  };

  // endregion
}

export type PageShopArticleManyBasicProps = {
  store: PageShopArticleManyStore;
  context: PageShopArticleManyContext;
};

export function usePageShopArticleManyRxjs(): PageShopArticleManyBasicProps {
  const { store, context } = useRxjsStore<PageShopArticleManyContext, PageShopArticleManyStore>(
    PageShopArticleManyStore,
    null,
    () => {
      store.load(false);
    },
  );

  return { store, context };
}

// endregion
