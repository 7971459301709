import PageDashboard from '../pages/PageDashboard';
import { FunctionComponent } from 'react';
import { LocationState } from '../services/LocationService';
import { ProfileState } from '../services/ProfileService';
import VacancyStructure from '../pages/vacancy/VacancyStructure';
import DmzStructure from '../pages/dmz/DmzStructure';
import ShopStructure from '../pages/shop/ShopStructure';
import CashStructure from '../pages/cash/CashStructure';
import ConnectStructure from '../pages/connect/ConnectStructure';
import ContactStructure from '../pages/contact/ContactStructure';
import TenantStructure from '../pages/tenant/TenantStructure';
import ContentStructure from '../pages/content/ContentStructure';
import DeviceStructure from '../pages/device/DeviceStructure';
import FinanceStructure from '../pages/finance/FinanceStructure';
import MarketingStructure from '../pages/marketing/MarketingStructure';
import PlanStructure from '../pages/plan/PlanStructure';
import OrderStructure from '../pages/order/OrderStructure';
import DocStructure from '../pages/doc/DocStructure';
import GenericStructure from '../pages/generic/GenericStructure';
import DisplayStructure from '../pages/display/DisplayStructure';

export interface AppStructureItem {
  pathname: string;
  title: string;
  icon: string;
  component: FunctionComponent<any>;

  special?: (profile: ProfileState) => boolean;
  is_global?: boolean;
  rights?: string[];
  parent?: (search: SearchParams) => LinkParams;
}

export interface SectionItem {
  title: string;
  icon: string;

  items: string[];
}

export interface MenuItem {
  title: string;
  icon: string;
  isProfile?: boolean;

  sections: SectionItem[];
}

const structure: AppStructureItem[] = [
  ...CashStructure,
  ...ConnectStructure,
  ...ContactStructure,
  ...ContentStructure,
  ...DeviceStructure,
  ...DisplayStructure,
  ...DmzStructure,
  ...DocStructure,
  ...FinanceStructure,
  ...MarketingStructure,
  ...OrderStructure,
  ...PlanStructure,
  ...ShopStructure,
  ...TenantStructure,
  ...VacancyStructure,
  ...GenericStructure,

  {
    pathname: '/',
    title: 'Dashboard',
    icon: 'fa-tachometer',
    component: PageDashboard,
    rights: ['core.authenticate'],
  },
];

const menu: MenuItem[] = [
  // region Cash

  {
    title: 'Kassa',
    icon: 'fa-barcode-read',

    sections: [
      {
        title: 'Kassa',
        icon: 'fa-barcode-read',
        items: ['/cash/dashboard', '/cash/sale', '/cash/parked'],
      },
    ],
  },

  // endregion
  // region Connect

  {
    title: 'Connect',
    icon: 'fa-chart-network',

    sections: [
      {
        title: 'Connect',
        icon: 'fa-chart-network',
        items: ['/connect/dashboard'],
      },
    ],
  },

  // endregion
  // region Contact

  {
    title: 'CRM',
    icon: 'fa-users',

    sections: [
      {
        title: 'Contact',
        icon: 'fa-person-sign',
        items: ['/contact/person/many'],
      },
      {
        title: 'Deelnemers',
        icon: 'fa-person-dolly-empty',
        items: ['/contact/tenant/many'],
      },
      {
        title: 'Vacatures',
        icon: 'fa-address-card',
        items: ['/vacancy/job/many', '/vacancy/application/many', '/vacancy/manage'],
      },
    ],
  },

  // endregion
  // region Content

  {
    title: 'Content',
    icon: 'fa-film-alt',

    sections: [
      {
        title: 'Content',
        icon: 'fa-film-alt',
        items: ['/content/dashboard'],
      },
    ],
  },

  // endregion
  // region Device

  {
    title: 'Device',
    icon: 'fa-hdd',

    sections: [
      {
        title: 'Device',
        icon: 'fa-hdd',
        items: ['/device/dashboard'],
      },
    ],
  },

  // endregion
  // region Finance

  {
    title: 'Finance',
    icon: 'fa-money-bill',

    sections: [
      {
        title: 'Finance',
        icon: 'fa-money-bill',
        items: ['/finance/dashboard', '/finance/settlement/many', '/finance/actions'],
      },
    ],
  },

  // endregion
  // region Marketing

  {
    title: 'Marketing',
    icon: 'fa-bullhorn',

    sections: [
      {
        title: 'Marketing',
        icon: 'fa-bullhorn',
        items: ['/marketing/dashboard'],
      },
    ],
  },

  // endregion
  // region Order

  {
    title: 'Orders',
    icon: 'fa-file-invoice',

    sections: [
      {
        title: 'Loods 5 Orders',
        icon: 'fa-file-invoice',
        items: ['/order/generic/many'],
      },
      {
        title: 'Deelnemers Orders',
        icon: 'fa-file-invoice',
        items: ['/order/tenant/many'],
      },
      {
        title: 'Sponsoring',
        icon: 'fa-gifts',
        items: ['/order/sponsor/many'],
      },
    ],
  },

  // endregion
  // region Plan

  {
    title: 'Plan',
    icon: 'fa-pencil-ruler',

    sections: [
      {
        title: 'Plan',
        icon: 'fa-pencil-ruler',
        items: ['/plan/dashboard'],
      },
    ],
  },

  // endregion
  // region Shop

  {
    title: 'Shop',
    icon: 'fa-newspaper',

    sections: [
      {
        title: 'Shop',
        icon: 'fa-newspaper',
        items: [
          // '/shop/article/many',
          '/shop/sticker/template/many',
        ],
      },
    ],
  },

  // endregion
  // region Docs

  {
    title: 'M / V',
    icon: 'fa-graduation-cap',

    sections: [
      {
        title: 'Missie / Visie',
        icon: 'fa-graduation-cap',
        items: ['/doc/mv/introduction'],
      },
    ],
  },

  // endregion
  // region Docs

  {
    title: 'Documentatie',
    icon: 'fa-books',

    sections: [
      {
        title: 'Applicatie',
        icon: 'fa-browser',
        items: [
          '/doc/core/contact/person',
          '/doc/core/vacancies',
          '/doc/order/tenant/many',
          '/doc/core/order/sponsor',
          '/doc/core/order/payment-refund',
          '/doc/core/order/payment-sale',
          '/doc/core/shop/sticker/template',
        ],
      },
    ],
  },

  // endregion
  // region Profile

  {
    title: 'Profiel',
    icon: 'fa-user',
    isProfile: true,

    sections: [
      {
        title: 'Beheer',
        icon: 'fa-user',

        items: ['/', '/profile/settings', '/dmz/logout-everywhere', '/dmz/logout', '/dmz/takeback'],
      },
    ],
  },

  // endregion
];

const structureByPathname: {
  [name: string]: AppStructureItem;
} = structure.reduce((result, item) => {
  result[item.pathname] = item;
  return result;
}, {} as { [name: string]: AppStructureItem });

function getItem(pathname: string, profile: ProfileState): AppStructureItem | undefined {
  return structure
    .filter(item => isAllowed(item.pathname, profile))
    .find(item => {
      return item.pathname === pathname;
    });
}

type ParentResult = [LinkParams, AppStructureItem];

function getParent(location: LocationState, item: AppStructureItem): ParentResult | [null, null] {
  if (!item || !item.parent) {
    return [null, null];
  }

  const url = item.parent(location.search);
  const parent = Structure.structureByPathname[url.pathName];

  return [url, parent];
}

function getParents(location: LocationState, item: AppStructureItem): ParentResult[] {
  const result: ParentResult[] = [];
  let breakout = 3;
  let current = item;

  while (breakout >= 0) {
    const [url, parent] = getParent(location, current);

    if (!url || !parent) {
      break;
    }

    result.push([url, parent]);
    current = parent;
    breakout -= 1;
  }

  return result.reverse();
}

function isAllowed(pathname: string, profile: ProfileState) {
  const item = structureByPathname[pathname];

  if (!item) {
    return false;
  }

  if (!profile.present && item.rights) {
    return false;
  }

  if (item.rights && !item.rights.every(r => profile.person.rights.indexOf(r) !== -1)) {
    return false;
  }

  if (item.is_global && !profile.person.is_global) {
    return false;
  }

  if (item.special && !item.special(profile)) {
    return false;
  }

  return true;
}

function getMenuForProfile(profile: ProfileState) {
  return menu.reduce((result, menuItem: MenuItem) => {
    const sections = menuItem.sections.reduce((result, sectionItem: SectionItem) => {
      const items: string[] = sectionItem.items.filter(p => isAllowed(p, profile));

      if (items.length) {
        result.push({
          ...sectionItem,
          items,
        });
      }

      return result;
    }, [] as SectionItem[]);

    if (sections.length) {
      result.push({
        ...menuItem,
        sections,
      });
    }

    return result;
  }, [] as MenuItem[]);
}

function getIndexes(menu: MenuItem[], pathname: string): [number, number, number] | [null, null, null] {
  let menuIndex: number | null = null;
  let sectionIndex: number | null = null;
  let itemIndex: number | null = null;

  const item = structureByPathname[pathname];
  const parents = getParents({ pathname, search: {}, hash: '', href: '#internal' }, item);

  // We join the current pathname with all the parents, as the child
  // might not appear in the many, the parent most certainly will.
  const pathnames = [pathname].concat(parents.map(([url, parent]) => url.pathName));

  menu.some((menuItem, mx) => {
    menuItem.sections.some((sectionItem, sx) => {
      pathnames.some(p => {
        itemIndex = sectionItem.items.indexOf(p);

        if (itemIndex !== -1) {
          menuIndex = mx;
          sectionIndex = sx;
        } else {
          itemIndex = null;
        }

        return itemIndex !== null;
      });

      return sectionIndex !== null;
    });

    return menuIndex !== null;
  });

  return [menuIndex, sectionIndex, itemIndex];
}

const Structure = {
  structure,
  structureByPathname,
  menu,

  getMenuForProfile,

  getIndexes,
  getItem,
  isAllowed,
  getParent,
  getParents,
};

export default Structure;
