import * as React from 'react';
import ModalLoading from '../../../components/ModalLoading';
import { formatDate, hasRights, loadOneRecord } from '../../../utils';

type Props = {
  person: ModelContactPerson;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalContactPersonView({ person, closeModal }: Props) {
  const [loaded, setLoaded] = React.useState(false);
  const [lookup, setLookup] = React.useState<{
    created_by?: ModelContactPerson;
    updated_by?: ModelContactPerson;
  }>({});

  React.useEffect(() => {
    (async () => {
      const [created_by, updated_by] = await Promise.all([
        person.created_by_id && (await loadOneRecord('/core/contact/person/one', { person: person.created_by_id })),
        person.updated_by_id && (await loadOneRecord('/core/contact/person/one', { person: person.updated_by_id })),
      ]);

      setLookup({ created_by, updated_by });
      setLoaded(true);
    })();
  }, [person.id]);

  if (!loaded) return <ModalLoading title={`Persoon ${person.first}`} />;

  const getTenantById = (id: number) => window.GLOBAL_GENERIC_DATA.tenants[id];
  const getWorkflowById = (id: number) => window.GLOBAL_GENERIC_DATA.workflows[id];

  return (
    <div className="modal active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Persoon {person.first}</div>
        </div>

        <table className="table">
          <tbody>
            <tr>
              <th>Naam</th>
              <td>
                {person.name}
                <small className="float-right">#{person.id}</small>
              </td>
            </tr>

            <tr>
              <th>Aangemaakt op</th>
              <td>
                {formatDate(person.created_at)}
                {lookup.created_by && <> door {lookup.created_by.name}</>}
              </td>
            </tr>
            <tr>
              <th>Aangepast op</th>
              <td>
                {formatDate(person.updated_at)}
                {lookup.updated_by && <> door {lookup.updated_by.name}</>}
              </td>
            </tr>

            {!!person.number_personnel && (
              <tr>
                <th>Personeelsnummer</th>
                <td>{person.number_personnel}</td>
              </tr>
            )}
            {!!person.number_badge && (
              <tr>
                <th>Badgenummer</th>
                <td>{person.number_badge}</td>
              </tr>
            )}
            <tr>
              <th>Gebruikersmode</th>
              <td>
                {person.mode === 'loods5-plus' && 'Loods 5 Plus - plusgebruiker bij Loods 5.'}
                {person.mode === 'loods5-basic' && 'Loods 5 Basic - medewerker bij Loods 5.'}
                {person.mode === 'external' && 'Extern - geen medewerker bij Loods 5.'}
              </td>
            </tr>
            {hasRights(['contact.person.manage']) && (
              <tr>
                <th>Workflow(s)</th>
                <td>
                  {person.workflows
                    .map(x => getWorkflowById(x))
                    .map(workflow => (
                      <span key={workflow.id} className="mr-1">
                        {workflow.name}
                      </span>
                    ))}
                </td>
              </tr>
            )}
            <tr>
              <th>E-mailadres</th>
              <td>{person.email}</td>
            </tr>
            {hasRights(['contact.person.manage']) && !!person.email_private && (
              <tr>
                <th>Privé e-mailadres</th>
                <td>{person.email_private}</td>
              </tr>
            )}
            {!!person.mobile && (
              <tr>
                <th>Mobielnummer</th>
                <td>{person.mobile}</td>
              </tr>
            )}
            {!!person.phone && (
              <tr>
                <th>Telefoonnummer</th>
                <td>{person.phone}</td>
              </tr>
            )}
            <tr>
              <th>Deelnemertoegang</th>
              <td>
                {person.is_global && 'Mag bij alle deelnemers.'}
                {!person.is_global && 'Mag alleen bij aangegeven deelnemers.'}
              </td>
            </tr>
            {(person.tenant_set.length > 0 || !person.is_global) && (
              <tr>
                <th>Deelnemer(s)</th>
                {person.tenant_set.length !== 0 && (
                  <td>
                    {person.tenant_set
                      .map(x => getTenantById(x))
                      .map(tenant => (
                        <div key={tenant.id} className="mr-1">
                          ({tenant.id}) <strong>{tenant.name}</strong>
                        </div>
                      ))}
                  </td>
                )}
                {person.tenant_set.length === 0 && (
                  <td>
                    <em>Geen deelnemers gekoppeld aan {person.first}.</em>
                  </td>
                )}
              </tr>
            )}
            <tr>
              <th>Vestiging(en)</th>
              {person.building_set.length !== 0 && <td>{person.building_set}</td>}
              {person.building_set.length === 0 && (
                <td>
                  <em>Actief bij alle vestigingen.</em>
                </td>
              )}
            </tr>
          </tbody>
        </table>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                <i className="fal fa-times" /> Sluiten
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
