import * as React from 'react';
import {
  cs,
  formatCentsShort,
  formatEan13Number,
  loadManyRecordsOr,
  loadOneRecord,
  parseCents,
  playSoundNegative,
  playSoundPositive,
} from '../../../utils';
import SpectreCardHeader from '../../../components/spectre/SpectreCardHeader';
import SpectreButton from '../../../components/spectre/SpectreButton';
import { FormikProps } from 'formik';
import { useDebounce } from '../../../effects';
import { PageOrderSponsorLinesCtx, usePageOrderSponsorLinesRxjs } from './PageOrderSponsorLinesStore';
import * as _ from 'underscore';
import ModalSponsorLineUpdate, { ModalLineEditValues } from './ModalSponsorLineUpdate';
import { Link } from '../../../components/Link';
import { nl2br } from '../../../quick';
import Loading from '../../../components/Loading';
import { LocationService } from '../../../services/LocationService';

export default function PageOrderSponsorLines({ id }: { id: number }) {
  const { store, context } = usePageOrderSponsorLinesRxjs(id);
  const scrollable = React.useRef<HTMLDivElement>(null);

  if (!store || !context) return null;

  const lines = [...context.lines].reverse();

  function scrollableToTop() {
    if (scrollable.current) {
      scrollable.current.scrollTo({ top: 0 });
    }
  }

  async function onSubmit() {
    if (await store.submit()) {
      LocationService.navigate('/order/sponsor/many');
    }
  }

  if (!context.sponsor) return <Loading />;

  return (
    <PageOrderSponsorLinesCtx.Provider value={{ store, context }}>
      <div className="container" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div className="card" style={{ height: '100%' }}>
          <div className={cs('card-loading', false && 'active')} />

          <SpectreCardHeader
            title={`Sponsoring ${context.sponsor.subject}`}
            buttons={[
              <SpectreButton
                icon="fa-arrow-left"
                link={['/order/sponsor/many']}
                tooltip={{ title: 'Terug naar het overzicht', position: 'left' }}
              />,
            ]}
          />

          <div className="card-partial">
            <Search scrollableToTop={scrollableToTop} />
          </div>

          {lines.length > 0 && (
            <React.Fragment>
              <div className="card-partial  pb-0">
                <table className="table table-fixed">
                  <Colgroup />
                  <thead>
                    <tr className="bg-primary">
                      <th>&nbsp;</th>
                      <th className="text-right">X</th>
                      <th>Artikel</th>
                      <th className="text-right">Totaal</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                </table>
              </div>

              <div className="card-body  card-body-fixed  py-0" ref={scrollable}>
                <table className="table table-fixed">
                  <Colgroup />
                  <tbody>
                    {lines.map(line => (
                      <TableRow line={line} key={line.id} />
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="card-partial  py-0">
                <table className="table table-fixed">
                  <Colgroup />
                  <tfoot>
                    <tr className="bg-primary">
                      <td>&nbsp;</td>
                      <td style={{ fontSize: '1.4em' }} className="text-right has-table-shrink-nowrap">
                        {context.sponsor.amount} x
                      </td>
                      <td>&nbsp;</td>
                      <td style={{ fontSize: '1.4em' }} className="text-right has-table-shrink-nowrap">
                        <strong>{formatCentsShort(context.sponsor.inclusive)}</strong>
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </React.Fragment>
          )}

          {lines.length === 0 && (
            <div className="card-body  card-body-fixed">
              <div className="empty">
                <div className="empty-icon">
                  <i className="fal fa-shopping-cart  fa-3x" />
                </div>
                <p className="empty-title h5">Geen artikelen</p>
                <p className="empty-subtitle">Er zijn nog geen artikelen toegevoegd...</p>
              </div>
            </div>
          )}

          <div className="card-footer">
            <div className="flex-row flex-space-between">
              <div>
                <Link pathName="/order/sponsor/many" className="btn btn-link">
                  <i className="fal fa-times" /> Annuleren
                </Link>
              </div>
              <div>
                <button type="submit" className="btn btn-primary btn-lg" onClick={onSubmit} disabled={!context.loaded}>
                  <i className="fal fa-save" /> Opslaan &amp; sluiten
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modals />
    </PageOrderSponsorLinesCtx.Provider>
  );
}

function TableRow({ line }: { line: ModelOrderSponsorLine }) {
  const { store, context } = React.useContext(PageOrderSponsorLinesCtx);

  function onMinus() {
    store.lineEdit(line.id, line.amount - 1, line.original_inclusive, line.description);
    if (line.amount - 1 <= 0) {
      playSoundNegative();
    } else {
      playSoundPositive();
    }
  }

  function onPlus() {
    store.lineEdit(line.id, line.amount + 1, line.original_inclusive, line.description);
    playSoundPositive();
  }

  function onDuplicate() {
    store.lineDuplicate(line.id);
    playSoundPositive();
  }

  function onEdit() {
    store.openModal({ which: 'line-update', data: { line } });
  }

  return (
    <tr key={line.id}>
      <td className="has-table-shrink-nowrap">
        <SpectreButton
          icon={line.amount === 1 ? 'fa-trash' : 'fa-minus'}
          onClick={onMinus}
          classNames={['btn-error-outline btn-action s-circle']}
          tooltip={{
            title: line.amount === 1 ? 'Artikel verwijderen.' : 'Aantal met 1 verminderen.',
            position: 'right',
          }}
        />
      </td>
      <td style={{ fontSize: '1.4em' }} className="text-right has-table-shrink-nowrap">
        {line.amount} x
      </td>
      <td>
        <div className="flex-row  pb-1">
          <div className="flex-grow" style={{ fontSize: '1.2em' }}>
            <strong>{line.article.name}</strong>
          </div>
          <div>{formatEan13Number(line.article.number_beeyond)}</div>
        </div>
        <div className="label">
          {line.amount} x {formatCentsShort(line.original_inclusive)}
        </div>
      </td>
      <td style={{ fontSize: '1.4em' }} className="text-right has-table-shrink-nowrap">
        {formatCentsShort(line.original_inclusive * line.amount)}
      </td>
      <td className="has-table-shrink-nowrap">
        <SpectreButton
          icon="fa-clone"
          onClick={onDuplicate}
          tooltip={{ title: 'Regel dupliceren.', position: 'left' }}
          classNames={['btn-primrary-outline btn-action s-circle mr-2']}
        />
        <SpectreButton
          icon="fa-pencil"
          onClick={onEdit}
          tooltip={{ title: 'Regel aanpassen (beschrijving).', position: 'left' }}
          classNames={[line.description.length ? 'btn-dark' : 'btn-dark-outline', 'btn-action s-circle mr-2']}
        />
        <SpectreButton
          icon="fa-plus"
          onClick={onPlus}
          classNames={['btn-success-outline btn-action s-circle']}
          tooltip={{ title: 'Aantal met 1 vermeerderen.', position: 'left' }}
        />
      </td>
    </tr>
  );
}

function Modals() {
  const { store, context } = React.useContext(PageOrderSponsorLinesCtx);
  const mctx = context.modal;

  if (!mctx) return null;

  function onLineEditClose(values?: ModalLineEditValues) {
    if (_.isEmpty(values)) {
      store.closeModal();
    } else {
      const line = context.modal.data.line;
      console.log('edit', values);
      store.lineEdit(line.id, line.amount, parseCents(values.original_inclusive), values.description);
      store.closeModal();
    }
  }

  return (
    <React.Fragment>
      {mctx.which === 'line-update' && <ModalSponsorLineUpdate {...mctx.data} closeModal={onLineEditClose} />}
    </React.Fragment>
  );
}

function Colgroup() {
  return (
    <colgroup>
      <col width="39px" />
      <col width="44px" />
      <col width="auto" />
      <col width="90px" />
      <col width="107px" />
    </colgroup>
  );
}

function Card({ fp }: { fp: FormikProps<any> }) {}

function Search({ scrollableToTop }) {
  const { context, store } = React.useContext(PageOrderSponsorLinesCtx);

  const me_group = React.useRef<HTMLDivElement>(null);
  const me = React.useRef<HTMLInputElement>(null);

  const [value, setValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [position, setPosition] = React.useState({ left: 0, top: 0, width: 0 });
  const [results, setResults] = React.useState({ value: '', loading: false, records: [] });
  const debouncedSearch = useDebounce([value, results.value, results.loading], 500);

  React.useEffect(() => {
    function handleResize() {
      const rect = me.current.getBoundingClientRect();

      setPosition({
        left: rect.left,
        top: rect.top + rect.height,
        width: rect.width,
      });
    }

    function handleClickOutside(event) {
      if (me_group.current && !me_group.current.contains(event.target) && open) {
        setOpen(false);
      }
    }

    function handleKeydown(event) {
      if (context.modal === null && me.current) {
        me.current.focus();
      }
    }

    document.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeydown);

    handleResize();

    return () => {
      document.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [me, open, context.modal === null]);

  React.useEffect(() => {
    if (results.loading === true) {
      // Do not start when still loading, the
      // effect will be re-triggered when the
      // loading has completed.
      return;
    }

    if (value !== results.value) {
      loadResults();
    }
  }, [debouncedSearch]);

  async function loadResults() {
    setResults({
      value: null,
      loading: true,
      records: results.records,
    });

    if (value.length < 3) {
      setOpen(false);
      setResults({
        value,
        records: [],
        loading: false,
      });
    } else if (/^(#|]C1)?[0-9]+$/.exec(value)) {
      const record = await loadOneRecord('/core/shop/article/one_by_number', { number: value });

      if (record) {
        store.lineAdd(record, true);
        playSoundPositive();
        scrollableToTop();
        closeAndReset();
      } else {
        playSoundNegative();
        setOpen(false);
        setResults({
          value,
          records: [],
          loading: false,
        });
      }
    } else {
      const records = await loadManyRecordsOr('/core/shop/article/many_search', { search: value, limit: 20 });

      setOpen(records.length > 0);
      setResults({
        value,
        records,
        loading: false,
      });
    }
  }

  function onKeyUp(event: React.KeyboardEvent) {
    if (event.key === 'Escape') {
      setOpen(false);
    } else if (event.key === 'Enter') {
      event.preventDefault();

      if (open && !results.loading && results.records.length > 0) {
        store.lineAdd(results.records[0], true);
        playSoundPositive();
        scrollableToTop();
        closeAndReset();
      }
    }
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  function closeAndReset() {
    setValue('');
    setOpen(false);
    setResults({
      value: '',
      records: [],
      loading: false,
    });
  }

  return (
    <div className={cs('form-group')} ref={me_group}>
      <label className="form-label is-optional">Zoeken</label>

      <input
        type="text"
        name="article_search_form__value"
        value={value}
        onKeyUp={onKeyUp}
        onChange={onChange}
        ref={me}
        className="form-input input-lg"
        placeholder="Scannen of zoeken..."
      />

      {open && (
        <ul
          className="menu"
          style={{
            position: 'fixed',
            top: position.top,
            left: position.left,
            width: position.width,
            maxWidth: 460,
            maxHeight: 300,
            overflow: 'auto',
          }}
        >
          {results.records.length === 0 && (
            <li className="menu-item">
              <em>Er zijn geen records gevonden...</em>
            </li>
          )}
          {results.records.map((record, rx) => {
            function onClick() {
              store.lineAdd(record, true);
              playSoundPositive();
              scrollableToTop();
              closeAndReset();
            }

            return (
              <li className="menu-item" key={record.id}>
                <a className="flex-row" href="javascript:" onClick={onClick}>
                  <div className="flex-grow mx-2">
                    <strong>{record.name}</strong>
                    <br />
                    <small>{formatEan13Number(record.number_beeyond)}</small>
                  </div>
                  <div className="flex-shrink">{formatCentsShort(record.inclusive)}</div>
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
