import * as React from 'react';
import Render from './Render';

export default function PageDocCoreVacancies() {
  return <Render markdown={markdown} />;
}

const markdown = `

# Inleiding

Welkom bij de handleiding van Loods 5 vacatures. We zoomen hier in
op de werkzaamheden die de HR-medewerker kan uitvoeren. Wanneer je
inlogt als HR-medewerker, vind je de vacature functionaliteiten
links in het menu onder het kopje CRM.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/c61d4468-62a8-4b98-9c00-32e8d25228a0.png)

## Vacature toevoegen

Wanneer je in het menu-item _Vacatures_ zit, heb je rechtsboven
in het scherm een knop waarmee je een nieuwe vacature kunt toevoegen.

![Vacature toevoegen - Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/e370ee32-d131-4029-8676-e89cadd3f4ad.png)

Zodra je hebt geklikt opent er een popup waar je alle gegevens
in kunt invoeren.

![Vacature toevoegen - Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/2caafdb9-d60d-4a48-a9d3-ef753028615d.png)

De volgende velden behoeven wat extra uitleg:

- *Locatie* en *Afdeling*, hoewel optioneel, met name voor de bezoeker
  erg prettig, omdat ze hier op kunnen filteren.
- *Contactpersoon*, in combinatie met het recht HR-meekijker zorgt dit
  veld er voor dat deze geselecteerde persoon (of meerdere) deze vacature
  kunnen inzien.
- *Mail voor de __...__* dit is de mail-template die de applicatie zal
  gebruiken wanneer je de sollicitant een mail wilt versturen. De __...__
  staat voor de status waar de sollicitant zich op dat moment in bevind.
- *Omschrijving*, je kunt hier in Word tekst in plakken, we halen dan
  alle opmaak die niet voor web geschikt is weer weg. Je kunt titels
  maken door *H1*, *H2*, en *H3* aan te klikken. Je kunt ook lijstjes
  maken, anders dan de zin met een - te beginnen, zijn de _echte_ lijstjes
  mooi vormgegeven door TwoPine!

Wanneer je op **Toevoegen** klikt zal de vacature worden toegevoegd
in _Concept_ status. Dit betekend dat je op ieder gewenst moment
er aan verder kunt werken, tot je er tevreden over bent!

![Stap 3](${GLOBAL_APIS_URL}static/doc/core-vacancies/494d41a4-4912-4ece-ae88-5a07b1335c52.png)

Na het toevoegen staat deze direct in het overzicht. Je ziet hier dat
deze nog **Offline** en in **Concept** staat.

![Stap 4](${GLOBAL_APIS_URL}static/doc/core-vacancies/30e30cfe-fd65-48ea-ad03-5b04d7e818ba.png)

## Vacature activeren

Zodra je klaar bent met het schrijven van de vacature kun je deze
activeren. Je doet dit vanuit het overzicht van de vacatures.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/52518872-ef84-44ad-8052-ad77a74a559b.png)

Er opent een popup waarbij je moet bevestigen. Je kunt door middel
van het schruifje de vacature ook _direct online_ plaatsen.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/b5f4e64d-2949-4170-a595-f39f0069d752.png)

Na het activeren zul je zien dat de status van de vacature is
veranderd naar **Online** en **Actief**. Heb je het schuifje
in de vorige stap uitgezet, dan zal er staan **Offline**
en **Actief**.

![Stap 3](${GLOBAL_APIS_URL}static/doc/core-vacancies/e0cd8575-b17e-46c1-a016-f21a4bd0fa93.png)

## Vacature offline/online zetten

Je kunt handmatig de vacature offline en online zetten. Het knopje
veranderd mee met de online-status van de vacature. Nu staat
deze vacature online, het knopje geeft dus aan _Offline halen_.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/801632d1-46e6-4466-9b96-641582c0d8bc.png)

Er opent een popup waarbij je moet bevestigen.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/a4f8bebf-2807-47ff-b75f-7f5489700ebc.png)

Na het bevestigen staat de vacature offline. Dit is ook te zien
in het vacature overzicht.

![Stap 3](${GLOBAL_APIS_URL}static/doc/core-vacancies/b471a50a-0442-49ec-927d-216b08c0f9f5.png)

Vacatures die offline zijn, kun je ook weer _Online zetten_.

![Stap 4](${GLOBAL_APIS_URL}static/doc/core-vacancies/fca9ec63-88f4-4370-bbb3-74651f6fc074.png)

Er opent een popup waarbij je moet bevestigen.

![Stap 5](${GLOBAL_APIS_URL}static/doc/core-vacancies/788f5919-f19d-45bb-962d-70b5d028386d.png)

## Vacature aanpassen

Je kunt een vacature ook aanpassen. Dit kan vanuit het
vacature overzicht.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/8d80c430-86ab-4da6-b063-4923bc6cc731.png)

Bij het aanpassen heb je de mogelijkheid om alle velden
bij de vacture aan te kunnen passen. Wanneer je gegevens aanpast
worden deze direct doorgevoerd, ook als deze online staat!

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/ad76300a-fc10-4f3f-9d38-de8aafededa5.png)

# Sollicitaties

Iedere vacature heeft zijn eigen _Agile board_ waarmee je de
sollicitaties kunt bekijken en kunt beheren.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/a8c22e03-5953-4b69-b3df-6432b06a0f72.png)

Heb je de vacature _net_ toegevoegd, dan vind je een leeg
board.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/0edb4740-5b1b-404f-96e9-45f192e828a5.png)

## Sollicitatie toevoegen

Je hebt altijd zelf de mogelijkheid een sollicitatie in te schieten,
dit werkt dus buiten de website om.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/ac7f56df-a9cd-4d9b-9fff-cb3ec605d055.png)

Je hebt toegang tot alle velden die sollicitanten normaal ook
hebben via de website. Alleen de voornaam en achternaam zijn
verplicht, de rest van de velden zijn optioneel.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/4a461af0-dbdf-482f-8209-690504c3a7d9.png)

De motivatie tekst kan ingevoerd worden wanneer je
_Motivatie beschikbaar_ aanzet.

![Stap 3](${GLOBAL_APIS_URL}static/doc/core-vacancies/b8ffb8c5-0ac3-44f8-8ab8-a74198a1eeb5.png)

Je kunt ook zelf bestanden koppelen aan de sollicitatie. Het
uploaden gebeurd in een aparte popup. Je kunt hier een bestand
naar toe slepen (vanaf de desktop), of klikken
op _Zoek op de computer_.

![Stap 4](${GLOBAL_APIS_URL}static/doc/core-vacancies/2186327d-d981-4853-a5bd-2926d849520b.png)

Dit is een voorbeeld van een volledig ingevoerde sollicitatie.
Je kunt na het _Toevoegen_ de sollicitatie gewoon aanpassen. Na
het toevoegen wordt er **geen automatische mail** verstuurd, dit gebeurd
alleen als de sollicitant zichzelf aanmeld via de website!

![Stap 5](${GLOBAL_APIS_URL}static/doc/core-vacancies/00a7ccf8-ee9f-478e-8b71-cc9ea33d0460.png)

Na het toevoegen zie je de sollicitant als kaartje op
het _Agile board_ verschijnen.

![Stap 6](${GLOBAL_APIS_URL}static/doc/core-vacancies/97aa73f0-cdd0-4f01-b9b0-2d16b60ab9a3.png)

## Sollicitant mailen

Ieder kaartje heeft een losse mail knop. Deze mail is _context_
afhankelijk. De mail die geselecteerd wordt is te bepalen
via het [instellen van de vacature](#vacature-aanpassen).

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/4f8a67fe-eb0c-4754-b77f-5ec109128662.png)

De applicatie zal het onderwerp en de content van de mail
automatisch voor je invullen. Je hebt echter wel de mogelijkheid
om deze verder te personaliseren. Zodra je klikt op _Mail versturen_
zal de applicatie de mail naar de sollicitant versturen.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/5fdadbb8-0e31-4272-b129-4c16cb979350.png)

Voorbeeld van de mail die is verstuurd.

![Stap 3](${GLOBAL_APIS_URL}static/doc/core-vacancies/6c812c66-5029-4a2a-8e37-47d597a3a6ab.png)

## Sollicitant verplaatsen

Vanuit het _Agile board_ kun je gemakkelijk een sollicitant
van status laten veranderen.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/97aa73f0-cdd0-4f01-b9b0-2d16b60ab9a3.png)

Simpelweg klikken en slepen zorgt er voor dat je het
kaartje van de sollicitant naar een andere kolom kunt
verplaatsen. 

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/a587d03e-0051-40a8-94f0-0a3db37aca16.mp4)

## Sollicitant tags toekennen

Als je tags wilt toekennen aan een sollicitant
klik je op het context icoontje en vervolgens op _Tags_.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/952da29f-2b52-45c9-a7dd-6a99a71c31e7.png)

Er opent een popup met daarin een aantal beschikbare tags. Deze
tags zijn _context_ afhankelijk, ze zijn gebaseerd op de status
waar de sollicant zich in bevind. Je kunt een of meerdere tags 
toewijzen aan een sollicitant.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/775baa96-9fae-4785-beef-3f9fbd628a97.png)

Na het klikken op opslaan zul je bij het kaartje op het
_Agile board_ zien welke tags er toegewezen zijn aan
de sollicitant.

![Stap 3](${GLOBAL_APIS_URL}static/doc/core-vacancies/aa5542d3-7e0c-4cbc-9704-c9e6e3e6fc06.png)

Verschuiven we de sollicitatie naar _Onderhandeling_
en openen we opnieuw het tag beheer, dan zul je zien
dat er andere tags getoond worden.

![Stap 4](${GLOBAL_APIS_URL}static/doc/core-vacancies/28868532-6525-45a3-a31c-69a18d92b2c7.png)

Je ziet dat de twee tags die we eerder hebben aangevinkt
hier nu ook bij staan, direct aangevinkt. Zolang je ze
aangevinkt laat staan, zullen ze gekoppeld blijven aan de
sollicitant.

![Stap 5](${GLOBAL_APIS_URL}static/doc/core-vacancies/d54aacbc-3ec8-4182-8e56-1e29275788df.png)

We vinken in dit voorbeeld _Beoordeling_ uit en twee
nieuwe tags aan.

![Stap 6](${GLOBAL_APIS_URL}static/doc/core-vacancies/bf7a23cc-36cf-47a6-b5b3-ad6d54abf2fe.png)

Je ziet nu de twee nieuwe tags en de oude tag gemixt
staan in het kaartje van de sollicitant. De tags blijven
dus gekoppeld aan de sollicitant tot je ze uitvinkt.

![Stap 7](${GLOBAL_APIS_URL}static/doc/core-vacancies/1b99e300-0ea1-4ce7-91ee-156b913226c6.png)

Er is echter één uitzondering! Wanneer je de sollicitant
sleept naar _Aangenomen_ of _Afgewezen_ zetten we automatisch
alle _nog openstaande tags_ uit.

![Stap 8](${GLOBAL_APIS_URL}static/doc/core-vacancies/d1f6def7-6e87-48e8-8642-c07cea98b1d1.png)

## Sollicitant aanpassen

Via het context menu van de sollicitant kun je deze
aanpassen.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/c68dd779-c34d-4b84-8543-783cdf449d5d.png)

Er opent een popup waarbij je alle gegevens van de sollicitant
kunt wijzigen, zo ook bijvoorbeeld de geüploade bestanden.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/b5352b78-d6e8-4699-ac6d-de60c1afd5a3.png)

## Sollicitant bekijken

Wil je alle gegevens van de sollicitant bekijken (zonder
aan te passen), dan kun je een popup openen via het 
context menu. Je kunt ook dubbel-klikken op het kaartje
van de sollicitant.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/4540dd1c-4719-4a53-915b-df104376dfae.png)

Er opent een popup met alle gegevens. Je kunt vanuit hier
ook de geüploade bestanden downloaden.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/d3868273-04b1-4340-95bf-16988c173454.png)

**Let op**, deze functionaliteit is ook beschikbaar voor
de HR-meekijker die je koppelt via het _Contactpersoon_
veld van de vacature.

## Sollicitant linken

Via het context menu is het mogelijk om de sollicitant te
linken aan een andere vacature.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/b7c8b4d1-2901-4ee6-a911-bed2c6b85604.png)

Er opent een popup met een lijst van alle actieve vacatures.
Je kunt nu een of meerdere vacatures selecteren waar je deze
sollicitant aan gekoppeld wilt hebben.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/b6b97d2d-3970-450f-a81b-382c7a0a80a0.png)

Open je nu de vacature waar we de sollicitant aan gekoppeld
hebben, zie je dat deze automatisch in de _Wachtlijst_ terecht
gekomen is.

![Stap 3](${GLOBAL_APIS_URL}static/doc/core-vacancies/da0bb42c-a169-40e5-bc6a-91fbb93cbb30.png)

**Let op**, we _koppelen_ de sollicitant. Het koppelen zorgt
er voor dat de sollicitant actief (en aanwezig) in het systeem
blijft tot hij bij _alle_ gekoppelde vacatures _Aangenomen_ of
_Afgewezen_ is. Het aanpassen van de gegevens van de sollicitant
geldt dus ook voor alle gekoppelde vacatures, pas je het mobiele 
nummer aan, dan veranderd dit overal.

De status en de tags werken wel op vacature niveau, en kun je
dus per vacature laten verschillen.

## Sollicitant afwijzen

Je kunt via het context menu de afwijzing starten.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/6ea7deb6-a07f-495f-963b-883069a3bca7.png)

Er opent een popup waarbij je moet bevestigen.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/b7faea77-c2d0-47a5-a3aa-cb2de17517b8.png)

De sollicitant is nu verplaatst naar _Afgewezen_. Je
kunt uiteraard ook de sollicitant slepen naar deze
kolom.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/375dce07-53cd-4e67-830f-ba0a00e00a5f.png)

## Sollicitant status

De kleuren van de kaartjes veranderen naar mate de 
sollicitant langer gekoppeld is aan de vacature. Na
het verstreiken van 1 week zal het kaartje oranje kleuren,
na het verstreiken van 3 weken zal het kaartje rood kleuren.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/dbfbaf80-109f-4ce5-8e7a-a011491e61b1.png)

# Sollicitanten overzicht

Er is ook een los overzicht waarbij je toegang hebt tot alle
sollicitanten, lopend, aangenomen, en afgewezen.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/7379bac3-0a6f-4f4c-b7b3-e7dacf51a674.png)

Bovenin bevind zich de filter knop waarmee je dit overzicht
gemakkelijk kunt filteren.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/09db6fd5-9db2-4d39-a9b0-2171ace0e65c.png)

Er opent een popup waarbij je een of meerdere filters kunt
instellen. Na het klikken op _Zoeken_ zal het overzicht
de filters toepassen.

![Stap 3](${GLOBAL_APIS_URL}static/doc/core-vacancies/735dfa66-66fc-43c4-94ec-ab3d2675ee89.png)

Je ziet nu dat het filterknopje een actieve kleur gekregen
heeft, dit betekend dat er een filter actief is. In dit voorbeeld
zie je nu alleen de sollicitaties die _Actief_ zijn en waarvan
de doorlooptijd tussen de 1 en 2 weken is.

![Stap 4](${GLOBAL_APIS_URL}static/doc/core-vacancies/6081c2a3-5b29-49fb-86be-4e88032a7bcb.png)

## Sollicitant beheren

Het sollicitanten overzicht heeft ook het context menu wat
je terug vindt bij het kaartje op het _Agile board_. Vanuit
hier kun je ook [bekijken](#sollicitant-bekijken),
[aanpassen](#sollicitant-aanpassen),
[tags toekennen](#sollicitant-tags-toekennen),
[linken](#sollicitant-linken),
en [afwijzen](#sollicitant-afwijzen).

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-vacancies/2ee6b0ed-22d9-4c51-909a-3bda65bf3ee9.png)

Er staat ook een knop naast waarmee je direct de
sollicitant kunt bekijken (zonder het context menu te openen).

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-vacancies/3d413dc1-aecb-49de-9c4f-d0a213c2989c.png)

`;
