import * as React from 'react';
import { http } from '../../../http';

type Props = {
  job: ModelVacancyJob;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalJobDearchive({ job, closeModal }: Props) {
  async function onClick() {
    await http().post('/core/vacancy/job/status', {
      job: job.id,
      status: 'pending',
    });

    closeModal({ reload: true });
  }

  return (
    <div className="modal modal-sm active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">
            Vacature <strong>{job.title}</strong> heropenen
          </div>
        </div>

        <div className="modal-body">
          <p>
            Vacature heropenen. Hiermee heropenen we de vacature. Hij krijgt hierbij de status <em>Concept</em> mee,
            zodat je eerst de vacacture kunt actualiseren. Hierna kun je de vacature gewoon weer activeren.
          </p>
        </div>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                <i className="fal fa-times" /> Annuleren
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-primary" onClick={onClick}>
                <i className="fal fa-sync" /> Heropenen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
