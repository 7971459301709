import * as React from 'react';
import { Field } from 'formik';

type RadioItem = {
  value: string;
  title: string;
};

type FormSelectProps = {
  name: string;
  title_on: string;
  title_off?: string;
};

export default function FormOptionSwitch({ name, title_on, title_off }: FormSelectProps) {
  return (
    <Field
      name={name}
      value="1"
      render={({ field, form }) => {
        const checked = form.values[name];

        return (
          <label className="form-switch">
            <input {...field} type="checkbox" value="1" checked={checked} />
            <i className="form-icon" /> {checked && title_on}
            {!checked && (title_off || title_on)}
          </label>
        );
      }}
    />
  );
}
