import * as React from 'react';
import { http } from '../../../http';

type Props = {
  job: ModelVacancyJob;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalJobActivate({ job, closeModal }: Props) {
  const [online, setOnline] = React.useState(true);

  async function onClick() {
    await http().post('/core/vacancy/job/status', {
      job: job.id,
      status: 'active',
      online_status: online ? 'online' : 'offline',
    });

    closeModal({ reload: true });
  }

  return (
    <div className="modal modal-sm active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">
            Vacature <strong>{job.title}</strong> activeren
          </div>
        </div>

        <div className="modal-body">
          <p>
            Hiermee activeer je de vacature. Vanaf het activeren kunnen er sollicitaties op geplaatst worden en zal de
            vacature, mits deze op <em>Online</em> staat, zichtbaar zijn op de website.
          </p>
          <div className="form-group">
            <label className="form-switch">
              <input type="checkbox" checked={online} onChange={() => setOnline(!online)} />
              <i className="form-icon" /> {online && 'Vacature gaat direct online na het activeren.'}
              {!online && 'Vacature blijft offline na het activeren.'}
            </label>
          </div>
        </div>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                <i className="fal fa-times" /> Annuleren
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-success" onClick={onClick}>
                <i className="fal fa-play" /> Activeren
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
