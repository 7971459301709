/** TODO: Rename to ModalLineUpdate */
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import * as React from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { handleHttpError } from '../../../http';
import { cs, formatCents, formatDate, formatMomentAgo, getTouchedErrors, isWithinMz, parseCents } from '../../../utils';
import FormInput from '../../../components/FormInput';
import { displayError } from '../../../components/FormGroup';
import FormRadio from '../../../components/FormRadio';
import { calculateCashSaleLineInclusive, getCashSaleLineSchema } from './CashSaleLineSchema';
import { LineFormValues, PageDefinition } from './PageState';
import FormSelect from '../../../components/FormSelect';
import moment = require('moment');

type Props = {
  page: PageDefinition;
};

type FormRenderProps = {
  line: ModelShopCartLine;
  close: any;
  fp: FormikProps<LineFormValues>;
};

function FormRender(props: FormRenderProps) {
  const { line, fp, close } = props;
  const errors = getTouchedErrors(fp.touched, fp.errors);

  const configurationError = errors.configuration;
  const depositPercentageError = errors.deposit_percentage;
  const increaseAmountError = errors.manual_increase_value;
  const discountAmountError = errors.manual_discount_value;
  const discountDescriptionError = errors.manual_discount_description;

  function onCloseClick() {
    close(null);
  }

  const calculated = calculateCashSaleLineInclusive(
    line.article.inclusive,
    fp.values.manual_increase_type,
    fp.values.manual_increase_value,
    fp.values.manual_discount_type,
    fp.values.manual_discount_value,
  );
  const has_manual_discount = parseCents(fp.values.manual_discount_value) !== 0;

  const transfer_options = line.article.type.transfer_options;
  const transfer_options_translations = {
    carry: 'Direct meenemen',
    delivery: 'Thuisbezorgen',
    'direct-pickup': 'Direct afhalen',
    pickup: 'Afhalen in winkel',
  };

  const delivery_moments = new Array(20).fill(undefined).map((x, ix) =>
    moment()
      .add(ix + 1, 'week')
      .format('L'),
  );

  const has_errors = Object.keys(fp.errors).length > 0;

  return (
    <Form className="modal-container">
      <div className="modal-header">
        <div className="modal-title h5">Aanpassen {line.article.name}</div>
        <div className="modal-subtitle h6 text-gray">
          Artikelnummer #{line.article.number_beeyond}, aantal {line.amount}x
        </div>
      </div>
      <div className="modal-body">
        <div className="form-horizontal p-0">
          <div className={cs('form-group', errors.amount && 'has-error')}>
            <div className="column col-3 col-sm-12">
              <label className="form-label">Aantal</label>
            </div>
            <div className="column col-9 col-sm-12">
              <FormInput name="amount" />
            </div>
          </div>

          {transfer_options.length > 1 && (
            <div className={cs('form-group', configurationError && 'has-error')}>
              <div className="column col-3 col-sm-12">
                <label className="form-label">Levering</label>
              </div>
              <div className="column col-9 col-sm-12">
                <FormRadio
                  name="transfer"
                  items={transfer_options
                    .filter(v => isWithinMz() || ['delivery', 'direct-pickup', 'pickup'].indexOf(v) !== -1)
                    .map(v => ({ value: v, title: transfer_options_translations[v] }))}
                />
              </div>
            </div>
          )}
          {transfer_options.length === 1 && <input type="hidden" name="transfer" value={transfer_options[0]} />}

          <div className={cs('form-group', configurationError && 'has-error')}>
            <div className="column col-3 col-sm-12">
              <label className="form-label">Configuratie</label>
            </div>
            <div className="column col-9 col-sm-12">
              <FormInput component="textarea" name="configuration" autoFocus />
            </div>
          </div>
          <div className={cs('form-group', increaseAmountError && 'has-error')}>
            <div className="column col-3 col-sm-12">
              <label className="form-label">Opslag</label>
            </div>
            <div className="column col-3 col-sm-12">
              <FormSelect name="manual_increase_type">
                <option value="percentage">Percentage</option>
                <option value="fixed">Vast bedrag</option>
              </FormSelect>
            </div>
            <div className="column col-3 col-sm-12  text-right">
              <FormInput
                name="manual_increase_value"
                classNames={['text-right']}
                onFocus={e => {
                  e.currentTarget.select();
                }}
              />
              {increaseAmountError && <div className="form-input-hint">{displayError(increaseAmountError)}</div>}
            </div>
            <div className="column col-3  ">
              <div className="form-input static  text-right">{formatCents(calculated.increase_inclusive)}</div>
            </div>
          </div>
          <div className={cs('form-group', discountAmountError && 'has-error')}>
            <div className="column col-3 col-sm-12">
              <label className="form-label">Korting</label>
            </div>
            <div className="column col-3 col-sm-12">
              <FormSelect name="manual_discount_type">
                <option value="percentage">Percentage</option>
                <option value="fixed">Vast bedrag</option>
              </FormSelect>
            </div>
            <div className="column col-3 col-sm-12  text-right">
              <FormInput
                name="manual_discount_value"
                classNames={['text-right']}
                onFocus={e => {
                  e.currentTarget.select();
                }}
              />
              {discountAmountError && <div className="form-input-hint">{displayError(discountAmountError)}</div>}
            </div>
            <div className="column col-3  ">
              <div className="form-input static  text-right">{formatCents(calculated.discount_inclusive)}</div>
            </div>
          </div>
          {has_manual_discount && (
            <div className={cs('form-group', discountDescriptionError && 'has-error')}>
              <div className="column col-3 col-sm-12">
                <label className="form-label">Reden van korting</label>
              </div>
              <div className="column col-9 col-sm-12">
                <FormInput component="textarea" name="manual_discount_description" />
                {discountDescriptionError && (
                  <div className="form-input-hint">{displayError(discountDescriptionError)}</div>
                )}
              </div>
            </div>
          )}
          {isWithinMz() && ',delivery,direct-pickup,pickup,'.indexOf(fp.values.transfer) !== -1 && (
            <div className={cs('form-group', depositPercentageError && 'has-error')}>
              <div className="column col-3 col-sm-12">
                <label className="form-label">Aanbetaling</label>
              </div>
              <div className="column" />
              <div className="column col-3 col-sm-12">
                <FormSelect name="deposit_percentage">
                  <option value="10000">100%</option>
                  <option value="5000">50%</option>
                  <option value="2500">25%</option>
                  <option value="2000">20%</option>
                  <option value="1000">10%</option>
                  <option value="0">0%</option>
                </FormSelect>
              </div>
            </div>
          )}
          {!isWithinMz() && <input type="hidden" name="deposit_percentage" value={0} />}

          {',delivery,pickup,'.indexOf(fp.values.transfer) !== -1 && (
            <div className={cs('form-group', (errors.delivery_min_at || errors.delivery_max_at) && 'has-error')}>
              <div className="column col-3 col-sm-12">
                <label className="form-label">Levertijd</label>
              </div>
              <div className="column col-3 col-sm-12">
                <FormSelect name="delivery_min_at">
                  {delivery_moments.map((m, ix) => {
                    return (
                      <option value={m}>
                        {ix + 1} {ix === 0 ? 'week' : 'weken'}
                      </option>
                    );
                  })}
                </FormSelect>
                {errors.delivery_min_at && (
                  <div className="form-input-hint">{displayError(errors.delivery_min_at)}</div>
                )}
                <div className="form-input-hint">
                  vroegst na: {formatMomentAgo(fp.values.delivery_min_at)},<br />
                  datum: {fp.values.delivery_min_at}
                </div>
              </div>
              <div className="column col-3 col-sm-12">
                <FormSelect name="delivery_max_at">
                  {delivery_moments.map((m, ix) => {
                    return (
                      <option value={m}>
                        {ix + 1} {ix === 0 ? 'week' : 'weken'}
                      </option>
                    );
                  })}
                </FormSelect>
                {errors.delivery_max_at && (
                  <div className="form-input-hint">{displayError(errors.delivery_max_at)}</div>
                )}
                <div className="form-input-hint">
                  uiterlijk op: {formatMomentAgo(fp.values.delivery_max_at)},<br />
                  datum: {fp.values.delivery_max_at}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-link  float-left" onClick={onCloseClick}>
          <i className="fal fa-times" /> Annuleren
        </button>

        <button type="submit" className="btn btn-primary  float-right" disabled={props.fp.isSubmitting}>
          <i className={cs('fal', has_errors ? 'fa-engine-warning' : 'fa-save')} /> Opslaan
        </button>
      </div>
    </Form>
  );
}

export default function ModalUpdateArticle(props: Props) {
  const line = props.page.context.modalUpdateLine;
  const close = props.page.store.close;
  const schema = useMemo(() => getCashSaleLineSchema(line.article.inclusive, []), [line.article.inclusive]);

  return (
    <div className="modal active" id="ModalUpdateArticle">
      <div className="modal-overlay" />

      <Formik
        initialValues={{
          amount: line.amount.toString(),
          transfer: line.transfer,
          configuration: line.configuration,
          manual_discount_value: formatCents(line.manual_discount_value, true),
          manual_discount_type: line.manual_discount_type,
          manual_discount_description: line.manual_discount_description,
          manual_increase_value: formatCents(line.manual_increase_value, true),
          manual_increase_type: line.manual_increase_type,
          deposit_percentage: line.deposit_percentage.toString(),
          delivery_min_at: formatDate(line.delivery_min_at || moment().add(2, 'weeks')),
          delivery_max_at: formatDate(line.delivery_max_at || moment().add(3, 'weeks')),
        }}
        validationSchema={schema}
        onSubmit={close}
        render={fp => <FormRender line={line} close={close} fp={fp} />}
      />
    </div>
  );
}
