import * as React from 'react';
import axios from 'axios';

export default function PageDmzExternalView() {
  window.GLOBAL_GENERIC_DATA.auto_reload = true;

  return (
    <div className="container">
      <div className="card">
        <div className="card-header">
          <div className="card-title h5">Externe view</div>
        </div>
        <div className="card-body">
          <br />
        </div>
      </div>
    </div>
  );
}
