import * as React from 'react';
import { useEffect } from 'react';
import { Link } from '../../components/Link';
import { cs, formatCentsShort, formatDate, formatMomentAgo } from '../../utils';
import { BaseStore, useRxjsStore } from '../../effects';
import { http } from '../../http';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import SpectreAutocomplete from '../../components/spectre/SpectreAutocomplete';
import FormSelect from '../../components/FormSelect';
import moment = require('moment');

interface PageCashRefundProps {
  id?: string;
}

type State = {
  loaded: boolean;
  carts: ModelShopCart[];
  parameters: SearchContext;
  pagination: Pagination;
};

type SearchContext = {
  max_age: string;
  state: string;
  buildings: string[];
};

class Store extends BaseStore<State> {
  setup(): State {
    const ggd = window.GLOBAL_GENERIC_DATA;

    return {
      loaded: false,
      carts: [],
      parameters: {
        max_age: moment()
          .add(-1, 'week')
          .format('L'),
        state: 'parked',
        buildings: (ggd.device && ggd.device.group && [ggd.device.group.building_id.toString()]) || [],
      },
      pagination: null,
    };
  }

  async search(search: SearchContext) {
    this.next(draft => {
      draft.parameters = search;
    });

    await this.load();
  }

  async load() {
    this.next(draft => {
      draft.loaded = false;
    });

    const res = await http().post('/sale/cart/many', {
      state: 'parked',
      ...this.current().parameters,
    });

    this.next(draft => {
      draft.loaded = true;
      draft.carts = res.data.records;
      draft.pagination = res.data.pagination;
    });
  }
}

export default function(props: PageCashRefundProps) {
  const { context, store } = useRxjsStore<State, Store>(Store);

  useEffect(() => {
    if (store) {
      store.load();
    }
  }, [store]);

  if (!context) {
    return null;
  }

  async function onLoadClick() {
    await store.load();
  }

  // if (!context.loaded) {
  //   return <Loading />;
  // }

  return (
    <div className="container">
      <div className="card">
        <div className="card-header">
          <div className="flex-row">
            <div className="flex-grow">
              <div className="card-title h5">Geparkeerde orders overnemen</div>
              <div className="card-subtitle text-gray">Hier kan je gemakkelijk een geparkeerde order overnemen.</div>
            </div>
            <div className="flex-shrink">
              <a href="javascript:" className="btn btn-link btn-action s-circle" onClick={onLoadClick}>
                <i className="fal fa-sync" />
              </a>
            </div>
          </div>
        </div>
        <div className="card-body">
          <SearchForm store={store} context={context} />
        </div>
        <div className="card-body">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Code</th>
                <th>Medewerker</th>
                <th>Op</th>
                <th>Klant</th>
                <th className="text-right">Artikelen</th>
                <th className="text-right">Totaal</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {context.carts.map(cart => (
                <tr key={cart.id}>
                  <td>
                    <Link pathName="/cash/sale" pathSearch={{ id: cart.id.toString() }}>
                      <i className="fal fa-hashtag" /> {cart.id}
                    </Link>
                  </td>
                  <td>{cart.person_sale && <>{cart.person_sale.name}</>}</td>
                  <td className="has-table-shrink-nowrap">
                    {formatDate(cart.created_at, 'LL')} om {formatDate(cart.created_at, 'LT')}
                    <br />
                    {formatMomentAgo(moment(), cart.created_at)}
                  </td>
                  <td>
                    {cart.customer && (
                      <>
                        {cart.customer.first} {cart.customer.email}
                        <br />
                        {cart.address_delivery && cart.address_delivery.city}
                      </>
                    )}
                  </td>
                  <td className="text-right has-table-shrink-nowrap">{cart.amount}</td>
                  <td className="text-right has-table-shrink-nowrap">{formatCentsShort(cart.inclusive)}</td>
                  <td className="has-table-shrink-nowrap">
                    <Link pathName="/cash/parked" pathSearch={{ id: cart.id.toString() }}>
                      <i className="fal fa-search" />
                    </Link>
                  </td>
                </tr>
              ))}

              {context.carts.length === 0 && (
                <tr>
                  <td colSpan={7}>
                    <div className="empty">
                      <div className="empty-icon">
                        <i className="fal fa-parking  fa-3x" />
                      </div>
                      <p className="empty-title h5">Niets gevonden</p>
                      <p className="empty-subtitle" style={{ maxWidth: '60%' }}>
                        Er zijn geparkeerde orders gevonden, gebruik de <span className="label">parkeren</span> knop in
                        het verkoop scherm om de order hier naar toe te verplaatsen.
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function SearchForm({ store, context }: { store: Store; context: State }) {
  const ggd = window.GLOBAL_GENERIC_DATA;

  const max_age_moments = new Array(10).fill(undefined).map((x, ix) =>
    moment()
      .subtract(ix + 1, 'week')
      .format('L'),
  );

  return (
    <Formik
      initialValues={context.parameters}
      onSubmit={async (values, fa) => {
        await store.search(values);
        fa.setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        order_id: Yup.number(),
        payment_foreign_number: Yup.string().max(6, 'stringMaxLength'),
      })}
      render={fp => (
        <Form style={{ padding: '0 1rem' }}>
          <div className={cs('form-group')}>
            <div className="columns">
              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="button"
                  className="btn btn-error-outline btn-action s-circle tooltip tooltip-right"
                  data-tooltip="Leeg alle zoekvelden."
                  disabled={!context.loaded}
                  onClick={async () => {
                    fp.setValues(store.setup().parameters);
                    await store.search(store.setup().parameters);
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="column col-2 col-xs-12  pr-2">
                <label className="form-label">Maximale leeftijd</label>
                <FormSelect name="max_age">
                  {max_age_moments.map((m, ix) => {
                    return (
                      <option value={m}>
                        {ix + 1} {ix === 0 ? 'week' : 'weken'} ({m})
                      </option>
                    );
                  })}
                </FormSelect>
              </div>

              <div className="column col-2 col-xs-12  pr-2">
                <label className="form-label">Status(sen)</label>
                <FormSelect name="state">
                  <option value="parked">Geparkeerd </option>
                  <option value="open">Openstaand </option>
                </FormSelect>
              </div>

              <div className="column col-2 col-xs-12  pr-2">
                <label className="form-label">Verstiging(en)</label>
                <SpectreAutocomplete
                  name="buildings"
                  url="/core/plan/building/autocomplete"
                  fp={fp}
                  multiple
                  allowEmptySearch
                />
              </div>

              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="submit"
                  className="btn btn-primary-outline btn-action s-circle"
                  disabled={fp.isSubmitting || !context.loaded}
                >
                  <i className={cs('fal fa-fw', !context.loaded ? 'fa-spin fa-spinner-third' : 'fa-search')} />
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
}
