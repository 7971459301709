import * as React from 'react';
import { Form, Formik } from 'formik';
import { cs, formatCents, getTouchedErrors, parseCents } from '../../../utils';
import FormInput from '../../../components/FormInput';
import * as Yup from 'yup';
import FormSelect from '../../../components/FormSelect';
import { displayError } from '../../../components/FormGroup';

type Props = {
  line: ModelOrderSponsorLine;
  closeModal: (values?: ModalLineEditValues) => void;
};

export type ModalLineEditValues = {
  description: string;
  original_inclusive: string;
};

const schema = Yup.object().shape({
  configuration: Yup.string().max(2500, 'stringMaxLength'),

  inclusive_value: Yup.string().matches(/^-?[,.0-9]+([,.][0-9]{2})?$/, 'invalidNumber'),
});

export default function ModalSponsorLineUpdate({ line, closeModal }: Props) {
  async function onSubmit(values: ModalLineEditValues) {
    closeModal(values);
  }

  const article = line.article;
  const initial = {
    description: line.description,
    original_inclusive: formatCents(line.original_inclusive, true),
  };

  return (
    <Formik
      initialValues={initial}
      validationSchema={schema}
      onSubmit={onSubmit}
      render={fp => {
        const errors = getTouchedErrors(fp.touched, fp.errors);
        const total_inclusive = parseCents(fp.values.original_inclusive) * line.amount;

        return (
          <Form>
            <div className="modal active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">
                    Artikel {article.name} {formatCents(line.article.inclusive)} aanpassen
                  </div>
                </div>

                <div className="modal-body">
                  <div className="form-horizontal p-0">
                    <div className={cs('form-group', errors.original_inclusive && 'has-error')}>
                      <div className="column col-4 col-sm-12">
                        <label className="form-label">Opslag</label>
                      </div>
                      <div className="column col-3 col-sm-12  text-right">
                        <FormInput name="original_inclusive" classNames={['text-right']} />

                        {errors.original_inclusive && (
                          <div className="form-input-hint">{displayError(errors.original_inclusive)}</div>
                        )}
                      </div>
                      {line.amount > 1 && (
                        <div className="column col-5">
                          <div className="form-input static  text-right" style={{ fontFamily: 'consolas' }}>
                            {formatCents(parseCents(fp.values.original_inclusive))} * {line.amount} ={' '}
                            {formatCents(total_inclusive)}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className={cs('form-group')}>
                      <div className="column col-4 col-sm-12">
                        <label className="form-label is-optional">Configuratie / Beschrijving</label>
                      </div>
                      <div className="column">
                        <FormInput component="textarea" name="description" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary" disabled={fp.isSubmitting}>
                        <i className="fal fa-paper-plane" /> Aanpassen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
