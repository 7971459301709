import { formatCentsShort, formatPercentage } from '../../../utils';
import * as React from 'react';
import MenuDropdown from '../../../components/MenuDropdown';

type Props = {
  line: ModelShopCartLine;
  imageSize?: number;
  onDuplicateLine?: (line: ModelShopCartLine) => any;
  onUpdateLineAmountClick?: (line_id: number, amount: number) => any;
  onOpenUpdateArticle?: (line: ModelShopCartLine) => any;
};

export default function ComponentTrLineSmall({
  line,
  imageSize,
  onDuplicateLine,
  onUpdateLineAmountClick,
  onOpenUpdateArticle,
}: Props) {
  const hasPriceMutated = line.original_inclusive * line.amount !== line.inclusive;

  return (
    <tr>
      {onUpdateLineAmountClick && (
        <td className="text-center">
          <a
            href="javascript:"
            className="btn btn-error-outline btn-action s-circle"
            onClick={onUpdateLineAmountClick(line.id, -1)}
          >
            {line.amount > 1 ? <i className="fal fa-minus" /> : <i className="fal fa-times" />}
          </a>
        </td>
      )}
      <td>
        {line.article.is_special_kitchen && <i className="fal fa-fw fa-print" />}
        <strong>{line.article.name}</strong>

        {line.configuration && (
          <div>
            <small className="text-overflow" style={{ display: 'inline-block', width: '95%' }}>
              {line.configuration}
            </small>
          </div>
        )}

        <div>
          <span className="label">
            <span className="mr-1">
              <strong>{line.amount}</strong>
              <i className="fal fa-times ml-1" />
            </span>
            {hasPriceMutated && (
              <span>
                {line.manual_increase_price > 0 && (
                  <span className="ml-1 text-warning  tooltip" data-tooltip="Prijsverhoging.">
                    <i className="fal fa-plus" /> {formatCentsShort(line.manual_increase_price)}
                  </span>
                )}
                {line.discount_price > 0 && (
                  <span className="ml-1 text-success  tooltip" data-tooltip="Berekende korting.">
                    <i className="fal fa-tag" />
                    {line.discount_type === 'fixed'
                      ? formatCentsShort(line.discount_price)
                      : formatPercentage(line.discount_value)}
                  </span>
                )}
                {line.manual_discount_price > 0 && (
                  <span className="ml-1 text-success  tooltip" data-tooltip="Gegeven korting.">
                    <i className="fal fa-tag" /> {formatCentsShort(line.manual_discount_price)}
                  </span>
                )}
                <span className="tooltip" data-tooltip="Totaal bedrag.">
                  <i className="fal fa-equals mx-1" />
                  {formatCentsShort(line.inclusive)}
                </span>
              </span>
            )}

            {!hasPriceMutated && (
              <span className="tooltip" data-tooltip="Totaal bedrag.">
                {formatCentsShort(line.inclusive)}
              </span>
            )}
          </span>
        </div>
      </td>
      {onDuplicateLine && onOpenUpdateArticle && onUpdateLineAmountClick && (
        <td className="text-right has-table-shrink-nowrap">
          <MenuDropdown
            button={<i className="fal fa-ellipsis-h" />}
            classNames={['mr-1']}
            btnClassNames={['btn btn-dark-outline btn-action s-circle']}
            items={[
              <a href="javascript:" onClick={onDuplicateLine(line)}>
                <i className="fal fa-clone" /> dupliceren
              </a>,
              <a href="javascript:" onClick={onOpenUpdateArticle(line)}>
                <i className="fal fa-pencil" /> configureren
              </a>,
            ]}
          />
          <a
            href="javascript:"
            className="btn btn-success-outline btn-action s-circle"
            style={{ marginLeft: 7 }}
            onClick={onUpdateLineAmountClick(line.id, 1)}
          >
            <i className="fal fa-plus" />
          </a>
        </td>
      )}
    </tr>
  );
}
