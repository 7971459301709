import * as React from 'react';
import { useTimeoutEffect } from '../../effects';
import { ProfileService } from '../../services/ProfileService';
import { LocationService } from '../../services/LocationService';

export default function PageDmzLogout() {
  useTimeoutEffect(() => {
    ProfileService.empty();
    LocationService.navigate('/dmz/login');
  }, 500);

  return <div className="container">Goodbye!</div>;
}
