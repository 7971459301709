import * as React from 'react';
import { useTimeoutEffect } from '../../effects';
import { ProfileService } from '../../services/ProfileService';
import { LocationService } from '../../services/LocationService';
import { http } from '../../http';
import axios, { AxiosError } from 'axios';
import { MessageService } from '../../services/MessageService';

export default function PageDmzTakeback() {
  async function takeback() {
    try {
      const result = await http().post('/dmz/security/takeback');

      axios.defaults.headers['x-session'] = result.data.session.token;

      ProfileService.setProfile({
        person: result.data.person,
        session: result.data.session,
        takeback: null,
      });

      MessageService.setMessage(`Je bent heraangemeld als ${result.data.person.name}.`, 'success', 3000);
      LocationService.navigate('/');
    } catch (exc) {
      const err: AxiosError = exc;
    }
  }

  useTimeoutEffect(() => {
    (async () => {
      await takeback();
    })();
  }, 1000);

  return <div className="container">Goodbye!</div>;
}
