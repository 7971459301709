import * as React from 'react';
import { ProfileService, ProfileState } from '../services/ProfileService';
import { LocationService, LocationState } from '../services/LocationService';
import { cs } from '../utils';
import Structure, { AppStructureItem } from '../lib/Structure';
import PageErrorNotFound from '../pages/error/PageErrorNotFound';
import { http } from '../http';
import { GenericDataService } from '../services/GenericDataService';

function getItem(location: LocationState, profile: ProfileState): AppStructureItem {
  const item = Structure.getItem(location.pathname, profile);

  if (!item) {
    if (!profile.present) {
      return Structure.structureByPathname['/dmz/login'];
    }
    return {
      pathname: location.pathname,
      title: 'Pagina niet gevonden',
      icon: 'fa-times',
      component: PageErrorNotFound,
    };
  }

  return item;
}

export default function AppRouter() {
  const profile = ProfileService.useEffect('AppRouter');
  const location = LocationService.useEffect('AppRouter');
  const generic = GenericDataService.useEffect('AppRouter');

  const item = getItem(location, profile);
  const Component = item.component as any;

  console.log(`AppRouter(${location.pathname}) => ${Component.name}`);

  React.useEffect(() => {
    async function check() {
      const version = localStorage.getItem('LOODS5_APPS_VERSION');
      const req = await http().get('/core/version');
      const newVersion = req.data;

      if (version === null) {
        localStorage.setItem('LOODS5_APPS_VERSION', newVersion);
        LocationService.newVersion(newVersion, newVersion);
      } else if (version !== newVersion) {
        localStorage.setItem('LOODS5_APPS_VERSION', newVersion);
        LocationService.newVersion(newVersion, version);
      }
    }

    check();

    const interval = setInterval(check, 60000);

    return () => clearInterval(interval);
  }, [location.pathname]);

  React.useEffect(() => {
    if (!generic.loaded) {
      GenericDataService.load();
    }
  }, [generic.loaded]);

  const componentClassname = Component.getAppRouterClassname && Component.getAppRouterClassname();

  return (
    <ErrorCatcher>
      <div className={cs('AppRouter', componentClassname)}>{generic.loaded && <Component {...location.search} />}</div>
    </ErrorCatcher>
  );
}

class ErrorCatcher extends React.Component<{}, { hasError: boolean; error: any }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.

    return {
      hasError: true,
      error: {
        name: error.name,
        message: error.message,
        stack: error.stack,
      },
    };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={cs('AppRouter')}>
          {this.state.error && (
            <div className="card bg-error" style={{ minHeight: '100%' }}>
              <div className="card-header">
                <h5 className="card-title">FOUTMELDING: {this.state.error.name}</h5>
                <div className="card-subtitle text-gray">{this.state.error.message}</div>
              </div>
              <div className="card-body">
                <p>
                  <strong>
                    Maak een screenshot en stuur deze naar het ShopManager team. Je kunt hierna het scherm verversen
                    (knop rechtsbovenin).
                  </strong>
                </p>

                <hr />

                <pre>{this.state.error.stack}</pre>
              </div>
            </div>
          )}
          {!this.state.error && (
            <div className="card bg-error" style={{ minHeight: '100%' }}>
              <div className="card-header">
                <h5 className="card-title">FOUTMELDING: ___onbekende fout____</h5>
              </div>
              <div className="card-body">
                <p>LET OP: er is een onbekende fout opgetreden. Neem contact op met het ShopManager team.</p>
              </div>
            </div>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}
