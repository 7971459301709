import * as React from 'react';
import { cs } from '../../utils';
import { Field, FormikProps } from 'formik';
import { http } from '../../http';

type Props = {
  name: string;
  folder?: string;
  fp: FormikProps<any>;
};

export default function SpectreContentResource(props: Props) {
  const me = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const [resource, setResource] = React.useState<ModelContentResource>(null);

  const value = props.fp.values[props.name];

  React.useEffect(() => {
    if (value) {
      (async () => {
        const res = await http().post('/core/content/resource/one', { resource: value });
        setResource(res.data);
      })();
    } else if (resource) {
      setResource(null);
    }
  }, [value]);

  return (
    <div ref={me}>
      <div className={cs('has-icon-right')}>
        <Field name={props.name} type="hidden" />
        {!value && (
          <div className="form-input">
            <em>Nog geen bestand geselecteerd.</em>
          </div>
        )}
        {value && !resource && (
          <div className="form-input">
            <em>We zijn nog even aan het laden, ogenblikje...</em>
          </div>
        )}
        {resource && (
          <div className="form-input">
            <div className="chip">
              <a href={resource.url} target="_blank" className="flex-grow">
                {resource.original_filename}
              </a>

              <a
                href="javascript:"
                className="btn btn-clear"
                aria-label="Close"
                role="button"
                onClick={() => props.fp.setFieldValue(props.name, '')}
              />
            </div>
          </div>
        )}
        <a href="javascript:" className="form-icon" onClick={() => !open && setOpen(true)}>
          <i className="fal fa-upload" />
        </a>
      </div>
      {open && (
        <ContentResource
          value={value}
          folder={props.folder}
          close={() => setOpen(false)}
          pick={value => {
            props.fp.setFieldValue(props.name, value);
            setOpen(false);
          }}
        />
      )}
    </div>
  );
}

function ContentResource({ value, folder, close, pick }) {
  const [dragOver, setDragOver] = React.useState(false);
  const [fileUpload, setFileUpload] = React.useState<File>(null);
  const [uploading, setUploading] = React.useState(false);
  const [progress, setProgress] = React.useState({ loaded: 0, total: 0 });

  function onDragEnter(evt: React.DragEvent<HTMLDivElement>) {
    setDragOver(true);
  }
  function onDragLeave(evt: React.DragEvent<HTMLDivElement>) {
    setDragOver(false);
  }
  function onDrop(evt: React.DragEvent<HTMLDivElement>) {
    setDragOver(false);
    if (evt.dataTransfer.files.length === 1) {
      setFileUpload(evt.dataTransfer.files[0]);
    }
  }
  function onSelect(evt: React.ChangeEvent<HTMLInputElement>) {
    if (evt.target.files.length === 1) {
      setFileUpload(evt.target.files[0]);
    }
  }

  async function uploadFileToServer() {
    setUploading(true);

    const data: FormData = new FormData();
    if (folder) {
      data.append('folder', folder);
    }
    data.append('stream', fileUpload);

    try {
      const res = await http().post('/core/content/resource/create', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: evt => {
          console.log(evt);
          setProgress({ loaded: evt.loaded, total: evt.total });
        },
      });

      pick(res.data.id.toString());
    } catch (exc) {
      console.error(exc);

      setFileUpload(null);
      setUploading(false);
    }
  }

  return (
    <div
      style={{
        position: 'fixed',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,

        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      onClick={e => e.currentTarget === e.target && !uploading && close()}
    >
      <div className="card" onDragEnter={onDragEnter} style={{ width: 420, marginTop: 60 }}>
        {!uploading && (
          <div className="card-body">
            <div className="empty">
              <div className="empty-icon">
                <i className="fal fa-upload  fa-3x" />
              </div>
              <p className="empty-title h5">{fileUpload ? fileUpload.name : 'Selecteer een bestand'}</p>
              {!fileUpload && (
                <p className="empty-subtitle">
                  Sleep een bestand en laat hem hier vallen, of gebruik de onderstaande knop om een bestande te zoeken
                  op de computer.
                </p>
              )}
              {fileUpload && (
                <p className="empty-subtitle">
                  Start met uploaden naar de server door hieronder te klikken. Je kunt ook een ander bestande selecteren
                  door deze hier opnieuw te laten vallen, of de ondestaande knop gebruiken om een ander bestand op de
                  computer te zoeken.
                </p>
              )}
              <div className="empty-action">
                <label className="btn btn-link">
                  <input type="file" onChange={onSelect} style={{ display: 'none' }} />
                  <i className="fal fa-search" /> Zoek op de computer
                </label>
              </div>
            </div>
          </div>
        )}
        {uploading && (
          <div className="card-body">
            <div className="empty">
              <div className="empty-icon">
                <i className="fal fa-cloud-upload  fa-3x" />
              </div>
              <p className="empty-title h5">{fileUpload.name}</p>
              <div className="empty-subtitle">
                <div>We zijn aan het uploaden, ogenblikje geduld alstjeblieft.</div>
                <div>
                  {progress.total === 0 && <progress className="progress" max="100" />}
                  {progress.total !== 0 && (
                    <progress className="progress" value={progress.loaded} max={progress.total} />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="card-footer">
          <div className="flex-row flex-space-between">
            <div>
              {!uploading && (
                <button type="button" className="btn btn-link" onClick={() => close()}>
                  <i className="fal fa-times" /> Sluiten
                </button>
              )}
            </div>
            <div>
              {fileUpload && !uploading && (
                <button type="button" className="btn btn-primary" onClick={() => uploadFileToServer()}>
                  <i className="fal fa-paper-plane" /> Uploaden naar server
                </button>
              )}
            </div>
          </div>
        </div>

        {dragOver && (
          <div
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div>
              <i className="fal fa-check  fa-4x  text-success" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
