import * as React from 'react';

export default function ModalLoading({ title }: { title: string }) {
  return (
    <div className="modal active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">{title}</div>
        </div>

        <div className="modal-body">
          <div className="text-center">
            <p>
              <i className="fal fa-spin fa-spinner-third" /> Even laden hoor!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
