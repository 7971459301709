import * as React from 'react';
import { useEffect } from 'react';
import { http } from '../../../http';
import { createPortal } from 'react-dom';

export default function PageGenericMailOne({ id }: { id: string }) {
  const [mail, setMail] = React.useState<ModelGenericMailSent>(null);

  useEffect(() => {
    if (!id) return;

    (async () => {
      const res = await http().post('/core/generic/mail/one', { mail: id });
      setMail(res.data);
    })();
  }, [id]);

  return (
    <div className="container">
      <div className="columns">
        <div className="column col-12">
          <div className="card">
            {!mail && (
              <React.Fragment>
                <div className="card-header">
                  <div className="card-title h5">Mail...</div>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <p>
                      <i className="fal fa-spin fa-spinner-third" /> Even laden hoor!
                    </p>
                  </div>
                </div>
              </React.Fragment>
            )}
            {mail && (
              <React.Fragment>
                <div className="card-header">
                  <div className="card-title h5">Mail naar {mail.to_name}</div>
                </div>
                <div className="card-body">
                  <table className="table">
                    <tr>
                      <th>Onderwerp</th>
                      <td>{mail.subject}</td>
                    </tr>
                    <tr>
                      <th>Naar</th>
                      <td>
                        <a href={`mailto:${mail.to_email}`}>
                          {mail.to_name} &lt;{mail.to_email}&gt;
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th>Verstuurd door</th>
                      <td>
                        <a href={`mailto:${mail.from_email}`}>
                          {mail.from_name} &lt;{mail.from_email}&gt;
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th>Antwoordadres</th>
                      <td>
                        <a href={`mailto:${mail.reply_email}`}>&lt;{mail.reply_email}&gt;</a>
                      </td>
                    </tr>
                    <tr>
                      <th>Verstuurd</th>
                      <td>
                        {mail.is_sent_success ? (
                          <span>
                            <i className="fal fa-check text-success" /> aangeboden aan de mailserver van de ontvanger
                          </span>
                        ) : (
                          <span>
                            <i className="fal fa-times text-danger" /> ontvangende mailserver wil deze mail niet
                            accepteren
                          </span>
                        )}
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="card-body">
                  <iframe
                    width="100%"
                    height="800px"
                    frameBorder={0}
                    src={'data:text/html,' + encodeURIComponent(mail.body)}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
