import * as React from 'react';
import { http } from '../../../http';
import { cs, sleep } from '../../../utils';

type Props = {
  purchase: ModelOrderPurchase;
  close: (success?: boolean) => void;
};

export default function ModalOrderDownloadPdf(props: Props) {
  const ggd = window.GLOBAL_GENERIC_DATA;
  const [loading, setLoading] = React.useState(false);
  const [link, setLink] = React.useState(null);
  const paper_printer = ggd.device?.paper_printer;

  async function submit() {
    setLoading(true);

    try {
      const res = await http().post('/core/order/purchase/download_pdf', { purchase: props.purchase.id });

      setLink(res.data.link);
    } catch (exc) {
      console.error(exc);
    }

    setLoading(false);
  }

  async function print() {
    setLoading(true);

    console.info('printing to', paper_printer);

    try {
      await http().post('/core/order/purchase/print_pdf', {
        purchase: props.purchase.id,
        paper_printer: paper_printer.id,
      });
      await sleep(2500);
    } catch (exc) {
      console.error(exc);
    }

    setLoading(false);
  }

  return (
    <div className="modal active" id="modal-id">
      <div className="modal-overlay" />

      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Download de factuur</div>
        </div>

        <div className="modal-body">
          {!link && <p>Klik op genereren om de PDF te genereren en een link te verkrijgen.</p>}
          {link && <p>De PDF van de factuur staat klaar, je kunt nu op downloaden klikken.</p>}
        </div>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-dark-outline" onClick={() => props.close()} disabled={loading}>
                <i className="fal fa-times" /> Annuleren
              </button>
            </div>
            <div>
              {!link && (
                <button type="button" className="btn btn-primary-outline" onClick={submit} disabled={loading}>
                  <i className="fal fa-cog" /> Genereren
                </button>
              )}

              {link && (
                <React.Fragment>
                  <button type="button" className="btn btn-primary-outline" onClick={submit} disabled={loading}>
                    <i className="fal fa-cog" />
                  </button>
                  <a
                    href={link}
                    className={cs('btn', !paper_printer ? 'btn-primary' : 'btn-primary-outline')}
                    target={'_blank'}
                  >
                    <i className="fal fa-download" /> Downloaden
                  </a>
                  {!!paper_printer && (
                    <button className="btn btn-primary" onClick={print} disabled={loading}>
                      <i className="fal fa-download" /> Direct afdrukken
                    </button>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
