import * as React from 'react';
import { useEffect } from 'react';
import { http } from '../../../http';
import { cs } from '../../../utils';

type Props = {
  customer: ModelShopCustomer;
  address: ModelShopCustomerAddress;

  close: () => void;
  closeAndCreate: () => void;
  select: (address: ModelShopCustomerAddress) => void;
};

type State = {
  loading: boolean;
  addresses: ModelShopCustomerAddress[];
};

export default function ModalAddressSelect(props: Props) {
  const [state, setState] = React.useState<State>({
    loading: true,
    addresses: [],
  });

  useEffect(() => {
    (async () => {
      const res = await http().post('/sale/cart/customer_addresses', { customer: props.customer.id });

      setState({ loading: false, addresses: res.data.records });
    })();
  }, []);

  return (
    <div className="modal active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Adressen bekend bij klant</div>
          <div className="h6 text-gray" />
        </div>
        <table className="table  table-hover">
          <tbody>
            {state.addresses.map(address => (
              <tr onClick={() => props.select(address)}>
                <td>
                  t.a.v. {address.name}
                  <br />
                  {address.street} {address.housenumber} {address.extension}
                  <br />
                  {address.zipcode} {address.city}
                  {address.has_company && (
                    <div className="mt-1">
                      {address.company_name}
                      <br />
                      {address.company_vat && <span>BTW: {address.company_vat}</span>}
                    </div>
                  )}
                </td>
                <td className="has-table-shrink-nowrap">
                  <i className="fal fa-fw fa-hand-point-right" />
                </td>
              </tr>
            ))}
            <tr onClick={() => props.closeAndCreate()}>
              <td colSpan={2} className="text-center">
                Het adres staat er niet tussen, <a href="javascript:">klik hier</a> om een nieuw adres aan te maken.
              </td>
            </tr>
          </tbody>
        </table>
        <div className="modal-footer">
          <button className="btn btn-link  float-left" onClick={props.close}>
            <i className="fal fa-times" /> Annuleren
          </button>
        </div>
      </div>
    </div>
  );
}
