import * as React from 'react';
import { cs } from '../utils';

type Props = {
  steps: {
    icon: string;
    label: React.ReactElement<any> | string;
  }[];
  step: number;
};

export default function Steps(props: Props) {
  return (
    <div>
      {props.steps.map((step, ix) => (
        <div key={ix}>
          <i
            className={cs(
              'fal fa-fw',
              props.step > ix ? 'fa-check-circle' : props.step === ix ? 'fa-spinner fa-spin' : 'fa-circle',
            )}
          />{' '}
          <i className={cs('fal fa-fw', step.icon)} /> <span>{step.label}</span>
        </div>
      ))}
    </div>
  );
}
