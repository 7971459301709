import * as React from 'react';
import { PageDefinition } from './PageState';
import { formatCents } from '../../../utils';

type Props = {
  page: PageDefinition;
};

export default function ModalFinish(props: Props) {
  const { store, context } = props.page;
  const [loading, setLoading] = React.useState(false);

  function close() {
    props.page.store.closeFinish();
  }

  async function submit() {
    setLoading(true);
    try {
      await store.closeCartWithoutPayment();
    } catch (exc) {
      console.error(exc);
      setLoading(false);
    }
  }

  return (
    <div className="modal active">
      <div className="modal-overlay" />
      <div className="modal-container" style={{ maxHeight: '90vh' }}>
        <div className="modal-header">
          <div className="modal-title h5">Order bevestigen</div>
        </div>

        <div className="modal-body">
          <p>
            Je gaat nu order <strong>#{context.cart.id}</strong> sluiten, je hebt het e-mailadres van{' '}
            {context.customer.first} ingevuld, {context.customer.email}, de klant krijgt automatisch een e-mail met
            daarin de bevestiging dat de order is geplaatst. Je kunt vervolgens deze order terugvinden onder het tabblad{' '}
            <em>nieuw</em> in het orders overzicht.
          </p>
          <p>
            Vanuit het order overzicht kun je de klant een betalingsverzoek versturen. De klant kan uiteraard ook
            betalen bij het magazijn als de order afgehaald wordt.
          </p>
          <p>
            De klant moet in totaal <strong>{formatCents(context.cart.inclusive)}</strong> betalen.
          </p>
        </div>

        <div className="modal-footer">
          <button type="button" className="btn btn-link  float-left" onClick={close} disabled={loading}>
            <i className="fal fa-times" /> Annuleren
          </button>
          <button type="button" className="btn btn-primary float-right" onClick={submit} disabled={loading}>
            <i className="fal fa-paper-plane" /> Bevestigen &amp; versturen
          </button>
        </div>
      </div>
    </div>
  );
}
