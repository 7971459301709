import { BehaviorSubject } from 'rxjs';
import { useEffect, useState } from 'react';
import { distinctUntilChanged, skip, tap } from 'rxjs/operators';
import { jsonEquals } from '../utils';

export default function Service<State>(service_name: string, initialState: () => State) {
  const subject = new BehaviorSubject<State>(initialState());

  return {
    subject,

    current: () => subject.getValue(),
    next: (state: State) => setTimeout(() => subject.next(state), 1),

    useEffect: (who_am_i: string, doTab: boolean = true) => {
      const [state, setState] = useState<State>(subject.getValue());

      useEffect(() => {
        const subscription = subject
          .pipe(
            skip(1),
            distinctUntilChanged(jsonEquals),
            tap((...args) => {
              if (doTab) {
                console.log(`${service_name}(${who_am_i}):`, ...args);
              }
            }),
          )
          .subscribe((next: State) => {
            setState(next);
          });

        return () => {
          subscription.unsubscribe();
        };
      }, []);

      return state;
    },
  };
}
