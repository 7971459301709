import * as React from 'react';
import { useEffect } from 'react';
import { nl2br } from '../../../quick';
import { http } from '../../../http';
import { formatDate, formatMomentAgo, loadManyRecordsOr, loadOneRecord, parseMoment } from '../../../utils';

type Props = {
  application: ModelVacancyApplication;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalJobApplicationView({ application, closeModal }: Props) {
  const applicant = application.applicant;
  const [lookup, setLookup] = React.useState<{
    job: ModelVacancyJob;
    source?: ModelVacancyLocation;
    location?: ModelVacancyLocation;
    department?: ModelVacancyDepartment;
  }>({ job: null, source: null, location: null, department: null });

  const { job, source, location, department } = lookup;

  useEffect(() => {
    application.job_id &&
      (async () => {
        const job = (await http().post('/core/vacancy/job/one', { job: application.job_id })).data;

        const [source, department, location] = await Promise.all([
          applicant.source_id && (await loadOneRecord('/core/vacancy/source/one', { source: applicant.source_id })),
          job.department_id && (await loadOneRecord('/core/vacancy/department/one', { department: job.department_id })),
          job.location_id && (await loadOneRecord('/core/vacancy/location/one', { location: job.location_id })),
        ]);

        setLookup({ job, source, department, location });
      })();
  }, [application.job_id]);

  return (
    <div className="modal active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Sollicitant {applicant.first}</div>
        </div>

        <div className="modal-body">
          <table className="table">
            <tbody>
              {job && (
                <tr>
                  <th className="has-table-shrink-nowrap">Vacature</th>
                  <td>
                    <strong>{job.title}</strong>
                    <br />
                    {department && department.name}
                    {department && location && ' in '}
                    {location && location.name}
                  </td>
                </tr>
              )}
              <tr>
                <th className="has-table-shrink-nowrap">Naam</th>
                <td>
                  {applicant.name}
                  <small className="float-right">#{applicant.id}</small>
                </td>
              </tr>
              <tr>
                <th className="has-table-shrink-nowrap">Aangemaakt op</th>
                <td>{formatDate(application.created_at)}</td>
              </tr>
              {application.closed_at && (
                <tr>
                  <th className="has-table-shrink-nowrap">Gesloten op</th>
                  <td>{formatDate(application.closed_at)}</td>
                </tr>
              )}
              <tr>
                <th className="has-table-shrink-nowrap">Doorlooptijd</th>
                <td>{formatMomentAgo(application.created_at, parseMoment(application.closed_at))}</td>
              </tr>
              <tr>
                <th className="has-table-shrink-nowrap">E-mailadres</th>
                <td>
                  <a href={`mailto:${applicant.email}`}>{applicant.email}</a>
                </td>
              </tr>
              {!!applicant.mobile && (
                <tr>
                  <th className="has-table-shrink-nowrap">Mobielnummer</th>
                  <td>{applicant.mobile}</td>
                </tr>
              )}
              {!!applicant.phone && (
                <tr>
                  <th className="has-table-shrink-nowrap">Telefoonnummer</th>
                  <td>{applicant.phone}</td>
                </tr>
              )}

              {application.tags.length !== 0 && (
                <tr>
                  <th className="has-table-shrink-nowrap">Tags</th>
                  <td>
                    {application.tags.map(application_tag => {
                      let tag = application_tag.tag;
                      return (
                        <div>
                          <span className="chip">
                            <figure className="avatar avatar-xs" style={{ backgroundColor: `#${tag.color}` }} />{' '}
                            {tag.name}
                          </span>
                          {!application_tag.end && <span>{' '}van {formatDate(application_tag.start)} tot heden</span>}
                          {application_tag.end && (
                            <span>
                              {' '}van
                              {formatDate(application_tag.start)} t/m
                              {formatDate(application_tag.end)}
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </td>
                </tr>
              )}

              <tr>
                <th className="has-table-shrink-nowrap">Uren beschikbaar per week</th>
                <td>{applicant.hours_available} uur</td>
              </tr>
              <tr>
                <th className="has-table-shrink-nowrap">Opzegtermijn</th>
                <td>{applicant.months_cancellation_period} maanden</td>
              </tr>
              <tr>
                <th className="has-table-shrink-nowrap">Weekend beschikbaarheid</th>
                <td>
                  {applicant.is_available_weekends
                    ? 'Is beschikbaar in het weekend.'
                    : 'Is niet beschikbaar in het weekend.'}
                </td>
              </tr>
              <tr>
                <th className="has-table-shrink-nowrap">Motivatie</th>
                <td>{!!applicant.motivation ? 'De motivatie staat hieronder.' : 'Er is geen motivatie geschreven.'}</td>
              </tr>
              {!!applicant.motivation && (
                <tr>
                  <td colSpan={2}>{nl2br(applicant.motivation)}</td>
                </tr>
              )}
              {source && (
                <tr>
                  <th className="has-table-shrink-nowrap">Bron</th>
                  <td>{source.name}</td>
                </tr>
              )}
              {applicant.avatar_resource_id && (
                <tr>
                  <th className="has-table-shrink-nowrap">Profiel-foto upload</th>
                  <td>
                    <DownloadLink resource_id={applicant.avatar_resource_id} />
                  </td>
                </tr>
              )}
              {applicant.resume_resource_id && (
                <tr>
                  <th className="has-table-shrink-nowrap">C.V. document upload</th>
                  <td>
                    <DownloadLink resource_id={applicant.resume_resource_id} />
                  </td>
                </tr>
              )}
              {applicant.motivation_resource_id && (
                <tr>
                  <th className="has-table-shrink-nowrap">Motivatie-document upload</th>
                  <td>
                    <DownloadLink resource_id={applicant.motivation_resource_id} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                <i className="fal fa-times" /> Sluiten
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DownloadLink({ resource_id }) {
  const [resource, setResource] = React.useState<ModelContentResource | null>(null);

  React.useEffect(() => {
    (async () => {
      setResource((await http().post('/core/content/resource/one', { resource: resource_id })).data);
    })();
  }, [resource_id]);

  if (resource === null) return null;

  return (
    <a href={resource.url} target="_blank">
      <i className="fal fa-fw fa-file" /> {resource.original_filename}
    </a>
  );
}
