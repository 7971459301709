import * as React from 'react';
import { Field } from 'formik';

type RadioItem = {
  value: string;
  title: string;
};

type FormSelectProps = {
  name: string;
  items: RadioItem[];
};

export default function FormCheckbox(props: FormSelectProps) {
  return (
    <div>
      {props.items.map(item => (
        <label className="form-checkbox" key={item.value} data-value={item.value}>
          <Field
            name={props.name}
            render={({ field, form }) => {
              const checked = field.value.includes(item.value);

              return (
                <input
                  {...field}
                  type="checkbox"
                  checked={checked}
                  onChange={() => {
                    if (checked) {
                      form.setFieldValue(props.name, field.value.filter(v => v !== item.value));
                    } else {
                      form.setFieldValue(props.name, [...field.value, item.value]);
                    }
                  }}
                />
              );
            }}
          />
          <i className="form-icon" /> {item.title}
        </label>
      ))}
    </div>
  );
}
