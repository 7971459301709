import { BaseStore, useRxjsStore } from '../../../effects';
import * as React from 'react';
import * as moment from 'moment';
import { calculateVat, loadOneRecord } from '../../../utils';
import { http } from '../../../http';

export type PageOrderSponsorLinesContext = {
  loaded: boolean;

  sponsor_id: number;
  sponsor: ModelOrderSponsor;
  lines: ModelOrderSponsorLine[];

  modal: PageOrderSponsorLinesModalContext;
};

export type PageOrderSponsorLinesValues = {
  started_at: string;
  subject: string;
  description: string;
};

export class PageOrderSponsorLinesStore extends BaseStore<PageOrderSponsorLinesContext> {
  setup(): PageOrderSponsorLinesContext {
    return {
      loaded: false,

      sponsor_id: null,
      sponsor: null,
      lines: [],

      modal: null,
    };
  }

  async load() {
    this.next(draft => {
      draft.loaded = false;
    });

    const sponsor = await loadOneRecord('/core/order/sponsor/one', { sponsor: this.current().sponsor_id });

    this.next(draft => {
      draft.loaded = true;
      draft.sponsor = sponsor;
      draft.lines = [...sponsor.lines];
    });
  }

  async submit(): Promise<boolean> {
    this.next(draft => {
      draft.loaded = false;
    });

    const current = this.current();

    try {
      await http().post('/core/order/sponsor/lines_update', {
        sponsor: current.sponsor.id,
        lines: current.lines.map(line => ({
          line: line.id.toString().indexOf('.') !== -1 ? null : line.id,

          article: line.article_id,

          amount: line.amount,
          original_inclusive: line.original_inclusive,
          description: line.description,
        })),
      });

      await this.load();

      return true;
    } catch (e) {
      this.next(draft => {
        draft.loaded = true;
        console.error(e);
      });

      return false;
    }
  }

  lineAdd(article: ModelShopArticle, should_update: boolean = false) {
    let line_id: number = null;

    this.next(draft => {
      const index = draft.lines.findIndex(x => x.article.id === article.id);

      if (index !== -1 && should_update) {
        const line = draft.lines[index];

        line_id = line.id;

        draft.lines.splice(index, 1);
        draft.lines.push({
          ...line,
          amount: line.amount + 1,
        });
      } else {
        line_id = Math.random();

        draft.lines.push({
          id: line_id,

          created_at: moment().format(),
          updated_at: moment().format(),

          position: 0,

          sponsor_id: draft.sponsor.id,
          tenant_id: article.tenant_id,
          article_id: article.id,

          original_inclusive: article.inclusive,
          original_vat_percentage: article.vat_percentage,

          total_inclusive: article.inclusive,
          total_exclusive: 0,
          total_vat: 0,

          amount: 1,
          description: '',

          article,
        });
      }
    });

    this.calculateLine(line_id);
    this.calculateTotals();
  }

  lineDuplicate(id: number) {
    const line_id = Math.random();

    this.next(draft => {
      const index = draft.lines.findIndex(x => x.id === id);
      const original = draft.lines[index];
      const article = original.article;

      draft.lines.push({
        id: line_id,

        created_at: moment().format(),
        updated_at: moment().format(),

        position: 0,

        sponsor_id: draft.sponsor.id,
        tenant_id: article.tenant_id,
        article_id: article.id,

        original_inclusive: article.inclusive,
        original_vat_percentage: article.vat_percentage,

        total_inclusive: article.inclusive,
        total_exclusive: 0,
        total_vat: 0,

        amount: 1,
        description: '',

        article,
      });
    });

    this.calculateLine(line_id);
    this.calculateTotals();
  }

  lineEdit(id: number, amount: number, original_inclusive: number, description: string) {
    if (amount <= 0) {
      this.lineRemove(id);
    } else {
      this.next(draft => {
        const index = draft.lines.findIndex(x => x.id === id);

        draft.lines[index].amount = amount;
        draft.lines[index].original_inclusive = original_inclusive;
        draft.lines[index].description = description;
      });

      this.calculateLine(id);
    }

    this.calculateTotals();
  }

  lineRemove(id: number) {
    this.next(draft => {
      const index = draft.lines.findIndex(x => x.id === id);

      draft.lines.splice(index, 1);
    });

    this.calculateTotals();
  }

  calculateLine(id: number) {
    this.next(draft => {
      const index = draft.lines.findIndex(x => x.id === id);
      const line = draft.lines[index];

      const inclusive = line.amount * line.original_inclusive;
      const [exclusive, vat] = calculateVat(inclusive, line.original_vat_percentage);

      draft.lines[index].total_inclusive = inclusive;
      draft.lines[index].total_exclusive = exclusive;
      draft.lines[index].total_vat = vat;
    });
  }

  calculateTotals() {
    this.next(draft => {
      draft.sponsor.amount = draft.lines.reduce((t, l) => t + l.amount, 0);
      draft.sponsor.inclusive = draft.lines.reduce((t, l) => t + l.total_inclusive, 0);
    });
  }

  // region Utils

  openModal(ctx: PageOrderSponsorLinesModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }

  closeModal = () => {
    this.next(draft => {
      draft.modal = null;
    });
  };

  // endregion
}

// region Modal Context

export type PageOrderSponsorLinesModalContext = {
  which: 'line-update';
  data: { line: ModelOrderSponsorLine };
};

// endregion
// region Utilities

export type PageOrderSponsorLinesBasicProps = {
  store: PageOrderSponsorLinesStore;
  context: PageOrderSponsorLinesContext;
};

export function usePageOrderSponsorLinesRxjs(sponsor_id: number): PageOrderSponsorLinesBasicProps {
  const { store, context } = useRxjsStore<PageOrderSponsorLinesContext, PageOrderSponsorLinesStore>(
    PageOrderSponsorLinesStore,
    () => {
      return { sponsor_id };
    },
    () => {
      store.load();
    },
  );

  return { store, context };
}

export const PageOrderSponsorLinesCtx = React.createContext<PageOrderSponsorLinesBasicProps>(null);

// endregion
