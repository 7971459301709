import * as React from 'react';
import { http } from '../../../http';
import axios, { AxiosError } from 'axios';
import { MessageService } from '../../../services/MessageService';
import { GenericDataService } from '../../../services/GenericDataService';
import { ProfileService } from '../../../services/ProfileService';
import Steps from '../../../components/Steps';

type Props = {
  onClose: () => void;
};

type State = {
  step: number;
  number_badge: string;
};

enum Action {
  StartValidation = 'start_validation',
  ValidationFailed = 'validation_failed',
  ValidationSuccess = 'validation_success',
}

type Payload =
  | {
      action: Action.StartValidation;
      number_badge;
    }
  | {
      action: 'validation_failed';
    }
  | {
      action: 'validation_success';
    };

function reducer(state: State, payload: Payload): State {
  if (payload.action === Action.StartValidation) {
    return { step: 1, number_badge: payload.number_badge };
  }
  if (payload.action === Action.ValidationSuccess) {
    return { step: 2, number_badge: state.number_badge };
  }
  if (payload.action === Action.ValidationFailed) {
    MessageService.setMessage('Badge-nummer onbekend, probeer het opnieuw.', 'error');

    return { step: 0, number_badge: '' };
  }

  return state;
}

export default function PageDmzLoginModalDroplet(props: Props) {
  const [state, dispatch] = React.useReducer<React.Reducer<State, Payload>>(reducer, {
    step: 0,
    number_badge: '',
  });

  async function loginWithBadge(number_badge): Promise<boolean> {
    try {
      const res = await http().post('/dmz/security/authentication_with_badge', { number_badge });

      dispatch({ action: Action.ValidationSuccess });

      axios.defaults.headers['x-session'] = res.data.session.token;

      await GenericDataService.load();

      ProfileService.setProfile({
        person: res.data.person,
        session: res.data.session,
      });

      props.onClose();
    } catch (exc) {
      const err: AxiosError = exc;

      dispatch({ action: Action.ValidationFailed });

      return false;
    }
  }

  React.useEffect(() => {
    let chars: number[] = [];
    let timeout: any = null;

    function resetChars() {
      chars = [];
    }

    function onKeyDown(event: KeyboardEvent) {
      const char = parseInt(event.key, 10);
      if (!isNaN(char)) {
        clearTimeout(timeout);

        chars.push(char);

        if (chars.length === 7) {
          const number_badge = chars.join('');
          dispatch({ action: Action.StartValidation, number_badge });
          loginWithBadge(number_badge);
        } else {
          timeout = setTimeout(resetChars, 500);
        }
      }
    }

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  function onCloseClick() {
    props.onClose();
  }

  return (
    <div className="modal modal-sm active" id="modal-id">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Inloggen met badge</div>
        </div>
        <div className="modal-body">
          <Steps
            steps={[
              { icon: 'fa-walking', label: 'plaats de badge op de scanner' },
              {
                icon: 'fa-microchip',
                label: (
                  <>
                    controle op <strong>{state.number_badge}</strong> badge uitvoeren
                  </>
                ),
              },
              { icon: 'fa-microchip', label: 'aanmelden in de applicatie' },
            ]}
            step={state.step}
          />
        </div>

        <div className="modal-footer">
          <button className="btn btn-link  float-left" onClick={onCloseClick}>
            <i className="fal fa-times" /> Annuleren
          </button>
        </div>
      </div>
    </div>
  );
}
