import * as React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import App from './components/App';
import './styles/styles.scss';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';

const rootEl = document.getElementById('root');
window.GLOBAL_IS_TOUCH_DEVICE = !!('ontouchstart' in window || navigator.msMaxTouchPoints);

const AppContainerDnD = DragDropContext(HTML5Backend)(AppContainer);

render(
  <AppContainerDnD>
    <App />
  </AppContainerDnD>,
  rootEl,
);

// Hot Module Replacement API
declare let module: { hot: any };

if (module.hot) {
  module.hot.accept('./components/App', () => {
    const NewApp = require('./components/App').default;

    render(
      <AppContainerDnD>
        <NewApp />
      </AppContainerDnD>,
      rootEl,
    );
  });
}
