import * as React from 'react';
import * as Yup from 'yup';
import FormGroup from '../../components/FormGroup';
import FormInput from '../../components/FormInput';
import { Form, Formik } from 'formik';
import { getTouchedErrors, randomString } from '../../utils';
import { handleSimpleFormSubmit, http, SubmitResult } from '../../http';
import { Link } from '../../components/Link';
import { BaseStore, useRxjsStore } from '../../effects';
import axios, { AxiosError } from 'axios';
import { GenericDataService } from '../../services/GenericDataService';
import { ProfileService } from '../../services/ProfileService';
import Structure from '../../lib/Structure';
import { LocationService } from '../../services/LocationService';

interface PrsFormValues {
  email: string;
}
interface PrcFormValues {
  code: string;
  password: string;
}

type Context = {
  loading: boolean;
  stage: 'start' | 'complete';

  cppr_id?: number;
  email: string;
  password: string;
};

class Store extends BaseStore<Context> {
  setup(): Context {
    return {
      loading: false,
      stage: 'start',

      email: '',
      password: randomString(14),
    };
  }

  start = async ({ email }): Promise<SubmitResult> => {
    try {
      const res = await http().post('/core/contact/person/password_recovery_start', {
        email,
      });

      this.next(draft => {
        draft.stage = 'complete';
        draft.email = email;
        draft.cppr_id = res.data.id;
      });

      return {
        valid: true,
        message: 'De beveiliginscode is opgestuurd.',
      };
    } catch (exc) {
      console.error(exc);
      const err: AxiosError = exc;

      return {
        valid: false,
        errors: err.response.data.errors,
      };
    }
  };

  complete = async ({ code, password }): Promise<SubmitResult> => {
    try {
      const email = this.current().email;

      await http().post('/core/contact/person/password_recovery_complete', {
        email,
        code,
        password,
      });

      const result = await http().post(
        '/dmz/security/authentication',
        new URLSearchParams({
          email,
          password,
        }),
      );

      axios.defaults.headers['x-session'] = result.data.session.token;

      await GenericDataService.load();

      ProfileService.setProfile({
        person: result.data.person,
        session: result.data.session,
      });

      return {
        valid: true,
        message: 'Je nieuwe wachtwoord is ingesteld! Welkom in de ShopManager POS.',
      };
    } catch (exc) {
      console.error(exc);
      const err: AxiosError = exc;

      return {
        valid: false,
        errors: err.response.data.errors,
      };
    }
  };
}

export default function PageDmzPasswordRecovery() {
  const profile = ProfileService.useEffect('PageDmzPasswordRecovery');
  const location = LocationService.useEffect('PageDmzPasswordRecovery');
  const { store, context } = useRxjsStore<Context, Store>(Store);

  if (!context) {
    return null;
  }

  if (profile.present) {
    // We always redirect when this page is active.
    const pathname = Structure.getMenuForProfile(profile).reduce((result, menuItem) => {
      return result === '/'
        ? menuItem.sections.reduce((result, sectionItem) => {
            return sectionItem.items[0];
          }, '/')
        : result;
    }, '/');

    LocationService.navigate('/');
  }

  return (
    <div
      className="container"
      style={{ backgroundImage: `url(${require('./../../assets/images/password-recovery.jpg')})` }}
    >
      <div className="columns">
        <div className="column col-sm-10 col-md-8 col-5 col-mx-auto">
          <h1 className="text-center text-light">Loods 5 - Apps</h1>

          {context.stage === 'start' && <PasswordRecoveryStart store={store} />}
          {context.stage === 'complete' && <PasswordRecoveryComplete store={store} context={context} />}
        </div>
      </div>
    </div>
  );
}

const prs_schema = Yup.object().shape({
  email: Yup.string()
    .email('shouldBeEmail')
    .required('isRequired'),
});

function PasswordRecoveryStart({ store }: { store: Store }) {
  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={prs_schema}
      onSubmit={handleSimpleFormSubmit<PrsFormValues>(store.start)}
      render={fp => {
        const errors = getTouchedErrors(fp.touched, fp.errors);

        return (
          <Form>
            <div className="card">
              <div className="card-header">
                <div className="card-title  h5">Wachtwoord herstellen - stap 1/2</div>
                <div className="card-subtitle text-gray">
                  Je bent je wachtwoord vergeten? Geen probleem, stel hier een nieuwe in!
                </div>
              </div>
              <div className="card-body">
                <p>
                  Wanneer je klikt op de onderstaande knop, sturen we je een e-mail met daarin een verificatie-code.
                  Deze code is maar tijdelijk geldig! Het versturen van de e-mail kan heel even duren, maximaal een
                  minuutje of 3. De verificatie-code moet je hier in een vervolgscherm invoeren.
                </p>

                <FormGroup title="E-mailadres" name="email" errors={errors}>
                  <FormInput name="email" loading={fp.isSubmitting} />
                </FormGroup>
              </div>
              <div className="card-footer">
                <div className="flex-row flex-space-between">
                  <div>
                    <button type="submit" className="btn btn-primary mr-2" disabled={fp.isSubmitting}>
                      <i className="fal fa-send" />
                      <i className="fal fa-paper-plane" /> Verificatie-code aanvragen
                    </button>
                  </div>
                  <div>
                    <Link type="button" className="btn btn-primary-outline" pathName="/dmz/login">
                      <i className="fal fa-key" /> Toch aanmelden?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}

const prc_schema = Yup.object().shape({
  code: Yup.string()
    .min(8)
    .max(8)
    .required('isRequired'),
  password: Yup.string()
    .min(8)
    .max(64)
    .required('isRequired'),
});

function PasswordRecoveryComplete({ store, context }: { store: Store; context: Context }) {
  return (
    <Formik
      initialValues={{ code: '', password: '' }}
      validationSchema={prc_schema}
      onSubmit={handleSimpleFormSubmit<PrcFormValues>(store.complete)}
      render={fp => {
        const errors = getTouchedErrors(fp.touched, fp.errors);

        return (
          <Form>
            <div className="card">
              <div className="card-header">
                <div className="card-title  h5">Wachtwoord herstellen - stap 2/2</div>
              </div>
              <div className="card-body">
                <p>
                  Je ontvangt van ons een e-mail op het adres: {context.email}.<br />
                  Deze mail heeft het volgnummer <strong>{context.cppr_id}</strong>.
                </p>
                <p>
                  Het kan even duren voordat je deze mail hebt, maximaal een minuut of 3. De e-mail bevat een
                  verificatie-code, dit is <em>geen wachtwoord</em>, maar een controle van ons zeker te weten dat jij
                  dit e-mailadres bezit (het is immers een automatisch proces).
                </p>

                <FormGroup title="Verificatie-code:" name="code" errors={errors}>
                  <FormInput name="code" loading={fp.isSubmitting} />
                </FormGroup>
                <FormGroup title="Je nieuwe wachtwoord:" name="password" errors={errors}>
                  <FormInput type="password" name="password" loading={fp.isSubmitting} />
                </FormGroup>

                <p>
                  Wij stellen voor: <code>{context.password}</code>
                </p>
              </div>
              <div className="card-footer">
                <div className="flex-row flex-space-between">
                  <div>
                    <button type="submit" className="btn btn-primary mr-2" disabled={fp.isSubmitting}>
                      <i className="fal fa-send" />
                      <i className="fal fa-paper-plane" /> Wachwoord instellen
                    </button>
                  </div>
                  <div>
                    <Link type="button" className="btn btn-primary-outline" pathName="/dmz/login">
                      <i className="fal fa-key" /> Toch aanmelden?
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}

PageDmzPasswordRecovery.getAppRouterClassname = () => {
  return 'PageDmzPasswordRecovery';
};
