import { Field, FormikProps } from 'formik';
import * as React from 'react';
import { http } from '../../http';
import { useDebounce } from '../../effects';
import { cs } from '../../utils';
import * as _ from 'underscore';

type SpectreAutocompleteProps = {
  name: string;
  url: string;
  query?: any;
  multiple?: boolean;
  allowEmptySearch?: boolean;
  fp: FormikProps<any>;
  render?: (record) => React.ReactNode;
};

export default function SpectreAutocomplete(props: SpectreAutocompleteProps) {
  const me = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [position, setPosition] = React.useState({ left: 0, top: 0, width: 0 });
  const [results, setResults] = React.useState({ search: '', loading: false, records: [] });
  const [chosen, setChosen] = React.useState({ value: null, loading: false, records: [] });
  const value = props.fp.values[props.name];
  const valueAsArray = !value ? [] : Array.isArray(value) ? value : [value];

  React.useEffect(() => {
    function handleResize() {
      const rect = me.current.getBoundingClientRect();

      setPosition({
        left: rect.left,
        top: rect.top + rect.height,
        width: rect.width,
      });
    }

    function handleClickOutside(event) {
      if (me.current && !me.current.contains(event.target) && open) {
        setOpen(false);
      }
    }

    document.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    handleResize();

    return () => {
      document.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [me, open]);

  // region Results

  async function loadChosen() {
    if (!value || value.length === 0) {
      setChosen({
        value,
        loading: false,
        records: [],
      });
    } else {
      setChosen({
        value: null,
        loading: true,
        records: chosen.records,
      });

      try {
        const res = await http().post(props.url, {
          chosen: valueAsArray,
        });

        setChosen({
          value,
          loading: false,
          records: res.data.records,
        });
      } catch (exc) {
        setChosen({
          value,
          loading: false,
          records: [],
        });
      }
    }
  }

  React.useEffect(() => {
    if (chosen.loading === true) {
      return;
    }

    if (JSON.stringify(value) !== JSON.stringify(chosen.value)) {
      loadChosen();
    }
  }, [JSON.stringify(value), JSON.stringify(chosen.value), chosen.loading]);

  // endregion
  // region Results

  async function loadResults() {
    if (search === '' && !props.allowEmptySearch) {
      setResults({
        search,
        loading: false,
        records: [],
      });
      setOpen(false);
    } else {
      setResults({
        search: null,
        loading: true,
        records: results.records,
      });

      try {
        const res = await http().post(props.url, {
          search: search,
          ...(props.query ? props.query : {}),
        });

        setResults({
          search,
          loading: false,
          records: res.data.records,
        });
        setOpen(true);
      } catch (exc) {
        setResults({
          search,
          loading: false,
          records: [],
        });
        setOpen(false);
      }
    }
  }

  const debouncedSearch = useDebounce([search, results.search, results.loading], 500);

  React.useEffect(() => {
    if (results.loading === true) {
      // Do not start when still loading, the
      // effect will be re-triggered when the
      // loading has completed.
      return;
    }

    if (search !== results.search) {
      loadResults();
    }
  }, [debouncedSearch]);

  // endregion

  function addFpValue(new_value) {
    let data;

    if (props.multiple) {
      data = [...valueAsArray];
      data = data.concat(new_value).filter(x => x.length);
    } else {
      data = new_value;
    }

    props.fp.setFieldValue(props.name, data);
  }

  function removeFpValue(del_value) {
    let data;

    if (props.multiple) {
      data = [...valueAsArray];
      data = data.filter(x => x !== del_value).filter(x => x.length);
    } else {
      data = '';
    }

    props.fp.setFieldValue(props.name, data);
  }

  return (
    <div className="form-autocomplete" ref={me}>
      <Field name={props.name} type="hidden" />
      <div className="form-autocomplete-input form-input">
        {chosen.records.map(chosen => {
          const className = cs('chip flex-row', !props.multiple && 'full-width');

          function onClick() {
            removeFpValue(chosen.value);
          }

          return (
            <div className={className} onClick={onClick} key={chosen.value}>
              <div className="flex-grow">
                {!props.render ? chosen.title : props.render(chosen)}
              </div>
              <a href="javascript:" className="btn btn-clear" aria-label="Close" role="button" />
            </div>
          );
        })}

        {(!value || props.multiple) && (
          <input
            className="form-input"
            type="text"
            value={search}
            placeholder="..."
            onChange={e => setSearch(e.target.value)}
            onFocus={e => props.allowEmptySearch && !open && loadResults()}
          />
        )}
      </div>
      {open && (
        <ul
          className="menu"
          style={{
            position: 'fixed',
            top: position.top,
            left: position.left,
            width: position.width,
            maxHeight: 300,
            overflow: 'auto',
          }}
        >
          {results.records.length === 0 && (
            <li className="menu-item">
              <em>Er zijn geen records gevonden...</em>
            </li>
          )}
          {results.records.map(record => {
            const is_active = _.contains(valueAsArray, record.value);

            function onClick() {
              if (is_active) {
                removeFpValue(record.value);
              } else {
                addFpValue(record.value);
              }
              setOpen(false);
              setSearch('');
            }

            return (
              <li className="menu-item" key={record.value}>
                <a href="javascript:" onClick={onClick} className={cs(is_active && 'active')}>
                  {!props.render ? record.title : props.render(record)}
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
