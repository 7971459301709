import * as React from 'react';
import { isWithinMz, loadOneRecord } from '../../../../utils';
import SpectreCardHeader from '../../../../components/spectre/SpectreCardHeader';
import SpectreButton from '../../../../components/spectre/SpectreButton';
import { BaseStore, useRxjsStore } from '../../../../effects';
import { Link } from '../../../../components/Link';
import Loading from '../../../../components/Loading';
import { http } from '../../../../http';
import { LocationService } from '../../../../services/LocationService';

// region Types

export type PageShopArticleStickerTemplatePublishContext = {
  loaded: boolean;

  template_id: string;
  template: ModelShopStickerTemplate;

  modal?: PageShopArticleStickerTemplatePublishModalContext;
};

export type PageShopArticleStickerTemplatePublishModalContext = {
  which: 'print';
  data: { template_id: number };
};

export type PageShopArticleStickerTemplatePublishModalFilterValues = {
  search: string;
};

// endregion

export const PageShopArticleStickerTemplatePublishCtx = React.createContext<
  PageShopArticleStickerTemplatePublishBasicProps
>(null);

export default function PageShopArticleStickerTemplatePublish({ template_id }) {
  const { store, context } = usePageShopArticleStickerTemplatePublishRxjs(template_id);
  const scrollable = React.useRef<HTMLDivElement>(null);

  if (!store || !context) return null;

  const { template } = context;

  function scrollableToTop() {
    if (scrollable.current) {
      scrollable.current.scrollTo({ top: 0 });
    }
  }

  async function onSubmit() {
    if (await store.publish()) {
      LocationService.navigate('/shop/sticker/template/many');
    }
  }

  if (!context.template) return <Loading />;

  return (
    <PageShopArticleStickerTemplatePublishCtx.Provider value={{ store, context }}>
      <div className="container" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div className="card" style={{ height: '100%' }}>
          <SpectreCardHeader
            title={`Sticker-Template: ${context.template.name}`}
            subtitle={
              <>
                Controleer nog eenmaal of dit de stickers zijn die je wilt printen. Je kunt hier het aantal van een
                artikel op <em>0</em> zetten wanneer je dat artikel niet geprint wilt zien. Je kunt hier ook nog
                afwijken van het ingestelde sticker-type, bijvoorbeeld van Onbeveiligd naar Beveiligd.
                <br />
                Druk op <strong>Printen</strong> om de opdracht naar de printers te sturen!
              </>
            }
            buttons={[
              <SpectreButton
                icon="fa-arrow-left"
                link={['/shop/sticker/template/many']}
                tooltip={{ title: 'Terug naar het overzicht', position: 'left' }}
              />,
            ]}
          />

          {template.rows.length !== 0 && (
            <div className="card-body  card-body-fixed">
              <Table />
            </div>
          )}
          {template.rows.length === 0 && (
            <div className="card-body  card-body-fixed">
              <div className="empty">
                <div className="empty-icon">
                  <i className="fal fa-shopping-cart  fa-3x" />
                </div>
                <p className="empty-title h5">Geen artikelen</p>
                <p className="empty-subtitle">Er zijn nog geen artikelen toegevoegd...</p>
              </div>
            </div>
          )}

          <div className="card-footer">
            <div className="flex-row flex-space-between">
              <div>
                <Link pathName="/shop/sticker/template/edit" pathSearch={{ template_id }} className="btn btn-link">
                  <i className="fal fa-times" /> Terug naar aanpassen
                </Link>
              </div>
              {isWithinMz() && (
                <div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg"
                    onClick={onSubmit}
                    disabled={!context.loaded}
                  >
                    <i className="fal fa-print" /> Printen
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <Modals />
      </div>
    </PageShopArticleStickerTemplatePublishCtx.Provider>
  );
}

// region Components

function Modals() {
  const { context, store } = React.useContext(PageShopArticleStickerTemplatePublishCtx);
  const mctx = context.modal;

  if (!mctx) return null;

  return <React.Fragment />;
}

function Table() {
  const { context, store } = React.useContext(PageShopArticleStickerTemplatePublishCtx);

  const { template } = context;

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th className="has-table-shrink-nowrap">Nummer</th>
          <th>Naam</th>
          <th className="has-table-shrink-nowrap  text-right">Aantal</th>
          <th className="has-table-shrink-nowrap">StickerType</th>
        </tr>
      </thead>
      <tbody>
        {template.rows.map((row, rx) => {
          return (
            <tr key={rx}>
              <td className="has-table-shrink-nowrap">{row.number_beeyond}</td>
              <td>{row.name}</td>
              <td style={{ width: 60 }}>
                <input
                  className="form-input  text-right"
                  type="text"
                  value={row.default_amount}
                  onChange={e => store.updateDefaultAmount(rx, e.currentTarget.value)}
                />
              </td>
              <td style={{ width: 140 }}>
                <select
                  className="form-select"
                  value={row.default_type}
                  onChange={e => store.updateDefaultType(rx, e.currentTarget.value)}
                >
                  <option value="label-small">Onbeveiligd</option>
                  <option value="label-protected">Beveiligd</option>
                  <option value="label-large">Meubel</option>
                </select>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

// endregion
// region Store

class PageShopArticleStickerTemplatePublishStore extends BaseStore<PageShopArticleStickerTemplatePublishContext> {
  setup(): PageShopArticleStickerTemplatePublishContext {
    return {
      loaded: false,
      template_id: null,
      template: null,
      modal: null,
    };
  }

  async load(partial: boolean = true) {
    const template_id = this.current().template_id;

    this.next(draft => {
      draft.loaded = false;
    });

    const result = await loadOneRecord('/core/shop/sticker/template/one', {
      template: template_id,
    });

    this.next(draft => {
      draft.loaded = true;

      draft.template = result;
    });
  }

  async submit(): Promise<boolean> {
    this.next(draft => {
      draft.loaded = false;
    });

    const current = this.current();

    try {
      await http().post('/core/shop/sticker/template/publish', {
        template: current.template.id,
        rows: current.template.rows,
      });

      return true;
    } catch (e) {
      this.next(draft => {
        draft.loaded = true;
        console.error(e);
      });

      return false;
    }
  }

  updateDefaultAmount(rx: number, default_amount: string) {
    this.next(draft => {
      if (default_amount === '') {
        draft.template.rows[rx].default_amount = '';
      } else {
        const parsed = parseInt(default_amount, 10);
        draft.template.rows[rx].default_amount = (isNaN(parsed) ? 0 : parsed).toString();
      }
    });
  }

  updateDefaultType(rx: number, default_type: string) {
    this.next(draft => {
      draft.template.rows[rx].default_type = default_type;
    });
  }

  async publish(): Promise<boolean> {
    const ggd = window.GLOBAL_GENERIC_DATA;

    this.next(draft => {
      draft.loaded = false;
    });

    const current = this.current();

    try {
      await http().post('/core/shop/sticker/template/publish', {
        template: current.template.id,
        building: ggd.device.group.building_id,
        rows: current.template.rows,
      });

      return true;
    } catch (e) {
      this.next(draft => {
        draft.loaded = true;
        console.error(e);
      });

      return false;
    }
  }

  // region Utils

  openModal(ctx: PageShopArticleStickerTemplatePublishModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }

  closeModal = ({ reload }: { reload: boolean } = { reload: false }) => {
    this.next(draft => {
      draft.modal = null;
    });

    if (reload) {
      setTimeout(() => {
        this.load();
      }, 1);
    }
  };

  // endregion
}

export type PageShopArticleStickerTemplatePublishBasicProps = {
  store: PageShopArticleStickerTemplatePublishStore;
  context: PageShopArticleStickerTemplatePublishContext;
};

export function usePageShopArticleStickerTemplatePublishRxjs(
  template_id: string,
): PageShopArticleStickerTemplatePublishBasicProps {
  const { store, context } = useRxjsStore<
    PageShopArticleStickerTemplatePublishContext,
    PageShopArticleStickerTemplatePublishStore
  >(
    PageShopArticleStickerTemplatePublishStore,
    (): Partial<PageShopArticleStickerTemplatePublishContext> => {
      return { template_id };
    },
    () => {
      store.load(false);
    },
  );

  return { store, context };
}

// endregion
