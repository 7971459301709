import * as React from 'react';
import { http, httpLive } from '../../../http';
import { BaseStore, useRxjsStore } from '../../../effects';
import { MessageService } from '../../../services/MessageService';
import Steps from '../../../components/Steps';
import Ticket from '../../../lib/Ticket';

type Props = {
  onClose: (success: boolean) => void;
  terminalId: number;
};

type Context = {
  loading: boolean;
  step: number;

  terminal: ModelDeviceTerminal;
  payment: ModelFinancePayment;
  ping: boolean;
};

class Store extends BaseStore<Context> {
  setup(): Context {
    return {
      loading: true,
      step: 0,

      terminal: null,
      payment: null,
      ping: false,
    };
  }

  async load(terminal_id: number) {
    this.next(draft => {
      draft.loading = true;
    });

    try {
      const res = await http().post('/core/device/terminal/one', { id: terminal_id });

      this.next(draft => {
        draft.loading = false;
        draft.terminal = res.data;
      });
    } catch (e) {
      this.next(draft => {
        draft.loading = false;
      });
    }
  }

  async terminalsProcessPayment() {
    const validation = await http().post('/sale/payment/create_validation', {
      terminal: this.current().terminal.id,
      amount: 1,
    });

    const payment = validation.data;

    const res = await httpLive().post('/terminals/process-payment', {
      payment_id: payment.id,
    });

    this.next(draft => {
      draft.step = 1;

      draft.payment = payment;
      draft.ping = true;
    });
  }

  async pingPayment() {
    if (!this.current().ping) return false;

    const res = await http().post('/sale/payment/one', {
      id: this.current().payment.id,
    });

    this.next(draft => {
      draft.payment = res.data.payment;
    });

    if (res.data.payment.is_authorized) {
      this.next(draft => {
        draft.ping = false;
        draft.step = 2;
      });

      await this.ticketsPrint(1, this.buildTicket());

      this.next(draft => {
        draft.step = 3;
      });
    } else if (res.data.payment.is_cancelled) {
      this.next(draft => {
        draft.ping = false;
        draft.step = 0;
      });
    }
  }

  async terminalsCancelProcess() {
    const res = await httpLive().post('/terminals/cancel-process', {
      terminal_id: this.current().payment.terminal_id,
    });
  }

  async ticketsPrint(ticket_printer_id, ticket: Ticket) {
    const res = await httpLive().post('/tickets/print', {
      ticket_printer_id,
      ticket: await ticket.generate(),
    });
  }

  buildTicket(): Ticket {
    const payment = this.current().payment;

    return new Ticket()
      .addLine('')
      .addBar()
      .addLine('Test bon', 'center')
      .addBar()
      .addLine('')
      .addLine('')
      .addClientTicket(payment.foreign_client_ticket)
      .addLine('')
      .addBar()
      .addLine('')
      .cutPaper();
  }
}

export default function ModelValidateTerminal(props: Props) {
  const { context, store } = useRxjsStore<Context, Store>(Store);

  React.useEffect(() => {
    if (context !== null) {
      store.load(props.terminalId);
    }
  }, [!!context]);

  React.useEffect(() => {
    if (context && context.ping) {
      const interval = setInterval(() => {
        store.pingPayment();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [context && context.ping]);

  function onStartClick() {
    store.terminalsProcessPayment();
  }

  function onCancelClick() {
    store.terminalsCancelProcess();
  }

  function onCloseClick() {
    props.onClose(context.step === 6);
  }

  async function onStopTransactionClick() {
    // rebuild.
    MessageService.setMessage(`Transactie annuleren...`, 'error', 3000);
  }

  if (!context) {
    return null;
  }

  return (
    <div className="modal active" id="modal-id">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Valideren van PIN-terminal</div>
          {context.terminal && <div className="h6 text-gray">terminal: {context.terminal.identifier}</div>}
        </div>
        {context.loading && (
          <div className="modal-body">
            <div className="text-center">
              <p>
                <i className="fal fa-spin fa-spinner-third" /> Even laden hoor!
              </p>
            </div>
          </div>
        )}

        {!context.loading && context.step > 0 && (
          <div className="modal-body">
            <Steps
              steps={[
                { icon: 'fa-file-invoice', label: 'betaling aanmaken' },
                { icon: 'fa-user  text-primary', label: `betaal met de terminal ${context.terminal.identifier}` },
                { icon: 'fa-receipt', label: 'afdrukken van de PIN-bon' },
              ]}
              step={context.step}
            />
          </div>
        )}

        {!context.loading && context.step === 0 && (
          <div className="modal-body">
            <p>
              Klik op de <strong>Starten</strong> knop om de validatie-betaling te starten.
            </p>
          </div>
        )}

        {!context.loading && (
          <div className="modal-footer">
            {context.step === 0 && (
              <button className="btn btn-link  float-left" onClick={onCloseClick}>
                <i className="fal fa-times" /> Annuleren
              </button>
            )}

            {context.step === 1 && (
              <button className="btn btn-error  float-right" onClick={onCancelClick}>
                <i className="fal fa-times" /> Stoppen
              </button>
            )}
            {!context.loading && context.step === 0 && (
              <button className="btn btn-primary  float-right" onClick={onStartClick}>
                <i className="fal fa-paper-plane" /> Starten
              </button>
            )}
            {context.step === 3 && (
              <button className="btn btn-success  float-right" onClick={onCloseClick}>
                <i className="fal fa-check" /> Sluiten
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
