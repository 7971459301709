import * as React from 'react';
import { BaseStore, useRxjsStore } from '../../../effects';
import { http, httpLive } from '../../../http';
import Ticket from '../../../lib/Ticket';
import { buildCartTicket } from '../../../utils';

type Context = {
  loaded: boolean;
  cart_id: number;

  device: ModalBundleDevice;

  cart: ModelShopCart;
  lines: ModelShopCartLine[];
  giftcards_payment: ModelShopCartGiftcardPayment[];
  giftcards_purchase: ModelShopCartGiftcardPurchase[];

  printing: boolean;
};

type Props = {
  cart_id: number;
  close: (success?: boolean) => void;
};

class Store extends BaseStore<Context> {
  setup(): Context {
    return {
      loaded: false,
      cart_id: null,

      device: null,

      cart: null,
      lines: [],
      giftcards_payment: [],
      giftcards_purchase: [],

      printing: false,
    };
  }

  async load() {
    this.next(draft => {
      draft.loaded = false;
    });

    try {
      let res;

      res = await httpLive().post('/devices/group');

      this.next(draft => {
        draft.device = res.data;
      });

      res = await http().post('/sale/cart/one', {
        cart: this.current().cart_id,
      });

      const cart = res.data.cart;
      const lines = res.data.lines;
      const giftcards_payment = res.data.giftcards_payment;
      const giftcards_purchase = res.data.giftcards_purchase;

      this.next(draft => {
        draft.loaded = true;

        draft.cart = cart;
        draft.lines = lines;
        draft.giftcards_payment = giftcards_payment;
        draft.giftcards_purchase = giftcards_purchase;
      });

      await this.print();
    } catch (e) {
      console.error(e);
      this.next(draft => {
        draft.loaded = true;
      });
    }
  }

  async print() {
    this.next(draft => {
      draft.printing = true;
    });

    const current = this.current();
    const ticket_printer_id = current.device.ticket_printer?.id;
    const ticket = await buildCartTicket(
      current.cart,
      current.lines,
      current.giftcards_payment,
      current.giftcards_purchase,
      null,
    );

    await this.ticketsPrint(ticket_printer_id, ticket);

    this.next(draft => {
      draft.printing = false;
    });
  }

  async ticketsPrint(ticket_printer_id, ticket: Ticket) {
    const data = await ticket.generate();

    const res = await httpLive().post('/tickets/print', {
      ticket_printer_id: ticket_printer_id || 0,
      ticket: data,
    });
  }
}

export default function ModalOrderPrintTicket(props: Props) {
  const { context, store } = useRxjsStore<Context, Store>(
    Store,
    (): any => {
      return {
        cart_id: props.cart_id,
      };
    },
    () => {
      store.load();
    },
  );

  if (!context) return null;

  return (
    <div className="modal active" id="modal-id">
      <div className="modal-overlay" />

      {context.loaded && (
        <div className="modal-container">
          <div className="modal-header">
            <div className="modal-title h5">Print ticket van #{context.cart.id}</div>
          </div>

          <div className="modal-body">
            <p>
              We gaan printen naar <code>{context.device.ticket_printer?.ip_address}</code>
            </p>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-dark-outline  float-left" onClick={() => props.close()}>
              <i className="fal fa-times" /> Sluiten
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
