import * as React from 'react';
import Render from './Render';

export default function PageDocCoreShopArticleStickerTemplate() {
  return <Render markdown={markdown} />;
}

const markdown = `

# Inleiding

Na het inloggen zie je links drie iconen staan. Het derde icoon is het onderdeel
wat gerelateerd is aan alles wat met de Shop te maken heeft. Hier vind je nu het
kunnen maken, beheren, en afdrukken van sticker-templates.
Wanneer je hebt geklikt op Stickers links, zie je een overzicht verschijnen.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-shop-article-sticker-template/1%20-%20overzicht.png)

## Sticker-template toevoegen

Rechts bovenin het scherm bevind zich een plus knopje. Door hier op te klikken kun
je een nieuwe sticker-template toevoegen.

![Stap 2](${GLOBAL_APIS_URL}static/doc/core-shop-article-sticker-template/2%20-%20toevoegen.png)

Na het klikken op het plusje ontstaat er een popup, hier kun je de naam van de
sticker-template invoeren. Je kunt sticker-templates zo vaak gebruiken als je wilt,
dus je kunt de naam gebruiken als referentie.

![Stap 3](${GLOBAL_APIS_URL}static/doc/core-shop-article-sticker-template/3%20-%20toevoegen%20-%20naam%20opgeven.png)

Wanneer je de naam gekozen hebt, kun je klikken op Versturen.

## Sticker-template beheren
 
Er opent een nieuw scherm waar je de artikelen kunt gaan beheren die horen bij deze
sticker-template. De applicatie zal altijd eerst “Geen artikelen” aangeven.

![Stap 4](${GLOBAL_APIS_URL}static/doc/core-shop-article-sticker-template/4%20-%20template%20overzicht.png)

Het toevoegen van artikelen aan de printopdracht doe je door middel van zoeken. Door
in het zoekveld een naam, Beeyond-nummer, of je eigen artikelcode in te voeren, vind
je snel de juiste artikelen.

Door te zoeken op naam zie je alle artikelen die daar ongeveer aan voldoen. Je kunt dus
een spelfout maken, of wat globaler zoeken, zoals we hier doen op Bijzettafel.

![Stap 5](${GLOBAL_APIS_URL}static/doc/core-shop-article-sticker-template/5%20-%20zoeken%20op%20naam.png)

Je kunt ook zoeken op een deel van je eigen artikel-codes. Zodra we een of meerder
artikelen hebben gevonden die deze artikel-code voeren, tonen we ze direct in beeld.

![Stap 6](${GLOBAL_APIS_URL}static/doc/core-shop-article-sticker-template/6%20-%20zoeken%20op%20artikelcode.png)

*Let op*, de artikel code moet starten met wat je invoert. Starten je artikel-codes
met bijvoorbeeld CTX, dan moet je dat deel ook invoeren!

Door te klikken op een artikel in het zoekresultaat voeg je ze toe aan de sticker-template.

![Stap 7](${GLOBAL_APIS_URL}static/doc/core-shop-article-sticker-template/7%20-%20klikken%20op%20artikel.png)

Voor het gemak kun je voor het toevoegen van een artikel rechts bovenin bij Aantal en 
StickerType alvast invoeren met welke waarden je ze toegevoegd wilt hebben. Wil je 10
verschillende artikelen gaan stickeren, waar je van ieder 15 stuks hebt. Dan kun je voor het
klikken op het zoekresultaat bij Aantal 15 invoeren. De applicatie neemt deze gegevens
dan direct over bij het toevoegen.

Wanneer je zoekresultaat meerdere artikelen bevat, die je allemaal wilt toevoegen, dan
kun je ze gewoon een voor een aanklikken. De zoekresultaten blijven openstaan tot je
ergens anders in het scherm klikt, bijvoorbeeld opnieuw op het zoeken veld.

![Stap 8](${GLOBAL_APIS_URL}static/doc/core-shop-article-sticker-template/8%20-%20meer%20artikelen%20zoeken.png)

Heb je alle artikelen toegevoegd aan je sticker-template, dan moet je deze nog opslaan.
Onderin het scherm krijg je twee keuzes:

1. Opslaan & printen – wanneer je direct met je sticker-template aan de slag wilt.
2. Opslaan & sluiten – wanneer je later verder wilt.

In dit voorbeeld klikken we op de knop Opslaan & printen.

## Sticker-template printen

![Stap 9](${GLOBAL_APIS_URL}static/doc/core-shop-article-sticker-template/9%20-%20artikelen%20toegevoegd.png)

Je kunt de sticker-templates ook thuis voorbereiden. Je kunt inloggen in de applicatie
en ze alvast aan maken. Wanneer je thuis of op werk bent zal echter de Opslaan & printen
knop niet zichtbaar zijn. Deze is alleen beschikbaar op een door Loods 5 beheerde deelnemers PC.

Het printen overzicht is bijna hetzelfde als het beheren overzicht, je ziet dat het
toevoegen en verwijderen van artikelen ontbreekt. De applicatie toont je nu de
voorbereidde opdracht, maar je kunt hier nu beslissen de aantallen of de sticker-types
nog even aan te passen.

![Stap 10](${GLOBAL_APIS_URL}static/doc/core-shop-article-sticker-template/10%20-%20start%20printen%20overzicht.png)

In dit voorbeeld passen we de eerste bijzettafel aan naar 3 keer, met een beveiligde sticker.

![Stap 11](${GLOBAL_APIS_URL}static/doc/core-shop-article-sticker-template/11%20-%20aanpassen%20voor%20het%20printen.png)

Ben je tevreden met de aantallen en de sticker-types, klik dan rechts onderin op Printen
en hij gaat direct voor je aan de slag.

Na het klikken op printen ga je direct terug naar het overzicht. Vanuit hier zie je het
nieuwe sticker-template staan. 

![Stap 12](${GLOBAL_APIS_URL}static/doc/core-shop-article-sticker-template/12%20-%20terug%20in%20het%20overzicht.png)

Wil je dezelfde stickers nog een keer afdrukken, of een ander sticker-template, dan kan
dat door te klikken op het printen icoontje bij de sticker-template.
 
![Stap 13](${GLOBAL_APIS_URL}static/doc/core-shop-article-sticker-template/13%20-%20direct%20starten%20met%20printen.png)

Wil je artikelen toevoegen, verwijderen, of de start-waardes aanpassen, dan kan dat door
te klikken op het aanpassen icoontjes.

![Stap 15](${GLOBAL_APIS_URL}static/doc/core-shop-article-sticker-template/15%20-%20aanpassen%20knopje.png)

`;
