import PageDeviceDashboard from './PageDeviceDashboard';

const DeviceStructure = [
  {
    pathname: '/device/dashboard',
    title: 'Dashboard',
    icon: 'fa-desktop',
    component: PageDeviceDashboard,

    rights: ['core.administrate'],
  },
];

export default DeviceStructure;
