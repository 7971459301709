import * as React from 'react';
import { ModalBoxContainer } from './Components';
import ModalEmail from './ModalEmail';
import { PageDisplayCtx } from './PageDisplay';

export default function Modals() {
  const { context, store } = React.useContext(PageDisplayCtx);
  if (context.modal === null) return null;
  const { which, data } = context.modal;

  return <ModalBoxContainer>{which === 'email' && <ModalEmail />}</ModalBoxContainer>;
}
