import * as React from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikActions } from 'formik';
import { http } from '../../../http';
import FormInput from '../../../components/FormInput';
import { displayError } from '../../../components/FormGroup';
import { AxiosError } from 'axios';
import SpectreCalendar from '../../../components/spectre/SpectreCalendar';
import { cs, getTouchedErrors, loadOneRecord } from '../../../utils';
import ModalLoading from '../../../components/ModalLoading';

type Props = {
  sponsor_id: number;
  closeModal: ({ reload: boolean }?) => void;
};

type Values = {
  started_at: string;
  subject: string;
  description: string;
};

const schema = {
  started_at: Yup.string().matches(/^\d{1,2}-\d{1,2}-\d{4}$/, 'dateIsRequired'),
  subject: Yup.string().required('isRequired'),
};

export default function ModalSponsorUpdate({ sponsor_id, closeModal }: Props) {
  const [loaded, setLoaded] = React.useState(false);
  const [sponsor, setSponsor] = React.useState<ModelOrderSponsor>(null);

  React.useEffect(() => {
    (async () => {
      setSponsor(await loadOneRecord('/core/order/sponsor/one', { sponsor: sponsor_id }));
      setLoaded(true);
    })();
  }, [sponsor_id]);

  if (!loaded) return <ModalLoading title="Even laden, bijna klaar!" />;

  const initialValues = {
    started_at: sponsor.started_at,
    subject: sponsor.subject,
    description: sponsor.description,
  };

  async function onSubmit(values: Values, formikActions: FormikActions<Values>) {
    try {
      const res = await http().post('/core/order/sponsor/update', {
        sponsor: sponsor.id,
        ...values,
      });

      closeModal({ reload: true });
    } catch (exc) {
      const err: AxiosError = exc;
      console.error(exc);

      if (err.response.data && err.response.data.errors) {
        formikActions.setErrors(err.response.data.errors);
      }

      formikActions.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      render={fp => {
        const errors = getTouchedErrors(fp.touched, fp.errors);

        return (
          <Form>
            <div className="modal active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">
                    Sponsor <strong>{fp.values.subject}</strong> aanpassen
                  </div>
                </div>

                <div className="modal-body">
                  <div className={cs('columns form-group', (errors.started_at || errors.subject) && 'has-error')}>
                    <div className="column col-3 col-sm-12">
                      <label className="form-label">Gestart op</label>
                      <SpectreCalendar name="started_at" fp={fp} />
                      {errors.started_at && <div className="form-input-hint">{displayError(errors.started_at)}</div>}
                    </div>
                    <div className="column col-9 col-sm-12">
                      <label className="form-label">Onderwerp</label>
                      <FormInput name="subject" />
                      {errors.subject && <div className="form-input-hint">{displayError(errors.subject)}</div>}
                    </div>
                  </div>
                  <div className={cs('form-group')}>
                    <label className="form-label is-optional">Beschrijving</label>
                    <FormInput component="textarea" name="description" />
                  </div>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary" disabled={fp.isSubmitting}>
                        <i className="fal fa-pencil" /> Aanpassen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
