import { cs, formatCentsShort, formatPercentage } from '../../../utils';
import * as React from 'react';
import ComponentColgroup from './ComponentColgroup';
import { PageDefinition } from './PageState';

type ComponentSaleFooterProps = {
  page: PageDefinition;
};

export default function ComponentFooter(props: ComponentSaleFooterProps) {
  const { context, store } = props.page;
  const isDisabled = context.scroll.scrollTop === context.scroll.scrollHeight - context.scroll.offsetHeight;
  const isLarge = context.touch && context.touch.is_large;

  if (!context.cart) {
    return null;
  }

  function onClick() {
    store.scroll(1);
  }

  const articles = context.cart.amount == 1 ? `1 artikel` : `${context.cart.amount} artikelen`;
  const total_discount = context.lines.reduce((p, e) => p + e.discount_price, 0);

  return (
    <table className="table table-small table-fixed">
      <ComponentColgroup isLarge={isLarge} />
      <tfoot>
        <tr>
          <th>&nbsp;</th>
          {!isLarge && <th>&nbsp;</th>}
          {!isLarge && <th>&nbsp;</th>}
          {!isLarge && <th>{articles}</th>}
          <th className="text-right">
            {isLarge && <div>{articles}</div>}
            {formatCentsShort(context.cart.inclusive)}
            {!isLarge && (
              <React.Fragment>
                {total_discount > 0 && (
                  <div>
                    <small>Korting: {formatCentsShort(total_discount)}</small>
                  </div>
                )}
                <div>
                  <small>BTW: {formatCentsShort(context.cart.vat)}</small>
                </div>
              </React.Fragment>
            )}
          </th>
          <th className="text-right">
            <a onClick={onClick} className={cs('btn btn-primary btn-action s-circle', isDisabled && 'disabled')}>
              <i className="fal fa-arrow-down" />
            </a>
          </th>
        </tr>
        {context.cart.exempt_vat && (
          <tr>
            <td colSpan={!isLarge ? 6 : 3} className="text-center  bg-primary">
              <strong>Let op! Deze order is vrijgesteld van BTW.</strong>
            </td>
          </tr>
        )}
      </tfoot>
    </table>
  );
}
