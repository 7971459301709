import { formatCents, hasRights, isWithinMz } from '../../../utils';
import * as React from 'react';
import { ProfileService } from '../../../services/ProfileService';
import MenuDropdown from '../../../components/MenuDropdown';

type Props = {
  purchase: ModelOrderPurchase;
  fully_paid: boolean;
  store: any;
};

export function ComponentOrderPurchaseHeader(props: Props) {
  const ggd = window.GLOBAL_GENERIC_DATA;
  const profile = ProfileService.useEffect('ComponentOrderPurchaseHeader');
  const { purchase, fully_paid, store } = props;
  const customer = purchase.customer;

  let tooltip = 'Volledig betaald.';
  if (!fully_paid) {
    tooltip = `Er is ${formatCents(purchase.paid)} van de ${formatCents(purchase.inclusive)} betaald, er moet 
    nog ${formatCents(purchase.inclusive - purchase.paid)} betaald worden.`;
  }

  const requires_payment_sale = purchase.current_inclusive > purchase.paid;
  const requires_payment_refund = purchase.current_inclusive < purchase.paid;

  return (
    <div className="card">
      <div className="card-header">
        <div className="flex-row">
          <div className="flex-grow">
            <div className="card-title h5">Order #{purchase.cart_id}</div>
            <div>
              {fully_paid ? (
                <span className="text-success">
                  <i className="fal fa-fw fa-money-bill-wave" /> volledig betaald
                </span>
              ) : (
                <span className="text-error tooltip tooltip-right" data-tooltip={tooltip}>
                  <i className="fal fa-fw fa-money-check" /> deels betaald
                </span>
              )}
            </div>
          </div>
          <div className="flex-shrink">
            {hasRights(['shop.orders.payment']) && (requires_payment_sale || hasRights(['core.administrate'])) && (
              <button
                type="button"
                className="btn btn-primary ml-2"
                onClick={() => store.openModal({ which: 'payment-sale', data: { purchase } })}
              >
                <i className="fal fa-fw fa-money-bill" /> klantbetaling uitvoeren
              </button>
            )}
            {hasRights(['cash.refund']) && (requires_payment_refund || hasRights(['core.administrate'])) && (
              <button
                type="button"
                className="btn btn-warning ml-2"
                onClick={() => store.openModal({ which: 'payment-refund', data: { purchase } })}
              >
                <i className="fal fa-fw fa-money-bill" /> klantterugbetaling uitvoeren
              </button>
            )}

            <MenuDropdown
              button={<i className="fal fa-ellipsis-h" />}
              classNames={['ml-2']}
              btnClassNames={['btn btn-dark-outline btn-action s-circle']}
              items={[
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => store.openModal({ which: 'download-pdf', data: { cart_id: purchase.cart_id } })}
                >
                  <i className="fal fa-fw fa-file-pdf" /> download factuur
                </button>,
                isWithinMz() && (
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => store.openModal({ which: 'print-ticket', data: { cart_id: purchase.cart_id } })}
                  >
                    <i className="fal fa-fw fa-ticket" /> print bon opnieuw
                  </button>
                ),
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => store.openModal({ which: 'mail-pdf' })}
                  disabled={!(customer && customer.email)}
                >
                  <i className="fal fa-fw fa-envelope" /> order bevestiging
                </button>,
                !fully_paid && (
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => store.openModal({ which: 'mail-request-payment' })}
                    disabled={!(customer && customer.email)}
                  >
                    <i className="fal fa-fw fa-envelope" /> verzoek restbetaling
                  </button>
                ),
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
