import { compactOrderPurchaseLines } from '../../utils';

export function formToDataObject(
  values: { [name: string]: string },
  purchases_per_tenant: ModelOrderPurchaseTenant[],
  shrunken_lines: ModelOrderPurchaseLineShrunken[],
) {
  const data = {
    purchase_tenant: purchases_per_tenant.map(opt => opt.id),
    lines: [],
  };

  for (const name of Object.keys(values)) {
    if (name.startsWith('line_set')) {
      const value = parseInt(values[name]);

      if (!value) {
        continue;
      }

      const lx = parseInt(name.split('-').pop());
      const line_id_set = shrunken_lines[lx].line_id_set.slice(0, value);

      data.lines = data.lines.concat(line_id_set);
    } else {
      data[name] = values[name];
    }
  }

  return data;
}

export function prepareShrunkenLines(
  purchases_per_tenant: ModelOrderPurchaseTenant[],
  disallowed_states: string[] = ['delivered', 'retour', 'cancelled', 'annulled'],
): ModelOrderPurchaseLineShrunken[] {
  const lines = purchases_per_tenant.reduce((r, opt) => r.concat(opt.lines), []);

  const allowed = (line: ModelOrderPurchaseLine) => {
    return disallowed_states.indexOf(line.state) === -1;
  };

  return compactOrderPurchaseLines(lines).filter(allowed);
}
