import * as React from 'react';
import { Form, Formik } from 'formik';
import FormGroup from '../../../components/FormGroup';
import SpectreAutocomplete from '../../../components/spectre/SpectreAutocomplete';
import SpectreCalendar from '../../../components/spectre/SpectreCalendar';
import FormCheckbox from '../../../components/FormCheckbox';
import FormSelect from '../../../components/FormSelect';

export type ModalFilterValues = {
  day_start: string;
  day_end: string;
  kinds: string[];
  tenants: string[];

  sorting: string;
};

type Props = {
  initial: ModalFilterValues;
  closeModal: (values?: ModalFilterValues) => void;
};

export default function ModalFilter({ initial, closeModal }: Props) {
  function onSubmit(values: ModalFilterValues) {
    closeModal(values);
  }

  return (
    <Formik
      initialValues={initial}
      onSubmit={onSubmit}
      render={fp => {
        return (
          <Form>
            <div className="modal modal-sm active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">Filteren van personen</div>
                </div>

                <div className="modal-body">
                  <FormGroup name="day_start" title="Vanaf datum" errors={fp.errors}>
                    <SpectreCalendar name="day_start" fp={fp} />
                  </FormGroup>
                  <FormGroup name="day_end" title="Tot datum" errors={fp.errors}>
                    <SpectreCalendar name="day_start" fp={fp} />
                  </FormGroup>

                  <FormGroup title="Types" name="kinds">
                    <FormCheckbox
                      name="kinds"
                      items={[
                        { title: 'Kassaverkoop', value: 'sale' },
                        { title: 'Levering', value: 'delivered' },
                        { title: 'Retour', value: 'cancellation' },
                        { title: 'Klantcompensatie', value: 'compensation' },
                      ]}
                    />
                  </FormGroup>

                  <FormGroup title="Deelnemer(s)" name="tenants">
                    <SpectreAutocomplete
                      name="tenants"
                      url="/core/contact/tenant/autocomplete"
                      fp={fp}
                      multiple
                      render={record => {
                        return (
                          <div>
                            ({record.value}) <strong>{record.title}</strong>
                          </div>
                        );
                      }}
                    />
                  </FormGroup>

                  <FormGroup title="Sorteren" name="sorting">
                    <FormSelect name="sorting">
                      <option value="created_at-asc">Aanmaakmoment (nieuwste eerst)</option>
                      <option value="created_at-desc">Aanmaakmoment (oudste eerst)</option>
                    </FormSelect>
                  </FormGroup>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary">
                        <i className="fal fa-search" /> Filteren
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
