import * as React from 'react';
import { PageVacancyManageCtx, usePageVacancyManageRxjs } from './PageVacancyManageStore';
import ModalLocationEdit from './ModalLocationEdit';
import ModalLocationCreate from './ModalLocationCreate';
import ModalLocationArchive from './ModalLocationArchive';
import SpectreCardHeader from '../../../components/spectre/SpectreCardHeader';
import SpectreButton from '../../../components/spectre/SpectreButton';
import ModalDepartmentCreate from './ModalDepartmentCreate';
import ModalDepartmentEdit from './ModalDepartmentEdit';
import ModalDepartmentArchive from './ModalDepartmentArchive';
import ModalSourceCreate from './ModalSourceCreate';
import ModalSourceEdit from './ModalSourceEdit';
import ModalSourceArchive from './ModalSourceArchive';
import ModalTagCreate from './ModalTagCreate';
import ModalTagEdit from './ModalTagEdit';
import ModalTagArchive from './ModalTagArchive';
import ModalLeaderCreate from './ModalLeaderCreate';
import ModalLeaderEdit from './ModalLeaderEdit';
import ModalLeaderArchive from './ModalLeaderArchive';
import ModalMailArchive from './ModalMailArchive';
import ModalMailEdit from './ModalMailEdit';
import ModalMailCreate from './ModalMailCreate';

export default function PageVacancyManage() {
  const { store, context } = usePageVacancyManageRxjs();

  if (!store || !context) return null;

  return (
    <PageVacancyManageCtx.Provider value={{ store, context }}>
      <div className="container">
        <div className="columns">
          <div className="column col-4">
            <div className="mb-2">
              <ManageLocations />
            </div>
            <div className="mb-2">
              <ManageTags />
            </div>
            <div className="mb-2">
              <ManageSources />
            </div>
          </div>
          <div className="column col-4">
            <div className="mb-2">
              <ManageDepartments />
            </div>
          </div>
          <div className="column col-4">
            <div className="mb-2">
              <ManageMails />
            </div>
            <div className="mb-2">
              <ManageLeaders />
            </div>
          </div>
        </div>

        <ManageModals />
      </div>
    </PageVacancyManageCtx.Provider>
  );
}

function ManageModals() {
  const { store, context } = React.useContext(PageVacancyManageCtx);
  const mctx = context.modal;

  if (!mctx) return null;

  return (
    <React.Fragment>
      {mctx.which === 'location-create' && <ModalLocationCreate {...mctx.data} />}
      {mctx.which === 'location-edit' && <ModalLocationEdit {...mctx.data} />}
      {mctx.which === 'location-archive' && <ModalLocationArchive {...mctx.data} />}

      {mctx.which === 'department-create' && <ModalDepartmentCreate {...mctx.data} />}
      {mctx.which === 'department-edit' && <ModalDepartmentEdit {...mctx.data} />}
      {mctx.which === 'department-archive' && <ModalDepartmentArchive {...mctx.data} />}

      {mctx.which === 'mail-create' && <ModalMailCreate {...mctx.data} />}
      {mctx.which === 'mail-edit' && <ModalMailEdit {...mctx.data} />}
      {mctx.which === 'mail-archive' && <ModalMailArchive {...mctx.data} />}

      {mctx.which === 'source-create' && <ModalSourceCreate {...mctx.data} />}
      {mctx.which === 'source-edit' && <ModalSourceEdit {...mctx.data} />}
      {mctx.which === 'source-archive' && <ModalSourceArchive {...mctx.data} />}

      {mctx.which === 'tag-create' && <ModalTagCreate {...mctx.data} />}
      {mctx.which === 'tag-edit' && <ModalTagEdit {...mctx.data} />}
      {mctx.which === 'tag-archive' && <ModalTagArchive {...mctx.data} />}

      {mctx.which === 'leader-create' && <ModalLeaderCreate {...mctx.data} />}
      {mctx.which === 'leader-edit' && <ModalLeaderEdit {...mctx.data} />}
      {mctx.which === 'leader-archive' && <ModalLeaderArchive {...mctx.data} />}
    </React.Fragment>
  );
}

function ManageLocations() {
  const { store, context } = React.useContext(PageVacancyManageCtx);

  function openLocationCreate() {
    store.openModal({ which: 'location-create', data: {} });
  }

  return (
    <div className="card">
      <SpectreCardHeader
        title="Locaties"
        subtitle="Beheer hier de locaties van de vacatures."
        buttons={[
          <SpectreButton
            icon="fa-plus"
            onClick={openLocationCreate}
            tooltip={{ title: 'Nieuwe locatie toevoegen', position: 'left' }}
          />,
        ]}
      />
      <div className="card-body">
        <table className="table">
          <tbody>
            {context.locations.map(location => {
              function openLocationArchive() {
                store.openModal({ which: 'location-archive', data: { location } });
              }
              function openLocationEdit() {
                store.openModal({ which: 'location-edit', data: { location } });
              }

              return (
                <tr key={location.id}>
                  <td>{location.name}</td>
                  <td className="has-table-shrink-nowrap">
                    <SpectreButton
                      icon="fa-trash"
                      onClick={openLocationArchive}
                      tooltip={{ title: 'Locatie archiveren.', position: 'left' }}
                    />
                    <SpectreButton
                      icon="fa-pencil"
                      onClick={openLocationEdit}
                      tooltip={{ title: 'Locatie aanpassen.', position: 'left' }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function ManageDepartments() {
  const { store, context } = React.useContext(PageVacancyManageCtx);

  function openDepartmentCreate() {
    store.openModal({ which: 'department-create', data: {} });
  }

  return (
    <div className="card">
      <SpectreCardHeader
        title="Afdelingen"
        subtitle="Beheer hier de afdelingen van de vacatures."
        buttons={[
          <SpectreButton
            icon="fa-plus"
            onClick={openDepartmentCreate}
            tooltip={{ title: 'Nieuwe afdeling toevoegen', position: 'left' }}
          />,
        ]}
      />
      <div className="card-body">
        <table className="table">
          <tbody>
            {context.departments.map(department => {
              function openDepartmentArchive() {
                store.openModal({ which: 'department-archive', data: { department } });
              }
              function openDepartmentEdit() {
                store.openModal({ which: 'department-edit', data: { department } });
              }

              return (
                <tr key={department.id}>
                  <td>{department.name}</td>
                  <td className="has-table-shrink-nowrap">
                    <SpectreButton
                      icon="fa-trash"
                      onClick={openDepartmentArchive}
                      tooltip={{ title: 'Afdeling archiveren.', position: 'left' }}
                    />
                    <SpectreButton
                      icon="fa-pencil"
                      onClick={openDepartmentEdit}
                      tooltip={{ title: 'Afdeling aanpassen.', position: 'left' }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function ManageMails() {
  const { store, context } = React.useContext(PageVacancyManageCtx);

  function openMailCreate() {
    store.openModal({ which: 'mail-create', data: {} });
  }

  return (
    <div className="card">
      <SpectreCardHeader
        title="Mails"
        subtitle="Beheer hier de mails die verstuurd kunnen worden naar de sollicitanten."
        buttons={[
          <SpectreButton
            icon="fa-plus"
            onClick={openMailCreate}
            tooltip={{ title: 'Nieuwe mail toevoegen', position: 'left' }}
          />,
        ]}
      />
      <div className="card-body">
        <table className="table">
          <tbody>
            {context.mails.map(mail => {
              function openMailArchive() {
                store.openModal({ which: 'mail-archive', data: { mail } });
              }
              function openMailEdit() {
                store.openModal({ which: 'mail-edit', data: { mail } });
              }

              return (
                <tr key={mail.id}>
                  <td>{mail.title}</td>
                  <td className="has-table-shrink-nowrap">
                    <SpectreButton
                      icon="fa-trash"
                      onClick={openMailArchive}
                      tooltip={{ title: 'Mail archiveren.', position: 'left' }}
                    />
                    <SpectreButton
                      icon="fa-pencil"
                      onClick={openMailEdit}
                      tooltip={{ title: 'Mail aanpassen.', position: 'left' }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function ManageSources() {
  const { store, context } = React.useContext(PageVacancyManageCtx);

  function openSourceCreate() {
    store.openModal({ which: 'source-create', data: {} });
  }

  return (
    <div className="card">
      <SpectreCardHeader
        title="Bronnen"
        subtitle="Beheer hier de bronnen van de sollicitanten."
        buttons={[
          <SpectreButton
            icon="fa-plus"
            onClick={openSourceCreate}
            tooltip={{ title: 'Nieuwe bron toevoegen', position: 'left' }}
          />,
        ]}
      />
      <div className="card-body">
        <table className="table">
          <tbody>
            {context.sources.map(source => {
              function openSourceArchive() {
                store.openModal({ which: 'source-archive', data: { source } });
              }
              function openSourceEdit() {
                store.openModal({ which: 'source-edit', data: { source } });
              }

              return (
                <tr key={source.id}>
                  <td>{source.name}</td>
                  <td className="has-table-shrink-nowrap">
                    <SpectreButton
                      icon="fa-trash"
                      onClick={openSourceArchive}
                      tooltip={{ title: 'Bron archiveren.', position: 'left' }}
                    />
                    <SpectreButton
                      icon="fa-pencil"
                      onClick={openSourceEdit}
                      tooltip={{ title: 'Bron aanpassen.', position: 'left' }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function ManageTags() {
  const { store, context } = React.useContext(PageVacancyManageCtx);

  function openTagCreate() {
    store.openModal({ which: 'tag-create', data: {} });
  }

  return (
    <div className="card">
      <SpectreCardHeader
        title="Tags"
        subtitle="Beheer hier de tags die je kunt toekennen aan je sollicitaties."
        buttons={[
          <SpectreButton
            icon="fa-plus"
            onClick={openTagCreate}
            tooltip={{ title: 'Nieuwe tag toevoegen', position: 'left' }}
          />,
        ]}
      />
      <div className="card-body">
        <table className="table">
          <tbody>
            {context.tags.map(tag => {
              function openTagArchive() {
                store.openModal({ which: 'tag-archive', data: { tag } });
              }
              function openTagEdit() {
                store.openModal({ which: 'tag-edit', data: { tag } });
              }

              return (
                <tr key={tag.id}>
                  <td>
                    <span className="chip">
                      <figure className="avatar avatar-xs" style={{ backgroundColor: `#${tag.color}` }} /> {tag.name}
                    </span>
                  </td>
                  <td className="has-table-shrink-nowrap">
                    <SpectreButton
                      icon="fa-trash"
                      onClick={openTagArchive}
                      tooltip={{ title: 'Bron archiveren.', position: 'left' }}
                    />
                    <SpectreButton
                      icon="fa-pencil"
                      onClick={openTagEdit}
                      tooltip={{ title: 'Bron aanpassen.', position: 'left' }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function ManageLeaders() {
  const { store, context } = React.useContext(PageVacancyManageCtx);

  function openLeaderCreate() {
    store.openModal({ which: 'leader-create', data: {} });
  }

  return (
    <div className="card">
      <SpectreCardHeader
        title="Leaders"
        subtitle="Beheer hier de leaders voor in we WebShop."
        buttons={[
          <SpectreButton
            icon="fa-plus"
            onClick={openLeaderCreate}
            tooltip={{ title: 'Nieuwe leader toevoegen', position: 'left' }}
          />,
        ]}
      />
      <div className="card-body">
        <table className="table">
          <tbody>
            {context.leaders.map(leader => {
              const department = context.departments.find(x => x.id === leader.department_id);
              const location = context.locations.find(x => x.id === leader.location_id);

              function openLeaderArchive() {
                store.openModal({ which: 'leader-archive', data: { leader } });
              }
              function openLeaderEdit() {
                store.openModal({ which: 'leader-edit', data: { leader } });
              }

              return (
                <tr key={leader.id}>
                  <td className="has-table-shrink-nowrap">
                    <div className="flex-row">
                      <div>
                        <figure className="avatar avatar-xl" style={{ backgroundColor: '#00796b' }}>
                          <img src={leader.resource.url} alt={leader.name} />
                        </figure>
                      </div>
                      <div className="flex-grow  ml-2">{leader.name}</div>
                    </div>
                    {!(department || location) && (
                      <div className="mt-2">
                        <span className="chip chip-sm">terugvallen op deze leader</span>
                      </div>
                    )}
                    {(department || location) && (
                      <div className="mt-2">
                        {department && <span className="chip chip-sm">{department.name}</span>}
                        {location && <span className="chip chip-sm">{location.name}</span>}
                      </div>
                    )}
                  </td>
                  <td className="has-table-shrink-nowrap">
                    <SpectreButton
                      icon="fa-trash"
                      onClick={openLeaderArchive}
                      tooltip={{ title: 'Leader archiveren.', position: 'left' }}
                    />
                    <SpectreButton
                      icon="fa-pencil"
                      onClick={openLeaderEdit}
                      tooltip={{ title: 'Leader aanpassen.', position: 'left' }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
