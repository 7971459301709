import * as React from 'react';
import { Form, Formik } from 'formik';
import FormGroup from '../../../components/FormGroup';
import FormSelect from '../../../components/FormSelect';
import SpectreAutocomplete from '../../../components/spectre/SpectreAutocomplete';
import FormInput from '../../../components/FormInput';
import FormCheckbox from '../../../components/FormCheckbox';

export type ModalFilterValues = {
  name: string;
  status: string;
  anonymized: string;
  lead_time: string[];
  jobs: string[];
  locations: string[];
  departments: string[];
  tags: string[];

  sorting: string;
};

type Props = {
  initial: ModalFilterValues;
  closeModal: (values?: ModalFilterValues) => void;
};

export default function ModalFilter({ initial, closeModal }: Props) {
  async function onSubmit(values: ModalFilterValues) {
    closeModal(values);
  }

  return (
    <Formik
      initialValues={initial}
      onSubmit={onSubmit}
      render={fp => {
        return (
          <Form>
            <div className="modal modal-sm active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">Filteren van sollicitanten</div>
                </div>

                <div className="modal-body">
                  <FormGroup title="Zoeken op naam" name="name">
                    <FormInput name="name" />
                  </FormGroup>
                  <FormGroup title="Status" name="status">
                    <FormSelect name="status">
                      <option value="">- niet filteren -</option>

                      <option value="bundle_active">Bundel - Actief</option>
                      <option value="bundle_closed">Bundel - Gesloten</option>

                      <option value="pending">Wachtlijst</option>
                      <option value="interview">Interview (rondes)</option>
                      <option value="negotiations">Onderhandeling</option>
                      <option value="employed">Aangenomen</option>
                      <option value="rejected">Afgewezen</option>

                      <option value="bundle_all">Bundel - Alles</option>
                    </FormSelect>
                  </FormGroup>
                  <FormGroup title="Anonimisering" name="anonymized">
                    <FormSelect name="anonymized">
                      <option value="-1">- niet filteren -</option>
                      <option value="1">Geanonimiseerd</option>
                      <option value="0">Gepersonaliseerd</option>
                    </FormSelect>
                  </FormGroup>
                  <FormGroup title="Doorlooptijd" name="lead_time">
                    <FormCheckbox
                      name="lead_time"
                      items={[
                        { title: 'Tussen vandaag en 1 week', value: 'week_0_and_1' },
                        { title: 'Tussen 1 en 2 weken', value: 'week_1_and_2' },
                        { title: 'Tussen 2 en 3 weken', value: 'week_2_and_3' },
                        { title: 'Langer dan 3 weken', value: 'week_3_and_up' },
                      ]}
                    />
                  </FormGroup>
                  <FormGroup title="Vacature(s)" name="jobs">
                    <SpectreAutocomplete
                      name="jobs"
                      url="/core/vacancy/job/autocomplete"
                      fp={fp}
                      multiple
                      allowEmptySearch
                      render={record => {
                        return (
                          <div>
                            <div>
                              <strong>{record.title}</strong>
                            </div>
                            {(record.department || record.location) && (
                              <div>
                                {record.department && record.location ? (
                                  <small>
                                    {record.department} in {record.location}
                                  </small>
                                ) : (
                                  <small>
                                    {record.department}
                                    {record.location}
                                  </small>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                  </FormGroup>
                  <FormGroup title="Locatie(s)" name="locations">
                    <SpectreAutocomplete
                      name="locations"
                      url="/core/vacancy/location/autocomplete"
                      fp={fp}
                      multiple
                      allowEmptySearch
                    />
                  </FormGroup>
                  <FormGroup title="Afdeling(en)" name="departments">
                    <SpectreAutocomplete
                      name="departments"
                      url="/core/vacancy/department/autocomplete"
                      fp={fp}
                      multiple
                      allowEmptySearch
                    />
                  </FormGroup>

                  <FormGroup title="Sorteren" name="sorting">
                    <FormSelect name="sorting">
                      <option value="lead_time-desc">Doorlooptijd (oudste eerst)</option>
                      <option value="lead_time-asc">Doorlooptijd (nieuwste eerst)</option>
                      <option value="name-asc">Naam (A -&gt; Z)</option>
                      <option value="name-desc">Naam (Z -&gt; A)</option>
                    </FormSelect>
                  </FormGroup>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary" disabled={fp.isSubmitting}>
                        <i className="fal fa-search" /> Filteren
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
