import * as React from 'react';
import { http } from '../../../http';
import axios, { AxiosError } from 'axios';
import { useTimeoutEffect } from '../../../effects';
import { ProfileService } from '../../../services/ProfileService';
import { MessageService } from '../../../services/MessageService';
import { LocationService } from '../../../services/LocationService';

type State = {
  loading: boolean;
};

type Props = {
  person: ModelContactPerson;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalTakeover(props: Props) {
  const [state, setState] = React.useState({ loading: false });

  async function takeover() {
    setState({ loading: true });

    try {
      const result = await http().post('/dmz/security/takeover', { person: props.person.id });

      axios.defaults.headers['x-session'] = result.data.session.token;

      ProfileService.setProfile({
        person: result.data.person,
        session: result.data.session,
        takeback: ProfileService.current().person,
      });

      MessageService.setMessage(`Je hebt het profiel van ${result.data.person.name} overgenomen.`, 'success', 3000);
      LocationService.navigate('/');
    } catch (exc) {
      const err: AxiosError = exc;
      setState({ loading: false });
    }
  }

  useTimeoutEffect(() => {
    (async () => {
      await takeover();
    })();
  }, 1000);

  return (
    <div className="modal active" id="ModalTakeover">
      <div className="modal-overlay" />

      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Profiel overnemen {props.person.name}</div>
        </div>
        <div className="modal-body">
          Het profiel van {props.person.name} wordt overgenomen, dit kan heel even duren.
        </div>
        <div className="modal-footer">
          <button className="btn btn-link  float-left" onClick={() => props.closeModal()} disabled={state.loading}>
            <i className="fal fa-times" /> Annuleren
          </button>
        </div>
      </div>
    </div>
  );
}
