import * as React from 'react';
import { http } from '../../../http';
import { AxiosError } from 'axios';
import { cs } from '../../../utils';

type State = {
  loading: boolean;
};

type Props = {
  printerId: number;
  onClose: (success?: boolean) => void;
};

export default function ModalDeleteTicketPrinter(props: Props) {
  const [state, setState] = React.useState({ loading: false });

  async function sendData() {
    setState({ loading: true });

    try {
      const result = await http().post('/core/device/ticket_printer/delete', { id: props.printerId });

      props.onClose(true);
    } catch (exc) {
      const err: AxiosError = exc;

      setState({ loading: false });
    }
  }

  function onClick(event: React.MouseEvent) {
    event.preventDefault();
    sendData();
  }

  return (
    <div className="modal active" id="modal-id">
      <div className="modal-overlay" />

      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Verwijderen Ticket Printer</div>
        </div>
        <div className="modal-body">
          <strong>Weet je zeker dat je deze printer wilt verwijderen?</strong>
          <br />
          Het verwijderen zal de printer niet deactiveren, dat is een manuele actie.
        </div>
        <div className="modal-footer">
          <button className="btn btn-link  float-left" onClick={() => props.onClose()}>
            <i className="fal fa-times" /> Annuleren
          </button>

          <button type="submit" className="btn btn-error  float-right" disabled={state.loading} onClick={onClick}>
            <i className={cs('fal fa-fw', state.loading ? 'fa-spinner fa-spin' : 'fa-trash-alt')} /> Verwijderen
          </button>
        </div>
      </div>
    </div>
  );
}
