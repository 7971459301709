import * as moment from 'moment';
import { Moment } from 'moment';
import * as Draft from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import Ticket from './lib/Ticket';
import { getGlobalProfileData } from './services/ProfileService';
import { http } from './http';
import { AxiosRequestConfig } from 'axios';
import * as _ from 'underscore';

moment.locale('nl');

declare type ClassValue = string | undefined | null | false | true | string[];

export type StringObject = { [name: string]: string };

export function jsonEquals(a: any, b: any) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function sleep(ms: number): Promise<null> {
  return new Promise(r => setTimeout(r, ms));
}

export function cs(...classes: ClassValue[]) {
  return classes
    .filter(x => Array.isArray(x) || typeof x === 'string')
    .map(x => (Array.isArray(x) ? cs(...x) : x))
    .join(' ');
}

export function getTouchedErrors(touched: any, errors: any): StringObject {
  return Object.keys(touched)
    .filter(x => x)
    .reduce((result: { [name: string]: string }, key: string) => {
      const error = (errors as any)[key];

      if (error) {
        return { ...result, [key]: error };
      }

      return result;
    }, {});
}

export function formatDate(value: string | moment.Moment, format: string = 'L', fallback: string = '') {
  const parsed = parseMoment(value);

  if (parsed && parsed.isValid()) {
    return parsed.format(format);
  }

  return fallback;
}

interface DraftValue {
  raw: Draft.RawDraftContentState;
  html: string;
}

export function draftToValue(editorState: Draft.EditorState) {
  const current = editorState.getCurrentContent();
  const raw = Draft.convertToRaw(current);

  return [raw, stateToHTML(current)];
}

export function valueToDraft(json: string, html: string) {
  if (!json) {
    const blocks = Draft.convertFromHTML(html);

    if (blocks === null || blocks.contentBlocks === null) {
      return Draft.EditorState.createEmpty();
    }

    return Draft.EditorState.createWithContent(
      Draft.ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap),
    );
  }
  return Draft.EditorState.createWithContent(Draft.convertFromRaw(JSON.parse(json)));
}

export function getThumbnailOfSize(image: CdnImageModal, constraint: number) {
  function getSize() {
    const sizes = Object.keys(image.thumbnails)
      .map(x => parseInt(x, 10))
      .sort((a, b) => a - b);

    for (const size of sizes) {
      if (size >= constraint) {
        return size;
      }
    }

    return sizes[sizes.length - 1];
  }

  return image.thumbnails[getSize().toString()];
}

export function parseCentedValue(value: number): [string, string] {
  if (value == null) {
    return ['0', '00'];
  }

  const parsed: number = parseInt(value.toString(), 10);
  if (isNaN(parsed)) {
    return ['0', '00'];
  }

  const isNegative = value < 0;
  const whole: number = Math.floor(Math.abs(value) / 100);
  let cents: string = (Math.abs(value) - whole * 100).toString();

  if (cents.length === 1) {
    cents = '0' + cents;
  }

  let wholeFormatted = whole.toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$&.');

  if (isNegative) {
    wholeFormatted = '-' + wholeFormatted;
  }

  return [wholeFormatted, cents];
}

export function formatCents(value: number, withoutPrefix: boolean = false, short: boolean = false): string {
  let [whole, cents] = parseCentedValue(value);
  const prefix = withoutPrefix ? '' : '€ ';

  if (cents === '00' && short) {
    cents = '-';
  }

  return prefix + whole + ',' + cents;
}

export function formatCentsShort(value: number, withoutPrefix: boolean = false): string {
  return formatCents(value, withoutPrefix, true);
}

export function formatSeconds(value: number) {
  const minutes = Math.floor(value / 60);
  const seconds = Math.floor(value) - minutes * 60;

  return `${minutes}:${seconds < 10 ? '0' + seconds.toString() : seconds}`;
}

export function formatPercentage(value: number, withoutAffix: boolean = false, short: boolean = true): string {
  let [whole, cents] = parseCentedValue(value);
  const affix = withoutAffix ? '' : ' %';

  if (short) {
    cents = cents.replace(/0+$/, '');
    if (cents === '') {
      return whole + affix;
    }
  }

  return whole + ',' + cents + affix;
}

export function formatEan13Number(value: string): string {
  const parts = [value.slice(0, 1), value.slice(1, 5), value.slice(5, 8), value.slice(8, 11), value.slice(11)];

  return parts.join(' ');
}

export function formatGiftcardIdentifier(value: string, muted: boolean = false): string {
  const parts = [value.slice(0, 4), value.slice(4, 8), value.slice(8, 12), value.slice(12, 16), value.slice(16, 19)];

  if (muted) {
    parts[1] = '*'.repeat(4);
    parts[2] = '*'.repeat(4);
    parts[3] = '*'.repeat(4);
  }

  return parts.join(' ');
}

export function formatLineState(value: string, is_header: boolean = false) {
  if (value === 'many') return is_header ? 'Alle verkopen' : 'Dit is vreemd';
  if (value === 'open') return is_header ? 'Nieuwe orders' : 'Nieuw';
  if (value === 'processed') return 'In behandeling';
  if (value === 'ready') return 'Gereed';
  if (value === 'delivery') return 'Onderweg';
  if (value === 'delivered') return 'Geleverd';
  if (value === 'retour') return 'Retour';
  if (value === 'cancelled') return 'Gecrediteerd';

  return value;
}

export function formatMomentAgo(
  value: string | Moment,
  now?: string | Moment,
  resolution: 'full' | 'days' | 'weeks' = 'full',
) {
  let val = parseMoment(value);

  if (!val || !val.isValid()) {
    return '';
  }

  val = val.startOf('day');
  now = (now ? parseMoment(now) : moment()).clone().startOf('day');
  const days = val.diff(now, 'days');
  const weeks = val.diff(now, 'weeks');

  if (resolution === 'days') {
    return days === 1 ? '1 dag' : `${days} dagen`;
  } else if (resolution === 'weeks') {
    if (weeks === 0 && days > 0) {
      return days === 1 ? '1 dag' : `${days} dagen`;
    }

    return weeks === 1 ? '1 week' : `${weeks} weken`;
  }

  if (days === 0) {
    return 'vandaag';
  } else if (days === 1) {
    return '1 dag';
  } else if (weeks === 0) {
    return `${days} dagen`;
  }

  const subdays = days - weeks * 7;
  const result = `${weeks} ${weeks === 1 ? 'week' : 'weken'}`;

  if (subdays === 0) {
    return result;
  }

  return `${result}, ${subdays} ${subdays === 1 ? 'dag' : 'dagen'}`;
}

export function formatInvoiceSettlementNumber(invoice: ModelFinanceInvoiceSettlement) {
  const y = invoice.year;
  const w = invoice.week;
  const b = invoice.building_id.toString().padStart(2, '0');
  const t = invoice.tenant_id.toString().padStart(4, '0');
  const i = invoice.id.toString().padStart(5, '0');

  return `${y}${w}-${b}-${t}-${i}`;
}

export function parseMoment(value: string | moment.Moment | null): Moment {
  if (value === null) return null;
  return moment(value, ['YYYY-MM-DDTHH:mm:ss.SSSS', 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'DD-MM-YYYY']);
}

export function parseCents(value: string): number {
  if (value === undefined) {
    return 0;
  }

  let negative = value.indexOf('-') === 0;

  value = value.replace(/[^\d,.]+/g, '');

  let rx = /[.,](\d{1,2})$/;
  let cents = '00';
  let match = rx.exec(value);

  if (match) {
    cents = match[1];
    if (cents.length === 1) {
      cents = `${cents}0`;
    }
  }

  let result = value.replace(rx, '').replace(/[,.]/g, '') + cents;
  let parsed = parseInt(/^\d+$/.exec(result) ? result : '0');

  return negative ? parsed * -1 : parsed;
}

export function calculateVat(inclusive: number, vat_percentage: number): [number, number] {
  let exclusive = 0;
  let vat = 0;

  if (vat_percentage === 0) {
    return [inclusive, 0];
  }

  let percentage = vat_percentage / 100 + 1;

  vat = Math.round(inclusive - inclusive / percentage);
  exclusive = Math.round(inclusive - vat);

  return [exclusive, vat];
}

export function calculateInclusive(exclusive: number, vat_percentage: number): [number, number] {
  let inclusive = 0;
  let vat = 0;

  if (vat_percentage === 0) {
    return [exclusive, 0];
  }

  let percentage = vat_percentage / 100 + 1;

  vat = Math.round(exclusive * percentage - exclusive);
  inclusive = Math.round(exclusive + vat);

  return [inclusive, vat];
}

export function wrapText(value: string, width: number = 42, indent: number = 0): string {
  const words = value.trim().split(' ');
  const lines = [];
  let line = [];

  for (const word of words) {
    if (line.concat(word).join(' ').length > width) {
      lines.push(' '.repeat(indent) + line.join(' '));
      line = [];
    }
    line.push(word);
  }

  if (line.length) {
    lines.push(' '.repeat(indent) + line.join(' '));
  }

  return lines.join('\n');
}

export function playSoundPositive() {
  new Audio(require('./assets/sounds/positive.wav')).play();
}

export function playSoundNegative() {
  new Audio(require('./assets/sounds/negative.wav')).play();
}

export async function buildCartTicket(
  cart: ModelShopCart,
  lines: ModelShopCartLine[],
  giftcards_payment: ModelShopCartGiftcardPayment[],
  giftcards_purchase: ModelShopCartGiftcardPurchase[],
  client_ticket: string,
): Promise<Ticket> {
  const ticket = new Ticket();
  const profile = getGlobalProfileData();
  const now = moment().format('L LT');

  ticket.addHeader();

  ticket.addLongLabel(`Datum: ${now}`, `Bon: ${cart.id.toString().padStart(8)}`);
  ticket.addLine(`Verkoper: ${profile.person.first}`);
  if (cart.number_buzzer) {
    ticket.addLine(`Buzzer  : ${cart.number_buzzer}`);
  }
  ticket.addLine('');
  ticket.addLine('--------');
  ticket.addLine('');

  let inclusive = 0;
  let discount = 0;

  for (const giftcard_purchase of giftcards_purchase) {
    const giftcard = giftcard_purchase.giftcard;
    ticket.addLongLabel('Loods 5 Cadeaukaart - Saldo', formatCents(giftcard_purchase.balance));
    ticket.addLine('    ' + formatGiftcardIdentifier(giftcard.identifier, true));

    if (giftcard_purchase.disclaimer) {
      ticket.addWrappedText(giftcard_purchase.disclaimer, 4);
    }

    inclusive += giftcard_purchase.balance;
  }

  for (const line of lines) {
    if (line.amount > 1) {
      ticket.addArticleLine(line.article.number_beeyond, line.article.name, '');
      ticket.addArticlePriceLine(line.amount, line.inclusive);
    } else {
      ticket.addArticleLine(line.article.number_beeyond, line.article.name, formatCents(line.inclusive));
    }

    if (line.configuration) {
      ticket.addWrappedText(line.configuration, 4);
    }
    if (line.manual_discount_value !== 0) {
      if (line.manual_discount_description) {
        ticket.addWrappedText('Reden van korting: ' + line.manual_discount_description, 4);
      } else {
        ticket.addWrappedText('Reden van korting: ONBEKEND.', 4);
      }
    }

    inclusive += (line.original_inclusive + line.manual_increase_price) * line.amount;
    discount += line.discount_price * line.discount_amount + line.manual_discount_price * line.amount;
  }

  ticket.addLine('--------', 'right');

  ticket.addShortLabel(`TOTAAL ${cart.amount} STUKS`, formatCents(inclusive));
  if (discount > 0) {
    ticket.addShortLabel(`Korting`, formatCents(discount));
  }

  ticket.addLine('');

  if ((cart.paid === 0 && cart.deposit !== cart.inclusive) || cart.paid !== cart.inclusive) {
    ticket.addShortLabel('TE BETALEN', formatCents(cart.deposit));
    ticket.addShortLabel('Later te voldoen', formatCents(cart.inclusive - cart.deposit));
    ticket.addShortLabel('TOTAAL', formatCents(cart.inclusive));
  } else {
    ticket.addShortLabel('TE BETALEN', formatCents(cart.inclusive));
  }
  ticket.addSmallBar(true, 'right');

  ticket.addLine('');
  [0, 6, 9, 21]
    .map(vp => [vp, cart[`vat_${vp}`]])
    .filter(v => v[1])
    .forEach(([vp, v]) => {
      ticket.addShortLabel(`BTW ${vp}%`, formatCents(v));
    });

  ticket.addLine('');

  if (giftcards_payment.length) {
    ticket
      .addLine('')
      .addSmallBar(true)
      .addLine('Gebruikte Loods 5 cadeaukaart(en)')
      .addLine('');

    for (const giftcard_payment of giftcards_payment) {
      const giftcard = giftcard_payment.giftcard;
      ticket.addLongLabel('Loods 5 Cadeaukaart - Rest saldo', formatCents(giftcard_payment.balance_after));
      ticket.addLine('   ' + formatGiftcardIdentifier(giftcard.identifier, true));
    }
  }

  await ticket.addFooter(cart.number_ean13);
  ticket.cutPaper();

  if (client_ticket) {
    ticket
      .addLine('')
      .addClientTicket(client_ticket)
      .addLine('')
      .cutPaper();
  }

  return ticket;
}

export async function buildKitchetTicket(cart: ModelShopCart, lines: ModelShopCartLine[]): Promise<Ticket> {
  const ticket = new Ticket();
  const profile = getGlobalProfileData();
  const now = moment().format('L LT');

  ticket.addLongLabel(`Datum: ${now}`, `Bon: ${cart.id.toString().padStart(8)}`);
  ticket.addLine(`Medewerker: ${profile.person.first}`);
  ticket.addLine('');
  ticket
    .addBar(false)
    .addLine('')
    .addLine('KEUKEN', 'center')
    .addLine('')
    .addBar(false)
    .addLine('');

  for (const line of lines) {
    if (!line.article.is_special_kitchen) {
      continue;
    }

    for (let x = 0; x < line.amount; x += 1) {
      ticket.addLine(line.article.name);
      if (line.configuration) {
        ticket.addWrappedText(line.configuration, 4);
      }
    }
  }

  ticket
    .addLine('')
    .addLine(`BUZZER ${cart.number_buzzer}`, 'center')
    .addLine('')
    .addBar(false)
    .addLine('')
    .addLine('');

  ticket.cutPaper();

  return ticket;
}

export async function buildReverseTicket(
  cart_id: number,
  reverse: ModelOrderReverse,
  lines: ModelOrderPurchaseLine[],
  active_lines: number[],
  client_ticket: string,
): Promise<Ticket> {
  const ticket = new Ticket();
  const profile = getGlobalProfileData();
  const now = moment().format('L LT');

  ticket.addHeader();

  ticket.addLongLabel(`Datum: ${now}`, `Bon: ${cart_id.toString().padStart(8)}`);
  ticket.addLine(`Balie: ${profile.person.first}`);
  ticket.addLine('');
  ticket.addLine('--------');
  ticket.addWrappedText('U heeft de volgende artikelen retour gebracht.');
  ticket.addLine('--------');
  ticket.addLine('');

  let amount = 0;

  for (const line of compactOrderPurchaseLines(lines.filter(l => active_lines.indexOf(l.id) !== -1))) {
    if (line.amount > 1) {
      ticket.addArticleLine(line.article.number_beeyond, line.article.name, '');
      ticket.addArticlePriceLine(line.amount, line.inclusive);
    } else {
      ticket.addArticleLine(line.article.number_beeyond, line.article.name, formatCents(line.inclusive));
    }

    amount += line.amount;
  }

  ticket.addLine('--------', 'right');

  const inclusive = reverse.terminal_inclusive + reverse.giftcard_inclusive;

  ticket.addShortLabel(`TOTAAL ${amount} STUKS`, formatCents(inclusive));
  ticket.addLine('');

  ticket.cutPaper();

  if (client_ticket) {
    ticket
      .addLine('')
      .addClientTicket(client_ticket)
      .addLine('')
      .addLine('')
      .addLine('')
      .addLine('')
      .cutPaper();
  }

  return ticket;
}

export function compactOrderPurchaseLines(lines: ModelOrderPurchaseLine[]): ModelOrderPurchaseLineShrunken[] {
  const result: ModelOrderPurchaseLineShrunken[] = [];
  let prev: ModelOrderPurchaseLineShrunken = null;

  for (const line of lines) {
    if (prev && prev.cart_line_id === line.cart_line_id && prev.state === line.state) {
      prev.amount += 1;
      prev.total_compensation += line.compensation_price;
      prev.total_inclusive += line.inclusive;
      prev.line_id_set.push(line.id);
    } else {
      prev = { ...line } as ModelOrderPurchaseLineShrunken;
      prev.amount = 1;
      prev.total_compensation = line.compensation_price;
      prev.total_inclusive = line.inclusive;
      prev.line_id_set = [line.id];

      result.push(prev);
    }
  }

  return result;
}

export function paginationCrunch(pagination: Pagination) {
  const from_page = Math.max(pagination.current_page - 10, 0);
  const offset = pagination.current_page - from_page;
  const till_page = Math.min(pagination.current_page + 20 - offset, pagination.total_pages);

  return Array.from({ length: till_page - from_page }, (v, k) => from_page + k);
}

export function isWithinMz(): boolean {
  const ggd = window.GLOBAL_GENERIC_DATA;
  return !!(ggd && ggd.device && ggd.device.computer);
}

export function getGlobalDevice(): ModalBundleDevice | undefined {
  const ggd = window.GLOBAL_GENERIC_DATA;
  return ggd && ggd.device;
}

export function getCountriesSorted() {
  const ggd = window.GLOBAL_GENERIC_DATA;
  const fixed = ['NL', 'BE', 'DE', 'FR'];

  return _.sortBy(Object.values(ggd.countries), function(v) {
    const ix = _.indexOf(fixed, v.iso2);
    return ix === -1 ? v.name : ix;
  });
}

export function hasRights(rights: string[], some = false): boolean {
  const ps = getGlobalProfileData();
  const op = some ? _.some : _.every;

  if (ps && ps.person && ps.person.rights) {
    return op(rights, x => _.contains(ps.person.rights, x));
  }

  return false;
}

type RandomStringOptions = {
  hasUpper: boolean;
  hasLower: boolean;
  hasNumbers: boolean;
  hasSymbols: boolean;
};

export function randomString(length: number = 8, options?: Partial<RandomStringOptions>): string {
  options = { hasUpper: true, hasLower: true, hasNumbers: true, hasSymbols: true, ...options };
  let result = '';

  const characters =
    (options.hasUpper ? 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.repeat(4) : '') +
    (options.hasLower ? 'abcdefghijklmnopqrstuvwxyz'.repeat(4) : '') +
    (options.hasNumbers ? '0123456789'.repeat(2) : '') +
    (options.hasSymbols ? '!@#$%^&*()_-=+' : '');
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export async function loadOneRecord(url: string, data?: any, config?: AxiosRequestConfig, record_or?: any) {
  try {
    const res = await http().post(url, data, config);
    return res.data;
  } catch (exc) {
    console.error(url, exc);
    return record_or || null;
  }
}

export async function loadManyRecordsOr(url: string, data?: any, config?: AxiosRequestConfig, records_or?: any[]) {
  try {
    const res = await http().post(url, data, config);
    return res.data.records;
  } catch (exc) {
    console.error(url, exc);
    return records_or || [];
  }
}

export async function loadManyRecordsPaginated(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
): Promise<{ pagination: Pagination; records: any[] }> {
  try {
    const res = await http().post(url, data, config);
    return {
      pagination: res.data.pagination,
      records: res.data.records,
    };
  } catch (exc) {
    console.error(url, exc);
    return {
      pagination: null,
      records: [],
    };
  }
}

export function sanitizeFormValues(values: { [name: string]: any }): { [name: string]: any } {
  function sanitize(value) {
    if (value === null || value === undefined) {
      return '';
    }

    if (_.isArray(value)) {
      return value.filter(x => x !== null && x !== undefined).map(sanitize);
    } else if (_.isBoolean(value) || _.isObject(value)) {
      return value;
    }

    return value.toString();
  }

  return _.mapObject(values, (value, key) => {
    return sanitize(value);
  });
}
