import * as React from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { displayError } from '../../../components/FormGroup';
import FormInput from '../../../components/FormInput';
import { cs, getTouchedErrors, parseCents } from '../../../utils';
import { PageDefinition } from './PageState';

type Props = {
  page: PageDefinition;
};

const schema = Yup.object().shape({
  balance: Yup.string().matches(/^[0-9]+([,.][0-9]{2})?$/, 'invalidNumber'),
});

type Values = {
  balance: string;
};

export default function ModalGiftcardPurchase(props: Props) {
  const { context, store } = props.page;
  const card = context.modalGiftcardPurchaseData;

  if (!context.modalGiftcardPurchaseOpen || !card) {
    return null;
  }

  function onClose() {
    store.toggleModalGiftcardPurchase();
  }

  async function onSubmit(values: Values, fp: FormikProps<Values>) {
    if (await store.addGiftcardPurchase(parseCents(values.balance))) {
      store.toggleModalGiftcardPurchase();
    } else {
      fp.setSubmitting(false);
    }
  }

  return (
    <div className="modal active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Cadeaukaart waarde toekennen</div>
          <div className="h6 text-gray">{card.identifier}</div>
        </div>

        <Formik
          initialValues={{ balance: '25' }}
          onSubmit={onSubmit}
          validationSchema={schema}
          render={fp => {
            const errors = getTouchedErrors(fp.touched, fp.errors);
            const { balance } = errors;

            return (
              <Form>
                <div className="modal-body">
                  <div className={cs('form-group', balance && 'has-error')}>
                    <label className="form-label">Waarde van de cadeaukaart</label>
                    <FormInput name="balance" autoFocus />
                    {balance && <div className="form-input-hint">{displayError(balance)}</div>}
                  </div>
                </div>

                <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <button
                    type="button"
                    className="btn btn-link"
                    style={{ alignSelf: 'flex-start' }}
                    onClick={onClose}
                    disabled={fp.isSubmitting}
                  >
                    <i className={cs('fal fa-fw fa-times')} /> Annuleren
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary-outline"
                    disabled={fp.isSubmitting}
                    style={{ alignSelf: 'flex-end' }}
                  >
                    <i className={cs('fal fa-fw', fp.isSubmitting ? 'fa-spin fa-spinner' : 'fa-save')} /> Opslaan
                  </button>
                </div>
              </Form>
            );
          }}
        />
      </div>
    </div>
  );
}
