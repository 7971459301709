import * as React from 'react';
import FormGroup from '../../../components/FormGroup';
import FormInput from '../../../components/FormInput';
import { http } from '../../../http';
import { Form, Formik, FormikActions } from 'formik';
import { AxiosError } from 'axios';
import { getTouchedErrors, randomString } from '../../../utils';

type Props = {
  person: ModelContactPerson;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalContactPersonPasswordSet({ person, closeModal }: Props) {
  async function onSubmit(values: any, formikActions: FormikActions<any>) {
    try {
      await http().post('/core/contact/person/password_set', {
        person: person.id,
        ...values,
      });

      closeModal({ reload: true });
    } catch (exc) {
      const err: AxiosError = exc;
      console.error(exc);

      if (err.response.data && err.response.data.errors) {
        formikActions.setErrors(err.response.data.errors);
      }

      formikActions.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{
        password: randomString(8, { hasUpper: false, hasSymbols: false }),
      }}
      onSubmit={onSubmit}
      render={fp => {
        const errors = getTouchedErrors(fp.touched, fp.errors);

        return (
          <Form>
            <div className="modal modal-sm active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">
                    Wachtwoord instellen bij <strong>{person.first}</strong>
                  </div>
                </div>

                <div className="modal-body">
                  Je gaat nu een wachtwoord instellen voor {person.first}. Het wachtwoord is het onderstaande
                  wachtwoord, deze kun je het beste verspreiden via Daily, of als het moet, via mail.
                </div>

                <div className="modal-body">
                  <FormGroup title="Wachtwoord" name="password" errors={errors}>
                    <FormInput name="password" loading={fp.isSubmitting} />
                  </FormGroup>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={fp.isSubmitting || Object.keys(fp.errors).length > 0}
                      >
                        <i className="fal fa-check" /> Wachtwoord instellen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
