import * as React from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import { http } from '../../../http';
import FormInput from '../../../components/FormInput';
import FormGroup from '../../../components/FormGroup';
import { AxiosError } from 'axios';
import { cs, getTouchedErrors, hasRights, loadManyRecordsOr, sanitizeFormValues } from '../../../utils';
import FormRadio from '../../../components/FormRadio';
import FormOptionSwitch from '../../../components/FormOptionSwitch';
import SpectreAutocomplete from '../../../components/spectre/SpectreAutocomplete';
import FormCheckbox from '../../../components/FormCheckbox';
import * as _ from 'underscore';

type Values = {
  first: string;
  infix: string;
  last: string;
  number_personnel: string;
  number_badge: string;
  mode: string;
  workflows: string[];
  email: string;
  email_private: string;
  mobile: string;
  phone: string;
  is_global: string;
  tenant_set: string[];
  building_set: string[];
  ignore_afas_sync: string;
  option_receive_revenue_weekly: string;
  option_receive_revenue_daily: string;
  option_receive_exports_weekly: string;
};

const schema = Yup.object().shape({
  first: Yup.string()
    .min(3, 'stringMinLength')
    .required('isRequired'),
  infix: Yup.string().max(20, 'stringMaxLength'),
  last: Yup.string()
    .min(3, 'stringMinLength')
    .required('isRequired'),

  mode: Yup.string()
    .oneOf(['loods5-plus', 'loods5-basic', 'external'])
    .required('isRequired'),

  email: Yup.string()
    .email('shouldBeEmail')
    .required('isRequired'),
  email_private: Yup.string().email('shouldBeEmail'),
});

type Props = {
  person: ModelContactPerson;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalContactPersonEdit({ person, closeModal }: Props) {
  const [loaded, setLoaded] = React.useState(false);
  const [workflows, setWorkflows] = React.useState<ModelGenericWorkflow[]>([]);

  React.useEffect(() => {
    (async () => {
      setWorkflows(await loadManyRecordsOr('/core/generic/workflow/many'));
      setLoaded(true);
    })();
  }, []);

  const initialValues = sanitizeFormValues({
    first: person.first,
    infix: person.infix,
    last: person.last,

    number_personnel: person.number_personnel,
    number_badge: person.number_badge,

    mode: person.mode,
    workflows: person.workflows.map(x => x.toString()),
    email: person.email,
    email_private: person.email_private,

    mobile: person.mobile,
    phone: person.phone,

    is_global: person.is_global,
    tenant_set: person.tenant_set.map(x => x.toString()),
    building_set: person.building_set.map(x => x.toString()),

    ignore_afas_sync: person.ignore_afas_sync,
    option_receive_revenue_weekly: person.option_receive_revenue_weekly,
    option_receive_revenue_daily: person.option_receive_revenue_daily,
    option_receive_exports_weekly: person.option_receive_exports_weekly,
  });

  async function onSubmit(values: Values, formikActions: FormikActions<Values>) {
    try {
      await http().post('/core/contact/person/edit', {
        ...values,
        person: person.id,
      });

      closeModal({ reload: true });
    } catch (exc) {
      const err: AxiosError = exc;
      console.error(exc);

      if (err.response.data && err.response.data.errors) {
        formikActions.setErrors(err.response.data.errors);
      }

      formikActions.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      render={fp => <ModalForm person={person} closeModal={closeModal} workflows={workflows} fp={fp} />}
    />
  );
}

type ModalFormProps = {
  workflows: ModelGenericWorkflow[];
  fp: FormikProps<any>;
} & Props;

function ModalForm({ person, workflows, fp, closeModal }: ModalFormProps) {
  const { isSubmitting } = fp;
  const errors = getTouchedErrors(fp.touched, fp.errors);

  return (
    <Form>
      <div className="modal active">
        <div className="modal-overlay" />
        <div className="modal-container">
          <div className="modal-header">
            <div className="modal-title h5">Persoon {person.first} aanpassen</div>
          </div>

          <div className="modal-body">
            <div className="columns">
              <div className="column col-4">
                <FormGroup title="Voornaam" name="first" errors={errors}>
                  <FormInput name="first" loading={isSubmitting} />
                </FormGroup>
              </div>
              <div className="column col-3">
                <FormGroup title="Tussenvoegsel" name="infix" errors={errors}>
                  <FormInput name="infix" loading={isSubmitting} />
                </FormGroup>
              </div>
              <div className="column">
                <FormGroup title="Achternaam" name="last" errors={errors}>
                  <FormInput name="last" loading={isSubmitting} />
                </FormGroup>
              </div>
            </div>
            <div className="columns">
              <div className="column col-4">
                <FormGroup title="Personeelsnummer" name="number_personnel" errors={errors}>
                  <FormInput name="number_personnel" loading={isSubmitting} />
                </FormGroup>
              </div>
              <div className="column col-4">
                <FormGroup title="Badgenummer" name="number_badge" errors={errors}>
                  <FormInput name="number_badge" loading={isSubmitting} />
                </FormGroup>
              </div>
            </div>

            <div className={cs('form-group')}>
              <FormGroup title="Gebruikersmode" name="mode" errors={errors}>
                <FormRadio
                  name="mode"
                  items={[
                    { title: 'Loods 5 Plus - plusgebruiker bij Loods 5.', value: 'loods5-plus' },
                    { title: 'Loods 5 Basic - medewerker bij Loods 5.', value: 'loods5-basic' },
                    { title: 'Extern - geen medewerker bij Loods 5.', value: 'external' },
                  ]}
                />
              </FormGroup>
            </div>

            <div className="columns">
              <div className="column">
                <FormGroup title="E-mailadres" name="email" errors={errors}>
                  <FormInput name="email" loading={isSubmitting} />
                </FormGroup>
              </div>
              {hasRights(['contact.person.rights']) && fp.values.mode !== 'external' && (
                <div className="column">
                  <FormGroup title="Privé e-mailadres" name="email_private" errors={errors}>
                    <FormInput name="email_private" loading={isSubmitting} />
                  </FormGroup>
                </div>
              )}
            </div>

            <div className="columns">
              <div className="column col-4 col-xs-12">
                <FormGroup title="Mobielnummer" name="mobile" errors={errors}>
                  <FormInput name="mobile" loading={isSubmitting} />
                </FormGroup>
              </div>
              <div className="column col-4 col-xs-12">
                <FormGroup title="Telefoonnummer" name="phone" errors={errors}>
                  <FormInput name="phone" loading={isSubmitting} />
                </FormGroup>
              </div>
            </div>

            {hasRights(['contact.person.rights']) && (
              <>
                <FormGroup name="is_global" title="Deelnemertoegang" errors={errors}>
                  <FormOptionSwitch
                    name="is_global"
                    title_on="Mag bij alle deelnemers."
                    title_off="Mag alleen bij aangegeven deelnemers."
                  />
                </FormGroup>

                <FormGroup title="Deelnemer(s)" name="tenant_set" errors={errors}>
                  <SpectreAutocomplete name="tenant_set" url="/core/contact/tenant/autocomplete" fp={fp} multiple />
                </FormGroup>
                <FormGroup title="Vestiging(en)" name="building_set" errors={errors}>
                  <SpectreAutocomplete
                    name="building_set"
                    url="/core/plan/building/autocomplete"
                    fp={fp}
                    multiple
                    allowEmptySearch
                  />
                </FormGroup>

                <FormGroup title="Workflow(s)" name="workflows" errors={errors}>
                  <FormCheckbox
                    name="workflows"
                    items={workflows.map(workflow => ({
                      value: workflow.id.toString(),
                      title: workflow.name,
                    }))}
                  />
                </FormGroup>

                <FormGroup name="ignore_afas_sync" title="Buitensluiten van AFAS synchronisatie" errors={errors}>
                  <FormOptionSwitch
                    name="ignore_afas_sync"
                    title_on="Is uitgesloten van synchronisatie (handmatig aan/uitzetten)."
                    title_off="Doet mee met de AFAS synchronisatie (standaard)."
                  />
                </FormGroup>

                {fp.values.tenant_set.length && (
                  <FormGroup name="option_receive_revenue_weekly" title="Weekstatements ontvangen" errors={errors}>
                    <FormOptionSwitch
                      name="option_receive_revenue_weekly"
                      title_on="Ontvangt van alle gekoppelde deelnemingen de weekstatements."
                      title_off="Ontvangt geen weekstatements."
                    />
                  </FormGroup>
                )}
                {fp.values.tenant_set.length && (
                  <FormGroup name="option_receive_revenue_daily" title="Dagomzet ontvangen" errors={errors}>
                    <FormOptionSwitch
                      name="option_receive_revenue_daily"
                      title_on="Ontvangt van alle gekoppelde deelnemingen de dagomzetten."
                      title_off="Ontvangt geen dagomzetten."
                    />
                  </FormGroup>
                )}
                {fp.values.is_global && (
                  <FormGroup name="option_receive_exports_weekly" title="Week-export (financieel)" errors={errors}>
                    <FormOptionSwitch
                      name="option_receive_exports_weekly"
                      title_on="Ontvangt de wekelijkse financiele Loods 5 export (alle deelnemers, alle winkels)."
                      title_off="Ontvangt geen week-export."
                    />
                  </FormGroup>
                )}
              </>
            )}
          </div>

          <div className="modal-footer">
            <div className="flex-row flex-space-between">
              <div>
                <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                  <i className="fal fa-times" /> Annuleren
                </button>
              </div>
              <div>
                <button type="submit" className="btn btn-primary" disabled={fp.isSubmitting}>
                  <i className="fal fa-pencil" /> Aanpassen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}
