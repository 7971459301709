import PagePlanDashboard from './PagePlanDashboard';

const PlanStructure = [
  {
    pathname: '/plan/dashboard',
    title: 'Dashboard',
    icon: 'fa-desktop',
    component: PagePlanDashboard,

    rights: ['xxx'],
  },
];

export default PlanStructure;
