import * as React from 'react';
import { BaseStore, useRxjsStore } from '../../../effects';
import { http } from '../../../http';
import { AxiosError } from 'axios';
import Loading from '../../../components/Loading';
import ComponentOrderPurchaseTenantLines from '../components/ComponentOrderPurchaseTenantLines';
import { ComponentOrderPurchaseHeader } from '../components/ComponentOrderPurchaseHeader';
import ComponentOrderPurchaseCustomer from '../components/ComponentOrderPurchaseCustomer';
import { Context, ModalContext } from '../generic/PageOrderGenericOne';
import ComponentOrderPurchaseModals from '../components/ComponentOrderPurchaseModals';
import ComponentOrderPurchaseActions from '../components/ComponentOrderPurchaseActions';

class Store extends BaseStore<Context> {
  setup(): Context {
    return {
      loaded: false,

      purchase_id: null,
      purchase_tenant_id: null,

      purchase: null,
      purchase_tenant: null,
      purchases_per_tenant: [],

      payments: [],
      creditations: [],
      actions: [],

      modal: null,
    };
  }

  async load() {
    this.next(draft => {
      draft.loaded = false;
    });

    try {
      const res = await http().post('/core/order/purchase/tenant/one', {
        purchase_tenant: this.current().purchase_tenant_id,
      });

      this.next(draft => {
        draft.loaded = true;
        draft.purchase_id = res.data.purchase.id;
        draft.purchase = res.data.purchase;
        draft.purchase_tenant = res.data.purchase_tenant;
        draft.actions = res.data.actions;
      });
    } catch (exc) {
      const err: AxiosError = exc;

      this.next(draft => {
        draft.loaded = true;
        draft.purchase = null;
        draft.purchase_tenant = null;
      });
    }
  }

  openModal = (ctx: ModalContext) => {
    this.next(draft => {
      draft.modal = ctx;
    });
  };
  closeModal = () => {
    this.next(draft => {
      draft.modal = null;
    });

    this.load();
  };
}

export default function PageOrderTenantOne(props: { id: number }) {
  const { context, store } = useRxjsStore<Context, Store>(
    Store,
    (): any => {
      return { purchase_tenant_id: props.id };
    },
    () => {
      store.load();
    },
  );

  if (!context) return null;

  if (!context.loaded) {
    return <div className="container">{!context.loaded && <Loading />}</div>;
  }

  const fully_paid = context.purchase.paid >= context.purchase.current_inclusive;

  return (
    <div className="container">
      {context.purchase && (
        <>
          <ComponentOrderPurchaseHeader purchase={context.purchase} fully_paid={fully_paid} store={store} />
          <ComponentOrderPurchaseCustomer context={context} store={store} />
        </>
      )}

      {context.purchase_tenant && (
        <ComponentOrderPurchaseTenantLines purchase_tenant={context.purchase_tenant} context={context} store={store} />
      )}

      {context.actions.length > 0 && <ComponentOrderPurchaseActions context={context} store={store} />}
      <ComponentOrderPurchaseModals context={context} store={store} />
    </div>
  );
}
