import * as React from 'react';
import { PageVacancyJobManyCtx } from '../job/PageVacancyJobManyStore';
import { http } from '../../../http';

type Props = {
  job: ModelVacancyJob;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalJobOnlineStatus({ job, closeModal }: Props) {
  const go_offline = job.online_status === 'online';

  async function onClick() {
    await http().post('/core/vacancy/job/online_status', {
      job: job.id,
      online_status: go_offline ? 'offline' : 'online',
    });

    closeModal({ reload: true });
  }

  return (
    <div className="modal modal-sm active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">
            Vacature <strong>{job.title}</strong> {go_offline ? 'offline halen' : 'online plaatsen'}
          </div>
        </div>

        {go_offline ? (
          <div className="modal-body">
            Vacature offline halen. Hiermee gaat de vacature direct offline, wanneer iemand de vacature open heeft in de
            browser, zullen zij de aanmelding nog wel kunnen doen.
          </div>
        ) : (
          <div className="modal-body">
            Vacature online plaatsen. Hiermee gaat de vacature direct online, het zal even duren voordat Google deze
            pagina gevonden heeft.
          </div>
        )}

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                <i className="fal fa-times" /> Annuleren
              </button>
            </div>
            <div>
              {go_offline ? (
                <button type="button" className="btn btn-error" onClick={onClick}>
                  <i className="fal fa-align-slash" /> Offline halen
                </button>
              ) : (
                <button type="button" className="btn btn-success" onClick={onClick}>
                  <i className="fal fa-plug" /> Online plaatsen
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
