import * as React from 'react';
import { Form, Formik } from 'formik';
import { PageOrderSponsorManyCtx, usePageOrderSponsorManyRxjs } from './PageOrderSponsorManyStore';
import { cs, formatCentsShort, formatDate } from '../../../utils';
import SpectreCardHeader from '../../../components/spectre/SpectreCardHeader';
import SpectreButton from '../../../components/spectre/SpectreButton';
import GenericPagination from '../../../components/GenericPagination';
import ModalManyFilter, { ModalManyFilterValues } from './ModalManyFilter';
import * as _ from 'underscore';
import ModalSponsorCreate from './ModalSponsorCreate';
import { LocationService } from '../../../services/LocationService';
import ModalSponsorUpdate from './ModalSponsorUpdate';
import { getGlobalProfileData } from '../../../services/ProfileService';
import ModalSponsorView from './ModalSponsorView';
import MenuDropdown from '../../../components/MenuDropdown';

export default function PageOrderSponsorMany() {
  const { context, store } = usePageOrderSponsorManyRxjs();

  if (!store || !context) return null;

  function openSponsorCreate() {
    store.openModal({ which: 'sponsor-create', data: {} });
  }

  return (
    <PageOrderSponsorManyCtx.Provider value={{ store, context }}>
      <div className="container">
        <div className="card">
          <div className={cs('card-loading', !context.loaded && 'active')} />

          <SpectreCardHeader
            title="Sponsoring"
            subtitle="Beheer hier alle registreerde sponsoring."
            buttons={[
              <SpectreButton
                icon="fa-plus"
                onClick={openSponsorCreate}
                tooltip={{ title: 'Nieuwe sponsoring registreren', position: 'left' }}
              />,
            ]}
          />
          <div className="card-body">
            <Search />
          </div>
          <div className="card-body">
            <Table />
          </div>
        </div>

        <Modals />
      </div>
    </PageOrderSponsorManyCtx.Provider>
  );
}

function Table() {
  const { context, store } = React.useContext(PageOrderSponsorManyCtx);
  const token = getGlobalProfileData().session.token;

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th className="has-table-shrink-nowrap text-center">
            <i className="fal fa-fw fa-hashtag" />
          </th>
          <th>Onderwerp</th>
          <th className="text-right">Gestart op</th>
          <th className="text-right">Artikelen</th>
          <th className="text-right">Totaal</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {context.records.map(sponsor => {
          const download = `${GLOBAL_APIS_URL}core/order/sponsor/download_excel?sponsor=${sponsor.id}&session=${token}`;

          function onDoubleClick() {
            LocationService.navigate('/order/sponsor/articles', { id: sponsor.id.toString() });
          }
          function onUpdate() {
            store.openModal({ which: 'sponsor-update', data: { sponsor_id: sponsor.id } });
          }
          function onView() {
            store.openModal({ which: 'sponsor-view', data: { sponsor_id: sponsor.id } });
          }

          return (
            <tr key={sponsor.id} onDoubleClick={onDoubleClick}>
              <td className="has-table-shrink-nowrap text-right">#{sponsor.id}</td>
              <td>{sponsor.subject}</td>
              <td className="has-table-shrink-nowrap text-right">{formatDate(sponsor.started_at)}</td>
              <td className="has-table-shrink-nowrap text-right">{sponsor.amount}</td>
              <td className="has-table-shrink-nowrap text-right">{formatCentsShort(sponsor.inclusive)}</td>
              <td className="has-table-shrink-nowrap">
                <MenuDropdown
                  button={<i className="fal fa-ellipsis-h" />}
                  classNames={['mr-1']}
                  btnClassNames={['btn btn-dark-outline btn-action s-circle']}
                  items={[
                    <a href="javascript:" onClick={onUpdate}>
                      <i className="fal fa-fw fa-pencil" /> aanpassen
                    </a>,
                    <a href="javascript:" onClick={onView}>
                      <i className="fal fa-fw fa-search" /> bekijken
                    </a>,
                    <a href={download} target="_blank">
                      <i className="fal fa-fw fa-file-excel" /> downloaden
                    </a>,
                  ]}
                />
                <SpectreButton
                  icon="fa-list-ul"
                  onClick={onDoubleClick}
                  tooltip={{
                    title: 'Beheer de artikelen bij deze sponsor.',
                    position: 'left',
                  }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>

      <GenericPagination pagination={context.pagination} setPage={store.setPage} span={6} />
    </table>
  );
}

function Search() {
  const { context, store } = React.useContext(PageOrderSponsorManyCtx);
  const initial = {
    date_from: context.query.date_from,
    date_till: context.query.date_till,
  };
  const query_is_empty = store.queryIsEmpty();

  function openFilter() {
    store.openModal({ which: 'filter', data: { initial: context.query } });
  }

  return (
    <Formik
      initialValues={initial}
      enableReinitialize={true}
      onSubmit={async (values, fa) => {
        await store.search(values);
        fa.setSubmitting(false);
      }}
      render={fp => (
        <Form style={{ padding: '0 1rem' }}>
          <div className={cs('form-group')}>
            <div className="columns">
              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="button"
                  className="btn btn-error-outline btn-action s-circle tooltip tooltip-right"
                  data-tooltip="Leeg alle zoekvelden."
                  disabled={!context.loaded}
                  onClick={async () => {
                    await store.resetQuery();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="column" />

              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <SpectreButton
                  icon="fa-filter"
                  classNames={!query_is_empty && ['btn-primary btn-action s-circle mx-1']}
                  onClick={openFilter}
                  disabled={!context.loaded}
                  tooltip={{ title: 'Filter de personen', position: 'left' }}
                />
                <button
                  type="submit"
                  className="btn btn-primary-outline btn-action s-circle"
                  disabled={!context.loaded}
                >
                  <i className={cs('fal fa-fw', !context.loaded ? 'fa-spin fa-spinner-third' : 'fa-search')} />
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
}

function Modals() {
  const { context, store } = React.useContext(PageOrderSponsorManyCtx);
  const mctx = context.modal;

  if (!mctx) return null;

  function closeFilter(values?: ModalManyFilterValues) {
    if (_.isEmpty(values)) {
      store.closeModal();
    } else {
      store.closeModal();
      store.search(values);
    }
  }

  function closeSponsorCreate(id?: number) {
    store.closeModal();

    if (id) {
      LocationService.navigate('/order/sponsor/articles', { id: id.toString() });
    }
  }

  return (
    <React.Fragment>
      {mctx.which === 'filter' && <ModalManyFilter {...mctx.data} closeModal={closeFilter} />}
      {mctx.which === 'sponsor-create' && <ModalSponsorCreate {...mctx.data} closeModal={closeSponsorCreate} />}
      {mctx.which === 'sponsor-update' && <ModalSponsorUpdate {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'sponsor-view' && <ModalSponsorView {...mctx.data} closeModal={store.closeModal} />}
    </React.Fragment>
  );
}
