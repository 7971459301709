import PageGenericMailOne from './mail/PageGenericMailOne';

const GenericStructure = [
  {
    pathname: '/generic/mail/one',
    title: 'Mail',
    icon: 'fa-envelope-o',
    component: PageGenericMailOne,

    rights: ['shop.orders'],
  },
];

export default GenericStructure;
