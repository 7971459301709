import * as React from 'react';
import { PageVacancyManageCtx } from './PageVacancyManageStore';
import { http } from '../../../http';

type Props = {
  location: ModelVacancyLocation;
};

export default function ModalLocationArchive({ location }: Props) {
  const { store, context } = React.useContext(PageVacancyManageCtx);
  const [loading, setLoading] = React.useState<boolean>(false);

  async function onSubmit() {
    setLoading(true);

    try {
      await http().post('/core/vacancy/location/archive', {
        location: location.id,
      });

      store.closeModal({ reload: true });
    } catch (exc) {
      console.error(exc);
      setLoading(false);
    }
  }

  return (
    <div className="modal modal-sm active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">
            Locatie <strong>{location.name}</strong> archiveren
          </div>
        </div>

        <div className="modal-body">
          <p>
            Weet je zeker dat je de locatie <strong>{location.name}</strong> wilt archiveren? Het archiveren zorgt er
            alleen voor dat je deze locatie niet meer kunt kiezen wanneer je een nieuwe vacature aanmaakt. De locaties
            blijft zichtbaar op de website tot alle vacactures met deze locatie offline zijn gegaan.
          </p>
        </div>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link" onClick={() => store.closeModal()} disabled={loading}>
                <i className="fal fa-times" /> Annuleren
              </button>
            </div>
            <div>
              <button type="submit" className="btn btn-error" onClick={() => onSubmit()} disabled={loading}>
                <i className="fal fa-trash" /> Archiveren
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
