import PageVacancyApplicationMany from './application/PageVacancyApplicationMany';
import PageVacancyJobMany from './job/PageVacancyJobMany';
import PageVacancyJobApplications from './job/PageVacancyJobApplications';
import PageVacancyManage from './manage/PageVacancyManage';

const VacancyStructure = [
  {
    pathname: '/vacancy/application/many',
    title: 'Sollicitanten',
    icon: 'fa-users',
    component: PageVacancyApplicationMany,

    rights: ['vacancy.read'],
  },
  {
    pathname: '/vacancy/job/many',
    title: 'Vacatures',
    icon: 'fa-file-signature',
    component: PageVacancyJobMany,

    rights: ['vacancy.read'],
  },
  {
    pathname: '/vacancy/job/applications',
    title: 'Sollicitaties',
    icon: 'fa-users',
    component: PageVacancyJobApplications,
    parent: () => ({ pathName: '/vacancy/job/many' }),

    rights: ['vacancy.read'],
  },
  {
    pathname: '/vacancy/manage',
    title: 'Beheer',
    icon: 'fa-cog',
    component: PageVacancyManage,

    rights: ['vacancy.manage'],
  },
];

export default VacancyStructure;
