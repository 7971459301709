import * as React from 'react';
import { useEffect } from 'react';
import { buildCartTicket, cs, formatCentsShort, formatDate } from '../../utils';
import { BaseStore, useRxjsStore } from '../../effects';
import { http, httpLive } from '../../http';
import Loading from '../../components/Loading';
import * as _ from 'underscore';

interface PageCashRefundProps {
  id?: string;
}

type State = {
  loaded: boolean;
  carts: ModelShopCart[];
  printing: number[];
  pagination: Pagination;
};

class Store extends BaseStore<State> {
  setup(): State {
    return {
      loaded: false,
      carts: [],
      printing: [],
      pagination: null,
    };
  }

  async load() {
    const ggd = window.GLOBAL_GENERIC_DATA;

    this.next(draft => {
      draft.loaded = false;
    });

    const res = await http().post('/sale/cart/many', {
      state: ['paid'],
      terminals: [ggd.device.terminal.id],
    });

    this.next(draft => {
      draft.loaded = true;
      draft.carts = res.data.records;
      draft.pagination = res.data.pagination;
    });
  }

  async print(cart_id: number) {
    const ggd = window.GLOBAL_GENERIC_DATA;

    this.next(draft => {
      draft.printing.push(cart_id);
    });

    const res_cart = await http().post('/sale/cart/one', { cart: cart_id });

    const cart = res_cart.data.cart;
    const lines = res_cart.data.lines;
    const giftcards_payment = res_cart.data.giftcards_payment;
    const giftcards_purchase = res_cart.data.giftcards_purchase;

    const ticket_printer_id = ggd.device.ticket_printer.id;
    const ticket = await buildCartTicket(cart, lines, giftcards_payment, giftcards_purchase, null);

    const res_print = await httpLive().post('/tickets/print', {
      ticket_printer_id,
      ticket: await ticket.generate(),
    });

    this.next(draft => {
      draft.printing.splice(draft.printing.indexOf(cart_id), 1);
    });
  }
}

export default function(props: PageCashRefundProps) {
  const { context, store } = useRxjsStore<State, Store>(Store);

  useEffect(() => {
    if (store) {
      store.load();
    }
  }, [store]);

  if (!context) {
    return null;
  }

  async function onLoadClick() {
    await store.load();
  }

  if (!context.loaded) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="card">
        <div className="card-header">
          <div className="flex-row">
            <div className="flex-grow">
              <div className="card-title h5">Laatste 20 orders</div>
            </div>
            <div className="flex-shrink">
              <a href="javascript:" className="btn btn-link btn-action s-circle" onClick={onLoadClick}>
                <i className="fal fa-sync" />
              </a>
            </div>
          </div>
        </div>
        <div className="card-body">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Code</th>
                <th>Medewerker</th>
                <th>Op</th>
                <th>Klant</th>
                <th className="text-right">Artikelen</th>
                <th className="text-right">Totaal</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {context.carts.map(cart => {
                const printing = _.contains(context.printing, cart.id);

                return (
                  <tr key={cart.id}>
                    <td className="has-table-shrink-nowrap">
                      <i className="fal fa-hashtag" /> {cart.id}
                    </td>
                    <td>{cart.person_sale && <>{cart.person_sale.name}</>}</td>
                    <td className="has-table-shrink-nowrap">
                      {formatDate(cart.created_at, 'LL')} om {formatDate(cart.created_at, 'LT')}
                    </td>
                    <td>{cart.customer && <>{cart.customer.first}</>}</td>
                    <td className="text-right has-table-shrink-nowrap">{cart.amount}</td>
                    <td className="text-right has-table-shrink-nowrap">{formatCentsShort(cart.inclusive)}</td>
                    <td className="has-table-shrink-nowrap">
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => store.print(cart.id)}
                        disabled={_.contains(context.printing, cart.id)}
                      >
                        <i className={cs('fal fa-fw', printing ? 'fa-spin fa-spinner-third' : 'fa-ticket')} /> print bon
                        opnieuw
                      </button>
                    </td>
                  </tr>
                );
              })}
              {context.carts.length === 0 && (
                <tr>
                  <td colSpan={7}>
                    <div className="empty">
                      <div className="empty-icon">
                        <i className="fal fa-parking  fa-3x" />
                      </div>
                      <p className="empty-title h5">Niets gevonden</p>
                      <p className="empty-subtitle" style={{ maxWidth: '60%' }}>
                        Er zijn geparkeerde orders gevonden, gebruik de <span className="label">parkeren</span> knop in
                        het verkoop scherm om de order hier naar toe te verplaatsen.
                      </p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
