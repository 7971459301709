import * as React from 'react';
import { PageVacancyManageCtx } from './PageVacancyManageStore';
import * as Yup from 'yup';
import { Form, Formik, FormikActions } from 'formik';
import { http } from '../../../http';
import FormInput from '../../../components/FormInput';
import FormGroup from '../../../components/FormGroup';
import { AxiosError } from 'axios';

type Values = {
  name: string;
};

const schema = Yup.object().shape({
  name: Yup.string().min(3, 'stringMinLength'),
});

export default function ModalDepartmentCreate() {
  const { store, context } = React.useContext(PageVacancyManageCtx);

  const initialValues = {
    name: '',
  };

  async function onSubmit(values: Values, formikActions: FormikActions<Values>) {
    try {
      await http().post('/core/vacancy/department/create', {
        name: values.name,
      });

      store.closeModal({ reload: true });
    } catch (exc) {
      const err: AxiosError = exc;
      console.error(exc);

      if (err.response.data && err.response.data.errors) {
        formikActions.setErrors(err.response.data.errors);
      }

      formikActions.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      render={fp => {
        return (
          <Form>
            <div className="modal modal-sm active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">Afdeling toevoegen</div>
                </div>

                <div className="modal-body">
                  <FormGroup title="Naam van de afdeling" name="name" errors={fp.errors}>
                    <FormInput name="name" loading={fp.isSubmitting} />
                  </FormGroup>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button type="button" className="btn btn-link" onClick={() => store.closeModal()}>
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary" disabled={fp.isSubmitting}>
                        <i className="fal fa-plus" /> Toevoegen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
