import { BaseStore, useRxjsStore } from '../../../effects';
import { ModalManyFilterValues } from './ModalManyFilter';
import { loadManyRecordsPaginated } from '../../../utils';
import * as _ from 'underscore';
import * as React from 'react';

export type PageOrderSponsorManyContext = {
  loaded: boolean;

  pagination?: Pagination;
  records: ModelOrderSponsor[];

  modal: PageOrderSponsorManyModalContext;

  query: {
    page: number;
    per_page: number;
  } & ModalManyFilterValues;

  load_iteration: number;
};

const emptyQuery = () => ({
  page: 0,
  per_page: 50,

  date_from: '',
  date_till: '',
});

export class PageOrderSponsorManyStore extends BaseStore<PageOrderSponsorManyContext> {
  setup(): PageOrderSponsorManyContext {
    return {
      loaded: false,

      pagination: null,
      records: [],

      modal: null,

      query: emptyQuery(),

      load_iteration: 0,
    };
  }

  async load() {
    this.next(draft => {
      draft.loaded = false;
    });

    const result = await loadManyRecordsPaginated('/core/order/sponsor/many', {
      ...this.current().query,
    });

    this.next(draft => {
      draft.loaded = true;

      draft.pagination = result.pagination;
      draft.records = result.records;
      draft.load_iteration += 1;
    });
  }

  async search(values: Partial<ModalManyFilterValues>) {
    this.next(draft => {
      _.keys(draft.query).forEach(key => {
        const value = values[key];
        if (value !== undefined) {
          draft.query[key] = value;
        }
      });
    });

    await this.load();
  }

  async resetQuery() {
    this.next(draft => {
      draft.query = emptyQuery();
    });

    await this.load();
  }

  queryIsEmpty(extra_ignore?: string[]) {
    const ignore = _.union(['page', 'per_page'], extra_ignore);
    const empty = emptyQuery();

    return _.pairs(this.current().query)
      .filter(([k, v]) => !_.contains(ignore, k))
      .every(([k, v]) => _.isEqual(v, empty[k]));
  }

  // region Utils

  setPage = async (page: number) => {
    this.next(draft => {
      draft.query.page = page;
    });
    await this.load();
  };

  openModal(ctx: PageOrderSponsorManyModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }

  closeModal = ({ reload }: { reload: boolean } = { reload: false }) => {
    this.next(draft => {
      draft.modal = null;
    });

    if (reload) {
      setTimeout(() => {
        this.load();
      }, 1);
    }
  };

  // endregion
}

// region Modal Context

export type PageOrderSponsorManyModalContext =
  | {
      which: 'filter';
      data: { initial: ModalManyFilterValues };
    }
  | {
      which: 'sponsor-create';
      data: {};
    }
  | {
      which: 'sponsor-update';
      data: { sponsor_id: number };
    }
  | {
      which: 'sponsor-view';
      data: { sponsor_id: number };
    };

// endregion
// region Utilities

export type PageOrderSponsorManyBasicProps = {
  store: PageOrderSponsorManyStore;
  context: PageOrderSponsorManyContext;
};

export function usePageOrderSponsorManyRxjs(): PageOrderSponsorManyBasicProps {
  const { store, context } = useRxjsStore<PageOrderSponsorManyContext, PageOrderSponsorManyStore>(
    PageOrderSponsorManyStore,
    null,
    () => {
      store.load();
    },
  );

  return { store, context };
}

export const PageOrderSponsorManyCtx = React.createContext<PageOrderSponsorManyBasicProps>(null);

// endregion
