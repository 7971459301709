import * as React from 'react';
import { Idle, IdleLogo } from './Components';

export default function PaneIdle({ loaded }: { loaded: boolean }) {
  return (
    <Idle className={`idle-state__black ${loaded ? 'hide' : ''}`}>
      <IdleLogo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.5 290">
        <g>
          <g>
            <path
              d="M10,30.9h277.5v54H77v45c0,0,12.4-14.6,41.1-15.2c28.7-0.6,107.5,0,107.5,0s31-2.3,53.5,16.3c0,0,18,16.3,18.6,38.8v52.4
                                    c0,0-0.6,28.1-16.3,43.3c0,0-16.9,21.4-69.2,22H80.9c0,0-40-1.7-56.3-14.6c0,0-19.7-10.1-21.4-38.3c-1.7-28.1,0-28.1,0-28.1h70.4
                                    c0,0-5.1,28.7,32.1,27h90.6c0,0,34.9,1.1,32.1-28.7v-14.6c0,0,2.2-23.1-32.1-23.7H95.5c0,0-15.2,0-17.4,16.3H10V30.9z"
            />
          </g>
        </g>
        <polygon points="17.8,3 17.8,20.1 33.7,20.1 33.7,26 10.8,26 10.8,3 " />
        <path
          d="M65.5,11.1c0-6.7,3.8-8.3,10.1-8.3H87c6.3,0,10.1,1.6,10.1,8.3V18c0,6.7-3.8,8.3-10.1,8.3H75.6c-6.3,0-10.1-1.6-10.1-8.3
                             M72.8,15.8c0,3.3,0.7,4.6,4,4.6h9c3.3,0,4-1.3,4-4.6v-2.5c0-2.9-0.7-4.6-4.4-4.6h-8.3c-3.7,0-4.4,1.7-4.4,4.6V15.8z"
        />
        <path
          d="M129.9,11.1c0-6.7,3.8-8.3,10.1-8.3h11.3c6.3,0,10.1,1.6,10.1,8.3V18c0,6.7-3.8,8.3-10.1,8.3H140c-6.3,0-10.1-1.6-10.1-8.3
                             M137.1,15.8c0,3.3,0.7,4.6,4,4.6h9c3.3,0,4-1.3,4-4.6v-2.5c0-2.9-0.7-4.6-4.4-4.6h-8.3c-3.7,0-4.4,1.7-4.4,4.6V15.8z"
        />
        <path
          d="M194.5,3h21.3c7,0,9.5,2.3,9.5,8v7c0,5.7-2.5,8-9.5,8h-21.3 M201.5,20.1h12.5c3,0,4.2-0.9,4.2-4.1v-3.1
                            c0-3.3-1.2-4.1-4.2-4.1h-12.5V20.1z"
        />
        <path
          d="M258.5,18.5h7c0,2.4,1,2.6,3,2.6h8.6c1.5,0,3-0.2,3-1.9c0-2-0.8-2.1-9.9-2.1c-10.6,0-11.8-1.3-11.8-6.9
                            c0-4.2,0.4-7.4,9.3-7.4h9.8c8.1,0,8.9,2.8,8.9,6.8v0.7h-7c0-1.7-0.2-2.4-3.4-2.4h-7.8c-1.6,0-2.7,0.5-2.7,1.8c0,2,0.9,2.1,8.4,2
                            c11,0,13.5,0.6,13.5,7.3c0,6.2-2.1,7.2-11.5,7.2h-8.5c-6.4,0-9-1.2-9-6.2"
        />
      </IdleLogo>
    </Idle>
  );
}
