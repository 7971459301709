import * as React from 'react';
import {
  cs,
  formatCentsShort,
  formatEan13Number,
  isWithinMz,
  loadManyRecordsOr,
  loadOneRecord,
  playSoundPositive,
} from '../../../../utils';
import SpectreCardHeader from '../../../../components/spectre/SpectreCardHeader';
import SpectreButton from '../../../../components/spectre/SpectreButton';
import { BaseStore, useDebounce, useRxjsStore } from '../../../../effects';
import { Link } from '../../../../components/Link';
import Loading from '../../../../components/Loading';
import { LocationService } from '../../../../services/LocationService';
import { http } from '../../../../http';

// region Types

export type PageShopArticleStickerTemplateEditContext = {
  loaded: boolean;

  template_id: string;
  template: ModelShopStickerTemplate;

  modal?: PageShopArticleStickerTemplateEditModalContext;

  default_amount: string;
  default_type: string;
};

export type PageShopArticleStickerTemplateEditModalContext = {
  which: 'print';
  data: { template_id: number };
};

export type PageShopArticleStickerTemplateEditModalFilterValues = {
  search: string;
};

// endregion

export const PageShopArticleStickerTemplateEditCtx = React.createContext<PageShopArticleStickerTemplateEditBasicProps>(
  null,
);

export default function PageShopArticleStickerTemplateEdit({ template_id }) {
  const { store, context } = usePageShopArticleStickerTemplateEditRxjs(template_id);
  const scrollable = React.useRef<HTMLDivElement>(null);

  if (!store || !context) return null;

  const { template } = context;

  function scrollableToTop() {
    if (scrollable.current) {
      scrollable.current.scrollTo({ top: 0 });
    }
  }

  async function onSaveAndPrint() {
    if (await store.submit()) {
      LocationService.navigate('/shop/sticker/template/publish', { template_id });
    }
  }

  async function onSaveAndClose() {
    if (await store.submit()) {
      LocationService.navigate('/shop/sticker/template/many');
    }
  }

  if (!context.template) return <Loading />;

  return (
    <PageShopArticleStickerTemplateEditCtx.Provider value={{ store, context }}>
      <div className="container" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div className="card" style={{ height: '100%' }}>
          <SpectreCardHeader
            title={`Sticker-Template: ${context.template.name}`}
            subtitle={
              <>
                Je kunt hier artikelen toevoegen aan het sticker-template. Wanneer je klaar bent kun je rechtsonderin
                klikken op <strong>Opslaan & printen</strong> wanneer je ze wilt gaan afdrukken.
                <br />
                Kies voor <strong>Opslaan & sluiten</strong> wanneer je later verder wilt gaan.
              </>
            }
            buttons={[
              <SpectreButton
                icon="fa-arrow-left"
                link={['/shop/sticker/template/many']}
                tooltip={{ title: 'Terug naar het overzicht', position: 'left' }}
              />,
            ]}
          />

          <div className="card-partial">
            <div className="columns">
              <div className="column  pr-2">
                <Search scrollableToTop={scrollableToTop} />
              </div>
              <div className="column col-2 col-xs-3  pr-2">
                <label className="form-label">Aantal</label>

                <input
                  className="form-input input-lg  text-right"
                  type="text"
                  value={context.default_amount}
                  onChange={e => store.setDefaultAmount(e.currentTarget.value)}
                />
              </div>
              <div className="column col-2 col-xs-3  pr-2">
                <label className="form-label">StickerType</label>

                <select
                  className="form-select select-lg"
                  value={context.default_type}
                  onChange={e => store.setDefaultType(e.currentTarget.value)}
                >
                  <option value="label-small">Onbeveiligd</option>
                  <option value="label-protected">Beveiligd</option>
                  <option value="label-large">Meubel</option>
                </select>
              </div>
            </div>
          </div>

          {template.rows.length !== 0 && (
            <div className="card-body  card-body-fixed">
              <Table />
            </div>
          )}
          {template.rows.length === 0 && (
            <div className="card-body  card-body-fixed">
              <div className="empty">
                <div className="empty-icon">
                  <i className="fal fa-shopping-cart  fa-3x" />
                </div>
                <p className="empty-title h5">Geen artikelen</p>
                <p className="empty-subtitle">Er zijn nog geen artikelen toegevoegd...</p>
              </div>
            </div>
          )}

          <div className="card-footer">
            <div className="flex-row flex-space-between">
              <div>
                <Link pathName="/shop/sticker/template/many" className="btn btn-link">
                  <i className="fal fa-times" /> Terug naar het overzicht
                </Link>
              </div>
              <div>
                {isWithinMz() && (
                  <button
                    type="submit"
                    className="btn btn-primary-outline btn-lg"
                    onClick={onSaveAndPrint}
                    disabled={!context.loaded}
                  >
                    <i className="fal fa-save" /> Opslaan &amp; printen
                  </button>
                )}
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  onClick={onSaveAndClose}
                  disabled={!context.loaded}
                >
                  <i className="fal fa-save" /> Opslaan &amp; sluiten
                </button>
              </div>
            </div>
          </div>
        </div>

        <Modals />
      </div>
    </PageShopArticleStickerTemplateEditCtx.Provider>
  );
}

// region Components

function Modals() {
  const { context, store } = React.useContext(PageShopArticleStickerTemplateEditCtx);
  const mctx = context.modal;

  if (!mctx) return null;

  return <React.Fragment />;
}

function Search({ scrollableToTop }) {
  const { context, store } = React.useContext(PageShopArticleStickerTemplateEditCtx);

  const me_group = React.useRef<HTMLDivElement>(null);
  const me = React.useRef<HTMLInputElement>(null);

  const [value, setValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [position, setPosition] = React.useState({ left: 0, top: 0, width: 0 });
  const [results, setResults] = React.useState({ value: '', loading: false, records: [] });
  const [skip_article_id_set, setSkipArticleIdSet] = React.useState([]);
  const debouncedSearch = useDebounce([value, results.value, results.loading], 500);

  const current_skip_article_id_set = context.template.rows.map(x => x.article_id).sort();

  React.useEffect(() => {
    if (current_skip_article_id_set.join(':') !== skip_article_id_set.join(':')) {
      setSkipArticleIdSet(current_skip_article_id_set);

      if (open && !results.loading) {
        loadResults();
      }
    }
  }, [open, results, current_skip_article_id_set.join(':'), skip_article_id_set.join(':')]);

  React.useEffect(() => {
    function handleResize() {
      const rect = me.current.getBoundingClientRect();

      setPosition({
        left: rect.left,
        top: rect.top + rect.height,
        width: rect.width,
      });
    }

    function handleClickOutside(event) {
      if (me_group.current && !me_group.current.contains(event.target) && open) {
        setOpen(false);
      }
    }

    document.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    handleResize();

    return () => {
      document.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [me, open, context.modal === null]);

  React.useEffect(() => {
    if (results.loading === true) {
      // Do not start when still loading, the
      // effect will be re-triggered when the
      // loading has completed.
      return;
    }

    if (value !== results.value) {
      loadResults();
    }
  }, [debouncedSearch]);

  async function loadResults() {
    setResults({
      value: null,
      loading: true,
      records: results.records,
    });

    if (value.length < 3) {
      setOpen(false);
      setResults({
        value,
        records: [],
        loading: false,
      });

      return;
    }

    if (/^(#|]C1)?[0-9]+$/.exec(value)) {
      const record = await loadOneRecord('/core/shop/article/one_by_number', { number: value });

      if (record) {
        store.lineAdd(record);
        playSoundPositive();
        scrollableToTop();
        closeAndReset();

        return;
      }
    }

    const records = await loadManyRecordsOr('/core/shop/article/many_search_tenant', {
      search: value,
      skip: current_skip_article_id_set,
      limit: 20,
    });

    setOpen(records.length > 0);
    setResults({
      value,
      records,
      loading: false,
    });
  }

  function onKeyUp(event: React.KeyboardEvent) {
    if (event.key === 'Escape') {
      setOpen(false);
    } else if (event.key === 'Enter') {
      event.preventDefault();

      if (open && !results.loading && results.records.length > 0) {
        store.lineAdd(results.records[0]);
        playSoundPositive();
        scrollableToTop();
        closeAndReset();
      }
    }
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  function closeAndReset() {
    setValue('');
    setOpen(false);
    setResults({
      value: '',
      records: [],
      loading: false,
    });
  }

  return (
    <div className={cs('form-group')} ref={me_group}>
      <label className="form-label is-optional">Zoeken </label>

      <input
        type="text"
        name="article_search_form__value"
        value={value}
        onKeyUp={onKeyUp}
        onChange={onChange}
        ref={me}
        className="form-input input-lg"
        placeholder="Scannen of zoeken..."
      />

      {open && (
        <ul
          className="menu"
          style={{
            position: 'fixed',
            top: position.top,
            left: position.left,
            width: position.width,
            maxWidth: 460,
            maxHeight: 300,
            overflow: 'auto',
          }}
        >
          {results.records.length === 0 && (
            <li className="menu-item">
              <em>Er zijn geen records gevonden...</em>
            </li>
          )}
          {results.records.map((record, rx) => {
            function onClick() {
              store.lineAdd(record);
              scrollableToTop();
              playSoundPositive();
              // closeAndReset();
            }

            return (
              <li className="menu-item" key={record.id}>
                <a className="flex-row" href="javascript:" onClick={onClick}>
                  <div className="flex-grow mx-2">
                    <strong>{record.name}</strong>
                    <br />
                    <small>{formatEan13Number(record.number_beeyond)}</small>
                    {record.number_order && <small>, eigen nummer: {record.number_order}</small>}
                  </div>
                  <div className="flex-shrink">{formatCentsShort(record.inclusive)}</div>
                </a>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

function Table() {
  const { context, store } = React.useContext(PageShopArticleStickerTemplateEditCtx);

  const { template } = context;

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th className="has-table-shrink-nowrap">Nummer</th>
          <th>Naam</th>
          <th className="has-table-shrink-nowrap  text-right">Aantal</th>
          <th className="has-table-shrink-nowrap">StickerType</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {template.rows.map((row, rx) => {
          function onDelete() {
            store.lineDelete(rx);
          }

          return (
            <tr key={rx}>
              <td className="has-table-shrink-nowrap">{row.number_beeyond}</td>
              <td>{row.name}</td>
              <td style={{ width: 60 }}>
                <input
                  className="form-input  text-right"
                  type="text"
                  value={row.default_amount}
                  onChange={e => store.setRowDefaultAmount(rx, e.currentTarget.value)}
                />
              </td>
              <td style={{ width: 140 }}>
                <select
                  className="form-select"
                  value={row.default_type}
                  onChange={e => store.setRowDefaultType(rx, e.currentTarget.value)}
                >
                  <option value="label-small">Onbeveiligd</option>
                  <option value="label-protected">Beveiligd</option>
                  <option value="label-large">Meubel</option>
                </select>
              </td>
              <td className="has-table-shrink-nowrap">
                <SpectreButton
                  icon="fa-times"
                  classNames={['btn-error-outline btn-action mx-1']}
                  onClick={onDelete}
                  tooltip={{
                    title: 'Verwijder deze regel.',
                    position: 'left',
                  }}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

// endregion
// region Store

class PageShopArticleStickerTemplateEditStore extends BaseStore<PageShopArticleStickerTemplateEditContext> {
  setup(): PageShopArticleStickerTemplateEditContext {
    return {
      loaded: false,
      template_id: null,
      template: null,
      modal: null,

      default_amount: '1',
      default_type: 'label-small',
    };
  }

  async load(partial: boolean = true) {
    const template_id = this.current().template_id;

    this.next(draft => {
      draft.loaded = false;
    });

    const result = await loadOneRecord('/core/shop/sticker/template/one', {
      template: template_id,
    });

    this.next(draft => {
      draft.loaded = true;

      draft.template = result;
    });
  }

  async submit(): Promise<boolean> {
    this.next(draft => {
      draft.loaded = false;
    });

    const current = this.current();

    try {
      await http().post('/core/shop/sticker/template/edit', {
        template: current.template.id,
        name: current.template.name,
        rows: current.template.rows,
      });

      return true;
    } catch (e) {
      this.next(draft => {
        draft.loaded = true;
        console.error(e);
      });

      return false;
    }
  }

  lineAdd(article: ModelShopArticle) {
    this.next(draft => {
      draft.template.rows.push({
        id: null,

        article_id: article.id,

        default_amount: draft.default_amount,
        default_type: draft.default_type,

        number_beeyond: article.number_beeyond,
        name: article.name,
      });
    });
  }

  lineDelete(rx: number) {
    this.next(draft => {
      draft.template.rows.splice(rx, 1);
    });
  }

  setDefaultAmount(default_amount: string) {
    this.next(draft => {
      if (default_amount === '') {
        draft.default_amount = '';
      } else {
        const parsed = parseInt(default_amount, 10);
        draft.default_amount = (isNaN(parsed) ? 0 : parsed).toString();
      }
    });
  }

  setDefaultType(default_type: string) {
    this.next(draft => {
      draft.default_type = default_type;
    });
  }

  setRowDefaultAmount(rx: number, default_amount: string) {
    this.next(draft => {
      if (default_amount === '') {
        draft.template.rows[rx].default_amount = '';
      } else {
        const parsed = parseInt(default_amount, 10);
        draft.template.rows[rx].default_amount = (isNaN(parsed) ? 0 : parsed).toString();
      }
    });
  }

  setRowDefaultType(rx: number, default_type: string) {
    this.next(draft => {
      draft.template.rows[rx].default_type = default_type;
    });
  }

  // region Utils

  openModal(ctx: PageShopArticleStickerTemplateEditModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }

  closeModal = ({ reload }: { reload: boolean } = { reload: false }) => {
    this.next(draft => {
      draft.modal = null;
    });

    if (reload) {
      setTimeout(() => {
        this.load();
      }, 1);
    }
  };

  // endregion
}

export type PageShopArticleStickerTemplateEditBasicProps = {
  store: PageShopArticleStickerTemplateEditStore;
  context: PageShopArticleStickerTemplateEditContext;
};

export function usePageShopArticleStickerTemplateEditRxjs(
  template_id: string,
): PageShopArticleStickerTemplateEditBasicProps {
  const { store, context } = useRxjsStore<
    PageShopArticleStickerTemplateEditContext,
    PageShopArticleStickerTemplateEditStore
  >(
    PageShopArticleStickerTemplateEditStore,
    (): Partial<PageShopArticleStickerTemplateEditContext> => {
      return { template_id };
    },
    () => {
      store.load(false);
    },
  );

  return { store, context };
}

// endregion
