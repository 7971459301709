import { PageDefinition } from './PageState';
import * as React from 'react';
import MenuDropdown from '../../../components/MenuDropdown';

export function ComponentTileEmpty({ page }: { page: PageDefinition }) {
  const is_large = !!page.context.touch && page.context.touch.is_large;
  let items = [];

  for (const discount of page.context.available_discounts) {
    items.push(
      <a href="javascript:" onClick={() => page.store.attachDiscount(discount.id)}>
        <i className="fal fa-tag  mr-1" /> {discount.name}
      </a>,
    );
    discount.name;
  }

  if (items.length > 0) {
    items.push(<div className="divider" />);
  }

  items = items.concat([
    <a href="javascript:" onClick={() => page.store.toggleModalCustomerSearch()}>
      <i className="fal fa-search  mr-1" /> klant opzoeken
    </a>,
    !is_large && (
      <a href="javascript:" onClick={() => page.store.toggleModalCustomerUpdate()}>
        <i className="fal fa-pencil  mr-1" /> klant aanmaken
      </a>
    ),
    <a href="javascript:" onClick={() => page.store.toggleModalPersonSearch()}>
      <i className="fal fa-user-alt  mr-1" /> medewerker opzoeken
    </a>,
    <a href="javascript:" onClick={() => page.store.toggleModalTenantSearch()}>
      <i className="fal fa-user-tie  mr-1" /> deelnemer opzoeken
    </a>,
  ]);

  return (
    <div className="tile mb-2  tile-centered">
      <div className="tile-content">
        <div className="tile-title">
          <em>Geen klant gekoppeld</em>
        </div>
      </div>
      <div className="tile-action">
        <div className="tile-action">
          {is_large && (
            <button className="btn btn-primary btn-lg" onClick={() => page.store.toggleModalCustomerUpdate()}>
              klant aanmaken
            </button>
          )}
          <MenuDropdown
            btnNoLink
            btnClassNames={['btn btn-primary btn-lg']}
            button={
              <span>
                <i className="fal fa-pencil" /> personaliseren
              </span>
            }
            classNames={['menu-dropdown-top']}
            items={items}
          />
        </div>
      </div>
    </div>
  );
}
