export default class Query<T> {
  private name: string;
  private session: boolean;

  constructor(name: string, session: boolean = true) {
    this.name = name;
    this.session = session;
  }

  storage() {
    return this.session ? window.sessionStorage : window.localStorage;
  }
  key() {
    return `Query-${this.name}`;
  }

  load(): T | null {
    const data = this.storage().getItem(this.key());

    if (data === null) {
      console.log('Nulled', this.key());
      return null;
    }

    console.log('Loading', this.key());
    return JSON.parse(data) as T;
  }

  save(data: T | null) {
    if (data === null) {
      console.log('Clearing', this.key());
      this.storage().removeItem(this.key());
    } else {
      console.log('Saving', this.key());
      this.storage().setItem(this.key(), JSON.stringify(data));
    }
  }
}
