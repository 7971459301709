import * as React from 'react';
import Render from './Render';

export default function PageDocCoreContactPerson() {
  return <Render markdown={markdown} />;
}

const markdown = `

# Inleiding

Welkom bij de handleiding van Loods 5 - CRM Personen. We zoomen hier in
op de werkzaamheden die de _Beheerder_ kan uitvoeren. Je vindt het beheer
van de personen links in het menu onder het kopje CRM.

![Stap 1](${GLOBAL_APIS_URL}static/doc/core-contact-person/bdfcbbf1-2ccc-47dc-b9dc-d4f657bc9d44.png)

# Persoon toevoegen

Vanuit het contacten overzicht kun je rechtsboven in het
scherm een nieuw contact toevoegen.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/4d92c3fc-47d8-425d-aa01-51ea2a0b7eea.png)

Er opent een popup waar alle informatie ingevoerd kan worden.
In dit voorbeeld voegen we een beheerder toe, straks bij het
aanpassen van een contact gebruiken we een deelnemer.

Er zijn een aantal velden waar we wat extra nadruk moeten
leggen op de betekenis er van:

- *Gebruikersmode: Loods 5*, vanaf deze applicatie kennen we
  verschillende gebruikersmodes, ook onder ons eigen personeel.
  *Loods 5 Plus* zijn gebruikers met een volledig @loods5.nl
  account. *Loods 5 Basic* hebben alleen een @mail.loods5.nl
  account.
- *Gebruikersmode: Extern*, alle personen die horen bij onze
  deelnemers zijn *Extern*.
- *Deelnemertoegang*, ben je een medewerker van Loods 5, dan
  gaat *Deelnemertoegang* automatisch op _Mag bij alle deelnemers_.
  De persoon mag dan bij de administratie van alle deelnemers.
  Zet je dit uit, dan mag de persoon alleen bij de deelnemers die je in
  het daar opvolgende veld aanzet.
- *Workflows*, je kunt meerdere workflows met elkaar combineren,
  zo kun je iemand *Deelnemer* en *HR-meekijker* maken. Workflows
  kunnen uiteraard overlap hebben, dat is niet erg.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/fd17ab66-9c71-4a09-8671-fec85d04a1c1.png)

Er zijn een aantal velden waar we wat extra nadruk moeten
leggen op de betekenis er van:

- *Week-export (financieel)*, wanneer _Mag bij alle deelnemers_
  aangevinkt staat, heb je de mogelijkheid om de **week-export**
  aan te zetten. **Let op**, dit is een financiele rapportage!
- *Wachtwoord instellen*, direct na het klikken op *Toevoegen*
  zal de applicatie de persoon een wachtwoord-instel mail sturen.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/0f96b772-08b7-45d4-81a4-43d03bddb881.png)

De volgende e-mail krijgt de persoon wanneer je *Wachtwoord instellen*
aangevinkt hebt staan.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/32d00ae4-2f9d-4362-8816-0e6150c27f46.png)

Klikt de persoon op deze link, dan krijgen ze de volgende pagina te zien.
Op deze pagina stellen we een veilig wachtwoord voor, men kan deze overnemen, maar
dat is niet verplicht.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/9abd6874-447e-4e96-9282-a79aaf899438.png)

Na het klikken op *Wachtwoord instellen* is de persoon direct ingelogt.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/486f9d11-8aae-45ac-b7e5-208a3d0decbf.png)

Na het klikken op *Wachtwoord instellen* krijgt de persoon ook een e-mail dat
het wachtwoord is gewijzigd.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/b0ba21b9-cbae-4bc0-b7ad-159db44c77dd.png)

# Persoon aanpassen

Je kunt een persoon aanpassen door het context menu te openen en op _Aanpassen_ te klikken.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/1a094eae-4751-4653-99ed-f423815dce23.png)

Er opent een popup met alle gegevens en instellingen van de persoon.

In dit voorbeeld openen we een deelnemer, je ziet hier dat het veld _Deelnemer(s)_ is
ingevuld, en de workflow _Deelnemer_ is aangevinkt.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/7ac16fb2-dc71-4712-bd85-a72409f501be.png)

Bij deelnemers heb je niet de mogelijkheid om de _Week-export_ aan te vinken, maar wel om:

- *Weekstatements ontvangen*, iedere maandag ochtend om iets over 00:05 genereert de applicatie
  alle weekstatements. Staat deze optie aan, dan krijgt deze persoon van alle deelnemers waar
  hij aan gekoppeld is, de weekstatement-mail.
- *Dagomzet ontvangen*, iedere nacht om iets over 00:05 genereert de applicatie de dagomzetten.
  Staat deze optie aan, dan krijgt deze persoon van alle deelnemers waar hij aan gekoppeld is,
  de dagomzet-mail, **mits er omzet is**.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/a162e979-f707-4430-b61a-d68287ee15a9.png)

# Wachtwoord herstel

Vanuit het context menu kun je kiezen voor de optie _Wachtwoord_.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/fef3a3be-8092-450b-9b11-a2c7db98f5bd.png)

Er opent dan een popup waarmee je het wachtwoord-herstel kunt uitvoeren. Op het moment
dat je klikt op _Mail versturen_, zal de applicatie de persoon een link toesturen.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/6249ddb0-5f39-4a5f-b4e9-4d2117167af1.png)

Dit is een voorbeeld van de mail die de persoon ontvangt. Het proces is
hetzelfde als bij [persoon toevoegen](/doc/core/contact/person#persoon-toevoegen)

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/07652ab7-47d7-4866-8f6c-e05bc07b6a17.png)

# Archiveren

Vanuit het context menu kun je kiezen voor de optie _Archiveren_.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/d9115c9d-0d4c-462c-b29d-e1e748f65819.png)

Er opent nu een popup, deze legt je uit wat er gebeurd wanneer je de persoon archiveert. In het kort markeert de
applicatie de persoon als gearchiveerd en alle workflows bij de persoon gaan uit. Als de persoon toevallig
nog ingelogd is zal hij _direct_ geen gebruik meer kunnen maken van de applicatie.

Je kunt er voor kiezen om de persoon hier automatisch per mail van op de hoogte te brengen. 

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/d6f7ac91-6281-4a8e-a54a-e4b3796dd19a.png)

Na het archiveren vind je de person alleen nog maar terug wanneer je filtert 
op _Gearchiveerd_! Het icoontje voor de naam laat zien door middel van een
kruisje, dat de persoon niet meer kan inloggen.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/b7b6e9aa-4702-4ddf-9af9-a62684595003.png)

Wanneer de persoon probeert in te loggen, zal de applicatie aangeven
dat de persoon niet gevonden kan worden. De persoon bestaat natuurlijk
nog wel, jij kan hem terugvinden door te filteren op _Gearchiveerd_.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/ba47040a-8777-4252-aadb-ed7ac8ba046f.png)

Probeert de persoon zijn wachtwoord te herstellen, zal de applicatie ook aangeven
dat de persoon niet gevonden kan worden.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/555214fe-233c-4a85-b236-262dcacc544a.png)

# Filteren

Bovenin het scherm is er een knop waarmee je de tabel kunt filteren.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/1772a1d6-9f06-4724-b85f-4344a58a075d.png)

Als je hier op klikt dan opent er een popup. Door middel van de aangegeven velden
kun je het resultaat filteren. Er is maar een veld die standaard een waarde heeft,
dat is _Gearchiveerd_, deze staat standaard op _Actief_, om zo het overzicht
niet te vervuilen met gearchiveerd accounts.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/18e2346c-60cc-47c6-a415-22fd7a82a4c4.png)

Je ziet nu dat het filterknopje een actieve kleur gekregen
heeft, dit betekend dat er een filter actief is. Je ziet ook dat hij
de naam waar we op zoeken heeft overgenomen in het zoek veld.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-contact-person/d77f32f8-f977-47db-a88c-3283f5d869f9.png)

`;
