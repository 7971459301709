import * as React from 'react';

export default function VacancyGenericApplicationStatus({ application }: { application: ModelVacancyApplication }) {
  switch (application.status) {
    case 'applying':
      return (
        <span className="text-primary">
          <i className="fal fa-fw fa-pencil" /> Concept
        </span>
      );
    case 'pending':
      return (
        <span className="text-primary">
          <i className="fal fa-fw fa-pause" /> Wachtlijst
        </span>
      );
    case 'interview':
      return (
        <span className="text-success">
          <i className="fal fa-fw fa-microphone-alt" /> Ronde {application.round + 1}
        </span>
      );
    case 'negotiations':
      return (
        <span className="text-primary">
          <i className="fal fa-fw fa-phone-volume" /> Onderhandeling
        </span>
      );
    case 'employed':
      return (
        <span className="text-success">
          <i className="fal fa-fw fa-check" /> Aangenomen
        </span>
      );
    case 'rejected':
      return (
        <span className="text-error">
          <i className="fal fa-fw fa-trash" /> Afgewezen
        </span>
      );
    default:
      return <span className="text-error">{application.status}</span>;
  }
}
