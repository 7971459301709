import * as React from 'react';
import { http } from '../../../http';
import { AxiosError } from 'axios';
import { useTimeoutEffect } from '../../../effects';
import { MessageService } from '../../../services/MessageService';

type Props = {
  person: ModelContactPerson;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalSignoutEverywhere(props: Props) {
  const [state, setState] = React.useState({ loading: false });

  async function signout_everywhere() {
    setState({ loading: true });

    try {
      const result = await http().post('/dmz/security/signout_everywhere', { person: props.person.id });
      let message = `Je hebt het profiel van ${result.data.person.name} overal uitgelogd.`;
      if (result.data.myself) {
        message += ' Alleen deze sessie van jouw account blijft actief!';
      }

      MessageService.setMessage(message, 'success', 3000);

      props.closeModal({ reload: true });
    } catch (exc) {
      const err: AxiosError = exc;
      setState({ loading: false });
    }
  }

  useTimeoutEffect(() => {
    (async () => {
      await signout_everywhere();
    })();
  }, 1000);

  return (
    <div className="modal active" id="ModalSignoutEverywhere">
      <div className="modal-overlay" />

      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Profiel overnemen {props.person.name}</div>
        </div>
        <div className="modal-body">
          We gaan de server vragen dit profiel overal ongeldig te maken, eventuele lopende acties zullen foutmeldingen
          (gaan) opleveren bij verder gebruik. Er kan na het uitloggen <em>direct</em> geen gebruik meer gemaakt worden
          van functies op de server.
        </div>
        <div className="modal-footer">
          <button className="btn btn-link  float-left" onClick={() => props.closeModal()} disabled={state.loading}>
            <i className="fal fa-times" /> Annuleren
          </button>
        </div>
      </div>
    </div>
  );
}
