import * as React from 'react';

export default function Loading() {
  return (
    <div className="container">
      <div className="columns">
        <div className="col-4 col-mx-auto">
          <div className="card">
            <div className="card-header">
              <div className="card-title h5">We zijn aan het laden!</div>
              <div className="card-subtitle text-gray">
                Een ogenblikje geduld alstjeblieft, dit zou niet lang moeten duren.
              </div>
            </div>
            <div className="card-body">
              <div className="loading loading-lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
