import PageDocCoreContactPerson from './PageDocCoreContactPerson';
import PageDocCoreVacancies from './PageDocCoreVacancies';
import PageDocCoreOrderSponsor from './PageDocCoreOrderSponsor';
import PageDocCoreOrderPaymentSale from './PageDocCoreOrderPaymentSale';
import PageDocCoreOrderPaymentRefund from './PageDocCoreOrderPaymentRefund';
import PageDocCoreShopArticleStickerTemplate from './PageDocCoreShopArticleStickerTemplate';
import PageDocMvIntroduction from './PageDocMvIntroduction';
import PageDocOrderTenantMany from './PageDocOrderTenantMany';

const DocStructure = [
  {
    pathname: '/doc/mv/introduction',
    title: 'Introductie',
    icon: 'fa-graduation-cap',
    component: PageDocMvIntroduction,

    rights: ['core.missionvision'],
  },
  {
    pathname: '/doc/core/contact/person',
    title: 'CRM Contacten',
    icon: 'fa-users',
    component: PageDocCoreContactPerson,

    rights: ['contact.person'],
  },
  {
    pathname: '/doc/core/vacancies',
    title: 'CRM Vacatures',
    icon: 'fa-address-card',
    component: PageDocCoreVacancies,

    rights: ['vacancy.write'],
  },
  {
    pathname: '/doc/order/tenant/many',
    title: 'Order - Deelnemer',
    icon: 'fa-file-invoice',
    component: PageDocOrderTenantMany,

    rights: ['shop.orders'],
  },
  {
    pathname: '/doc/core/order/sponsor',
    title: 'Order - Sponsoring',
    icon: 'fa-gifts',
    component: PageDocCoreOrderSponsor,

    rights: ['order.sponsor'],
  },
  {
    pathname: '/doc/core/order/payment-refund',
    title: 'Order - Retour(betalingen)',
    icon: 'fa-hand-holding-usd',
    component: PageDocCoreOrderPaymentRefund,

    is_global: true,
    rights: ['shop.orders'],
  },
  {
    pathname: '/doc/core/order/payment-sale',
    title: 'Order - Betalingen',
    icon: 'fa-money-bill-wave-alt',
    component: PageDocCoreOrderPaymentSale,

    is_global: true,
    rights: ['shop.orders'],
  },
  {
    pathname: '/doc/core/shop/sticker/template',
    title: 'Stickers printen',
    icon: 'fa-sticky-note',
    component: PageDocCoreShopArticleStickerTemplate,

    rights: ['shop.articles'],
  },
];

export default DocStructure;
