import * as React from 'react';
import { PageVacancyManageCtx } from './PageVacancyManageStore';
import { http } from '../../../http';

type Props = {
  tag: ModelVacancyTag;
};

export default function ModalTagArchive({ tag }: Props) {
  const { store, context } = React.useContext(PageVacancyManageCtx);
  const [loading, setLoading] = React.useState<boolean>(false);

  async function onSubmit() {
    setLoading(true);

    try {
      await http().post('/core/vacancy/tag/archive', {
        tag: tag.id,
      });

      store.closeModal({ reload: true });
    } catch (exc) {
      console.error(exc);
      setLoading(false);
    }
  }

  return (
    <div className="modal modal-sm active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">
            Tag <strong>{tag.name}</strong> archiveren
          </div>
        </div>

        <div className="modal-body">
          <p>
            Weet je zeker dat je de tag <strong>{tag.name}</strong> wilt archiveren? Het archiveren zorgt er alleen voor
            dat deze tag niet meer geselecteerd kan worden. Huidige koppelingen aan de tag blijven actief, statistieken
            zullen bewaard blijven.
          </p>
        </div>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link" onClick={() => store.closeModal()} disabled={loading}>
                <i className="fal fa-times" /> Annuleren
              </button>
            </div>
            <div>
              <button type="submit" className="btn btn-error" onClick={() => onSubmit()} disabled={loading}>
                <i className="fal fa-trash" /> Archiveren
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
