import * as React from 'react';

export interface ToastProps {
  color?: string;
  dismiss?: () => void;
  children: React.ReactNode;
}

export function Toast(props: ToastProps) {
  return (
    <div className={`toast toast-${props.color || 'primary'}`}>
      <button className="btn btn-clear float-right" onClick={props.dismiss} />

      {props.children}
    </div>
  );
}
