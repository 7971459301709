import * as React from 'react';
import * as Yup from 'yup';
import FormGroup from '../../components/FormGroup';
import FormInput from '../../components/FormInput';
import { Form, Formik } from 'formik';
import { getTouchedErrors, randomString } from '../../utils';
import { handleSimpleFormSubmit, http, SubmitErrorResult, SubmitSuccessResult } from '../../http';
import axios, { AxiosError } from 'axios';
import { GenericDataService } from '../../services/GenericDataService';
import { ProfileService } from '../../services/ProfileService';
import { LocationService } from '../../services/LocationService';

interface Values {
  email: string;
  code: string;
  password: string;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .email('shouldBeEmail')
    .required('isRequired'),
  code: Yup.string()
    .min(8)
    .max(8)
    .required('isRequired'),
  password: Yup.string()
    .min(8)
    .max(64)
    .required('isRequired'),
});

export default function PageDmzPasswordReset({ email, code }) {
  return (
    <div
      className="container"
      style={{ backgroundImage: `url(${require('./../../assets/images/password-recovery.jpg')})` }}
    >
      <div className="columns">
        <div className="column col-sm-10 col-md-8 col-5 col-mx-auto">
          <h1 className="text-center text-light">Loods 5 - Apps</h1>

          <PasswordRecoveryComplete email={atob(email)} code={atob(code)} />
        </div>
      </div>
    </div>
  );
}

function PasswordRecoveryComplete({ email, code }) {
  const [proposedPassword] = React.useState(randomString(14));

  async function onSubmit({ email, code, password }): Promise<SubmitSuccessResult | SubmitErrorResult> {
    try {
      await http().post('/core/contact/person/password_reset_complete', {
        email,
        code,
        password,
      });

      const result = await http().post(
        '/dmz/security/authentication',
        new URLSearchParams({
          email,
          password,
        }),
      );

      axios.defaults.headers['x-session'] = result.data.session.token;

      await GenericDataService.load();

      ProfileService.setProfile({
        person: result.data.person,
        session: result.data.session,
      });

      LocationService.navigate('/');

      return {
        valid: true,
        message: 'Je nieuwe wachtwoord is ingesteld! Welkom in de ShopManager POS.',
      };
    } catch (exc) {
      console.error(exc);
      const err: AxiosError = exc;

      return {
        valid: false,
        errors: err.response.data.errors,
      };
    }
  }

  return (
    <Formik
      initialValues={{ email, code, password: '' }}
      validationSchema={schema}
      onSubmit={handleSimpleFormSubmit<Values>(onSubmit)}
      render={fp => {
        const errors = getTouchedErrors(fp.touched, fp.errors);

        return (
          <Form>
            <div className="card">
              <div className="card-header">
                <div className="card-title  h5">Wachtwoord instellen</div>
                <div className="card-subtitle text-gray">Stel hier je wachtwoord voor ShopManager POS in.</div>
              </div>
              <div className="card-body">
                <FormGroup title="E-mailadres" name="email" errors={errors}>
                  <FormInput name="email" loading={fp.isSubmitting} readOnly />
                </FormGroup>
                <FormGroup title="Verificatie-code" name="code" errors={errors}>
                  <FormInput name="code" loading={fp.isSubmitting} readOnly />
                </FormGroup>
                <FormGroup title="Wachtwoord" name="password" errors={errors}>
                  <FormInput type="password" name="password" loading={fp.isSubmitting} />
                </FormGroup>

                <p>
                  Wij stellen voor: <code>{proposedPassword}</code>
                </p>
              </div>
              <div className="card-footer">
                <div className="flex-row flex-space-between">
                  <div>
                    <button type="submit" className="btn btn-primary mr-2" disabled={fp.isSubmitting}>
                      <i className="fal fa-send" />
                      <i className="fal fa-paper-plane" /> Wachwoord instellen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}

PageDmzPasswordReset.getAppRouterClassname = () => {
  return 'PageDmzPasswordReset';
};
