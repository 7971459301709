import * as React from 'react';
import { http } from '../../../http';
import { formatCents } from '../../../utils';

type Props = {
  payment: ModelFinancePayment;
  close: (success: boolean) => void;
};

export default function ModalPaymentDelete({ payment, close }: Props) {
  const [loading, setLoading] = React.useState<boolean>(false);

  async function onSubmit() {
    setLoading(true);

    try {
      await http().post('/core/finance/payment/delete', {
        payment: payment.id,
      });

      close(true);
    } catch (exc) {
      console.error(exc);
      setLoading(false);
    }
  }

  return (
    <div className="modal modal-sm active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Betaling #{payment.id} verwijderen</div>
        </div>

        <div className="modal-body">
          <p>
            Weet je zeker dat je betaling #{payment.id} van {formatCents(payment.current_amount)} wilt verwijderen bij
            order #{payment.cart_id}. Het verwijderen van de betaling zal de order opnieuw berekenen.
          </p>
        </div>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link" onClick={() => close(false)} disabled={loading}>
                <i className="fal fa-times" /> Annuleren
              </button>
            </div>
            <div>
              <button type="submit" className="btn btn-error" onClick={() => onSubmit()} disabled={loading}>
                <i className="fal fa-trash" /> Verwijderen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
