import { Form, Formik } from 'formik';
import * as React from 'react';
import { useMemo } from 'react';
import { displayError } from '../../../components/FormGroup';
import FormInput from '../../../components/FormInput';
import FormRadio from '../../../components/FormRadio';
import FormSelect from '../../../components/FormSelect';
import { BaseStore, useDebounce, useRxjsStore } from '../../../effects';
import { http } from '../../../http';
import {
  cs,
  formatCents,
  formatCentsShort,
  formatEan13Number,
  getTouchedErrors,
  parseCents,
  playSoundNegative,
  playSoundPositive,
} from '../../../utils';
import { calculateCashSaleLineInclusive, getCashSaleLineSchema } from '../../cash/sale/CashSaleLineSchema';
import { Context, Store } from '../generic/PageOrderGenericOne';

type Props = {
  context: Context;
  store: Store;
  close: (success?: boolean) => void;
};

export default function ModalOrderAdd(props: Props) {
  const { context, store } = props;
  const [article, setArticle] = React.useState<ModelShopArticle>(null);

  async function select(id: number) {
    const res = await http().post('/core/shop/article/one', { id });
    setArticle(res.data);
  }

  async function submit() {
    // pass
  }

  return (
    <div className="modal active" id="ModalOrderAdd">
      <div className="modal-overlay" />

      <div className="modal-container" style={{ position: 'relative' }}>
        {!article && <Search select={select} close={() => props.close(false)} />}
        {!!article && (
          <Define
            article={article}
            close={() => props.close(false)}
            cancel={() => {
              setArticle(null);
            }}
            done={async values => {
              const res = await http().post('/core/order/purchase/attach_line', {
                purchase: context.purchase.id,
                article: article.id,

                amount: parseInt(values.amount),
                transfer: values.transfer,
                configuration: values.configuration,
                manual_discount_type: values.manual_discount_type,
                manual_discount_value: parseCents(values.manual_discount_value),
                manual_discount_description: values.manual_discount_description,
                manual_increase_type: values.manual_increase_type,
                manual_increase_value: parseCents(values.manual_increase_value),
                deposit_percentage: 0,
              });

              props.close(true);
            }}
          />
        )}
      </div>
    </div>
  );
}

// region Search

type SearchProps = {
  close: () => void;
  select: (id: number) => void;
};

type SearchContext = {
  loading: boolean;
  value: string;
  isOpen: boolean;
  records: ModelShopArticle[];

  article: ModelShopArticle;
};

class SearchStore extends BaseStore<SearchContext> {
  setup(): SearchContext {
    return {
      loading: false,
      value: '',
      isOpen: false,
      records: [],

      article: null,
    };
  }

  setValue(value: string) {
    this.next(draft => {
      draft.value = value;
    });
  }

  async search() {
    const value = this.current().value;

    if (value.length < 3) {
      this.next(draft => {
        draft.loading = false;
        draft.isOpen = false;
        draft.records = [];
      });
    } else {
      if (/^(#|]C1)?[0-9]+$/.exec(value)) {
        await this.loadRecordByNumber(value);
      } else {
        await this.loadRecordsBySearch(value);
      }
    }
  }

  async loadRecordByNumber(value) {
    try {
      const res = await http().post('/core/shop/article/one_by_number', { number: value });

      this.next(draft => {
        draft.loading = false;
        draft.isOpen = false;
        draft.records = [];
        draft.value = '';

        draft.article = res.data;
      });

      playSoundPositive();
    } catch (exc) {
      console.error(exc);
    }

    this.next(draft => {
      draft.loading = false;
      draft.isOpen = false;
      draft.records = [];
    });

    playSoundNegative();
  }

  async loadRecordsBySearch(value) {
    try {
      const res = await http().post('/core/shop/article/many_search', { search: value, limit: 20 });

      if (res.data.records.length) {
        this.next(draft => {
          draft.loading = false;
          draft.isOpen = true;
          draft.records = res.data.records;
        });
      }

      return;
    } catch (exc) {
      console.error(exc);
    }

    this.next(draft => {
      draft.loading = false;
      draft.isOpen = false;
      draft.records = [];
    });
  }
}

function Search({ select, close }: SearchProps) {
  const me = React.useRef<HTMLFormElement>(null);
  const { context, store } = useRxjsStore<SearchContext, SearchStore>(SearchStore);

  const debouncedSearch = useDebounce(context && context.value, 500);

  React.useEffect(() => {
    if (context && context.value) {
      store.search();
    }
  }, [debouncedSearch]);

  React.useEffect(() => {
    if (context && context.article) {
      select(context.article.id);
    }
  }, [context && context.article]);

  if (!context) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <div className="modal-title h5">Artikel toevoegen aan order</div>
      </div>

      <div className="modal-body" style={{ overflowY: 'auto', position: 'static' }}>
        <form autoComplete="off" onSubmit={e => e.preventDefault()} ref={me}>
          <div className={cs(context.loading && 'has-icon-right')}>
            <input
              type="text"
              name="search"
              value={context.value}
              onKeyUp={() => {}}
              onChange={event => {
                store.setValue(event.target.value);
              }}
              className="form-input"
              placeholder="Scannen of zoeken..."
            />
            {context.loading && (
              <div className="form-icon">
                <i className="fal fa-spin fa-spinner" />
              </div>
            )}
          </div>

          {context.isOpen && (
            <div
              className="menu"
              style={{ position: 'absolute', width: '75%', minWidth: '400px', overflow: 'auto', maxHeight: '60vh' }}
            >
              <div className="divider" data-content="Resultaten" />
              {context.records.map(article => {
                const image = article.images.length && article.images[0];

                return (
                  <div className="menu-item" key={article.id}>
                    <a
                      onClick={() => select(article.id)}
                      href="javascript:"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <div style={{ display: 'flex', border: '1px solid rgba(0,0,0,0.1)' }} className=" s-rounded p-1">
                        {image ? (
                          <img
                            className="img-fit-contain"
                            src={image.href}
                            style={{
                              width: 40,
                              height: 40,
                            }}
                            alt={article.name}
                          />
                        ) : (
                          <img src="https://via.placeholder.com/150" alt="150x150" style={{ width: 40, height: 40 }} />
                        )}
                      </div>
                      <div style={{ flexGrow: 1 }} className="ml-2 mr-2">
                        <span className="text-large">{article.name}</span>
                        <br />
                        <code>{formatEan13Number(article.number_beeyond)}</code>
                      </div>
                      <div>{formatCentsShort(article.inclusive)}</div>
                    </a>
                  </div>
                );
              })}
            </div>
          )}
        </form>
      </div>

      <div className="modal-footer">
        <div className="flex-row flex-space-between">
          <div>
            <button type="button" className="btn btn-dark-outline" onClick={() => close()} disabled={context.loading}>
              <i className="fal fa-times" /> Annuleren
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

// endregion
// region Define

type DefineProps = {
  article: ModelShopArticle;
  close: () => void;
  cancel: () => void;
  done: (values) => void;
};

function Define({ article, close, cancel, done }: DefineProps) {
  const transfer_options = article.type.transfer_options;
  const transfer_options_translations = {
    carry: 'Direct meenemen',
    delivery: 'Thuisbezorgen',
    'direct-pickup': 'Direct afhalen',
    pickup: 'Afhalen in winkel',
  };

  const schema = useMemo(
    () => getCashSaleLineSchema(article.inclusive, ['deposit_percentage', 'delivery_min_at', 'delivery_max_at']),
    [article.inclusive],
  );

  return (
    <Formik
      initialValues={{
        amount: '1',
        transfer: 'delivery',
        configuration: '',
        manual_discount_value: formatCents(0, true),
        manual_discount_type: 'percentage',
        manual_discount_description: '',
        manual_increase_value: formatCents(0, true),
        manual_increase_type: 'percentage',
      }}
      validationSchema={schema}
      onSubmit={async (values, actions) => {
        try {
          await done(values);
        } catch (exc) {
          console.error(exc);
          actions.setSubmitting(false);
        }
      }}
      render={fp => {
        const errors = getTouchedErrors(fp.touched, fp.errors);

        const inclusive = article.inclusive;
        const calculation = calculateCashSaleLineInclusive(
          article.inclusive,
          fp.values.manual_increase_type,
          fp.values.manual_increase_value,
          fp.values.manual_discount_type,
          fp.values.manual_discount_value,
        );
        const has_manual_discount = parseCents(fp.values.manual_discount_value) !== 0;

        return (
          <Form>
            <div className="modal-header">
              <div className="modal-title h5">Artikel {article.name} toevoegen aan order</div>
            </div>

            <div className="modal-body">
              <div className="form-horizontal p-0">
                <div className={cs('form-group', errors.amount && 'has-error')}>
                  <div className="column col-3 col-sm-12">
                    <label className="form-label">Aantal keer toevoegen</label>
                  </div>
                  <div className="column col-3 col-sm-12  text-right">
                    <FormInput name="amount" classNames={['text-right']} />
                    {errors.amount && <div className="form-input-hint">{displayError(errors.amount)}</div>}
                  </div>
                </div>

                {transfer_options.length > 1 && (
                  <div className={cs('form-group', errors.transfer && 'has-error')}>
                    <div className="column col-3 col-sm-12">
                      <label className="form-label">Levering</label>
                    </div>
                    <div className="column col-9 col-sm-12">
                      <FormRadio
                        name="transfer"
                        items={transfer_options
                          .filter(v => ['delivery', 'direct-pickup', 'pickup'].indexOf(v) !== -1)
                          .map(v => ({ value: v, title: transfer_options_translations[v] }))}
                      />
                    </div>
                  </div>
                )}

                <div className={cs('form-group', errors.configuration && 'has-error')}>
                  <div className="column col-3 col-sm-12">
                    <label className="form-label">Configuratie</label>
                  </div>
                  <div className="column col-9 col-sm-12">
                    <FormInput component="textarea" name="configuration" />
                  </div>
                </div>
                <div className={cs('form-group', errors.manual_increase_value && 'has-error')}>
                  <div className="column col-3 col-sm-12">
                    <label className="form-label">Opslag</label>
                  </div>
                  <div className="column col-3 col-sm-12">
                    <FormSelect name="manual_increase_type">
                      <option value="percentage">Percentage</option>
                      <option value="fixed">Vast bedrag</option>
                    </FormSelect>
                  </div>
                  <div className="column col-3 col-sm-12  text-right">
                    <FormInput name="manual_increase_value" classNames={['text-right']} />
                    {errors.manual_increase_value && (
                      <div className="form-input-hint">{displayError(errors.manual_increase_value)}</div>
                    )}
                  </div>
                  <div className="column col-3  ">
                    <div className="form-input static  text-right">{formatCents(calculation.increase_inclusive)}</div>
                  </div>
                </div>
                <div className={cs('form-group', errors.manual_discount_value && 'has-error')}>
                  <div className="column col-3 col-sm-12">
                    <label className="form-label">Korting</label>
                  </div>
                  <div className="column col-3 col-sm-12">
                    <FormSelect name="manual_discount_type">
                      <option value="percentage">Percentage</option>
                      <option value="fixed">Vast bedrag</option>
                    </FormSelect>
                  </div>
                  <div className="column col-3 col-sm-12  text-right">
                    <FormInput name="manual_discount_value" classNames={['text-right']} />
                    {errors.manual_discount_value && (
                      <div className="form-input-hint">{displayError(errors.manual_discount_value)}</div>
                    )}
                  </div>
                  <div className="column col-3  ">
                    <div className="form-input static  text-right">{formatCents(calculation.discount_inclusive)}</div>
                  </div>
                </div>
                {has_manual_discount && (
                  <div className={cs('form-group', errors.manual_discount_description && 'has-error')}>
                    <div className="column col-3 col-sm-12">
                      <label className="form-label">Reden van korting</label>
                    </div>
                    <div className="column col-9 col-sm-12">
                      <FormInput component="textarea" name="manual_discount_description" />
                      {errors.manual_discount_description && (
                        <div className="form-input-hint">{displayError(errors.manual_discount_description)}</div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="modal-body">
              Je gaat het artikel {article.name} toevoegen aan de order, het totaalbedrag van {formatCents(inclusive)}{' '}
              keer {parseInt(fp.values.amount)}x geeft het bedrag{' '}
              <strong>{formatCents(calculation.discount_inclusive * parseInt(fp.values.amount))}</strong>. Na het
              toevoegen zal de order automatisch op <em>deels betaald</em> springen.
            </div>

            <div className="modal-footer">
              <div className="flex-row flex-space-between">
                <div>
                  <button
                    type="button"
                    className="btn btn-dark-outline"
                    onClick={() => close()}
                    disabled={fp.isSubmitting}
                  >
                    <i className="fal fa-times" /> Annuleren
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark-outline ml-1"
                    onClick={() => cancel()}
                    disabled={fp.isSubmitting}
                  >
                    <i className="fal fa-recycle" /> Ander artikel
                  </button>
                </div>
                <div>
                  <button type="submit" className="btn btn-primary" disabled={fp.isSubmitting}>
                    <i className="fal fa-times" /> Toevoegen
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}

// endregion
