import * as React from 'react';
import { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { BaseStore, useRxjsStore } from '../../../effects';
import { http } from '../../../http';
import { GenericDataService } from '../../../services/GenericDataService';
import { cs, formatPercentage } from '../../../utils';
import FormInput from '../../../components/FormInput';
import GenericPagination from '../../../components/GenericPagination';
import Query from '../../../lib/Query';
import AtomTableNoRecords from '../../../components/AtomTableNoRecords';

type Context = {
  loading: boolean;

  query: ContextSearch;

  pagination?: Pagination;
  records: ModelContactTenant[];

  modal: ModalContext;
};

type ContextSearch = {
  page: number;
  per_page: number;
} & SearchContext;

type SearchContext = {
  creditor: string;
  name: string;
};

type ModalContext = {
  which: 'print-ticket';
  data: {
    cart_id: number;
  };
};

function emptyQuery() {
  return {
    page: 0,
    per_page: 12,
    creditor: '',
    name: '',
  };
}

const query = new Query<ContextSearch>('PageContactTenantMany');

class Store extends BaseStore<Context> {
  setup(): Context {
    return {
      loading: false,

      query: query.load() || emptyQuery(),

      pagination: null,
      records: [],

      modal: null,
    };
  }

  setPage = async (page: number) => {
    this.next(draft => {
      draft.query.page = page;
      query.save(draft.query);
    });

    await this.load();
  };

  async load() {
    this.next(draft => {
      draft.loading = true;
    });

    try {
      const query = this.current().query;
      const res = await http().post('/core/contact/tenant/many', {
        creditor: query.creditor || null,
        name: query.name || null,
        page: query.page,
        per_page: query.per_page,
      });

      const pagination = res.data.pagination;
      const records = res.data.records;

      this.next(draft => {
        draft.loading = false;
        draft.pagination = pagination;
        draft.records = records;
      });
    } catch (exc) {
      const err: AxiosError = exc;

      this.next(draft => {
        draft.loading = false;
        draft.records = [];
        draft.pagination = null;
      });
    }
  }

  async search(values: SearchContext) {
    this.next(draft => {
      draft.query.creditor = values.creditor || '';
      draft.query.name = values.name || '';
      draft.query.page = 0;
      query.save(draft.query);
    });

    await this.load();
  }

  openModal(ctx: ModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }
  closeModal() {
    this.next(draft => {
      draft.modal = null;
    });
  }
}

export default function PageContactTenantMany() {
  const generic = GenericDataService.useEffect('PageContactTenantMany');
  const { context, store } = useRxjsStore<Context, Store>(Store, null, () => {
    store.load();
  });

  if (!context) return null;

  const getCountryById = (id: number) => generic.data.countries[id];

  return (
    <div className="container">
      <div className="card">
        <div className={cs('card-loading', context.loading && 'active')} />

        <div className="card-header">
          <div className="card-title h5">Deelnemers overzicht</div>
        </div>
        <div className="card-body">
          <SearchForm store={store} context={context} />
        </div>
        <div className="card-body">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="has-table-shrink-nowrap text-center">
                  <i className="fal fa-fw fa-hashtag" />
                </th>
                <th>Naam</th>
                <th>Adres</th>
                <th className="has-table-shrink-nowrap">
                  <i className="fal fa-cash-register" />
                </th>
                <th className="has-table-shrink-nowrap">
                  <i className="fal fa-user-hard-hat" />
                </th>
                <th colSpan={2} />
              </tr>
            </thead>

            {context.records.length > 0 && (
              <>
                <GenericPagination pagination={context.pagination} setPage={store.setPage} span={7} />
                <tbody>
                  {context.records.map(record => {
                    const address = record.address;

                    return (
                      <tr
                        key={record.id}
                        data-id={record.id}
                        onDoubleClick={evt => {
                          evt.preventDefault();
                          // LocationService.navigate('/contact/tenant/one', { id: record.id.toString() });
                        }}
                      >
                        <td className="has-table-shrink-nowrap text-right">#{record.id}</td>
                        <td>{record.name}</td>

                        <td className="has-table-shrink-nowrap">
                          {record.address && (
                            <span>
                              {address.street} {address.housenumber}{' '}
                              {address.extension && <span>&ndash; {address.extension}</span>}
                              <br />
                              {address.zipcode}, {address.city}
                              <br />
                              {getCountryById(address.country_id).name}
                            </span>
                          )}
                        </td>
                        <td className="has-table-shrink-nowrap">{formatPercentage(record.commission_percentage)}</td>
                        <td className="has-table-shrink-nowrap">
                          {formatPercentage(record.administration_percentage)}
                        </td>
                        <td className="has-table-shrink-nowrap">&nbsp;</td>
                        <td className="has-table-shrink-nowrap" />
                      </tr>
                    );
                  })}
                </tbody>
                <GenericPagination pagination={context.pagination} setPage={store.setPage} span={7} />
              </>
            )}

            {context.records.length === 0 && (
              <tbody>
                <tr>
                  <td colSpan={7}>
                    <AtomTableNoRecords />
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}

function SearchForm({ store, context }) {
  const initialValues: SearchContext = {
    creditor: context.query.creditor,
    name: context.query.name,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, fa) => {
        await store.search(values);
        fa.setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        creditor: Yup.number(),
        name: Yup.string(),
      })}
      render={fp => (
        <Form style={{ padding: '0 1rem' }}>
          <div className={cs('form-group')}>
            <div className="columns">
              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="button"
                  className="btn btn-error-outline btn-action s-circle tooltip tooltip-right"
                  data-tooltip="Leeg alle zoekvelden."
                  disabled={context.loading}
                  onClick={async () => {
                    fp.setValues(emptyQuery());
                    await store.search(emptyQuery());
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="column col-2 col-xs-12  pr-2">
                <label className="form-label">Crediteurnummer</label>
                <FormInput name="creditor" />
              </div>
              <div className="column col-4 col-xs-12  pr-2">
                <label className="form-label">Naam</label>
                <FormInput name="name" />
              </div>

              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="submit"
                  className="btn btn-primary-outline btn-action s-circle"
                  disabled={fp.isSubmitting || context.loading}
                >
                  <i className={cs('fal fa-fw', context.loading ? 'fa-spin fa-spinner-third' : 'fa-search')} />
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
}
