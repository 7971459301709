import PageContactTenantMany from '../contact/tenant/PageContactTenantMany';

const TenantStructure = [
  {
    pathname: '/contact/tenant/many',
    title: 'Overzicht',
    icon: 'fa-desktop',
    component: PageContactTenantMany,

    rights: ['contact.tenant'],
  },
];

export default TenantStructure;
