import * as React from 'react';
import { Form, Formik } from 'formik';
import FormGroup from '../../../components/FormGroup';
import SpectreCalendar from '../../../components/spectre/SpectreCalendar';

export type ModalManyFilterValues = {
  date_from: string;
  date_till: string;
};

type Props = {
  initial: ModalManyFilterValues;
  closeModal: (values?: ModalManyFilterValues) => void;
};

export default function ModalManyFilter({ initial, closeModal }: Props) {
  function onSubmit(values: ModalManyFilterValues) {
    closeModal(values);
  }

  return (
    <Formik
      initialValues={initial}
      onSubmit={onSubmit}
      render={fp => {
        return (
          <Form>
            <div className="modal modal-sm active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">Filteren van de resultaten</div>
                </div>

                <div className="modal-body">
                  <div className="columns">
                    <div className="column col-4">
                      <FormGroup name="date_from" title="Vanaf" errors={fp.errors}>
                        <SpectreCalendar name="date_from" fp={fp} />
                      </FormGroup>
                    </div>
                    <div className="column col-4">
                      <FormGroup name="date_till" title="Tot" errors={fp.errors}>
                        <SpectreCalendar name="date_till" fp={fp} />
                      </FormGroup>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button type="submit" className="btn btn-primary">
                        <i className="fal fa-search" /> Filteren
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
