import { http, httpLive } from '../../../http';
import { BaseStore, useRxjsStore } from '../../../effects';
import { formatDate, getGlobalDevice, isWithinMz, parseCents } from '../../../utils';
import { MessageService } from '../../../services/MessageService';
import { LocationService } from '../../../services/LocationService';

export type LineFormValues = {
  amount: string;
  transfer: string;
  configuration: string;
  manual_discount_value: string;
  manual_discount_type: string;
  manual_discount_description: string;
  manual_increase_value: string;
  manual_increase_type: string;
  deposit_percentage: string;
  delivery_min_at: string;
  delivery_max_at: string;
};

type State = {
  loaded: boolean;

  touch: ModelShopTouch;
  touch_categories: ModelShopTouchCategory[];

  scroll: {
    scrollTop: number;
    scrollHeight: number;
    offsetHeight: number;
    scrollPercentage: number;
  };

  cart: ModelShopCart;
  lines: ModelShopCartLine[];
  giftcards_purchase: ModelShopCartGiftcardPurchase[];
  giftcards_payment: ModelShopCartGiftcardPayment[];
  available_discounts: ModelShopDiscount[];
  discounts: ModelShopDiscount[];

  hasKitchenArticles: boolean;

  modalGiftcardPurchaseOpen: boolean;
  modalGiftcardPurchaseData: ResponseGiftcardRetrieve;

  modalWrongCodeOpen: boolean;
  modalWrongCodeMessage?: string;
  modalAbandonOpen: boolean;
  modalParkOpen: boolean;
  modalUpdateLineOpen: boolean;
  modalUpdateLine: ModelShopCartLine;
  modalFinishOpen: boolean;

  modalCustomerSearchOpen: boolean;
  modalCustomerUpdateOpen: boolean;
  modalAddressType: 'delivery' | 'invoice';
  modalAddressSelectOpen: boolean;
  modalAddressUpdateOpen: boolean;
  modalPersonSearchOpen: boolean;
  modalTenantSearchOpen: boolean;
  modalBuzzerOpen: boolean;
  modalPaymentOpen: boolean;
  modalQuotationOpen: boolean;

  customer: ModelShopCustomer;
  address_delivery: ModelShopCustomerAddress;
  address_invoice: ModelShopCustomerAddress;

  person: ModelContactPersonSimple;
  tenant: ModelContactTenantSimple;
};

export type UpdateCustomerValues = {
  country: string;
  first: string;
  infix: string;
  last: string;
  email: string;
  phone: string;
  mobile: string;
};

export type UpdateAddressValues = {
  country: string;
  name: string;
  zipcode: string;
  housenumber: string;
  extension: string;
  street: string;
  city: string;

  has_company: string;
  company_name: string;
  company_vat: string;

  is_vies_validated?: boolean;
};

class Store extends BaseStore<State> {
  setup(): State {
    return {
      loaded: false,

      touch: null,
      touch_categories: null,

      scroll: {
        scrollTop: 0,
        scrollHeight: 0,
        offsetHeight: 0,
        scrollPercentage: 0,
      },

      cart: null,
      lines: [],

      giftcards_purchase: [],
      giftcards_payment: [],

      available_discounts: [],
      discounts: [],

      hasKitchenArticles: false,

      modalGiftcardPurchaseOpen: false,
      modalGiftcardPurchaseData: null,

      modalWrongCodeOpen: false,
      modalAbandonOpen: false,
      modalParkOpen: false,
      modalUpdateLineOpen: false,
      modalUpdateLine: null,

      modalFinishOpen: false,

      modalCustomerSearchOpen: false,
      modalCustomerUpdateOpen: false,
      modalAddressType: null,
      modalAddressSelectOpen: false,
      modalAddressUpdateOpen: false,
      modalPersonSearchOpen: false,
      modalTenantSearchOpen: false,
      modalBuzzerOpen: false,
      modalPaymentOpen: false,
      modalQuotationOpen: false,

      customer: null,
      address_delivery: null,
      address_invoice: null,

      person: null,
      tenant: null,
    };
  }

  // region Helpers

  private getCartId() {
    const cart = this.current().cart;
    return cart && cart.id;
  }

  private getLineById(line_id: number) {
    return this.current().lines.find(l => l.id === line_id);
  }
  private getLineByArticleId(article_id: number) {
    return this.current()
      .lines.reverse()
      .find(l => l.article_id === article_id);
  }

  private fireFocusEvent() {
    setTimeout(() => {
      document.dispatchEvent(new Event('focus-search'));
    }, 5);
  }

  // endregion
  // region Load

  load = async () => {
    this.next(draft => {
      draft.loaded = false;
    });

    try {
      const group = isWithinMz() && getGlobalDevice().group;
      const data = { group: group && group.id };
      const res = await http().post('/sale/touch/one_by_group', data);

      this.next(draft => {
        draft.touch = res.data.touch;
        draft.touch_categories = res.data.categories;
      });
    } catch (exc) {
      console.error(exc, 'No touch definition');
    }

    this.next(draft => {
      draft.loaded = true;
    });
  };

  // endregion
  // region Interface

  updateDimensions = (scrollHeight: number, offsetHeight: number) => {
    this.next(draft => {
      draft.scroll.scrollHeight = scrollHeight;
      draft.scroll.offsetHeight = offsetHeight;
    });
  };

  scroll = (direction: number) => {
    const { scrollTop, scrollHeight, offsetHeight } = this.current().scroll;

    let nextScrollTop = scrollTop + (direction > 0 ? 90 : -90);
    const height = scrollHeight - offsetHeight;

    if (nextScrollTop < 0) {
      nextScrollTop = 0;
    } else if (nextScrollTop + offsetHeight > scrollHeight) {
      nextScrollTop = height;
    }

    this.next(draft => {
      draft.scroll.scrollTop = nextScrollTop;
      draft.scroll.scrollPercentage = height <= 0 ? 100 : (scrollTop / height) * 100;
    });
  };

  toggleWrongCode(open: boolean, message?: string) {
    this.next(draft => {
      draft.modalWrongCodeOpen = open;
      draft.modalWrongCodeMessage = message;
    });
  }

  toggleAbandon(open: boolean) {
    this.next(draft => {
      draft.modalAbandonOpen = open;
    });
    if (!open) this.fireFocusEvent();
  }
  togglePark(open: boolean) {
    this.next(draft => {
      draft.modalParkOpen = open;
    });
    if (!open) this.fireFocusEvent();
  }

  modalQuotationOpen() {
    this.next(draft => {
      draft.modalQuotationOpen = true;
    });
  }
  modalQuotationClose() {
    this.next(draft => {
      draft.modalQuotationOpen = false;
    });
    this.fireFocusEvent();
  }

  async modalPaymentOpen() {
    const group = isWithinMz() && getGlobalDevice().group;

    if (group) {
      try {
        await httpLive().post('/display/update', {
          group_id: group.id,
          payload: {
            pane: 'payment',
            cart_id: this.current().cart.id,
          },
        });
      } catch (exc) {
        console.error('Could not talk to http-live', exc);
      }
    }

    this.next(draft => {
      draft.modalPaymentOpen = true;
    });
  }
  async modalPaymentClose(success: boolean) {
    const group = isWithinMz() && getGlobalDevice().group;

    if (success) {
      await this.abandon();

      if (group) {
        try {
          await httpLive().post('/display/update', {
            group_id: group.id,
            payload: {
              pane: 'idle',
              cart_id: null,
            },
          });
        } catch (exc) {
          console.error('Could not talk to http-live', exc);
        }
      }
    } else {
      this.next(draft => {
        draft.modalPaymentOpen = false;
      });

      if (group) {
        try {
          await httpLive().post('/display/update', {
            group_id: group.id,
            payload: {
              pane: 'cart',
              cart_id: this.current().cart.id,
            },
          });
        } catch (exc) {
          console.error('Could not talk to http-live', exc);
        }
      }

      this.fireFocusEvent();
    }
  }

  toggleModalCustomerSearch(open: boolean = null) {
    this.next(draft => {
      draft.modalCustomerSearchOpen = open !== null ? open : !draft.modalCustomerSearchOpen;
    });

    if (!open) this.fireFocusEvent();
  }
  toggleModalCustomerUpdate(open: boolean = null) {
    this.next(draft => {
      draft.modalCustomerUpdateOpen = open !== null ? open : !draft.modalCustomerUpdateOpen;
    });

    if (!open) this.fireFocusEvent();
  }
  toggleModalAddressSelect(type: 'delivery' | 'invoice' = null, open: boolean = null) {
    this.next(draft => {
      draft.modalAddressType = type;
      draft.modalAddressSelectOpen = open !== null ? open : !draft.modalAddressSelectOpen;
    });

    if (!open) this.fireFocusEvent();
  }
  toggleModalAddressUpdate(type: 'delivery' | 'invoice' = null, open: boolean = null) {
    this.next(draft => {
      draft.modalAddressType = type;
      draft.modalAddressUpdateOpen = open !== null ? open : !draft.modalAddressUpdateOpen;
    });

    if (!open) this.fireFocusEvent();
  }

  toggleModalPersonSearch(open: boolean = null) {
    this.next(draft => {
      draft.modalPersonSearchOpen = open !== null ? open : !draft.modalPersonSearchOpen;
    });

    if (!open) this.fireFocusEvent();
  }
  toggleModalTenantSearch(open: boolean = null) {
    this.next(draft => {
      draft.modalTenantSearchOpen = open !== null ? open : !draft.modalTenantSearchOpen;
    });

    if (!open) this.fireFocusEvent();
  }

  toggleModalBuzzer(open: boolean) {
    this.next(draft => {
      draft.modalBuzzerOpen = open;
    });

    if (!open) this.fireFocusEvent();
  }

  // endregion
  // region Finish

  openFinish() {
    this.next(draft => {
      draft.modalFinishOpen = true;
    });
  }
  closeFinish(success: boolean = false) {
    if (success) {
      this.abandon();
    } else {
      this.next(draft => {
        draft.modalFinishOpen = false;
      });
    }

    if (!success) this.fireFocusEvent();
  }

  // endregion
  // region Customer

  anonymize = async () => {
    await http().post('/sale/cart/anonymize', { cart: this.getCartId() });
    await this.update();
  };

  setCustomer = async (customer: ModelShopCustomer) => {
    const res = await http().post('/sale/cart/customer_select', {
      cart: this.getCartId(),
      customer: customer.id,
    });

    if (!this.getCartId()) {
      await this.update(res.data.cart_id);
    }

    if (customer.default_address_delivery_id) {
      await http().post('/sale/cart/address_delivery_select', {
        cart: this.getCartId(),
        address: customer.default_address_delivery_id,
      });
    }

    if (customer.default_address_invoice_id) {
      await http().post('/sale/cart/address_invoice_select', {
        cart: this.getCartId(),
        address: customer.default_address_invoice_id,
      });
    }

    await this.update();
  };

  updateCustomer = async (values: UpdateCustomerValues) => {
    const customer_id = this.current().customer && this.current().customer.id;

    const res = await http().post('/sale/cart/customer_update', {
      id: customer_id,
      ...values,
    });

    await http().post('/sale/cart/customer_select', {
      cart: this.getCartId(),
      customer: res.data.customer_id,
    });

    await this.update();
  };

  setDeliveryAddress = async (address: ModelShopCustomerAddress) => {
    await http().post('/sale/cart/address_delivery_select', {
      cart: this.getCartId(),
      address: address.id,
    });

    await this.update();
  };

  setInvoiceAddress = async (address: ModelShopCustomerAddress) => {
    await http().post('/sale/cart/address_invoice_select', {
      cart: this.getCartId(),
      address: address.id,
    });

    await this.update();
  };

  updateDeliveryAddress = async (values: UpdateAddressValues) => {
    const address_id = this.current().address_delivery && this.current().address_delivery.id;

    const res = await http().post('/sale/cart/address_update', {
      id: address_id,
      customer: this.current().customer.id,
      ...values,
    });

    await http().post('/sale/cart/address_delivery_select', {
      cart: this.getCartId(),
      address: res.data.address_id,
    });

    await this.update();
  };

  updateInvoiceAddress = async (values: UpdateAddressValues) => {
    const address_id = this.current().address_invoice && this.current().address_invoice.id;

    const res = await http().post('/sale/cart/address_update', {
      id: address_id,
      customer: this.current().customer.id,
      ...values,
    });

    await http().post('/sale/cart/address_invoice_select', {
      cart: this.getCartId(),
      address: res.data.address_id,
    });

    await this.update();
  };

  setPerson = async (person: ModelContactPersonSimple) => {
    const res = await http().post('/sale/cart/person_select', {
      cart: this.getCartId(),
      person: person.id,
    });

    this.next(draft => {
      draft.modalPersonSearchOpen = false;
    });

    await this.update(res.data.cart_id);
  };

  setTenant = async (tenant: ModelContactTenantSimple) => {
    const res = await http().post('/sale/cart/tenant_select', {
      cart: this.getCartId(),
      tenant: tenant.id,
    });

    this.next(draft => {
      draft.modalTenantSearchOpen = false;
    });

    await this.update(res.data.cart_id);
  };

  // endregion
  // region Cart

  addOrUpdate = async article_id => {
    const line = this.getLineByArticleId(article_id);

    if (line) {
      await this.updateLineAmount(line.id, 1);
    } else {
      const res = await http().post('/sale/cart/line_add', {
        cart: this.getCartId(),
        article: article_id,
        transfer: isWithinMz() ? 'carry' : 'delivery',
        deposit_percentage: isWithinMz() ? 10000 : 0,
      });

      await this.update(res.data.cart_id);
    }
  };

  cloneLine = async (line_id: number) => {
    const line = this.getLineById(line_id);

    if (line) {
      await http().post('/sale/cart/line_add', {
        cart: this.getCartId(),
        article: line.article_id,
      });

      await this.update();
    }
  };

  updateLineAmount = async (line_id: number, amount: number) => {
    const line = this.getLineById(line_id);

    await http().post('/sale/cart/line_update', {
      cart: this.getCartId(),
      line: line.id,
      amount: line.amount + amount,
      transfer: line.transfer,
      deposit_percentage: line.deposit_percentage,
      manual_discount_type: line.manual_discount_type,
      manual_discount_value: line.manual_discount_value,
      manual_discount_description: line.manual_discount_description,
      manual_increase_type: line.manual_increase_type,
      manual_increase_value: line.manual_increase_value,
      configuration: line.configuration,
      delivery_min_at: line.delivery_min_at,
      delivery_max_at: line.delivery_max_at,
    });

    await this.update();
  };

  update = async (cart_id?: number, ignore_display_update: boolean = false) => {
    const group = isWithinMz() && getGlobalDevice().group;
    const found_cart_id = cart_id || this.getCartId();

    if (!ignore_display_update && group) {
      try {
        await httpLive().post('/display/update', {
          group_id: group.id,
          payload: {
            pane: 'cart',
            cart_id: found_cart_id,
          },
        });
      } catch (exc) {
        console.error('Could not talk to http-live', exc);
      }
    }

    const res = await http().post('/sale/cart/one', {
      cart: found_cart_id,
    });

    const cart = res.data.cart;
    const lines = res.data.lines;
    const customer = res.data.customer;
    const address_delivery = res.data.address_delivery;
    const address_invoice = res.data.address_invoice;
    const person = res.data.person;
    const tenant = res.data.tenant;
    const giftcards_purchase = res.data.giftcards_purchase;
    const giftcards_payment = res.data.giftcards_payment;
    const available_discounts = res.data.available_discounts;
    const discounts = res.data.discounts;

    if (cart.state === 'parked') {
      const sco_res = await http().post('/sale/cart/open', { cart: cart.id });
      if (sco_res.data === false) {
        MessageService.setMessage(
          `De order ${cart_id} is van de parkeerlijst afgehaald, hij was al afgerond.`,
          'error',
        );

        await this.abandon();
      } else {
        MessageService.setMessage(
          `De order ${cart_id} is van de parkeerlijst afgehaald, je hebt hem nu overgenomen.`,
          'success',
        );

        await this.update(cart.id);
      }
    } else {
      if (cart.state === 'paid' && !group) {
        // We are outside the MZ, so selling is not our forté, redirect
        // the user to the order page.
        const res = await http().post('/core/order/purchase/resolve', { cart: cart.id });

        if (res.data.purchase_tenant_id) {
          LocationService.navigate(`/order/tenant/one`, { id: res.data.purchase_tenant_id.toString() });
        } else {
          LocationService.navigate(`/order/generic/one`, { id: res.data.purchase_id.toString() });
        }
      } else if (cart.state !== 'open') {
        await this.abandon();
      } else {
        this.next(draft => {
          if (draft.cart === null || draft.cart.id != cart.id) {
            const url = `/cash/sale?id=${cart.id}`;
            window.history.replaceState(null, url, url);
          }

          draft.cart = cart;
          draft.lines = lines;
          draft.customer = customer;
          draft.address_delivery = address_delivery;
          draft.address_invoice = address_invoice;
          draft.person = person;
          draft.tenant = tenant;
          draft.giftcards_purchase = giftcards_purchase;
          draft.giftcards_payment = giftcards_payment;
          draft.available_discounts = available_discounts;
          draft.discounts = discounts;

          draft.hasKitchenArticles = lines.some(x => x.article.is_special_kitchen);
        });
      }
    }
  };

  open = async (line_id: number) => {
    this.next(draft => {
      draft.modalUpdateLine = this.getLineById(line_id);
      draft.modalUpdateLineOpen = true;
    });
  };

  close = async (values?: LineFormValues) => {
    const { id } = this.current().modalUpdateLine;

    if (values) {
      await http().post('/sale/cart/line_update', {
        cart: this.getCartId(),
        line: id,
        amount: values.amount,
        transfer: values.transfer,
        configuration: values.configuration,
        manual_discount_type: values.manual_discount_type,
        manual_discount_value: parseCents(values.manual_discount_value),
        manual_discount_description: values.manual_discount_description,
        manual_increase_type: values.manual_increase_type,
        manual_increase_value: parseCents(values.manual_increase_value),
        deposit_percentage: values.deposit_percentage,
        delivery_min_at: formatDate(values.delivery_min_at, 'YYYY-MM-DD'),
        delivery_max_at: formatDate(values.delivery_max_at, 'YYYY-MM-DD'),
      });

      await this.update();
    }

    this.next(draft => {
      draft.modalUpdateLine = null;
      draft.modalUpdateLineOpen = false;
    });

    this.fireFocusEvent();
  };

  park = async () => {
    const group = isWithinMz() && getGlobalDevice().group;
    const cart_id = this.getCartId();

    await http().post('/sale/cart/park', {
      cart: cart_id,
      building_id: group && group.building_id,
    });

    MessageService.setMessage(
      `Order ${cart_id} is geparkeerd, deze kan opgepakt worden uit de parkeerlijst.`,
      'success',
      5000,
    );

    await this.abandon();
  };

  abandon = async (update: boolean = false) => {
    const group = isWithinMz() && getGlobalDevice().group;
    const cart_id = this.getCartId();

    if (group) {
      try {
        await httpLive().post('/display/update', {
          group_id: group.id,
          payload: {
            pane: 'idle',
            cart_id: null,
          },
        });
      } catch (exc) {
        console.error('Could not talk to http-live', exc);
      }
    }

    if (update && cart_id) {
      await http().post('/sale/cart/abandon', {
        cart: cart_id,
      });

      MessageService.setMessage(`Order ${cart_id} is geannuleerd.`, 'success', 5000);
    }

    this.next(draft => {
      window.history.replaceState(null, null, `/cash/sale`);

      draft.cart = null;
      draft.lines = [];
      draft.customer = null;
      draft.address_delivery = null;
      draft.address_invoice = null;
      draft.tenant = null;
      draft.person = null;
      draft.giftcards_purchase = [];
      draft.giftcards_payment = [];
      draft.hasKitchenArticles = false;
      draft.discounts = [];
      draft.available_discounts = [];

      draft.modalAbandonOpen = false;
      draft.modalGiftcardPurchaseOpen = false;
      draft.modalGiftcardPurchaseData = null;
      draft.modalParkOpen = false;
      draft.modalUpdateLineOpen = false;
      draft.modalFinishOpen = false;
      draft.modalCustomerSearchOpen = false;
      draft.modalCustomerUpdateOpen = false;
      draft.modalAddressSelectOpen = false;
      draft.modalAddressUpdateOpen = false;
      draft.modalPaymentOpen = false;
      draft.modalQuotationOpen = false;
    });
  };

  attachDiscount = async (discount_id: number) => {
    try {
      const res = await http().post('/sale/cart/discount_attach', {
        cart: this.getCartId(),
        discount: discount_id,
      });

      await this.update(res.data.cart_id);

      return true;
    } catch (exc) {
      console.error(exc);
      return false;
    }
  };

  async buzzer(number_buzzer: number) {
    const res = await http().post('/sale/cart/buzzer_set', {
      cart: this.getCartId(),
      number_buzzer,
    });

    await this.update(res.data.cart_id);
  }

  async closeCartWithoutPayment() {
    const device = isWithinMz() && getGlobalDevice();
    const group = device && device.group;
    const terminal = device && device.terminal;

    const res = await http().post('/sale/cart/close', {
      cart: this.getCartId(),
      group: group && group.id,
      terminal: terminal && terminal.id,
    });

    await this.update(res.data.cart_id);
  }

  // endregion
  // region Giftcard

  toggleModalGiftcardPurchase(data?: ResponseGiftcardRetrieve) {
    if (data === null) {
      this.next(draft => {
        draft.modalGiftcardPurchaseOpen = false;
        draft.modalGiftcardPurchaseData = null;
      });
    } else {
      this.next(draft => {
        draft.modalGiftcardPurchaseOpen = true;
        draft.modalGiftcardPurchaseData = data;
      });
    }
  }

  async addGiftcardPurchase(balance: number): Promise<boolean> {
    const data = this.current().modalGiftcardPurchaseData;

    try {
      const res = await http().post('/sale/cart/giftcard_purchase', {
        cart: this.getCartId(),
        identifier: data.identifier,
        balance,
      });

      await this.update(res.data.cart_id);

      return true;
    } catch (exc) {
      console.error(exc);
      return false;
    }
  }

  async addGiftcardPayment(data: ResponseGiftcardRetrieve): Promise<boolean> {
    try {
      const res = await http().post('/sale/cart/giftcard_payment', {
        cart: this.getCartId(),
        identifier: data.identifier,
      });

      await this.update(res.data.cart_id);

      return true;
    } catch (exc) {
      console.error(exc);
      return false;
    }
  }

  async deleteGiftcardPayment(payment_id: number) {
    try {
      const res = await http().post('/sale/cart/giftcard_payment_delete', {
        cart: this.getCartId(),
        payment: payment_id,
      });

      await this.update(res.data.cart_id);

      return true;
    } catch (exc) {
      console.error(exc);
      return false;
    }
  }

  async deleteGiftcardPurchase(purchase_id: number) {
    try {
      const res = await http().post('/sale/cart/giftcard_purchase_delete', {
        cart: this.getCartId(),
        purchase: purchase_id,
      });

      await this.update(res.data.cart_id);

      return true;
    } catch (exc) {
      console.error(exc);
      return false;
    }
  }

  // endregion
}

export type PageDefinition = {
  context: State;
  store: Store;
};

export default function usePageStore(): PageDefinition {
  return useRxjsStore<State, Store>(Store);
}
export type PageCashSaleCartArticleBase = {
  id: number;
  name: string;

  type_id: number;
  tenant_id: number;

  inclusive: number;
  exclusive: number;
  vat: number;
  vat_percentage: number;

  number: string;
  number_foreign: string;
  number_lotus: string;
  number_beeyond: string;
  number_gtin: string;
  number_mpn: string;
  number_external: string;

  image_href?: string;
};

export type PageCashSaleCartArticle = {
  position: number;
  amount: number;

  configuration: string;

  increase_type: 'percentage' | 'fixed';
  increase_value: number;
  increase_price: number;

  discount_type: 'percentage' | 'fixed';
  discount_value: number;
  discount_price: number;

  final_inclusive: number;
  final_exclusive: number;
  final_vat: number;

  isConfigurable: boolean;
  isOpen: boolean;
} & PageCashSaleCartArticleBase;

export type PageCashSaleCartState = {
  amount: number;
  inclusive: number;

  cart?: ModelShopCart;
  lines: ModelShopCartLine[];

  articles: PageCashSaleCartArticle[];

  modalUpdateArticleOpen: boolean;
  modalUpdateArticle: PageCashSaleCartArticle;
};

export type PageCashSaleScrollState = {
  scrollTop: number;
  scrollHeight: number;
  offsetHeight: number;
  scrollPercentage: number;
};
