import * as React from 'react';
import { http } from '../../../http';
import { draftToValue, loadOneRecord, sanitizeFormValues, valueToDraft } from '../../../utils';
import { Form, Formik, FormikActions } from 'formik';
import FormGroup from '../../../components/FormGroup';
import FormInput from '../../../components/FormInput';
import { AxiosError } from 'axios';
import * as Yup from 'yup';
import FormEditor from '../../../components/FormEditor';

type Props = {
  application: ModelVacancyApplication;
  closeModal: ({ reload: boolean }?) => void;
};

type Values = {
  subject: string;
};

const schema = Yup.object().shape({
  subject: Yup.string().min(3, 'stringMinLength'),
});

export default function ModalApplicationMail({ application, closeModal }: Props) {
  const [loaded, setLoaded] = React.useState(false);
  const [mail, setMail] = React.useState<ModelVacancyMail>(null);

  React.useEffect(() => {
    (async () => {
      setMail(
        await loadOneRecord('/core/vacancy/application/determine_mail', {
          application: application.id,
        }),
      );

      setLoaded(true);
    })();
  }, []);

  if (!loaded) return null;

  const initial = sanitizeFormValues({
    subject: mail.subject,
    description_state: valueToDraft(mail.description_json, mail.description_html || ''),
  });

  async function onSubmit(values: Values, formikActions: FormikActions<Values>) {
    const [description_json, description_html] = draftToValue((values as any).description_state);

    try {
      await http().post('/core/vacancy/application/mail', {
        application: application.id,

        subject: values.subject,

        description_json: JSON.stringify(description_json),
        description_html: description_html,
      });

      closeModal({ reload: true });
    } catch (exc) {
      const err: AxiosError = exc;
      console.error(exc);

      if (err.response.data && err.response.data.errors) {
        formikActions.setErrors(err.response.data.errors);
      }

      formikActions.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={initial}
      validationSchema={schema}
      onSubmit={onSubmit}
      render={fp => {
        return (
          <Form>
            <div className="modal modal-lg active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">
                    Sollicitant <strong>{fp.values.first}</strong> mailen
                  </div>
                </div>

                <div className="modal-body">
                  <FormGroup title="Onderwerp van de mail" name="subject" errors={fp.errors}>
                    <FormInput name="subject" loading={fp.isSubmitting} />
                  </FormGroup>

                  <FormGroup name="description_state" title="Content van de mail" errors={fp.errors}>
                    <FormEditor
                      name="description_state"
                      onChange={(n, s) => fp.setFieldValue(n, s)}
                      editorState={(fp.values as any).description_state}
                    />
                  </FormGroup>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={fp.isSubmitting || Object.keys(fp.errors).length > 0}
                      >
                        <i className="fal fa-envelope" /> Mail versturen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
