import { cs } from '../../../utils';
import * as React from 'react';
import ComponentColgroup from './ComponentColgroup';
import { PageDefinition } from './PageState';

type ComponentSaleHeaderProps = {
  page: PageDefinition;
};

export default function ComponentHeader(props: ComponentSaleHeaderProps) {
  function onClick() {
    props.page.store.scroll(-1);
  }

  const isDisabled = props.page.context.scroll.scrollTop === 0;
  const isLarge = (props.page.context.touch && props.page.context.touch.is_large);

  return (
    <table className="table table-fixed table-small">
      <ComponentColgroup isLarge={isLarge}/>
      <thead>
        <tr>
          <th>&nbsp;</th>
          {!isLarge && <th>&nbsp;</th>}
          {!isLarge && <th>&nbsp;</th>}
          <th>Artikel</th>
          {!isLarge && <th className="text-right">Totaal</th>}
          <th className="text-right">
            <a onClick={onClick} className={cs('btn btn-primary btn-action s-circle', isDisabled && 'disabled')}>
              <i className="fal fa-arrow-up" />
            </a>
          </th>
        </tr>
      </thead>
    </table>
  );
}
