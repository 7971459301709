import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { FormikActions } from 'formik';
import { MessageService } from './services/MessageService';

export function http(): AxiosInstance {
  const instance = axios.create({
    baseURL: GLOBAL_APIS_URL,
  });

  instance.interceptors.response.use(
    resp => {
      return resp;
    },
    error => {
      const res = error.response as AxiosResponse | undefined;
      if (res !== undefined) {
        const has_expired = res.data.errors?.session?.message === 'sessionHasExpired';

        if (has_expired) {
          const ProfileService = require('./services/ProfileService').ProfileService;

          MessageService.setMessage('Je sessie is verlopen, je moet opnieuw inloggen.', 'error', 10000);
          ProfileService.empty();
        }
      }

      console.error('Request.onRejected', error.response);

      return Promise.reject(error);
    },
  );

  return instance;
}

export function httpLive(): AxiosInstance {
  return axios.create({
    baseURL: GLOBAL_LIVE_URL,
  });
}

export interface SubmitSuccessResult {
  valid: true;
  message?: string;
}

export interface SubmitErrorResult {
  valid: false;
  message?: string;
  errors: { [name: string]: string };
}

export type SubmitResult = SubmitSuccessResult | SubmitErrorResult;

type SimpleFormSubmitCallback<FormValues> = (values: FormValues) => Promise<SubmitSuccessResult | SubmitErrorResult>;

export function handleSimpleFormSubmit<FormValues>(sendData: SimpleFormSubmitCallback<FormValues>) {
  return async (values: FormValues, actions: FormikActions<FormValues>) => {
    const result = await sendData(values);

    if (result) {
      if (result.valid === true) {
        MessageService.setMessage(result.message || 'Opgeslagen op de server!', 'success', 3000);
      } else {
        MessageService.setMessage(result.message || 'Er is een fout ontstaan op de server.', 'error', 3000);
        actions.setErrors(result.errors as any);
      }

      actions.setSubmitting(false);
    }
  };
}

export function handleHttpError(exc: any, errorMessage?: string): SubmitErrorResult {
  const err: AxiosError = exc;

  if (err.response) {
    if (err.response.status !== 400) {
      return {
        valid: false,
        message: `Server fout ${err.response.status}.`,
        errors: {},
      };
    }

    return {
      valid: false,
      message: errorMessage || 'Het formulier kon niet correct verwerkt worden op de server.',
      errors: err.response.data.errors,
    };
  }

  console.error(err);

  return {
    valid: false,
    message: 'We kunnen geen verbinding maken met de server, heb je internet?',
    errors: {},
  };
}
