import * as React from 'react';
import { PageDefinition } from './PageState';

type Props = {
  page: PageDefinition;
};

export default function ModalWrongCode(props: Props) {
  const [seconds, setSeconds] = React.useState(5);

  React.useEffect(() => {
    if (seconds <= 0) {
      setTimeout(() => {
        props.page.store.toggleWrongCode(false);
      }, 250);
    } else {
      const timeout = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [seconds]);

  function onClose() {
    props.page.store.toggleWrongCode(false);
  }

  return (
    <div className="modal active">
      <div className="modal-overlay" />
      <div className="modal-container  bg-error">
        <div className="modal-header">
          <div className="modal-title h5  text-light">Artikel niet gevonden</div>
        </div>

        <div className="modal-body">
          <div className="text-center">
            <p className="h3">
              <i className="fal fa-exclamation-triangle" /> {props.page.context.modalWrongCodeMessage}
            </p>
          </div>
        </div>

        <div className="modal-footer">
          <button type="button" className="btn btn-link btn-lg  float-right  text-light" onClick={onClose}>
            <i className="fal fa-times" /> Sluiten over {seconds} seconden...
          </button>
        </div>
      </div>
    </div>
  );
}
