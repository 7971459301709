import * as React from 'react';
import { http } from '../../../http';
import { Form, Formik, FormikActions } from 'formik';
import { AxiosError } from 'axios';
import FormGroup from '../../../components/FormGroup';
import FormOptionSwitch from '../../../components/FormOptionSwitch';

type Props = {
  person: ModelContactPerson;
  closeModal: ({ reload: boolean }?) => void;
};

type Values = {
  notify: string;
};

export default function ModalContactPersonArchive({ person, closeModal }: Props) {
  async function onSubmit({ notify }, formikActions: FormikActions<Values>) {
    try {
      await http().post('/core/contact/person/archive', {
        person: person.id,
        notify,
      });

      closeModal({ reload: true });
    } catch (exc) {
      const err: AxiosError = exc;
      console.error(exc);

      if (err.response.data && err.response.data.errors) {
        formikActions.setErrors(err.response.data.errors);
      }

      formikActions.setSubmitting(false);
    }
  }

  return (
    <Formik
      initialValues={{ notify: '' }}
      onSubmit={onSubmit}
      render={fp => {
        return (
          <Form>
            <div className="modal modal-sm active">
              <div className="modal-overlay" />
              <div className="modal-container">
                <div className="modal-header">
                  <div className="modal-title h5">
                    Profiel van <strong>{person.first}</strong> archiveren
                  </div>
                </div>

                <div className="modal-body">
                  <p>
                    Je gaat het profiel van {person.first} archiveren. Wanneer je het profiel archiveert, kan{' '}
                    {person.first} niet meer inloggen in ShopManager POS.
                  </p>
                  <p>
                    Deze actie doet twee dingen, het zet het profiel op gearchiveerd, en het verwijdert alle rechten die
                    ingesteld staan. Als {person.first} nu is aangemeld zullen alle acties die hierna worden uitgevoerd
                    automatisch blokkeren.
                  </p>
                  <p>
                    Gearchiveerde profielen zullen altijd blijven bestaand, je kunt het profiel ook weer activeren mocht
                    dit nodig zijn. Vergeet in het overzicht niet te filteren op <strong>Status: Gearchiveerd</strong>!
                  </p>

                  <FormGroup name="notify" title="Notificatie versturen">
                    <FormOptionSwitch
                      name="notify"
                      title_on="E-mail notificatie versturen."
                      title_off="Geen notificatie versturen."
                    />
                  </FormGroup>
                </div>

                <div className="modal-footer">
                  <div className="flex-row flex-space-between">
                    <div>
                      <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                        <i className="fal fa-times" /> Annuleren
                      </button>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn btn-error"
                        disabled={fp.isSubmitting || Object.keys(fp.errors).length > 0}
                      >
                        <i className="fal fa-stop-circle" /> Archiveren
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}
