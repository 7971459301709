import * as React from 'react';
import { GenericDataService } from '../../../services/GenericDataService';
import { compactOrderPurchaseLines, cs, formatCents, formatEan13Number, formatMomentAgo } from '../../../utils';
import ComponentOrderPurchaseLineState from '../../../components/ComponentOrderPurchaseLineState';
import ComponentOrderPurchaseLineTransfer from '../../../components/ComponentOrderPurchaseLineTransfer';

type Props = { purchase_tenant: ModelOrderPurchaseTenant; context: any; store: any };

export default function ComponentOrderPurchaseTenantLines(props: Props) {
  const { purchase_tenant, context, store } = props;
  const fully_paid = context.purchase.paid >= context.purchase.current_inclusive;
  const generic = GenericDataService.useEffect('PageOrderGenericOne');
  const tenant = generic.data.tenants[purchase_tenant.tenant_id];

  const shrunken_lines = compactOrderPurchaseLines(purchase_tenant.lines);

  return (
    <div className="card  mt-2" key={purchase_tenant.id}>
      <div className="card-header">
        <div className="flex-row">
          <div className="flex-grow">
            <div className="card-title h5">
              Deel-order #{purchase_tenant.id} van {tenant.name}
            </div>
          </div>
          <div className="flex-shrink">
            <Buttons purchase_tenant={purchase_tenant} store={store} fully_paid={fully_paid} />
          </div>
        </div>
      </div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr>
              <th>Status</th>
              <th>Nummer</th>
              <th>Artikel</th>
              <th>Aantal</th>
              <th>Gekocht</th>
              <th>Levering</th>
              <th>Bedrag</th>
            </tr>
          </thead>
          <tbody>
            {shrunken_lines.map((line, lx) => (
              <React.Fragment key={lx}>
                <tr className={cs(!!line.configuration && 'no-border-bottom')}>
                  <td className="has-table-shrink-nowrap">
                    <ComponentOrderPurchaseLineState state={line.state} />
                  </td>
                  <td className="has-table-shrink-nowrap">{formatEan13Number(line.article.number_beeyond)}</td>
                  <td>{line.article.name}</td>
                  <td className="has-table-shrink-nowrap  text-right">{line.amount}</td>
                  <td className="has-table-shrink-nowrap">{formatMomentAgo(line.created_at)}</td>
                  <td className="has-table-shrink-nowrap">
                    <ComponentOrderPurchaseLineTransfer transfer={line.transfer_bought} />
                  </td>
                  <td className="has-table-shrink-nowrap  text-right">{formatCents(line.inclusive)}</td>
                </tr>
                {!!line.configuration && (
                  <tr>
                    <td colSpan={2}>&nbsp;</td>
                    <td>{line.configuration}</td>
                    <td colSpan={4}>&nbsp;</td>
                  </tr>
                )}
              </React.Fragment>
            ))}

            <tr className="bg-primary">
              <td colSpan={3} />
              <td className="has-table-shrink-nowrap  text-right">
                {shrunken_lines.reduce((r, x) => r + x.amount, 0)}
              </td>
              <td colSpan={2} />
              <td className="has-table-shrink-nowrap  text-right">
                <strong>{formatCents(shrunken_lines.reduce((r, x) => r + x.total_inclusive, 0))}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

type ButtonsProps = {
  purchase_tenant: ModelOrderPurchaseTenant;
  store: any;
  fully_paid: boolean;
};

function Buttons({ purchase_tenant, store, fully_paid }: ButtonsProps) {
  if (purchase_tenant.state === 'delivered') {
    return null;
  }

  return (
    <div className="card-footer">
      <div className="flex-row flex-space-between">
        <div />
        <div>
          <button
            type="button"
            className="btn btn-primary-outline ml-1"
            onClick={() => store.openModal({ which: 'processed', data: { purchases_per_tenant: [purchase_tenant] } })}
          >
            Verwerken
          </button>
          <button
            type="button"
            className="btn btn-primary-outline ml-1"
            onClick={() => store.openModal({ which: 'ready', data: { purchases_per_tenant: [purchase_tenant] } })}
          >
            Gereed
          </button>
          <button
            type="button"
            className="btn btn-primary-outline ml-1"
            onClick={() => store.openModal({ which: 'delivery', data: { purchases_per_tenant: [purchase_tenant] } })}
            disabled={!fully_paid}
          >
            Onderweg
          </button>
          <button
            type="button"
            className="btn btn-primary-outline ml-1"
            onClick={() => store.openModal({ which: 'delivered', data: { purchases_per_tenant: [purchase_tenant] } })}
            disabled={!fully_paid}
          >
            Geleverd
          </button>
        </div>
      </div>
    </div>
  );
}
