import * as React from 'react';
import { cs, formatCents, formatDate } from '../../../utils';
import { BaseStore, useRxjsStore } from '../../../effects';
import { http } from '../../../http';
import { AxiosError } from 'axios';
import { GenericDataService } from '../../../services/GenericDataService';
import { Form, Formik } from 'formik';
import FormInput from '../../../components/FormInput';
import * as Yup from 'yup';
import GenericPagination from '../../../components/GenericPagination';
import MenuDropdown from '../../../components/MenuDropdown';
import FormGroup from '../../../components/FormGroup';
import SpectreAutocomplete from '../../../components/spectre/SpectreAutocomplete';
import _ = require('underscore');
import Query from '../../../lib/Query';
import AtomTableNoRecords from '../../../components/AtomTableNoRecords';

type Context = {
  loading: boolean;

  query: ContextQuery;
  query_is_empty: boolean;

  pagination?: Pagination;
  records: ModelFinanceInvoiceSettlement[];
};

type ContextQuery = {
  page: number;
  per_page: number;
  state: string;
} & SearchContext;

type SearchContext = {
  settlement_id: string;
  tenants: string[];
  buildings: string[];
  from_year: string;
  from_week: string;
  till_year: string;
  till_week: string;
};

function emptyQuery() {
  return {
    page: 0,
    per_page: 12,
    state: 'many',
    settlement_id: '',
    tenants: [],
    buildings: [],
    from_year: '',
    from_week: '',
    till_year: '',
    till_week: '',
  };
}

function isQueryEmpty(query: ContextQuery) {
  console.log('equal', query, emptyQuery());
  return _.isEqual(query, emptyQuery());
}

const query = new Query<ContextQuery>('PageFinanceSettlementMany');

class Store extends BaseStore<Context> {
  setup(): Context {
    const q = query.load() || emptyQuery();

    return {
      loading: false,

      query: q,
      query_is_empty: isQueryEmpty(q),

      pagination: null,
      records: [],
    };
  }

  async setState(state: string) {
    this.next(draft => {
      draft.query.state = state;
    });

    await this.load();
  }

  setPage = async (page: number) => {
    this.next(draft => {
      draft.query.page = page;
      draft.query_is_empty = isQueryEmpty(draft.query);

      query.save(draft.query);
    });

    await this.load();
  };

  async load() {
    this.next(draft => {
      draft.loading = true;
    });

    try {
      const q = this.current().query;
      const res = await http().post('/core/finance/settlement/many', {
        page: q.page,
        per_page: q.per_page,

        settlement_id: q.settlement_id || null,
        tenants: q.tenants,
        buildings: q.buildings,
        from_year: q.from_year || null,
        from_week: q.from_week || null,
        till_year: q.till_year || null,
        till_week: q.till_week || null,
      });

      const pagination = res.data.pagination;
      const records = res.data.records;

      this.next(draft => {
        draft.loading = false;
        draft.pagination = pagination;
        draft.records = records;
      });
    } catch (exc) {
      const err: AxiosError = exc;

      this.next(draft => {
        draft.loading = false;
        draft.records = [];
        draft.pagination = null;
      });
    }

    return this;
  }

  async search(values: SearchContext) {
    this.next(draft => {
      draft.query.page = 0;
      draft.query.settlement_id = values.settlement_id;
      draft.query.tenants = values.tenants;
      draft.query.buildings = values.buildings;
      draft.query.from_year = values.from_year;
      draft.query.from_week = values.from_week;
      draft.query.till_year = values.till_year;
      draft.query.till_week = values.till_week;
      draft.query_is_empty = isQueryEmpty(draft.query);

      query.save(draft.query);
    });

    return await this.load();
  }

  async resetQuery() {
    return await this.search(emptyQuery());
  }
}

export default function PageFinanceSettlementMany() {
  const generic = GenericDataService.useEffect('PageFinanceSettlementMany');
  const { context, store } = useRxjsStore<Context, Store>(Store, null, () => {
    store.load();
  });

  if (!context) return null;

  const getTenantById = (id: number) => generic.data.tenants[id];
  const getBuildingById = (id: number) => generic.data.buildings[id];

  return (
    <div className="container">
      <div className="card">
        <div className={cs('card-loading', context.loading && 'active')} />

        <div className="card-header">
          <div className="card-title h5">Order overzicht</div>
        </div>
        <div className="card-body">
          <SearchForm store={store} context={context} />
        </div>
        <div className="card-body">
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="has-table-shrink-nowrap text-center">
                  <i className="fal fa-fw fa-hashtag" />
                </th>
                <th className="has-table-shrink-nowrap text-right">Vestiging</th>
                <th className="has-table-shrink-nowrap text-right">Datum</th>
                <th className="has-table-shrink-nowrap text-right">Jaar</th>
                <th className="has-table-shrink-nowrap text-right">Week</th>
                <th className="has-table-shrink-nowrap text-right">DlNr</th>
                <th>Deelnemer</th>
                <th className="has-table-shrink-nowrap text-right">Omzet</th>
                <th className="has-table-shrink-nowrap text-right">Commissie</th>
                <th className="has-table-shrink-nowrap text-right">Ontvangen</th>
                <th className="has-table-shrink-nowrap text-right">&nbsp;</th>
              </tr>
            </thead>

            {context.records.length === 0 && (
              <tbody>
                <tr>
                  <td colSpan={11}>
                    <AtomTableNoRecords />
                  </td>
                </tr>
              </tbody>
            )}
            {context.records.length > 0 && (
              <>
                <GenericPagination pagination={context.pagination} setPage={store.setPage} span={11} />
                <tbody>
                  {context.records.map(record => {
                    const building = getBuildingById(record.building_id);
                    const tenant = getTenantById(record.tenant_id);

                    return (
                      <tr key={record.id}>
                        <td className="has-table-shrink-nowrap text-right">#{record.id}</td>
                        <td className="has-table-shrink-nowrap">{building.name}</td>
                        <td className="has-table-shrink-nowrap text-right">{formatDate(record.created_at, 'L LT')}</td>
                        <td className="has-table-shrink-nowrap text-right">{record.year}</td>
                        <td className="has-table-shrink-nowrap text-right">{record.week}</td>
                        <td className="has-table-shrink-nowrap text-right">{record.tenant_id}</td>
                        <td>{tenant && tenant.name}</td>
                        <td className="has-table-shrink-nowrap text-right">{formatCents(record.sale_inclusive)}</td>
                        <td className="has-table-shrink-nowrap text-right">
                          {formatCents(-record.commission_inclusive)}
                        </td>
                        <td className="has-table-shrink-nowrap text-right">{formatCents(record.total_inclusive)}</td>

                        <td className="has-table-shrink-nowrap">
                          <MenuDropdown
                            button={<i className="fal fa-ellipsis-h" />}
                            classNames={['mr-1']}
                            btnClassNames={['btn btn-dark-outline btn-action s-circle']}
                            items={[
                              <a
                                href={`${GLOBAL_APIS_URL}dmz/downloads/tenant/finance_invoice_settlement_pdf?id=${record.id}&secret=${record.secret}`}
                                target="_blank"
                              >
                                <i className="fal fa-fw  fa-file-pdf" /> download PDF
                              </a>,
                              <a
                                href={`${GLOBAL_APIS_URL}dmz/downloads/tenant/finance_invoice_settlement_excel?id=${record.id}&secret=${record.secret}&csv=1`}
                                target="_blank"
                              >
                                <i className="fal fa-fw  fa-file-csv" /> download CSV
                              </a>,
                              <a
                                href={`${GLOBAL_APIS_URL}dmz/downloads/tenant/finance_invoice_settlement_excel?id=${record.id}&secret=${record.secret}`}
                                target="_blank"
                              >
                                <i className="fal fa-fw  fa-file-excel" /> download Excel
                              </a>,
                            ]}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <GenericPagination pagination={context.pagination} setPage={store.setPage} span={11} />
              </>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}

function SearchForm({ store, context }) {
  const initialValues: SearchContext = context.query;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, fa) => {
        await store.search(values);
        fa.setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        order_id: Yup.number(),
        payment_foreign_number: Yup.string().max(6, 'stringMaxLength'),
      })}
      render={fp => (
        <Form style={{ padding: '0 1rem' }}>
          <div className={cs('form-group')}>
            <div className="columns">
              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="button"
                  className={cs(
                    'btn btn-action s-circle tooltip tooltip-right',
                    context.query_is_empty ? 'btn-error-outline' : 'btn-error',
                  )}
                  data-tooltip="Leeg alle zoekvelden."
                  disabled={context.loading}
                  onClick={async () => {
                    fp.setValues((await store.resetQuery()).current().query);
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="column col-1 col-xs-12  pr-2">
                <label className="form-label">Nummer</label>
                <FormInput name="settlement_id" />
              </div>
              <div className="column col-4 col-xs-12  pr-2">
                <FormGroup title="Deelnemer(s)" name="tenants">
                  <SpectreAutocomplete
                    name="tenants"
                    url="/core/contact/tenant/autocomplete"
                    fp={fp}
                    multiple
                    render={record => {
                      return (
                        <div>
                          ({record.value}) <strong>{record.title}</strong>
                        </div>
                      );
                    }}
                  />
                </FormGroup>
              </div>
              <div className="column col-2 col-xs-12  pr-2">
                <FormGroup title="Vestiging(en)" name="buildings">
                  <SpectreAutocomplete
                    name="buildings"
                    url="/core/plan/building/autocomplete"
                    fp={fp}
                    multiple
                    allowEmptySearch
                  />
                </FormGroup>
              </div>
              <div className="column col-4">
                <div className="columns">
                  <div className="column col-3 pr-2">
                    <label className="form-label">Vanaf jaar</label>
                    <FormInput name="from_year" />
                  </div>
                  <div className="column col-3  pr-2">
                    <label className="form-label">Vanaf week</label>
                    <FormInput name="from_week" />
                  </div>
                  <div className="column col-3  pr-2">
                    <label className="form-label">Tot jaar</label>
                    <FormInput name="till_year" />
                  </div>
                  <div className="column col-3  pr-2">
                    <label className="form-label">Tot week</label>
                    <FormInput name="till_week" />
                  </div>
                </div>
              </div>

              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="submit"
                  className="btn btn-primary-outline btn-action s-circle"
                  disabled={fp.isSubmitting || context.loading}
                >
                  <i className={cs('fal fa-fw', context.loading ? 'fa-spin fa-spinner-third' : 'fa-search')} />
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
}
