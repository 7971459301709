import * as React from 'react';
import Render from './Render';

export default function PageDocCoreOrderPaymentSale() {
  return <Render markdown={markdown} />;
}

const markdown = `

# Starten van een klantbetaling

Welkom bij de handleiding van betalingen en _intern geld_. In deze nieuwe versie zijn de twee
losse knoppen _Restbetaling uitvoeren_ en _Bankbetaling registereren_ komen te vervallen. Hier
is een knop voor terug gekomen _klantbetaling uitvoeren_.

Klik op deze knop om het openstaande bedrag te kunnen betalen.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-sale/d9a611c6-5d05-4bcd-9bd9-b2b8876d021e.png)

Er opent een popup met een aantal keuzes waarop de betaling uitgevoerd kan worden:

1. _PIN-terminal_: direct de klant geld laten pinnen.
2. _Loods 5 Cadeaukaart_: direct de klant laten betalen met een cadeaukaart.
3. _Per bank registreren_: een betaling per bank registereren.
4. _Overwaarde andere order_: een betaling doen met de overwaarde van een andere order (intern geld).

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-sale/f6c79bc3-a192-4047-b7af-2bd2a7b6706e.png)

In dit voorbeeld gaan we voor _Betaling per bank registreren_, waarbij we niet hele bedrag zullen
betalen. We gaan voor € 500,- van het openstaande bedrag, € 1.719,-.

Vul de gegevens van het formulier in. De applicatie geeft automatisch aan bij de velden van het
formulier wanneer er fouten ontstaan.

Na het invullen klik op _Starten_.

*Let op*, heb je gekozen voor _PIN-terminal_, dan springt de PIN-terminal aan en moet de klant een handeling
uitvoeren, er verschijnt een extra scherm om dit in de gaten te houden. 

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-sale/5a8b27ef-85af-47db-906c-b3a26640e16c.png)

Je ziet dat er bij _Betalingen_ nu een _Bankbetaling_ is geregistreerd. We gaan hierna
een klein deel van deze order betalen _met een andere order_.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-sale/fcc68b34-9aa2-454f-a65b-6b3c35b62490.png)

# Interne transfer

**Dit is een andere order**, hier zijn twee artikelen in geretourneerd en er staat nu een bedrag
van € 18,- open. We gaan deze € 18,- euro gebruiken om een (klein) deel van onze order te betalen. 

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-sale/36c97880-d0d3-4b58-a11c-e812cb4bfe97.png)

We zijn weer terug bij onze order, we klikken opnieuw op _klantbetaling uitvoeren_.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-sale/cb075841-9ead-492f-aae3-560d345871b3.png)

Selecteer nu _Betaling via overwaarde andere order_, er verschijnt een veld _Ordernummer (met overwaarde)_.
Vul hier nu het nummer van de _andere_ order in. 

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-sale/9ec47ede-b892-49c3-8108-9cdd4e5cfb31.png)

Er gebeuren nu direct twee dingen:

1. De applicatie haalt direct de andere order op, en geeft onder het veld het bedrag weer wat er beschikbaar
is, dit is de € 18,- van eerder.
2. Onder bedrag is direct een foutmelding komen te staan, het bedrag staat namelijk automatisch ingesteld
op het resterende bedrag wat er nog betaald moest worden.

Je zal dus nu de waarde in het veld _Bedrag_ moeten aanpassen. Als je klikt op _Er is ... beschikbaar ..._,
dan wordt het bedrag direct overgenomen.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-sale/369411da-b38a-42ba-8b82-ba2ebcc42563.png)

Na het instellen van de € 18,- zijn er geen foutmeldingen meer en kun je klikken op _Starten_.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-sale/9b2eae3c-9562-43c7-a760-548428e46ceb.png)

In het overzicht van de order zie je nu onder _Betalingen_ dat er een extra regel bij is gekomen. De regel geeft
aan _Interne transfer_ om aan te geven dat het een interne overboeking is geweest. Zo zie je dat het
bedrag wat je hebt geselecteert bij het intern overboeken goed is overgenomen.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-sale/7eb45fe0-a672-40b4-b9c5-7143ca62589c.png)

Als we nu de andere order openen, zul je zien dat hier nu voor hetzelfde bedrag een _Creditatie_ is verschenen.
Ook deze is gekenmerkt met _Interne transfer_.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-sale/53aedead-8d2c-4723-a18a-1b368c2c0953.png)

`;
