import * as React from 'react';
import Render from './Render';

export default function PageDocCoreOrderPaymentRefund() {
  return <Render markdown={markdown} />;
}

const markdown = `

# Starten van een retour

Welkom bij de handleiding van retouren en retourbetalingen. In deze nieuwe versie gaan
we uit van de gewone order-interface. Waar dit eerst een apart scherm was, gebruiken
we nu direct de order zelf.

Boven de orderregels zie je nu een knop staan _Retour in winkel_.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-refund/346b815d-ef66-4e98-95bf-4f31fb70d4f5.png)

Er opent een popup waarbij de artikelen die retour komen opgegeven kunnen worden. De
dropdown zorgt er voor dat op dezelfde regel automatisch het bedrag komt te staan
wat later gecrediteerd moet worden. Na het instellen klik dan op _Retour aanslaan_.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-refund/1049e19a-8df6-416f-bf66-c9100ebe7382.png)

In het overzicht van de order zijn nu drie dingen veranderd.

1. Rechts bovenin zie je dat er een rode regel is onstaan met de tekst _Creditatiebedrag_, van € 41,90. Dit
is het bedrag van de twee artikelen die we retour hebben geslagen.
2. Onder de orderregels zie je dat er twee regels zwart zijn geworden, dit zijn de geretourneerde artikelen.
3. Het is nu zaak om de klant geld terug te geven. Je ziet dat er bovenin een knop _klantterugbetaling uitvoeren_
is verschenen.

# Retourbetaling uitvoeren

Klik op de knop _klantterugbetaling uitvoeren_.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-refund/fe6406a7-c123-4c72-9e22-a1770c2bb78a.png)

Er opent een popup waarbij je kunt aangeven hoe je de terugbetaling wilt uitvoeren. Je hebt de keuze uit:

1. _PIN-terminal_: direct de klant geld laten terugpinnen.
2. _Loods 5 Cadeaukaart_: direct de klant een cadeaukaart van Loods 5 meegeven.
3. _Per bank registreren_: een retourbetaling per bank registereren.

In dit voorbeeld gaan we voor _Terugbetaling per bank registreren_.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-refund/53138f60-994a-4cde-904e-ba61b6c0ef3e.png)

We vullen de gevraagde gegevens in. Let op dat je een geldig IBAN rekening-nummer invoert, hij controleert
direct bij het invoeren (het werkt 100% zeker voor alle Europese banken). In dit voorbeeld gaan we voor een
deel van het bedrag, dus maar € 20,- in plaats van de volledige € 41,90. 

Na het invullen klik op _Starten_.

*Let op*, heb je gekozen voor _PIN-terminal_, dan springt de PIN-terminal aan en moet de klant een handeling
uitvoeren, er verschijnt een extra scherm om dit in de gaten te houden. 

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-refund/425d7157-1539-4af8-aaa0-fdc1e84ac6e4.png)

Je ziet dat er bij _Creditaties_ een regel bij gekomen is met onze € 20,-. We gaan nu nogmaals op
_klantterugbetaling uitvoeren_ klikken.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-refund/136dd551-64e1-4929-a2cd-02199ad2e4f3.png)

We doen nogmaals een _Terugbetaling per bank registreren_, we passen nu echter het bedrag niet aan. Klik
op _Starten_ om verder te gaan.

*Let op*, het scherm blokkeert wanneer je een te hoog bedrag kiest, er verschijnt dan een foutmelding
onder het veld _Bedrag_.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-refund/80af2617-4a10-456a-8200-4c5c66be3805.png)

Je ziet dat er bij _Creditaties_ een extra regel bij gekomen is met onze € 21,90,-. Er is nu in totaal
€ 41,90 gecrediteerd, en de applicatie heeft berekend dat het bedrag nu weer in balans is met de waarde
van de order.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-refund/21aae19f-76df-4d12-bddb-7886f9cb9e58.png)

# Geven van klantcompensatie

Is het artikel geleverd bij de klant, maar er is iets mis mee. Je kunt dan een klantcompensatie uitvoeren
om zo de waarde van het artikelen, na leveren, te verminderen. Klik op de knop
_Klantcompensatie uitvoeren op deze regel_.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-refund/599aa5b0-10af-48dd-9706-4577bdd4f962.png)

Er opent een popup waarbij je een aantal gegevens moet invoeren. De applicatie berekent automatisch hoe
het bedrag van de artikelregels verlaagt moet worden.

Je moet een reden opgeven, waarom je een compensatie uitvoert. Deze reden wordt opgeslagen bij de orderregel.

Klik op _Aanpassen_ om de compensatie op te slaan.

*Let op*: soms bestaan de orderregels uit meerdere artikelen! Je kunt dan in dit formulier aangeven over hoeveel
van de artikelen je wilt dat de klantcompensatie gaat. Heeft de klant bijvoorbeeld 4 kussen gekocht, maar er moet
er maar voor 1 een compensatie gegeven worden, kies dan bij _Aantal artikelen_ het getal 1. Het meest rechter
veld van _Compensatie_ laat zien hoeveel compensatie er in _totaal_ gegeven zal worden.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-refund/68624b37-ccff-4b0e-9c78-f03d493b569e.png)

In het overzicht van de order zijn nu twee dingen veranderd.

1. Bij _Creditatiebedrag_ staan nu de € 6,24 van de klantcompensatie die we hebben uitgevoerd.
2. Het bedrag van het artikel is doorgestreept (dat was het origineel), en de nieuwe waarde van
het artikel staat er achter.

![Afbeelding](${GLOBAL_APIS_URL}static/doc/core-order-payment-refund/79d08e96-8121-428f-ade9-c532a9e187a2.png)

`;
