import * as React from 'react';
import { formatCentsShort, formatGiftcardIdentifier } from '../../../utils';
import { PageDefinition } from './PageState';
import ComponentTrLine from './ComponentTrLine';
import ComponentTrGiftcardPurchase from './ComponentTrGiftcardPurchase';
import ComponentColgroup from './ComponentColgroup';
import ComponentTrLineSmall from './ComponentTrLineSmall';

type ComponentSaleArticlesProps = {
  page: PageDefinition;
};

export default function ComponentArticles(props: ComponentSaleArticlesProps) {
  const { context, store } = props.page;
  const isLarge = context.touch && context.touch.is_large;

  function onUpdateLineAmountClick(line_id: number, amount: number) {
    return async (event: React.MouseEvent) => {
      event.preventDefault();
      await store.updateLineAmount(line_id, amount);
    };
  }

  function onOpenUpdateArticle(line: ModelShopCartLine) {
    return async (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      await store.open(line.id);
    };
  }

  function onDuplicateLine(line: ModelShopCartLine) {
    return async (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      await store.cloneLine(line.id);
    };
  }

  function onDeleteGiftcardPaymentClick(purchase_id: number) {
    return async (event: React.MouseEvent) => {
      event.preventDefault();
      await store.deleteGiftcardPayment(purchase_id);
    };
  }

  function onDeleteGiftcardPurchaseClick(purchase_id: number) {
    return async (event: React.MouseEvent) => {
      event.preventDefault();
      await store.deleteGiftcardPurchase(purchase_id);
    };
  }

  return (
    <table
      className="table table-fixed table-small"
      style={{ transform: `translateY(-${context.scroll.scrollTop}px)`, transition: 'transform 250ms ease-in-out' }}
    >
      <ComponentColgroup isLarge={isLarge} />
      <tbody>
        {context.giftcards_payment.map((payment: ModelShopCartGiftcardPayment) => (
          <TrGiftcardPayment
            key={`payment-${payment.id}`}
            payment={payment}
            isLarge={isLarge}
            onDeleteGiftcardPaymentClick={onDeleteGiftcardPaymentClick}
          />
        ))}
        {context.giftcards_purchase.map((purchase: ModelShopCartGiftcardPurchase) => (
          <ComponentTrGiftcardPurchase
            key={`purchase-${purchase.id}`}
            purchase={purchase}
            isLarge={isLarge}
            onDeleteGiftcardPurchaseClick={onDeleteGiftcardPurchaseClick}
          />
        ))}
        {[...context.lines].reverse().map((line: ModelShopCartLine) => {
          const props = {
            key: line.id,
            imageSize: 48,
            line,
            onDuplicateLine,
            onOpenUpdateArticle,
            onUpdateLineAmountClick,
          };

          return isLarge ? <ComponentTrLineSmall {...props} /> : <ComponentTrLine {...props} />;
        })}
      </tbody>
    </table>
  );
}

function TrGiftcardPayment({ payment, isLarge, onDeleteGiftcardPaymentClick }) {
  return (
    <tr>
      <td className="text-center">
        <a
          href="javascript:"
          className="btn btn-error-outline btn-action s-circle"
          onClick={onDeleteGiftcardPaymentClick(payment.id)}
        >
          <i className="fal fa-times" />
        </a>
      </td>
      <td>
        <img
          className="img-fit-contain s-rounded"
          src={require('./../../../assets/images/giftcard.png')}
          style={{
            width: 48,
            height: 48,
            display: 'inline-block',
            border: '1px solid rgba(0,0,0,.1)',
          }}
          alt="Aankoop nieuwe cadeaukaart"
        />
      </td>
      <td className="px-0  text-center">
        <i className="fal fa-fw fa-hand-holding-usd" />
      </td>
      <td>
        <strong>Betalen met Loods 5 Cadeaukaart</strong>
        <div>{formatGiftcardIdentifier(payment.giftcard.identifier, true)}</div>
      </td>
      {!isLarge && (
        <td className="text-right  tooltip tooltip-bottom" data-tooltip="Deze waarde is een schatting.">
          <strong>{formatCentsShort(payment.balance_before)}</strong>
        </td>
      )}
      <td>&nbsp;</td>
    </tr>
  );
}
