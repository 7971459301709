import * as React from 'react';
import { Field } from 'formik';

type RadioItem = {
  value: string;
  title: string | React.ReactNode;
};

type FormSelectProps = {
  name: string;
  items: (RadioItem | false | null)[];
};

export default function FormRadio(props: FormSelectProps) {
  const items: RadioItem[] = props.items.filter(x => !!x) as any;

  return (
    <div>
      {items.map(item => (
        <label className="form-radio" key={item.value}>
          <Field
            name={props.name}
            value={item.value}
            render={({ field, form }) => (
              <input {...field} type="radio" value={item.value} checked={form.values[props.name] === item.value} />
            )}
          />
          <i className="form-icon" /> {item.title}
        </label>
      ))}
    </div>
  );
}
