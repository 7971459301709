import * as React from 'react';
import { formatCents, isWithinMz, parseCents, StringObject } from '../../../utils';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import FormInput from '../../../components/FormInput';
import FormGroup from '../../../components/FormGroup';
import * as Yup from 'yup';
import FormRadio from '../../../components/FormRadio';
import * as _ from 'underscore';
import { http, httpLive } from '../../../http';
import { AxiosError } from 'axios';
import * as IBAN from 'iban';
import { MessageService } from '../../../services/MessageService';
import moment = require('moment');

type Props = {
  purchase: ModelOrderPurchase;
  close: (success?: boolean) => void;
};

export default function ModalPaymentRefund({ purchase, close }: Props) {
  const [active, setActive] = React.useState(false);
  const [values, setValues] = React.useState<StringObject>({});

  const [creditation, setCreditation] = React.useState<ModelFinanceCreditation | null>(null);

  function back() {
    setCreditation(null);
    setValues({});
    setActive(false);
  }

  async function submit(values, formikActions: FormikActions<any>) {
    const ggd = window.GLOBAL_GENERIC_DATA;

    try {
      const res = await http().post(`/core/order/purchase/payment/refund_by_${values.mode}`, {
        ...values,
        amount: parseCents(values.amount),

        purchase: purchase.id,
        group: ggd.device && ggd.device.group.id,
      });

      if (res.data.creditation.is_authorized) {
        MessageService.setMessage('De retourbetaling is opgeslagen, bedankt!', 'success', 3000);

        close(true);
      } else {
        setCreditation(res.data.creditation);
        setValues(values);
        setActive(true);
      }
    } catch (exc) {
      console.error(exc);
      const err: AxiosError = exc;

      if (err.response && err.response.data && err.response.data.errors) {
        formikActions.setErrors(err.response.data.errors);
      }

      formikActions.setSubmitting(false);
    }
  }

  const initial = {
    mode: isWithinMz() ? 'terminal' : 'bank',
    amount:
      purchase.current_inclusive < purchase.paid ? formatCents(purchase.paid - purchase.current_inclusive, true) : '',

    giftcard_number: '',

    account_name: (purchase.customer && purchase.customer.name) || '',
    account_number: '',

    authorized_at: moment().format('L'),
  };

  const schema = Yup.object().shape({
    amount: Yup.string()
      .test('tooSmall', 'Het bedrag is te laag.', function(value) {
        return parseCents(value) >= 1;
      })
      .test('tooLarge', 'Het bedrag is te hoog.', function(value) {
        const maximum_amount = this.parent.mode === 'giftcard' ? 50000 : 1000000;
        return parseCents(value) <= maximum_amount;
      })
      .required('isRequired'),

    account_name: Yup.string().test('isPresent', 'isRequired', function(value) {
      if (this.parent.mode !== 'bank') return true;
      return (value || '').length >= 2;
    }),
    account_number: Yup.string().test('isIBANValid', 'Dit is geen geldig IBAN nummer.', function(value) {
      if (this.parent.mode !== 'bank') return true;
      return IBAN.isValid(value || '');
    }),
  });

  return (
    <div className="modal modal-sm active" id="modal-id">
      <div className="modal-overlay" />
      {!active && (
        <Formik
          initialValues={initial}
          validationSchema={schema}
          onSubmit={submit}
          render={fp => <RefundForm purchase={purchase} close={close} fp={fp} />}
        />
      )}

      {active && values.mode === 'terminal' && (
        <RefundTerminalProcessing purchase={purchase} close={close} back={back} creditation={creditation} />
      )}
    </div>
  );
}

function RefundForm({ purchase, close, fp }: Props & { fp: FormikProps<any> }) {
  const errors = fp.errors;

  return (
    <Form className="modal-container">
      <div className="modal-header">
        <div className="modal-title h5">Terugbetaling uitvoeren bij #{purchase.cart_id}</div>
      </div>

      <table className="table">
        <tbody>
          <tr>
            <th>Totaalbedrag order</th>
            <td className="text-right">{formatCents(purchase.current_inclusive)}</td>
          </tr>
          <tr>
            <th>Betaald door klant</th>
            <td className="text-right">{formatCents(purchase.paid)}</td>
          </tr>

          {purchase.current_inclusive < purchase.paid && (
            <tr className="bg-error">
              <th>Creditatiebedrag</th>
              <td className="text-right">{formatCents(purchase.paid - purchase.current_inclusive)}</td>
            </tr>
          )}
          {purchase.current_inclusive >= purchase.paid && (
            <tr className="bg-error">
              <th>Drempel te laag</th>
              <td className="text-right">Er is nog niet voldoende betaald om een retourbedrag te kunnen berekenen.</td>
            </tr>
          )}
        </tbody>
      </table>

      <input type="hidden" name="mode" value={fp.values.mode} />

      <div className="modal-body">
        <FormGroup title="Terugbetalings methode" name="mode" errors={errors}>
          <FormRadio
            name="mode"
            items={[
              isWithinMz() && { title: 'Terugbetaling met PIN-terminal', value: 'terminal' },
              isWithinMz() && { title: 'Terugbetaling met Loods 5 Cadeaukaart', value: 'giftcard' },
              { title: 'Terugbetaling per bank registreren', value: 'bank' },
            ]}
          />
        </FormGroup>

        <FormGroup title="Bedrag" name="amount" errors={errors}>
          <FormInput name="amount" />
        </FormGroup>

        {fp.values.mode === 'giftcard' && (
          <FormGroup title="Cadeaukaart (scannen)" name="giftcard_number" errors={errors}>
            <FormInput name="giftcard_number" />
          </FormGroup>
        )}

        {fp.values.mode === 'bank' && (
          <FormGroup title="Rekeningnummer (van de klant)" name="account_number" errors={errors}>
            <FormInput name="account_number" />
          </FormGroup>
        )}
        {fp.values.mode === 'bank' && (
          <FormGroup title="T.n.v. rekening" name="account_name" errors={errors}>
            <FormInput name="account_name" />
          </FormGroup>
        )}

        {fp.values.mode === 'bank' && (
          <FormGroup title="Betalingsmoment" name="authorized_at" errors={errors}>
            <FormInput name="authorized_at" />
          </FormGroup>
        )}
      </div>

      <div className="modal-footer">
        <div className="flex-row flex-space-between">
          <div>
            <button type="button" className="btn btn-dark-outline" onClick={() => close()}>
              <i className="fal fa-times" /> Annuleren
            </button>
          </div>
          <div>
            <button type="submit" className="btn btn-primary" disabled={!_.isEmpty(fp.errors) || fp.isSubmitting}>
              <i className="fal fa-money-bill-wave" /> Starten
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
}

type RefundProcessingProps = {
  creditation: ModelFinanceCreditation;
  back: () => void;
} & Props;

function RefundTerminalProcessing({ purchase, close, creditation, back }: RefundProcessingProps) {
  const ggd = window.GLOBAL_GENERIC_DATA;

  const [started, setStarted] = React.useState(false);
  const [ping, setPing] = React.useState(false);

  async function terminalSendCreditation() {
    await http().post('/sale/creditation/reset', {
      id: creditation.id,
    });

    try {
      const res = await httpLive().post('/terminals/process-creditation', {
        creditation_id: creditation.id,
      });
    } catch (exc) {
      console.error(exc);
    }

    setPing(true);
  }

  async function terminalSendCancel() {
    setPing(false);

    const res = await httpLive().post('/terminals/cancel-process', {
      terminal_id: ggd.device.terminal.id,
    });
  }

  // Directly start the PIN-terminal creditation.
  React.useEffect(() => {
    if (started) return;

    setStarted(true);
    terminalSendCreditation();
  }, [started]);

  // Ping the creditation to check if it's done already.
  React.useEffect(() => {
    if (!ping) return;

    const interval = setInterval(() => {
      (async () => {
        const res = await http().post('/sale/creditation/one', {
          id: creditation.id,
        });

        if (res.data.creditation.is_authorized) {
          MessageService.setMessage('De retourbetaling is opgeslagen, bedankt!', 'success', 3000);

          setPing(false);
          close(true);
        } else if (res.data.creditation.is_cancelled) {
          let message = res.data.creditation.foreign_latest_message;

          console.log('ERROR!!', message);

          setPing(false);
        }
      })();
    }, 1000);

    return () => clearInterval(interval);
  }, [ping]);

  function onBack() {
    back();
  }
  function onCancel() {
    terminalSendCancel();
  }
  function onApply() {
    terminalSendCreditation();
  }

  return (
    <div className="modal-container">
      <div className="modal-header">
        <div className="modal-title h5">Terugbetaling uitvoeren bij #{purchase.cart_id}</div>
      </div>

      <div className="modal-body">
        <p>
          De klant kan nu de kaart in de PIN-automaat steken. Het nummer van de PIN-automaat is{' '}
          <code>{ggd.device.terminal.identifier}</code>.
        </p>
        <p>
          Het bedrag wat de klant teruggestort zal krijgen op de rekening is {formatCents(creditation.requested_amount)}
          .
        </p>
        {creditation.is_demo && <p className="text-warning">Dit is een DEMO creditatie!</p>}
      </div>

      <div className="modal-footer">
        <div className="flex-row flex-space-between">
          <div>
            {!ping && (
              <button type="button" className="btn btn-dark-outline" onClick={onBack}>
                <i className="fal fa-arrow-left" /> Terug
              </button>
            )}
            {ping && (
              <button type="button" className="btn btn-dark-outline" onClick={onCancel}>
                <i className="fal fa-times" /> Afbreken
              </button>
            )}
          </div>
          <div>
            {!ping && (
              <button type="button" className="btn btn-primary" onClick={onApply}>
                <i className="fal fa-money-bill-wave" /> Opnieuw aanbieden
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
