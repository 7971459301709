import * as React from 'react';
import { useEffect } from 'react';
import { BaseStore, useRxjsStore } from '../../../effects';
import Steps from '../../../components/Steps';
import { http, httpLive } from '../../../http';
import Ticket from '../../../lib/Ticket';
import { PageDefinition } from './PageState';
import {
  buildCartTicket,
  buildKitchetTicket,
  cs,
  formatCents,
  formatCentsShort,
  formatDate,
  formatGiftcardIdentifier,
  sleep,
} from '../../../utils';
import { MessageService } from '../../../services/MessageService';

type Props = {
  page: PageDefinition;
  close: (success: boolean) => void;
};

type Context = {
  loading: boolean;
  closing: boolean;
  canceling: boolean;

  step: number;

  device: ModalBundleDevice;
  terminal: ModelDeviceTerminal;
  payment: ModelFinancePayment;
  ping: boolean;

  cart: ModelShopCart;
  lines: ModelShopCartLine[];
  giftcards_payment: ModelShopCartGiftcardPayment[];
  giftcards_purchase: ModelShopCartGiftcardPurchase[];

  hasKitchenArticles: boolean;
  lock_because_of_error: boolean;
};

class Store extends BaseStore<Context> {
  setup(): Context {
    return {
      step: 0,

      loading: false,
      closing: false,
      canceling: false,

      device: null,
      terminal: null,
      payment: null,
      ping: false,

      cart: null,
      lines: [],
      giftcards_payment: [],
      giftcards_purchase: [],

      hasKitchenArticles: false,

      lock_because_of_error: false,
    };
  }

  private getCartId() {
    const cart = this.current().cart;
    return cart && cart.id;
  }

  async load(cart_id: number) {
    const res = await httpLive().post('/devices/group');

    this.next(draft => {
      draft.device = res.data;
    });

    await this.update(cart_id);
  }

  async update(cart_id?: number) {
    this.next(draft => {
      draft.loading = true;
    });

    const res = await http().post('/sale/cart/one', {
      cart: cart_id || this.getCartId(),
    });

    const cart = res.data.cart;
    const lines = res.data.lines;
    const giftcards_payment = res.data.giftcards_payment;
    const giftcards_purchase = res.data.giftcards_purchase;

    this.next(draft => {
      draft.loading = false;
      draft.cart = cart;
      draft.lines = lines;
      draft.giftcards_payment = giftcards_payment;
      draft.giftcards_purchase = giftcards_purchase;

      draft.hasKitchenArticles = lines.some(x => x.article.is_special_kitchen);
    });
  }

  async closeCart() {
    this.next(draft => {
      draft.closing = true;
    });

    const res = await http().post('/sale/cart/close', {
      cart: this.getCartId(),
      group: this.current().device.group.id,
      terminal: this.current().device.terminal.id,
    });

    await this.update();

    this.next(draft => {
      draft.payment = res.data.payment;
      draft.closing = false;
    });

    if (res.data.payment && res.data.payment.id) {
      // We need to implement payment-by-terminal.
      await this.terminalsProcessPayment();
    } else {
      // Everything is paid using the giftcard.
      // await this.printDefaultTicket();

      if (this.current().hasKitchenArticles) {
        await this.printKitchenTicket();
      }
    }
  }

  async cancelCart() {
    this.next(draft => {
      draft.canceling = true;
    });

    const res = await http().post('/sale/cart/cancel', {
      cart: this.getCartId(),
    });

    this.next(draft => {
      draft.canceling = false;
    });

    return res.data.state;
  }

  async printDefaultTicket() {
    const current = this.current();
    const ticket_printer_id = current.device.ticket_printer.id;
    const ticket = await buildCartTicket(
      current.cart,
      current.lines,
      current.giftcards_payment,
      current.giftcards_purchase,
      current.payment && current.payment.foreign_client_ticket,
    );
    await this.ticketsPrint(ticket_printer_id, ticket);
  }

  async printKitchenTicket() {
    const current = this.current();
    const ticket_printer_id = current.device.kitchen_printer.id;
    const ticket = await buildKitchetTicket(current.cart, current.lines);
    await this.ticketsPrint(ticket_printer_id, ticket);
  }

  // region Handlers

  async terminalsProcessPayment() {
    this.next(draft => {
      draft.step = 1;
      draft.canceling = true;
    });

    const payment = this.current().payment;
    if (payment.is_cancelled) {
      await http().post('/sale/payment/reset', {
        id: payment.id,
      });

      await this.update();
    }

    try {
      const res = await httpLive().post('/terminals/process-payment', {
        payment_id: this.current().payment.id,
      });
    } catch (exc) {
      console.error(exc);
    }

    this.next(draft => {
      draft.ping = true;
      draft.canceling = false;
    });
  }

  async pingPayment() {
    if (!this.current().ping) return false;

    const res = await http().post('/sale/payment/one', {
      id: this.current().payment.id,
    });

    this.next(draft => {
      draft.payment = res.data.payment;
    });

    if (res.data.payment.is_authorized) {
      this.next(draft => {
        draft.ping = false;
        draft.step = 2;
      });

      await httpLive().post('/display/update', {
        group_id: this.current().device.group.id,
        payload: {
          pane: 'thanks',
          cart_id: this.current().cart.id,
        },
      });

      await this.update();

      if (!this.current().device.group.manual_ticket_print) {
        await this.printDefaultTicket();
      }

      if (this.current().hasKitchenArticles) {
        await this.printKitchenTicket();
      }

      this.next(draft => {
        draft.step = 3;
      });
    } else if (res.data.payment.is_cancelled) {
      let message = res.data.payment.foreign_latest_message;
      const lock = message === 'terminal-disconnected';

      if (/incident: 2630/.exec(message)) {
        message =
          'Je hebt de betaling geannuleerd, wacht even tot de PIN-terminal weer actief wordt ' +
          'met opnieuw aanbieden van de transactie.';
      } else if (/incident: 2629/.exec(message)) {
        message = 'Betaling is geannuleerd door de klant.';
      } else if (/incident: 1802/.exec(message)) {
        message = 'De betaalautomaat is bezig en kan (nog) geen betaling uitvoeren voor je.';
      } else if (/incident: 1803/.exec(message)) {
        message = 'De betaalautomaat annuleerde de betaling (duurde te lang).';
      } else if (message === 'terminal-already-active') {
        message = 'De betaalautomaat wordt door een ander gebruikt, even wachten.';
      } else if (message === 'terminal-not-connected') {
        message = 'De betaalautomaat is niet verbonden met de server.';
      } else if (message === 'api-sale-payment-one-failed') {
        message = 'De betalingsinformatie kon niet gevonden worden op de server.';
      } else if (message === 'terminal-disconnected') {
        message = 'De betaalautomaat is niet meer verbonden met de server.';
      }

      MessageService.setMessage(message, 'error', 5000);

      this.next(draft => {
        draft.ping = false;
        draft.step = 0;
        draft.lock_because_of_error = lock;
      });
    }
  }

  async terminalsCancelProcess() {
    this.next(draft => {
      draft.canceling = true;
    });

    await httpLive().post('/terminals/cancel-process', {
      terminal_id: this.current().payment.terminal_id,
    });
    await sleep(5000);

    this.next(draft => {
      draft.canceling = false;
    });
  }

  async ticketsPrint(ticket_printer_id, ticket: Ticket) {
    await httpLive().post('/tickets/print', {
      ticket_printer_id,
      ticket: await ticket.generate(),
    });
  }

  // endregion
}

export default function ModalPayment(props: Props) {
  const { context, store } = useRxjsStore<Context, Store>(Store);

  useEffect(() => {
    if (store) {
      store.load(props.page.context.cart.id);
    }
  }, [store]);

  React.useEffect(() => {
    if (context && context.ping) {
      let pinging = false;

      const interval = setInterval(async () => {
        if (!pinging) {
          pinging = true;
          await store.pingPayment();
          pinging = false;
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [context && context.ping]);

  async function onCancelClick() {
    await store.cancelCart();

    props.close(false);
  }

  if (!context) {
    return null;
  }

  let state = 'waiting';

  if (context.lock_because_of_error) {
    state = 'lock-because-of-error';
  } else if (!context.device || !context.cart) {
    state = 'connecting-to-server';
  } else if (context.loading) {
    state = 'loading-resources';
  } else if (context.closing) {
    state = 'closing';
  } else if (context.cart.state === 'paying') {
    state = 'paying';
  } else if (context.cart.state === 'paid') {
    state = 'paid';
  }

  return (
    <div className="modal active" id="ModalPayment">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">
            <strong>Betalen</strong>{' '}
            {context.payment && (
              <span>
                order: {context.cart.id}, transactie: {context.payment.id}
              </span>
            )}
          </div>
        </div>

        {state === 'lock-because-of-error' && <BodyLockBecauseOfError context={context} />}
        {state === 'connecting-to-server' && <BodyConnectingToServer />}
        {state === 'loading-resources' && <BodyLoadingResources />}
        {state === 'waiting' && <BodyWaiting />}
        {state === 'closing' && <BodyClosing />}
        {state === 'paid' && (
          <BodyPaid
            device={context.device}
            giftcards_purchase={context.giftcards_purchase}
            giftcards_payment={context.giftcards_payment}
          />
        )}
        {state === 'paying' && <BodyPaying step={context.step} amount={context.payment.requested_amount} />}

        {state !== 'paid' && context.cart && context.device && (
          <BodyExtraInformation
            device={context.device}
            cart={context.cart}
            lines={context.lines}
            giftcards_payment={context.giftcards_payment}
            giftcards_purchase={context.giftcards_purchase}
          />
        )}

        {state !== 'lock-because-of-error' && (
          <div className="modal-footer">
            {state === 'waiting' && (
              <button type="button" className="btn btn-dark-outline  float-left" onClick={() => props.close(false)}>
                <i className="fal fa-times" /> Annuleren
              </button>
            )}
            {state === 'waiting' && (
              <button type="button" className="btn btn-success btn-lg  float-right" onClick={() => store.closeCart()}>
                <i className="fal fa-check" /> Start de betaling
              </button>
            )}

            {state === 'paying' && context.step === 0 && (
              <button
                type="button"
                className="btn btn-error-outline  float-left"
                onClick={onCancelClick}
                disabled={context.canceling}
              >
                <i className="fal fa-times" /> Afbreken
              </button>
            )}
            {state === 'paying' && context.step === 0 && (
              <button
                type="button"
                className="btn btn-success  float-right"
                onClick={() => store.terminalsProcessPayment()}
                disabled={context.canceling}
              >
                <i className="fal fa-sync" /> Opnieuw aanbieden
              </button>
            )}
            {state === 'paying' && context.step === 1 && (
              <button
                type="button"
                className="btn btn-error  float-right"
                onClick={() => store.terminalsCancelProcess()}
                disabled={context.canceling}
              >
                <i className="fal fa-times" /> Stoppen
              </button>
            )}
            {state === 'paid' && (
              <div className="float-right">
                {context.hasKitchenArticles && (
                  <button className="btn btn-primary-outline" onClick={() => store.printKitchenTicket()}>
                    <i className="fal fa-ticket" /> Keukenbon afdrukken
                  </button>
                )}
                {context.device.ticket_printer && (
                  <button className="btn btn-primary-outline" onClick={() => store.printDefaultTicket()}>
                    <i className="fal fa-ticket" /> Bon afdrukken
                  </button>
                )}
                <button type="button" className="btn btn-primary" onClick={() => props.close(true)}>
                  <i className="fal fa-check" /> Volgende klant
                </button>
              </div>
            )}
          </div>
        )}

        {state === 'lock-because-of-error' && (
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-dark-outline  float-left"
              onClick={() => {
                if (
                  window.confirm('Weet je zeker dat je wilt herladen, de bovenstaande informatie zal dan verdwijnen!')
                ) {
                  window.location.reload();
                }
              }}
            >
              <i className="fal fa-sync" /> Herladen
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

function BodyConnectingToServer() {
  return (
    <div className="modal-body">
      <div className="toast mb-2">
        <i className="fal fa-fw fa-hourglass" />
        We maken nu een verbinding met de server om betalingen te mogen uitvoeren, heel even geduld alsjeblieft!
      </div>
    </div>
  );
}

function BodyLoadingResources() {
  return (
    <div className="modal-body">
      <div className="toast mb-2">
        <i className="fal fa-fw fa-hourglass" />
        De gegevens van de winkelwagen worden ingeladen!
      </div>
    </div>
  );
}

function BodyWaiting() {
  return (
    <div className="modal-body">
      <div className="toast toast-primary mb-2">
        <i className="fal fa-fw fa-play-circle" />
        Klik op <em>Starten</em> om de winkelwagen te sluiten en de betaling te starten. We zullen dan vervolgens eerst
        automatisch de gekoppelde cadeaukaarten verrekenen.
      </div>
    </div>
  );
}

function BodyClosing() {
  return (
    <div className="modal-body">
      <div className="toast mb-2">
        <i className="fal fa-fw fa-hourglass" />
        We zijn nu de winkelwagen aan het sluiten, dit kan eventjes duren omdat we nu eerst de betalingen met de
        cadeaukaarten verwerken, eventjes geduld alsjeblieft.
      </div>
    </div>
  );
}

function BodyPaying({ step, amount }) {
  return (
    <div className="modal-body">
      <div className="toast toast-primary mb-2">
        <i className="fal fa-fw fa-money-bill-wave" /> Er moet nu een totaalbedrag van {formatCentsShort(amount)} worden
        betaald door de klant.
      </div>

      <div className="mb-2 pt-2">
        <Steps
          steps={[
            { icon: 'fa-play-circle', label: 'betaling aanmaken' },
            { icon: 'fa-user  text-primary', label: 'de klant mag nu betalen!' },
            { icon: 'fa-check', label: 'betaling is binnen' },
          ]}
          step={step}
        />
      </div>
    </div>
  );
}

function BodyPaid({ device, giftcards_purchase, giftcards_payment }) {
  return (
    <div className="modal-body">
      {device.ticket_printer && device.group.manual_ticket_print && (
        <div className="toast toast-primary mb-2">
          <i className="fal fa-fw fa-check mr-2" />
          De klant heeft afgerekend, er wordt niet meer automatisch een bon geprint. Klik op <em>Bon afdrukken</em> om
          een bon te printen voor de klant.
          <br />
          <br />
          Volgende klant helpen? Klik dan op <em>Volgende klant</em>.
        </div>
      )}
      {device.ticket_printer && !device.group.manual_ticket_print && (
        <div className="toast toast-primary mb-2">
          <i className="fal fa-fw fa-check mr-2" />
          De klant heeft afgerekend, de bon zal nu automatisch geprint worden. Is dit niet het geval dan kun je drukken
          op <em>Bon afdrukken</em>.<br />
          <br />
          <br />
          Volgende klant helpen? Klik dan op <em>Volgende klant</em>.
        </div>
      )}
      {!device.ticket_printer && (
        <div className="toast toast-primary mb-2">
          <i className="fal fa-fw fa-check mr-2" />
          De klant heeft betaalt! Volgende klant helpen? Klik dan op <em>Volgende klant</em>.
        </div>
      )}

      {giftcards_purchase.length > 0 && (
        <div className="pt-2">
          <div className="card-title">Geactiveerde cadeaukaarten</div>
          <div className="card-subtitle  text-gray">
            Het totaalbedrag van de cadeaukaarten is gecontroleerd. De volgende cadeaukaarten zijn nu actief:
          </div>

          <table className="table">
            <thead>
              <tr>
                <th>Cadeaukaart</th>
                <th className="text-right">Saldo</th>
              </tr>
            </thead>
            <tbody>
              {giftcards_purchase.map((gp: ModelShopCartGiftcardPurchase) => (
                <tr key={gp.id}>
                  <td>{formatGiftcardIdentifier(gp.giftcard.identifier)}</td>
                  <td className="text-right  has-table-shrink-nowrap">{formatCentsShort(gp.balance)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {giftcards_payment.length > 0 && (
        <div className="pt-2">
          <div className="card-title">Gebruikte cadeaukaarten</div>
          <div className="card-subtitle  text-gray">
            Het overgebleven saldo staat op de bon. De volgende cadeaukaarten zijn gebruikt:
          </div>

          <table className="table">
            <thead>
              <tr>
                <th>Cadeaukaart</th>
                <th className="text-right">Start saldo</th>
                <th className="text-right">Rest saldo</th>
              </tr>
            </thead>
            <tbody>
              {giftcards_payment.map((gp: ModelShopCartGiftcardPayment) => (
                <tr key={gp.id}>
                  <td>{formatGiftcardIdentifier(gp.giftcard.identifier)}</td>
                  <td className="text-right  has-table-shrink-nowrap">{formatCentsShort(gp.balance_before)}</td>
                  <td className="text-right  has-table-shrink-nowrap">{formatCentsShort(gp.balance_after)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

function BodyLockBecauseOfError({ context }: { context: Context }) {
  return (
    <div className="modal-body">
      <div className="toast toast-error mb-2">
        Er ging iets fout met het verwerken van de betaling. De betaalautomaat is ontkoppeld van het netwerk.{' '}
        <strong>Neem contact op met de technische dienst (Nils).</strong> Vermeld de volgende informatie:
      </div>
      <table className="table table-striped">
        <tbody>
          <tr>
            <td>Order nummer</td>
            <td>{context.cart.id}</td>
          </tr>
          <tr>
            <td>Transactie nummer</td>
            <td>{context.payment.id}</td>
          </tr>
          <tr>
            <td>Computer</td>
            <td>{context.device.computer.name}</td>
          </tr>
          <tr>
            <td>Terminal</td>
            <td>{context.device.terminal.identifier}</td>
          </tr>
          <tr>
            <td>Datum</td>
            <td>{formatDate(context.payment.created_at, 'LL LT')}</td>
          </tr>
          <tr>
            <td>Bedrag</td>
            <td>{formatCents(context.payment.requested_amount)}</td>
          </tr>
        </tbody>
      </table>
      <div className="toast mb-2">
        Het kan zijn dat de klant kan aantonen door middel van digitaal bankieren dat het betalen wel gelukt is. Noteer
        dan altijd de <em>Authorisatie code</em> of <em>Transactie code</em>, deze ziet er zo uit: <code>T482F3</code>.
        Het kan zijn dat er twee van dit soort codes staan, noteer dan beide!
      </div>
    </div>
  );
}

type BodyExtraInformationProps = {
  device: ModalBundleDevice;
  cart: ModelShopCart;
  lines: ModelShopCartLine[];
  giftcards_payment: ModelShopCartGiftcardPayment[];
  giftcards_purchase: ModelShopCartGiftcardPurchase[];
};

function BodyExtraInformation(props: BodyExtraInformationProps) {
  const { device, cart, lines, giftcards_payment, giftcards_purchase } = props;

  let inclusive_subtracting_giftcards = cart.inclusive;
  let discount = lines.reduce((value, line) => {
    return value + line.discount_price + line.manual_discount_price;
  }, 0);
  let cart_rest_amount = cart.inclusive - cart.deposit;
  let cart_deposit = cart.deposit;
  let has_deposit = cart.inclusive !== cart_deposit;

  return (
    <div className="modal-body">
      <div className="pt-2">
        <table className="table">
          <thead>
            <tr>
              <th>Totalen</th>
              <th className="text-right">Bedragen</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Subtotaal</td>
              <td className="text-right  has-table-shrink-nowrap">
                {formatCents(lines.reduce((p, l) => p + l.original_inclusive * l.amount, 0))}
              </td>
            </tr>
            {discount > 0 && (
              <tr>
                <td>Korting</td>
                <td className="text-right  has-table-shrink-nowrap">- {formatCents(discount)}</td>
              </tr>
            )}
            {giftcards_payment.map((gp: ModelShopCartGiftcardPayment) => {
              let using = 0;

              if (gp.balance_before > inclusive_subtracting_giftcards) {
                using = inclusive_subtracting_giftcards;
              } else {
                using = gp.balance_before;
              }

              inclusive_subtracting_giftcards -= using;

              return (
                <tr key={gp.id}>
                  <td>
                    Cadeaukaartbetaling &ndash; {formatGiftcardIdentifier(gp.giftcard.identifier)}
                    <span className={cs('label ml-2', using > 0 && 'label-primary')}>
                      {formatCentsShort(gp.balance_before)}
                    </span>
                  </td>
                  <td className="text-right  has-table-shrink-nowrap">- {formatCents(using)}</td>
                </tr>
              );
            })}

            {has_deposit && (
              <>
                <tr>
                  <td>Rest-bedrag</td>
                  <td className="text-right  has-table-shrink-nowrap">- {formatCents(cart_rest_amount)}</td>
                </tr>
                <tr className="bg-primary">
                  <td>Aan te betalen met kaart</td>
                  <td className="text-right  has-table-shrink-nowrap">{formatCents(cart_deposit)}</td>
                </tr>
              </>
            )}
            {!has_deposit && (
              <tr className="bg-primary">
                <td>Te betalen met kaart</td>
                <td className="text-right  has-table-shrink-nowrap">{formatCents(inclusive_subtracting_giftcards)}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
