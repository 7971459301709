import * as React from 'react';
import { cs, formatCentsShort, formatEan13Number, playSoundNegative, playSoundPositive } from '../../../utils';
import { http } from '../../../http';
import { AxiosError } from 'axios';
import { BaseStore, useDebounce, useRxjsStore } from '../../../effects';
import { PageCashSaleCartArticleBase, PageDefinition } from './PageState';
import { MessageService } from '../../../services/MessageService';

type ComponentSearchProps = {
  page: PageDefinition;
};

type Context = {
  isOpen: boolean;
  loading: boolean;

  value: string;

  records: PageCashSaleCartArticleBase[];

  page: PageDefinition;
  search?: React.MutableRefObject<HTMLInputElement>;
};

class Store extends BaseStore<Context> {
  setup(): Context {
    return {
      isOpen: false,
      loading: false,

      value: '',

      records: [],

      page: null,
      search: null,
    };
  }

  private getPageStore() {
    return this.current().page.store;
  }

  setSearchRef(ref) {
    this.next(draft => {
      draft.search = ref;
    });
  }

  close() {
    this.next(draft => {
      draft.isOpen = false;
    });
  }

  focus() {
    const current = this.current().search.current;

    setTimeout(() => {
      if (document.activeElement !== current) {
        current.focus();
      }
    }, 5);
  }

  setValue(value: string) {
    this.next(draft => {
      draft.value = value;
    });
  }

  async load() {
    if (this.current().loading) {
      return false;
    }

    this.next(draft => {
      draft.loading = true;
    });

    this.focus();

    const value = this.current().value;

    if (value.length < 3) {
      this.next(draft => {
        draft.isOpen = false;
        draft.loading = false;
        draft.records = [];
      });
    } else {
      if (/^#?[0-9]{19}$/.exec(value)) {
        if (await this.loadByGiftcard()) {
          this.reset();
          playSoundPositive();
        } else {
          playSoundNegative();

          this.next(draft => {
            draft.loading = false;
          });
        }
      } else if (/^(#|]C1)?[0-9]{13}$/.exec(value)) {
        if (await this.loadByNumber()) {
          this.reset();
          playSoundPositive();
        } else {
          this.reset();
          this.getPageStore().toggleWrongCode(true, `Artikel met EAN13 code ${value} kon niet gevonden worden.`);
        }
      } else if (/^(#|]C1)?[0-9]+$/.exec(value)) {
        if (await this.loadByNumber()) {
          this.reset();
          playSoundPositive();
        } else {
          playSoundNegative();
          this.next(draft => {
            draft.loading = false;
          });
        }
      } else {
        await this.loadBySearch();
      }

      this.focus();
    }
  }

  async loadByNumber(): Promise<boolean> {
    const value = this.current().value;
    const page = this.current().page;

    let res;

    try {
      res = await http().post('/core/shop/article/one_by_number', { number: value });

      await page.store.addOrUpdate(dataResultToArticle(res.data).id);

      this.reset();

      return true;
    } catch (exc) {
      const err: AxiosError = exc;

      if (err.response && err.response.status == 404) {
        return false;
      }

      throw err;
    }
  }

  async loadByGiftcard(): Promise<boolean> {
    const value = this.current().value;

    try {
      const res = await http().post('/sale/giftcard/retrieve', { identifier: value });
      const data: ResponseGiftcardRetrieve = res.data;

      if (data.result_code === 119) {
        // Inactive card that may be activated.
        this.current().page.store.toggleModalGiftcardPurchase(data);
        this.reset();
      } else if (data.result_code === 0) {
        // Active card that can be used for payment.
        this.current().page.store.addGiftcardPayment(data);
        this.reset();
      } else {
        MessageService.setMessage(
          `Cadeaukaart gaf de foutmelding (${res.data.result_code}): ${res.data.result_description}`,
          'error',
          5000,
        );
      }

      return true;
    } catch (exc) {
      return false;
    }
  }

  async loadBySearch(): Promise<boolean> {
    const value = this.current().value;

    try {
      const res = await http().post('/core/shop/article/many_search', { search: value, limit: 20 });
      const records = res.data.records.map(x => dataResultToArticle(x));

      this.next(draft => {
        draft.isOpen = true;
        draft.records = records;
        draft.loading = false;
      });

      return true;
    } catch (exc) {
      const err: AxiosError = exc;

      this.next(draft => {
        draft.isOpen = false;
        draft.records = [];
        draft.loading = false;
      });

      return false;
    }
  }

  async select(article_id: number) {
    this.next(draft => {
      draft.loading = true;
    });

    await this.getPageStore().addOrUpdate(article_id);
    this.reset();
  }

  reset() {
    this.next(draft => {
      draft.isOpen = false;
      draft.value = '';
      draft.records = [];
      draft.loading = false;
    });
  }
}

export default function ComponentSearch(props: ComponentSearchProps) {
  const { page } = props;

  const me = React.useRef<HTMLFormElement>(null);
  const searchField = React.useRef<HTMLInputElement>(null);

  const { context, store } = useRxjsStore<Context, Store>(Store, (): any => {
    return {
      page,
    };
  });

  React.useEffect(() => {
    if (store && searchField) {
      store.setSearchRef(searchField);
    }
  }, [store && searchField]);

  React.useEffect(() => {
    function isModalOpen() {
      return (
        page.context.modalGiftcardPurchaseOpen ||
        page.context.modalAbandonOpen ||
        page.context.modalParkOpen ||
        page.context.modalUpdateLineOpen ||
        page.context.modalUpdateLine ||
        page.context.modalFinishOpen ||
        page.context.modalCustomerSearchOpen ||
        page.context.modalCustomerUpdateOpen ||
        page.context.modalAddressSelectOpen ||
        page.context.modalAddressUpdateOpen ||
        page.context.modalPersonSearchOpen ||
        page.context.modalTenantSearchOpen ||
        page.context.modalBuzzerOpen ||
        page.context.modalPaymentOpen
      );
    }

    function handleClickOutside(event) {
      if (me.current && !me.current.contains(event.target) && context.isOpen) {
        store.close();
      }

      handleKeyboardInput();
    }

    function handleKeyboardInput() {
      const will_focus = store && !isModalOpen();

      if (will_focus && searchField.current) {
        searchField.current.focus();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyboardInput);
    document.addEventListener('focus-search', handleKeyboardInput);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyboardInput);
      document.removeEventListener('focus-search', handleKeyboardInput);
    };
  });

  const debouncedSearch = useDebounce(context && context.value, 500);

  React.useEffect(() => {
    if (store) {
      store.load();
    }
  }, [debouncedSearch]);

  // endregion
  // region Events

  function onKeyUp(event: React.KeyboardEvent) {
    if (event.key === 'Escape') {
      store.close();
    } else if (event.key === 'Enter') {
      store.load();
    }
  }

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    store.setValue(event.target.value);
  }

  function onSearchItemClick(article_id: number) {
    return () => {
      store.select(article_id);
    };
  }

  // endregion

  if (!context) {
    return null;
  }

  return (
    <form autoComplete="off" onSubmit={e => e.preventDefault()} ref={me}>
      <div style={{ position: 'relative' }}>
        <div className={cs(context.loading && 'has-icon-right')}>
          <input
            type="text"
            name="search"
            value={context.value}
            onKeyUp={onKeyUp}
            onChange={onChange}
            ref={searchField}
            className="form-input"
            placeholder="Scannen of zoeken..."
          />
          {context.loading && (
            <div className="form-icon">
              <i className="fal fa-spin fa-spinner" />
            </div>
          )}
        </div>

        {context.isOpen && (
          <div
            className="menu"
            style={{ position: 'absolute', width: '75%', minWidth: '400px', overflow: 'auto', maxHeight: '60vh' }}
          >
            <div className="divider" data-content="Resultaten" />
            {context.records.map(article => (
              <div className="menu-item" key={article.id}>
                <a
                  onClick={onSearchItemClick(article.id)}
                  href="javascript:"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div style={{ display: 'flex', border: '1px solid rgba(0,0,0,0.1)' }} className=" s-rounded p-1">
                    {article.image_href ? (
                      <img
                        className="img-fit-contain"
                        src={article.image_href}
                        style={{
                          width: 40,
                          height: 40,
                        }}
                        alt={article.name}
                      />
                    ) : (
                      <img src="https://via.placeholder.com/150" alt="150x150" style={{ width: 40, height: 40 }} />
                    )}
                  </div>
                  <div style={{ flexGrow: 1 }} className="ml-2 mr-2">
                    <span className="text-large">{article.name}</span>
                    <br />
                    <code>{formatEan13Number(article.number_beeyond)}</code>
                  </div>
                  <div>{formatCentsShort(article.inclusive)}</div>
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </form>
  );
}

function dataResultToArticle(data: any): PageCashSaleCartArticleBase {
  return {
    id: data.id,
    name: data.name,

    type_id: data.type_id,
    tenant_id: data.tenant_id,

    inclusive: data.inclusive,
    exclusive: data.exclusive,
    vat: data.vat,
    vat_percentage: data.vat_percentage,

    number: data.number,
    number_foreign: data.number_foreign,
    number_lotus: data.number_lotus,
    number_beeyond: data.number_beeyond,
    number_gtin: data.number_gtin,
    number_mpn: data.number_mpn,
    number_external: data.number_external,

    image_href: data.images.length && data.images[0].href,
  };
}
