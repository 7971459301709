import * as React from 'react';

export function nl2br(value: string) {
  return value.split('\n').map((x, ix) => (
    <React.Fragment key={ix}>
      {x}
      <br />
    </React.Fragment>
  ));
}
