import * as React from 'react';
import { PageDefinition } from './PageState';
import { getGlobalProfileData } from '../../../services/ProfileService';
import Ticket from '../../../lib/Ticket';
import { BaseStore, useRxjsStore } from '../../../effects';
import { httpLive } from '../../../http';
import { cs } from '../../../utils';
import moment = require('moment');

type Props = {
  page: PageDefinition;
};

type Context = {
  cart: ModelShopCart;
  printing: boolean;
};

class Store extends BaseStore<Context> {
  setup(): Context {
    return { cart: null, printing: false };
  }

  async print() {
    const ggd = window.GLOBAL_GENERIC_DATA;
    const profile = getGlobalProfileData();
    const cart = this.current().cart;
    const now = moment().format('L LT');

    this.next(draft => {
      draft.printing = true;
    });

    const ticket = new Ticket();

    ticket.addHeader();

    ticket.addLongLabel(`Datum: ${now}`, `Bon: ${cart.id.toString().padStart(8)}`);
    ticket.addLine(`Medewerker: ${profile.person.first}`);
    ticket.addLine('');
    ticket
      .addBar(false)
      .addLine('')
      .addLine('GEPARKEERDE ORDER', 'center')
      .addLine('')
      .addBar(false)
      .addLine('')
      .addWrappedText(
        'Laat deze bon scannen door een medewerker van Loods 5, ' +
          'zij kunnen je dan direct verder helpen met deze order.',
      )
      .addLine('');

    await ticket.addFooter(cart.number_ean13);
    ticket.cutPaper();

    const res = await httpLive().post('/tickets/print', {
      ticket_printer_id: ggd.device.ticket_printer.id,
      ticket: await ticket.generate(),
    });
  }
}

export default function ModalPark(props: Props) {
  const ggd = window.GLOBAL_GENERIC_DATA;
  const ticket_printer = ggd.device && ggd.device.ticket_printer;

  const { context, store } = useRxjsStore<Context, Store>(Store, (): any => {
    return {
      cart: props.page.context.cart,
    };
  });

  if (context === null) return null;

  async function onParkClick() {
    await props.page.store.park();
  }

  async function onParkTicketClick() {
    await store.print();
    await props.page.store.park();
  }

  return (
    <div className="modal active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Order parkeren</div>
        </div>

        <div className="modal-body">
          {ticket_printer && (
            <p>
              Je kunt een parkeer-bon afdrukken en deze meegeven aan de klant. Na het parkeren kunnen al je collega's
              deze order terugvinden onder <em>Parkeren</em> in het kassa-menu. Deze order heeft het nummer{' '}
              <code>{context.cart.id}</code>.
            </p>
          )}
          {!ticket_printer && (
            <p>
              Er is geen ticket-printer verbonden aan deze computer, dus we kunnen helaas geen parkeer-bon voor je
              afdrukken. Deze order heeft het nummer: <code>{context.cart.id}</code>.
            </p>
          )}
        </div>

        <div className="modal-footer">
          <button type="button" className="btn btn-link  float-left" onClick={() => props.page.store.togglePark(false)}>
            <i className="fal fa-times" /> Sluiten
          </button>
          {ticket_printer && (
            <button
              type="button"
              className="btn btn-primary float-right"
              onClick={onParkTicketClick}
              disabled={context.printing}
            >
              <i className={cs('fal', context.printing ? 'fa-spin fa-spinner ' : 'fa-ticket')} /> Parkeer-bon &amp;
              parkeren
            </button>
          )}
          <button type="button" className="btn btn-primary-outline float-right" onClick={onParkClick}>
            <i className="fal fa-parking-circle" /> Parkeren
          </button>
        </div>
      </div>
    </div>
  );
}
