import * as React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { cs } from '../../../utils';
import { displayError } from '../../../components/FormGroup';
import FormSelect from '../../../components/FormSelect';
import FormInput from '../../../components/FormInput';
import { http } from '../../../http';

type Props = {
  close: () => void;
  select: (customer: ModelShopCustomer) => void;
};

type Values = {
  country: string;
  zipcode: string;
  housenumber: string;
  email: string;
};

type State = {
  loading: boolean;
  customers: ModelShopCustomer[];
};

export default function ModalCustomerSearch(props: Props) {
  const error = null;

  const [state, setState] = React.useState<State>({
    loading: false,
    customers: [],
  });

  async function onSubmit(values: Values, fp: FormikProps<Values>) {
    setState({
      loading: true,
      customers: [],
    });

    try {
      const res = await http().post('/sale/cart/customer_search', values);

      setState({ loading: false, customers: res.data.records });
    } catch (e) {
      console.log(e);
      setState({ loading: false, customers: [] });
    }
  }

  function pickCustomer(customer: ModelShopCustomer) {
    return () => {
      props.select(customer);
    };
  }

  return (
    <div className="modal active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Klant zoeken in database</div>
          <div className="h6 text-gray" />
        </div>

        <div className="modal-body">
          <Formik
            initialValues={{
              country: '1',
              zipcode: '',
              housenumber: '',
              email: '',
            }}
            onSubmit={onSubmit}
            render={fp => (
              <Form style={{ padding: '0 1rem' }}>
                <div className={cs('form-group', error && 'has-error')}>
                  <div className="columns">
                    <div className="column col-auto  pr-2">
                      <label className="form-label">Land</label>
                      <FormSelect name="country">
                        <option value="1">NL</option>
                        <option value="2">BE</option>
                        <option value="3">DE</option>
                        <option value="4">FR</option>
                      </FormSelect>
                      {error && <div className="form-input-hint">{displayError(error)}</div>}
                    </div>
                    <div className="column col-2 col-xs-12  pr-2">
                      <label className="form-label">Postcode</label>
                      <FormInput name="zipcode" autoFocus />
                      {error && <div className="form-input-hint">{displayError(error)}</div>}
                    </div>
                    <div className="column col-2 col-xs-12  pr-2">
                      <label className="form-label">Huisnummer</label>
                      <FormInput name="housenumber" />
                      {error && <div className="form-input-hint">{displayError(error)}</div>}
                    </div>
                    <div className="column col-xs-12  pr-2">
                      <label className="form-label">E-mailadres</label>
                      <FormInput name="email" />
                      {error && <div className="form-input-hint">{displayError(error)}</div>}
                    </div>
                    <div className="column col-auto" style={{ alignSelf: 'center' }}>
                      <button
                        type="submit"
                        className="btn btn-primary-outline btn-action s-circle"
                        disabled={fp.isSubmitting || state.loading}
                      >
                        <i className="fal fa-fw fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          />

          {state.loading && <p>We zijn aan het laden!</p>}
          {!state.loading && state.customers.length > 0 && (
            <div className="pt-2">
              <table className="table  table-hover">
                <tbody>
                  {state.customers.map(customer => (
                    <tr key={customer.id} onClick={pickCustomer(customer)}>
                      <td className="has-table-shrink-nowrap">#{customer.id}</td>
                      <td>{customer.name}</td>
                      <td>
                        {customer.default_address_delivery &&
                          (address => (
                            <>
                              {address.street} {address.housenumber} {address.extension}
                              <br />
                              {address.zipcode} {address.city}
                              {address.has_company && (
                                <div className="mt-1">
                                  {address.company_name}
                                  <br />
                                  {address.company_vat && <span>BTW: {address.company_vat}</span>}
                                </div>
                              )}
                            </>
                          ))(customer.default_address_delivery)}
                      </td>
                      <td>{customer.email}</td>
                      <td className="has-table-shrink-nowrap">
                        <i className="fal fa-fw fa-hand-point-up" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <div className="modal-footer">
          <button className="btn btn-link  float-left" onClick={props.close}>
            <i className="fal fa-times" /> Annuleren
          </button>
        </div>
      </div>
    </div>
  );
}
