import * as React from 'react';
import { cs } from '../utils';

export type FormError =
  | string
  | {
      message: string;
      data: { [key: string]: string };
    }
  | any;

export type FormErrors = { [name: string]: FormError };

export type FormGroupProps = {
  name: string;
  title?: string;
  errors?: FormErrors;
  children: React.ReactNode;
};

const errors: { [name: string]: string } = {
  doesNotExist: 'Kon niet gevonden worden.',
  isRequired: 'Dit veld is verplicht.',
  isNotUnique: 'Deze waarde bestaat al.',
  dateIsRequired: 'Datumformaat verwacht, dd-mm-jjjj',
  stringLength: 'Onjuiste hoeveelheid aan tekens.',
  stringMinLength: 'Te weinig tekens.',
  stringMaxLength: 'Teveel tekens.',
  invalidNumber: 'Ongeldig getal, bijvoorbeeld: 1345.33',
  invalidCalculation: 'Ongeldige berekening, de berekening is negatief geworden',
  invalidNumberWhole: 'Ongeldig getal, bijvoorbeeld: 13',
  giftcardMimimum: 'Cadeaukaart minimaal 5,- euro.',
  shouldBeEmail: 'E-mailadres gevraagd.',

  cpprNotFound: 'Verificatie-code onbekend.',
  cpprIsExpired: 'Verificatie-code is verlopen.',
  cpprCodeInvalid: 'Verificatie-code komt niet overeen.',

  eitherOrMobile: 'Dit veld, of telefoonnummer, is verplicht.',
  eitherOrPhone: 'Dit veld, of mobielnummer, is verplicht.',

  couldNotBeActivated: 'Deze kaart kan niet geactiveerd worden.',
};

const formatters: { [name: string]: (data: any) => string } = {
  stringMinLength: (data: { required: number; found: number }) => {
    return `Waarde te kort, ${data.required} tekens verwacht.`;
  },
  stringMaxLength: (data: { required: number; found: number }) => {
    return `Waarde te lang, maximaal ${data.required} tekens.`;
  },
};

export function displayError(error: FormError): string {
  if (typeof error === 'string') {
    return errors[error] || error;
  } else if (error) {
    const formatter = formatters[error.message];
    if (formatter) {
      return formatter(error.data);
    } else {
      return errors[error.message] || error.message;
    }
  }

  return '';
}

export default function FormGroup(props: FormGroupProps) {
  const error = props.errors && props.errors[props.name];
  let display: string = displayError(error);

  return (
    <div className={cs('form-group', error && 'has-error')}>
      {props.title && <label className="form-label">{props.title}</label>}
      {props.children}
      {display && <div className="form-input-hint">{display}</div>}
    </div>
  );
}
