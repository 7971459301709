import PageContactPersonMany from './person/PageContactPersonMany';

const ContactStructure = [
  {
    pathname: '/contact/person/many',
    title: 'Overzicht',
    icon: 'fa-desktop',
    component: PageContactPersonMany,

    rights: ['contact.person'],
  },
];

export default ContactStructure;
