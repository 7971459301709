import PageCashSale from './sale/PageCashSale';
import { isWithinMz } from '../../utils';
import PageCashParked from './PageCashParked';
import PageCashDashboard from './PageCashDashboard';

const CashStructure = [
  {
    pathname: '/cash/dashboard',
    title: 'Dashboard',
    icon: 'fa-desktop',
    component: PageCashDashboard,

    special: isWithinMz,
    rights: ['cash.sale'],
  },
  {
    pathname: '/cash/sale',
    title: 'Verkoop',
    icon: 'fa-money-bill-alt',
    component: PageCashSale,

    // special: isWithinMz,
    rights: ['cash.sale'],
  },
  {
    pathname: '/cash/parked',
    title: 'Parkeren',
    icon: 'fa-parking',
    component: PageCashParked,

    // special: isWithinMz,
    rights: ['cash.sale'],
  },
];

export default CashStructure;
