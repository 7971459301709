import * as React from 'react';
import { Context, Store } from '../generic/PageOrderGenericOne';
import { calculateInclusive, formatCents, formatDate, formatEan13Number, formatPercentage } from '../../../utils';

type Props = { context: Context; store: Store };

export default function ComponentOrderPurchaseActions(props: Props) {
  const { context, store } = props;
  const purchase = context.purchase;
  const actions = context.actions;

  const opla_kind_translations = {
    cancellation: 'Retour',
    sale: 'Kassaverkoop',
    delivered: 'Geleverd',
    compensation: 'Klantcompensatie',
  };

  return (
    <div className="card  mt-2">
      <div className="card-header">
        <div className="flex-row">
          <div className="flex-grow">
            <div className="card-title h5">Statementregels #{purchase.cart_id}</div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr>
              <th rowSpan={2}>Detail</th>
              <th rowSpan={2} className="text-right">
                Datum
              </th>
              <th colSpan={2} className="text-center is-left-column">
                Commissie
              </th>
              <th colSpan={2} className="text-center is-left-column">
                Administratie
              </th>
              <th colSpan={3} className="text-center is-left-column">
                Artikel
              </th>
            </tr>
            <tr>
              <th className="has-table-shrink-nowrap text-right is-left-column shrink">Bedrag (Exc.)</th>
              <th className="has-table-shrink-nowrap text-right shrink">Perc.</th>
              <th className="has-table-shrink-nowrap text-right is-left-column shrink">Bedrag (Exc.)</th>
              <th className="has-table-shrink-nowrap text-right shrink">Perc.</th>
              <th className="has-table-shrink-nowrap text-right is-left-column shrink">BTW %</th>
              <th className="has-table-shrink-nowrap text-right shrink">Bedrag (Exc.)</th>
              <th className="has-table-shrink-nowrap text-right shrink">
                <span className="tooltip tooltip-left shrink" data-tooltip="Het inclusieve bedrag is indicatief!">
                  Bedrag (Inc.) *
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {context.actions.map(action => {
              const [inclusive, vat] = calculateInclusive(action.exclusive, action.vat_percentage);
              return (
                <tr key={action.id}>
                  <td>
                    #{action.id} {opla_kind_translations[action.kind]}
                    {action.is_double_to_be_removed && (
                      <>
                        {' '}
                        <strong>
                          <i className="fal fa-fw  fa-clone" /> dubbel
                        </strong>
                      </>
                    )}
                    <br />
                    {formatEan13Number(action.article.number_beeyond)} - {action.article.name}
                  </td>
                  <td className="has-table-shrink-nowrap text-right">
                    {formatDate(action.created_at, 'L LT')}
                    <br />
                    {formatDate(action.created_at, 'YYYY / W')}
                  </td>
                  <td className="has-table-shrink-nowrap text-right is-left-column">
                    {formatCents(-action.commission_exclusive)}
                  </td>
                  <td className="has-table-shrink-nowrap text-right">
                    {formatPercentage(action.commission_percentage)}
                  </td>
                  <td className="has-table-shrink-nowrap text-right is-left-column">
                    {formatCents(-action.administration_exclusive)}
                  </td>
                  <td className="has-table-shrink-nowrap text-right">
                    {formatPercentage(action.administration_percentage)}
                  </td>
                  <td className="has-table-shrink-nowrap text-right is-left-column">
                    {formatPercentage(action.vat_percentage * 100)}
                  </td>
                  <td className="has-table-shrink-nowrap text-right">{formatCents(action.exclusive)}</td>
                  <td className="has-table-shrink-nowrap text-right">{formatCents(inclusive)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
