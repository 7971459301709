import { BaseStore, useRxjsStore } from '../../../effects';
import * as React from 'react';
import { loadManyRecordsOr, loadManyRecordsPaginated } from '../../../utils';
import Query from '../../../lib/Query';
import _ = require('underscore');

export type PageVacancyJobManyContext = {
  loaded: boolean;
  locations: ModelVacancyLocation[];
  departments: ModelVacancyDepartment[];
  leaders: ModelVacancyLeader[];

  query: PageVacancyJobManyContextSearch;
  query_is_empty: boolean;

  pagination?: Pagination;
  jobs: ModelVacancyJob[];

  modal?: PageVacancyJobManyModalContext;
};

export type PageVacancyJobManyContextSearch = {
  page: number;
  per_page: number;
} & PageVacancyJobManyContextSearchValues;

export type PageVacancyJobManyContextSearchValues = {
  title: string;
  online_status: string;
  status: string;
  location?: string;
  department?: string;
};

const query = new Query<PageVacancyJobManyContextSearch>('PageVacancyJobMany');
function emptyQuery(): PageVacancyJobManyContextSearch {
  return {
    page: 0,
    per_page: 12,

    title: '',
    online_status: '',
    status: '',
    location: null,
    department: null,
  };
}
function isEmptyQuery(query: PageVacancyJobManyContextSearch): boolean {
  return _.isEqual(query, emptyQuery());
}

export class PageVacancyJobManyStore extends BaseStore<PageVacancyJobManyContext> {
  setup(): PageVacancyJobManyContext {
    const q = query.load() || emptyQuery();

    return {
      loaded: false,
      locations: [],
      departments: [],
      leaders: [],

      query: q,
      query_is_empty: isEmptyQuery(q),

      pagination: null,
      jobs: [],

      modal: null,
    };
  }

  async load(partial: boolean = true) {
    this.next(draft => {
      draft.loaded = false;
    });

    if (partial === false) {
      const locations = await loadManyRecordsOr('/core/vacancy/location/many');
      const departments = await loadManyRecordsOr('/core/vacancy/department/many');
      const leaders = await loadManyRecordsOr('/core/vacancy/leader/many');

      this.next(draft => {
        draft.locations = locations;
        draft.departments = departments;
        draft.leaders = leaders;
      });
    }

    const jobs = await loadManyRecordsPaginated('/core/vacancy/job/many', this.current().query);

    this.next(draft => {
      draft.loaded = true;

      draft.pagination = jobs.pagination;
      draft.jobs = jobs.records;
    });

    return this;
  }

  async search(values: PageVacancyJobManyContextSearchValues) {
    this.next(draft => {
      draft.query.title = values.title || '';
      draft.query.online_status = values.online_status || '';
      draft.query.status = values.status || '';
      draft.query.location = values.location || null;
      draft.query.department = values.department || null;

      draft.query_is_empty = isEmptyQuery(draft.query);

      query.save(draft.query);
    });

    return await this.load();
  }

  async resetQuery() {
    return await this.search(emptyQuery());
  }

  // region Utils

  async setPage(page: number) {
    this.next(draft => {
      draft.query.page = page;
      draft.query_is_empty = isEmptyQuery(draft.query);

      query.save(draft.query);
    });
    await this.load();
  }

  departmentById(department_id: number) {
    return this.current().departments.find(x => x.id === department_id);
  }
  locationById(location_id: number) {
    return this.current().locations.find(x => x.id === location_id);
  }

  openModal(ctx: PageVacancyJobManyModalContext) {
    this.next(draft => {
      draft.modal = ctx;
    });
  }

  closeModal = ({ reload }: { reload: boolean } = { reload: false }) => {
    this.next(draft => {
      draft.modal = null;
    });

    if (reload) {
      setTimeout(() => {
        this.load();
      }, 1);
    }
  };

  // endregion
}

// region Modal Context

export type PageVacancyJobManyModalContext =
  | {
      which: 'job-create';
      data: {};
    }
  | {
      which: 'job-edit';
      data: { job: ModelVacancyJob };
    }
  | {
      which: 'job-online-status';
      data: { job: ModelVacancyJob };
    }
  | {
      which: 'job-activate';
      data: { job: ModelVacancyJob };
    }
  | {
      which: 'job-archive';
      data: { job: ModelVacancyJob };
    }
  | {
      which: 'job-dearchive';
      data: { job: ModelVacancyJob };
    };

// endregion
// region Utilities

export type PageVacancyJobManyBasicProps = {
  store: PageVacancyJobManyStore;
  context: PageVacancyJobManyContext;
};

export function usePageVacancyJobManyRxjs(): PageVacancyJobManyBasicProps {
  const { store, context } = useRxjsStore<PageVacancyJobManyContext, PageVacancyJobManyStore>(
    PageVacancyJobManyStore,
    null,
    () => {
      store.load(false);
    },
  );

  return { store, context };
}

export const PageVacancyJobManyCtx = React.createContext<PageVacancyJobManyBasicProps>(null);

// endregion
