import * as React from 'react';
import { Field, FieldProps } from 'formik';
import { cs } from '../utils';

type FormInputProps = {
  name: string;
  loading?: boolean;
  type?: string;
  placeholder?: string;
  classNames?: string[];
  innerRef?: (el: React.ReactHTMLElement<any>) => void;
  component?: string | React.ComponentType<FieldProps<any>> | React.ComponentType<void>;
  rows?: number;
  readOnly?: boolean;
  autoFocus?: boolean;
  onFocus?: (e: React.FocusEvent<any>) => void;
};

export default function FormInput(props: FormInputProps) {
  return (
    <div className={cs(props.loading && 'has-icon-right')}>
      <Field
        component={props.component || 'input'}
        innerRef={props.innerRef}
        type={props.type || 'text'}
        name={props.name}
        className={cs('form-input', props.classNames)}
        disabled={props.loading}
        placeholder={props.placeholder}
        autoComplete="off"
        autoFocus={props.autoFocus}
        onFocus={props.onFocus}
        rows={props.rows}
        readOnly={props.readOnly}
      />
      {props.loading && (
        <div className="form-icon">
          <i className="fal fa-spin fa-spinner" />
        </div>
      )}
    </div>
  );
}
