import * as React from 'react';
import { cs } from '../utils';

type AtomTableNoRecordsProps = {
  icon?: string;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
};

export default function AtomTableNoRecords(props: AtomTableNoRecordsProps) {
  return (
    <div className="card-body  card-body-fixed">
      <div className="empty">
        <div className="empty-icon">
          <i className={cs('fal fa-3x', props.icon || 'fa-empty-set')} />
        </div>
        <p className="empty-title h5">{props.title || 'Geen regels'}</p>
        <p className="empty-subtitle">
          {props.subtitle || 'Er zijn geen regels gevonden die voldoen aan je zoekopdracht...'}
        </p>
      </div>
    </div>
  );
}
