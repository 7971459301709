import * as React from 'react';
import { useTimeoutEffect } from '../../effects';
import { ProfileService } from '../../services/ProfileService';
import { LocationService } from '../../services/LocationService';
import { http } from '../../http';

export default function PageDmzLogoutEverywhere() {
  const profile = ProfileService.useEffect('PageDmzLogoutEverywhere');

  async function logout() {
    await http().post('/dmz/security/signout_everywhere', { person: profile.person.id, myself: true });

    ProfileService.empty();
    LocationService.navigate('/dmz/login');
  }

  if (!profile.person) return null;

  return (
    <div className="container">
      <div className="columns">
        <div className="col-4 col-mx-auto">
          <div className="card">
            <div className="card-header">
              <div className="card-title h5">Overal afmelden</div>
            </div>

            <div className="card-body">
              <p>
                Weet je zeker dat je dit account op alle overige plekken wilt afmelden? Wanneer een andere gebruiker nu
                in jouw account zit, zal deze na het afmelden <em>direct</em> niet meer kunnen werken.
              </p>
            </div>

            <div className="card-footer">
              <div className="flex-row flex-space-between">
                <div>
                  <button type="submit" className="btn btn-error" onClick={logout}>
                    <i className="fal fa-trash" /> Ja, meld mij overal af
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
