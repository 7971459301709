import * as React from 'react';

export type HeigtAnimationProps = {
  active: boolean;
  children: React.ReactNode;
};

export default function HeightAnimation(props: HeigtAnimationProps) {
  const content = React.useRef(null);
  const [state, setState] = React.useState(0);

  React.useEffect(() => {
    if (content.current) {
      const element = (content.current as any) as HTMLDivElement;
      setState(element.offsetHeight);
    }

    return () => null;
  }, [props.active]);

  return (
    <div className="HeightAnimation-container" style={{ height: props.active ? state : 0 }}>
      <div className="HeightAnimation-content" ref={content}>
        {props.children}
      </div>
    </div>
  );
}
