import { cs, paginationCrunch } from '../utils';
import * as React from 'react';

export default function GenericPagination({ pagination, setPage, span }) {
  if (!pagination) {
    return null;
  }

  const { current_page, total_pages } = pagination;

  return (
    <tbody>
      <tr>
        <td colSpan={span}>
          <ul className="pagination" style={{ margin: '-0.7rem 0' }}>
            <li className={cs('page-item', current_page === 0 && 'disabled')}>
              <a href="javascript:" onClick={() => setPage(current_page - 1)}>
                Vorige
              </a>
            </li>
            {paginationCrunch(pagination).map(page => (
              <li key={page} className={cs('page-item', page === current_page && 'active')}>
                <a href="javascript:" onClick={() => setPage(page)}>
                  {page + 1}
                </a>
              </li>
            ))}
            <li className={cs('page-item', current_page === total_pages - 1 && 'disabled')}>
              <a href="javascript:" onClick={() => setPage(current_page + 1)}>
                Volgende
              </a>
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  );
}
