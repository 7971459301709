import * as React from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { getTouchedErrors } from '../../../utils';
import { handleHttpError, handleSimpleFormSubmit, http, SubmitResult } from '../../../http';
import FormGroup from '../../../components/FormGroup';
import FormInput from '../../../components/FormInput';

type Props = {
  onClose: (success?: boolean) => void;
};

type FormValues = {
  ip_address: string;
};

const schema = Yup.object().shape({
  ip_address: Yup.string()
    .min(6, 'stringMinLength')
    .required('isRequired'),
});

function FormRender(props: Props & { fp: FormikProps<FormValues> }) {
  const errors = getTouchedErrors(props.fp.touched, props.fp.errors);

  return (
    <Form className="modal-container">
      <div className="modal-header">
        <div className="modal-title h5">Aanmaken Ticket Printer</div>
      </div>
      <div className="modal-body">
        <FormGroup title="IP-adres" name="ip_address" errors={errors}>
          <FormInput name="ip_address" loading={props.fp.isSubmitting} />
        </FormGroup>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-link  float-left" onClick={() => props.onClose()}>
          <i className="fal fa-times" /> Annuleren
        </button>

        <button type="submit" className="btn btn-primary  float-right" disabled={props.fp.isSubmitting}>
          <i className="fal fa-paper-plane" /> Versturen
        </button>
      </div>
    </Form>
  );
}

export default function ModalCreateTicketPrinter(props: Props) {
  async function sendData(values: FormValues): Promise<SubmitResult> {
    try {
      const result = await http().post('/core/device/ticket_printer/create', values);

      setTimeout(() => {
        props.onClose(true);
      }, 250);
    } catch (exc) {
      return handleHttpError(exc, 'Het verwerken van de data ging niet goed.');
    }
  }

  return (
    <div className="modal active" id="ModalCreateTicketPrinter">
      <div className="modal-overlay" />

      <Formik
        initialValues={{ ip_address: '' }}
        validationSchema={schema}
        onSubmit={handleSimpleFormSubmit<FormValues>(sendData)}
        render={fp => <FormRender {...props} fp={fp} />}
      />
    </div>
  );
}
