import * as React from 'react';
import { http } from '../../../http';

type Props = {
  job: ModelVacancyJob;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalJobArchive({ job, closeModal }: Props) {
  const is_fulfilled = job.amount_applications_employed > 0;

  async function onClick() {
    await http().post('/core/vacancy/job/status', {
      job: job.id,
      status: is_fulfilled ? 'fulfilled' : 'abandoned',
    });

    closeModal({ reload: true });
  }

  return (
    <div className="modal modal-sm active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">
            Vacature <strong>{job.title}</strong> {is_fulfilled ? 'vervuld' : 'verlaten'}
          </div>
        </div>

        <div className="modal-body">
          <p>
            {is_fulfilled ? 'Vacature archiveren als vervuld' : 'Vacature archiveren als verlaten'}. Hiermee sluit je de
            vacature. Hij verdwijnt hiermee uit het actieve overzicht, maar je kunt hem uiteraard altijd terugvinden in
            het archief. Na het archiveren kun je geen aanpassingen meer doen in de vacature.
          </p>
          {job.amount_applications_active > 0 && (
            <p>Er zullen automatisch {job.amount_applications_active} sollicitaties afgewezen worden.</p>
          )}
          {job.online_status === 'online' && <p>Oh, en we halen de vacature offline.</p>}
        </div>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                <i className="fal fa-times" /> Annuleren
              </button>
            </div>
            <div>
              {is_fulfilled ? (
                <button type="button" className="btn btn-primary" onClick={onClick}>
                  <i className="fal fa-check" /> Archiveren als vervuld
                </button>
              ) : (
                <button type="button" className="btn btn-error" onClick={onClick}>
                  <i className="fal fa-trash" /> Archiveren als verlaten
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
