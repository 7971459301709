import * as React from 'react';
import { LocationService, urlFor } from '../services/LocationService';

export interface LinkProps extends LinkParams, React.AnchorHTMLAttributes<any> {
  /** url properties */
  handleClick?: (pathname: string, search: SearchParams, hash: string) => boolean | void;

  disabled?: boolean;

  /** inside */
  children: any;
}

/**
 * Usage:
 * <a onClick={navigate('/somewhere')}>Link</a>
 */
export const navigate = (pathname: string, search: SearchParams = {}, hash: string = '', force: boolean = false) => {
  return (event: any) => {
    event && event.preventDefault();
    LocationService.navigate(pathname, search, hash, force);
  };
};

export function Link(props: LinkProps) {
  const { pathName, pathSearch, pathHash, children, handleClick, ...extra } = props;
  const search = pathSearch || {};
  const hash = pathHash || '';

  const disabled = extra.className && extra.className.split(' ').indexOf('disabled') !== -1;

  const location = urlFor(pathName, search, hash);
  const handle = (event: any) => {
    event && event.preventDefault();

    if (!disabled && handleClick === undefined || handleClick(pathName, search, hash) !== false) {
      LocationService.navigate(pathName, search, hash);
    }
  };

  return (
    <a href={location} onClick={handle} {...extra}>
      {children}
    </a>
  );
}
