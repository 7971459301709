import { PageDefinition } from './PageState';
import * as React from 'react';
import MenuDropdown from '../../../components/MenuDropdown';

export function ComponentTileTenant({ page }: { page: PageDefinition }) {
  const tenant = page.context.tenant;

  return (
    <div className="tile mb-2  tile-centered">
      <div className="tile-content">
        <div className="tile-title">#{tenant.id} &ndash; {tenant.name}</div>
        <small className="tile-subtitle text-gray">
          Deelnemer gekoppeld aan de order.
        </small>
      </div>
      <div className="tile-action">
        <MenuDropdown
          button={<i className="fal fa-pencil" />}
          classNames={['menu-dropdown-top']}
          items={[
            <a href="javascript:" onClick={() => page.store.anonymize()}>
              <i className="fal fa-trash-alt  mr-1" /> ontkoppelen
            </a>,
          ]}
        />
      </div>
    </div>
  );
}
