import * as React from 'react';
import { cs } from '../utils';

type Props = {
  button: string | React.ReactElement<any>;
  items: (React.ReactElement<any> | boolean)[];
  classNames?: string[];
  btnClassNames?: string[];
  btnNoLink?: boolean;
  hover?: boolean;
};

export default function MenuDropdown(props: Props) {
  const { button, items, classNames, btnClassNames, btnNoLink, hover } = props;
  const me = React.useRef(null);

  const [open, setOpen] = React.useState(false);
  const [hasEntered, setHasEntered] = React.useState(false);
  const [hasLeft, setHasLeft] = React.useState(false);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (me.current && !me.current.contains(event.target) && open) {
        setOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  React.useEffect(() => {
    if (!hover || !hasEntered) return;

    const timeout = setTimeout(() => {
      setOpen(true);
      setHasLeft(false);
    }, 250);

    return () => clearTimeout(timeout);
  }, [hasEntered]);

  React.useEffect(() => {
    if (!hover || !hasLeft) return;

    const timeout = setTimeout(() => {
      setOpen(false);
    }, 450);

    return () => clearTimeout(timeout);
  }, [hasLeft]);

  function onButtonClick() {
    if (!hover) setOpen(!open);
  }

  function onItemClick() {
    setOpen(false);
  }

  return (
    <div ref={me} style={{ position: 'relative' }} className={cs('menu-dropdown', ...(classNames || []))}>
      <div
        className={cs('btn', !btnNoLink && 'btn-link', open && 'active', ...(btnClassNames || []))}
        onClick={onButtonClick}
        onMouseEnter={() => setHasEntered(true)}
        onMouseLeave={() => {
          setHasEntered(false);
          setHasLeft(true);
        }}
      >
        {button}
      </div>
      {open && (
        <div
          className="menu"
          style={{ maxWidth: 250 }}
          onMouseEnter={() => setHasLeft(false)}
          onMouseLeave={() => setHasLeft(true)}
        >
          {items.map((item, ix) => {
            if (item === true) return <div className="menu-spacer" key={ix} />;
            if (item === false || item === null) return null;

            return (
              <div className="menu-item has-text-nowrap" onClick={onItemClick} key={ix}>
                {item}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
