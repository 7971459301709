import * as React from 'react';
import { LocationService } from '../services/LocationService';
import { ProfileService } from '../services/ProfileService';
import { cs } from '../utils';
import Structure from '../lib/Structure';
import HeightAnimation from './HeightAnimation';
import { Link } from './Link';
import { GenericDataService } from '../services/GenericDataService';
import { useOnlineStatus } from '../effects';

type State = {
  isOpen: boolean;
  isPinned: boolean;
  menuIndex: number | null;
  sectionIndexes: number[] | null;
};

function useNavigationState() {
  const shouldBePinned = window.innerWidth > 1366;

  const initial = {
    isOpen: shouldBePinned,
    isPinned: shouldBePinned,
    menuIndex: null,
    sectionIndexes: null,
  };

  const [state, setState] = React.useState<State>(initial);

  function toggleOpen(isOpen?: boolean) {
    const value = isOpen === undefined ? !state.isOpen : isOpen;

    if (!state.isPinned && !value) {
      setState({
        ...state,
        isOpen: false,
      });
    } else {
      setState({
        ...state,
        isOpen: value,
      });
    }
  }

  function togglePinned(isPinned?: boolean) {
    setState({ ...state, isPinned: isPinned === undefined ? !state.isPinned : isPinned });
  }

  function setMenuIndex(menuIndex: number) {
    setState({
      ...state,
      menuIndex,
      sectionIndexes: [0],
      isOpen: true,
    });
  }

  function toggleSectionIndex(sectionIndex: number) {
    const sectionIndexes = state.sectionIndexes || [];
    const index = sectionIndexes.indexOf(sectionIndex);

    if (index === -1) {
      setState({ ...state, sectionIndexes: [...sectionIndexes, sectionIndex] });
    } else {
      setState({ ...state, sectionIndexes: sectionIndexes.filter(x => x !== sectionIndex) });
    }
  }

  return {
    state,

    toggleOpen,
    togglePinned,
    setMenuIndex,
    toggleSectionIndex,
  };
}

export default function AppNavigation() {
  const location = LocationService.useEffect('AppNavigation');
  const profile = ProfileService.useEffect('AppNavigation');
  const generic = GenericDataService.useEffect('PageOrderGenericOne');
  const isOnline = useOnlineStatus();

  const { state, toggleOpen, togglePinned, setMenuIndex, toggleSectionIndex } = useNavigationState();

  // region Helpers & Events

  function onMouseEnter(event: React.MouseEvent<HTMLDivElement>) {
    if (!state.isPinned) {
      toggleOpen(true);
    }
  }

  function onMouseLeave(event: React.MouseEvent<HTMLDivElement>) {
    if (!state.isPinned) {
      toggleOpen(false);
    }
  }

  function isSectionOpen(sectionIndex: number) {
    return sectionIndexes.indexOf(sectionIndex) !== -1;
  }

  function onItemClick() {
    if (!state.isPinned && state.isOpen) {
      toggleOpen(false);
    }
  }

  if (profile.person === null) return null;

  // endregion

  if (state.isPinned) {
    window.document.body.classList.add('AppNavigation-isPinned');
  } else {
    window.document.body.classList.remove('AppNavigation-isPinned');
  }
  if (state.isOpen) {
    window.document.body.classList.add('AppNavigation-isOpen');
  } else {
    window.document.body.classList.remove('AppNavigation-isOpen');
  }

  // region Define menu & section indexes

  const menu = Structure.getMenuForProfile(profile);
  const [activeMenuIndex, activeSectionIndex] = Structure.getIndexes(menu, location.pathname);

  let menuIndex = 0;

  if (state.menuIndex !== null) {
    menuIndex = state.menuIndex;
  } else if (activeMenuIndex !== null) {
    menuIndex = activeMenuIndex;
  }

  const sectionIndexes = state.sectionIndexes === null ? [activeSectionIndex] : state.sectionIndexes;
  const shownMenuItem = menu[menuIndex || 0];

  // endregion

  return (
    <div
      className={cs('AppNavigation', state.isOpen && 'active')}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="AppNavigation-Menu">
        <Link className="MenuLogo" pathName="/">
          {isOnline && <img src={require('./../assets/logo.svg')} alt="Loods 5 - Apps" />}
          {!isOnline && (
            <div
              style={{
                position: 'relative',
              }}
            >
              <img src={require('./../assets/logo.svg')} alt="Loods 5 - Apps" style={{ opacity: 0.3 }} />
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 'calc(50% - 35px / 2)',
                  fontSize: 28,
                }}
              >
                <i className="fal fa-wifi-slash text-error" />
              </div>
            </div>
          )}
        </Link>

        {menu.map((item, mx) => (
          <div className={cs('MenuItem', mx === menuIndex && 'active', item.isProfile && 'profile')} key={item.title}>
            <a
              className="MenuItem-wrapper tooltip tooltip-right"
              href="javascript:"
              onClick={e => ~e.preventDefault() && setMenuIndex(mx)}
              data-tooltip={item.title}
            >
              {item.isProfile ? (
                <div className="profile-btn">
                  {profile.person.name
                    .split(' ')
                    .map(x => x.charAt(0))
                    .filter(x => !!/[A-Z]/.exec(x))
                    .join('')}
                </div>
              ) : (
                <i className={`fal ${item.icon}`} />
              )}
            </a>
          </div>
        ))}
      </div>

      {shownMenuItem && (
        <div className="AppNavigation-Items">
          <div className="ItemsHeader">
            <div className="ItemsHeader-title">{shownMenuItem.title}</div>
            <a className="ItemsHeader-pinned" href="javascript:" onClick={() => togglePinned()}>
              <i className={cs('fal', state.isPinned ? 'fa-link' : 'fa-unlink')} />
            </a>
            <a className="ItemsHeader-close" href="javascript:" onClick={() => toggleOpen(false)}>
              <i className="fal fa-times" />
            </a>
          </div>

          {shownMenuItem.sections.map((section, sx: number) => (
            <div className="ItemsDrop" key={section.title}>
              <a className="ItemsDropHeader" href="javascript:" onClick={() => toggleSectionIndex(sx)}>
                <div className="ItemsDrop-icon">
                  <i className={cs('fal', section.icon)} />
                </div>
                <div className="ItemsDrop-title">{section.title}</div>
                <div className="ItemsDrop-toggle">
                  <i className={cs('fal', isSectionOpen(sx) ? 'fa-caret-down' : 'fa-caret-up')} />
                </div>
              </a>

              <HeightAnimation active={isSectionOpen(sx)}>
                <div className="ItemsDropped">
                  {section.items.map((p, ix) => {
                    const item = Structure.structureByPathname[p];

                    return (
                      <Link
                        key={item.title}
                        pathName={item.pathname}
                        handleClick={onItemClick}
                        className={cs('DropItem', item.pathname === location.pathname && 'active')}
                      >
                        {item.icon && (
                          <div className="DropItem-icon">
                            <i className={cs('fal', item.icon)} />
                          </div>
                        )}
                        <div className="DropItem-title">{item.title}</div>
                      </Link>
                    );
                  })}
                </div>
              </HeightAnimation>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
