import * as React from 'react';
import { Field } from 'formik';
import { cs } from '../utils';

type FormSelectProps = {
  name: string;
  loading?: boolean;
  children: React.ReactNode;
};

export default function FormSelect(props: FormSelectProps) {
  return (
    <div>
      <Field component="select" name={props.name} className="form-select" disabled={props.loading}>
        {props.children}
      </Field>
      {props.loading && (
        <div className="form-icon">
          <i className="fal fa-spin fa-spinner" />
        </div>
      )}
    </div>
  );
}
