import * as React from 'react';
import { http } from '../../../http';

type Props = {
  application: ModelVacancyApplication;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalJobApplicationDelete({ application, closeModal }: Props) {
  async function onClick() {
    await http().post('/core/vacancy/application/delete', {
      application: application.id,
    });

    closeModal({ reload: true });
  }

  return (
    <div className="modal modal-sm active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">
            Sollicitatie van <strong>{application.applicant.name}</strong>{' '}
            <strong>
              <em>verwijderen</em>
            </strong>
          </div>
        </div>

        <div className="modal-body">
          <p>
            <strong>Let op!</strong> Je gaat deze sollicicatie volledig verwijderen. Hierdoor raak je alle gekoppelde
            data kwijt. <strong>Dit is niet anonimiseren</strong>, want dan blijven de statistieken bewaard,{' '}
            <strong>je verwijdert nu echt alles</strong>!
          </p>
          <p>
            <em>Het verwijderen kan niet ongedaan gemaakt worden</em>.
          </p>
        </div>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                <i className="fal fa-times" /> Annuleren
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-error" onClick={onClick}>
                <i className="fal fa-trash" /> Verwijderen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
