import * as React from 'react';

export default function ComponentFinancePaymentKind({ kind }: { kind: string }) {
  if (kind === 'offline-worldline')
    return (
      <span>
        <i className="fal fa-fw  fa-cash-register" /> Kassabetaling
      </span>
    );
  if (kind === 'online-adyen')
    return (
      <span>
        <i className="fal fa-fw  fa-browser" /> Internet-betaling
      </span>
    );
  if (kind === 'online-intersolve')
    return (
      <span>
        <i className="fal fa-fw  fa-credit-card-front" /> Cadeaukaartbetaling
      </span>
    );
  if (kind === 'by-bank')
    return (
      <span>
        <i className="fal fa-fw  fa-piggy-bank" /> Bankbetaling
      </span>
    );
  if (kind === 'internal-transfer')
    return (
      <span>
        <i className="fal fa-fw  fa-exchange-alt" /> Interne transfer
      </span>
    );

  return null;
}
