import * as React from 'react';
import { BaseStore, useRxjsStore } from '../effects';
import { http } from '../http';
import { cs, formatCents, formatDate, loadManyRecordsOr } from '../utils';
import { ProfileService } from '../services/ProfileService';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import * as moment from 'moment';
import * as _ from 'underscore';

export default function PageDashboard() {
  const profile = ProfileService.useEffect('AppRouter');

  if (profile.person.is_global) {
    if (_.contains(profile.person.rights, 'finance.statistics')) {
      return <DashboardAdministrator />;
    }
  } else if (profile.person.tenant_set.length !== 0) {
    return <DashboardTenant />;
  }

  return null;
}

// region Administrator

type AdministratorContext = {
  loading: boolean;
  records: {
    day: string;
    uid: string;
    year: number;
    week: number;
    sale: number;
    delivered: number;
    cancellation: number;
    compensation: number;
  }[];
  record_active_index: number;
  uid: string;
};

class AdministratorStore extends BaseStore<AdministratorContext> {
  setup(): AdministratorContext {
    return {
      loading: false,
      records: [],
      uid: '-1',
      record_active_index: -1,
    };
  }

  async load() {
    const records = await loadManyRecordsOr('/core/finance/statistics/administrator_dashboard_generic');

    this.next(draft => {
      draft.records = records;
      if (records.length) {
        draft.uid = records[records.length - 1].uid;
      }
    });
  }

  setUid(uid: string) {
    this.next(draft => {
      draft.uid = uid;

      const week = parseInt(uid.split('-').pop());

      if (moment().week() === week) {
        draft.record_active_index = moment().weekday();
      } else {
        draft.record_active_index = 0;
      }
    });
  }

  setRecordActiveIndex(index) {
    this.next(draft => {
      draft.record_active_index = index;
    });
  }
}

function DashboardAdministrator() {
  const { context, store } = useRxjsStore<AdministratorContext, AdministratorStore>(
    AdministratorStore,
    null,
    async () => {
      await store.load();
    },
  );

  if (!context) return null;

  const weeks = [...new Set(context.records.map(x => x.uid))];
  const week_data = context.records.filter(x => x.uid == context.uid);
  const day_data = week_data.find(x => moment(x.day).weekday() === context.record_active_index);

  return (
    <div className="container">
      <div className="columns">
        <div className="column col-4" style={{ minHeight: '100%' }}>
          <div className="card" style={{ minHeight: '100%' }}>
            <div className="card-header">
              <h5 className="card-title">Weekomzetten</h5>
            </div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="text-left">Wk.</th>
                  <th className="text-right has-table-shrink">Kassa</th>
                  <th className="text-right has-table-shrink">Geleverd</th>
                </tr>
              </thead>
              <tbody>
                {weeks.reverse().map(uid => {
                  const records = context.records.filter(x => x.uid === uid);
                  const sale = records.reduce((r, x) => r + x.sale, 0);
                  const delivered = records.reduce((r, x) => r + x.delivered, 0);

                  return (
                    <tr
                      key={uid}
                      className={cs(context.uid === uid && 'bg-primary')}
                      style={{ cursor: 'pointer' }}
                      onClick={() => store.setUid(uid)}
                    >
                      <td className="text-left">{uid.split('-').join(' / ')}</td>
                      <td className="text-right has-table-shrink-nowrap">{formatCents(sale)}</td>
                      <td className="text-right has-table-shrink-nowrap">{formatCents(delivered)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="column col-8" style={{ minHeight: '100%' }}>
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Dagomzetten</h5>
            </div>
            <div className="card-body">
              {week_data.length > 0 && (
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart
                    data={week_data}
                    margin={{
                      left: 20,
                      bottom: 60,
                    }}
                    onClick={x => {
                      store.setRecordActiveIndex(x.activeTooltipIndex);
                    }}
                  >
                    <CartesianGrid stroke="#ccc" />
                    <XAxis
                      dataKey="day"
                      interval={0}
                      tick={props => {
                        return (
                          <g transform={`translate(${props.x}, ${props.y})`}>
                            <text
                              x={0}
                              y={0}
                              dy={16}
                              textAnchor="end"
                              fill="#666"
                              transform="rotate(-35)"
                              fontWeight={props.index === context.record_active_index ? 'bold' : 'normal'}
                            >
                              {moment(props.payload.value).format('L')}
                            </text>
                          </g>
                        );
                      }}
                    />
                    <YAxis
                      tick={props => {
                        return (
                          <g transform={`translate(${props.x}, ${props.y})`}>
                            <text x={0} y={-12} dy={16} textAnchor="end" fill="#666">
                              {formatCents(props.payload.value)}
                            </text>
                          </g>
                        );
                      }}
                    />
                    <Tooltip
                      content={props => {
                        return null; // We do this to have the "cursor" background in the chart.
                      }}
                    />
                    <Bar dataKey="sale">
                      {week_data.map((item, ix) => (
                        <Cell
                          cursor="pointer"
                          fill={ix === context.record_active_index ? '#8BC34A' : '#AED581'}
                          stroke={ix === context.record_active_index ? '#558B2F' : '#8BC34A'}
                          key={ix}
                        />
                      ))}
                    </Bar>
                    <Bar dataKey="delivered">
                      {week_data.map((item, ix) => (
                        <Cell
                          cursor="pointer"
                          fill={ix === context.record_active_index ? '#00796b' : '#009688'}
                          stroke={ix === context.record_active_index ? '#004D40' : '#00796b'}
                          key={ix}
                        />
                      ))}
                    </Bar>
                    <Bar dataKey="cancellation">
                      {week_data.map((item, ix) => (
                        <Cell
                          cursor="pointer"
                          fill={ix === context.record_active_index ? '#FF9800' : '#FFB74D'}
                          stroke={ix === context.record_active_index ? '#F57C00' : '#FF9800'}
                          key={ix}
                        />
                      ))}
                    </Bar>
                    <Bar dataKey="compensation">
                      {week_data.map((item, ix) => (
                        <Cell
                          cursor="pointer"
                          fill={ix === context.record_active_index ? '#9C27B0' : '#BA68C8'}
                          stroke={ix === context.record_active_index ? '#7B1FA2' : '#9C27B0'}
                          key={ix}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </div>
          </div>
          {day_data && (
            <div className="card mt-2">
              <div className="card-header">
                <h5 className="card-title">Dagomzet van {formatDate(day_data.day)}</h5>
              </div>
              <div className="card-body">
                <div className="columns">
                  <div className="column col-4">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th>Kassaverkoop</th>
                          <td className="text-right">{formatCents(day_data.sale)}</td>
                        </tr>
                        <tr>
                          <th>Geleverd</th>
                          <td className="text-right">{formatCents(day_data.delivered)}</td>
                        </tr>
                        <tr>
                          <th>Retour</th>
                          <td className="text-right">{formatCents(day_data.cancellation)}</td>
                        </tr>
                        <tr>
                          <th>Klantcompensatie</th>
                          <td className="text-right">{formatCents(day_data.compensation)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// endregion
// region Tenant

type TenantContext = {
  loading: boolean;
  invoices: ModelFinanceInvoiceSettlement[];
};

class TenantStore extends BaseStore<TenantContext> {
  setup(): TenantContext {
    return {
      loading: false,
      invoices: [],
    };
  }

  async load() {
    try {
      const res = await http().post('/core/contact/tenant/dashboard_invoices');

      this.next(draft => {
        draft.invoices = res.data.records;
      });
    } catch (err) {
      console.error(err);
    }
  }
}

function DashboardTenant() {
  const ggd = window.GLOBAL_GENERIC_DATA;
  const { context, store } = useRxjsStore<TenantContext, TenantStore>(TenantStore, null, async () => {
    await store.load();
  });

  if (!context) return null;

  return (
    <div className="container">
      <div className="columns">
        <div className="column col-6">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Weekstatements</h5>
            </div>
            <div className="card-body">
              <table className="table">
                <thead>
                  <tr>
                    <th className=" has-table-shrink-nowrap">Vestiging</th>
                    <th>Week</th>
                    <td className="text-right has-table-shrink-nowrap">Verkocht</td>
                    <td className="text-right has-table-shrink-nowrap">Commissie</td>
                    <td className="text-right has-table-shrink-nowrap bg-gray-light">Te ontvangen</td>
                    <td colSpan={2}>&nbsp;</td>
                  </tr>
                </thead>
                <tbody>
                  {context.invoices.map(invoice => {
                    const base = `${GLOBAL_APIS_URL}dmz/downloads/tenant`;
                    const append = `id=${invoice.id}&secret=${invoice.secret}`;
                    const link_excel = `${base}/finance_invoice_settlement_excel?${append}`;
                    const link_pdf = `${base}/finance_invoice_settlement_pdf?${append}`;

                    const building = ggd.buildings[invoice.building_id];

                    return (
                      <tr key={invoice.id}>
                        <td>{building.name}</td>
                        <td>
                          <a
                            href={link_pdf}
                            target="_blank"
                            className="tooltip tooltip-right"
                            data-tooltip="Download het gekoppelde PDF bestand."
                          >
                            {invoice.year} - week {invoice.week} voor {invoice.tenant_id}
                          </a>
                        </td>
                        <td className="text-right has-table-shrink-nowrap">{formatCents(invoice.sale_exclusive)}</td>
                        <td className="text-right has-table-shrink-nowrap">
                          {formatCents(invoice.commission_exclusive)}
                        </td>
                        <td className="text-right has-table-shrink-nowrap bg-gray-light">
                          {formatCents(invoice.total_exclusive)}
                        </td>
                        <td className="has-table-shrink-nowrap">
                          <a
                            href={link_excel}
                            target="_blank"
                            className="tooltip tooltip-left"
                            data-tooltip="Download het gekoppelde Excel bestand."
                          >
                            <i className="fal fa-fw fa-file-excel" />
                          </a>
                        </td>
                        <td className="has-table-shrink-nowrap">
                          <a
                            href={link_pdf}
                            target="_blank"
                            className="tooltip tooltip-left"
                            data-tooltip="Download het gekoppelde PDF bestand."
                          >
                            <i className="fal fa-fw fa-file-pdf" />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={6}>
                      <em>
                        <small>Alle bedragen zijn exclusief BTW.</small>
                      </em>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// endregion
