import * as React from 'react';
import { http } from '../../../http';

type Props = {
  application: ModelVacancyApplication;
  closeModal: ({ reload: boolean }?) => void;
};

export default function ModalJobApplicationArchive({ application, closeModal }: Props) {
  async function onClick() {
    await http().post('/core/vacancy/application/status', {
      application: application.id,
      status: 'rejected',
    });

    closeModal({ reload: true });
  }

  return (
    <div className="modal modal-sm active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">
            Sollicitatie van <strong>{application.applicant.name}</strong> afwijzen
          </div>
        </div>

        <div className="modal-body">
          <p>
            Je wijst hiermee direct de sollicitatie van {application.applicant.name} af. Als deze sollicitant niet
            gekoppeld is aan andere sollicitaites, zal de sollicitatie 31 dagen na afwijzing geanonimiseerd worden.
          </p>
        </div>

        <div className="modal-footer">
          <div className="flex-row flex-space-between">
            <div>
              <button type="button" className="btn btn-link" onClick={() => closeModal()}>
                <i className="fal fa-times" /> Annuleren
              </button>
            </div>
            <div>
              <button type="button" className="btn btn-error" onClick={onClick}>
                <i className="fal fa-trash" /> Afwijzen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
