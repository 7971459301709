import * as React from 'react';
import { Button, ModalBox, ModalBoxClose, ModalBoxQuestion, ModalBoxQuestionInput, ModalBoxTitle } from './Components';
import { PageDisplayCtx } from './PageDisplay';
import { http, httpLive } from '../../http';

export default function ModalEmail() {
  const ggd = window.GLOBAL_GENERIC_DATA;

  const { context, store } = React.useContext(PageDisplayCtx);
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');

  async function onSubmit(e) {
    e && e.preventDefault();

    const res = await http().post('/sale/cart/customer_email', {
      cart: context.cart_id,
      computer: ggd.device.me.id,
      group: ggd.device.group.id,
      email,
      is_customer_hidden: true,
    });

    await httpLive().post('/sale/update', {
      group_id: ggd.device.group.id,
      payload: {
        cart_id: context.cart,
      },
    });

    store.closeModal();
  }

  return (
    <form onSubmit={onSubmit}>
      <ModalBox>
        {!loading && (
          <ModalBoxClose onClick={() => store.closeModal()}>
            <i className="fal fa-times" />
          </ModalBoxClose>
        )}

        <ModalBoxTitle>De bon direct in je mailbox</ModalBoxTitle>

        <ModalBoxQuestion>
          <ModalBoxQuestionInput type="text" value={email} onChange={e => setEmail(e.currentTarget.value)} />
          <Button onClick={onSubmit} type="button" primary disabled={loading}>
            {loading && (
              <>
                <i className="fal fa-spin fa-spinner-third" />
              </>
            )}
            {!loading && <>ontvang bon</>}
          </Button>
        </ModalBoxQuestion>
      </ModalBox>
    </form>
  );
}
