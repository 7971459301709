import { formatCentsShort, formatGiftcardIdentifier } from '../../../utils';
import * as React from 'react';

type Props = {
  purchase: ModelShopCartGiftcardPurchase;
  isLarge: boolean;
  imageSize?: number;
  onDeleteGiftcardPurchaseClick?: (purchase_id: number) => any;
};

export default function ComponentTrGiftcardPurchase({
  purchase,
  isLarge,
  imageSize,
  onDeleteGiftcardPurchaseClick,
}: Props) {
  return (
    <tr>
      {onDeleteGiftcardPurchaseClick && (
        <td className="text-center">
          <a
            href="javascript:"
            className="btn btn-error-outline btn-action s-circle"
            onClick={onDeleteGiftcardPurchaseClick(purchase.id)}
          >
            <i className="fal fa-times" />
          </a>
        </td>
      )}

      <td>
        <img
          className="img-fit-contain s-rounded"
          src={require('./../../../assets/images/giftcard.png')}
          style={{
            width: imageSize || 64,
            height: imageSize || 64,
            display: 'inline-block',
            border: '1px solid rgba(0,0,0,.1)',
          }}
          alt="Aankoop nieuwe cadeaukaart"
        />
      </td>
      <td className="px-0  text-center">
        <i className="fal fa-fw fa-money-bill-wave" />
      </td>
      <td>
        <strong>Aanschaffen van Loods 5 Cadeaukaart</strong>
        <div>{formatGiftcardIdentifier(purchase.giftcard.identifier, true)}</div>
      </td>
      {!isLarge && <td className="text-right">{formatCentsShort(purchase.balance)}</td>}

      {onDeleteGiftcardPurchaseClick && <td>&nbsp;</td>}
    </tr>
  );
}
