import * as React from 'react';
import * as Yup from 'yup';
import { Form, Formik, FormikProps } from 'formik';
import { displayError } from '../../../components/FormGroup';
import FormInput from '../../../components/FormInput';
import { cs, getCountriesSorted, getTouchedErrors } from '../../../utils';
import FormSelect from '../../../components/FormSelect';
import { UpdateCustomerValues } from '../../cash/sale/PageState';

type Props = {
  customer: ModelShopCustomer;
  close: () => void;
  update: (values: UpdateCustomerValues) => Promise<boolean>;
};

const schema = Yup.object().shape({
  country: Yup.number().required('isRequired'),

  first: Yup.string()
    .max(250, 'stringMaxLength')
    .required('isRequired'),
  infix: Yup.string().max(250, 'stringMaxLength'),
  last: Yup.string()
    .max(250, 'stringMaxLength')
    .required('isRequired'),

  email: Yup.string()
    .email('shouldBeEmail')
    .required('isRequired'),
  phone: Yup.string()
    .test('hasMobbile', 'eitherOrPhone', function(value) {
      return this.parent.mobile ? true : !!value;
    })
    .max(20),
  mobile: Yup.string()
    .test('hasPhone', 'eitherOrMobile', function(value) {
      return this.parent.phone ? true : !!value;
    })
    .max(20),
});

export default function ModalOrderCustomerUpdate(props: Props) {
  async function onSubmit(values: UpdateCustomerValues, fp: FormikProps<UpdateCustomerValues>) {
    if (!(await props.update(values))) {
      fp.setSubmitting(false);
    }
  }

  const values = props.customer
    ? {
        country: props.customer.country_id,
        first: props.customer.first,
        infix: props.customer.infix,
        last: props.customer.last,
        email: props.customer.email,
        mobile: props.customer.mobile,
        phone: props.customer.phone,
      }
    : {
        country: '1',
        first: '',
        infix: '',
        last: '',
        email: '',
        mobile: '',
        phone: '',
      };

  return (
    <div className="modal active">
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Klantgegevens opvoeren</div>
          <div className="h6 text-gray" />
        </div>

        <Formik
          initialValues={values}
          onSubmit={onSubmit}
          validationSchema={schema}
          render={fp => {
            const errors = getTouchedErrors(fp.touched, fp.errors);
            const { country, first, infix, last, email, phone, mobile } = errors;

            return (
              <Form>
                <div className="modal-body">
                  <div className={cs('form-group', country && 'has-error')}>
                    <label className="form-label">Land</label>
                    <FormSelect name="country">
                      {getCountriesSorted().map(country => (
                        <option value={country.id}>{country.name}</option>
                      ))}
                    </FormSelect>
                    {country && <div className="form-input-hint">{displayError(country)}</div>}
                  </div>

                  <div className={cs('form-group', email && 'has-error')}>
                    <label className="form-label">E-mailadres</label>
                    <FormInput name="email" />
                    {email && <div className="form-input-hint">{displayError(email)}</div>}
                  </div>

                  <div className={cs('columns form-group', (first || infix || last) && 'has-error')}>
                    <div className="column col-4 col-sm-12">
                      <label className="form-label">Voornaam</label>
                      <FormInput name="first" />
                      {first && <div className="form-input-hint">{displayError(first)}</div>}
                    </div>
                    <div className="column col-3 col-sm-12">
                      <label className="form-label is-optional">Tussenvoegsel</label>
                      <FormInput name="infix" />
                      {infix && <div className="form-input-hint">{displayError(infix)}</div>}
                    </div>
                    <div className="column col-5 col-sm-12">
                      <label className="form-label">Achternaam</label>
                      <FormInput name="last" />
                      {last && <div className="form-input-hint">{displayError(last)}</div>}
                    </div>
                  </div>

                  <div className={cs('columns form-group', (phone || mobile) && 'has-error')}>
                    <div className="column">
                      <label className="form-label is-optional">Mobielnummer</label>
                      <FormInput name="mobile" />
                      {mobile && <div className="form-input-hint">{displayError(mobile)}</div>}
                    </div>
                    <div className="column">
                      <label className="form-label is-optional">Telefoonnummer</label>
                      <FormInput name="phone" />
                      {phone && <div className="form-input-hint">{displayError(phone)}</div>}
                    </div>
                  </div>
                </div>

                <div className="modal-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <button
                    type="button"
                    className="btn btn-link"
                    style={{ alignSelf: 'flex-start' }}
                    onClick={props.close}
                    disabled={fp.isSubmitting}
                  >
                    <i className={cs('fal fa-fw fa-times')} /> Annuleren
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary-outline"
                    disabled={fp.isSubmitting}
                    style={{ alignSelf: 'flex-end' }}
                  >
                    <i className={cs('fal fa-fw', fp.isSubmitting ? 'fa-spin fa-spinner' : 'fa-save')} /> Opslaan
                  </button>
                </div>
              </Form>
            );
          }}
        />
      </div>
    </div>
  );
}
