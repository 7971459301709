import * as React from 'react';
import { formatCents, hasRights, isWithinMz, parseCents, StringObject } from '../../../utils';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import { http, httpLive } from '../../../http';
import { AxiosError } from 'axios';
import * as Yup from 'yup';
import * as IBAN from 'iban';
import FormGroup from '../../../components/FormGroup';
import FormRadio from '../../../components/FormRadio';
import * as _ from 'underscore';
import FormInput from '../../../components/FormInput';
import { useDebounce } from '../../../effects';
import { MessageService } from '../../../services/MessageService';
import moment = require('moment');

type Props = {
  purchase: ModelOrderPurchase;
  close: (success?: boolean) => void;
};

export default function ModalPaymentSale({ purchase, close }: Props) {
  const [active, setActive] = React.useState(false);
  const [values, setValues] = React.useState<StringObject>({});

  const [payment, setPayment] = React.useState<ModelFinancePayment | null>(null);

  function back() {
    setPayment(null);
    setValues({});
    setActive(false);
  }

  async function submit(values, formikActions: FormikActions<any>) {
    const ggd = window.GLOBAL_GENERIC_DATA;

    try {
      const res = await http().post(`/core/order/purchase/payment/sale_by_${values.mode}`, {
        ...values,
        amount: parseCents(values.amount),

        purchase: purchase.id,
        group: ggd.device && ggd.device.group.id,
      });

      if (res.data.payment.is_authorized) {
        MessageService.setMessage('De betaling is opgeslagen, bedankt!', 'success', 3000);

        close(true);
      } else {
        setPayment(res.data.payment);
        setValues(values);
        setActive(true);
      }
    } catch (exc) {
      console.error(exc);
      const err: AxiosError = exc;

      if (err.response && err.response.data && err.response.data.errors) {
        formikActions.setErrors(err.response.data.errors);
      }

      formikActions.setSubmitting(false);
    }
  }

  const maximum_amount = purchase.current_inclusive - purchase.paid;

  const initial = {
    mode: isWithinMz() ? 'terminal' : 'bank',
    amount: '',

    giftcard_number: '',

    account_name: (purchase.customer && purchase.customer.name) || '',
    account_number: '',
    authorized_at: moment().format('L'),

    cart_id: '',
    other_purchase_maximum_amount: '',
  };

  const schema = Yup.object().shape({
    amount: Yup.string()
      .test('tooSmall', 'Het bedrag is te laag.', function(value) {
        return parseCents(value) >= 1;
      })
      .required('isRequired'),

    account_name: Yup.string().test('isPresent', 'isRequired', function(value) {
      if (this.parent.mode !== 'bank') return true;
      return (value || '').length >= 2;
    }),
    account_number: Yup.string().test('isIBANValid', 'Dit is geen geldig IBAN nummer.', function(value) {
      if (this.parent.mode !== 'bank') return true;
      return IBAN.isValid(value || '');
    }),
  });

  return (
    <div className="modal modal-sm active" id="modal-id">
      <div className="modal-overlay" />
      {!active && (
        <Formik
          initialValues={initial}
          validationSchema={schema}
          onSubmit={submit}
          render={fp => <SaleForm purchase={purchase} close={close} fp={fp} />}
        />
      )}

      {active && values.mode === 'terminal' && (
        <SaleTerminalProcessing purchase={purchase} close={close} back={back} payment={payment} />
      )}
    </div>
  );
}

function SaleForm({ purchase, close, fp }: Props & { fp: FormikProps<any> }) {
  const errors = fp.errors;

  const maximum_amount = purchase.paid - purchase.current_inclusive;
  const amount = parseCents(fp.values.amount);

  const [other_purchase, setOtherPurchase] = React.useState<ModelOrderPurchase | null>(null);
  const cart_id = fp.values.cart_id;
  const cart_id_debounced = useDebounce(cart_id, 350);

  const other_purchase_maximum_amount = other_purchase && other_purchase.paid - other_purchase.current_inclusive;

  React.useEffect(() => {
    if (!cart_id) return;

    (async () => {
      try {
        const res = await http().post('/core/order/purchase/one_by_cart', {
          cart: cart_id,
        });

        setOtherPurchase(res.data.purchase);
      } catch (exc) {
        const err: AxiosError = exc;
        console.error(exc);

        if (err.response && err.response.data && err.response.data.errors) {
          fp.setFieldError('cart_id', err.response.data.errors.cart_id);
        }
      }
    })();
  }, [cart_id_debounced]);

  React.useEffect(() => {
    if (fp.values.mode !== 'order') return;

    console.log('setFieldValue', other_purchase_maximum_amount);
    fp.setFieldValue('other_purchase_maximum_amount', other_purchase_maximum_amount);
  }, [other_purchase_maximum_amount]);

  return (
    <Form className="modal-container">
      <div className="modal-header">
        <div className="modal-title h5">Betaling uitvoeren bij #{purchase.cart_id}</div>
      </div>

      <table className="table">
        <tbody>
          <tr>
            <th>Totaalbedrag order</th>
            <td className="text-right">{formatCents(purchase.current_inclusive)}</td>
          </tr>
          <tr>
            <th>Betaald door klant</th>
            <td className="text-right">{formatCents(purchase.paid)}</td>
          </tr>

          {purchase.current_inclusive > purchase.paid && (
            <tr
              className="bg-warning"
              onClick={() => {
                fp.setFieldValue('amount', ((purchase.current_inclusive - purchase.paid) / 100).toFixed(2));
              }}
            >
              <th>Restbedrag</th>
              <td className="text-right">{formatCents(purchase.current_inclusive - purchase.paid)}</td>
            </tr>
          )}
        </tbody>
      </table>

      <input type="hidden" name="mode" value={fp.values.mode} />

      <div className="modal-body">
        <FormGroup title="Betalingsmethode" name="mode" errors={errors}>
          <FormRadio
            name="mode"
            items={[
              isWithinMz() && { title: 'Betalen via PIN-terminal', value: 'terminal' },
              isWithinMz() && { title: 'Betalen met Loods 5 Cadeaukaart', value: 'giftcard' },
              { title: 'Betaling per bank registreren', value: 'bank' },
              { title: 'Betaling via overwaarde andere order', value: 'order' },
            ]}
          />
        </FormGroup>

        <FormGroup title="Bedrag" name="amount" errors={errors}>
          <FormInput name="amount" />
        </FormGroup>

        {fp.values.mode === 'giftcard' && (
          <FormGroup title="Cadeaukaart (scannen)" name="giftcard_number" errors={errors}>
            <FormInput name="giftcard_number" />
          </FormGroup>
        )}

        {fp.values.mode === 'bank' && (
          <FormGroup title="Rekeningnummer (van de klant)" name="account_number" errors={errors}>
            <FormInput name="account_number" />
          </FormGroup>
        )}
        {fp.values.mode === 'bank' && (
          <FormGroup title="T.n.v. rekening" name="account_name" errors={errors}>
            <FormInput name="account_name" />
          </FormGroup>
        )}
        {fp.values.mode === 'bank' && (
          <FormGroup title="Betalingsmoment" name="authorized_at" errors={errors}>
            <FormInput name="authorized_at" />
          </FormGroup>
        )}

        {fp.values.mode === 'order' && (
          <FormGroup title="Ordernummer (met overwaarde)" name="cart_id" errors={errors}>
            <FormInput name="cart_id" />
          </FormGroup>
        )}
        {fp.values.mode === 'order' &&
          other_purchase &&
          (() => {
            const available = other_purchase.paid - other_purchase.current_inclusive;

            function place() {
              fp.setFieldValue('amount', formatCents(available, true));
            }

            return (
              <p>
                <a href="javascript:" onClick={place}>
                  Er is {formatCents(available)} beschikbaar binnen deze order.
                </a>
              </p>
            );
          })()}
      </div>

      <div className="modal-footer">
        <div className="flex-row flex-space-between">
          <div>
            <button type="button" className="btn btn-dark-outline" onClick={() => close()}>
              <i className="fal fa-times" /> Annuleren
            </button>
          </div>
          <div>
            <button type="submit" className="btn btn-primary" disabled={!_.isEmpty(fp.errors) || fp.isSubmitting}>
              <i className="fal fa-money-bill-wave" /> Starten
            </button>
          </div>
        </div>
      </div>
    </Form>
  );
}

type PaymentProcessingProps = {
  payment: ModelFinancePayment;
  back: () => void;
} & Props;

function SaleTerminalProcessing({ purchase, close, payment, back }: PaymentProcessingProps) {
  const ggd = window.GLOBAL_GENERIC_DATA;

  const [started, setStarted] = React.useState(false);
  const [ping, setPing] = React.useState(false);

  async function terminalSendPayment() {
    await http().post('/sale/payment/reset', {
      id: payment.id,
    });

    try {
      const res = await httpLive().post('/terminals/process-payment', {
        payment_id: payment.id,
      });
    } catch (exc) {
      console.error(exc);
    }

    setPing(true);
  }

  async function terminalSendCancel() {
    setPing(false);

    const res = await httpLive().post('/terminals/cancel-process', {
      terminal_id: ggd.device.terminal.id,
    });
  }

  // Directly start the PIN-terminal payment.
  React.useEffect(() => {
    if (started) return;

    setStarted(true);
    terminalSendPayment();
  }, [started]);

  // Ping the payment to check if it's done already.
  React.useEffect(() => {
    if (!ping) return;

    const interval = setInterval(() => {
      (async () => {
        const res = await http().post('/sale/payment/one', {
          id: payment.id,
        });

        if (res.data.payment.is_authorized) {
          MessageService.setMessage('De betaling is opgeslagen, bedankt!', 'success', 3000);

          setPing(false);
          close(true);
        } else if (res.data.payment.is_cancelled) {
          let message = res.data.payment.foreign_latest_message;

          console.log('ERROR!!', message);

          setPing(false);
        }
      })();
    }, 1000);

    return () => clearInterval(interval);
  }, [ping]);

  function onBack() {
    back();
  }
  function onCancel() {
    terminalSendCancel();
  }
  function onApply() {
    terminalSendPayment();
  }

  return (
    <div className="modal-container">
      <div className="modal-header">
        <div className="modal-title h5">Betaling uitvoeren bij #{purchase.cart_id}</div>
      </div>

      <div className="modal-body">
        <p>
          De klant kan nu betalen. Het nummer van de PIN-automaat is <code>{ggd.device.terminal.identifier}</code>.
        </p>
        <p>Het bedrag wat de klant moet betalen is {formatCents(payment.requested_amount)}.</p>
        {payment.is_demo && <p className="text-warning">Dit is een DEMO betaling!</p>}
      </div>

      <div className="modal-footer">
        <div className="flex-row flex-space-between">
          <div>
            {!ping && (
              <button type="button" className="btn btn-dark-outline" onClick={onBack}>
                <i className="fal fa-arrow-left" /> Terug
              </button>
            )}
            {ping && (
              <button type="button" className="btn btn-dark-outline" onClick={onCancel}>
                <i className="fal fa-times" /> Afbreken
              </button>
            )}
          </div>
          <div>
            {!ping && (
              <button type="button" className="btn btn-primary" onClick={onApply}>
                <i className="fal fa-money-bill-wave" /> Opnieuw aanbieden
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
