import * as React from 'react';
import { PageDefinition } from './PageState';
import { cs } from '../../../utils';

type Props = {
  page: PageDefinition;
};

export default function ModalBuzzer(props: Props) {
  const [loading, setLoading] = React.useState(false);

  function onCloseClick() {
    props.page.store.toggleModalBuzzer(false);
  }

  async function onBuzzerClick(number_buzzer: number) {
    setLoading(true);

    await props.page.store.buzzer(number_buzzer);
    await props.page.store.toggleModalBuzzer(false);
  }

  async function onNoBuzzerClick() {
    await onBuzzerClick(0);
  }

  const number_buzzer = (props.page.context.cart && props.page.context.cart.number_buzzer) || 0;

  return (
    <div className="modal active">
      <div className="modal-overlay" />
      <div className="modal-container" style={{ maxHeight: '90vh' }}>
        <div className="modal-header">
          <div className="modal-title h5">Buzzer kiezen</div>
        </div>

        <div className="modal-body">
          <div className="columns">
            {Array.from(Array(50)).map((value, ix) => {
              const number = ix + 1;
              const isActive = number_buzzer === number;

              return (
                <div className="column col-auto p-1" key={number} style={{ width: '20%' }}>
                  <button
                    className={cs('btn btn-block', isActive ? 'btn-primary' : 'btn-dark-outline')}
                    style={{ fontSize: '1.25rem', height: '3.2rem' }}
                    onClick={() => onBuzzerClick(number)}
                  >
                    {number}
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        <div className="modal-footer">
          <button type="button" className="btn btn-link  float-left" onClick={onCloseClick} disabled={loading}>
            <i className="fal fa-times" /> Sluiten
          </button>
          <button
            type="button"
            className="btn btn-error-outline float-right"
            onClick={onNoBuzzerClick}
            disabled={loading}
          >
            <i className="fal fa-times" /> Geen buzzer
          </button>
        </div>
      </div>
    </div>
  );
}
