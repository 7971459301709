import * as React from 'react';
import { jsonEquals } from '../utils';
import Service from './Service';

export interface LocationState {
  pathname: string;
  search: { [name: string]: string | string[] };
  hash: string;

  href: string;

  version?: string;
  newVersion?: string;
}

// region Utilities

function paramsToObject(value: string): SearchParams {
  return (Array.from(new URLSearchParams(value) as any) as [string, string][]).reduce(
    (result: SearchParams, [key, value]) => {
      if (result[key] === undefined) {
        return { ...result, [key]: value };
      }
      if (Array.isArray(result[key])) {
        return { ...result, [key]: [...(result[key] as string[]), value] };
      }

      return { ...result, [key]: [result[key] as string, value] };
    },
    {},
  );
}

function paramsToArray(params: SearchParams): [string, string][] {
  return Object.keys(params).reduce(
    (result, key: string) => {
      const value = params[key];
      const record: [string, string][] = [];

      if (Array.isArray(value)) {
        value.forEach(v => record.push([key, v]));
      } else {
        record.push([key, value]);
      }

      return result.concat(record);
    },
    [] as [string, string][],
  );
}

function locationToState(): LocationState {
  const url = new URL(window.location.href);
  const search = paramsToObject(url.search.substring(1));
  const hash = url.hash.substring(1);

  const { pathname, href } = url;

  return {
    search,
    hash,
    pathname,
    href,
  };
}

export function urlFor(pathname: string, search: SearchParams = {}, hash: string = '') {
  let url = pathname;

  if (search && Object.keys(search).length) {
    const _search = new URLSearchParams(paramsToArray(search)).toString();
    url += `?${_search}`;
  }
  if (hash) {
    url += `#${hash}`;
  }

  return url;
}

// endregion
// region Service

export const LocationService = (() => {
  const { current, next, useEffect } = Service<LocationState>('LocationService', locationToState);

  function update() {
    next({ ...locationToState(), version: current().version, newVersion: current().newVersion });
  }

  function navigate(pathname: string, search: SearchParams = {}, hash: string = '', force: boolean = false) {
    const url = urlFor(pathname, search, hash);

    if (force || !jsonEquals(current(), { pathname, search, hash })) {
      window.history.pushState(null, url, url);
      update();
    }
  }

  function newVersion(newVersion: string, version: string) {
    if (newVersion !== version && window.GLOBAL_GENERIC_DATA.auto_reload) {
      window.location.reload();
    } else {
      next({ ...current(), newVersion, version });
    }
  }

  window.onpopstate = update;

  return {
    current,
    next,
    useEffect,

    update,
    navigate,
    newVersion,
  };
})();

(window as any).__LocationService = LocationService;

// endregion
