import * as React from 'react';

type Context = {
  loading: boolean;
};

type Props = {
  printerId: number;
  onClose: (success?: boolean) => void;
};

export default function ModalTestTicketPrinter(props: Props) {
  return (
    <div className="modal active" id="modal-id">
      <div className="modal-overlay" />

      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-title h5">Stuk</div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-link  float-left" onClick={() => props.onClose()}>
            <i className="fal fa-times" /> Annuleren
          </button>
        </div>
      </div>
    </div>
  );
}
