import * as React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';

export const theme = {
  primary: '#c09745',
};

export const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700');
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }
`;

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
`;

export const ButtonGroup = styled.div`
  display: flex;
  padding: 20px 15px;
`;

export const Button = styled.button`
  flex: auto;

  background: ${props => (props.primary ? theme.primary : 'white')};
  color: ${props => (props.primary ? 'white' : theme.primary)};

  font-size: 1.2em;
  margin: 0 15px;
  padding: 1em 0;
  font-weight: bold;
  border: 1px solid ${theme.primary};
`;

export const Col = styled.div`
  height: 100%;
  ${props =>
    props.left &&
    css`
      background: #f0eff4;
      width: 60%;
    `}
  ${props =>
    props.right &&
    css`
      width: 40%;
      background: #ffffff;
      border-left: 1px solid #bfbfbf;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    `}
`;

export const TopData = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  min-height: 0px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: white;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #bfbfbf;
  }
`;

export const BottomData = styled.div`
  min-height: min-content;
  width: 100%;
  margin-top: auto;
  border-top: 1px solid #bfbfbf;
  ul {
    padding: 0 30px;
    box-sizing: border-box;
    li {
      padding: 20px 0;
    }
  }
`;

export const List = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  ${props =>
    props.specialdeal &&
    css`
      border-top: 1px solid #bfbfbf;
    `}
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;

  padding: 20px 30px;
  & + & {
    border-top: 1px solid #dfdfdf;
  }
  ${props =>
    props.discounts &&
    css`
      flex-wrap: wrap;
    `}

  ${props =>
    props.payment &&
    css`
      justify-content: center;
    `}
`;

export const Text = styled.div`
  ${props =>
    props.primary &&
    css`
      color: ${theme.primary};
      font-size: 1.4em;
    `}
`;

export const ItemData = styled.span`
  font-weight: 500;
  ${props =>
    props.amount &&
    css`
      border-radius: 16px;
      padding: 4px 16px;
      border: 1px solid #bfbfbf;
      margin-right: 15px;
    `}
  ${props =>
    props.price &&
    css`
      text-align: right;
      margin-left: auto;
      white-space: nowrap;
      padding-left: 15px;
      span + span {
        font-weight: 400;
        text-decoration: line-through;
      }
    `}
`;

export const Data = styled.span`
  width: 100%;
  float: left;
  & + & {
    color: #777;
    font-size: 0.75em;
  }
  span + span {
    font-weight: 400;
    &::before {
      content: '•';
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`;

export const ListSubItem = styled.span`
  width: 100%;
  float: left;
  display: flex;
  & + & {
    padding-top: 10px;
  }
`;

export const Label = styled.span`
  display: flex;
  flex-wrap: wrap;
  ${props =>
    props.total &&
    css`
      font-weight: 500;
    `}
`;
export const SubLabel = styled.span`
  color: #777;
  font-size: 0.75em;
  font-weight: 500;
  width: 100%;
  clear: both;
  float: left;
`;

export const Value = styled.span`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    props.total &&
    css`
      font-weight: 700;
      font-size: 1.6em;
    `}
`;

export const Receipt = styled.svg`
  width: 40px;
  height: 41px;
`;

export const Checkmark = styled.svg`
  width: 14px;
  height: 11px;
  fill: ${theme.primary};
`;

export const StyledCheckbox = styled.label`
  border: 1px solid #bfbfbf;
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 15px;
  position: relative;
`;
export const StyledCheckboxInput = styled.input`
  display: none;
`;

export const StyledCheckboxIcon = styled.span`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const RoundedNumber = styled.span`
  background: ${theme.primary};
  border-radius: 100%;
  width: 16px;
  height: 16px;
  font-weight: 700;
  color: #fff;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 11px;
  line-height: 1.6em;
  margin-right: 6px;
  margin-top: 1px;
  ${props =>
    props.tiny &&
    css`
        position: absolute;
        right: -15px;
        top: -7px;
        font-size: 0.9em
        line-height: 1.6em;
        width: 14px;
        height: 14px;
    `}
`;

export const GiftCardAmount = styled.span`
  border-radius: 14px;
  padding: 1px 7px;
  font-size: 11px;
  line-height: 1.45em;
  font-weight: 700;
  border: 1px solid #bfbfbf;
  margin-left: 8px;
  position: relative;
`;

export const ModalBoxContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  align-items: flex-start;
  justify-content: center;
  display: flex;
`;

export const ModalBox = styled.div`
  max-width: 620px;

  background: #fff;
  margin-top: 30px;
  padding: 60px;
  position: relative;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const ModalBoxClose = styled.a`
  position: absolute;
  right: 0;
  top: 0;

  background-color: #000;
  color: white;
  font-size: 1.4em;
  padding: 0.5em 1em;
`;

export const ModalBoxTitle = styled.h2`
  font-size: 1.5em;
  line-height: 1.6em;
  font-weight: 700;
  width: 100%;
  float: left;
  text-align: center;
`;

export const ModalBoxQuestion = styled.div`
  display: flex;
  width: 100%;

  & > ${Button} {
    margin-left: 0;
    flex-shrink: 1;
  }
`;
export const ModalBoxQuestionInput = styled.input`
  flex-grow: 1;
  border: 2px solid ${theme.primary};
  font-size: 1.6em;
  padding: 0 10px;
`;

export const AnswerList = styled.ul`
    list-style: none;
    padding:0
    margin: 0;
    width: 100%;
    font-size: 3em;
    line-height: 1em;
    li {
        display: inline-block;
        a {
        display: inline-block;
        padding: 10px;
        margin: 0 5px;
        text-decoration: none;
    }
`;

export const Idle = styled.div`
  background: #000;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  transform: translateX(0);
  transition: transform 0.5s ease 0s;
  z-index: 1;
  display: flex;
  &.hide {
    transform: translateX(-100vw);
  }
`;

export const IdleLogo = styled.svg`
  width: 150px;
  height: 145px;
  position: relative;
  fill: #fff;
`;
