import * as React from 'react';
import Service from './Service';
import axios, { AxiosError } from 'axios';
import { http } from '../http';
import { MessageService } from './MessageService';

export interface ProfileValues {
  person?: ModelContactPerson | null;
  session?: ModelContactPersonSession;
  takeback?: ModelContactPerson | null;
}

export interface ProfileState extends ProfileValues {
  present: boolean;
  loading: boolean;
}

// region Utilities

function emptyState(): ProfileState {
  return {
    present: false,
    loading: false,

    person: null,
    session: null,
  };
}

// endregion
// region Service

export const ProfileService = (() => {
  function initialState() {
    const profile = getGlobalProfileData();

    if (profile === null) {
      return emptyState();
    }

    check(profile.session && profile.session.token);

    return {
      present: true,
      loading: true,
      person: profile.person,
      session: profile.session,
    };
  }

  async function check(token: string = null) {
    try {
      const res = await http().post('/dmz/security/check', null, {
        headers: {
          'x-session': token || (current && current().session.token),
        },
      });

      console.log(res.data.session.token);

      axios.defaults.headers['x-session'] = res.data.session.token;

      setTimeout(() => {
        next({
          present: true,
          loading: false,
          person: res.data.person,
          session: res.data.session,
          takeback: res.data.takeover,
        });
      }, 250);

      return true;
    } catch (exc) {
      const err: AxiosError = exc;
      console.error(exc);

      let message = '';

      if (err.response && err.response.data.errors) {
        if (!err.response.data.errors.session) {
          // Something has gone terribly wrong, this should not occur.
        } else if (err.response.data.errors.session.message === 'sessionHasExpired') {
          message = 'Je sessie is verlopen, je zal opnieuw moeten inloggen.';
        }
      }

      if (message) {
        MessageService.setMessage(message, 'error', 3000);
      }

      setTimeout(() => {
        next(emptyState());
      }, 50);

      return false;
    }
  }

  const { subject, current, next, useEffect } = Service<ProfileState>('ProfileService', initialState);

  subject.subscribe(state => {
    window.localStorage.setItem('ProfileService', JSON.stringify(state));
  });

  return {
    current,
    next,
    useEffect,
    check,

    setProfile: (values: ProfileValues) => {
      next({ present: true, loading: false, ...values });
    },
    empty: () => {
      next(emptyState());
    },
  };
})();

export function getGlobalProfileData(): ProfileState {
  const data = window.localStorage.getItem('ProfileService');

  if (data) {
    return JSON.parse(data) as ProfileState;
  }

  return null;
}

// endregion
