import * as React from 'react';
import { PageVacancyApplicationManyCtx, usePageVacancyApplicationManyRxjs } from './PageVacancyApplicationManyStore';
import SpectreCardHeader from '../../../components/spectre/SpectreCardHeader';
import SpectreButton from '../../../components/spectre/SpectreButton';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { cs, formatDate, formatMomentAgo, hasRights, parseMoment } from '../../../utils';
import FormInput from '../../../components/FormInput';
import GenericPagination from '../../../components/GenericPagination';
import ModalFilter, { ModalFilterValues } from './ModalFilter';
import * as _ from 'underscore';
import VacancyGenericApplicationStatus from '../generic/VacancyGenericApplicationStatus';
import MenuDropdown from '../../../components/MenuDropdown';
import ModalJobApplicantEdit from '../modals/ModalJobApplicantEdit';
import ModalJobApplicantLinkTo from '../modals/ModalJobApplicantLinkTo';
import ModalJobApplicationTag from '../modals/ModalJobApplicationTag';
import ModalJobApplicationArchive from '../modals/ModalJobApplicationArchive';
import ModalJobApplicationView from '../modals/ModalJobApplicationView';
import { Link } from '../../../components/Link';
import ModalJobApplicationDelete from '../modals/ModalJobApplicationDelete';
import AtomTableNoRecords from '../../../components/AtomTableNoRecords';
import moment = require('moment');

export default function PageVacancyApplicationMany() {
  const { store, context } = usePageVacancyApplicationManyRxjs();

  if (!store || !context) return null;

  return (
    <PageVacancyApplicationManyCtx.Provider value={{ store, context }}>
      <div className="container">
        <Table />
        <Modals />
      </div>
    </PageVacancyApplicationManyCtx.Provider>
  );
}

function Table() {
  const { store, context } = React.useContext(PageVacancyApplicationManyCtx);

  function openApplicationCreate() {
    store.openModal({ which: 'applicant-create', data: {} });
  }

  return (
    <div className="card">
      <div className={cs('card-loading', !context.loaded && 'active')} />

      <SpectreCardHeader
        title="Sollicitanten"
        subtitle="Beheer hier alle sollicitanten."
        buttons={[
          hasRights(['vacancy.write']) && (
            <SpectreButton
              icon="fa-plus"
              onClick={openApplicationCreate}
              tooltip={{ title: 'Nieuwe sollicitatie toevoegen', position: 'left' }}
            />
          ),
        ]}
      />
      <div className="card-body">
        <Search />
      </div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Naam</th>
              <th>Vacature</th>
              <th>Status</th>
              <th>Doorlooptijd</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          {context.applications.length === 0 && (
            <tbody>
              <tr>
                <td colSpan={6}>
                  <AtomTableNoRecords />
                </td>{' '}
              </tr>
            </tbody>
          )}
          {context.applications.length > 0 && (
            <>
              <GenericPagination pagination={context.pagination} setPage={store.setPage} span={6} />
              <tbody>
                {context.applications.map(application => (
                  <TableRow key={application.id} application={application} />
                ))}
              </tbody>
              <GenericPagination pagination={context.pagination} setPage={store.setPage} span={6} />
            </>
          )}
        </table>
      </div>
    </div>
  );
}

function TableRow({ application }: { application: ModelVacancyApplication }) {
  const { store } = React.useContext(PageVacancyApplicationManyCtx);

  const applicant = application.applicant;
  const job = store.jobById(application.job_id);
  const location = job && store.locationById(job.location_id);
  const department = job && store.departmentById(job.department_id);
  const closed = _.contains(['employed', 'rejected'], application.status);

  const age = moment()
    .startOf('day')
    .diff(parseMoment(applicant.created_at).startOf('day'), 'weeks');

  let age_classname = closed ? 'text-dark' : 'text-dark';

  if (_.contains(['pending', 'interview', 'negotiations'], application.status)) {
    if (age >= 3) {
      age_classname = 'text-error';
    } else if (age > 1) {
      age_classname = 'text-warning';
    }
  }

  return (
    <tr key={application.id}>
      <td className="has-table-shrink-nowrap">#{application.id}</td>
      <td>
        {applicant && <strong>{applicant.name}</strong>}
        {!applicant && <em>Anoniem #{application.id}</em>}
        <div>
          <ApplicationTags application={application} />
        </div>
      </td>
      <td style={{ width: '25%' }}>
        {job && (
          <React.Fragment>
            <Link pathName="/vacancy/job/applications" pathSearch={{ job_id: job.id.toString() }}>
              <strong>{job.title}</strong>
            </Link>
            <br />
            <em>
              {department && department.name}
              {department && location && ' in '}
              {location && location.name}
            </em>
          </React.Fragment>
        )}
      </td>
      <td className="has-table-shrink-nowrap">
        <VacancyGenericApplicationStatus application={application} />
      </td>
      <td className="has-table-shrink-nowrap">
        <span className={age_classname}>
          {formatDate(application.created_at)}
          <br />
          {formatMomentAgo(application.created_at, parseMoment(application.closed_at))}
        </span>
      </td>
      <td className="has-table-shrink-nowrap">
        {hasRights(['vacancy.write']) && applicant && (
          <MenuDropdown
            button={<i className="fal fa-ellipsis-h" />}
            classNames={['mr-1']}
            btnClassNames={['btn btn-dark-outline btn-action s-circle']}
            items={[
              <a
                href="javascript:"
                onClick={() => {
                  store.openModal({ which: 'application-view', data: { application } });
                }}
              >
                <i className="fal fa-fw fa-search" /> bekijken
              </a>,
              <a
                href="javascript:"
                onClick={() => {
                  store.openModal({ which: 'applicant-edit', data: { applicant } });
                }}
              >
                <i className="fal fa-fw fa-pencil" /> aanpassen
              </a>,
              <a
                href="javascript:"
                onClick={() => {
                  store.openModal({ which: 'application-tag', data: { application } });
                }}
              >
                <i className="fal fa-fw fa-tags" /> tags
              </a>,
              <a
                href="javascript:"
                onClick={() => {
                  store.openModal({ which: 'applicant-link-to', data: { applicant } });
                }}
              >
                <i className="fal fa-fw fa-link" /> linken
              </a>,
              true,
              !closed && (
                <a
                  href="javascript:"
                  onClick={() => {
                    store.openModal({ which: 'application-archive', data: { application } });
                  }}
                >
                  <i className="fal fa-fw fa-trash" /> afwijzen
                </a>
              ),
              closed && (
                <a
                  href="javascript:"
                  onClick={() => {
                    store.openModal({ which: 'application-delete', data: { application } });
                  }}
                >
                  <i className="fal fa-fw fa-trash" /> verwijderen
                </a>
              ),
            ]}
          />
        )}
        <SpectreButton
          icon="fa-search"
          tooltip={{ title: 'Bekijk deze sollicitatie.', position: 'left' }}
          onClick={() => store.openModal({ which: 'application-view', data: { application } })}
        />
      </td>
    </tr>
  );
}

function Modals() {
  const { store, context } = React.useContext(PageVacancyApplicationManyCtx);
  const mctx = context.modal;

  if (!mctx) return null;

  function filterApplications(values?: ModalFilterValues) {
    if (_.isEmpty(values)) {
      store.closeModal();
    } else {
      store.closeModal();
      store.search(values);
    }
  }

  return (
    <React.Fragment>
      {mctx.which === 'filter-applications' && <ModalFilter {...mctx.data} closeModal={filterApplications} />}

      {mctx.which === 'applicant-edit' && <ModalJobApplicantEdit {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'application-view' && <ModalJobApplicationView {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'applicant-link-to' && <ModalJobApplicantLinkTo {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'application-tag' && <ModalJobApplicationTag {...mctx.data} closeModal={store.closeModal} />}
      {mctx.which === 'application-archive' && (
        <ModalJobApplicationArchive {...mctx.data} closeModal={store.closeModal} />
      )}
      {mctx.which === 'application-delete' && (
        <ModalJobApplicationDelete {...mctx.data} closeModal={store.closeModal} />
      )}
    </React.Fragment>
  );
}

function Search() {
  const { store, context } = React.useContext(PageVacancyApplicationManyCtx);
  const initial = {
    name: context.query.name,
  };
  const query_is_empty = store.queryInModalIsEmpty(true);

  function openFilterApplications() {
    store.openModal({ which: 'filter-applications', data: { initial: context.query } });
  }

  return (
    <Formik
      initialValues={initial}
      enableReinitialize
      onSubmit={async (values, formikActions) => {
        await store.search(values);
        formikActions.setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string(),
        online_status: Yup.string(),
        status: Yup.string(),
      })}
      render={fp => (
        <Form style={{ padding: '0 1rem' }}>
          <div className={cs('form-group')}>
            <div className="columns">
              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <button
                  type="button"
                  className={cs(
                    'btn btn-action s-circle tooltip tooltip-right',
                    context.query_is_empty ? 'btn-error-outline' : 'btn-error',
                  )}
                  data-tooltip="Leeg alle zoekvelden."
                  disabled={!context.loaded}
                  onClick={async () => {
                    await store.resetQuery();
                  }}
                >
                  <i className="fal fa-times" />
                </button>
              </div>

              <div className="column col-4 col-xs-12  pr-2">
                <label className="form-label">Zoeken op sollicitant</label>
                <FormInput name="name" />
              </div>

              <div className="column" />

              <div className="column col-auto" style={{ alignSelf: 'flex-end' }}>
                <SpectreButton
                  icon="fa-filter"
                  classNames={!query_is_empty && ['btn-primary btn-action s-circle mx-1']}
                  onClick={openFilterApplications}
                  disabled={!context.loaded}
                  tooltip={{ title: 'Filter de sollicitaties', position: 'left' }}
                />
                <button
                  type="submit"
                  className="btn btn-primary-outline btn-action s-circle"
                  disabled={!context.loaded}
                >
                  <i className={cs('fal fa-fw', !context.loaded ? 'fa-spin fa-spinner-third' : 'fa-search')} />
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  );
}

function ApplicationTags({ application }: { application: ModelVacancyApplication }) {
  const { store, context } = React.useContext(PageVacancyApplicationManyCtx);

  return (
    <React.Fragment>
      {application.tags
        .filter(vat => vat.end === null)
        .map(vat => {
          const tag = vat.tag;

          function closeTag(tag_id: number) {
            store.applicationCloseTag(application.id, tag_id);
          }

          return (
            <span className="chip chip-sm" style={{ maxWidth: '100%' }} key={vat.id}>
              <div className="flex-row" style={{ width: '100%' }}>
                <div className="flex-shrink">
                  <figure className="avatar avatar-xs" style={{ backgroundColor: `#${tag.color}` }} />
                </div>
                <div
                  className="flex-grow"
                  style={{
                    flex: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    minWidth: 0,
                  }}
                >
                  {tag.name}
                </div>
                {hasRights(['vacancy.write']) && (
                  <div className="flex-shrink">
                    <a
                      href="javascript:"
                      className="btn btn-clear"
                      aria-label="Close"
                      role="button"
                      onClick={() => closeTag(tag.id)}
                    />
                  </div>
                )}
              </div>
            </span>
          );
        })}
    </React.Fragment>
  );
}
